export interface LoginCredentialsModel {
    username: string,
    password: string
}

export class LoginCredentials {
    static deserialize (data: string): LoginCredentials | undefined {
        if (!data) {
            return;
        }
        return LoginCredentials.create(JSON.parse(data) as LoginCredentialsModel);
}

    static create (model: LoginCredentialsModel): LoginCredentials {
        const {
            username,
            password
        } = model;

        return new LoginCredentials(username, password);
    }

    constructor(
        public readonly username: string,
        public readonly password: string
    ) {}
    
    serialize(): string {
        const {
            username,
            password
        } = this;

        return JSON.stringify({
            username,
            password
        })
    }
}