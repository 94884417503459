import {
    Button,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Tooltip,
    Typography
} from '@material-ui/core';
import {
    createStyles,
    WithStyles,
    withStyles
} from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import {
    Clear,
    Done,
    NotInterested,
    Warning
} from '@material-ui/icons';
import memoize from 'memoize-one';
import * as React from 'react';
import {
    FloorPlanStatusResponseResults,
    FloorPlanStatusResponseResultsItem
} from '../../models/floor-plan-responses';
import { convertMarkersResultIntoMarkerList } from '../../utils/markers-utilities';
import {
    ExportMarkersButton,
    MarkersListItem
} from '../export-markers-button';

enum MarkersListType {
    Processed = 0,
    Skipped,
    Failed
}

const styles = (theme: Theme) => createStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        flex: 1,
        minHeight: '70%',
        marginBottom: '1em'
    },
    buttonContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
    },
    listContainer: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        alignItems: 'center',
        marginBottom: '2em'
    },
    title: {
    },
    titleOnly: {
        marginBottom: '1em'
    },
    summary: {
        fontSize: '0.9em',
        fontStyle: 'italic',
        marginBottom: '1em'
    },
    list: {
        overflow: 'auto',
        maxHeight: '19em'
    },
    listItem: {
        padding: '0 0 0.5em 0'
    },
    listItemIcon: {
        margin : '0 0.4em 0 0'
    },
    listItemText: {
        padding: '0 0.9em 0 0'
    },
    skipIcon: {
        color: '#F3F735'
    },
    csvExport: {
        marginTop: '1em'
    },
    genericButton: {
        height: '2.5em',
        marginRight: '2em'
    }
});

interface Props extends WithStyles<typeof styles> {
    title: string;
    nextButtonDisabled: boolean;
    showSummary: boolean;
    results: FloorPlanStatusResponseResults | undefined;
    onError?: (error: string, errorDescription: string) => void;
    onNextClick?: () => void;
    onStartOver?: () => void;
};

// tslint:disable-next-line: no-empty-interface
interface States {
}

class FloorPlanStatusReport extends React.Component<Props, States> {
    public static defaultProps = {
        nextButtonDisabled: false,
        showSummary: true,
        results: undefined as (FloorPlanStatusResponseResults | undefined)
    };

    state = {
    };

    convertMarkersResultIntoMarkerList = memoize((markersResult: FloorPlanStatusResponseResultsItem[]): MarkersListItem[] => {
        return convertMarkersResultIntoMarkerList(markersResult);
    });
    
    generateMarkersList = (items :FloorPlanStatusResponseResultsItem[], listType: MarkersListType): React.ReactNode[] =>{
        const { classes } = this.props;
        return items.map((item, index) => {
            let key;
            let icon;
            let secondary;
            let tooltip;
            switch (listType) {
                case MarkersListType.Processed:
                    key = `proc-${index}`;
                    icon = <Done color="primary"/>;
                    secondary = item.markerId;
                    tooltip = 'Marker Processed'
                    break;
                case MarkersListType.Skipped:
                    key = `skip-${index}`;
                    icon = <NotInterested className={classes.skipIcon} />;
                    secondary = item.markerId;
                    tooltip = 'Marker Skipped'
                    break;
                case MarkersListType.Failed:
                    key = `fail-${index}`;
                    icon = <Clear color="error" />;
                    secondary = item.error;
                    tooltip = 'Marker Failed'
                    break;
                default:
                    key = `${index}`;
                    icon = <Warning color="error" />;
                    secondary ='Unknown Type';
            }

            return (
                 <ListItem
                    key={key}
                    className={classes.listItem}
                >
                    <ListItemIcon className={classes.listItemIcon}>
                        <Tooltip title={tooltip ? tooltip : ''}>
                            {icon}
                        </Tooltip>
                    </ListItemIcon>
                    <ListItemText
                        className={classes.listItemText}
                        primary={item.markerName}
                        secondary={secondary}
                    />
                </ListItem>
            );
        });
    }

    public render() {
        const {
            classes,
            nextButtonDisabled,
            results,
            title,
            onNextClick,
            onStartOver,
            showSummary
        } = this.props;

        let isExportDisabled;
        let markers;
        let markersProcessedLength;
        let markersFailedLength;
        let markersSkippedLength;
        let markersTotal;
        if (results) {
            const {
                markersProcessed,
                markersFailed,
                markersSkipped,
                total
            } = results;
            markers = this.convertMarkersResultIntoMarkerList(markersProcessed) as MarkersListItem[];
            markersProcessedLength = markersProcessed.length;
            isExportDisabled = (markersProcessedLength < 1);
            markersFailedLength = markersFailed.length;
            markersSkippedLength = markersSkipped.length;
            markersTotal = total;
        } else {
            markers = [] as MarkersListItem[];
            isExportDisabled = true;
            markersProcessedLength = 0;
            markersFailedLength = 0;
            markersSkippedLength = 0;
            markersTotal = 0;
        }

        return (
            <div className={classes.root}>
                <div className={classes.listContainer}>
                { title ?
                    <Typography className={`${classes.title}${showSummary ? '' : ` ${classes.titleOnly}`}`} variant="h6" >
                        {title}
                    </Typography>
                : ''
                }
                { showSummary ?
                    <Typography className={classes.summary} variant="h6">
                        Total Markers: {markersTotal} - Processed: {markersProcessedLength}, Failed: {markersFailedLength}, Skipped: {markersSkippedLength}
                    </Typography>
                : ''
                }
                { results ?
                    <List className={classes.list}>
                    { 
                        this.generateMarkersList(results.markersProcessed, MarkersListType.Processed)
                            .concat(this.generateMarkersList(results.markersSkipped, MarkersListType.Skipped))
                            .concat(this.generateMarkersList(results.markersFailed, MarkersListType.Failed))
                    }
                    </List>
                :  <Typography
                        variant="h5"
                    >
                        No Results
                    </Typography>
                }
                { results ?
                    <ExportMarkersButton
                        className={classes.csvExport}
                        markers={markers}
                        disabled={isExportDisabled}
                    />
                 : ''
                }
                </div>
                <div className={classes.buttonContainer}>
                    <Button
                        className={classes.genericButton}
                        variant="contained"
                        color="secondary"
                        onClick={onStartOver}
                    >
                        Start Over
                    </Button>
                    <Button
                        className={classes.genericButton}
                        variant="contained"
                        color="primary"
                        disabled={nextButtonDisabled}
                        onClick={onNextClick}
                    >
                        Next &gt;
                    </Button>
                </div>
            </div>
        );
    }
};

const MUIComponent = withStyles(styles)(FloorPlanStatusReport);
export { MUIComponent as FloorPlanStatusReport };
