import { TextField } from '@material-ui/core';
import {
    createStyles,
    WithStyles,
    withStyles,
    Theme
} from '@material-ui/core/styles';
import { Autocomplete } from '@material-ui/lab';
import * as React from 'react';
import { ModuleGroup } from '../../models/module-group';
import { SimpleList, SimpleListItem } from '../../shared/components/simple-list';

const styles = (theme: Theme) => createStyles({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    list: {
        flex: '1 0',
        overflow: 'auto',
        boxShadow: 'none'
    },
    selectInput: {
        margin: '1em'
    }
});

interface Props extends WithStyles<typeof styles> {
    className?: string;
    existingModuleList: {[key: string]: boolean};
    moduleGroups: ModuleGroup[];
    moduleKey: string;
    canEdit: boolean;
    onModuleChange?: (moduleKey: string, moduleList: {[key: string]: boolean}) => void;
    registerAction?: (module: string, operation: string, moduleKey: string) => void;
}

interface States {
    moduleList: SimpleListItem[];
    autoCompleteValue: ModuleGroup | null | undefined;
    autoCompleteKey: string;
}

class ModuleGroupForm extends React.Component<Props, States> {

    generateRandomKey = () => {
        const randomKey = Math.random().toString(36).substring(7);
        return randomKey;
    };

    state: States = {
        moduleList: [],
        autoCompleteValue: null,
        autoCompleteKey: ''
    }

    componentDidMount() {
        this.formatModuleList();
    }

    formatModuleList = () => {
        const { existingModuleList, moduleGroups } = this.props;

        if (!existingModuleList) {
            return;
        }

        const moduleList = [];

        for (const key in existingModuleList) {
            const fullModule = moduleGroups.find(e => e.module_group_id === key);

            if (!fullModule) {
                moduleList.push(new SimpleListItem(key, key));
            } else {
                moduleList.push(new SimpleListItem(key, fullModule.name, key));
            }
        }

        this.setState({moduleList});
    }

    onDeleteModuleClicked = (listItem: SimpleListItem) => {
        const { onModuleChange, registerAction, existingModuleList, moduleKey } = this.props;
        if (!onModuleChange) {
            return;
        }
        
        const { moduleList } = this.state;
        delete existingModuleList[listItem.id];

        onModuleChange(moduleKey, existingModuleList);
        
        const delIndex = moduleList.findIndex(e => e.id === listItem.id);

        if (registerAction) {
            registerAction(listItem.label!, 'DELETE', moduleKey);
        }

        moduleList.splice(delIndex, 1);
        this.setState({moduleList});
    }

    onAddModule = (module: ModuleGroup) => {
        const { onModuleChange, registerAction, existingModuleList, moduleKey } = this.props;
        if (!onModuleChange) {
            return;
        }
        
        const { moduleList } = this.state;
        existingModuleList[module.module_group_id] = true;
        onModuleChange(moduleKey, existingModuleList);
        
        if (registerAction) {
            registerAction(module.name, 'ADD', moduleKey);
        }

        moduleList.push(new SimpleListItem(module.module_group_id, module.name, module.module_group_id));
        this.setState({moduleList});
    }

    onModuleSearch = (e: React.ChangeEvent<{}>, value: ModuleGroup | null) => {
        if (!value) {
            return;
        }

        this.onAddModule(value);
    }

    getAvailableModuleToAddList = () => {
        const { moduleGroups } = this.props;
        const { moduleList } = this.state;

        const modulesToAdd = moduleGroups.filter(e => !moduleList.find(f => f.id === e.module_group_id));

        return modulesToAdd;
    }

    clearAutocompleteSelector = (evt: React.FocusEvent<HTMLDivElement> | undefined) => {
        this.setState({
            autoCompleteValue: null,
            autoCompleteKey: this.generateRandomKey()
        });
        return;
    }

    public render() {
        const {
            className,
            classes,
            canEdit,
            moduleKey
        } = this.props;
        const {
            moduleList,
            autoCompleteKey,
            autoCompleteValue
        } = this.state;

        return (
            <div className={`${classes.root} ${className}`} data-testid="module-list">
                <div className={`${classes.container}`}>
                    <SimpleList
                        className={classes.list}
                        items={moduleList}
                        noItemsLabel={"No Modules"}
                        canDeleteListItem={canEdit}
                        keepItemSelected={false}
                        onDeleteItemClick={this.onDeleteModuleClicked}
                    />
                    {
                        canEdit &&
                            <Autocomplete
                                id={`combo-box-module-${moduleKey}`}
                                key={autoCompleteKey}
                                className={classes.selectInput}
                                options={this.getAvailableModuleToAddList()}
                                getOptionLabel={(option) => option.name}
                                renderInput={(params) => <TextField {...params} label="Add a module" variant="standard" />}
                                onChange={(e, pickedValue) => this.onModuleSearch(e, pickedValue)}
                                data-testid="module-group-form-input"
                                value={autoCompleteValue}
                                clearOnBlur={true}
                                blurOnSelect={true}
                                onBlur={this.clearAutocompleteSelector}
                            />
                    }
                </div>
            </div>
        );
    }
}

const MUIComponent = withStyles(styles)(ModuleGroupForm);
export {MUIComponent as ModuleGroupForm}
