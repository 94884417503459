import {
    Button,
    Checkbox, Divider, FormControlLabel, Grid, ListItemText, Paper, Switch, TextField, Typography } from '@material-ui/core';
import {
    createStyles,
    WithStyles,
    withStyles,
    Theme
} from '@material-ui/core/styles';
import * as React from 'react';
import { Account, Folder, Team } from '../../shared/domain';
import _ from "lodash";
import { ModuleGroupForm } from '../module-group-form';
import { FolderTreePicker } from '../folder-tree-picker';
import { UserListPicker } from '../user-list-picker';
import { userComponent } from '../user-list-picker/user-list.picker';
import { SimpleSelectInput } from '../simple-select-input';
import { EmailConfigForm } from '../email-config-form';
import { formIntent, TeamRegisteredChanges } from '../../features/teams/teams';
import { ModuleGroup } from '../../models/module-group';
import { CCSpinner } from '../../shared/components/cc-spinner';

export type TeamFormInput = 'string' | 'boolean' | 'modules' | 'folders' | 'users' | 'select' | 'email';
export type FolderActionIntent = 'ADD' | 'DELETE';
export interface teamForm {
    field: string;
    required: boolean;
    input: TeamFormInput;
    items?: string[];
    helpLabel?: string;
    depedentValues?: string;
}

const MANAGER_DEFAULT_ACTIONS = {
    access: {
        management_app: true,
        portal: true,
    },
    common: {
        agree: true,
        assign: true,
        categorize: true,
        comment: true,
        delete_comments: true,
        delete_reports: true,
        marker_omniscience: true,
        marker_visibility: true,
        modify_folders: false,
        modify_markers: false,
        schedule: true,
        status: true,
    },
    meta: {
        create_account: false,
        modify_account: false,
        modify_floorplans: false,
        modify_teams: false,
        modify_users: false,
    },
};
const EMPLOYEE_DEFAULT_ACTIONS = {
    access: {
        management_app: false,
        portal: false,
    },
    common: {
        agree: true,
        assign: false,
        categorize: false,
        comment: true,
        delete_comments: false,
        delete_reports: false,
        marker_omniscience: true,
        marker_visibility: true,
        modify_folders: false,
        modify_markers: false,
        schedule: false,
        status: false,
    },
    meta: {
        create_account: false,
        modify_account: false,
        modify_floorplans: false,
        modify_teams: false,
        modify_users: false,
    },
};

const styles = (theme: Theme) => createStyles({
    [theme.breakpoints.down('lg')]: {
        simpleList: {
            minWidth: '32em'
        },
    },
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    container: {
        display: 'flex',
        flexDirection: 'column'
    },
    heading: {
        fontWeight: 700,
        marginBottom: '0.5em',
        marginTop: '1em'
    },
    ellipsisDetails: {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'visible'
    },
    simpleList: {
        height: '100%',
        minWidth: '35em',
    },
    textField: {
        padding: '1em',
        width: '100%',
        '& label': {
            fontWeight: 'bolder',
            padding: '1em 0 0 1em',

            fontSize: '1.4em',
            color: 'rgba(0, 0, 0, 0.8) !important'
        },
        '&::before': {
            borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
            borderBottomStyle: 'solid'
        }
    },
    listHeader: {
        paddingLeft: '1em',
        '& span': {
            fontWeight: 'bolder'
        }
    },
    toggleItem: {
        '& .MuiSwitch-root': {
            marginRight: '1em'
        },
        '& .MuiFormControlLabel-label': {
            width: '100%',
            fontWeight: 'bolder',
        }
    },
    dividerForm: {
        margin: '1em'
    },
    detailBorder: {
        border: '1px solid #e1e1e1',
        overflowY: 'scroll',
        height: '100%'
    },
    progressContainer: {
        flexGrow: 1,
        height: '100%'
    },
    mainSpinnerLabel: {
        paddingBottom: '30px',
        color: 'white',
        fontWeight: 'bolder',
        fontSize: '2em'
    },
    checkContainer: {
        marginRight: 'auto',
        marginLeft: 'auto',
        marginBottom: '1em',
        '& .MuiFormControlLabel-label': {
            fontSize: '1.5em',
            fontWeight: 'bold',
        }
    },
    saveButton: {
        marginTop: '1em',
        marginBottom: '1em'
    },
    tooltipText: {
        marginTop: '0.5em',
        fontStyle: 'italic',
        color: 'gray',
        marginBottom: '1em',
        marginLeft: '1em',
    },
    errorHeading: {
        fontWeight: 500,
        marginBottom: '0.5em',
        marginTop: '0em',
        color: theme.palette.error.main,
        fontSize: 'medium',
        textAlign: 'center',
        whiteSpace: 'break-spaces'
    },
});

interface Props extends WithStyles<typeof styles> {
    className?: string;
    currentAccount: Account;
    pickedTeam: Team | null;
    isCreate: boolean;
    moduleGroups: ModuleGroup[];
    accountModuleGroups: ModuleGroup[];
    accountFolders: Folder[];
    isAccountFoldersLoading: boolean;
    formFields: teamForm[];
    hiddenAttributes: string[];
    onFormSubmit?: (formTeam: Team, registerChanges: TeamRegisteredChanges[], intent: formIntent) => void;
}

interface States {
    teamInForm: Team;
    formList: React.ReactChild[];
    canSubmit: boolean;
    isManager: boolean;
    registeredChanges: TeamRegisteredChanges[];
    invalidSubmitIssue: string;
}

class TeamForm extends React.Component<Props, States> {

    state: States = {
        teamInForm: {} as Team,
        formList: [],
        canSubmit: false,
        isManager: false,
        registeredChanges: [],
        invalidSubmitIssue: ''
    }

    componentDidMount() {
        const { pickedTeam } = this.props;
        if (!pickedTeam) {
            return;
        }

        // Check and correctly format the email_api_config attribute to
        // add missing fields
        if (pickedTeam.email_api_config) {
            if (!pickedTeam.email_api_config.default) {
                pickedTeam.email_api_config.default = []
            }

            if (!pickedTeam.email_api_config.negative_user_feedback) {
                pickedTeam.email_api_config.negative_user_feedback = []
            }
        }
        
        this.setState({teamInForm: pickedTeam}, () => {
            this.formatTeamForm();
        });
    }

    handleStringChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, key: string) => {
        const { teamInForm, registeredChanges } = this.state;

        if (!teamInForm) {
            return;
        }

        const changeValue = e.target.value;
        _.set(teamInForm, key, changeValue);
        let existingChange = registeredChanges.find(e => e.field === key);
        if (!existingChange) {
            registeredChanges.push({
                label: this.formatFieldName(key),
                field: key,
                value: changeValue
            });
        } else {
            existingChange.value = changeValue; 
        }

        this.setState({teamInForm, canSubmit: true, registeredChanges});
    }

    handleBooleanChange = (key: string) => {
        const { teamInForm, registeredChanges } = this.state;

        if (!teamInForm) {
            return;
        }
        
        const oldValue = _.get(teamInForm, key);
        const newValue = !oldValue as boolean;

        _.set(teamInForm, key, newValue);
        let existingChange = registeredChanges.find(e => e.field === key);
        if (!existingChange) {
            registeredChanges.push({
                label: this.formatFieldName(key),
                field: key,
                value: `${newValue}`
            });
        } else {
            existingChange.value = `${newValue}`; 
        }

        this.setState({teamInForm, canSubmit: true});
    }

    formatFieldName = (teamKey: string) => {
        const lowerWords = teamKey.split("_").join(" ");
        return lowerWords.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
    }

    addObjectFormLabels = (fieldClass: string, teamInForm: Team, keyObject: object, fieldForm: React.ReactChild[]) => {
        for (const key in keyObject) {
            const keyName = this.formatFieldName(key);
            fieldForm.push(
                <Grid container spacing={1} alignItems="center">
                    <Grid item xs={1}>
                    </Grid>
                    <Grid item xs={11}>
                        <TextField
                            className={fieldClass}
                            id={`${teamInForm.team_id}_${key}`}
                            defaultValue={keyName}
                            disabled={true}
                            multiline={true}
                        />
                    </Grid>
                </Grid>
            )
        }
    }

    addObjectHeader = (fieldClass: string, headerName: string, fieldForm: React.ReactChild[]) => {
        fieldForm.push(
            <Grid container spacing={1} alignItems="center">
                <Grid item xs={1}>
                </Grid>
                <Grid item xs={11}>
                    <ListItemText className={fieldClass} primary={headerName} />
                </Grid>
            </Grid>
        );
    }

    formatTeamForm = () => {
        const {
            formFields,
            classes,
            moduleGroups,
            currentAccount,
            hiddenAttributes,
            isCreate,
            accountModuleGroups,
            accountFolders,
            isAccountFoldersLoading
        } = this.props;
        const { teamInForm } = this.state;
        const formattedFormFields: React.ReactChild[] = [];
        const teamKeys = Object.keys(teamInForm).filter(e => !hiddenAttributes.includes(e));
        if (teamKeys.includes("excluded_folders")){
            teamKeys.splice(teamKeys.indexOf("excluded_folders"), 1);
            teamKeys.push("excluded_folders");
        }
        teamKeys.forEach(key => {
            const keyValue = teamInForm[key as keyof Team];
            const keyName = this.formatFieldName(key);
            // CHECK IF THE FIELD IS EDITABLE OR NOT
            const formType = formFields.find(e => e.field === key);
            if (formType) {
                // IS EDITABLE
                // CHECK TYPE
                switch (formType.input) {
                    case 'string':
                        formattedFormFields.push(
                            <TextField
                                className={classes.textField}
                                id={`${teamInForm.team_id}_${key}`}
                                label={`${keyName} ${formType.required ? '*' : ''}`}
                                defaultValue={keyValue}
                                onChange={e => this.handleStringChange(e, key)}
                                multiline={true}
                                placeholder={keyName}
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        );
                        break;
                    case 'boolean':
                        formattedFormFields.push(
                            <FormControlLabel
                                className={classes.toggleItem}
                                id={`${teamInForm.team_id}_${key}`}
                                control={
                                    <Switch
                                        color="primary"
                                        defaultChecked={keyValue as boolean}
                                        onChange={() => this.handleBooleanChange(key)}
                                    />
                                }
                                label={`${keyName} ${formType.required ? '*' : ''}`}
                                labelPlacement="start"
                            />
                        );
                        break;
                    case 'modules':
                        formattedFormFields.push(<ListItemText className={classes.listHeader} primary={`${keyName} ${formType.required && accountModuleGroups.length > 0 ? '*' : ''}`} />);
                        this.addObjectHeader(classes.listHeader, "Read", formattedFormFields);
                        formattedFormFields.push(
                            <Grid container spacing={1} alignItems="center">
                                <Grid item xs={1}>
                                </Grid>
                                <Grid item xs={11}>
                                    <ModuleGroupForm
                                        existingModuleList={teamInForm.module_groups.read}
                                        moduleGroups={accountModuleGroups.length <= 0 ? moduleGroups : accountModuleGroups}
                                        moduleKey={"module_groups.read"}
                                        canEdit={accountModuleGroups.length > 0}
                                        onModuleChange={this.onModuleChange}
                                        registerAction={this.onModuleOperacionRegister}
                                    />
                                </Grid>
                            </Grid>
                        );
                        formattedFormFields.push(<Divider className={classes.dividerForm} />);
                        this.addObjectHeader(classes.listHeader, "Write", formattedFormFields);
                        formattedFormFields.push(
                            <Grid container spacing={1} alignItems="center">
                                <Grid item xs={1}>
                                </Grid>
                                <Grid item xs={11}>
                                    <ModuleGroupForm
                                        existingModuleList={teamInForm.module_groups.write}
                                        moduleGroups={accountModuleGroups.length <= 0 ? moduleGroups : accountModuleGroups}
                                        moduleKey={"module_groups.write"}
                                        canEdit={accountModuleGroups.length > 0}
                                        onModuleChange={this.onModuleChange}
                                        registerAction={this.onModuleOperacionRegister}
                                    />
                                </Grid>
                            </Grid>
                        );
                        formattedFormFields.push(<Divider className={classes.dividerForm} />);
                        break;
                    case 'folders':
                        formattedFormFields.push(
                            <ListItemText className={classes.listHeader} primary={`${keyName} ${formType.required ? '*' : ''}`} />  
                        );
                        if (formType.helpLabel) {
                            formattedFormFields.push(
                                <ListItemText className={classes.tooltipText} primary={`${formType.helpLabel}`} />  
                            );  
                        };
                        const excludeFolderIds:
                            | undefined
                            | string[] = formType.depedentValues
                            ? (teamInForm[
                                  formType.depedentValues as keyof Team
                              ] as string[])
                            : undefined;
                        formattedFormFields.push(
                            <Grid container spacing={1} alignItems="center">
                                <Grid item xs={1}>
                                </Grid>
                                <Grid item xs={11}>
                                    <CCSpinner
                                        loading={isAccountFoldersLoading}
                                    >
                                        <FolderTreePicker
                                            currentAccount={currentAccount}
                                            folderIds={keyValue as string[]}
                                            folderKey={key}
                                            excludeFolderIds={excludeFolderIds}
                                            onFolderchange={this.onFolderChange}
                                            accountFolders={accountFolders}
                                        />
                                    </CCSpinner>
                                </Grid>
                            </Grid>,
                            <Divider className={classes.dividerForm} />
                        ) 
                        break;
                    case 'users':
                        formattedFormFields.push(<ListItemText className={classes.listHeader} primary={`${keyName} ${formType.required ? '*' : ''}`} />);
                        formattedFormFields.push(
                            <Grid container spacing={1} alignItems="center">
                                <Grid item xs={1}>
                                </Grid>
                                <Grid item xs={11}>
                                    <UserListPicker
                                        existingUserList={keyValue as userComponent}
                                        userKey={key}
                                        canEdit={true}
                                        onUserChange={this.onUserChange}
                                    />
                                </Grid>
                            </Grid>
                        ) 
                        break;
                    case 'select':
                        formattedFormFields.push(<ListItemText className={classes.listHeader} primary={`${keyName} ${formType.required ? '*' : ''}`} />);
                        formattedFormFields.push(
                            <Grid container spacing={1} alignItems="center">
                                <Grid item xs={1}>
                                </Grid>
                                <Grid item xs={11}>
                                    <SimpleSelectInput
                                        formCurrentValue={keyValue as string[]}
                                        formKey={key}
                                        formOptions={formType.items!}
                                        canEdit={true}
                                        onValueChange={this.onSelectChange}
                                    />
                                </Grid>
                            </Grid>,
                            <Divider className={classes.dividerForm} />
                        )
                        break;
                    case 'email':
                        formattedFormFields.push(<ListItemText className={classes.listHeader} primary={`${keyName} ${formType.required ? '*' : ''}`} />);
                        formattedFormFields.push(
                            <Grid container spacing={1} alignItems="center">
                                <Grid item xs={1}>
                                </Grid>
                                <Grid item xs={11}>
                                    <EmailConfigForm
                                        emailCurrentValue={keyValue as Team['email_api_config']}
                                        canEdit={true}
                                        emailKey={key}
                                        onEmailChange={this.onEmailChange}
                                    />
                                </Grid>
                            </Grid>,
                            <Divider className={classes.dividerForm} />
                        )
                        break;
                    default:
                        break;
                }
            } else {
                // NOT EDITABLE
                if (typeof keyValue === 'string' || typeof keyValue === 'number') {
                    formattedFormFields.push(
                        <TextField
                            className={classes.textField}
                            id={`${teamInForm.team_id}_${key}`}
                            label={keyName}
                            defaultValue={keyValue !== '' ? keyValue : '-'}
                            disabled={true}
                            multiline={true}
                        />
                    );
                } else if (Array.isArray(keyValue)) {
                    // ADD THE HEADER
                    formattedFormFields.push(
                        <ListItemText className={classes.listHeader} primary={keyName} />
                    );
                    if (keyValue.length <= 0) {
                        formattedFormFields.push(
                            <Grid container spacing={1} alignItems="center">
                                    <Grid item xs={1}>
                                    </Grid>
                                    <Grid item xs={11}>
                                        <TextField
                                            className={classes.textField}
                                            id={`${teamInForm.team_id}_${key}`}
                                            defaultValue={'No elements'}
                                            disabled={true}
                                            multiline={true}
                                        />
                                    </Grid>
                            </Grid>
                        );
                    } else {
                        keyValue.forEach(element => {
                            formattedFormFields.push(
                                <Grid container spacing={1} alignItems="center">
                                    <Grid item xs={1}>
                                    </Grid>
                                    <Grid item xs={11}>
                                        <TextField
                                            className={classes.textField}
                                            id={`${teamInForm.team_id}_${key}`}
                                            defaultValue={element}
                                            disabled={true}
                                            multiline={true}
                                        />
                                    </Grid>
                                </Grid>
                            );
                        });
                    }
                } else if (typeof keyValue === 'boolean') {
                    formattedFormFields.push(
                        <FormControlLabel
                            className={classes.toggleItem}
                            id={`${teamInForm.team_id}_${key}`}
                            control={<Switch color="primary" disabled={true} />}
                            label={keyName}
                            labelPlacement="start"
                        />
                    );
                } else if (typeof keyValue === 'object') {
                    formattedFormFields.push(<ListItemText className={classes.listHeader} primary={keyName} />);
                    switch (key) {
                        case "actions":
                            this.addObjectHeader(classes.listHeader, "Access", formattedFormFields);
                            this.addObjectFormLabels(classes.textField, teamInForm, teamInForm.actions.access, formattedFormFields);

                            this.addObjectHeader(classes.listHeader, "Common", formattedFormFields);
                            this.addObjectFormLabels(classes.textField, teamInForm, teamInForm.actions.common, formattedFormFields);

                            this.addObjectHeader(classes.listHeader, "Meta", formattedFormFields);
                            this.addObjectFormLabels(classes.textField, teamInForm, teamInForm.actions.meta, formattedFormFields);
                            break;
                        case "module_groups":
                            this.addObjectHeader(classes.listHeader, "Read", formattedFormFields);
                            formattedFormFields.push(
                                <Grid container spacing={1} alignItems="center">
                                    <Grid item xs={1}>
                                    </Grid>
                                    <Grid item xs={11}>
                                        <ModuleGroupForm
                                            existingModuleList={teamInForm.module_groups.read}
                                            moduleGroups={moduleGroups}
                                            moduleKey={"module_groups.read"}
                                            canEdit={false}
                                        />
                                    </Grid>
                                </Grid>
                            );

                            this.addObjectHeader(classes.listHeader, "Write", formattedFormFields);
                            formattedFormFields.push(
                                <Grid container spacing={1} alignItems="center">
                                    <Grid item xs={1}>
                                    </Grid>
                                    <Grid item xs={11}>
                                        <ModuleGroupForm
                                            existingModuleList={teamInForm.module_groups.write}
                                            moduleGroups={moduleGroups}
                                            moduleKey={"module_groups.write"}
                                            canEdit={false}
                                        />
                                    </Grid>
                                </Grid>
                            );

                            break;
                        case "members":
                            formattedFormFields.push(
                                <Grid container spacing={1} alignItems="center">
                                    <Grid item xs={1}>
                                    </Grid>
                                    <Grid item xs={11}>
                                        <UserListPicker
                                            existingUserList={keyValue as userComponent}
                                            userKey={key}
                                            canEdit={false}
                                        />
                                    </Grid>
                                </Grid>
                            );
                            break;
                        default:
                            break;
                    }
                }
            }
        });
        if (isCreate) {
            formattedFormFields.push(
                <FormControlLabel
                    className={`${classes.checkContainer}`}
                    control={
                        <Checkbox
                            onChange={this.onManagerChange}
                            name="checkManager"
                            color="primary"
                        />
                    }
                    label="Is this a manager team?"
                    labelPlacement="start"
                />
            );
        }
        this.setState({formList: formattedFormFields});
    }

    onModuleChange = (moduleKey: string, moduleList: {[key: string]: boolean}) => {
        const { teamInForm } = this.state;
        if (!teamInForm) {
            return;
        }

        _.set(teamInForm, moduleKey, moduleList);
        this.setState({teamInForm, canSubmit: true});
    }

    onFolderChange = (folderKey: string, folderList: string[], intent: FolderActionIntent): string[] => {
        const { teamInForm, registeredChanges } = this.state;
        if (!teamInForm) {
            return folderList;
        }
        const existingFolderIds = teamInForm[folderKey as keyof Team] as string[];
        let clearedFolderList = folderList;
        const removedFolders: string[] = [];
        if (intent === 'ADD') {
            switch (folderKey) {
                case "folders":
                    if (teamInForm.excluded_folders) {
                        clearedFolderList = folderList.map(e => {
                            if (teamInForm.excluded_folders.includes(e)) {
                                removedFolders.push(e);
                                return '';
                            }
    
                            return e;
                        }).filter(e => e !== '');
    
                        if (clearedFolderList.length <= 0) {
                            return folderList;
                        }
                    }
                    break;
                
                case "excluded_folders":
                    clearedFolderList = folderList.map(e => {
                        if (teamInForm.folders.includes(e)) {
                            removedFolders.push(e);
                            return '';
                        }
    
                        return e;
                    }).filter(e => e !== '');
    
                    if (clearedFolderList.length <= 0) {
                        return folderList;
                    }
                    break;
                
                default:
                    break;
            }
            
            clearedFolderList = Array.from(new Set([...clearedFolderList, ...existingFolderIds]))
        } else {
            clearedFolderList = Array.from(new Set([...existingFolderIds.filter(e => !clearedFolderList.includes(e))]));
        }

        _.set(teamInForm, folderKey, clearedFolderList);
        let existingChange = registeredChanges.find(e => e.field === folderKey);
        if (!existingChange) {
            registeredChanges.push({
                label: this.formatFieldName(folderKey),
                field: folderKey,
                value: folderList.toString()
            });
        } else {
            existingChange.value = folderList.toString(); 
        }
        this.setState({teamInForm, canSubmit: true});
        return removedFolders;
    }

    onUserChange = (userKey: string, userList: userComponent) => {
        const { teamInForm } = this.state;
        if (!teamInForm) {
            return;
        }

        _.set(teamInForm, userKey, userList);
        this.setState({teamInForm, canSubmit: true});
    }

    onSelectChange = (formKey: string, itemList: string[]) => {
        const { teamInForm, registeredChanges } = this.state;
        if (!teamInForm) {
            return;
        }

        _.set(teamInForm, formKey, itemList);
        let existingChange = registeredChanges.find(e => e.field === formKey);
        if (!existingChange) {
            registeredChanges.push({
                label: this.formatFieldName(formKey),
                field: formKey,
                value: itemList.toString()
            });
        } else {
            existingChange.value = itemList.toString(); 
        }
        this.setState({teamInForm, canSubmit: true});
    }

    onEmailChange = (emailKey: string, emailValue: Team['email_api_config']) => {
        const { teamInForm, registeredChanges } = this.state;
        if (!teamInForm) {
            return;
        }

        _.set(teamInForm, emailKey, emailValue);
        let existingChange = registeredChanges.find(e => e.field === emailKey);
        if (!existingChange) {
            registeredChanges.push({
                label: this.formatFieldName(emailKey),
                field: emailKey,
                value: 'UPDATED'
            });
        } else {
            existingChange.value = 'UPDATED'; 
        }
        this.setState({teamInForm, canSubmit: true});
    }

    onManagerChange = () => {
        const { isManager, teamInForm } = this.state;

        if (isManager === true) {
            _.set(teamInForm, 'actions', EMPLOYEE_DEFAULT_ACTIONS);
        } else {
            _.set(teamInForm, 'actions', MANAGER_DEFAULT_ACTIONS);
        }
        
        this.setState({isManager: !isManager});
    }

    onSubmitChanges = () => {
        const { onFormSubmit, isCreate } = this.props;
        const { teamInForm, registeredChanges } = this.state;
        if (!onFormSubmit) {
            return;
        }

        if (this.checkIfTeamValid()){
            return;
        }

        onFormSubmit(teamInForm, registeredChanges, isCreate ? 'CREATE' : 'EDIT');
        return;
    }

    onModuleOperacionRegister = (module: string, operation: string, moduleKey: string) => {
        const { registeredChanges } = this.state;

        registeredChanges.push({
            field: moduleKey,
            label: this.formatFieldName(moduleKey),
            value: `${operation}: ${module}`
        });

        this.setState({registeredChanges});
    }

    checkIfTeamValid = () => {
        const { teamInForm } = this.state;

        if (!teamInForm.name) {
            return true;
        }

        if (teamInForm.folders.length <= 0) {
            return true;
        }

        if (!teamInForm.access_code) {
            return true;
        }

        return false;
    }

    getInvalidTeamMessage = () => {
        const { teamInForm } = this.state;
        let message = ''
        if (!teamInForm.name) {
            message += 'Missing Name' + '\n';
        }

        if (teamInForm.folders.length <= 0) {
            message += 'Folders cannot be empty' + '\n';
        }

        if (!teamInForm.access_code) {
            message += 'Missing Access Code' + '\n';
        }

        return message;
    }

    public render() {
        const {
            className,
            classes,
            isCreate,
            pickedTeam
        } = this.props;
        const {
            formList,
            canSubmit
        } = this.state;

        return (
            <div className={`${classes.root} ${className}`} data-testid="team-form">
                <div className={`${classes.container} ${classes.simpleList}`}>
                    <Typography
                        variant='h6'
                        className={`${classes.heading}`}
                    >
                        { isCreate ? 'Creating a new Team' : `Editing: ${pickedTeam!.name}` }
                    </Typography>
                    { canSubmit && 
                        <React.Fragment>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={this.onSubmitChanges}
                                className={classes.saveButton}
                                disabled={this.checkIfTeamValid()}
                            >
                                { isCreate ? 'Create Team' : 'Save Changes' }
                            </Button>
                            {
                                this.checkIfTeamValid() &&
                                    <Typography
                                        variant='h6'
                                        className={classes.errorHeading}
                                    >
                                        {this.getInvalidTeamMessage()}
                                    </Typography>
                            }
                        </React.Fragment>
                    }
                    <Paper className={`${classes.container} ${classes.detailBorder}`}>
                        { formList }
                    </Paper>
                </div>
            </div>
        );
    }
}

const MUIComponent = withStyles(styles)(TeamForm);
export {MUIComponent as TeamForm}
