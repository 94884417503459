import {
    Button,
    Grid,
    TextField
} from '@material-ui/core';
import {
    createStyles,
    WithStyles,
    withStyles
} from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { Publish } from '@material-ui/icons';
import memoize from 'memoize-one';
import * as React from 'react';
import { CsvMarker } from '../../models';
import { CSVButton } from '../../shared/components/csv-button';
import { ExportMarkersButton } from '../export-markers-button';
import { 
    TransferList,
    TransferListItem
} from '../transfer-list';

export interface MarkersListItem {
    id: string;
    name: string;
}

const styles = (theme: Theme) => createStyles({
    root: {
        justifyContent: 'center'
    },
    leftPanel: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1
    },
    genericButton: {
        alignSelf: 'flex-start',
        marginTop: '1.45em'
    },
    fontSize: {
        maxWidth: '5em'
    },
    transferList: {
        justifyContent: 'flex-start'
    },
    startOverButton: {
        marginTop: '0.8em',
        marginLeft: '4%'
    },
    csvButtonContainer: {
        display: 'flex',
        flexDirection: 'row',
        '& > div': {
            marginRight: '1em'
        }
    },
    csvButton: {
        margin: '0.5em 0 0 0'
    },
    csvButtonButton: {
        fontSize: '0.8em',
        padding: '0.3em 0.6em 0.3em 0.2em'
    },
    importIcon: {
        fontSize: '1.6em',
        marginRight: '0.1em'
    }
});

interface Props extends WithStyles<typeof styles> {
    loading: boolean;
    markers: MarkersListItem[];
    onCSVImport?: (markers: MarkersListItem[]) => void;
    onLoad?: (isLoading: boolean, progress: number, progressLabel: string) => void;
    onClearMarkers?: () => void;
    onSubmit: (markers: MarkersListItem[], qrTemplateFile: File, qrPositionFile: File, fontSize: number) => void;
};

interface States {
    availableMarkers: TransferListItem[];
    markersToProcess: TransferListItem[];
    qrTemplateFile: File | undefined;
    qrPositionFile: File | undefined;
    fontSize: number;
}

class QrCodeGenerator extends React.Component<Props, States> {
    public static defaultProps = {
        loading: false
    };

    convertTransferListItemIntoMarkers = memoize((markers: TransferListItem[]): MarkersListItem[] =>{
        return markers.map((listItem: TransferListItem):  MarkersListItem => {
            const {
                id,
                label: name
            } = listItem;

            return {
                id,
                name
            } as MarkersListItem;
        }) as MarkersListItem[];
    });

    convertMarkersIntoTransferListItem = memoize((markers: MarkersListItem[]): TransferListItem[] =>{
        return markers.map((marker: MarkersListItem): TransferListItem => {
            const {
                id,
                name: label
            } = marker;

            return {
                id,
                label
            } as TransferListItem;
        }) as TransferListItem[];
    });

    state = {
        availableMarkers: this.convertMarkersIntoTransferListItem(this.props.markers),
        markersToProcess: [] as TransferListItem[],
        qrTemplateFile: undefined as File | undefined,
        qrPositionFile: undefined as File | undefined,
        fontSize: NaN
    };
    
    componentDidUpdate(prevProps: Readonly<Props>) {
        if (this.props.markers !== prevProps.markers) {
            this.setState({
                availableMarkers: this.convertMarkersIntoTransferListItem(this.props.markers),
                markersToProcess: [] as TransferListItem[]
            })
          }
    }

    setLoading = (isLoading: boolean, progress: number, progressLabel: string) => {
        const { onLoad } = this.props;
        if (onLoad) {
            onLoad(isLoading, progress, progressLabel);
        }
    }

    onFileChanged = (type: 'qrPositionFile' | 'qrTemplateFile') => (e: any) => {
        const fileName = e.target.files[0];
        const newState: {qrPositionFile?: File, qrTemplateFile?: File} = {};
        newState[type] = fileName;
        this.setState(newState as States);
    };

    onSendClicked = () => {
        const { onSubmit} = this.props;
        const {
            markersToProcess,
            qrTemplateFile,
            qrPositionFile,
            fontSize
        } = this.state;
        
        this.setLoading(true, 0, '');

        if ((markersToProcess.length > 0) && qrTemplateFile && qrPositionFile) {
            const markers = this.convertTransferListItemIntoMarkers(markersToProcess);
            onSubmit(markers, qrTemplateFile, qrPositionFile, fontSize);
        }
    };

    onTransferListItemsChanged = (leftItems: TransferListItem[], rightItems: TransferListItem[]) => {
        this.setState({
            availableMarkers: leftItems,
            markersToProcess: rightItems
        });
    };

    onClearMarkersClicked = () =>{
        const { onClearMarkers } = this.props;
        if (onClearMarkers) {
            onClearMarkers();
        }
    };

    onFontSizeChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        const fontSize = parseInt(event.target.value, 10);
        this.setState({ fontSize });
    }
    
    onCSVImportLoading = (isLoading: boolean) => {
        this.setLoading(isLoading, isLoading ? 0 : 100, 'Importing CSV');
    }

    onCSVImportClicked = (data: any[]) => {
        const { onCSVImport } = this.props;
        if (!onCSVImport) {
            return;
        }

        const markers = data.map((element) => {
            const {
                marker_id: id,
                marker_name: name
            } = element as CsvMarker;
            return {id, name} as MarkersListItem;
        });

        onCSVImport(markers);
    };

    public render() {
        const {
            classes,
            loading,
            markers
        } = this.props;
        const {
            availableMarkers,
            markersToProcess,
            qrTemplateFile,
            qrPositionFile,
            fontSize: fontSizeRaw
        } = this.state;
        const isDisabled = (markers.length < 1) || loading;
        const fontSize = isNaN(fontSizeRaw) ? '' : `${fontSizeRaw}`;

        return (
            <Grid className={classes.root} container={true}>
                <Grid item={true} xs={9}>
                    <TransferList
                        className={classes.transferList}
                        showIds={true}
                        leftTitle="Available Markers"
                        leftItems={availableMarkers}
                        rightTitle="Markers to Process"
                        rightItems={markersToProcess}
                        onItemsChange={this.onTransferListItemsChanged}
                    />
                    <div className={classes.csvButtonContainer}>
                        <CSVButton
                            className={classes.csvButton}
                            classes={{
                                button: classes.csvButtonButton
                            }}
                            variant="contained"
                            color="secondary"
                            importData={true}
                            onLoading={this.onCSVImportLoading}
                            onCSVImport={this.onCSVImportClicked}
                        >
                            <Publish className={classes.importIcon} />
                            Import CSV
                        </CSVButton>
                        <ExportMarkersButton
                            className={classes.csvButton}
                            markers={markers}
                        />
                    </div>
                </Grid>
                <Grid
                    className={classes.leftPanel}
                    item={true}
                    xs={2}
                >
                    <h4>Template File:</h4>
                    <input
                        type="file"
                        id="template-file"
                        className="input-file"
                        data-testid="template-file"
                        accept=".pdf"
                        disabled={isDisabled}
                        onChange={this.onFileChanged('qrTemplateFile')}
                    />
                    <TextField
                        label="Font Size"
                        placeholder="Template"
                        className={classes.fontSize}
                        value={fontSize}
                        disabled={isDisabled}
                        onChange={this.onFontSizeChanged}
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <h4>QR Code Position File:</h4>
                    <input
                        type="file"
                        id="position-file"
                        data-testid="position-file"
                        className="input-file"
                        accept=".xfdf"
                        disabled={isDisabled}
                        onChange={this.onFileChanged('qrPositionFile')}
                    />
                    <Button
                        className={classes.genericButton}
                        variant="contained"
                        color="primary"
                        disabled={(isDisabled || (markersToProcess.length < 1) || !qrTemplateFile || !qrPositionFile)}
                        onClick={this.onSendClicked}
                    >
                    { loading ? 'Processing' : 'Process' }
                    </Button>
                </Grid>
                <Grid item={true} xs={12}>
                    <Button
                        className={`${classes.genericButton} ${classes.startOverButton}`}
                        variant="contained"
                        color="secondary"
                        onClick={this.onClearMarkersClicked}
                    >
                        Clear Markers
                    </Button>
                </Grid>
            </Grid>
        );
    }
};

const MUIComponent = withStyles(styles)(QrCodeGenerator);
export { MUIComponent as QrCodeGenerator };
