import { Button, IconButton, Paper, TextField, Typography } from '@material-ui/core';
import {
    createStyles,
    WithStyles,
    withStyles
} from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { AddOutlined, ClearOutlined, SearchOutlined } from '@material-ui/icons';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { CCSpinner } from '../../shared/components/cc-spinner';
import { PageBoundary } from '../../shared/components/simple-grid-pagination';
import { SimpleListItem } from '../../shared/components/simple-list';
import { SimpleListPagination } from '../../shared/components/simple-list-pagination';
import { Account, Team } from '../../shared/domain';

const styles = (theme: Theme) => createStyles({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    container: {
        display: 'flex',
        flexDirection: 'column'
    },
    heading: {
        fontWeight: 700,
        marginBottom: '0.5em',
        marginTop: '1em'
    },
    ellipsis: {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden'
    },
    simpleList: {
        overflow: 'auto',
        height: '100%',
        minWidth: '25em',
        maxWidth: '25em'
    },
    simpleListAccounts: {
        marginRight: '1.5em',
    },
    noAccountContainer: {
        minWidth: '30em',
        height: '90%',
        fontWeight: 'bold',
         '& > *': {
            color: 'rgba(0,0,0,0.2)'
        }
    },
    teamSpinnerContainer: {
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '3em',
        marginBottom: '4em',
        borderRadius: '0.4em'
    },
    newTeam: {
        marginTop: '2em'
    },
    searchInputContainer: {
        marginBottom: '1em'
    },
    searchInput: {
        width: '100%',
        paddingLeft: '1em'
    },
});

interface Props extends WithStyles<typeof styles> {
    className?: string;
    accounts?: Account[] | null;
    accountsIsLoading: boolean;
    currentAccountName?: string;
    accountsTotalItems?: number;
    accountsItemsOffset: number;
    rowsPerPage: number;
    onAccountClick?: (account: Account) => void;
    onAccountDataPageOverBoundary?: (boundary: PageBoundary, nextPage: number) => Promise<void>;
    teams: Team[] | null;
    totalTeams: number;
    isTeamsLoading: boolean;
    currentTeam: Team | null;
    currentTeamOffset: number;
    onTeamClick?: (team: Team) => void;
    onTeamDataPageOverBoundary?: (boundary: PageBoundary, nextPage: number) => Promise<void>;
    startAddTeam?: () => void;
    onAccountSearch?: (searchName: string) => void;
};

interface States {
    accountNameSearch: string;
};

@inject('rootStore')
@observer
class AccountTeams extends React.Component<Props, States> {
    public static defaultProps: Partial<Props> = {
    };

    state = {
        accountNameSearch: ''
    };

    onAccountClicked = (listItem: SimpleListItem) => {
        const {accounts, onAccountClick} = this.props;
        if(!accounts) {
            return;
        }

        const currentAccount = accounts.find((account:Account) => {
            return account.accountId === listItem.id;
        });
        if (!currentAccount) {
            return;
        }

        if (onAccountClick) {
            onAccountClick(currentAccount);
        }
    };

    onTeamClicked = (listItem: SimpleListItem) => {
        const {teams, onTeamClick} = this.props;
        if(!teams) {
            return;
        }

        const currentTeam = teams.find((team: Team) => {
            return team.team_id === listItem.id;
        });
        if (!currentTeam) {
            return;
        }

        if (onTeamClick) {
            onTeamClick(currentTeam);
        }
    };



    onAccountNameSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        const searchValue = event.target.value;

        this.setState({
            accountNameSearch: searchValue
        });
    };

    onAccountNameKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        const { accountNameSearch } = this.state;
        const { onAccountSearch } = this.props;

        if (!onAccountSearch) {
            return;
        }

        if (event.key === 'Delete' || event.key === 'Backspace') {
            if (accountNameSearch === '') {
                onAccountSearch('');
                return;
            }
        }

        if (event.key === 'Enter') {
            onAccountSearch(accountNameSearch);
            return;
        }
    };

    onButtonSearch = () => {
        const { accountNameSearch } = this.state;
        const { onAccountSearch } = this.props;

        if (!onAccountSearch) {
            return;
        }

        onAccountSearch(accountNameSearch);
    }

    onCancelSearch = () => {
        const { onAccountSearch } = this.props;

        if (!onAccountSearch) {
            return;
        }

        this.setState({ accountNameSearch: '' }, () => {
            onAccountSearch('');
        });
    }

    public render() {
        const {
            className,
            classes,
            accounts,
            currentAccountName,
            accountsIsLoading,
            accountsTotalItems,
            accountsItemsOffset,
            rowsPerPage,
            onAccountDataPageOverBoundary,
            teams,
            totalTeams,
            isTeamsLoading,
            currentTeamOffset,
            onTeamDataPageOverBoundary,
            startAddTeam
        } = this.props;

        const {
            accountNameSearch
        } = this.state;

        const accountListItems = accounts ? accounts.map((account: Account) => {
            const accountId = account.accountId;
            return new SimpleListItem(accountId, account.name, accountId);
        }) : [];
        const teamListItems = teams ? teams.map(((team, index) => {
            const teamId = team.team_id;
            return new SimpleListItem(teamId, team.name, teamId);
        })) : [];

        return (
            <div className={`${classes.root} ${className}`} data-testid="account-team-picker">
                <div className={`${classes.container} ${classes.simpleListAccounts}`}>
                    <Typography
                        variant='h6'
                        className={`${classes.heading} ${classes.ellipsis}`}
                    >
                        Customer Organizations
                    </Typography>
                    <Paper className={classes.searchInputContainer}>
                        <TextField
                            data-testid="account-searchbar"
                            className={classes.searchInput}
                            type="text"
                            placeholder="Search by name"
                            variant="standard"
                            multiline={false}
                            value={accountNameSearch}
                            InputProps={{
                                disableUnderline: true,
                                endAdornment: (
                                    <React.Fragment>
                                        {
                                            accountNameSearch !== '' &&
                                                <IconButton
                                                    aria-label="cancel"
                                                    data-testid="cancel-search"
                                                    onClick={this.onCancelSearch}
                                                >
                                                    <ClearOutlined />
                                                </IconButton>
                                        }
                                        <IconButton
                                            aria-label="search"
                                            data-testid="commit-search"
                                            onClick={this.onButtonSearch}
                                        >
                                            <SearchOutlined />
                                        </IconButton>
                                    </React.Fragment>
                                )
                            }}
                            onChange={this.onAccountNameSearch}
                            onKeyDown={this.onAccountNameKeyDown}
                        />
                    </Paper>
                    <SimpleListPagination
                        className={classes.simpleList}
                        keepItemSelected={true}
                        items={accountListItems}
                        isLoading={accountsIsLoading}
                        onListItemClick={this.onAccountClicked}
                        rowsPerPage={rowsPerPage}
                        totalItems={accountsTotalItems}
                        offset={accountsItemsOffset}
                        onPageOverBoundary={onAccountDataPageOverBoundary}
                        noItemsLabel='No accounts found'
                    />
                </div>
                <div>
                    {
                        currentAccountName &&
                            <Button
                                variant="contained"
                                color="secondary"
                                className={classes.newTeam}
                                startIcon={<AddOutlined />}
                                onClick={startAddTeam}
                            >
                                Create Team
                            </Button>
                    }
                    { teams && (teams.length > 0) ?
                            <div className={`${classes.container} ${classes.simpleListAccounts}`} data-testid="teamPicker">
                                <Typography
                                    variant='h6'
                                    className={`${classes.heading} ${classes.ellipsis}`}
                                >
                                    Teams on {currentAccountName}
                                </Typography>
                                <SimpleListPagination
                                    className={classes.simpleList}
                                    keepItemSelected={true}
                                    isLoading={isTeamsLoading}
                                    items={teamListItems}
                                    offset={currentTeamOffset}
                                    onListItemClick={this.onTeamClicked}
                                    onPageOverBoundary={onTeamDataPageOverBoundary}
                                    rowsPerPage={rowsPerPage}
                                    totalItems={totalTeams}
                                />
                                
                            </div>
                            :
                            <Paper className={`${classes.container} ${classes.simpleListAccounts} ${classes.teamSpinnerContainer} ${(!isTeamsLoading ? classes.noAccountContainer : '')}`}>
                                <CCSpinner
                                    loading={isTeamsLoading}
                                    size={70}
                                >
                                    <Typography variant={!teams ? 'h4' : 'h5'}>
                                        {
                                            !teams || !currentAccountName ? 'No organization selected' : `No teams for ${currentAccountName ? currentAccountName : 'the selected account'}`
                                        }
                                    </Typography>
                                </CCSpinner>
                            </Paper>
                    }
                </div>
            </div>
        );
    }
}

const MUIComponent = withStyles(styles)(AccountTeams);
export {MUIComponent as AccountTeams}