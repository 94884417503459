import { RouterState, RouterStore } from 'mobx-state-router';
import LoginProvider from '../providers/login.provider';
import { AdminStore } from './admin.store';
import { RootStoreBase } from './root.store.base';
import { ROUTE_NAMES, routes } from './routes';
import { StoreProviders } from './store-providers';

const notFound = new RouterState(ROUTE_NAMES.NOT_FOUND);

export class RootStore implements RootStoreBase{
    routerStore: RouterStore = new RouterStore(this, routes, notFound);
    adminStore: AdminStore = new AdminStore(this);

    // Adapters for use by all stores
    providers: StoreProviders  = {
        loginProvider: new LoginProvider(),
    };

    // ----- Life cycle hooks -----
    // Useful for starting and stopping observers, autoruns and reactions

    init() {}

    destroy() {}
}
