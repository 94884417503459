import {
    Paper,
    Typography
} from '@material-ui/core';
import {
    createStyles,
    WithStyles,
    withStyles
} from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import * as React from 'react';
import { AccountFull } from '../../models/account';
import { ModuleGroup } from '../../models/module-group';
import { PageBoundary } from '../../shared/components/simple-grid-pagination';
import { SimpleListItem } from '../../shared/components/simple-list';
import { SimpleListPagination } from '../../shared/components/simple-list-pagination';

const styles = (theme: Theme) => createStyles({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    container: {
        display: 'flex',
        flexDirection: 'column'
    },
    heading: {
        fontWeight: 700,
        marginBottom: '0.5em',
        marginTop: '1em'
    },
    ellipsis: {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden'
    },
    simpleList: {
        overflow: 'auto',
        height: '100%',
        minWidth: '20em'
    },
    simpleListModules: {
        width:'22em'
    },
    noModulesContainer: {
        width:'22em',
        fontWeight: 'bold',
        textAlign: 'center',
         '& > *': {
            color: 'rgba(0,0,0,0.2)'
        }
    },
    paperEmpty: {
        padding:'2em'
    }
});

interface Props extends WithStyles<typeof styles> {
    className?: string;
    moduleGroups?: ModuleGroup[] | null;
    accounts?: AccountFull[];
    modulesIsLoading: boolean;
    modulesTotalItems?: number;
    modulesItemsOffset: number;
    rowsPerPage: number;
    onModuleClick?: (moduleGroup: ModuleGroup) => void ;
    onModuleDataPageOverBoundary?: (boundary: PageBoundary, nextPage: number) => Promise<void>;
};

class ModuleGroupPicker extends React.Component<Props> {
    public static defaultProps = {
        currentAccountName: '',
        modulesItemsOffset: 0
    };

    onModuleGroupClicked = (listItem: SimpleListItem) => {
        const {moduleGroups, onModuleClick} = this.props;
        if(!moduleGroups) {
            return;
        }

        const currentModuleGroup = moduleGroups.find((moduleGroup:ModuleGroup) => {
            return moduleGroup.module_group_id === listItem.id;
        });
        if (!currentModuleGroup) {
            return;
        }

        if (onModuleClick) {
            onModuleClick(currentModuleGroup);
        }
    };

    public render() {
        const {
            className,
            classes,
            moduleGroups,
            modulesIsLoading,
            modulesTotalItems,
            modulesItemsOffset,
            rowsPerPage,
            onModuleDataPageOverBoundary,
        } = this.props;

        const moduleListItems = moduleGroups ? moduleGroups.map((moduleGroup: ModuleGroup) => {
            return new SimpleListItem(moduleGroup.module_group_id, moduleGroup.name, `Account: ${moduleGroup.account_name}`);
        }) : [];

        if (moduleGroups && (moduleGroups.length > 0)) {
            return (
                <div className={`${classes.root} ${className}`} data-testid="module-group-picker">
                    <div className={`${classes.container} ${classes.simpleListModules}`}>
                        <Typography
                            variant='h6'
                            className={`${classes.heading} ${classes.ellipsis}`}
                        >
                            Registered Module Groups
                        </Typography>
                        <SimpleListPagination
                            className={classes.simpleList}
                            keepItemSelected={true}
                            items={moduleListItems}
                            isLoading={modulesIsLoading}
                            onListItemClick={this.onModuleGroupClicked}
                            rowsPerPage={rowsPerPage}
                            totalItems={modulesTotalItems}
                            offset={modulesItemsOffset}
                            onPageOverBoundary={onModuleDataPageOverBoundary}
                        />
                    </div>
                </div>
            );
        } else {
            return (
                <div className={`${classes.root} ${classes.noModulesContainer}`}>
                    <Paper className={classes.paperEmpty}>
                        <Typography
                            variant='h4'
                        >
                            No Module Groups found
                        </Typography>
                    </Paper>
                </div>
            );
        }
    }
}

const MUIComponent = withStyles(styles)(ModuleGroupPicker);
export {MUIComponent as ModuleGroupPicker}