import * as React from 'react';

import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TableSortLabel,
    Typography
} from '@material-ui/core';
import {
    createStyles,
    WithStyles,
    withStyles
} from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import memoize from 'memoize-one';
import { CCSpinner } from '../cc-spinner';
import { SimpleGridCellDescriptor } from './simple-grid-cell-descriptor';
import { SimpleGridCellType } from './simple-grid-cell-type';
import { 
    SIMPLE_GRID_ORDER_ASC,
    SIMPLE_GRID_ORDER_DESC,
    SimpleGridOrder
} from './simple-grid-order-type';
import { SimpleGridRowDescriptor } from './simple-grid-row-descriptor';
import { SimpleGridUtilities } from './simple-grid-utilities';

const LOADING_TEXT = 'Loading ...';
const GRID_HEADER_ID = 'header';

const styles = (theme: Theme) => createStyles({
    root: {
        position: 'relative'
    },
    table: {
        '& td, & th':{
            padding: '1em'
        }
    },
    row: {},
    emptyHeader: {
        backgroundColor: theme.ccPalette.disabled.light
    },
    emptyRow: {
        '& $emptyCell': {
            border: 0,
            padding: 0
        }
    },
    emptyCell: {},
    spinner: {
        position: 'absolute',
        backgroundColor: theme.ccPalette.disabled.light,
        width: '100%',
        height: '100%',
        zIndex: 1
    },
    noItemsLabel:{
        color: theme.ccPalette.disabled.main,
        textAlign: 'center',
        padding: '2em'
    }
});

export interface SimpleGridProps {
    headersData?: SimpleGridCellDescriptor[];
    rowsData: SimpleGridRowDescriptor[];
    enableSorting: boolean;
    isLoading: boolean;
    order: SimpleGridOrder;
    orderBy?: string;
    noItemsLabel?: string;
    showHeaders: boolean;
    onRowClick?: (rowDescriptor: SimpleGridRowDescriptor) => void;
    onSortClick?: (cellDescriptor: SimpleGridCellDescriptor, order: SimpleGridOrder) => void;
};

interface Props extends SimpleGridProps, WithStyles<typeof styles> {
    className?: string;
};

interface States {
    orderBy?: string;
    order: SimpleGridOrder;
}

class SimpleGrid extends React.Component<Props, States> {
    public static defaultProps = {
        enableSorting: false,
        isLoading: false,
        order: SIMPLE_GRID_ORDER_ASC as SIMPLE_GRID_ORDER_ASC,
        showHeaders: true
    };

    generateGridRows = memoize((rowsData: SimpleGridRowDescriptor[], hover:boolean = false, headersCells?: SimpleGridCellDescriptor[]): React.ReactNode[] => {
        if (!rowsData || (rowsData.length < 1)) {
            return [];
        }
        return rowsData.map((row: SimpleGridRowDescriptor, index): React.ReactNode => {
            const { id: itemId } = row;
            const rows: React.ReactNode[] = this.generateRowCells(row.cells, headersCells);
            const isHeaderEmpty = (!headersCells && (!rows || (rows.length < 1)));
            const { classes } = this.props;

            return (
                <TableRow
                    className={isHeaderEmpty ? classes.emptyHeader : classes.row}
                    key={`${itemId}-${index}`}
                    data-id={itemId}
                    hover={hover ? true : undefined}
                    onClick={this.onGridClicked}
                >
                    {
                        isHeaderEmpty ? <TableCell colSpan={1000}><span>{'\u00a0'}</span></TableCell> : rows
                    }
                </TableRow>
            );
        });
    });
    
    constructor(props: Readonly<Props>) {
        super(props);

        const { 
            order,
            orderBy
        } = props;

        this.state = {
            order,
            orderBy,
        };
    }

    getDescriptorFromId = (id: string, isHeaderRow: boolean): SimpleGridCellDescriptor | SimpleGridRowDescriptor | null => {
        const {
            headersData,
            rowsData
        } = this.props;
        if (!id && !headersData) {
            return null;
        }
     
        const descriptors: Array<SimpleGridCellDescriptor | SimpleGridRowDescriptor> =
                    isHeaderRow && !!headersData ? headersData : rowsData;

        const foundItem = descriptors.find((item: SimpleGridCellDescriptor | SimpleGridRowDescriptor) => {
            return id === item.id;
        });
     
        return foundItem ? foundItem : null;
    }

    generateRowCells = (data: SimpleGridCellDescriptor[], headersCells?: SimpleGridCellDescriptor[]): React.ReactNode[] => {
        return data.map((item: SimpleGridCellDescriptor, index): React.ReactNode => {
            const { enableSorting } = this.props;
            const {
                order,
                orderBy
            } = this.getOrderAndOrderBy();
            const areHeadersCellPresent = (!!headersCells && (headersCells.length > 0));
            const itemCellType = (areHeadersCellPresent && headersCells) ?
                                        headersCells[index].cellType : item.cellType;
            const {
                id: itemId,
                className,
                label,
                child
            } = item;

            return (
                <TableCell
                    key={`${index}-${itemId}`}
                    id={itemId}
                    className={className}
                    sortDirection={orderBy === itemId ? order: false}
                    align={( itemCellType === SimpleGridCellType.numeric) ? 'right' : undefined}
                >
                    {
                        (areHeadersCellPresent || !enableSorting) ?
                            child ? child : label
                        : <TableSortLabel
                                key={itemId}
                                active={orderBy === itemId}
                                direction={order}
                                onClick={this.onSortClickedHandler(itemId)}
                            >
                                {child ? child : label}
                            </TableSortLabel>
                    }
                </TableCell>
            );
        });
    };

    getOrderAndOrderBy() {
        const {
            order: orderProp,
            orderBy: orderByProp,
            onSortClick
        } = this.props;
        const {
            order: orderState,
            orderBy: orderByState
        } = this.state;

        const order = onSortClick ? orderProp : orderState;
        const orderBy = onSortClick ? orderByProp: orderByState;

        return {
            order,
            orderBy
        }
    }

    onSortClickedHandler = (id: string) => (event: React.MouseEvent<HTMLElement>) => {
        const {
            order,
            orderBy
        } = this.getOrderAndOrderBy();

        const toggledOrder = (order === SIMPLE_GRID_ORDER_ASC) ? SIMPLE_GRID_ORDER_DESC : SIMPLE_GRID_ORDER_ASC;

        // If the sort callback is defined we let the parent to sort the data
        const { onSortClick } = this.props;
        if (onSortClick) {
            const descriptor: SimpleGridCellDescriptor = this.getDescriptorFromId(id, true) as SimpleGridCellDescriptor;
            onSortClick(descriptor, toggledOrder);
            return;
        }

        // If the callback isn't defined we sort the internal buffer

        // If it was clicked to the same column
        if (orderBy === id) {
            this.setState({order: toggledOrder}); // We toggle the order (e.g. from asc to desc)
        } else { // If the clicked column was different...
            this.setState({ // we change the sorting column and reset the sorting order to ascending
                order: SIMPLE_GRID_ORDER_ASC,
                orderBy: id
            });
        }
    };

    onGridClicked = (event: React.MouseEvent<HTMLTableRowElement>) => {
        const { onRowClick } = this.props;
        if (!onRowClick) {
            return;
        }

        const { id } = event.currentTarget.dataset;
        if (id === GRID_HEADER_ID) {
            return;
        }
        const descriptor: SimpleGridCellDescriptor | SimpleGridRowDescriptor | null = 
            this.getDescriptorFromId(id ? id : '', false);
        
        if (!descriptor) {
            return;
        }
        onRowClick(descriptor as SimpleGridRowDescriptor);
    };
    
    public render() {
        const {
            classes,
            className,
            headersData,
            rowsData,
            enableSorting,
            isLoading,
            noItemsLabel,
            showHeaders,
            onRowClick
        } = this.props;
        const {
            order,
            orderBy
        } = this.getOrderAndOrderBy();

        const headers = headersData ? 
            this.generateGridRows(
                [{id: GRID_HEADER_ID, cells: headersData} as SimpleGridRowDescriptor],
                false)
            : undefined;
        const rowsDataBuffer = enableSorting ?
                SimpleGridUtilities.sortRowsDataById(rowsData,
                                                     order,
                                                     headersData,
                                                     orderBy
                                                    ) :
                rowsData;
        const rows = this.generateGridRows(rowsDataBuffer, !!onRowClick, headersData);
        const areRowsPresent = rows && (rows.length > 0)
        const isNoItemLabelPresent = noItemsLabel && (noItemsLabel.length > 0);

        const rootClassNames = classes.root + (className ? ` ${className}` : '');

        return (
            <Paper className={rootClassNames}>
                <CCSpinner
                    className={classes.spinner}
                    loading={isLoading}
                    size={70}
                /> 
                <Table className={classes.table}>
                { showHeaders && (headers !== undefined) && (headers.length > 0) ? 
                    <TableHead>
                        {headers}
                    </TableHead>
                    : undefined
                }
                    <TableBody>
                        {
                            (areRowsPresent) ?
                            rows :
                            <TableRow
                                className={classes.emptyRow}
                            >
                                <TableCell
                                className={classes.emptyCell}
                                    colSpan={1000}
                                >
                                    <Typography
                                        className={classes.noItemsLabel}
                                        variant="h5"
                                    >
                                        { isNoItemLabelPresent ? noItemsLabel : isLoading ? LOADING_TEXT : ''}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        }
                    </TableBody>
                </Table>
            </Paper>
        );
    }
};

const MUIComponent = withStyles(styles)(SimpleGrid);
export { MUIComponent as SimpleGrid };
