import {
    createStyles,
    WithStyles,
    withStyles
} from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
    TreeItem,
    TreeView
} from '@material-ui/lab';
// import memoize from 'memoize-one';
import * as React from 'react';
import { CCSpinner } from '../cc-spinner';

export interface RenderTree {
    id: string;
    name: string;
    children?: RenderTree[];
    path: string;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const styles = (theme: Theme) => createStyles({
    root: {
        display: 'block',
        position: 'relative',
        minHeight: '5em',
        padding: '1em 0'
    },
    spinnerParent: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        left: '0',
        transition: 'top 0.5s ease',
    },
    spinner: {
        position: 'absolute',
        height: '110%',
        width: '100%',
        backgroundColor: theme.ccPalette.disabled.light,
        top: '-10%',
        left: '0'
    }
});

interface Props extends WithStyles<typeof styles> {
    treeData: RenderTree;
    nodesExpanded?: string[];
    className?: string;
    isLoading: boolean;
    onNodeSelected?: (treeData: RenderTree , nodeId: string, nodeLabel: string, updateCallback: (nodeIds: string[]) => void) => void;
    onNodeToggled?: (nodeIds: string[]) => void;
    defaultCollapseIcon?: JSX.Element;
    defaultExpandIcon?: JSX.Element;
    labelIcon?: JSX.Element;
    labelIconCondition?: (nodeId: string) => boolean;
    loadingOverlayPosition?: number;
}

interface States {
    expanded: string[];
}

class NodesTreeView extends React.Component<Props, States> {
    public static defaultProps = {
        isLoading: false
    };

    state = {
        expanded: this.props.nodesExpanded ? this.props.nodesExpanded : [this.props.treeData.id]
    };

    showIcon = (nodeId: string) => {
        const { labelIconCondition } = this.props;

        if (labelIconCondition) {
            return labelIconCondition(nodeId);
        }

        return false;
    }
 
    renderTree = (nodes: RenderTree) => {
        const {
            labelIcon,
            labelIconCondition
        } = this.props;

        let isLabelIcon = false;
        if (labelIcon) {
            if (labelIconCondition) {
                isLabelIcon = this.showIcon(nodes.id);
            } else {
                isLabelIcon = true;
            }
        }

        return (
            <TreeItem
                key={nodes.id}
                nodeId={nodes.id}
                label={nodes.name}
                endIcon={isLabelIcon ? labelIcon : ''}
            >
                {Array.isArray(nodes.children) ? nodes.children.map((node) => this.renderTree(node)) : null}
            </TreeItem>
        );
    }

    handleToggle = (event: React.ChangeEvent<unknown>, nodeIds: string[]) => {
        const { onNodeToggled } = this.props;
        this.expandNodes(nodeIds);

        if (onNodeToggled) {
            onNodeToggled(nodeIds);
        }
    };

    expandNodes = (nodeIds: string[]) => {
        // Update the current nodes expanded
        // eslint-disable-next-line no-prototype-builtins
        this.setState({expanded: nodeIds});
    };

    handleSelect = (event: React.ChangeEvent<unknown>, nodeId: string) => {
        const { onNodeSelected, treeData } = this.props;

        if (onNodeSelected && event.target) {
            const nodeElement = event.target as HTMLElement;
            // Update tree once the node selected is complete 
            onNodeSelected(treeData, nodeId, nodeElement.innerText, this.expandNodes);
        }
    };

    public render() {
        const {
            classes,
            className,
            defaultCollapseIcon,
            defaultExpandIcon,
            isLoading,
            treeData,
            loadingOverlayPosition
        } = this.props;

        const {
            expanded
        } = this.state;

        const mainClasses = `${classes.root}${className ? ` ${className}` : ''}`;
        
        return (
            <TreeView
                className={mainClasses}
                defaultCollapseIcon={defaultCollapseIcon ? defaultCollapseIcon : <ExpandMoreIcon />}
                expanded={expanded}
                defaultExpandIcon={defaultExpandIcon ? defaultExpandIcon : <ChevronRightIcon />}
                onNodeSelect={this.handleSelect}
                onNodeToggle={this.handleToggle}
            >
                <div 
                    className={classes.spinnerParent} 
                    style={{
                        top: `${loadingOverlayPosition ? loadingOverlayPosition : 0}px`
                    }}
                >
                    <CCSpinner
                        className={classes.spinner}
                        loading={isLoading}
                        size={60}
                    />
                </div>
                {this.renderTree(treeData)}
            </TreeView>
        );
    }
}

const MUIComponent = withStyles(styles)(NodesTreeView);
export {MUIComponent as NodesTreeView};
