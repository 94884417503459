import { COOKIE_NAMES, LocalStorageUtil } from './../utils/local-storage';
// tslint:disable: ordered-imports
import { RunningTasks, TaskGroup } from './../providers/job.provider';
import * as CryptoJS from 'crypto-js';
import {
    action,
    computed,
    observable
} from 'mobx';
import { RouterState } from 'mobx-state-router';
import { HttpConnectorAdapter } from '../adapters';
import { MarkersListItem } from '../components/qr-code-generator';
import { LoginCredentials } from '../models/login-credentials';
import { RootStoreBase } from './root.store.base';
import { ROUTE_NAMES } from './routes';
import UserProvider, { UserIdentifiers } from '../providers/user.provider';
import TeamProvider from '../providers/team.provider';
import { LoginResponse } from '../providers/login.provider';

interface SerializedRouteData {
    routeName: string;
    params: string;
}

export enum MainTabs {
    AccountInfo = 0,
    Markers,
    Floorplan_Replace,
    QR_Code,
    Marker_Export,
    Team_Info,
    User_Export,
    Marker_Operations,
    Marker_Detail,
    Teams,
    Folders,
    Accounts,
    Module_Groups,
    Scheduling_Permissions
}

interface StoredRunningTasks {
    page: MainTabs,
    tasks: RunningTasks[],
    groups?: TaskGroup[]
}

const LOGIN_ERROR_INVALID_CREDENTIALS = 'Invalid Credentials';
const LOGIN_ERROR_FAILED_USER_IDENTIFIERS = 'Failed to get valid user identifiers';
const LOGIN_ERROR_MAX_ATTEMPTS = 'Max login attempts have been reached';
const LOGIN_ERROR_GENERIC = 'Network Error';

export class AdminStore {
    //#region Observables
    @observable
    isAlternateLoginRequired = false;

    @observable
    loadingError: string = '';

    @observable
    loginError: string = '';

    @observable
    isLoginLoading: boolean = false;

    @observable
    currentTab: MainTabs = MainTabs.AccountInfo;

    @observable
    currentFloorPlanId: string = '';

    @observable
    currentMarkersList: MarkersListItem[] = [] as MarkersListItem[];

    @observable
    private currentExportTasksInternal: StoredRunningTasks[] | null = [] as StoredRunningTasks[];

    @observable
    private loginCredentialsInternal: LoginCredentials | undefined = undefined;

    @observable
    private userIdentifiersInternal: UserIdentifiers | undefined = undefined;
    
    private userProvider = new UserProvider();

    private teamProvider = new TeamProvider();
    //#endregion

    get loginCredentials() : LoginCredentials | undefined  {
        if (this.loginCredentialsInternal){
            return this.loginCredentialsInternal;
        }

        const storedData = LocalStorageUtil.getItem(COOKIE_NAMES.LOGIN_CREDENTIALS);
        if (storedData){
            this.loginCredentialsInternal = storedData as LoginCredentials;
        }

        return this.loginCredentialsInternal;
    }

    set loginCredentials(credentials: LoginCredentials | undefined) {
        if (credentials) {
            LocalStorageUtil.setItem(COOKIE_NAMES.LOGIN_CREDENTIALS, credentials);
        }
        HttpConnectorAdapter.setCredentials(credentials);
        this.loginCredentialsInternal = credentials;
    }

    get userIdentifiers() : UserIdentifiers | undefined {
        if (this.userIdentifiersInternal) {
            return this.userIdentifiersInternal;
        }

        const storedData = LocalStorageUtil.getItem(COOKIE_NAMES.USER_IDENTIFIERS);
        if (storedData){
            this.userIdentifiersInternal = storedData as UserIdentifiers;
        }

        return this.userIdentifiersInternal;
    }

    set userIdentifiers(identifiers: UserIdentifiers | undefined) {
        if (identifiers) {
            LocalStorageUtil.setItem(COOKIE_NAMES.USER_IDENTIFIERS, identifiers);
        }
        HttpConnectorAdapter.setUserIdentifiers(identifiers);
        this.userIdentifiersInternal = identifiers;
    }

    get currentExportTasks(): StoredRunningTasks[] {
        const storedData = LocalStorageUtil.getItem(COOKIE_NAMES.STORED_TASKS) as StoredRunningTasks[];
        this.currentExportTasksInternal = storedData;
        return this.currentExportTasksInternal;
    }

    set currentExportTasks(storedTasks: StoredRunningTasks[]) {
        LocalStorageUtil.setItem(COOKIE_NAMES.STORED_TASKS, storedTasks);
        this.currentExportTasksInternal = storedTasks;
    }

    //#region Computed
    @computed
    get isUserLoggedIn(): boolean {
        const credentials = this.loginCredentials;
        return credentials !== undefined;
    }
    //#endregion

    constructor(
        protected readonly rootStore: RootStoreBase
    ) {
        if (!this.currentExportTasks) {
            // SET THE PAGES THAT HAVE RUNNING TASKS
            this.initTasksCookie();
        }
    }

    //#region actions

    //#region login
    @action
    login = (username?: string, password?: string, captcha?: string): Promise<LoginResponse> => {
        const credentials =
            (!username || !password) ? this.loginCredentials : new LoginCredentials(username, CryptoJS.SHA1(password).toString());
        return this.loginWithCredentials(credentials, captcha);
    };
    //#endregion

    //#region loginWithCredentials
    @action
    loginWithCredentials(credentials?: LoginCredentials, captcha?: string) {
        return new Promise<LoginResponse>((resolve, reject) => {
            if (!credentials) { 
                return reject();
            }

            const {
                password,
                username
            } = credentials;
            const {
                providers: { loginProvider },
                routerStore
            } = this.rootStore;
            loginProvider
                .login(username, password, captcha)
                .then((areCredentialsCorrect: LoginResponse) => {
                    if (areCredentialsCorrect.success) {
                        this.loginCredentials = credentials;
                        this.loginError = '';
                        const partialUserIdentifiers = {
                            user_id: areCredentialsCorrect.user_id,
                            auth_token: areCredentialsCorrect.auth_token,
                            team_id: '',
                            account_id: '',
                            expiry: new Date().getTime() + 12 * 3600000
                        } as UserIdentifiers;
                        this.userIdentifiers = partialUserIdentifiers;
                        this.fetchUserIdentifiers(areCredentialsCorrect.user_id, areCredentialsCorrect.auth_token, username).then(check => {
                            const routeName = this.getLogInRedirect();
                            if (routeName) {
                                routerStore.goTo(routeName);
                                this.setLogInRedirect();
                            } else {
                                routerStore.goTo(ROUTE_NAMES.HOME);
                            }
                            this.isAlternateLoginRequired = false;
                            this.initTasksCookie();
                        }).catch(error => {
                            console.error(error);
                            this.loginError = LOGIN_ERROR_FAILED_USER_IDENTIFIERS;
                            this.isLoginLoading = false;
                        });
                    } else {
                        this.loginError = LOGIN_ERROR_INVALID_CREDENTIALS;
                        this.isLoginLoading = false;
                        return resolve(areCredentialsCorrect);
                    }
                })
                .catch((data)=> {
                    let loginError = data.message ? data.message : LOGIN_ERROR_GENERIC;
                    const { status } = data;

                    if (loginError === LOGIN_ERROR_MAX_ATTEMPTS) {
                        this.isAlternateLoginRequired = true;
                    }

                    if (status === 401) {
                        loginError = LOGIN_ERROR_INVALID_CREDENTIALS;
                    }

                    this.loginError = loginError;
                    this.isLoginLoading = false;
                });
                this.loginCredentials = undefined;
                this.isLoginLoading = true;
        });
    }
    //#endregion

    //#region get/set LogInRedirect
    @action
    setLogInRedirect(route?: RouterState) {
        if (!route) {
            LocalStorageUtil.clearItem(COOKIE_NAMES.ROUTE);
        } else {
            const {
                routeName,
                params
            } = route;
            const serializedRoute = JSON.stringify({
                routeName,
                params: JSON.stringify(params)
            } as SerializedRouteData);
            LocalStorageUtil.setItem(COOKIE_NAMES.ROUTE, serializedRoute);
        }
    }

    getLogInRedirect(): RouterState | undefined {
        const storedUri = LocalStorageUtil.getItem(COOKIE_NAMES.ROUTE);
        try {
            const {
                routeName,
                params: paramsSerialized
            } = storedUri as SerializedRouteData;
            const params = JSON.parse(paramsSerialized);
            return new RouterState(routeName, params);
        } catch {
            return undefined;
        }
    }
    //#endregion
    
    //#region logout
    @action
    logout() {
        const {
            routerStore
        } = this.rootStore;

        this.isAlternateLoginRequired = false;
        this.currentExportTasksInternal = null;
        LocalStorageUtil.clearStorage();
        routerStore.goTo(ROUTE_NAMES.LOGIN);
    }
    //#endregion

    //#region changeCurrentTab
    @action
    changeCurrentTab = (newTab: MainTabs) => {
        this.currentTab = newTab;
    }
    //#endregion

    //#region getUserIdentifiers
    @action
    fetchUserIdentifiers = (userId: string, authToken: string, userEmail?: string) => {
        return new Promise<boolean>((resolve, reject) => {
            this.isLoginLoading = true;
            this.userProvider.getUserIdentifiers(userEmail!).then((firstSet) => {
                const { teams } = firstSet;
                if(teams.length <= 0) {
                    const finalUserIdentifiers = {
                        user_id: userId,
                        auth_token: authToken,
                        account_id: '',
                        team_id: '',
                        expiry: new Date().getTime() + 12 * 3600000
                    } as UserIdentifiers;
                    this.userIdentifiers = finalUserIdentifiers;
                    this.isLoginLoading = false;
                    return resolve(true);
                }
                const teamId = teams[0].team_id;
                this.teamProvider.getTeamInfoById(teamId).then((lastSet) => {
                    const { accountId } = lastSet;
                    const finalUserIdentifiers = {
                        user_id: userId,
                        auth_token: authToken,
                        account_id: accountId ? accountId : '',
                        team_id: teamId ? teamId : '',
                        expiry: new Date().getTime() + 12 * 3600000
                    } as UserIdentifiers;
                    this.userIdentifiers = finalUserIdentifiers;
                    this.isLoginLoading = false;
                    return resolve(true);
                }).catch(error => {
                    this.loginError = error.message;
                    this.isLoginLoading = false;
                    return reject(false);
                });
            }).catch(error => {
                this.loginError = error.message;
                this.isLoginLoading = false;
                return reject(false);
            });
        });
    }
    //#endregion

    //#region setFloorId
    setFloorPlanId = (floorPlanId:string) => {
        this.currentFloorPlanId = floorPlanId;
    }
    //#endregion

    //#region setMarkersList
    setMarkersList = (markersList: MarkersListItem[]) => {
        this.currentMarkersList = markersList ? markersList : [];
    }
    //#endregion

    //#region initTasksCookie
    initTasksCookie = () => {
        this.currentExportTasks = [
            { page: MainTabs.Marker_Export, tasks: [] },
            { page: MainTabs.User_Export, tasks: [] },
            { page: MainTabs.Marker_Operations, tasks: [], groups: [] },
            { page: MainTabs.QR_Code, tasks: [] },
            { page: MainTabs.Markers, tasks: [] },
            { page: MainTabs.Teams, tasks: [] },
            { page: MainTabs.Marker_Detail, tasks: [] },
            { page: MainTabs.Folders, tasks: [] },
            { page: MainTabs.Accounts, tasks: [] },
            { page: MainTabs.Floorplan_Replace, tasks: [], groups: [] },
            { page: MainTabs.Scheduling_Permissions, tasks: [] },
        ];
    }
    //#endregion

    //#region setMarkersList
    setCurrentExportTasks = (tasks: RunningTasks[], page: MainTabs) => {
        const storedTasks = this.currentExportTasks;
        if (!storedTasks) return;
        storedTasks.find(e => e.page === page)!.tasks = tasks ? tasks : [];
        this.currentExportTasks = storedTasks;
    }

    setCurrentExportGroups = (groups: TaskGroup[], page: MainTabs) => {
        const storedTasks = this.currentExportTasks;
        if (!storedTasks) return;
        storedTasks.find(e => e.page === page)!.groups = groups ? groups : [];
        this.currentExportTasks = storedTasks;
    }
    //#endregion

    //#endregion
}
