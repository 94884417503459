import {
    Button,
    Paper,
    Typography
} from '@material-ui/core';
import {
  createStyles,
  WithStyles,
  withStyles
} from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import memoize from 'memoize-one';
import * as React from 'react';
import { Marker } from '../../models';
import { CCSpinner } from '../../shared/components/cc-spinner';
import {
    SimpleList,
    SimpleListItem
} from '../../shared/components/simple-list';
import { Floorplan } from '../../shared/domain';
import { sortByKey } from '../../utils/sort-utilities';
import { noPictureImage } from './no-picture-image';

const styles = (theme: Theme) => createStyles({
    [theme.breakpoints.down('lg')]: {
        miniMapImageContainer: {
            width: '22em !important',
        },
        noMiniMapImageContainer: {
            width: '22em !important'
        },
        miniMapImage: {
            maxWidth: '22em !important',
        },
        heading: {
            maxWidth: '15em',
        }
    },
    root: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    container: {
        display: 'flex',
        flexDirection: 'column'
    },
    heading: {
        fontWeight: 700,
        marginBottom: '0.9em'
    },
    ellipsis: {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden'
    },
    simpleList: {
        overflow: 'auto',
        height: '50em',
        minWidth: '20em'
    },
    simpleListContainer: {
        marginRight: '4em',
        minHeight: '19em',
        width:'20em'
    },
    complementedListContainer: {
        marginRight: '2em',
        minHeight: '19em',
        width:'20em'
    },
    boxContainer: {
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: '4em',
        marginTop: '50px',
    },
    spinnerContainer: {
        position: 'relative',
        '&::before': {
            backgroundColor: 'rgba(0,0,0,0.2)',
            content: '""',
            display: 'block',
            height: '100%',
            position: 'absolute',
            width: '100%'
        }
    },
    spinnerContent: {
        position: 'absolute'
    },
    markersListSpinnerContainer:{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
    },
    emptyContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        fontWeight: 'bold',
        textAlign: 'center',
        justifyContent: 'center',
        '& > *': {
            color: 'rgba(0,0,0,0.2)'
        }
    },
    emptySimpleList: {
        paddingTop: '3.6em'
    },
    miniMapImageContainer: {
        alignItems: 'center',
        display: 'flex',
        height: '19em',
        justifyContent: 'center',
        marginTop: '0',
        position: 'relative',
        width:'26.5em'
    },
    noMiniMapImageContainer: {
        alignItems: 'center',
        background: '#fbfbfb',
        display: 'flex',
        height: '19em',
        justifyContent: 'center',
        position: 'relative',
        textAlign: 'center',
        width: '26.5em'
    },
    miniMapImage: {
        height: '100%',
        maxWidth: '26.5em',
        objectFit: 'contain'
    },
    noPictureImage: {
        maxWidth: '40%',
        objectFit: 'contain'
    },
    exportDataButton: {
        maxWidth: '14em',
        marginTop: '1em'
    }
});

interface Props extends WithStyles<typeof styles> {
    currentFloorplan?: Floorplan;
    currentMarkerName?: string;
    exportDataButtonDisabled: boolean;
    exportDataButtonText: string;
    floorPlans?: Floorplan[] | null;
    floorPlansIsLoading: boolean;
    markers?: Marker[] | null;
    markerSelection: boolean;
    markersIsLoading: boolean;
    miniMapImage?: Blob;
    miniMapImageIsLoading: boolean;
    canSearchFloorplan: boolean;
    onFloorplanClick?: (floorPlan: Floorplan) => void ;
    onMarkerClick?: (marker: Marker) => void;
    onExportDataButtonClick?: () => void;
};

// tslint:disable-next-line:no-empty-interface
interface States {
}

class FloorplansList extends React.Component<Props, States> {
    public static defaultProps = {
        exportDataButtonText: 'Export',
        exportDataButtonDisabled: false,
        markerSelection: true
    };

    state = {
    };

    getFloorplanListItems = memoize(floorPlans => {
        return floorPlans.sort(sortByKey('name')).map((floorPlan: Floorplan) => {
            return new SimpleListItem(floorPlan.id, floorPlan.name, floorPlan.id);
        });
    }); 

    getMarkerListItems = memoize(markers => {
        return markers.sort(sortByKey('name')).map((marker: Marker) => {
            return new SimpleListItem(marker.marker_id, marker.name, marker.marker_id);
        });
    });

    onFloorplanClicked = (listItem: SimpleListItem) => {
        const {floorPlans, onFloorplanClick} = this.props;
        if (!floorPlans) {
            return;
        }

        const currentFloorplan = floorPlans.find((floorPlan:Floorplan) => {
            return floorPlan.id === listItem.id;
        });
        if (!currentFloorplan) {
            return;
        }

        if (onFloorplanClick) {
            onFloorplanClick(currentFloorplan);
        }
    };

    onMarkerClicked = (listItem: SimpleListItem) => {
        const {markers, onMarkerClick} = this.props;

        if(!markers) {
            return;
        }

        const currentMarker = markers.find((marker: Marker) => {
            return marker.marker_id === listItem.id;
        });
        if (!currentMarker) {
            return;
        }

        if (onMarkerClick) {
            onMarkerClick(currentMarker);
        }
    };

    onExportDataButtonClicked = () => {
        const { onExportDataButtonClick } = this.props;
        if (!onExportDataButtonClick){
            return;
        }

        onExportDataButtonClick();
    }

    getImgBlobURL = () => {
        const { miniMapImage } = this.props;
        if (!miniMapImage) {
            return;
        }
        const objectURL = URL.createObjectURL(miniMapImage);
        return objectURL;
    }

    public render() {
        const {
            classes,
            exportDataButtonText,
            exportDataButtonDisabled,
            floorPlans,
            currentFloorplan,
            floorPlansIsLoading,
            markers,
            markerSelection,
            markersIsLoading,
            miniMapImage,
            miniMapImageIsLoading,
            currentMarkerName,
            canSearchFloorplan
        } = this.props;
        const currentFloorplanName = currentFloorplan ? currentFloorplan.name : '';
        const isMinimapImageLoaded = miniMapImage && currentMarkerName;
        const minimapImageClasses = `${classes.container} ${classes.boxContainer} ${miniMapImageIsLoading ? classes.spinnerContainer : ''}`;

        if (!canSearchFloorplan || (floorPlans && (floorPlans.length > 0) && !floorPlansIsLoading && canSearchFloorplan)) {
            return (
                <div className={classes.root}>
                    {
                        canSearchFloorplan &&
                            <div className={`${classes.container} ${classes.simpleListContainer}`}>
                                <Typography
                                    variant='h6'
                                    className={`${classes.heading} ${classes.ellipsis}`}
                                >
                                    Floor plan List
                                </Typography>
                                <SimpleList
                                    className={classes.simpleList}
                                    keepItemSelected={true}
                                    items={this.getFloorplanListItems(floorPlans)}
                                    isLoading={floorPlansIsLoading}
                                    onListItemClick={this.onFloorplanClicked}
                                    noLabelText="<NO NAME>"
                                />
                                <Button
                                    className={classes.exportDataButton}
                                    variant="contained"
                                    color="primary"
                                    disabled={exportDataButtonDisabled}
                                    onClick={this.onExportDataButtonClicked}
                                >
                                    {exportDataButtonText}
                                </Button>
                            </div>
                    }
                    <div className={`${classes.container} ${canSearchFloorplan ? classes.simpleListContainer : classes.complementedListContainer} ${!currentFloorplanName ? classes.emptySimpleList : ''}`}>
                    { currentFloorplanName ?
                        <Typography
                            variant='h6'
                            className={`${classes.heading} ${classes.ellipsis}`}
                        >
                            Markers on {currentFloorplanName}
                        </Typography>
                      : ''
                    }
                    { markers && (markers.length > 0) && currentFloorplanName ?
                            <div>
                                <SimpleList
                                    className={classes.simpleList}
                                    keepItemSelected={true}
                                    disabled={!markerSelection}
                                    isLoading={markersIsLoading}
                                    items={this.getMarkerListItems(markers)}
                                    onListItemClick={this.onMarkerClicked}
                                />
                                {
                                    !canSearchFloorplan && 
                                        <Button
                                            className={classes.exportDataButton}
                                            variant="contained"
                                            color="primary"
                                            disabled={exportDataButtonDisabled}
                                            onClick={this.onExportDataButtonClicked}
                                        >
                                            {exportDataButtonText}
                                        </Button>
                                }
                            </div>
                        :
                        <Paper className={`${classes.simpleList} ${(markersIsLoading ? `${classes.spinnerContainer} ${classes.markersListSpinnerContainer}` : classes.emptyContainer )}`}>
                            <CCSpinner 
                                loading={markersIsLoading}
                                size={70}
                            >
                                <Typography variant={!markers ? 'h4' : 'h6'}>
                                    {
                                        !markers ? 'No Floorplan selected' : `No markers found`
                                    }
                                </Typography>
                            </CCSpinner>
                        </Paper>
                    }
                    </div>
                    <div>
                        { isMinimapImageLoaded ?
                            <Typography
                                variant='h6'
                                className={`${classes.heading} ${classes.ellipsis}`}
                            >
                                MiniMap for {currentMarkerName}
                            </Typography>
                        : ''
                        }
                        <Paper className={`${minimapImageClasses} ${isMinimapImageLoaded ? classes.miniMapImageContainer : classes.noMiniMapImageContainer}`}>
                            <CCSpinner 
                                loading={miniMapImageIsLoading}
                                className={`${classes.spinnerContent}`}
                                size={70}
                            />
                            <img 
                                alt="minimap"
                                key={`img-${isMinimapImageLoaded}`}
                                className={`${isMinimapImageLoaded ? classes.miniMapImage :  classes.noPictureImage}`}
                                src={`${isMinimapImageLoaded ? this.getImgBlobURL() : noPictureImage}`}
                            />
                        </Paper>
                    </div>
                </div>
            );
        } else {
            return (
                <Paper className={`${classes.container} ${classes.simpleListContainer} ${classes.boxContainer} ${(!floorPlansIsLoading ? classes.emptyContainer : classes.spinnerContainer)}`}>
                    <CCSpinner 
                        loading={floorPlansIsLoading}
                        size={70}
                    >
                        <Typography variant='h4'>
                            No Floor Plan Defined
                        </Typography>
                    </CCSpinner>
                </Paper>
            );
        }
    }
};

const MUIComponent = withStyles(styles)(FloorplansList);
export { MUIComponent as FloorplansList };
