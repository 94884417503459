import { createStyles, WithStyles, withStyles } from "@material-ui/core/styles";
import { Theme } from '@material-ui/core/styles';
import { inject } from "mobx-react";
import { RouterView, ViewMap } from "mobx-state-router";
import * as React from "react";
import "typeface-roboto";
import { Home, Login, NotFoundPage } from "./features";
import { RootStore } from "./stores/root.store";
import { ROUTE_NAMES } from "./stores/routes";

const viewMap: ViewMap = {
    [ROUTE_NAMES.HOME]: <Home />,
    [ROUTE_NAMES.MARKERS]: <Home />,
    [ROUTE_NAMES.FLOORPLAN_REPLACE]: <Home />,
    [ROUTE_NAMES.QR_CODES]: <Home />,
    [ROUTE_NAMES.QR_CODES_WITH_PARAM]: <Home />,
    [ROUTE_NAMES.MARKER_EXPORT]: <Home />,
    [ROUTE_NAMES.TEAM_INFO]: <Home />,
    [ROUTE_NAMES.USER_EXPORT]: <Home />,
    [ROUTE_NAMES.MARKER_OPERATIONS]: <Home />,
    [ROUTE_NAMES.MARKER_DETAIL]: <Home />,
    [ROUTE_NAMES.TEAMS]: <Home />,
    [ROUTE_NAMES.FOLDERS]: <Home />,
    [ROUTE_NAMES.ACCOUNTS]: <Home />,
    [ROUTE_NAMES.MODULE_GROUPS]: <Home />,
    [ROUTE_NAMES.SCHEDULING_PERMISSIONS]: <Home />,
    [ROUTE_NAMES.LOGIN]: <Login />,
    [ROUTE_NAMES.NOT_FOUND]: <NotFoundPage />
};

const styles = (theme: Theme) => createStyles({
    '@global': {
        html: {
            height: '100%',
            boxSizing: 'border-box'
        },
        '*, *:before, *:after': {
            boxSizing: 'inherit'
        },
        body: {
            height: '100%',
            margin: 0,
            padding: 0,
            background: theme.palette.background.default,
            fontFamily: theme.typography.fontFamily,
            fontSize: theme.typography.fontSize,
            color: theme.palette.text.primary,

            // Helps fonts on OSX look more consistent with other systems
            WebkitFontSmoothing: 'antialiased',
            MozOsxFontSmoothing: 'grayscale',

            // Use momentum-based scrolling on iOS devices
            WebkitOverflowScrolling: 'touch'
        },
        '#root': {
            height: '100%',
            display: 'flex',
            flexDirection: 'column'
        }
    },
    root: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column'
    }
});

interface Props extends WithStyles<typeof styles> {
    rootStore: RootStore;
}

@inject('rootStore')
class Shell extends React.Component<Props> {
    public static defaultProps: Partial<Props> = {
    };

    public render() {
        const {
            classes,
            rootStore: { routerStore }
        } = this.props;
        return (
            <div className={classes.root}>
                <RouterView
                  routerStore={routerStore}
                  viewMap={viewMap}
                />
            </div>
        );
    }
}

const MUIComponent = withStyles(styles)(Shell);

export {MUIComponent as Shell};
