import * as React from 'react';

import { IconButton } from '@material-ui/core';
import {
    createStyles,
    WithStyles,
    withStyles
} from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { TablePaginationActionsProps } from '@material-ui/core/TablePagination/TablePaginationActions';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';

const styles = (theme: Theme) => createStyles({
    root: {
        flexShrink: 0,
        color: theme.palette.text.secondary,
        marginLeft: theme.spacing(2.5),
        [theme.breakpoints.only('xs')]: {
            padding: '0 1em 0 0',
            marginLeft: '0.2em'
        }
    },
    paginationButton: {
        marginLeft: '0.2em',
        padding: '0',
        transform: 'scale(1)',
        [theme.breakpoints.only('xs')]: {
            marginLeft: '1em',
            transform: 'scale(1.8)'
        }
    }
});

interface Props extends TablePaginationActionsProps, WithStyles<typeof styles> {
    count: number;
    disabled: boolean;
    onPageChange: (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => void,
    page: number;
    rowsPerPage: number;
    theme: Theme;
}

class SimplePaginationActions extends React.Component<Props> {
  public static defaultProps = {
      disabled: false
  };
  
  handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      this.props.onPageChange(event, 0);
  };
  
  handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      this.props.onPageChange(event, this.props.page - 1);
  };

  handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>)  => {
      this.props.onPageChange(event, this.props.page + 1);
  };

  handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>)  => {
      const {
          count,
          rowsPerPage
      } = this.props;

      this.props.onPageChange(
          event,
          Math.max(0, Math.ceil(count / rowsPerPage) - 1),
      );
  };

  render() {
      const { 
          classes,
          count,
          disabled,
          page,
          rowsPerPage,
          theme
      } = this.props;

      return (
          <div className={classes.root}>
              <IconButton
                  className={classes.paginationButton}
                  onClick={this.handleFirstPageButtonClick}
                  disabled={disabled || (page === 0)}
                  aria-label="First Page"
              >
                  {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
              </IconButton>
              <IconButton
                  className={classes.paginationButton}
                  onClick={this.handleBackButtonClick}
                  disabled={disabled || (page === 0)}
                  aria-label="Previous Page"
              >
                  {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
              </IconButton>
              <IconButton
                  className={classes.paginationButton}
                  onClick={this.handleNextButtonClick}
                  disabled={disabled || (page >= Math.ceil(count / rowsPerPage) - 1)}
                  aria-label="Next Page"
              >
                  {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
              </IconButton>
              <IconButton
                  className={classes.paginationButton}
                  onClick={this.handleLastPageButtonClick}
                  disabled={disabled || (page >= Math.ceil(count / rowsPerPage) - 1)}
                  aria-label="Last Page"
              >
                  {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
              </IconButton>
          </div>
      );
  }
}

const MUIComponent = withStyles(styles, { withTheme: true })(SimplePaginationActions);
export {MUIComponent as SimplePaginationActions};
