import { IconButton, InputAdornment } from '@material-ui/core';
import { TextFieldProps } from '@material-ui/core/TextField';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import * as React from 'react';

export interface WithPasswordShowHideProps {
    iconClassName?: string;
}
export interface WithPasswordShowHideState {
    showPassword: boolean;
}
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const withPasswordShowHide = <P extends Record<string, unknown>, S extends Record<string, unknown>>(WrappedComponent: React.ComponentType<P>) =>
    class WithLoading extends React.Component<P & TextFieldProps & WithPasswordShowHideProps, S & WithPasswordShowHideState> {
        constructor(props: Readonly<P & TextFieldProps & WithPasswordShowHideProps>) {
            super(props);
            this.state = Object.assign({}, this.state, { showPassword: false });
        }

        onShowPasswordClicked = () => {
            this.setState({ showPassword: !(this.state.showPassword) } as (S & WithPasswordShowHideState));
        };

        render() {
            const {
                iconClassName,
                InputProps: wrappedInputProps
            } = this.props;
            const inputProps = {
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                            className={iconClassName}
                            disableRipple={true}
                            aria-label="Toggle password visibility"
                            onClick={this.onShowPasswordClicked}
                        >
                            {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
                )
            };
            const mergedInputProps = wrappedInputProps ?
                Object.assign({}, wrappedInputProps, inputProps) :
                inputProps;

            // Delete the property of the HOC so we don't pollute the wrapped component.
            const parentProps = Object.assign({}, this.props) as P & TextFieldProps & WithPasswordShowHideProps;
            delete parentProps.iconClassName;

            return <WrappedComponent
                {...parentProps}
                type={this.state.showPassword ? 'text' : 'password'}
                InputProps={mergedInputProps}
            />;
        }
    };
