import { Button, Chip, Drawer, Typography } from '@material-ui/core';
import {
    createStyles,
    Theme,
    WithStyles,
    withStyles
} from '@material-ui/core/styles';
import { Error } from '@material-ui/icons';
import _ from 'lodash';
import {
    inject,
    observer
} from 'mobx-react';
import React from 'react';
import { AccountFolders, RunningTasksList } from '../../components';
import { FolderForm, folderForm } from '../../components/folder-form/folder-form';
import { FolderFull } from '../../models/folder';
import { Structure } from '../../models/structure';
import AccountProvider from '../../providers/account.provider';
import FolderProvider from '../../providers/folder.provider';
import JobProvider, { RunningTasks, STATIC_PROGRESS_LIMIT, StatusTypes } from '../../providers/job.provider';
import StructureProvider from '../../providers/structure.provider';
import { CCSpinner } from '../../shared/components/cc-spinner';
import { RenderTree } from '../../shared/components/node-tree';
import {
    DialogResult,
    SimpleDialog
} from '../../shared/components/simple-dialog';
import { PageBoundary } from '../../shared/components/simple-grid-pagination';
import { SimpleModal } from '../../shared/components/simple-modal';
import {
    Account,
    Folder,
    Team,
} from '../../shared/domain';
import { MainTabs } from '../../stores/admin.store';
import { RootStore } from '../../stores/root.store';
import { StoredTreeSelection } from '../../components/folder-position-picker/folder-position-picker';

const ROWS_PER_PAGE = 10;
const TASKS_ROWS_PER_PAGE = 10;
const ACCOUNTS_PAGING_LIMIT = 20;
const TASKS_PAGING_LIMIT = 100;
const EDIT_FOLDER_FORM: folderForm[] = [
    {
        field: 'name',
        required: true,
        input: 'string'
    },
    {
        field: 'location',
        required: true,
        input: 'location'
    },
    {
        field: 'email_domains',
        required: true,
        input: 'emails'
    },
    {
        field: 'user_app_visible',
        required: false,
        input: 'boolean'
    },
];
const CREATE_FOLDER_FORM: folderForm[] = [
    {
        field: 'name',
        required: true,
        input: 'string'
    },
    {
        field: 'location',
        required: false,
        input: 'location'
    },
    {
        field: 'email_domains',
        required: false,
        input: 'emails'
    },
    {
        field: 'user_app_visible',
        required: false,
        input: 'boolean'
    },
    {
        field: 'position',
        required: true,
        input: 'position'
    },
];
const EDIT_FOLDER_HIDDEN_ATTRIBUTES = [
    '_rev',
    'channels',
    'structure_id',
    'jobs',
    'type',
    'public_config',
    'meta',
    'template'
];
const CREATE_FOLDER_HIDDEN_ATTRIBUTES = [
    '_id',
    '_rev',
    '_allow_anonymous',
    'channels',
    'structure_id',
    'jobs',
    'type',
    'public_config',
    'folder_id',
    'public_config',
    'template'
];

export enum PickedType {
    None = 0,
    Folder,
    FloorPlan
}

export interface JobResultDataset {
    status: string;
    result: string;
}

const styles = (theme: Theme) => createStyles({
    container: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row'
    },
    analyticsTabList: {
        height: '18.2em'
    },
    errorPopup: {
        width: '98%',
        borderRadius: 5
    },
    errorPopupHeader: {
        height: '0.6em',
        backgroundColor: theme.palette.error.main,
        borderRadius: '3px 3px 0 0'
    },
    errorPopupContentContainer :{
        display: 'flex',
        flexDirection: 'row',
        padding: theme.spacing(1),
    },
    errorPopupIcon: {
        fontSize: 40,
        marginRight: '1em'
    },
    mainSpinnerLabel: {
        paddingBottom: '30px'
    },
    progressContainer: {
        flexGrow: 1
    },
    animContainer: {
        transition: 'width 1s'
    },
    fullContainerSize: {
        width: '100%'
    },
    semiContainerSize: {
        width: '70%'
    },
    tasksContainerSize: {
        width: '30em',
        padding: '0 1em',
    },
    formContainer: {
        width: '30em',
        marginLeft: '2em'
    },
    jobMenuButton: {
        position: 'fixed',
        right: '6%',
        top: '3%',
    },
    notFinishedBadge: {
        marginLeft: '1em',
        background: '#ffb300',
        color: 'white',
        fontWeight: 'bolder',
    },
    finishedBadge: {
        marginLeft: '1em',
        background: '#107d00',
        color: 'white',
        fontWeight: 'bolder',
    },
    paper: {
        zIndex: 'auto'
    },
});

interface Props extends WithStyles<typeof styles> {
    rootStore: RootStore;
    onAuthError?: () => void;
}

interface States {
    accounts: Account[];
    currentAccount: Account | null;
    currentAccountOffset: number;
    networkError: boolean;
    dataPageIsLoading: boolean;
    isAccountsLoading: boolean;
    totalAccounts: number;
    modalOpen: boolean;
    modalText: string;
    folderIsLoading: boolean;
    mainSpinnerText: string;
    folders: Folder[];
    isFoldersLoading: boolean;
    currentNodeTree: RenderTree;
    treeIsLoading: boolean;
    pickedType: PickedType;
    pickedId: string;
    pickedName: string;
    runningTasks: RunningTasks[];
    currentTasksOffset: number;
    timeElapser: number | null;
    isNotificationModalOpened: boolean;
    notificationModalHeader: string;
    notificationModalText: string;
    processedFolders: string[];
    editFolder: FolderFull | undefined;
    isCreateFolder: boolean;
    formInputs: folderForm[];
    formHiddenAttributes: string[];
    isFormProcessing: boolean;
    isJobMenuOpen: boolean;
    teams: Team[];
    isCreateIntent: boolean;
    formHasChanges: boolean;
    isChangesModalOpen: boolean;
    storedFolderSelection?: StoredTreeSelection;
    searchAccountName: string;
    storedAccounts: Account[];
    storedAccountOffset: number;
    storedAccountTotalCount: number;
}

@inject('rootStore')
@observer
class Folders extends React.Component<Props, States> {
    public static defaultProps: Partial<Props> = {
    };

    state: States = {
        accounts: [],
        currentAccount: null,
        currentAccountOffset: 0,
        networkError: false,
        dataPageIsLoading: true,
        isAccountsLoading: false,
        totalAccounts: 0,
        modalOpen: false,
        modalText: '',
        folderIsLoading: false,
        mainSpinnerText: 'Please wait. Loading data.',
        folders: [],
        isFoldersLoading: false,
        currentNodeTree: {id: '', name:'', children:[], path: ''},
        treeIsLoading: false,
        pickedType: PickedType.None,
        pickedId: '',
        pickedName: '',
        runningTasks: [],
        currentTasksOffset: 0,
        timeElapser: null,
        isNotificationModalOpened: false,
        notificationModalHeader: '',
        notificationModalText: '',
        processedFolders: [],
        editFolder: undefined,
        isCreateFolder: false,
        formInputs: [],
        formHiddenAttributes: [],
        isFormProcessing: false,
        isJobMenuOpen: false,
        teams: [],
        isCreateIntent: false,
        formHasChanges: false,
        isChangesModalOpen: false,
        storedFolderSelection: undefined,
        searchAccountName: '',
        storedAccounts: [],
        storedAccountOffset: 0,
        storedAccountTotalCount: 0
    }

    folderProvider = new FolderProvider();
    accountProvider = new AccountProvider();
    jobProvider = new JobProvider();
    structureProvider = new StructureProvider();

    componentDidMount() {
        this.fetchAccounts(this.state.currentAccountOffset);
        this.fetchRunningTasks();
        this.startTimeInterval();
    };
  
    componentWillUnmount() {
        const {
            rootStore: { adminStore }
        } = this.props;   
        const { runningTasks } = this.state;
        this.clearIntervals(runningTasks);
        adminStore.setCurrentExportTasks(runningTasks, MainTabs.Folders);
        this.clearTimeElapserInterval();
    }

    clearIntervals(tasks: RunningTasks[]) {
        tasks.forEach(element => {
            element.set_to_stop = true;
        });
    }

    clearTimeElapserInterval() {
        const { timeElapser } = this.state;
        if( timeElapser ) {
            window.clearInterval(timeElapser);
        }
    }

    startTimeInterval() {
        const { timeElapser } = this.state;
        if (timeElapser) {
            return;
        }
        const timeElapserInterval = window.setInterval(() => {
            const { runningTasks } = this.state;
            const time = Date.now();
            const processing = runningTasks.filter(e => e.status === 102);
            processing.forEach(element => {
                element.elapsedTime = Math.round((time - element.startedAt) / 1000);
            });
            this.setState({runningTasks});
        }, 1000);
        this.setState({timeElapser: timeElapserInterval});
    }

    fetchAccounts = (offset:number, limit: number = ACCOUNTS_PAGING_LIMIT) => {
        return this.accountProvider.getAllAccounts(offset, limit)
          .then((results) => {
            const {accounts, totalCount} = results;
            if (!accounts) {
                if (offset === 0) {
                    this.setState({networkError: true});
                }
                return;
            }

            this.setState({
                dataPageIsLoading: false,
                isAccountsLoading: false,
                totalAccounts: totalCount,
                accounts,
                currentAccountOffset: offset,
                isCreateFolder: false,
                editFolder: undefined
            });
        }).catch((error) => {
            const { status } = error.response;
            if (status === 401) {
                const { onAuthError } = this.props;
                if (!onAuthError) {
                    return;
                }
                onAuthError();
                return;
            }
            this.setState({networkError: true});
        });
    }

    searchAccounts = (searchName: string, offset?: number) => {
        // Nothing to search load the normal account list
        if (searchName === '') {
            const {
                storedAccounts,
                storedAccountOffset,
                storedAccountTotalCount
            } = this.state;

            // Empty search do nothing
            if (!storedAccounts.length) {
                return;
            }

            // Going back from actual seach
            this.setState({
                accounts: storedAccounts,
                currentAccountOffset: storedAccountOffset,
                totalAccounts: storedAccountTotalCount,
                searchAccountName: '',
                storedAccounts: [],
                storedAccountOffset: 0,
                storedAccountTotalCount: 0
            });
            return;
        }

        // We have a name search
        const {
            currentAccountOffset,
            accounts,
            totalAccounts,
            storedAccounts
        } = this.state;
        this.setState({
            isAccountsLoading: true,
            searchAccountName: searchName
        }, () => {
            const searchOffset = offset ? offset : 0;
            return this.accountProvider
                .searchAccountByNameShortSchema(searchName, searchOffset, ACCOUNTS_PAGING_LIMIT)
                .then(results => {
                    const { accounts: foundAccounts, totalCount } = results;
                    if (!foundAccounts) {
                        if (currentAccountOffset === 0) {
                            this.setState({ networkError: true });
                        }
                        return;
                    }
    
                    // If we haven't stored the original list do it
                    // This prevents multiple searchs overriding each others
                    if (!storedAccounts.length) {
                        this.setState({
                            storedAccounts: accounts,
                            storedAccountTotalCount: totalAccounts,
                            storedAccountOffset: currentAccountOffset
                        })
                    }

                    this.setState({
                        isAccountsLoading: false,
                        totalAccounts: totalCount,
                        accounts: foundAccounts,
                        currentAccountOffset: searchOffset
                    });
                })
                .catch(error => {
                    const { status } = error.response;
                    if (status === 401) {
                        const { onAuthError } = this.props;
                        if (!onAuthError) {
                            return;
                        }
                        onAuthError();
                        return;
                    }
                    this.setState({ networkError: true });
                });
        });
    }

    fetchRunningTasks = () => {
        const {
            rootStore: { adminStore }
        } = this.props;
        const tasks = adminStore.currentExportTasks.find(e => e.page === MainTabs.Folders);
        this.setState({
            runningTasks: tasks!.tasks
        }, () => {
            tasks!.tasks.forEach(element => {
                if(element.status === 102) {
                    // SET TO RESTART POOLING
                    element.set_to_stop = false;
                    this.startTaskWatcher(element);
                }
            });
        });
    }

    onAccountClicked = (account: Account, isRefresh?: boolean) => {
        const { currentAccount } = this.state;
        if (currentAccount === account && !isRefresh) {
            return;
        }

        this.setState({
            currentAccount: account,
            isCreateFolder: false,
            editFolder: undefined,
            storedFolderSelection: undefined
        });
        this.fetchFoldersFromAccount(account);
    };

    fetchFoldersFromAccount = (account: Account) => {
        this.setState({
            folderIsLoading: true,
            pickedType: PickedType.None,
            pickedId: '',
            processedFolders: []
        });

        return this.accountProvider.getRootFoldersByAccountId(account.accountId)
            .then((results) => {
                const folders: Folder[] = results;
                if(!folders) {
                    this.setState({networkError: true});
                    return;
                }

                // TRANSFORM THE FOLDERS INTO TREE VIEW
                const accountTree: RenderTree = {
                    id: account.accountId,
                    name: account.name,
                    children: [],
                    path: ''
                };

                const treeNodes: RenderTree[] = folders.map(element => {
                    const newItem = {
                        id: element.id,
                        name: element.name,
                        children: [],
                        path: `${element.id}__`
                    }
                    return newItem as RenderTree;
                });

                accountTree.children = treeNodes;

                this.setState({
                    folders,
                    currentNodeTree: accountTree
                }, () => {
                    this.fetchAccountsTeams(account.accountId);
                });

        }).catch((error) => {
            // tslint:disable-next-line:no-console
            console.error(error);
            const { status } = error.response;
            if (status === 401) {
                const { onAuthError } = this.props;
                if (!onAuthError) {
                    return;
                }
                onAuthError();
                return;
            }
            this.setState({networkError: true});
        });

    };

    fetchAccountsTeams = (accountId: string) => {
        this.accountProvider.fetchTeamsFromAccountId(accountId).then(data => {
            const { teams } = data;
            this.setState({
                folderIsLoading: false,
                teams: teams
            })
        }, error => {
            console.error(error);
            const { status } = error.response;
            if (status === 401) {
                const { onAuthError } = this.props;
                if (!onAuthError) {
                    return;
                }
                onAuthError();
                return;
            }
            this.setState({networkError: true});
        });
    }

    onAccountDataPageOverBoundaryReached = (boundary: PageBoundary, nextPage: number): Promise<void> => {
        return new Promise<void>((resolve, reject) => {
            const { 
                currentAccountOffset,
                totalAccounts
            } = this.state;
            const isFirstPage = nextPage === 0;
            const isLastPage = nextPage === (Math.ceil(totalAccounts / ROWS_PER_PAGE)-1);
            const newOffset = isFirstPage ? 0
                            : isLastPage ? ACCOUNTS_PAGING_LIMIT * Math.floor(totalAccounts / ACCOUNTS_PAGING_LIMIT)
                            : (boundary === PageBoundary.Upper) ? 
                                currentAccountOffset+ACCOUNTS_PAGING_LIMIT :
                                currentAccountOffset-ACCOUNTS_PAGING_LIMIT;
            this.setState({isAccountsLoading: true});
            this.fetchAccounts(newOffset)
              .then(() => resolve())
              .catch(()=> reject());
        });
    };

    onTaskDataPageOverBoundaryReached = (boundary: PageBoundary, nextPage: number): Promise<void> => {
        return new Promise<void>((resolve, reject) => {
            const { 
                currentTasksOffset,
                runningTasks
            } = this.state;
            const totalTasks = runningTasks.length;
            const isFirstPage = nextPage === 0;
            const isLastPage = nextPage === (Math.ceil(totalTasks / TASKS_ROWS_PER_PAGE)-1);
            const newOffset = isFirstPage ? 0
                            : isLastPage ? TASKS_PAGING_LIMIT * Math.floor(totalTasks / TASKS_PAGING_LIMIT)
                            : (boundary === PageBoundary.Upper) ? 
                            currentTasksOffset+TASKS_PAGING_LIMIT :
                            currentTasksOffset-TASKS_PAGING_LIMIT;
            const nextTasks = runningTasks;
            // MANUAL OFFSET
            this.setState({
                runningTasks: nextTasks.splice(newOffset, nextTasks.length)
            });
        });
    };

    onConfirmationDialogClicked = (result: DialogResult) => {
        // Closes the dialog
        this.setState({modalOpen: false});
    };

    deepTreeSearch = (treeData: RenderTree, searchId: string) => {
        let foundTree: RenderTree | undefined;
        let searchLevel: RenderTree[] | undefined = treeData.children;
        do {
            foundTree = searchLevel?.find((e: RenderTree) => e.id === searchId);
            let tempLevel: any = [];
            searchLevel?.forEach((element: RenderTree) => {
                tempLevel = tempLevel.concat(element.children);
            });
            searchLevel = tempLevel;
        } while(!foundTree);
        return foundTree;
    }

    updateChildrenOnPath = (treeData: RenderTree, nodeId: string, children: RenderTree[]) => {
        treeData.children!.forEach(element => {
            if (_.isEqual(element.id, nodeId)) {
                element.children = children;
            } else {
                this.updateChildrenOnPath(element, nodeId, children);
            }
        });
    }

    updateNameOnPath = (treeData: RenderTree, nodeId: string, name: string) => {
        treeData.children!.forEach(element => {
            if (_.isEqual(element.id, nodeId)) {
                element.name = name;
            } else {
                this.updateNameOnPath(element, nodeId, name);
            }
        });
    }

    onNodeSelected = (treeData: RenderTree , nodeId: string, nodeLabel: string, updateCallback: (nodeIds: string[]) => void) => {
        const { folders, currentAccount, processedFolders } = this.state;
        if(currentAccount!.accountId === nodeId) {
            this.setState({
                pickedType: PickedType.None,
                pickedId: '',
                pickedName: ''
            });
            return;
        }
        this.setState({treeIsLoading: true});
        // CHECK PICK TYPE
        // FOLDER
        const currentNodePos = this.deepTreeSearch(treeData, nodeId);
        if(folders.find(e => e.id === nodeId)) {
            // DID WE ALREADY CHECK FOR CHILDREN?
            if (processedFolders.find(e => e === nodeId)) {
                // YES WE HAVE
                this.setState({
                    pickedType: PickedType.Folder,
                    pickedId: nodeId,
                    pickedName: currentNodePos.name,
                    currentNodeTree: treeData,
                    treeIsLoading: false
                }, () => {
                    this.onFolderClick(PickedType.Folder, nodeId);
                });
                return;
            }
            // EMPTY
            // CHECK FOR FOLDER CHILDREN FIRST
            return this.accountProvider.getChildrenFolderByFolderId(currentAccount!.accountId, nodeId)
                .then((results: Folder[]) => {
                    if (results.length > 0) {
                        const nodes: RenderTree[] = results.map(element => {
                            const newItem = {
                                id: element.id,
                                name: element.name,
                                children: [],
                                path: `${currentNodePos.path}${element.id}__`
                            }
                            return newItem as RenderTree;
                        });
                        this.updateChildrenOnPath(treeData, nodeId, nodes);
                        const currentPath = currentNodePos.path.slice(0, -2);
                        updateCallback([currentAccount!.accountId, ...currentPath.split('__'), ...nodes.map(e => e.id)]);
                        processedFolders.push(nodeId);
                    }
                    this.setState({
                        pickedType: PickedType.Folder,
                        pickedId: nodeId,
                        pickedName: currentNodePos.name,
                        currentNodeTree: treeData,
                        treeIsLoading: false,
                        processedFolders,
                        folders: folders.concat(results),
                    }, () => {
                        this.onFolderClick(PickedType.Folder, nodeId);
                    });
                    return;
                    
            }).catch((error) => {
                // tslint:disable-next-line:no-console
                console.error(error);
                const { status } = error.response;
                if (status === 401) {
                    const { onAuthError } = this.props;
                    if (!onAuthError) {
                        return;
                    }
                    onAuthError();
                    return;
                }
                this.setState({networkError: true});
            });
        }
        this.setState({
            pickedType: PickedType.Folder,
            pickedId: nodeId,
            pickedName: currentNodePos.name,
            currentNodeTree: treeData,
            treeIsLoading: false,
        }, () => {
            this.onFolderClick(PickedType.Folder, nodeId);
        });
        return;
    }

    onNodeToggled = (nodeIds: string[]) => {
        return;
    }

    onFolderClick = (pickedTypeJob: PickedType, newPickedId: string) => {
        const { pickedType, pickedId, pickedName } = this.state;
        if(
            pickedType !== pickedTypeJob ||
            !pickedId ||
            !pickedName ||
            pickedId === '' ||
            pickedName === ''
        ) {
            return;
        }

        const { editFolder } = this.state;

        if (editFolder) {
            // CHECK THAT WE DONT RELOAD THE SAME FLOORPLAN
            if (editFolder.folder_id === newPickedId) {
                return;
            }

            // CHECK IF WE HAVE UNSAVED CHANGES AND INFORM THE USER
            const { formHasChanges } = this.state;
            if ( formHasChanges) {
                this.setState({isChangesModalOpen: true, isCreateIntent: false});
                return;
            }
        }

        this.setState({
            treeIsLoading: true,
            editFolder: undefined,
            isCreateFolder: false
        });

        this.folderProvider.getFolderById(pickedId).then((data) => {
            const { folder } = data;
            // SET THE TEMP FIELD TO STORE THE PUBLIC CONFIG ATTRIBUTE
            folder.user_app_visible = folder.public_config.user_app_visible;
            this.setState({
                editFolder: folder,
                treeIsLoading: false,
                formInputs: EDIT_FOLDER_FORM,
                formHiddenAttributes: EDIT_FOLDER_HIDDEN_ATTRIBUTES,
                pickedType: PickedType.None
            });
        }, error => {
            console.error(error);
            const { status } = error.response;
            if (status === 401) {
                const { onAuthError } = this.props;
                if (!onAuthError) {
                    return;
                }
                onAuthError();
                return;
            }
            this.setState({networkError: true});
        });
    }

    startTaskWatcher = (task: RunningTasks) => {
        const { runningTasks } = this.state;

        if (!task.export_name || !task.task_id || !task.export_id) {
            return;
        }

        const statusCallback = (taskId: string) => {
            const taskIndex = runningTasks.findIndex(e => e.task_id === taskId);
            const taskItem = runningTasks.find(e => e.task_id === taskId);

            if (!taskItem || taskItem.status !== 102) {
                return;
            }

            if (taskItem.set_to_stop) {
                return;
            }   
            
            this.jobProvider.checkJobStatus(taskItem.task_id).then(data => {
                if (data.job_status === StatusTypes.Error) {
                    taskItem.status = 500;
                    runningTasks[taskIndex] = taskItem;
                    this.setState({runningTasks});
                }
                if (data.job_status === StatusTypes.Complete) {
                    taskItem.status = 200;
                    taskItem.progress = 100.0;
                    this.jobProvider.checkJobResult(taskItem.task_id).then(res => {
                        const results = res.result as JobResultDataset;
                        taskItem.result_data = results.result;
                        runningTasks[taskIndex] = taskItem;
                        this.setState({runningTasks});
                    }).catch((error) => {
                        const { status } = error.response;
                        // CHECK IF WE TIMEOUT OR NOT
                        if (status === 408 || status === 504) {
                            // WE HAVE TIMEOUT INCREASE TIMER AND KEEP TRYING
                            taskItem.timer += 1000;
                            setTimeout(taskItem.internalCallback!, taskItem.timer, taskId);
                            return;
                        }
                        taskItem.status = 500;
                        runningTasks[taskIndex] = taskItem;
                        this.setState({runningTasks});
                    });
                } else {
                    // KEEP TRACK OF THE PROGRESS STATUS IF ITS STILL THE SAME
                    if (taskItem.progress === data.estimate_percent_complete) {
                        taskItem.static_progress_count += 1;
                    }
                    taskItem.progress = data.estimate_percent_complete;
                    // IF WE REACH A POINT WHERE THE STATUS IS THE SAME THE JOB IS SLOW
                    // MAKE FEWER REQUESTS TO NOT OVERWHELM THE STATUS ENDPOINT
                    if (taskItem.static_progress_count === STATIC_PROGRESS_LIMIT) {
                        taskItem.timer += 1000;
                        taskItem.static_progress_count = 0;
                    }
                    setTimeout(taskItem.internalCallback!, taskItem.timer, taskId);
                }
            }).catch((error) => {
                const { status } = error.response;
                // CHECK IF WE TIMEOUT OR NOT
                if (status === 408 || status === 504) {
                    // WE HAVE TIMEOUT INCREASE TIMER AND KEEP TRYING
                    taskItem.timer += 1000;
                    setTimeout(taskItem.internalCallback!, taskItem.timer, taskId);
                    return;
                }
                taskItem.status = 500;
                runningTasks[taskIndex] = taskItem;
                this.setState({runningTasks});
            });
        }

        // PREVENT GENERATING TIMERS WITHOUT PROPERTIES BEING INITIALIZED
        const taskIndex = runningTasks.findIndex(e => e.task_id === task.task_id);
        runningTasks[taskIndex].internalCallback = statusCallback;
        this.setState({runningTasks}, () => {
            setTimeout(statusCallback, task.timer, task.task_id);
        });
    }

    onTaskClick = (task: RunningTasks) => {
        if (task.status === 102) {
            return;
        }
        if(task.status === 200) {
            return;
        }
        this.jobProvider.checkJobError(task.task_id)
            .then((data) => {
                this.setState({
                    isNotificationModalOpened: true,
                    notificationModalHeader: data.error,
                    notificationModalText: data.stack_trace
                });
            }).catch((error) => {
                // tslint:disable-next-line:no-console
                console.error(error);
                this.setState({networkError: true});
            });
    }

    onTaskClear = () => {
        const { runningTasks } = this.state;
        const pendingTasks = runningTasks.filter(e => e.status === 102);
        const {
            rootStore: { adminStore }
        } = this.props;
        adminStore.setCurrentExportTasks(pendingTasks, MainTabs.Folders);
        this.setState({runningTasks: pendingTasks});
    }

    onNotificationModalClicked = () => {
        this.setState({isNotificationModalOpened: false});   
    }

    onCreateFolderClick = () => {
        const { currentAccount } = this.state;

        if (!currentAccount) {
            return;
        }
        
        // CHECK IF WE HAVE UNSAVED CHANGES AND INFORM THE USER
        const { formHasChanges } = this.state;
        if ( formHasChanges) {
            this.setState({isChangesModalOpen: true, isCreateIntent: true});
            return;
        }

        this.setState({
            isCreateFolder: false,
            editFolder: undefined
        });

        const newFolderTemplate: FolderFull = {
            allow_anonymous: false,
            channels: [],
            name: '',
            location: {
                coordinates: [
                    0, 0
                ],
                type:'Point'
            },
            email_domains: {},
            user_app_visible: false,
            position: '',
            public_config: {
                agree: true,
                auth: {
                    read: {
                        maintenance: true,
                        thermal: true
                    },
                    write: {
                        maintenance: true,
                        thermal: true
                    }
                },
                comment: true,
                enabled: true,
                marker_visibility: true,
                user_app_public: false,
                user_app_visible: false
            },
            type: 'folder',
            structure_id: currentAccount.structures[0],
            folder_id: '',
            _id: ''
        };
        setTimeout(() => {
            this.setState({
                isCreateFolder: true,
                editFolder: newFolderTemplate,
                formInputs: CREATE_FOLDER_FORM,
                formHiddenAttributes: CREATE_FOLDER_HIDDEN_ATTRIBUTES
            });
        }, 500);
    }

    addFolderToStructureById = (structureId: string, folderPath: string, newFolder: FolderFull) => {
        this.structureProvider.getStructureById(structureId).then(data => {
            const { structure } = data;
            const { structure: definedStructure } = structure;

            // FIRST GET THE OBJECT THAT WILL HOUSE THE FOLDER
            const holderObject = _.get(definedStructure, folderPath);
            // ADD THE NEW FOLDER KEY TO THE PATH
            holderObject[newFolder.folder_id] = {};
            // UPDATE THE STRUCTURE OBJECT
            _.update(definedStructure, folderPath, () => { return holderObject});
            // SEND THE UPDATE
            const newFolderPath = `${folderPath.split(".").join("__")}__`;
            const parentId = folderPath.split(".").at(-1) as string;
            this.updateStructure(structureId, structure, newFolder, parentId, newFolderPath);
        }).catch(error => {
            console.error(error);
            const { status } = error.response;
            if (status === 401) {
                const { onAuthError } = this.props;
                if (!onAuthError) {
                    return;
                }
                onAuthError();
                return;
            }
            this.setState({
                isNotificationModalOpened: true,
                notificationModalHeader: `Error fetching structure: ${status}`,
                notificationModalText: error.stack_trace
            });
        });
    }

    updateStructure = (structureId: string, structure: Structure, newFolder: FolderFull, parentFolder: string, fullPath: string) => {
        this.setState({
            isFormProcessing: true,
            treeIsLoading: true
        });

        const { currentAccount, folders, currentNodeTree } = this.state;
        this.structureProvider.updateStructure(structureId, structure).then(data => {
            const { structure } = data;

            if (structure.jobs) {
                const {
                    rootStore: { adminStore }
                } = this.props;
                const { runningTasks } = this.state;
                const jobId = Object.keys(structure.jobs)[0];
                const newTask: RunningTasks = {
                    account: currentAccount ? currentAccount : {} as Account,
                    export_name: 'Updating folder structure',
                    export_id: structure!.structure_id,
                    type: 0,
                    task_id: jobId,
                    status: 102,
                    startedAt: Date.now(),
                    elapsedTime: 0,
                    progress: 0,
                    timer: 1500,
                    static_progress_count: 0,
                        set_to_stop: false
                };
                runningTasks.push(newTask);
                adminStore.setCurrentExportTasks(runningTasks, MainTabs.Folders);
                this.startTaskWatcher(newTask);
                this.startTimeInterval();
                this.setState({runningTasks});
            }
 
            this.setState({
                isFormProcessing: false,
                treeIsLoading: false,
                isNotificationModalOpened: true,
                notificationModalHeader: 'Success',
                notificationModalText: 'Structure succesfully updated'
            }, () => {
                // CHECK WETHER OR NOT THE PICKED FOLDER IS IN THE CURRENT VIEW
                const checkFolder = folders.find(e => e.id === parentFolder);
                if (checkFolder) {
                    // YES IT IS
                    const existingNode = this.deepTreeSearch(currentNodeTree, parentFolder);
                    const newChildren = [...existingNode.children!, {
                        children: [],
                        id: newFolder.folder_id,
                        name: newFolder.name,
                        path: fullPath
                    }];
                    this.updateChildrenOnPath(currentNodeTree, parentFolder, newChildren);
                } else {
                    // NO IT ISN'T REFRESH EVERYTHING
                    this.onAccountClicked(currentAccount!, true);
                }
            });
        }).catch(error => {
            const { status } = error.response;
            if (status === 401) {
                const { onAuthError } = this.props;
                if (!onAuthError) {
                    return;
                }
                onAuthError();
                return;
            }
            this.setState({
                isNotificationModalOpened: true,
                notificationModalHeader: `Error updating structure: ${status}`,
                notificationModalText: error.stack_trace
            });
        });
    }

    onFormSubmit = (formFolder: FolderFull, storedFolderSelection?: StoredTreeSelection) => {
        const { isCreateFolder, editFolder } = this.state;

        if (!editFolder) {
            return;
        }
        
        formFolder.public_config.user_app_visible = formFolder.user_app_visible!;
        delete formFolder.user_app_visible;
        this.setState({
            isChangesModalOpen: false,
            formHasChanges: false,
            storedFolderSelection: storedFolderSelection
        }, () => {
            if (isCreateFolder) {
                this.onCreateSubmit(formFolder);
                return;
            }
    
            this.onEditSubmit(formFolder);
        });
    }

    onCreateSubmit = (newFolder: FolderFull) => {
        this.setState({
            isFormProcessing: true,
            treeIsLoading: true
        });

        const { currentAccount } = this.state;
        const { position } = newFolder;

        if (!position) {
            return;
        }
        this.folderProvider.createFolder(newFolder).then(data => {
            // FIRST WE CREATE THE FOLDER OBJECT
            const { folder } = data;
            
            if (folder.jobs) {
                const {
                    rootStore: { adminStore }
                } = this.props;
                const { runningTasks } = this.state;
                const jobId = Object.keys(folder.jobs)[0];
                const newTask: RunningTasks = {
                    account: currentAccount ? currentAccount : {} as Account,
                    export_name: `Creating folder: ${newFolder.name}`,
                    export_id: folder.folder_id,
                    type: 0,
                    task_id: jobId,
                    status: 102,
                    startedAt: Date.now(),
                    elapsedTime: 0,
                    progress: 0,
                    timer: 1500,
                    static_progress_count: 0,
                        set_to_stop: false
                };
                runningTasks.push(newTask);
                adminStore.setCurrentExportTasks(runningTasks, MainTabs.Folders);
                this.startTaskWatcher(newTask);
                this.startTimeInterval();
                this.setState({runningTasks});
            }
            
            // WITH THE FOLDER ID WE UPDATE THE STRUCTURE OBJECT ON THE DESIRED LOCATION
            this.addFolderToStructureById(newFolder.structure_id, position, folder);

            this.setState({
                treeIsLoading: false,
                isFormProcessing: false,
                isCreateFolder: false,
                editFolder: undefined,
                isNotificationModalOpened: true,
                notificationModalHeader: 'Success',
                notificationModalText: 'Folder succesfully created'
            });
        }).catch(error => {
            const { status, data } = error.response;
            if (status === 401) {
                const { onAuthError } = this.props;
                if (!onAuthError) {
                    return;
                }
                onAuthError();
                return;
            }
            this.setState({
                treeIsLoading: false,
                isFormProcessing: false,
                isNotificationModalOpened: true,
                notificationModalHeader: `Error creating folder: ${status}`,
                notificationModalText: data.message ? data.message : error.stack_trace
            });
        });
    }

    onEditSubmit = (updatedFolder: FolderFull) => {
        this.setState({
            isFormProcessing: true,
            treeIsLoading: true
        });

        const { pickedId, currentAccount, currentNodeTree } = this.state;
        this.folderProvider.updateFolder(pickedId, updatedFolder).then(data => {

            const { folder } = data;
            if (folder.jobs) {
                const {
                    rootStore: { adminStore }
                } = this.props;
                const { runningTasks } = this.state;
                const jobId = Object.keys(folder.jobs)[0];
                const newTask: RunningTasks = {
                    account: currentAccount ? currentAccount : {} as Account,
                    export_name: `Updating folder: ${updatedFolder.name}`,
                    export_id: updatedFolder!.folder_id,
                    type: 0,
                    task_id: jobId,
                    status: 102,
                    startedAt: Date.now(),
                    elapsedTime: 0,
                    progress: 0,
                    timer: 1500,
                    static_progress_count: 0,
                        set_to_stop: false
                };
                runningTasks.push(newTask);
                adminStore.setCurrentExportTasks(runningTasks, MainTabs.Folders);
                this.startTaskWatcher(newTask);
                this.startTimeInterval();
                this.setState({runningTasks});
            }
 
            this.setState({
                isNotificationModalOpened: true,
                notificationModalHeader: 'Success',
                notificationModalText: 'Folder succesfully updated',
                treeIsLoading: false,
                isFormProcessing: false,
                isCreateFolder: false,
                editFolder: undefined,
            }, () => {
                // INSTEAD OF RELOADING THE WHOLE TREE COMPONENT DO A DEEP UPDATE
                this.updateNameOnPath(currentNodeTree, folder.folder_id, folder.name);
            });
        }).catch(error => {
            const { status, data } = error.response;
            if (status === 401) {
                const { onAuthError } = this.props;
                if (!onAuthError) {
                    return;
                }
                onAuthError();
                return;
            }
            this.setState({
                treeIsLoading: false,
                isFormProcessing: false,
                isNotificationModalOpened: true,
                notificationModalHeader: `Error updating folder: ${status}`,
                notificationModalText: data.message ? data.message : error.stack_trace
            });
        })
    }

    onJobMenuToggle = () => {
        const { isJobMenuOpen } = this.state;
        this.setState({isJobMenuOpen: !isJobMenuOpen});
    }

    getJobCount = (needBoolean: Boolean) => {
        const { runningTasks } = this.state;

        const finishedCount = runningTasks.filter(e => e.status !== 102).length;
        const totalCount = runningTasks.length;

        if (needBoolean) {
            return finishedCount === totalCount;
        }

        return `${finishedCount}/${totalCount}`;
    }

    onFormChanges = () => {
        this.setState({formHasChanges: true});
    }

    onChangesModalClicked = (dialogResult: DialogResult) => {
        if (!dialogResult) {
            return;
        }

        if (dialogResult === DialogResult.Ok) {
            const { pickedId, isCreateIntent } = this.state;

            this.setState({
                formHasChanges: false
            }, () => {
                if(isCreateIntent) {
                    this.onCreateFolderClick();
                } else {
                    this.onFolderClick(PickedType.Folder, pickedId);
                }
            });
        }
        this.setState({isChangesModalOpen: false});
    }

    public render() {
        const {
            classes
        } = this.props;
        const {
            accounts,
            currentAccount,
            currentAccountOffset,
            networkError,
            dataPageIsLoading,
            isAccountsLoading,
            totalAccounts,
            modalOpen,
            modalText,
            folderIsLoading,
            mainSpinnerText,
            folders,
            currentNodeTree,
            treeIsLoading,
            pickedType,
            pickedId,
            runningTasks,
            currentTasksOffset,
            isNotificationModalOpened,
            notificationModalHeader,
            notificationModalText,
            editFolder,
            isCreateFolder,
            formInputs,
            formHiddenAttributes,
            isFormProcessing,
            isJobMenuOpen,
            teams,
            isChangesModalOpen,
            storedFolderSelection
        } = this.state

        const currentAccountName = currentAccount ? currentAccount.name : '';
        const container = window !== undefined ? () => window.document.body : undefined;

        return (
            <div className={classes.container} data-testid="mainRender">
                <CCSpinner
                    label ={mainSpinnerText}
                    labelClassName={classes.mainSpinnerLabel}
                    className={classes.progressContainer}
                    loading={dataPageIsLoading}
                    size={200}
                >
                    <AccountFolders
                        className={`${classes.animContainer} ${classes.fullContainerSize}`}
                        rowsPerPage={ROWS_PER_PAGE}
                        accounts={accounts}
                        accountsIsLoading={isAccountsLoading}
                        accountsTotalItems={totalAccounts}
                        accountsItemsOffset={currentAccountOffset}
                        currentAccountName={currentAccountName}
                        folders={folders}
                        folderIsLoading={folderIsLoading}
                        onAccountClick={this.onAccountClicked}
                        onAccountDataPageOverBoundary={
                            this.onAccountDataPageOverBoundaryReached
                        }
                        currentNodeTree={currentNodeTree}
                        treeIsLoading={treeIsLoading}
                        onNodeSelected={this.onNodeSelected}
                        onNodeToggled={this.onNodeToggled}
                        pickedType={pickedType}
                        pickedId={pickedId}
                        onFolderActionButtonClick={this.onCreateFolderClick}
                        isActionButtonEnabled={true}
                        folderActionButtonLabel="Add new Folder"
                        onAccountSearch={this.searchAccounts}
                    />
                    {
                        (editFolder || isCreateFolder) && 
                            <FolderForm
                                className={classes.formContainer}
                                currentAccount={currentAccount}
                                pickedFolder={editFolder}
                                formFields={formInputs}
                                hiddenAttributes={formHiddenAttributes}
                                isProcessing={isFormProcessing}
                                isCreating={isCreateFolder}
                                onFormSubmit={this.onFormSubmit}
                                teams={teams}
                                onFormChanges={this.onFormChanges}
                                storedFolderSelection={storedFolderSelection}
                            />
                    }
                    {
                        (runningTasks && (runningTasks.length > 0)) &&
                        <React.Fragment>
                            <Button
                                className={classes.jobMenuButton}
                                variant="contained"
                                color="primary"
                                data-testid="marker-detail-job-drawer-button"
                                onClick={this.onJobMenuToggle}
                            >
                                Running Jobs
                                <Chip
                                    label={this.getJobCount(false)}
                                    className={`${this.getJobCount(true) ? classes.finishedBadge : classes.notFinishedBadge}`}
                                />
                            </Button>
                            <Drawer
                                container={container}
                                variant={"temporary"}
                                anchor={"right"}
                                open={isJobMenuOpen}
                                onClose={this.onJobMenuToggle}
                                classes={{
                                    paper: classes.paper,
                                }}
                            >
                                <RunningTasksList
                                    className={classes.tasksContainerSize}
                                    tasks={runningTasks}
                                    rowsPerPage={ROWS_PER_PAGE}
                                    taskItemsOffset={currentTasksOffset}
                                    listName="Running Operations"
                                    onTaskClear={this.onTaskClear}
                                    onDrawerClose={this.onJobMenuToggle}
                                    onTaskClick={this.onTaskClick}
                                />
                            </Drawer>
                        </React.Fragment>
                    }
                </CCSpinner>
                <SimpleDialog
                    open={isChangesModalOpen}
                    titleText="You have unsaved changes"
                    contentText="Do you wish to discard them?"
                    buttonCancelLabel="No"
                    buttonOkLabel="Yes"
                    onDialogResult={this.onChangesModalClicked}
                />
                <SimpleDialog
                    open={isNotificationModalOpened}
                    titleText={notificationModalHeader}
                    contentText={notificationModalText}
                    buttonCancelLabel=""
                    onDialogResult={this.onNotificationModalClicked}
                />
                {
                    networkError ?
                        <SimpleModal
                            className={classes.errorPopup}
                            open={networkError}
                            contentClasses= {classes.errorPopupContentContainer}
                            buttonOkLabel=""
                            buttonCancelLabel=""
                            header=''
                            headerClassName={classes.errorPopupHeader}
                        >
                            <Error color="error" className={classes.errorPopupIcon} />
                            <div>
                                <Typography variant="h5">
                                    {'Network Error'}
                                </Typography>
                                <Typography variant="subtitle1">
                                    {'Please reload the page.'}
                                </Typography>
                            </div>
                        </SimpleModal>
                    :
                        <SimpleDialog
                            open={modalOpen}
                            titleText="Please confirm"
                            contentText={modalText}
                            onDialogResult={this.onConfirmationDialogClicked}
                        />
                }
            </div>
        );
    }
}

const MUIComponent = withStyles(styles)(Folders);
export {MUIComponent as Folders};
