import { Marker } from './../models/marker';
import { HttpConnectorAdapter } from './../adapters/http-connector-adapter';

export default class MarkerProvider {
    private endpointAdmin: string;

    constructor() {
        this.endpointAdmin = `${process.env.REACT_APP_ADMIN_TOOL_API}marker`;
    };

    init = () => {
        return HttpConnectorAdapter;
    };

    fetchMinimapUrl = (markerId: string) => {
        return this.init().getFileWithCredentials(`${this.endpointAdmin}/${markerId}/minimap`).then(response => {
            const statusCode = response.status;

            return { status: statusCode, file_blob: response.data };
        });
    }

    updateMarker = (marker: Marker) => {
        delete marker['_rev'];
        delete marker['_sync'];
        const headers = { 'Content-Type': 'application/json' };
        return this.init().putWithCredentials(`${this.endpointAdmin}/${marker.marker_id}`, JSON.stringify(marker), headers).then(response => {
            const statusCode = response.status;

            return { status: statusCode, marker: response.data as Marker };
        });
    }
}