import {
    RouterState,
    RouterStore
} from 'mobx-state-router';
import { HttpConnectorAdapter } from '../adapters';
import { RootStoreBase } from './root.store.base';
import { MainTabs } from './admin.store';

// Routes are matched from top to bottom. Make sure they are sequenced
// in the order of priority. It is generally best to sort them by pattern,
// prioritizing specific patterns over generic patterns (patterns with
// one or more parameters). For example:
//     /items
//     /items/:id


export const ROUTE_NAMES = {
    NOT_FOUND: 'not-found',
    HOME: 'home',
    LOGIN: 'login',
    MARKERS: 'markers',
    QR_CODES: 'qr',
    QR_CODES_WITH_PARAM:'qr-param',
    MARKER_EXPORT: 'marker-export',
    TEAM_INFO: 'team-info',
    USER_EXPORT: 'user-export',
    MARKER_OPERATIONS: 'marker-operations',
    MARKER_DETAIL: 'marker-detail',
    TEAMS: 'teams',
    FOLDERS: 'folders',
    ACCOUNTS: 'accounts',
    MODULE_GROUPS: 'module-groups',
    FLOORPLAN_REPLACE: 'floorplan-replace',
    SCHEDULING_PERMISSIONS: 'scheduling-permissions'
}

const checkForUserSignedIn = (fromState: RouterState, toState: RouterState, routerStore: RouterStore) => {
    // Redirect to Login Page if the user isn't logged in
    const {
    rootStore: { 
        adminStore
    }
    }: { rootStore: RootStoreBase } = routerStore;
    const {
        isUserLoggedIn,
        loginCredentials,
        userIdentifiers
    } = adminStore;
    if (!isUserLoggedIn || !loginCredentials || !userIdentifiers) {
        // Save the redirection state
        adminStore.setLogInRedirect(toState);

        // Redirects to the login page 
        return Promise.reject(new RouterState(ROUTE_NAMES.LOGIN));
    }
    HttpConnectorAdapter.setCredentials(loginCredentials);
    HttpConnectorAdapter.setUserIdentifiers(userIdentifiers);
    return Promise.resolve();
}

const openQrCodePage = (floorPlanId: string, routerStore: RouterStore) => {
    const {
        rootStore: {
            adminStore: { 
                changeCurrentTab,
                setFloorPlanId
            }
        }
    }: { rootStore: RootStoreBase } = routerStore;
    
    setFloorPlanId(floorPlanId);
    changeCurrentTab(MainTabs.QR_Code);

    return Promise.resolve();
}

export const routes = [
    { 
        name: ROUTE_NAMES.HOME,
        pattern: '/',
        beforeEnter: checkForUserSignedIn,
        onEnter: (fromState: RouterState, toState: RouterState, routerStore: RouterStore): Promise<void> => {
            const {
                rootStore: { 
                    adminStore: { changeCurrentTab }
                }
            }: { rootStore: RootStoreBase } = routerStore;
            changeCurrentTab(MainTabs.AccountInfo);
            
            return Promise.resolve();
        }
    },
    { 
        name: ROUTE_NAMES.MARKERS,
        pattern: `/${ROUTE_NAMES.MARKERS}`,
        beforeEnter: checkForUserSignedIn,
        onEnter: (fromState: RouterState, toState: RouterState, routerStore: RouterStore): Promise<void> => {
            const {
                rootStore: { 
                    adminStore: { changeCurrentTab }
                }
            }: { rootStore: RootStoreBase } = routerStore;
            changeCurrentTab(MainTabs.Markers);

            return Promise.resolve();
        }
    },
    { 
        name: ROUTE_NAMES.QR_CODES,
        pattern: `/${ROUTE_NAMES.QR_CODES}`,
        beforeEnter: checkForUserSignedIn,
        onEnter: (fromState: RouterState, toState: RouterState, routerStore: RouterStore): Promise<void> => {
            return openQrCodePage('', routerStore);
        }
    },
    { 
        name: ROUTE_NAMES.QR_CODES_WITH_PARAM,
        pattern: `/${ROUTE_NAMES.QR_CODES}/:floorPlanId`,
        beforeEnter: checkForUserSignedIn,
        onEnter: (fromState: RouterState, toState: RouterState, routerStore: RouterStore): Promise<void> => {
            const {
                floorPlanId
            } = toState.params;
            
            return openQrCodePage(floorPlanId, routerStore);
        }
    },
    {
        name: ROUTE_NAMES.NOT_FOUND,
        pattern: `/${ROUTE_NAMES.NOT_FOUND}`
    },
    {
        name: ROUTE_NAMES.LOGIN,
        pattern: `/${ROUTE_NAMES.LOGIN}`,
        onEnter: (fromState: RouterState, toState: RouterState, routerStore: RouterStore): Promise<void> => {
            // TO-DO: The store stuff for the page
            return Promise.resolve();
        }
    },
    { 
        name: ROUTE_NAMES.MARKER_EXPORT,
        pattern: `/${ROUTE_NAMES.MARKER_EXPORT}`,
        beforeEnter: checkForUserSignedIn,
        onEnter: (fromState: RouterState, toState: RouterState, routerStore: RouterStore): Promise<void> => {
            const {
                rootStore: { 
                    adminStore: { changeCurrentTab }
                }
            }: { rootStore: RootStoreBase } = routerStore;
            changeCurrentTab(MainTabs.Marker_Export);

            return Promise.resolve();
        }
    },
    { 
        name: ROUTE_NAMES.TEAM_INFO,
        pattern: `/${ROUTE_NAMES.TEAM_INFO}`,
        beforeEnter: checkForUserSignedIn,
        onEnter: (fromState: RouterState, toState: RouterState, routerStore: RouterStore): Promise<void> => {
            const {
                rootStore: { 
                    adminStore: { changeCurrentTab }
                }
            }: { rootStore: RootStoreBase } = routerStore;
            changeCurrentTab(MainTabs.Team_Info);

            return Promise.resolve();
        }
    },
    { 
        name: ROUTE_NAMES.USER_EXPORT,
        pattern: `/${ROUTE_NAMES.USER_EXPORT}`,
        beforeEnter: checkForUserSignedIn,
        onEnter: (fromState: RouterState, toState: RouterState, routerStore: RouterStore): Promise<void> => {
            const {
                rootStore: { 
                    adminStore: { changeCurrentTab }
                }
            }: { rootStore: RootStoreBase } = routerStore;
            changeCurrentTab(MainTabs.User_Export);

            return Promise.resolve();
        }
    },
    { 
        name: ROUTE_NAMES.MARKER_OPERATIONS,
        pattern: `/${ROUTE_NAMES.MARKER_OPERATIONS}`,
        beforeEnter: checkForUserSignedIn,
        onEnter: (fromState: RouterState, toState: RouterState, routerStore: RouterStore): Promise<void> => {
            const {
                rootStore: { 
                    adminStore: { changeCurrentTab }
                }
            }: { rootStore: RootStoreBase } = routerStore;
            changeCurrentTab(MainTabs.Marker_Operations);

            return Promise.resolve();
        }
    },
    { 
        name: ROUTE_NAMES.MARKER_DETAIL,
        pattern: `/${ROUTE_NAMES.MARKER_DETAIL}`,
        beforeEnter: checkForUserSignedIn,
        onEnter: (fromState: RouterState, toState: RouterState, routerStore: RouterStore): Promise<void> => {
            const {
                rootStore: { 
                    adminStore: { changeCurrentTab }
                }
            }: { rootStore: RootStoreBase } = routerStore;
            changeCurrentTab(MainTabs.Marker_Detail);

            return Promise.resolve();
        }
    },
    { 
        name: ROUTE_NAMES.TEAMS,
        pattern: `/${ROUTE_NAMES.TEAMS}`,
        beforeEnter: checkForUserSignedIn,
        onEnter: (fromState: RouterState, toState: RouterState, routerStore: RouterStore): Promise<void> => {
            const {
                rootStore: { 
                    adminStore: { changeCurrentTab }
                }
            }: { rootStore: RootStoreBase } = routerStore;
            changeCurrentTab(MainTabs.Teams);

            return Promise.resolve();
        }
    },
    { 
        name: ROUTE_NAMES.FOLDERS,
        pattern: `/${ROUTE_NAMES.FOLDERS}`,
        beforeEnter: checkForUserSignedIn,
        onEnter: (fromState: RouterState, toState: RouterState, routerStore: RouterStore): Promise<void> => {
            const {
                rootStore: { 
                    adminStore: { changeCurrentTab }
                }
            }: { rootStore: RootStoreBase } = routerStore;
            changeCurrentTab(MainTabs.Folders);

            return Promise.resolve();
        }
    },
    { 
        name: ROUTE_NAMES.ACCOUNTS,
        pattern: `/${ROUTE_NAMES.ACCOUNTS}`,
        beforeEnter: checkForUserSignedIn,
        onEnter: (fromState: RouterState, toState: RouterState, routerStore: RouterStore): Promise<void> => {
            const {
                rootStore: { 
                    adminStore: { changeCurrentTab }
                }
            }: { rootStore: RootStoreBase } = routerStore;
            changeCurrentTab(MainTabs.Accounts);

            return Promise.resolve();
        }
    },
    { 
        name: ROUTE_NAMES.FLOORPLAN_REPLACE,
        pattern: `/${ROUTE_NAMES.FLOORPLAN_REPLACE}`,
        beforeEnter: checkForUserSignedIn,
        onEnter: (fromState: RouterState, toState: RouterState, routerStore: RouterStore): Promise<void> => {
            const {
                rootStore: { 
                    adminStore: { changeCurrentTab }
                }
            }: { rootStore: RootStoreBase } = routerStore;
            changeCurrentTab(MainTabs.Floorplan_Replace);

            return Promise.resolve();
        }
    },
    { 
        name: ROUTE_NAMES.MODULE_GROUPS,
        pattern: `/${ROUTE_NAMES.MODULE_GROUPS}`,
        beforeEnter: checkForUserSignedIn,
        onEnter: (fromState: RouterState, toState: RouterState, routerStore: RouterStore): Promise<void> => {
            const {
                rootStore: { 
                    adminStore: { changeCurrentTab }
                }
            }: { rootStore: RootStoreBase } = routerStore;
            changeCurrentTab(MainTabs.Module_Groups);

            return Promise.resolve();
        }
    },
    { 
        name: ROUTE_NAMES.SCHEDULING_PERMISSIONS,
        pattern: `/${ROUTE_NAMES.SCHEDULING_PERMISSIONS}`,
        beforeEnter: checkForUserSignedIn,
        onEnter: (fromState: RouterState, toState: RouterState, routerStore: RouterStore): Promise<void> => {
            const {
                rootStore: { 
                    adminStore: { changeCurrentTab }
                }
            }: { rootStore: RootStoreBase } = routerStore;
            changeCurrentTab(MainTabs.Scheduling_Permissions);

            return Promise.resolve();
        }
    }
];
