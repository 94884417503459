import { FloorplanFull, Marker } from '../models';
import { HttpConnectorAdapter } from './../adapters/http-connector-adapter';
import _ from "lodash";

export interface PDF2JPGConvertion {
    image: string;
    dimensions: {
        cropbox: number[];
        mediabox: number[];
        rotation: number;
    }
}

export default class FloorplanProvider {
    private endpointAdmin: string;
    private memoizedSort = _.memoize((arr: any[], sortAttribute: string) => arr.sort((a, b) => a[sortAttribute].localeCompare(b[sortAttribute])));

    constructor() {
        this.endpointAdmin = `${process.env.REACT_APP_ADMIN_TOOL_API}floorplan`;
    };

    init = () => {
        return HttpConnectorAdapter;
    };

    fetchMarkers = (floorplanId: string) => {
        return this.init().getWithCredentials(`${this.endpointAdmin}/${floorplanId}/markers`).then(response => {
            const { markers } = response.data;
            const sortedMarkers = this.memoizedSort([...markers], "name");
            return sortedMarkers.map((marker: unknown) => {
                return marker as Marker;
            });
        });
    }

    downloadFloorplanPDF = (floorplanId: string) => {
        return this.init().getFileWithCredentials(`${this.endpointAdmin}/${floorplanId}/pdf`).then(response => {
            const statusCode = response.status;

            return { status: statusCode, file_blob: response.data };
        });
    }

    uploadFloorplanPDF = (floorplanId: string, floorplanPdf: Blob) => {
        const formData = new FormData();
        formData.append('floorplan_file', floorplanPdf);
        return this.init().postWithCredentials(`${this.endpointAdmin}/${floorplanId}/pdf`, formData).then(response => {
            const { file_url } = response.data;
            const statusCode = response.status;

            return { status: statusCode, file_url };
        });
    }

    fetchMarkersInXFDF = (floorplanId: string) => {
        return this.init().getWithCredentials(`${this.endpointAdmin}/${floorplanId}/xfdf_markers`).then(response => {
            const statusCode = response.status ? response.status : 204;
            const { markers } = response.data ? response.data : [];

            return { status: statusCode, markers };
        });
    }

    fetchMarkersInCanvas = (floorplanId: string) => {
        return this.init().getWithCredentials(`${this.endpointAdmin}/${floorplanId}/canvas_markers`).then(response => {
            const statusCode = response.status ? response.status : 204;
            const { markers } = response.data ? response.data : [];

            return { status: statusCode, markers };
        });
    }

    generateFloorplan = (
        folderId: string, 
        floorPlanName:string,
        floorPlanFile: File,
        markersFile?: File,
        markersPrefix: string = ''
    ) => {
        const formData = new FormData();
        formData.append('folder_id', folderId);
        formData.append('floorplan_name', floorPlanName);
        formData.append('floorplan_file', floorPlanFile);
        formData.append('markers_prefix', markersPrefix);
        if (markersFile) {
            formData.append('markers_file', markersFile);
        }
        return this.init().postWithCredentials(`${this.endpointAdmin}/`, formData).then(response => {
            const { jobs = undefined, _id, compressed } = response.data;
            const statusCode = response.status;

            return { status: statusCode, job_id: jobs ? Object.keys(jobs)[0] : undefined, floorplan_id: _id.split('::')[1], compressed };
        });
    }

    getFloorplanById = (floorplanId: string) => {
        return this.init().getWithCredentials(`${this.endpointAdmin}/${floorplanId}`).then(response => {
            const statusCode = response.status;

            return { status: statusCode, floorplan: response.data as FloorplanFull };
        });
    }

    updateFloorplan = (floorplanId: string, floorplan: FloorplanFull) => {
        const formattedFloorplan: any = floorplan;
        delete formattedFloorplan['_rev'];
        delete formattedFloorplan['_sync'];
        const headers = { 'Content-Type': 'application/json' };
        return this.init().putWithCredentials(`${this.endpointAdmin}/${floorplanId}`, JSON.stringify(formattedFloorplan), headers).then(response => {
            const statusCode = response.status;

            return { status: statusCode, floorplan: response.data as FloorplanFull };
        });
    }

    deleteFloorplan = (floorplanId: string) => {
        return this.init().deleteWithCredentials(`${this.endpointAdmin}/${floorplanId}`).then(response => {
            const statusCode = response.status;
            const { job } = response.data;
            
            return { status: statusCode, job };
        });
    }

    convertPDF2JPG = (floorplanId: string, floorPlanFile: File) => {
        const formData = new FormData();
        formData.append('floorplan_file', floorPlanFile);
        return this.init().postWithCredentials(`${this.endpointAdmin}/${floorplanId}/convert_pdf_image`, formData).then(response => {
            const statusCode = response.status;

            return { status: statusCode, image: response.data as PDF2JPGConvertion };
        });
    }
}