import { UserModuleGroup } from '../models/module-group';
import { HttpConnectorAdapter } from './../adapters/http-connector-adapter';

export default class UserModuleGroupProvider {
    private endpointAdmin: string;

    constructor() {
        this.endpointAdmin = `${process.env.REACT_APP_ADMIN_TOOL_API}user_module_group`;
    }

    init = () => {
        return HttpConnectorAdapter;
    };

    fetchModuleGroup = (moduleId: string) => {
        return this.init()
        .getWithCredentials(`${this.endpointAdmin}/${moduleId}`)
        .then(response => {
            const statusCode = response.status;
            const module = response.data as UserModuleGroup;

            return { status: statusCode, module: module };
        });
    };

    updateUserModuleGroup = (moduleGroup: UserModuleGroup) => {
        const newModuleGroup = {...moduleGroup} as any;
        delete newModuleGroup['_rev'];
        delete newModuleGroup['_sync'];
        delete newModuleGroup['flag_to_clone'];
        delete newModuleGroup['has_updates'];
        delete newModuleGroup['data_visualizations'];
        const headers = { 'Content-Type': 'application/json' };
        return this.init().putWithCredentials(`${this.endpointAdmin}/${moduleGroup.module_group_id}`, JSON.stringify(newModuleGroup), headers).then(response => {
            const moduleGroup = response.data as UserModuleGroup;
            const statusCode = response.status;

            return { status: statusCode, moduleGroup };
        });
    };


    createUserModuleGroup = (moduleGroup: UserModuleGroup) => {
        const newModuleGroup = {...moduleGroup} as any;
        delete newModuleGroup['_id'];
        delete newModuleGroup['timestamp'];
        delete newModuleGroup['module_group_id'];
        delete newModuleGroup['_rev'];
        delete newModuleGroup['_sync'];
        delete newModuleGroup['flag_to_clone'];
        delete newModuleGroup['has_updates'];
        delete newModuleGroup['last_modified'];
        const headers = { 'Content-Type': 'application/json' };
        return this.init().postWithCredentials(`${this.endpointAdmin}/`, JSON.stringify(newModuleGroup), headers).then(response => {
            const moduleGroup = response.data as UserModuleGroup;
            const statusCode = response.status;

            return { status: statusCode, moduleGroup };
        });
    };
}