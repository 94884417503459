import { Button, MenuItem, Paper, Select, TextField, Theme, Typography, withStyles } from "@material-ui/core";
import { createStyles, WithStyles } from "@material-ui/styles";
import { inject, observer } from "mobx-react";
import { RootStore } from "../../stores/root.store";
import React from 'react';
import JobProvider from "../../providers/job.provider";
import ModuleGroupProvider from "../../providers/modulegroup.provider";
import { AccountFull } from "../../models/account";
import { ModuleGroup } from "../../models/module-group";
import { CCSpinner } from "../../shared/components/cc-spinner";
import { ModuleGroupBuilder, ModuleGroupPicker } from "../../components";
import { DialogResult, SimpleDialog } from "../../shared/components/simple-dialog";
import { SimpleModal } from "../../shared/components/simple-modal";
import { AddOutlined, Error as ErrorIcon } from "@material-ui/icons";
import { PageBoundary } from "../../shared/components/simple-grid-pagination";
import { Module } from "../../models/module";
import ModuleProvider from "../../providers/module.provider";
import { UserModule } from "../../models/user-module";
import UserModuleProvider from "../../providers/usermodule.provider";
import { ModuleTemplate } from "../../models/module-template";
import ModuleTemplateProvider from "../../providers/moduletemplate.provider";

const ROWS_PER_PAGE = 10;
const MODULES_PAGING_LIMIT = 100;
type SAVE_CHANGES_INTENT = 'preview' | 'clear';

enum SearchType {
    None = 0,
    moduleName,
    accountName
}

const styles = (theme: Theme) => createStyles({
    container: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row'
    },
    errorPopup: {
        width: '98%',
        borderRadius: 5
    },
    errorPopupHeader: {
        height: '0.6em',
        backgroundColor: theme.palette.error.main,
        borderRadius: '3px 3px 0 0'
    },
    popupContentContainer :{
        display: 'flex',
        flexDirection: 'row',
        padding: theme.spacing(1),
    },
    errorPopupIcon: {
        fontSize: 40,
        marginRight: '1em'
    },
    mainSpinnerLabel: {
        paddingBottom: '30px'
    },
    progressContainer: {
        flexGrow: 1
    },
    animContainer: {
        transition: 'width 1s'
    },
    formHolder: {
        marginLeft: '2em',
        marginTop: '1.5em'
    },
    SearchContainer: {
        marginTop: '0.5em',
        padding: '1em',
        maxWidth: '22em'
    },
    textField: {
        padding: '1em 0',
        width: '100%',
        maxWidth: '22em',
        '& label': {
            fontWeight: 'bolder',
            padding: '1em 0 0 1em',

            fontSize: '1.4em',
            color: 'rgba(0, 0, 0, 0.8) !important'
        },
        '&::before': {
            borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
            borderBottomStyle: 'solid'
        }
    },
    moduleBuilder: {
        marginTop: '2em'
    },
    heading: {
        fontWeight: 700,
        marginTop: '1em'
    },
    ellipsis: {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden'
    },
    searchLabel: {
        width: '95%'
    }
});

interface Props extends WithStyles<typeof styles> {
    rootStore: RootStore;
    onAuthError?: () => void;
}

interface States {
    accounts: AccountFull[];
    dataPageIsLoading: boolean;
    isModulesLoading: boolean;
    allModules: ModuleGroup[];
    modules: ModuleGroup[];
    totalModules: number;
    currentModulesOffset: number;
    searchType: SearchType;
    searchTxt: string;
    mainSpinnerText: string;
    isCreate: boolean;
    networkError: boolean;
    formModuleGroup: ModuleGroup | undefined;
    formModule: Module | undefined;
    isProcessing: boolean;
    isPreview: boolean;
    isErrorModalOpened: boolean;
    errorModalHeader: string;
    errorModalText: string;
    isNotificationModalOpened: boolean;
    notificationModalHeader: string;
    notificationModalText: string;
    formHasChanges: boolean;
    isChangesModalOpen: boolean;
    changesModalIntent: SAVE_CHANGES_INTENT;
    tempModuleGroup: ModuleGroup | undefined;
    formUserModule: UserModule | undefined;
    formModuleTemplate: ModuleTemplate | undefined;
}

@inject('rootStore')
@observer
class ModuleGroups extends React.Component<Props, States> {
    public static defaultProps: Partial<Props> = {
    };

    state: States = {
        accounts: [],
        dataPageIsLoading: true,
        isModulesLoading: true,
        allModules: [],
        modules: [],
        totalModules: 0,
        currentModulesOffset: 0,
        searchType: SearchType.moduleName,
        searchTxt: '',
        mainSpinnerText: 'Please wait. Loading data.',
        isCreate: false,
        networkError: false,
        formModuleGroup: {} as ModuleGroup,
        formModule: {} as Module,
        isErrorModalOpened: false,
        isProcessing: false,
        isPreview: false,
        errorModalHeader: '',
        errorModalText: '',
        isNotificationModalOpened: false,
        notificationModalHeader: '',
        notificationModalText: '',
        formHasChanges: false,
        isChangesModalOpen: false,
        changesModalIntent: 'preview',
        tempModuleGroup: undefined,
        formUserModule: undefined,
        formModuleTemplate: undefined
    }

    jobProvider = new JobProvider();
    moduleGroupProvider = new ModuleGroupProvider();
    moduleProvider = new ModuleProvider();
    userModuleProvider = new UserModuleProvider();
    moduleTemplateProvider = new ModuleTemplateProvider();

    componentDidMount() {
        this.fetchAccounts();
    }

    fetchAccounts = () => {
        return this.moduleGroupProvider.fetchIsolatedAccounts()
          .then((results) => {
            const { accounts } = results;
            if (!accounts) {
                    this.setState({
                        isErrorModalOpened: true,
                        errorModalHeader: 'Unable to load accounts',
                        errorModalText: 'Admin tool api was unable to load all registered accounts',
                        dataPageIsLoading: false
                    });
                return;
            }

            this.setState({ accounts }, () => {
                this.fetchModuleGroups(accounts);
            });
        }).catch((error) => {
            const { status } = error.response;
            if (status === 401) {
                const { onAuthError } = this.props;
                if (!onAuthError) {
                    return;
                }
                onAuthError();
                return;
            }
            this.setState({networkError: true});
        });
    }

    fetchModuleGroups = (accounts: AccountFull[]) => {
        return this.moduleGroupProvider.fetchModuleGroups()
            .then((results) => {
                const { modules } = results;
                const formattedModules = modules.map(e => {
                    if (e.account_id) {
                        const moduleGroupAccount = accounts.find(a => a.account_id === e.account_id);
                        e.account_name = moduleGroupAccount ? moduleGroupAccount.name : "ACCOUNT NOT FOUND";
                    } else {
                        e.account_name = 'Not Assigned'
                    }
                    return e;
                });
                this.setState({
                    allModules: formattedModules,
                    modules: formattedModules,
                    dataPageIsLoading: false,
                    isModulesLoading: false,
                    totalModules: modules.length
                });
            }).catch((error) => {
                console.error(error);
                const { status } = error.response;
                if (status === 401) {
                    const { onAuthError } = this.props;
                    if (!onAuthError) {
                        return;
                    }
                    onAuthError();
                    return;
                }
                this.setState({networkError: true});
            });
    }
    
    onModulesDataPageOverBoundaryReached = (boundary: PageBoundary, nextPage: number): Promise<void> => {
        return new Promise<void>((resolve, reject) => {
            const { 
                currentModulesOffset,
                totalModules,
                allModules
            } = this.state;
            const isFirstPage = nextPage === 0;
            const isLastPage = nextPage === (Math.ceil(totalModules / ROWS_PER_PAGE)-1);
            const newOffset = isFirstPage ? 0
                            : isLastPage ? MODULES_PAGING_LIMIT * Math.floor(totalModules / MODULES_PAGING_LIMIT)
                            : (boundary === PageBoundary.Upper) ? 
                            currentModulesOffset + MODULES_PAGING_LIMIT :
                            currentModulesOffset - MODULES_PAGING_LIMIT;
            const nextModules = [...allModules];
            // MANUAL OFFSET
            this.setState({
                modules: nextModules.splice(newOffset, nextModules.length),
                currentModulesOffset: newOffset
            });
        });
    };

    onErrorModalClicked = (result: DialogResult) => {
        // Closes the dialog
        this.setState({isErrorModalOpened: false});
    };

    handleSearchTypeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        this.setState({searchType: event.target.value as number});
    }

    handleModuleSearchChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { searchType, allModules } = this.state;

        if (!e.target.value && e.target.value === '') {
            this.setState({
                searchTxt: e.target.value,
                modules: allModules
            });
            return;
        }

        const searchInput = e.target.value.toLowerCase();
        let moduleSearch: ModuleGroup[] = [];

        switch (searchType) {
            case SearchType.accountName:
                moduleSearch = allModules.filter(e => e.account_name!.toLowerCase().indexOf(searchInput) > -1);
                break;
            case SearchType.moduleName:
                moduleSearch = allModules.filter(e => e.name.toLowerCase().indexOf(searchInput) > -1);
                break;
                
            default:
                break;
        }
            
        this.setState({
            searchTxt: e.target.value,
            modules: moduleSearch
        });
    }

    onModuleGroupClick = (moduleGroup: ModuleGroup) => {
        if (!moduleGroup) { 
            return;
        }
        
        const { formHasChanges } = this.state;

        if (formHasChanges) {
            this.setState({
                tempModuleGroup: moduleGroup,
                isChangesModalOpen: true,
                changesModalIntent: 'preview'
            });
            return;
        }

        this.setState({isPreview: false, isCreate: false});
        this.moduleProvider.fetchModule(moduleGroup.modules[0]).then(data => {
            const { module } = data;
            this.setState({
                isPreview: true,
                formModuleGroup: moduleGroup,
                formModule: module
            })
        }).catch(error => {
            console.error(error);
            const { status } = error.response;
            if (status === 401) {
                const { onAuthError } = this.props;
                if (!onAuthError) {
                    return;
                }
                onAuthError();
                return;
            }
            this.setState({networkError: true});
        })
    }

    onCreateModuleClick = () => {
        const { formHasChanges } = this.state;
        if (formHasChanges) {
            this.setState({
                isChangesModalOpen: true,
                changesModalIntent: 'clear'
            });
            return;
        }
        this.setState({ isPreview: false });
        setTimeout(() => {
            this.setState({ isCreate: true, formUserModule: undefined });
        }, 100);
    }

    onModuleGroupFormSubmit = (
        moduleGroup: ModuleGroup,
        module: Module,
        userModule?: UserModule, 
        moduleTemplate?: ModuleTemplate
    ) => {
        if (!moduleGroup || !module) {
            return;
        }

        this.setState({
            formModuleGroup: moduleGroup,
            formModule: module
        });

        const { isCreate } = this.state;
        this.setState({isProcessing: true});

        if (userModule && moduleTemplate) {
            this.setState({
                formUserModule: userModule,
                formModuleTemplate: moduleTemplate
            });
        }

        if (isCreate) {
            this.createModule(module);
        }
    }

    createModule = (module: Module) => {
        this.moduleProvider.createModule(module).then(data => {
            const { formModuleGroup } = this.state;
            const { module } = data;

            if (!formModuleGroup) {
                return;
            }

            formModuleGroup.modules = [module.module_id];
            formModuleGroup.module_group_id = module.module_id;
            formModuleGroup.group_id = module.module_id;
            formModuleGroup.id = `module_group::${module.module_id}`;
            formModuleGroup._id = `module_group::${module.module_id}`;
            this.createModuleGroup(formModuleGroup);
        }).catch(error => {
            console.error(error);
            const { status } = error.response;
            if (status === 401) {
                const { onAuthError } = this.props;
                if (!onAuthError) {
                    return;
                }
                onAuthError();
                return;
            }
            this.setState({
                isErrorModalOpened: true,
                errorModalHeader: `Unable to create module ${status}`,
                errorModalText: error.stack_trace,
                isProcessing: false
            });
        });
    }

    createModuleGroup = (moduleGroup: ModuleGroup) => {
        this.moduleGroupProvider.createModuleGroup(moduleGroup).then(data => {
            const { formUserModule } = this.state;

            if (formUserModule) {
                this.createModuleTemplate(moduleGroup);
                return;
            }
            this.setState({
                isProcessing: false,
                isNotificationModalOpened: true,
                isCreate: false,
                formHasChanges: false,
                notificationModalHeader: 'Success',
                notificationModalText: 'Module Group succesfully created',
                formModule: {} as Module,
                formModuleGroup: {} as ModuleGroup
            })
        }).catch(error => {
            console.error(error);
            const { status } = error.response;
            if (status === 401) {
                const { onAuthError } = this.props;
                if (!onAuthError) {
                    return;
                }
                onAuthError();
                return;
            }
            this.setState({
                isErrorModalOpened: true,
                errorModalHeader: `Unable to create module group ${status}`,
                errorModalText: error.stack_trace,
                isProcessing: false
            });
        });
    }

    createModuleTemplate = (moduleGroup: ModuleGroup) => {
        const { formModuleTemplate, formUserModule } = this.state;

        if (!formModuleTemplate || !formUserModule) {
            return;
        }

        this.moduleTemplateProvider.createModuleTemplate(formModuleTemplate).then(data => {
            const { moduleTemplate } = data;

            this.createUserModule(moduleGroup, moduleTemplate);
        }).catch(error => {
            console.error(error);
            const { status } = error.response;
            if (status === 401) {
                const { onAuthError } = this.props;
                if (!onAuthError) {
                    return;
                }
                onAuthError();
                return;
            }
            this.setState({
                isErrorModalOpened: true,
                errorModalHeader: `Unable to create module template ${status}`,
                errorModalText: error.stack_trace,
                isProcessing: false
            });
        });
    }

    createUserModule = (moduleGroup: ModuleGroup, moduleTemplate: ModuleTemplate) => {
        const { formUserModule } = this.state;

        if (!formUserModule) {
            return;
        }

        formUserModule.module_group_id = moduleGroup.module_group_id;
        formUserModule.module_id = moduleGroup.module_group_id;
        formUserModule._id = `user_module::${moduleGroup.module_group_id}`;
        formUserModule.id = `user_module::${moduleGroup.module_group_id}`;
        formUserModule.user_module_id = moduleGroup.module_group_id;
        formUserModule.module_template_id = moduleTemplate._id;

        this.userModuleProvider.createUserModule(formUserModule).then(data => {
            this.setState({
                isProcessing: false,
                isNotificationModalOpened: true,
                isCreate: false,
                formHasChanges: false,
                notificationModalHeader: 'Success',
                notificationModalText: 'User Module succesfully created',
                formModule: {} as Module,
                formModuleGroup: {} as ModuleGroup,
                formUserModule: undefined,
                formModuleTemplate: undefined
            });
        }).catch(error => {
            console.error(error);
            const { status } = error.response;
            if (status === 401) {
                const { onAuthError } = this.props;
                if (!onAuthError) {
                    return;
                }
                onAuthError();
                return;
            }
            this.setState({
                isErrorModalOpened: true,
                errorModalHeader: `Unable to create user module ${status}`,
                errorModalText: error.stack_trace,
                isProcessing: false
            });
        });
    }

    onNotificationModalClicked = () => {
        this.setState({isNotificationModalOpened: false});
    }

    onFormChanges = () => {
        this.setState({formHasChanges: true});
    }

    onChangesModalClicked = (dialogResult: DialogResult) => {
        if (!dialogResult) {
            return;
        }

        if (dialogResult === DialogResult.Ok) {
            const { tempModuleGroup, changesModalIntent } = this.state;
            if (tempModuleGroup && changesModalIntent === 'preview') {
                this.setState({
                    formHasChanges: false
                }, () => {
                    this.onModuleGroupClick(tempModuleGroup);
                });
            } else if(changesModalIntent === 'clear') {
                this.setState({
                    formHasChanges: false,
                    isCreate: false,
                    isPreview: false
                }, () => {
                    this.onCreateModuleClick()
                });
            }
        }

        this.setState({isChangesModalOpen: false});
    }

    public render() {
        const {
            classes
        } = this.props;
        const {
            accounts,
            dataPageIsLoading,
            isModulesLoading,
            modules,
            totalModules,
            currentModulesOffset,
            searchType,
            searchTxt,
            mainSpinnerText,
            networkError,
            isErrorModalOpened,
            errorModalHeader,
            errorModalText,
            isCreate,
            isProcessing,
            isNotificationModalOpened,
            notificationModalHeader,
            notificationModalText,
            isPreview,
            formModuleGroup,
            formModule,
            isChangesModalOpen
        } = this.state;

        return (
            <div className={classes.container} data-testid="mainRender">
                <CCSpinner
                    label ={mainSpinnerText}
                    labelClassName={classes.mainSpinnerLabel}
                    className={classes.progressContainer}
                    loading={dataPageIsLoading}
                    size={200}
                >
                    <div>
                        <Typography
                            variant='h6'
                            className={`${classes.heading} ${classes.ellipsis}`}
                        >
                            Search By
                        </Typography>
                        <Paper className={classes.SearchContainer}>
                            <Select
                                id="select-search-module-input"
                                data-testid="select-search-module"
                                value={searchType}
                                onChange={this.handleSearchTypeChange}
                                label="Search by"
                                className={classes.searchLabel}
                            >
                                <MenuItem value={SearchType.moduleName}>Module Name</MenuItem>
                                <MenuItem value={SearchType.accountName}>Account Name</MenuItem>
                            </Select>
                            <TextField
                                className={classes.textField}
                                id="search-field-module"
                                value={searchTxt}
                                onChange={e => this.handleModuleSearchChange(e)}
                                multiline={false}
                                placeholder="Type your search here"
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Paper>
                        <ModuleGroupPicker
                            className={classes.animContainer}
                            moduleGroups={modules}
                            accounts={accounts}
                            modulesIsLoading={isModulesLoading}
                            modulesTotalItems={searchTxt !== '' ? modules.length : totalModules}
                            modulesItemsOffset={currentModulesOffset}
                            rowsPerPage={ROWS_PER_PAGE}
                            onModuleClick={this.onModuleGroupClick}
                            onModuleDataPageOverBoundary={this.onModulesDataPageOverBoundaryReached}
                        />
                    </div>
                    <div className={classes.formHolder}>
                        <Button
                            variant="contained"
                            color="secondary"
                            startIcon={<AddOutlined />}
                            onClick={this.onCreateModuleClick}
                        >
                            Create new Module Group
                        </Button>
                        {
                            isCreate || isPreview ?
                            <ModuleGroupBuilder
                                className={`${classes.animContainer} ${classes.moduleBuilder}`}
                                accounts={accounts}
                                canEdit={false}
                                isPreview={isPreview}
                                isFormProcessing={isProcessing}
                                pickedModuleGroup={formModuleGroup}
                                pickedModule={formModule}
                                onFormSubmit={this.onModuleGroupFormSubmit}
                                onFormChanges={this.onFormChanges}
                            /> : ''
                        }
                    </div>
                </CCSpinner>
                <SimpleDialog
                    open={isErrorModalOpened}
                    titleText={errorModalHeader}
                    contentText={errorModalText}
                    buttonCancelLabel=""
                    onDialogResult={this.onErrorModalClicked}
                    data-testid="errorModal"
                />
                <SimpleDialog
                    open={isChangesModalOpen}
                    titleText="You have unsaved changes"
                    contentText="Do you wish to discard them?"
                    buttonCancelLabel="No"
                    buttonOkLabel="Yes"
                    onDialogResult={this.onChangesModalClicked}
                />
                <SimpleDialog
                    open={isNotificationModalOpened}
                    titleText={notificationModalHeader}
                    contentText={notificationModalText}
                    buttonCancelLabel=""
                    onDialogResult={this.onNotificationModalClicked}
                />
                {
                    networkError &&
                        <SimpleModal
                            className={classes.errorPopup}
                            open={networkError}
                            contentClasses= {classes.popupContentContainer}
                            buttonOkLabel=""
                            buttonCancelLabel=""
                            header=""
                            headerClassName={classes.errorPopupHeader}
                        >
                            <ErrorIcon color="error" className={classes.errorPopupIcon} />
                            <div>
                                <Typography variant="h5">
                                    {'Network Error'}
                                </Typography>
                                <Typography variant="subtitle1">
                                    {'Please reload the page.'}
                                </Typography>
                            </div>
                        </SimpleModal>
                }
            </div>
        );
    }
}

const MUIComponent = withStyles(styles)(ModuleGroups);
export {MUIComponent as ModuleGroups};