import {
    Button,
    Checkbox,
    Grid,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Paper,
    Typography
} from '@material-ui/core';
import {
    createStyles,
    WithStyles,
    withStyles
} from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import memoize from 'memoize-one';
import * as React from 'react';

export interface TransferListItem {
    id: string;
    label: string;
}

const not = (source: TransferListItem[], filterItems: TransferListItem[]): TransferListItem[] =>{
    return source.filter((item: TransferListItem) => {
        return filterItems.indexOf(item) === -1;
    });
}

const sortItemsByLabel = (a:TransferListItem, b:TransferListItem) => {
    const nameA = a.label.toLocaleUpperCase();
    const nameB = b.label.toLocaleUpperCase();
    if (nameA < nameB) {
        return -1;
    }

    if (nameA > nameB) {
        return 1;
    }

    // names must be equal
    return 0;
};

const styles = (theme: Theme) => createStyles({
    root: {
        margin: 'auto',
    },
    paper: {
        width: 250,
        height: 230,
        overflow: 'auto',
    },
    title: {
        marginBottom: '0.5em',
        textAlign: 'center'
    },
    button: {
        margin: '0.5em 0',
    },
    listItem: {
        padding: '0 0 0.5em 0'
    },
    listItemIcon: {
        margin : '0 0.4em'
    },
    listItemText: {
        padding: '0 0.9em 0 0'
    },
    checkbox: {
        padding: 0
    }
});

interface Props extends WithStyles<typeof styles> {
    className?: string;
    leftItems: TransferListItem[];
    rightItems: TransferListItem[];
    showIds: boolean;
    leftTitle?: string;
    rightTitle?: string;
    onItemsChange?: (leftItems: TransferListItem[], rightItems: TransferListItem[]) => void;
};

interface States {
    checkedItemIdsLeft: string[];
    checkedItemIdsRight: string[];
}

class TransferList extends React.Component<Props, States> {
    public static defaultProps = {
        showIds: false
    };

    state = {
        checkedItemIdsLeft: [] as string[],
        checkedItemIdsRight: [] as string[]
    };

    createCustomList = memoize((items: TransferListItem[], isLeftArray: boolean) => {
        const {
            classes,
            showIds
        } = this.props;

        const {
            checkedItemIdsLeft,
            checkedItemIdsRight
         } = this.state;

        const checkedItemIds = isLeftArray ? checkedItemIdsLeft : checkedItemIdsRight;
        
        return (
            <Paper className={classes.paper}>
                <List
                    dense={true}
                    role="list"
                >
                    {items.sort(sortItemsByLabel)
                        .map((item: TransferListItem) => {
                        const {
                            id,
                            label
                        } = item;
                        const labelId = `transfer-list-item-${id}-label`;
                        return (
                            <ListItem
                                key={id}
                                className={classes.listItem}
                                role="listitem"
                                button={true}
                                onClick={this.onItemToggled(item, isLeftArray)}
                            >
                                <ListItemIcon className={classes.listItemIcon}>
                                    <Checkbox
                                        className={classes.checkbox}
                                        checked={checkedItemIds.indexOf(id) !== -1}
                                        tabIndex={-1}
                                        disableRipple={true}
                                        inputProps={{ 'aria-labelledby': labelId }}
                                    />
                                </ListItemIcon>
                                <ListItemText
                                    id={labelId}
                                    className={classes.listItemText}
                                    primary={label}
                                    secondary={showIds ? id : undefined}
                                />
                            </ListItem>
                        );}
                    )}
                    <ListItem />
                </List>
            </Paper>
        );
    });
    
    //#region Events
    onItemToggled = (item: TransferListItem, isLeftItem: boolean) => () => {
        const {
            checkedItemIdsLeft,
            checkedItemIdsRight
         } = this.state;
        const { id: itemId } = item;
        const newChecked = isLeftItem ? [...checkedItemIdsLeft] : [...checkedItemIdsRight];
        const currentIndex = newChecked.indexOf(itemId);
    
        if (currentIndex === -1) {
          newChecked.push(itemId);
        } else {
          newChecked.splice(currentIndex, 1);
        }
        if (isLeftItem) {
            this.setState({checkedItemIdsLeft: newChecked});
         } else {
            this.setState({checkedItemIdsRight: newChecked});
         }
    };
    
    OnMoveAllItemsClicked = (moveAllLeft: boolean) => () => {
        const {
            leftItems,
            rightItems,
            onItemsChange
        } = this.props;

        if (!onItemsChange) {
            return;
        }

        if (moveAllLeft) {
            onItemsChange(leftItems.concat(rightItems).sort(sortItemsByLabel), []);
            this.setState({checkedItemIdsRight: []});
        } else {
            onItemsChange([], leftItems.concat(rightItems).sort(sortItemsByLabel));
            this.setState({checkedItemIdsLeft: []});
        }
    };

    onMoveItemsClicked = (moveLeft: boolean) => () =>{
        const {
            leftItems,
            rightItems,
            onItemsChange
        } = this.props;
        
        if (!onItemsChange) {
            return;
        }

        const {
            checkedItemIdsLeft,
            checkedItemIdsRight
         } = this.state;

        const checkedItemIds = moveLeft ? checkedItemIdsRight : checkedItemIdsLeft;
        const currentItems = moveLeft ? rightItems : leftItems;
        const checkedItems = currentItems.filter((item) => {
            return (checkedItemIds.indexOf(item.id) >= 0);
        });
        const newLeft   = moveLeft ? leftItems.concat(checkedItems) : not(leftItems, checkedItems);
        const newRight  = moveLeft ? not(rightItems, checkedItems) : rightItems.concat(checkedItems);

        if (moveLeft) {
            this.setState({checkedItemIdsRight: []});
        } else {
            this.setState({checkedItemIdsLeft: []});
        }

        onItemsChange(newLeft.sort(sortItemsByLabel), newRight.sort(sortItemsByLabel));
    }
    //#endregion

    public render() {
        const {
            classes,
            className,
            leftItems,
            rightItems,
            leftTitle,
            rightTitle
        } = this.props;
        const {
            checkedItemIdsLeft,
            checkedItemIdsRight
        } = this.state;
        const rootClassNames = classes.root + (className ? ` ${className}` : '');

        return (
            <Grid
                className={rootClassNames}
                container={true}
                justify="center"
                alignItems="center"
            >
                <Grid item={true} data-testid="left-list">
                { leftTitle ?
                    <Typography
                        className={classes.title}
                        variant="h5"
                    >
                        {leftTitle}
                    </Typography>
                  : ''
                }
                    {this.createCustomList(leftItems, true)}
                </Grid>
                <Grid item={true} xs={2}>
                    <Grid
                        container={true}
                        direction="column" 
                        alignItems="center"
                    >
                    <Button
                        variant="outlined"
                        size="small"
                        className={classes.button}
                        onClick={this.OnMoveAllItemsClicked(false)}
                        disabled={leftItems.length === 0}
                        aria-label="move all right"
                    >
                        ≫
                    </Button>
                    <Button
                        variant="outlined"
                        size="small"
                        className={classes.button}
                        onClick={this.onMoveItemsClicked(false)}
                        disabled={checkedItemIdsLeft.length === 0}
                        aria-label="move selected right"
                    >
                        &gt;
                    </Button>
                    <Button
                        variant="outlined"
                        size="small"
                        className={classes.button}
                        onClick={this.onMoveItemsClicked(true)}
                        disabled={checkedItemIdsRight.length === 0}
                        aria-label="move selected left"
                    >
                        &lt;
                    </Button>
                    <Button
                        variant="outlined"
                        size="small"
                        className={classes.button}
                        onClick={this.OnMoveAllItemsClicked(true)}
                        disabled={rightItems.length === 0}
                        aria-label="move all left"
                    >
                        ≪
                    </Button>
                    </Grid>
                </Grid>
                <Grid item={true} data-testid="right-list">
                { rightTitle ?
                    <Typography
                        className={classes.title}
                        variant="h5"
                    >
                        {rightTitle}
                    </Typography>
                  : ''
                }
                    {this.createCustomList(rightItems, false)}
                </Grid>
            </Grid>
          );
    }
};

const MUIComponent = withStyles(styles)(TransferList);
export { MUIComponent as TransferList };
