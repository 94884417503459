import access_control from './svg-sprite/access_control.svg';
import acoustics from './svg-sprite/acoustics.svg';
import air from './svg-sprite/air.svg';
import ant from './svg-sprite/ant.svg';
import appliances from './svg-sprite/appliances.svg';
import audio from './svg-sprite/audio.svg';
import av from './svg-sprite/av.svg';
import badge from './svg-sprite/badge.svg';
import bed from './svg-sprite/bed.svg';
import broom from './svg-sprite/broom.svg';
import building from './svg-sprite/building.svg';
import calendar from './svg-sprite/calendar.svg';
import catering from './svg-sprite/catering.svg';
import chemicals from './svg-sprite/chemicals.svg';
import cleaning from './svg-sprite/cleaning.svg';
import clipboard from './svg-sprite/clipboard.svg';
import coffee from './svg-sprite/coffee.svg';
import coffee_machine from './svg-sprite/coffee_machine.svg';
import cold from './svg-sprite/cold.svg';
import conf_room from './svg-sprite/conf_room.svg';
import conf_room_equip from './svg-sprite/conf_room_equip.svg';
import cubicles from './svg-sprite/cubicles.svg';
import {default as defaultIcon} from './svg-sprite/default.svg';
import dolly from './svg-sprite/dolly.svg';
import door from './svg-sprite/door.svg';
import dry_ice from './svg-sprite/dry_ice.svg';
import ehs_concern from './svg-sprite/ehs_concern.svg';
import electrical from './svg-sprite/electrical.svg';
import elevator from './svg-sprite/elevator.svg';
import event_setup from './svg-sprite/event_setup.svg';
import exclamation_mark from './svg-sprite/exclamation_mark.svg';
import eye from './svg-sprite/eye.svg';
import faucet from './svg-sprite/faucet.svg';
import file_storage from './svg-sprite/file_storage.svg';
import fire_extinguisher from './svg-sprite/fire_extinguisher.svg';
import first_aid_box from './svg-sprite/first_aid_box.svg';
import food from './svg-sprite/food.svg';
import food_and_beverage from './svg-sprite/food_and_beverage.svg';
import furniture from './svg-sprite/furniture.svg';
import furniture_2 from './svg-sprite/furniture_2.svg';
import gas_cylinders from './svg-sprite/gas_cylinders.svg';
import gauge from './svg-sprite/gauge.svg';
import gears from './svg-sprite/gears.svg';
import glassware from './svg-sprite/glassware.svg';
import gloves from './svg-sprite/gloves.svg';
import hand_sanitizer from './svg-sprite/hand_sanitizer.svg';
import happy_face from './svg-sprite/happy_face.svg';
import hazardous_waste from './svg-sprite/hazardous_waste.svg';
import heart_with_cross from './svg-sprite/heart_with_cross.svg';
import hot from './svg-sprite/hot.svg';
import housekeeping_supplies from './svg-sprite/housekeeping_supplies.svg';
import humidity from './svg-sprite/humidity.svg';
import janitorial from './svg-sprite/janitorial.svg';
import just_right from './svg-sprite/just_right.svg';
import keg from './svg-sprite/keg.svg';
import key from './svg-sprite/key.svg';
import lab_equipment from './svg-sprite/lab_equipment.svg';
import lab_services from './svg-sprite/lab_services.svg';
import landscaping from './svg-sprite/landscaping.svg';
import leak from './svg-sprite/leak.svg';
import letter from './svg-sprite/letter.svg';
import light_levels from './svg-sprite/light_levels.svg';
import lighting from './svg-sprite/lighting.svg';
import machinery from './svg-sprite/machinery.svg';
import maintenance from './svg-sprite/maintenance.svg';
import medical from './svg-sprite/medical.svg';
import microscope from './svg-sprite/microscope.svg';
import mobile_phone from './svg-sprite/mobile_phone.svg';
import monitor from './svg-sprite/monitor.svg';
import neutral_face from './svg-sprite/neutral_face.svg';
import noise_and_odors from './svg-sprite/noise_and_odors.svg';
import nose from './svg-sprite/nose.svg';
import other from './svg-sprite/other.svg';
import paper_clip from './svg-sprite/paper_clip.svg';
import pencil_and_paper from './svg-sprite/pencil_and_paper.svg';
import personal_storage from './svg-sprite/personal_storage.svg';
import phone from './svg-sprite/phone.svg';
import pipettes from './svg-sprite/pipettes.svg';
import plant from './svg-sprite/plant.svg';
import plumbing from './svg-sprite/plumbing.svg';
import printer from './svg-sprite/printer.svg';
import projector from './svg-sprite/projector.svg';
import pure_pack from './svg-sprite/pure_pack.svg';
import rake from './svg-sprite/rake.svg';
import recycling from './svg-sprite/recycling.svg';
import recycling_waste from './svg-sprite/recycling_waste.svg';
import red_exclamation from './svg-sprite/red_exclamation.svg';
import register from './svg-sprite/register.svg';
import repairs from './svg-sprite/repairs.svg';
import restock_supplies from './svg-sprite/restock_supplies.svg';
import restrooms from './svg-sprite/restrooms.svg';
import safety from './svg-sprite/safety-concern.svg';
import screen_share from './svg-sprite/screen_share.svg';
import security from './svg-sprite/security.svg';
import shredder from './svg-sprite/shredder.svg';
import snacks from './svg-sprite/snacks.svg';
import soap from './svg-sprite/soap.svg';
import spot_cooler from './svg-sprite/spot_cooler.svg';
import spray_bottle from './svg-sprite/spray_bottle.svg';
import standing_desk from './svg-sprite/standing_desk.svg';
import suggestion_box from './svg-sprite/suggestion_box.svg';
import supplies from './svg-sprite/supplies.svg';
import telephony from './svg-sprite/telephony.svg';
import thermal from './svg-sprite/thermal.svg';
import tissues from './svg-sprite/tissues.svg';
import too_cold from './svg-sprite/too_cold.svg';
import tools from './svg-sprite/tools.svg';
import tools_tech from './svg-sprite/tools_tech.svg';
import trashcan from './svg-sprite/trashcan.svg';
import video from './svg-sprite/video.svg';
import warehouse from './svg-sprite/warehouse.svg';
import water from './svg-sprite/water.svg';
import water_bottle from './svg-sprite/water_bottle.svg';
import whiteboard from './svg-sprite/whiteboard.svg';
import wifi from './svg-sprite/wifi.svg';
import window from './svg-sprite/window.svg';
import workstation_equip from './svg-sprite/workstation_equip.svg';
import wrench from './svg-sprite/wrench.svg';

export const IconDictionary = [
    { label: 'access_control', icon: access_control},
    { label: 'acoustics', icon: acoustics},
    { label: 'air', icon: air},
    { label: 'ant', icon: ant},
    { label: 'appliances', icon: appliances},
    { label: 'audio', icon: audio},
    { label: 'av', icon: av},
    { label: 'badge', icon: badge},
    { label: 'bed', icon: bed},
    { label: 'broom', icon: broom},
    { label: 'building', icon: building},
    { label: 'calendar', icon: calendar},
    { label: 'catering', icon: catering},
    { label: 'chemicals', icon: chemicals},
    { label: 'cleaning', icon: cleaning},
    { label: 'clipboard', icon: clipboard},
    { label: 'coffee', icon: coffee},
    { label: 'coffee_machine', icon: coffee_machine},
    { label: 'cold', icon: cold},
    { label: 'conf_room', icon: conf_room},
    { label: 'conf_room_equip', icon: conf_room_equip},
    { label: 'cubicles', icon: cubicles},
    { label: 'default', icon: defaultIcon},
    { label: 'dolly', icon: dolly},
    { label: 'door', icon: door},
    { label: 'dry_ice', icon: dry_ice},
    { label: 'ehs_concern', icon: ehs_concern},
    { label: 'electrical', icon: electrical},
    { label: 'elevator', icon: elevator},
    { label: 'event_setup', icon: event_setup},
    { label: 'exclamation_mark', icon: exclamation_mark},
    { label: 'eye', icon: eye},
    { label: 'faucet', icon: faucet},
    { label: 'file_storage', icon: file_storage},
    { label: 'fire_extinguisher', icon: fire_extinguisher},
    { label: 'first_aid_box', icon: first_aid_box},
    { label: 'food', icon: food},
    { label: 'food_and_beverage', icon: food_and_beverage},
    { label: 'furniture', icon: furniture},
    { label: 'furniture_2', icon: furniture_2},
    { label: 'gas_cylinders', icon: gas_cylinders},
    { label: 'gauge', icon: gauge},
    { label: 'gears', icon: gears},
    { label: 'glassware', icon: glassware},
    { label: 'gloves', icon: gloves},
    { label: 'hand_sanitizer', icon: hand_sanitizer},
    { label: 'happy_face', icon: happy_face},
    { label: 'hazardous_waste', icon: hazardous_waste},
    { label: 'heart_with_cross', icon: heart_with_cross},
    { label: 'hot', icon: hot},
    { label: 'housekeeping_supplies', icon: housekeeping_supplies},
    { label: 'humidity', icon: humidity},
    { label: 'janitorial', icon: janitorial},
    { label: 'just_right', icon: just_right},
    { label: 'keg', icon: keg},
    { label: 'key', icon: key},
    { label: 'lab_equipment', icon: lab_equipment},
    { label: 'lab_services', icon: lab_services},
    { label: 'landscaping', icon: landscaping},
    { label: 'leak', icon: leak},
    { label: 'letter', icon: letter},
    { label: 'light_levels', icon: light_levels},
    { label: 'lighting', icon: lighting},
    { label: 'machinery', icon: machinery},
    { label: 'maintenance', icon: maintenance},
    { label: 'medical', icon: medical},
    { label: 'microscope', icon: microscope},
    { label: 'mobile_phone', icon: mobile_phone},
    { label: 'monitor', icon: monitor},
    { label: 'neutral_face', icon: neutral_face},
    { label: 'noise_and_odors', icon: noise_and_odors},
    { label: 'nose', icon: nose},
    { label: 'other', icon: other},
    { label: 'paper_clip', icon: paper_clip},
    { label: 'pencil_and_paper', icon: pencil_and_paper},
    { label: 'personal_storage', icon: personal_storage},
    { label: 'phone', icon: phone},
    { label: 'pipettes', icon: pipettes},
    { label: 'plant', icon: plant},
    { label: 'plumbing', icon: plumbing},
    { label: 'printer', icon: printer},
    { label: 'projector', icon: projector},
    { label: 'pure_pack', icon: pure_pack},
    { label: 'rake', icon: rake},
    { label: 'recycling', icon: recycling},
    { label: 'recycling_waste', icon: recycling_waste},
    { label: 'red_exclamation', icon: red_exclamation},
    { label: 'register', icon: register},
    { label: 'repairs', icon: repairs},
    { label: 'restock_supplies', icon: restock_supplies},
    { label: 'restrooms', icon: restrooms},
    { label: 'safety-concern', icon: safety},
    { label: 'screen_share', icon: screen_share},
    { label: 'security', icon: security},
    { label: 'shredder', icon: shredder},
    { label: 'snacks', icon: snacks},
    { label: 'soap', icon: soap},
    { label: 'spot_cooler', icon: spot_cooler},
    { label: 'spray_bottle', icon: spray_bottle},
    { label: 'standing_desk', icon: standing_desk},
    { label: 'suggestion_box', icon: suggestion_box},
    { label: 'supplies', icon: supplies},
    { label: 'telephony', icon: telephony},
    { label: 'thermal', icon: thermal},
    { label: 'tissues', icon: tissues},
    { label: 'too_cold', icon: too_cold},
    { label: 'tools', icon: tools},
    { label: 'tools_tech', icon: tools_tech},
    { label: 'trashcan', icon: trashcan},
    { label: 'video', icon: video},
    { label: 'warehouse', icon: warehouse},
    { label: 'water', icon: water},
    { label: 'water_bottle', icon: water_bottle},
    { label: 'whiteboard', icon: whiteboard},
    { label: 'wifi', icon: wifi},
    { label: 'window', icon: window},
    { label: 'workstation_equip', icon: workstation_equip},
    { label: 'wrench', icon: wrench},
]