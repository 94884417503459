import {
    Avatar,
    Paper
} from '@material-ui/core';
import grey from '@material-ui/core/colors/grey';
import {
    createStyles,
    WithStyles,
    withStyles
} from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import * as React from 'react';

const USER_NOT_VERIFIED_TEXT = 'User Not Verified';
const USER_VERIFIED_TEXT = 'User Verified';

const styles = (theme: Theme) => createStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        minWidth: '30em',
    },
    paperContent: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        alignItems: 'center',
        padding: '0.5em',
        paddingBottom: '1em'
    },
    paperParagraph: {
        fontSize: '.9em',
        margin: '0 0.5em',
        lineHeight: '2'
    },
    fullName: {
        fontSize: '1.5em'
    },
    userName: {
        fontSize: '1.2em'
    },
    email: {
        fontSize: '0.9em'
    },
    userCreationDate: {
        fontStyle: 'italic'
    },
    avatar: {
        margin: 10,
        color: '#fff',
        backgroundColor: grey[500],
        width: 60,
        height: 60,
    },
    userNotVerified: {
        backgroundColor: '#f5b0b0'
    }
});

interface Props extends WithStyles<typeof styles> {
    className?: string,
    verified: boolean,
    fullName?: string,
    userName?: string,
    email: string,
    userCreationDate?: Date
};

interface States {
    notVerified: boolean
}

class User extends React.Component<Props, States> {
    state = {
        notVerified: false,
    };

    public render() {
        const {
            className,
            classes,
            verified,
            fullName,
            userName,
            email,
            userCreationDate
        } = this.props;
        const userVerifiedText = verified ? USER_VERIFIED_TEXT : USER_NOT_VERIFIED_TEXT;
        const containerClasses = classes.container +
                                 (className ? ` ${className}` : '')+
                                 (!verified ? ` ${classes.userNotVerified}`: '');
        return (
            <Paper className={containerClasses}>
                <span className={classes.paperParagraph}>{userVerifiedText}</span>
                <div className={classes.paperContent}>
                    <Avatar aria-label="User" className={classes.avatar}>
                        <PermIdentityIcon />
                    </Avatar>
                    { fullName && fullName.length > 0 ? 
                        <span className={classes.fullName}>{fullName}</span>
                        : ''
                    }
                    { userName && userName.length > 0 ? 
                        <span className={classes.userName}>{userName}</span>
                        : ''
                    }
                    <span className={classes.email}>{email}</span>
                </div>
                <span className={`${classes.paperParagraph} ${classes.userCreationDate}`}>
                {
                    userCreationDate ? 
                    `Member Since: ${userCreationDate.toLocaleDateString('en-US')}` :
                    ''
                }
                </span>
            </Paper>
        );
    }
}

const MUIComponent = withStyles(styles)(User);
export {MUIComponent as User}
