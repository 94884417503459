import { Paper, Typography } from '@material-ui/core';
import {
    createStyles,
    WithStyles,
    withStyles
} from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { CCSpinner } from '../../shared/components/cc-spinner';
import { PageBoundary } from '../../shared/components/simple-grid-pagination';
import { SimpleListItem } from '../../shared/components/simple-list';
import { SimpleListPagination } from '../../shared/components/simple-list-pagination';
import { Account, Team } from '../../shared/domain';
import { ExpandedTeamInfo } from '../expanded-team-info';

const styles = (theme: Theme) => createStyles({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    container: {
        display: 'flex',
        flexDirection: 'column'
    },
    heading: {
        fontWeight: 700,
        marginBottom: '0.5em',
        marginTop: '1em'
    },
    ellipsis: {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden'
    },
    simpleList: {
        overflow: 'auto',
        height: '100%',
        minWidth: '20em'
    },
    simpleListAccounts: {
        marginRight: '1.5em',
        width:'32%',
        '&:last-child': {
            marginRight: 0
        }
    },
    noAccountContainer: {
        fontWeight: 'bold',
         '& > *': {
            color: 'rgba(0,0,0,0.2)'
        }
    },
    teamSpinnerContainer: {
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '3em',
        marginBottom: '4em',
        borderRadius: '0.4em'
    },
});

interface Props extends WithStyles<typeof styles> {
    className?: string;
    accounts?: Account[] | null;
    accountsIsLoading: boolean;
    currentAccountName?: string;
    folderIsLoading: boolean;
    accountsTotalItems?: number;
    accountsItemsOffset: number;
    rowsPerPage: number;
    onAccountClick?: (account: Account) => void;
    onAccountDataPageOverBoundary?: (boundary: PageBoundary, nextPage: number) => Promise<void>;
    teams: Team[] | null;
    totalTeams: number;
    isTeamsLoading: boolean;
    currentTeam: Team | null;
    currentTeamOffset: number;
    onTeamClick?: (team: Team) => void;
    onTeamDataPageOverBoundary?: (boundary: PageBoundary, nextPage: number) => Promise<void>;
    teamItems: SimpleListItem[];
    isTeamInfoLoading: boolean;
    teamInfoOffset: number;
    onTeamInfoDataPageOverBoundary?: (boundary: PageBoundary, nextPage: number) => Promise<void>;
};

@inject('rootStore')
@observer
class AccountTeamInfo extends React.Component<Props> {
    public static defaultProps: Partial<Props> = {
    };

    onAccountClicked = (listItem: SimpleListItem) => {
        const {accounts, onAccountClick} = this.props;
        if(!accounts) {
            return;
        }

        const currentAccount = accounts.find((account:Account) => {
            return account.accountId === listItem.id;
        });
        if (!currentAccount) {
            return;
        }

        if (onAccountClick) {
            onAccountClick(currentAccount);
        }
    };

    onTeamClicked = (listItem: SimpleListItem) => {
        const {teams, onTeamClick} = this.props;
        if(!teams) {
            return;
        }

        const currentTeam = teams.find((team: Team) => {
            return team.team_id === listItem.id;
        });
        if (!currentTeam) {
            return;
        }

        if (onTeamClick) {
            onTeamClick(currentTeam);
        }
    };

    public render() {
        const {
            className,
            classes,
            accounts,
            currentAccountName,
            accountsIsLoading,
            accountsTotalItems,
            accountsItemsOffset,
            rowsPerPage,
            onAccountDataPageOverBoundary,
            teams,
            totalTeams,
            isTeamsLoading,
            currentTeam,
            currentTeamOffset,
            onTeamDataPageOverBoundary,
            teamItems,
            isTeamInfoLoading,
            teamInfoOffset,
            onTeamInfoDataPageOverBoundary
        } = this.props;

        const accountListItems = accounts ? accounts.map((account: Account) => {
            const accountId = account.accountId;
            return new SimpleListItem(accountId, account.name, accountId);
        }) : [];
        const teamListItems = teams ? teams.map(((team, index) => {
            const teamId = team.team_id;
            return new SimpleListItem(teamId, team.name, teamId);
        })) : [];

        if (accounts && (accounts.length > 0)) {
            return (
                <div className={`${classes.root} ${className}`} data-testid="accountPicker">
                    <div className={`${classes.container} ${classes.simpleListAccounts}`}>
                        <Typography
                            variant='h6'
                            className={`${classes.heading} ${classes.ellipsis}`}
                        >
                            Account Organizations
                        </Typography>
                        <SimpleListPagination
                            className={classes.simpleList}
                            keepItemSelected={true}
                            items={accountListItems}
                            isLoading={accountsIsLoading}
                            onListItemClick={this.onAccountClicked}
                            rowsPerPage={rowsPerPage}
                            totalItems={accountsTotalItems}
                            offset={accountsItemsOffset}
                            onPageOverBoundary={onAccountDataPageOverBoundary}
                        />
                    </div>
                    { teams && (teams.length > 0) ?
                            <div className={`${classes.container} ${classes.simpleListAccounts}`} data-testid="teamPicker">
                                <Typography
                                    variant='h6'
                                    className={`${classes.heading} ${classes.ellipsis}`}
                                >
                                    Teams on {currentAccountName}
                                </Typography>
                                    <SimpleListPagination
                                        className={classes.simpleList}
                                        keepItemSelected={true}
                                        isLoading={isTeamsLoading}
                                        items={teamListItems}
                                        offset={currentTeamOffset}
                                        onListItemClick={this.onTeamClicked}
                                        onPageOverBoundary={onTeamDataPageOverBoundary}
                                        rowsPerPage={rowsPerPage}
                                        totalItems={totalTeams}
                                    />
                            </div>
                            :
                            <Paper className={`${classes.container} ${classes.simpleListAccounts} ${classes.teamSpinnerContainer} ${(!isTeamsLoading ? classes.noAccountContainer : '')}`}>
                                <CCSpinner
                                    loading={isTeamsLoading}
                                    size={70}
                                >
                                    <Typography variant={!teams ? 'h4' : 'h5'}>
                                        {
                                            !teams ? 'No organization selected' : `No teams for ${currentAccountName ? currentAccountName : 'the selected account'}`
                                        }
                                    </Typography>
                                </CCSpinner>
                            </Paper>
                    }
                    { currentTeam ?
                        <ExpandedTeamInfo
                            className={classes.simpleListAccounts}
                            team={currentTeam}
                            teamItems={teamItems}
                            isTeamInfoLoading={isTeamInfoLoading}
                            rowsPerPage={rowsPerPage}
                            teamInfoOffset={teamInfoOffset}
                            onTeamDataPageOverBoundary={onTeamInfoDataPageOverBoundary}
                        />
                        :
                        <Paper className={`${classes.container} ${classes.simpleListAccounts} ${classes.teamSpinnerContainer} ${(!isTeamsLoading ? classes.noAccountContainer : '')}`}>
                                <CCSpinner
                                    loading={isTeamsLoading}
                                    size={70}
                                >
                                    <Typography variant="h4">
                                        No team selected
                                    </Typography>
                                </CCSpinner>
                            </Paper>
                    }
                </div>
            );
        } else {
            return (
                <div className={`${classes.root} ${classes.noAccountContainer}`}>
                    <Typography
                        variant='h4'
                    >
                        No accounts found
                    </Typography>
                </div>
            );
        }

    }
}

const MUIComponent = withStyles(styles)(AccountTeamInfo);
export {MUIComponent as AccountTeamInfo}