import * as React from 'react';

import {
    createStyles,
    WithStyles,
    withStyles
} from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { CCSpinner } from '../cc-spinner';
import {
    LoginWizardNewAccountData as LoginWizardNewAccountDataLocal,
    LoginWizardStepLogin,
    LoginWizardStepNewAccount,
    LoginWizardStepPassword,
    LoginWizardStepPasswordResetSuccess
} from './steps';
import { LoginWizardStepValidateAccount } from './steps/login-wizard-step-validate-account';

export enum LoginWizardSteps {
    Login = 0,
    Password,
    NewAccount,
    ValidateAccount,
    PasswordReset,
    PasswordResetSuccess
}

const styles = (theme: Theme) => createStyles({
    root: {
        position: 'relative',
        backgroundColor: '#ffffff',
        borderRadius: '2px',
        border: 'solid 1px #dddddd',
        padding: '1.75em 20px',
        minWidth: '25em',
        width: '75%'
    },
    mainContainer: {
        marginTop: '0.75em'
    },
    wizardContainer: {
        display: 'flex',
        flexDirection: 'column'
    },
    divider: {
        margin: '20px 0'
    },
    error: {
        fontSize: '1em',
        color: theme.ccPalette.cc_colors.solid.ccLeftsilhouette.main,
        marginBottom: '0.3em'
    },
    title: {
        fontSize: '1.6em',
        fontWeight: 'normal',
        textAlign: 'left'
    },
    subtitle: {
        fontSize: '1em',
        fontWeight: 'bold'
    },
    textField: {
        margin: '0.6em 0 1.35em 0',
        width: '100%'
    },
    link: {
        fontWeight: 'bold',
        textAlign: 'center'
    },
    input: {
        height: '2.25em',
        padding: '0 1em',
        fontSize: '1em'
    },
    inputLabel: {
        fontSize: '1em',
        transform: 'translate(1em, 0.8em) scale(1)'
    },
    footerTitle: {
        fontSize: '1em',
        fontWeight: 'normal',
        marginBottom: '0.5em'
    },
    footerButton: {
        fontSize: '0.95em',
        fontWeight: 'normal',
        marginTop: '0.6em'
    },
    loginButton: {
        margin: '0 0 0.75em 0'
    },
    passwordResetContainer: {
        alignItems: 'center'
    },
    passwordResetTitle: {
        fontSize: '1.7em',
        marginBottom: '0.5em'
    }
});

interface Props extends WithStyles<typeof styles> {
    accessCode?: string;
    email: string;
    error: string;
    loading: boolean;
    step: LoginWizardSteps;
    title: string,
    captchaEnabled: boolean;
    captchaSiteKey: string;
    hideAccessCode: boolean;
    onCheckEmail?: (email: string) => void;
    onLogin?: (password: string, captchaToken: string) => void;
    onForgotPassword?: (email: string) => void;
    onResetPassword?: (email: string) => void;
    onGoBackToLogin?: () => void;
    onGotoCreateNewAccount?: () => void;
    onCreateNewAccount?: (newAccountData: LoginWizardNewAccountData) => void;
    onValidateNewAccount?: (validationCode: number) => void;
}

interface States {
    login: string
}

class LoginWizard extends React.Component<Props, States> {
    public static defaultProps = {
        captchaEnabled: false,
        captchaSiteKey: '',
        email: '',
        error: '',
        loading: false,
        hideAccessCode: false
    };

    state = {
        login: this.props.email,
    };

    onLoginNextButtonClicked = (email: string) => {
        const { onCheckEmail } = this.props;
        this.setState({login: email});

        if (onCheckEmail) {
            onCheckEmail(email);
        }
    }
    
    onLoginClicked = (password: string, captchaToken: string) => {
        const { onLogin } = this.props;
        if (onLogin) {
            onLogin(password, captchaToken);
        }
    }

    onForgotPasswordClicked = () => {
        const { login } = this.state;
        const { onForgotPassword } = this.props;
        if (onForgotPassword) {
            onForgotPassword(login);
        }
    }

    onResetPasswordClicked = (email: string) => {
        const { onResetPassword } = this.props;
        this.setState({login: email});

        if (onResetPassword) {
            onResetPassword(email);
        }
    }

    public render() {
        const {
            classes,
            accessCode,
            captchaEnabled,
            captchaSiteKey,
            error,
            loading,
            step,
            title,
            hideAccessCode,
            onGoBackToLogin,
            onGotoCreateNewAccount,
            onCreateNewAccount,
            onValidateNewAccount,
            onForgotPassword
        } = this.props;

        const {
            login,
            // password
        } = this.state;

        const stepsClasses = {
            root: classes.wizardContainer,
            divider: classes.divider,
            title: classes.title,
            error: classes.error,
            footerTitle: classes.footerTitle,
            footerButton: classes.footerButton,
            input: classes.input,
            inputLabel: classes.inputLabel,
            wizardContainer: classes.wizardContainer,
            textField: classes.textField
        };
        const stepLoginClasses = Object.assign({}, stepsClasses, {
            subtitle: classes.subtitle,
        });
        const stepPasswordClasses = Object.assign({}, stepLoginClasses, {
            loginButton: classes.loginButton,
            link: classes.link
        });
        const stepPasswordResetClasses = {
            root: classes.wizardContainer,
            divider: classes.divider,
            title: classes.passwordResetTitle,
            footerButton: classes.footerButton,
            passwordResetContainer: `${classes.wizardContainer} ${classes.passwordResetContainer}`,
            // subtitle: {},
            // textField: {}
        };
        const stepCreateNewAccount = Object.assign({}, stepsClasses);
        const stepValidateAccount =  {
            root: classes.wizardContainer,
            title: classes.title,
            subtitle: classes.subtitle,
            error: classes.error,
            footerButton: classes.footerButton,
            input: classes.input,
            inputLabel: classes.inputLabel,
            textField: classes.textField,
            wizardContainer: classes.wizardContainer
        };

        return (
            <div className={classes.root}>
                {
                    (step === LoginWizardSteps.Login) ?
                        <LoginWizardStepLogin 
                            classes={stepLoginClasses}
                            title="Let's get started"
                            subtitle={'Sign into your account'}
                            buttonText={'GET STARTED'}
                            footerTitleText=""
                            footerButtonText=""
                            error={error}
                            email={login}
                            onMainButtonClick={this.onLoginNextButtonClicked}
                        />
                        : (step === LoginWizardSteps.Password) ?
                            <LoginWizardStepPassword 
                                classes={stepPasswordClasses}
                                title={title}
                                subtitle={'Please enter your credentials to view your assigned location'}
                                buttonText={'RETURNING USER? LOG IN'}
                                footerTitleText=""
                                footerButtonText={'NEW USER? SIGN UP'}
                                captchaEnabled={captchaEnabled}
                                captchaSiteKey={captchaSiteKey}
                                email={login}
                                hideEmail={false}
                                error={error}
                                onLoginClick={this.onLoginClicked}
                                onForgotPasswordClick={onForgotPassword ? this.onForgotPasswordClicked : undefined}
                                onFooterButtonClick={onGotoCreateNewAccount}
                            />
                            : (step === LoginWizardSteps.PasswordReset) ?
                                <LoginWizardStepLogin 
                                    classes={stepLoginClasses}
                                    title={'Reset your password'}
                                    buttonText={'Reset Password'}
                                    footerTitleText={'Remember your password?'}
                                    footerButtonText={'LOG IN'}
                                    error={error}
                                    email={login}
                                    onMainButtonClick={this.onResetPasswordClicked}
                                    onFooterButtonClick={onGoBackToLogin}
                                />
                                : (step === LoginWizardSteps.PasswordResetSuccess) ?
                                    <LoginWizardStepPasswordResetSuccess
                                        classes={stepPasswordResetClasses}
                                        title={'Your password has been reset'}
                                        subtitle={'We\'ve sent a new password to'}
                                        email={login}
                                        footerButtonText={'LOG IN'}
                                        onFooterButtonClick={onGoBackToLogin}
                                    />
                                    : (step === LoginWizardSteps.NewAccount) ?
                                        <LoginWizardStepNewAccount
                                            classes={stepCreateNewAccount}
                                            accessCode={accessCode}
                                            hideAccessCode={hideAccessCode}
                                            email={login}
                                            title={'Create your account'}
                                            buttonText={'CREATE ACCOUNT'}
                                            footerTitleText={'Already have an account?'}
                                            footerButtonText={'LOG IN'}
                                            error={error}
                                            onFooterButtonClick={onGoBackToLogin}
                                            onMainButtonClick={onCreateNewAccount}
                                        />
                                        : (step === LoginWizardSteps.ValidateAccount) ?
                                            <LoginWizardStepValidateAccount
                                                classes={stepValidateAccount}
                                                title="Enter the Validation Code"
                                                subtitle="We just sent an email with the validation code"
                                                footerButtonText="VALIDATE ACCOUNT"
                                                error={error}
                                                onFooterButtonClick={onValidateNewAccount}
                                            />
                                            : 'Unknown Step'
                }
                <CCSpinner
                    overlayVisible={true}
                    loading={loading}
                />
            </div>
        );
    }
}

const MUIComponent = withStyles(styles)(LoginWizard);
export {MUIComponent as LoginWizard};
export type LoginWizardNewAccountData = LoginWizardNewAccountDataLocal;
