import * as React from 'react';

import {
    createStyles,
    WithStyles,
    withStyles
} from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import memoize from 'memoize-one';
import {
    PagingBuffer,
    PagingBufferPageBoundary as PageBoundary
} from '../../utils/paging-buffer';
import {
    SIMPLE_GRID_ORDER_ASC,
    SimpleGrid,
    SimpleGridCellDescriptor,
    SimpleGridOrder,
    SimpleGridProps,
    SimpleGridRowDescriptor,
    SimpleGridUtilities
} from '../simple-grid';
import { SimplePagination } from '../simple-pagination';

export interface SimpleGridUtilityFunctions {
    resetGrid: () => void;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const styles = (theme: Theme) => createStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        minHeight: '38.4em'
    },
    table: {},
    row: {}
});

interface Props extends SimpleGridProps, WithStyles<typeof styles> {
    className?: string;
    gridClassName?: string;
    offset: number;
    rowsPerPage: number;
    totalItems?: number;
    onComponentMount?: (utilities: SimpleGridUtilityFunctions) => void;
    onPageOverBoundary?: (boundary: PageBoundary, nextPage: number) => void;
}

interface States {
    orderBy?: string;
    order: SimpleGridOrder;
    page: number;
}

class SimpleGridPagination extends React.Component<Props, States> {
    public static defaultProps = {
        enableSorting: false,
        isLoading: false,
        order: SIMPLE_GRID_ORDER_ASC as SIMPLE_GRID_ORDER_ASC,
        showHeaders: true,
        offset: 0
    };
    
    getPagingBufferInstance = memoize(
        ( rowsData: SimpleGridRowDescriptor[],
            headersData: SimpleGridCellDescriptor[] | undefined,
            enableSorting: boolean,
            rowsPerPage: number,
            offset: number,
            order: SimpleGridOrder,
            orderBy?: string
        ): PagingBuffer<SimpleGridRowDescriptor> => {
            const rowsDataBuffer = 
                enableSorting ?
                    SimpleGridUtilities.sortRowsDataById(
                        rowsData,
                        order,
                        headersData,
                        orderBy
                    ):
                    rowsData;
            return new PagingBuffer(rowsDataBuffer, rowsPerPage, offset, 0);
        }
    );

    constructor(props: Readonly<Props>) {
        super(props);

        const { 
            order,
            orderBy
        } = props;

        this.state = {
            order,
            orderBy,
            page: 0
        };
    }
    
    componentDidMount() {
        const { onComponentMount } = this.props;
        if (onComponentMount) {
            onComponentMount({
                resetGrid: this.resetGrid
            });
        }
    }

    resetGrid = () => {
        const { 
            order,
            orderBy
        } = this.props;

        this.setState({
            order,
            orderBy,
            page: 0
        });
    }

    componentDidUpdate(prevProps: Props) {
        if  (this.props.totalItems !== prevProps.totalItems) {
            this.setState({page: 0});
        }
    }

    onPageChanged = (event: React.MouseEvent<HTMLButtonElement> | null, nextPage: number) => {
        const {
            rowsData,
            rowsPerPage,
            offset,
            headersData,
            enableSorting,
            order,
            orderBy,
            onPageOverBoundary
        } = this.props;

        const buffer: PagingBuffer<SimpleGridRowDescriptor> =
            this.getPagingBufferInstance(
                rowsData,
                headersData,
                enableSorting,
                rowsPerPage,
                offset,
                order,
                orderBy
            );
        // Set the new buffer page
        buffer.page = nextPage;
        
        const {
            isUpperBoundaryPassed,
            isLowerBoundaryPassed
        } = buffer;

        // If we hit the buffer's upper boundary or the buffer's lower boundary
        if (isUpperBoundaryPassed || isLowerBoundaryPassed) {
            if (onPageOverBoundary) {
                onPageOverBoundary(
                    isUpperBoundaryPassed ? PageBoundary.Upper : PageBoundary.Lower,
                    nextPage
                );
            }
        }

        this.setState({page: nextPage});
    }
    
    onSortClicked = (cellDescriptor: SimpleGridCellDescriptor, order: SimpleGridOrder) => {

        // If the sort callback is defined we let the parent to sort the data
        const { onSortClick } = this.props;
        if (onSortClick) {
            onSortClick(cellDescriptor, order);
            return;
        }

        // If the callback isn't defined we sort the internal buffer

        // If it was clicked to the same column
        const { orderBy } = this.state;

        // If it was clicked to the same column
        if (orderBy === cellDescriptor.id) {
            this.setState({order}); // We toggle the order (e.g. from asc to desc)
        } else { // If the clicked column was different...
            this.setState({ // we change the sorting column and reset the sorting order to ascending
                order: SIMPLE_GRID_ORDER_ASC,
                orderBy: cellDescriptor.id
            });
        }
    };

    public render() {
        const {
            classes,
            className,
            gridClassName,
            enableSorting,
            isLoading,
            offset,
            totalItems,
            headersData,
            rowsData,
            rowsPerPage,
            ...simpleGridProps
        } = this.props;
        const {
            order,
            orderBy,
            page
        } = this.state;

        const pagingBuffer =
            this.getPagingBufferInstance(
                rowsData,
                headersData,
                enableSorting,
                rowsPerPage,
                offset,
                order,
                orderBy
            );
        pagingBuffer.page = page;
        const bufferedListItems: SimpleGridRowDescriptor[] = pagingBuffer.buffer;

        const rootClassNames = classes.root + (className ? ` ${className}` : '');
        
        return (
            <div className={rootClassNames} >
                <SimpleGrid { ...simpleGridProps }
                    classes={{
                        table: classes.table,
                        row: classes.row
                    }}
                    className={gridClassName} /* overrides the class name present in the props */
                    enableSorting={enableSorting}
                    headersData={headersData}
                    isLoading={isLoading}
                    order={order}
                    orderBy={orderBy}
                    rowsData={bufferedListItems}
                    onSortClick={this.onSortClicked}
                />
                <SimplePagination
                    count={totalItems ? totalItems : rowsData.length}
                    isLoading={isLoading}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    onPageChange={this.onPageChanged}
                />
            </div>
        );
    }
}

const MUIComponent = withStyles(styles)(SimpleGridPagination);
export { MUIComponent as SimpleGridPagination };
export { PageBoundary };
