import {
    createStyles,
    WithStyles,
    withStyles
} from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import * as React from 'react';
import { SimpleModal } from '../../shared/components/simple-modal';
import { Button, TextField, Typography } from '@material-ui/core';

export type DeleteEntity = 'floorplan';

const styles = (theme: Theme) => createStyles({
    resumePopup: {
        width: '41em',
        borderRadius: 5,
    },
    resumePopupHeader: {
        height: '2em',
        backgroundColor: theme.palette.primary.main,
        borderRadius: '3px 3px 0 0',
        color: 'white',
        padding: '0.5em',
        fontSize: '1.5em'
    },
    popupContentContainer :{
        display: 'flex',
        flexDirection: 'row',
        padding: theme.spacing(1),
    },
    mainSpinnerLabel: {
        paddingBottom: '30px'
    },
    modalButtonContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row'
    },
    modalButton: {
        width: '49%',
        margin: '0 1em'
    },
    textField: {
        padding: '1em',
        width: '100%',
        marginBottom: '1em',
        '& label': {
            fontWeight: 'bolder',
            padding: '1em 0 0 1em',

            fontSize: '1.4em',
            color: 'rgba(0, 0, 0, 0.8) !important'
        },
        '&::before': {
            borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
            borderBottomStyle: 'solid'
        }
    },
    labels: {
        marginBottom: '1.5em',
        textAlign: 'center'
    },
    modalContentHolder: {
        width: '100%'
    }
});

interface Props extends WithStyles<typeof styles> {
    isDeleteModalOpen: boolean;
    deleteEntity: DeleteEntity;
    confirmationPrompt: string;
    confirmationPromptField: string;
    entityName: string;
    closeDeleteModal?: () => void;
    onSubmitDelete?: () => void;
}

interface States {
    confirmationInputValue: string;
}

class DeleteConfirmationPrompt extends React.Component<Props, States> {
    public static defaultProps = {
    };

    state: States = {
        confirmationInputValue: ''
    }

    handleDisableEvent = (e: { preventDefault: () => void; }) => {
        e.preventDefault();
    }

    handleStringChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({confirmationInputValue: e.target.value});
    }

    public render() {
        const {
            classes,
            isDeleteModalOpen,
            deleteEntity,
            confirmationPrompt,
            confirmationPromptField,
            entityName,
            closeDeleteModal,
            onSubmitDelete
        } = this.props;

        const {
            confirmationInputValue
        } = this.state;

        return (
            <SimpleModal
                className={classes.resumePopup}
                open={isDeleteModalOpen}
                contentClasses={classes.popupContentContainer}
                buttonOkLabel=""
                buttonCancelLabel=""
                header={`Delete ${deleteEntity}: ${entityName}`}
                headerClassName={classes.resumePopupHeader}
            >
                <div className={classes.modalContentHolder} data-test-id="delete-entity-prompt">
                    <Typography variant="subtitle1" className={classes.labels}>
                        Please confirm that you want to delete this {deleteEntity}: 
                        
                    </Typography>
                    <Typography variant="subtitle1" className={classes.labels}>
                        <b>
                            {entityName}
                        </b>
                    </Typography>
                    <Typography variant="subtitle1" className={classes.labels}>
                        This action is PERMANENT and cannot be reversed
                    </Typography>
                    <Typography variant="subtitle1" className={classes.labels}>
                        To proceed type the {confirmationPromptField} of the {deleteEntity} in the input below
                    </Typography>
                    <TextField
                        className={classes.textField}
                        id="delete_modal_prompt"
                        placeholder={`${deleteEntity} ${confirmationPromptField}`}
                        multiline={false}
                        onChange={this.handleStringChange}
                        // PREVENT COPY/CUT/PASTE OPERATIONS
                        onCut={this.handleDisableEvent}
                        onCopy={this.handleDisableEvent}
                        onPaste={this.handleDisableEvent}
                    />
                    <div className={classes.modalButtonContainer}>
                        <Button
                            variant="contained"
                            color="secondary"
                            className={classes.modalButton}
                            onClick={closeDeleteModal}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            data-testid="submit-delete-modal"
                            className={classes.modalButton}
                            onClick={onSubmitDelete}
                            disabled={confirmationPrompt !== confirmationInputValue}
                        >
                            Delete
                        </Button>
                    </div>
                </div>
            </SimpleModal>
        );
    }
}

const MUIComponent = withStyles(styles)(DeleteConfirmationPrompt);
export {MUIComponent as DeleteConfirmationPrompt}