import * as React from 'react';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

export default class BugsnagProvider {
    private apiKey: string;

    constructor() {
        this.apiKey = `${process.env.REACT_APP_BUGSNAG_KEY}`;
    }

    startBugsnag = () => {
        return Bugsnag.start({
            apiKey: this.apiKey,
            plugins: [new BugsnagPluginReact()]
        });
    };

    createErrorWrapper = () => {
        return Bugsnag.getPlugin('react')!
            .createErrorBoundary(React);
    };
}
