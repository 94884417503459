import { Structure } from '../models/structure';
import { HttpConnectorAdapter } from './../adapters/http-connector-adapter';

export default class StructureProvider {
    private endpointAdmin: string;

    constructor() {
        this.endpointAdmin = `${process.env.REACT_APP_ADMIN_TOOL_API}structure`;
    };

    init = () => {
        return HttpConnectorAdapter;
    };

    getStructureById = (structureId: string) => {
        return this.init().getWithCredentials(`${this.endpointAdmin}/${structureId}`).then(response => {
            const structure = response.data as Structure;
            const statusCode = response.status;

            return { status: statusCode, structure };
        });
    }

    updateStructure = (structureId: string, structure: Structure) => {
        delete structure['_rev'];
        const headers = { 'Content-Type': 'application/json' };
        return this.init().putWithCredentials(`${this.endpointAdmin}/${structureId}`, JSON.stringify(structure), headers).then(response => {
            const structure = response.data as Structure;
            const statusCode = response.status;

            return { status: statusCode, structure };
        });
    }

    createStructure = (structure: Structure) => {
        const newStructure = {...structure} as any;
        delete newStructure['_id'];
        delete newStructure['timestamp'];
        delete newStructure['structure_id'];
        const headers = { 'Content-Type': 'application/json' };
        return this.init().postWithCredentials(`${this.endpointAdmin}/`, JSON.stringify(newStructure), headers).then(response => {
            const structure = response.data as Structure;
            const statusCode = response.status;

            return { status: statusCode, structure };
        });
    }
}