import * as React from 'react';
import { createStyles, IconButton, InputBase, Paper, Theme, Typography, withStyles } from "@material-ui/core";
import { WithStyles } from "@material-ui/styles";
import { Floorplan } from "../../shared/domain";
import { SearchRounded } from '@material-ui/icons';
import { SimpleListItem } from '../../shared/components/simple-list';
import { memoize } from 'lodash';
import { sortByKey } from '../../utils/sort-utilities';
import { CCSpinner } from '../../shared/components/cc-spinner';
import { SimpleListPagination } from '../../shared/components/simple-list-pagination';


const styles = (theme: Theme) => createStyles({
    root: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    container: {
        display: 'flex',
        flexDirection: 'column'
    },
    heading: {
        fontWeight: 700,
        marginBottom: '0.9em'
    },
    ellipsis: {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden'
    },
    simpleList: {
        overflow: 'auto',
        height: '100%',
        minWidth: '20em'
    },
    simpleListContainer: {
        marginRight: '4em',
        minHeight: '25em',
        width:'20em'
    },
    iconButton: {
        padding: 10,
        color: '#009933',
    },
    searchInputHolder: {
        display: 'flex',
        alignItems: 'center',
        width: '20em',
        borderBottom: '1px solid gray',
        '& input': {
            width: '15em'
        }
    },
    boxContainer: {
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: '4em',
        marginTop: '50px',
    },
    emptyContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        fontWeight: 'bold',
        textAlign: 'center',
        justifyContent: 'center',
        '& > *': {
            color: 'rgba(0,0,0,0.2)'
        }
    },
    searchListHolder: {
        marginTop: '1em'
    }
});

interface Props extends WithStyles<typeof styles> {
    className?: string;
    rowsPerPage: number;
    floorplans: Floorplan[];
    floorplanIsLoading: boolean;
    onFolderSearch?: (folderId: string) => void;
    onFloorplanClick?: (floorplanId: string, floorplanNane: string) => void ;
}

interface States {
    folderSearch: string
}

class FolderIdFloorplans extends React.Component<Props, States> {

    state: States = {
        folderSearch: ''
    };

    getFloorplanListItems = memoize(floorPlans => {
        return floorPlans.sort(sortByKey('name')).map((floorPlan: Floorplan) => {
            return new SimpleListItem(floorPlan.id, floorPlan.name, floorPlan.id);
        });
    });

    onFolderSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({folderSearch: event.target.value});
    }

    onFloorplanClicked = (listItem: SimpleListItem) => {
        const {floorplans, onFloorplanClick} = this.props;
        if (!floorplans) {
            return;
        }

        const currentFloorplan = floorplans.find((floorplan:Floorplan) => {
            return floorplan.id === listItem.id;
        });
        if (!currentFloorplan) {
            return;
        }

        if (onFloorplanClick) {
            onFloorplanClick(currentFloorplan.id, currentFloorplan.name);
        }
    };

    onFolderSearch = () =>{
        const { onFolderSearch } = this.props;

        if (!onFolderSearch) {
            return;
        }

        const { folderSearch } = this.state;

        if (folderSearch === '') {
            return;
        }

        onFolderSearch(folderSearch);
    }

    onKeyPressed = (event:any) => {
        if (event.key !== 'Enter') {
            return;
        }
        this.onFolderSearch();
        event.preventDefault();
    }

    public render() {
        const {
            className,
            classes,
            floorplans,
            floorplanIsLoading,
            rowsPerPage
        } = this.props;
        const {
            folderSearch
        } = this.state;

        return (
            <div className={`${classes.root} ${className}`} data-testid="search-floorplans-by-folder-id">
                <div className={classes.container}>
                    <div className={classes.searchInputHolder}>
                        <InputBase
                            placeholder="<Folder ID>"
                            value={folderSearch}
                            onChange={this.onFolderSearchChange}
                            onKeyPress={this.onKeyPressed}
                        />
                        <IconButton
                            type="submit"
                            className={classes.iconButton}
                            onClick={this.onFolderSearch}
                            data-testid="folder-id-search-btn"
                        >
                            <SearchRounded />
                        </IconButton>
                    </div>
                    {
                        floorplans.length > 0 ?
                            <div className={classes.searchListHolder}>
                                <Typography
                                    variant='h6'
                                    className={`${classes.heading} ${classes.ellipsis}`}
                                >
                                    Floor plan List
                                </Typography>
                                <SimpleListPagination
                                    className={classes.simpleList}
                                    keepItemSelected={true}
                                    items={this.getFloorplanListItems(floorplans)}
                                    isLoading={floorplanIsLoading}
                                    onListItemClick={this.onFloorplanClicked}
                                    rowsPerPage={rowsPerPage}
                                />
                            </div>
                        :
                        <Paper className={`${classes.container} ${classes.simpleListContainer} ${classes.boxContainer} ${classes.emptyContainer}`}>
                            <CCSpinner
                                loading={floorplanIsLoading}
                                size={70}
                            >
                                <Typography variant='h4'>
                                    No Floor Plan found
                                </Typography>
                            </CCSpinner>
                        </Paper>
                    }
                </div>
            </div>
        );
    }
}

const MUIComponent = withStyles(styles)(FolderIdFloorplans);
export { MUIComponent as FolderIdFloorplans };
