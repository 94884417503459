import { AccountFull } from '../models/account';
import { RoleDescription } from '../models/role';
import { HttpConnectorAdapter } from './../adapters/http-connector-adapter';

export default class SchedulingProvider {
    private endpointAdmin: string;

    constructor() {
        this.endpointAdmin = `${process.env.REACT_APP_ADMIN_TOOL_API}scheduling`;
    }

    init = () => {
        return HttpConnectorAdapter;
    };

    fetchSchedulingAccounts = () => {
        return this.init()
            .getWithCredentials(`${this.endpointAdmin}/available_accounts`)
            .then(response => {
                const status = response.status;
                const { docs, total } = response.data;

                return {
                    status,
                    accounts: docs as AccountFull[],
                    total_account: total
                };
            });
    };

    createSchedulingRole = (roleData: RoleDescription) => {
        const newRole = { ...roleData } as any;
        delete newRole['id'];
        const headers = { 'Content-Type': 'application/json' };
        return this.init()
            .postWithCredentials(
                `${this.endpointAdmin}/`,
                JSON.stringify(newRole),
                headers
            )
            .then(response => {
                const role = response.data as RoleDescription;
                const statusCode = response.status;

                return { status: statusCode, role };
            });
    };

    updateShedulingRole = (roleData: RoleDescription) => {
        const newRole = { ...roleData } as any;
        const headers = { 'Content-Type': 'application/json' };
        return this.init()
            .putWithCredentials(
                `${this.endpointAdmin}/${roleData.id}`,
                JSON.stringify(newRole),
                headers
            )
            .then(response => {
                const role = response.data as RoleDescription;
                const statusCode = response.status;

                return { status: statusCode, role };
            });
    };
}
