import * as React from 'react';
import {
    createStyles,
    Theme,
    WithStyles,
    withStyles
} from '@material-ui/core/styles';
import { RootStore } from '../../stores/root.store';
import { AccountFull } from '../../models/account';
import { inject, observer } from 'mobx-react';
import AccountProvider from '../../providers/account.provider';
import { CCSpinner } from '../../shared/components/cc-spinner';
import { AccountPicker } from '../../components';
import { PageBoundary } from '../../shared/components/simple-grid-pagination';
import { Button, Typography } from '@material-ui/core';
import { AddOutlined } from '@material-ui/icons';
import { SimpleListPagination } from '../../shared/components/simple-list-pagination';
import { SimpleListItem } from '../../shared/components/simple-list';
import { Folder } from '../../shared/domain';
import { DialogResult, SimpleDialog } from '../../shared/components/simple-dialog';
import SchedulingProvider from '../../providers/scheduling.provider';
import { RoleDescription } from '../../models/role';
import { ScheduleRoleForm } from '../../components/schedule-role-form';
import { formIntent } from '../teams/teams';
import { SimpleModal } from '../../shared/components/simple-modal';
import { UserFull } from '../../models/user';
import TeamProvider from '../../providers/team.provider';

export interface RoleRegisteredChanges {
    field: string;
    label: string;
    value: string;
}

const ROWS_PER_PAGE = 10;

const styles = (theme: Theme) =>
    createStyles({
        container: {
            flex: 1,
            display: 'flex',
            flexDirection: 'row'
        },
        mainSpinnerLabel: {
            paddingBottom: '30px'
        },
        progressContainer: {
            flexGrow: 1
        },
        animContainer: {
            transition: 'width 1s'
        },
        formHolder: {
            marginLeft: '1em',
            marginTop: '1.5em'
        },
        simpleList: {
            overflow: 'auto',
            height: '100%',
            minWidth: '25em',
            marginTop: '1.5em',
            '& .MuiTypography-displayBlock': {
                whiteSpace: 'break-spaces !important'
            }
        },
        heading: {
            fontWeight: 700,
            marginBottom: '0.5em',
            marginTop: '1em'
        },
        ellipsis: {
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden'
        },
        list: {
            flex: '1 0',
            overflow: 'auto',
            boxShadow: 'none'
        },
        listLoader: {
            height: '25em',
            background: 'lightgray',
            marginTop: '1em'
        },
        resumePopup: {
            width: '41em',
            borderRadius: 5
        },
        resumePopupHeader: {
            height: '2em',
            backgroundColor: theme.palette.primary.main,
            borderRadius: '3px 3px 0 0',
            color: 'white',
            padding: '0.5em',
            fontSize: '1.5em'
        },
        popupContentContainer: {
            display: 'flex',
            flexDirection: 'column',
            padding: theme.spacing(1)
        },
        modalButtonContainer: {
            width: '100%',
            display: 'flex',
            flexDirection: 'row'
        },
        modalButton: {
            width: '49%',
            margin: '0 1em'
        }
    });

interface Props extends WithStyles<typeof styles> {
    rootStore: RootStore;
    onAuthError?: () => void;
}

interface States {
    rolePermissions: string[];
    accounts: AccountFull[];
    currentAccount: AccountFull | null;
    currentAccountOffset: number;
    accountFolders: Folder[];
    accountUsers: UserFull[];
    isAccountUsersLoading: boolean;
    isErrorModalOpened: boolean;
    errorModalHeader: string;
    errorModalText: string;
    dataPageIsLoading: boolean;
    isAccountsLoading: boolean;
    isFoldersLoading: boolean;
    isRolesLoading: boolean;
    totalAccounts: number;
    mainSpinnerText: string;
    accountRoles: RoleDescription[];
    selectedRole: RoleDescription | null;
    roleToSubmit: RoleDescription | null;
    formIntent: formIntent;
    changeList: SimpleListItem[];
    isCreateRole: boolean;
    isFormSuccess: boolean;
    isConfirmChanges: boolean;
    formHasChanges: boolean;
    tempRole: SimpleListItem | null;
    isChangesModalOpen: boolean;
}

@inject('rootStore')
@observer
class SchedulingPermissions extends React.Component<Props, States> {
    public static defaultProps: Partial<Props> = {};

    state: States = {
        rolePermissions: [],
        accounts: [],
        currentAccount: null,
        currentAccountOffset: 0,
        accountFolders: [],
        accountUsers: [],
        isAccountUsersLoading: false,
        isErrorModalOpened: false,
        errorModalHeader: '',
        errorModalText: '',
        dataPageIsLoading: true,
        isAccountsLoading: false,
        isRolesLoading: false,
        totalAccounts: 0,
        mainSpinnerText: 'Please wait. Loading data.',
        accountRoles: [],
        selectedRole: null,
        roleToSubmit: null,
        formIntent: 'CREATE',
        changeList: [],
        isCreateRole: false,
        isFoldersLoading: false,
        isFormSuccess: false,
        isConfirmChanges: false,
        formHasChanges: false,
        tempRole: null,
        isChangesModalOpen: false
    };

    schedulingProvider = new SchedulingProvider();
    accountProvider = new AccountProvider();
    teamProvider = new TeamProvider();

    componentDidMount() {
        this.fetchAccounts();
    }

    fetchAccounts = () => {
        return this.schedulingProvider
            .fetchSchedulingAccounts()
            .then(results => {
                const { accounts, total_account } = results;
                if (!accounts) {
                    this.setState({
                        isErrorModalOpened: true,
                        errorModalHeader: 'Error fetching accounts',
                        errorModalText: 'No accounts were found'
                    });
                    return;
                }

                this.setState({
                    isAccountsLoading: false,
                    totalAccounts: total_account,
                    accounts,
                    currentAccountOffset: 0,
                    dataPageIsLoading: false
                });
            })
            .catch(error => {
                const { status } = error.response;
                if (status === 401) {
                    const { onAuthError } = this.props;
                    if (!onAuthError) {
                        return;
                    }
                    onAuthError();
                    return;
                }
                this.setState({
                    isErrorModalOpened: true,
                    errorModalHeader: 'Error fetching accounts',
                    errorModalText: error.message
                });
            });
    };

    onAccountClicked = (account: AccountFull) => {
        const { currentAccount } = this.state;
        if (currentAccount === account) {
            return;
        }

        let rolePermissions = [];

        if (account.meta["scheduling_routes"] && account.meta["scheduling_routes"]["admin_tool"]) {
            rolePermissions = account.meta["scheduling_routes"]["admin_tool"]["permissions"]
        }

        this.setState(
            {
                currentAccount: account,
                isRolesLoading: true,
                isFoldersLoading: true,
                roleToSubmit: null,
                selectedRole: null,
                formHasChanges: false,
                rolePermissions
            },
            () => {
                this.fetchAccountFolders(account);
                this.fetchAccountRoles(account);
                this.fetchAccountTeamAvailableUsers(account);
            }
        );
    };

    fetchAccountFolders = (account: AccountFull) => {
        this.accountProvider
            .getFoldersByAccountId(account.account_id)
            .then(data => {
                this.setState({
                    accountFolders: data,
                    isFoldersLoading: false
                });
            })
            .catch(error => {
                const { status } = error.response;
                if (status === 401) {
                    const { onAuthError } = this.props;
                    if (!onAuthError) {
                        return;
                    }
                    onAuthError();
                    return;
                }
                this.setState({
                    isErrorModalOpened: true,
                    errorModalHeader: 'Error fetching accounts',
                    errorModalText: error.message
                });
            });
    };

    fetchAccountRoles = (account: AccountFull) => {
        this.accountProvider
            .fetchAccountRoles(account.account_id)
            .then(data => {
                this.setState({
                    accountRoles: data.roles,
                    isRolesLoading: false
                });
            })
            .catch(error => {
                const { status } = error.response;
                if (status === 401) {
                    const { onAuthError } = this.props;
                    if (!onAuthError) {
                        return;
                    }
                    onAuthError();
                    return;
                }
                this.setState({
                    isErrorModalOpened: true,
                    errorModalHeader: 'Error fetching accounts',
                    errorModalText: error.message
                });
            });
    };

    fetchAccountTeamAvailableUsers = (account: AccountFull) => {
        this.setState({ isAccountUsersLoading: true });
        let teamId = ''
        try {
            teamId = account.meta["scheduling_routes"]["admin_tool"]["permissions_users_lookup_team"];
            this.teamProvider
                .fetchTeamUsers(teamId)
                .then(data => {
                    const { users } = data;
                    this.setState({
                        accountUsers: users,
                        isAccountUsersLoading: false
                    })
                })
                .catch(error => {
                    const { status } = error.response;
                    if (status === 401) {
                        const { onAuthError } = this.props;
                        if (!onAuthError) {
                            return;
                        }
                        onAuthError();
                        return;
                    }
                    this.setState({
                        isErrorModalOpened: true,
                        isAccountUsersLoading: false,
                        errorModalHeader: 'Error fetching account users',
                        errorModalText: error.message
                    });
                });
        } catch (error) {
            this.setState({
                isErrorModalOpened: true,
                isAccountUsersLoading: false,
                errorModalHeader: 'Error fetching account users',
                errorModalText: "Account doesn't have PCL team set up"
            });
        }
    }

    onAccountDataPageOverBoundaryReached = (
        boundary: PageBoundary,
        nextPage: number
    ): Promise<void> => {
        return new Promise<void>((resolve, reject) => {
            resolve();
        });
    };

    onErrorModalClicked = () => {
        this.setState({
            isErrorModalOpened: false,
            errorModalHeader: '',
            errorModalText: ''
        });
    };

    onCreateRolClicked = () => {
        const { currentAccount } = this.state;

        if (!currentAccount) {
            return;
        }

        this.setState(
            {
                isCreateRole: true,
                selectedRole: null,
                roleToSubmit: {} as RoleDescription,
                isConfirmChanges: false
            },
            () => {
                this.setState({
                    selectedRole: {
                        name: '',
                        id: 0,
                        permissions: [],
                        permissions_list: [],
                        users: [],
                        folder_ids: [],
                        account_doc_id: currentAccount._id,
                        members: []
                    }
                });
            }
        );
    };

    onRoleClick = (listItem: SimpleListItem) => {
        const { accountRoles } = this.state;
        const { formHasChanges } = this.state;

        if (!accountRoles) {
            return;
        }

        const selectedRole = accountRoles.find(
            e => e.id === Number.parseInt(listItem.id)
        );

        if (!selectedRole) {
            return;
        }

        if (!formHasChanges) {
            this.setState(
                {
                    isCreateRole: false,
                    selectedRole: null
                },
                () => {
                    this.setState({ selectedRole });
                }
            );
            return;
        }

        const { tempRole } = this.state;

        if (tempRole && tempRole.id === listItem.id) {
            return;
        }

        this.setState({
            tempRole: listItem,
            isChangesModalOpen: true
        });
    };

    setFormChanges = () => {
        this.setState({ formHasChanges: true });
    };

    onSubmitChanges = (
        roleInForm: RoleDescription,
        changeList: RoleRegisteredChanges[],
        intent: formIntent
    ) => {
        const changeItemList = changeList.map(e => {
            return new SimpleListItem(e.field, e.label, e.value);
        });

        this.setState({
            roleToSubmit: roleInForm,
            formIntent: intent,
            changeList: changeItemList,
            isConfirmChanges: true
        });
    };

    closeResumeModal = () => {
        this.setState({ isConfirmChanges: false, changeList: [] });
    };

    onSubmitRole = () => {
        const { formIntent, roleToSubmit } = this.state;

        if (!roleToSubmit) {
            return;
        }

        this.setState(
            {
                isConfirmChanges: false,
                selectedRole: null,
                isCreateRole: false,
                formHasChanges: false
            },
            () => {
                if (formIntent === 'CREATE') {
                    this.onCreateSubmit(roleToSubmit);
                } else {
                    this.onEditSubmit(roleToSubmit);
                }
            }
        );
    };

    onCreateSubmit = (newRole: RoleDescription) => {
        this.setState({
            dataPageIsLoading: true,
            mainSpinnerText: 'Creating Role',
            changeList: []
        });
        this.schedulingProvider
            .createSchedulingRole(newRole)
            .then(data => {
                const { currentAccount } = this.state;

                if (!currentAccount) {
                    return;
                }

                this.setState(
                    {
                        isFormSuccess: true
                    },
                    () => {
                        this.fetchAccountRoles(currentAccount);
                    }
                );

                return;
            })
            .catch(error => {
                console.error(error);
                const { status, data } = error.response;
                if (status === 401) {
                    const { onAuthError } = this.props;
                    if (!onAuthError) {
                        return;
                    }
                    onAuthError();
                    return;
                }
                this.setState({
                    isErrorModalOpened: true,
                    errorModalHeader: 'Error on rol creation',
                    errorModalText: data ? `${data.error}, ${data.message}` : error
                });
            })
            .finally(() => {
                this.setState({ dataPageIsLoading: false });
            });
    };

    onEditSubmit = (updateRole: RoleDescription) => {
        this.setState({
            dataPageIsLoading: true,
            mainSpinnerText: 'Updating Role',
            changeList: []
        });
        this.schedulingProvider
            .updateShedulingRole(updateRole)
            .then(data => {
                const { currentAccount } = this.state;

                if (!currentAccount) {
                    return;
                }

                this.setState(
                    {
                        isFormSuccess: true
                    },
                    () => {
                        this.fetchAccountRoles(currentAccount);
                    }
                );

                return;
            })
            .catch(error => {
                console.error(error);
                const { status, data } = error.response;
                if (status === 401) {
                    const { onAuthError } = this.props;
                    if (!onAuthError) {
                        return;
                    }
                    onAuthError();
                    return;
                }
                this.setState({
                    isErrorModalOpened: true,
                    errorModalHeader: 'Error on rol update',
                    errorModalText: data ? `${data.error}, ${data.message}` : error
                });
            })
            .finally(() => {
                this.setState({ dataPageIsLoading: false });
            });
    };

    onSuccessModalClick = () => {
        this.setState({
            isFormSuccess: false,
            selectedRole: null,
            formIntent: 'CREATE'
        });
    };

    onChangesModalClicked = (dialogResult: DialogResult) => {
        if (!dialogResult) {
            return;
        }

        if (dialogResult === DialogResult.Ok) {
            const { tempRole } = this.state;
            if (tempRole) {
                this.setState({
                    formHasChanges: false
                }, () => {
                    this.onRoleClick(tempRole);
                });
            }
        }

        this.setState({isChangesModalOpen: false});
    };

    public render() {
        const { classes } = this.props;
        const {
            mainSpinnerText,
            dataPageIsLoading,
            rolePermissions,
            accounts,
            isAccountsLoading,
            isFoldersLoading,
            isRolesLoading,
            totalAccounts,
            currentAccount,
            accountUsers,
            isAccountUsersLoading,
            currentAccountOffset,
            isErrorModalOpened,
            errorModalHeader,
            errorModalText,
            accountRoles,
            selectedRole,
            accountFolders,
            changeList,
            isCreateRole,
            isFormSuccess,
            isConfirmChanges,
            formIntent,
            isChangesModalOpen
        } = this.state;

        const currentAccountName = currentAccount ? currentAccount.name : '';
        const roleList = currentAccount
            ? accountRoles.map(e => {
                  return new SimpleListItem(
                      `${e.id}`,
                      e.name,
                      `ID: ${e.id} - Folders: ${e.folder_ids.length}`
                  );
              })
            : [];

        return (
            <div className={classes.container} data-testid="mainRender">
                <CCSpinner
                    label={mainSpinnerText}
                    labelClassName={classes.mainSpinnerLabel}
                    className={classes.progressContainer}
                    loading={dataPageIsLoading}
                    size={200}
                >
                    <AccountPicker
                        className={`${classes.animContainer}`}
                        rowsPerPage={ROWS_PER_PAGE}
                        accounts={accounts}
                        accountsIsLoading={isAccountsLoading}
                        accountsTotalItems={totalAccounts}
                        accountsItemsOffset={currentAccountOffset}
                        currentAccountName={currentAccountName}
                        onAccountClick={this.onAccountClicked}
                        onAccountDataPageOverBoundary={
                            this.onAccountDataPageOverBoundaryReached
                        }
                    />
                    {currentAccount && (
                        <div className={classes.formHolder}>
                            <Typography
                                variant="h6"
                                className={`${classes.heading} ${classes.ellipsis}`}
                            >
                                Permissions Roles on {currentAccountName}
                            </Typography>
                            <Button
                                variant="contained"
                                color="secondary"
                                startIcon={<AddOutlined />}
                                onClick={this.onCreateRolClicked}
                                data-testid="create-role-button"
                            >
                                Create new Role
                            </Button>
                            <CCSpinner
                                loading={isFoldersLoading || isRolesLoading}
                                className={classes.listLoader}
                            >
                                <div>
                                    <SimpleListPagination
                                        className={classes.simpleList}
                                        keepItemSelected={false}
                                        items={roleList}
                                        onListItemClick={this.onRoleClick}
                                        rowsPerPage={ROWS_PER_PAGE}
                                        totalItems={roleList.length}
                                        offset={0}
                                        noItemsLabel="No Roles found for this account"
                                    />
                                </div>
                            </CCSpinner>
                        </div>
                    )}
                    {selectedRole && currentAccount && (
                        <ScheduleRoleForm
                            currentAccount={currentAccount}
                            accountUsers={accountUsers}
                            isAccountUsersLoading={isAccountUsersLoading}
                            accountFolders={accountFolders}
                            isCreate={isCreateRole}
                            rolePermissions={rolePermissions}
                            pickedRole={selectedRole}
                            setFormChanges={this.setFormChanges}
                            onFormSubmit={this.onSubmitChanges}
                        />
                    )}
                </CCSpinner>
                <SimpleDialog
                    open={isErrorModalOpened}
                    titleText={errorModalHeader}
                    contentText={errorModalText}
                    buttonCancelLabel=""
                    onDialogResult={this.onErrorModalClicked}
                />
                <SimpleModal
                    className={classes.resumePopup}
                    open={isConfirmChanges}
                    contentClasses={classes.popupContentContainer}
                    buttonOkLabel=""
                    buttonCancelLabel=""
                    header="Role changes resume"
                    headerClassName={classes.resumePopupHeader}
                >
                    <Typography variant="subtitle1">
                        Please review the role changes before submitting, once
                        you are sure they are correct click on the submit button
                    </Typography>
                    <SimpleListPagination
                        className={classes.simpleList}
                        keepItemSelected={true}
                        items={changeList}
                        isLoading={false}
                        rowsPerPage={ROWS_PER_PAGE}
                        totalItems={changeList.length}
                        offset={0}
                    />
                    <div className={classes.modalButtonContainer}>
                        <Button
                            variant="contained"
                            color="secondary"
                            className={classes.modalButton}
                            onClick={this.closeResumeModal}
                            data-testid="cancel-changes-button"
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.modalButton}
                            onClick={this.onSubmitRole}
                            data-testid="submit-changes-button"
                        >
                            Submit
                        </Button>
                    </div>
                </SimpleModal>
                <SimpleDialog
                    open={isChangesModalOpen}
                    titleText="You have unsaved changes"
                    contentText="Do you wish to discard them?"
                    buttonCancelLabel="No"
                    buttonOkLabel="Yes"
                    onDialogResult={this.onChangesModalClicked}
                />
                <SimpleDialog
                    open={isFormSuccess}
                    titleText="Success"
                    contentText={
                        formIntent === 'CREATE'
                            ? 'Rol Created Succesfully'
                            : 'Rol Updated Succesfully'
                    }
                    buttonCancelLabel=""
                    onDialogResult={this.onSuccessModalClick}
                />
            </div>
        );
    }
}

const MUIComponent = withStyles(styles)(SchedulingPermissions);
export { MUIComponent as SchedulingPermissions };
