import { TextField } from '@material-ui/core';
import {
    createStyles,
    WithStyles,
    withStyles,
    Theme
} from '@material-ui/core/styles';
import { Autocomplete } from '@material-ui/lab';
import * as React from 'react';
import { UserModule } from '../../models/user-module';
import { SimpleList, SimpleListItem } from '../../shared/components/simple-list';
import { CancelOutlined } from '@material-ui/icons';
import { UserModuleGroup } from '../../models/module-group';
import UserModuleGroupProvider from '../../providers/usermodulegroup.provider';

const styles = (theme: Theme) => createStyles({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    list: {
        flex: '1 0',
        overflow: 'auto',
        boxShadow: 'none'
    },
    selectInput: {
        margin: '1em'
    },
    errorItem: {
        color: '#eb445a',
        marginRight: '0.5em',
        fontSize: '2em'
    },
});

interface Props extends WithStyles<typeof styles> {
    className?: string;
    userModuleGroup: string;
    userModules: UserModule[];
    moduleKey: string;
    canEdit: boolean;
    onMarkerUserModuleGroupLoad?: (markerUserModuleGroup: UserModuleGroup) => void;
    onModuleChange?: (moduleList: string[]) => void;
    registerAction?: (module: string, operation: string, moduleKey: string) => void;
}

interface States {
    moduleList: SimpleListItem[];
    existingModuleList: string[];
    isListLoading: boolean;
    autoCompleteValue: UserModule | null | undefined;
    autoCompleteKey: string;
}

class UserModuleForm extends React.Component<Props, States> {

    generateRandomKey = () => {
        const randomKey = Math.random().toString(36).substring(7);
        return randomKey;
    };

    state: States = {
        moduleList: [],
        existingModuleList: [],
        isListLoading: true,
        autoCompleteValue: null,
        autoCompleteKey: this.generateRandomKey()
    }

    moduleGroupProvider = new UserModuleGroupProvider();

    componentDidMount() {
        this.getUserModuleListFromModuleGroup();
    }
    
    getUserModuleListFromModuleGroup = () => {
        const { userModuleGroup, classes } = this.props;
        if (!userModuleGroup) {
            this.setState({
                existingModuleList: [],
                isListLoading: false
            }, () => {
                const newUserModuleGroup: UserModuleGroup = {
                    _id: "",
                    autovalue: {
                        defaults: {
                            feedback_message: "Thank you for your report!",
                            feedback_title: "Thanks!",
                            status: "."
                        },
                        meta_handler: []
                    },
                    channels: ["public"],
                    data_visualizations: [],
                    last_modified: "2023-10-27T20:50:01.428Z",
                    meta: {},
                    module_group_id: "",
                    modules: [],
                    name: "Standard Dedicated",
                    prompt: "",
                    timestamp: "",
                    flag_to_clone: true
                };

                const { onMarkerUserModuleGroupLoad } = this.props;

                if (onMarkerUserModuleGroupLoad) {
                    onMarkerUserModuleGroupLoad(newUserModuleGroup);
                }
            });
            return;
        }
        this.moduleGroupProvider.fetchModuleGroup(userModuleGroup).then(data => {
            const { module: moduleGroup } = data;
            if (!moduleGroup.modules) {
                this.setState({isListLoading: false});
                throw new Error("Module list not found");
            }

            this.setState({
                existingModuleList: moduleGroup.modules,
                isListLoading: false
            }, () => {
                const { onMarkerUserModuleGroupLoad } = this.props;

                if (onMarkerUserModuleGroupLoad) {
                    onMarkerUserModuleGroupLoad(moduleGroup as UserModuleGroup);
                }
                this.formatModuleList();
            })
        }).catch((error) => {
            // tslint:disable-next-line:no-console
            console.error(error);
            const errorItem: SimpleListItem = {
                id: 'error-item',
                label: 'Error',
                secondaryLabel: 'There was an exception getting the user module list for this marker',
                icon: <CancelOutlined className={ classes.errorItem }/>
            }
            this.setState({
                moduleList: [errorItem]
            });
        });
    }

    formatModuleList = () => {
        const { userModules } = this.props;
        const { existingModuleList } = this.state;

        if (!existingModuleList) {
            return;
        }

        const moduleList: SimpleListItem[] = [];

        existingModuleList.forEach(umodule => {
            const fullModule = userModules.find(e => e.user_module_id === umodule);

            if (!fullModule) {
                moduleList.push(new SimpleListItem(umodule, umodule));
            } else {
                moduleList.push(new SimpleListItem(umodule, fullModule.name, umodule));
            }
        });

        this.setState({moduleList});
    }

    onDeleteModuleClicked = (listItem: SimpleListItem) => {
        const { onModuleChange, registerAction, moduleKey } = this.props;
        const { existingModuleList } = this.state;

        if (!onModuleChange) {
            return;
        }
        
        const { moduleList } = this.state;

        const indextoDel = existingModuleList.indexOf(listItem.id);
        existingModuleList.splice(indextoDel, 1);

        onModuleChange(existingModuleList);
        
        const delIndex = moduleList.findIndex(e => e.id === listItem.id);

        if (registerAction) {
            registerAction(listItem.label!, 'DELETE', moduleKey);
        }

        moduleList.splice(delIndex, 1);
        this.setState({moduleList});
    }

    onAddModule = (module: UserModule) => {
        const { onModuleChange, registerAction, moduleKey } = this.props;
        const { existingModuleList } = this.state;

        if (!onModuleChange) {
            return;
        }
        
        const { moduleList } = this.state;
        existingModuleList.push(module.user_module_id)
        onModuleChange(existingModuleList);
        
        if (registerAction) {
            registerAction(module.name, 'ADD', moduleKey);
        }

        moduleList.push(new SimpleListItem(module.user_module_id, module.name, module.user_module_id));
        this.setState({moduleList});
    }

    onModuleSearch = (e: React.ChangeEvent<{}>, value: UserModule | null) => {
        if (!value) {
            return;
        }

        this.onAddModule(value);
    }

    getAvailableModuleToAddList = () => {
        const { userModules } = this.props;
        const { moduleList } = this.state;

        const modulesToAdd = userModules.filter(e => !moduleList.find(f => f.id === e.user_module_id));

        return modulesToAdd;
    }

    clearAutocompleteSelector = (evt: React.FocusEvent<HTMLDivElement> | undefined) => {
        this.setState({
            autoCompleteValue: null,
            autoCompleteKey: this.generateRandomKey()
        });
        return;
    }

    public render() {
        const {
            className,
            classes,
            canEdit,
            moduleKey
        } = this.props;
        const {
            moduleList,
            isListLoading,
            autoCompleteKey,
            autoCompleteValue
        } = this.state;

        return (
            <div className={`${classes.root} ${className}`} data-testid="user-module-list">
                <div className={`${classes.container}`}>
                    <SimpleList
                        className={classes.list}
                        items={moduleList}
                        noItemsLabel={"No Modules"}
                        canDeleteListItem={canEdit}
                        keepItemSelected={false}
                        onDeleteItemClick={this.onDeleteModuleClicked}
                        isLoading={isListLoading}
                    />
                    {
                        canEdit &&
                            <Autocomplete
                                id={`combo-box-user-module-${moduleKey}`}
                                key={autoCompleteKey}
                                className={classes.selectInput}
                                options={this.getAvailableModuleToAddList()}
                                getOptionLabel={(option) => option.name}
                                renderInput={(params) => <TextField {...params} label="Add a module" variant="standard" />}
                                onChange={(e, pickedValue) => this.onModuleSearch(e, pickedValue)}
                                data-testid="user-module-form-input"
                                value={autoCompleteValue}
                                clearOnBlur={true}
                                blurOnSelect={true}
                                onBlur={this.clearAutocompleteSelector}
                            />
                    }
                </div>
            </div>
        );
    }
}

const MUIComponent = withStyles(styles)(UserModuleForm);
export {MUIComponent as UserModuleForm}
