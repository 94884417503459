import { Typography } from '@material-ui/core';
import {
    createStyles,
    WithStyles,
    withStyles
} from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import memoize from 'memoize-one';
import * as React from 'react';
import {
    SimpleList,
    SimpleListItem
} from '../../shared/components/simple-list';
import { Team, User } from '../../shared/domain';
import { User as UserComponent } from '../user';

const RECORD_ERROR_MESSAGE = 'Error inside user record';
const NO_SEARCH_RESULT_MESSAGE = 'No Search Results';

const styles = (theme: Theme) => createStyles({
    [theme.breakpoints.down('lg')]: {
        noResultsContainer: {
            textAlign: 'center !important'
        },
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        flex: '1 0',
        '& > *': {
            marginRight: 'auto'
        }
    },
    childContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '1.5em'
    },
    header: {
        margin: '0.1em 0 0.5em'
    },
    list: {
        overflow: 'auto',
        minWidth: '30em'
    },
    userComponent: {
        padding: '0.86em'
    },
    noResultsContainer: {
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1,
        '& > *': {
            color: 'rgba(0,0,0,0.2)'
        }
    },
    userRecordError: {
        color: theme.palette.error.light
    }
});

interface Props extends WithStyles<typeof styles> {
    disabled: boolean;
    searchResultTerm?: string;
    users?: User[];
    teams?: Team[];
    currentTeamId?: string;
    onClick?:  (teamId: string) => void;
    onDelete?: (teamId: string) => void;
};

class EmailSearchResults extends React.Component<Props> {
    public static defaultProps = {
        disabled: false
    };

    getTeamItems = memoize((teams: Team[] | undefined) => {
        return teams ? teams.map((team: Team) => {
            return new SimpleListItem(team.team_id, team.name, team.team_id);
        }) : [];
    });

    onUsersTeamClicked =  (listItem: SimpleListItem) => {
        const { onClick } = this.props;
        if (onClick) {
            onClick(listItem.id);
        }
    }

    onDeleteItemClicked = (listItem: SimpleListItem) => {
        const { onDelete } = this.props;
        if (onDelete) {
            onDelete(listItem.id);
        }
    }

    public render() {
        const {
            classes,
            currentTeamId,
            disabled,
            searchResultTerm,
            teams,
            users,
        } = this.props;
        const items = this.getTeamItems(teams);

        const user = users ? users[0] : null;
        if (users && user && teams &&
            (users.length === 1) &&
            user.email &&
            (user.email.length > 0)
            ){
            const {
                email,
                userName,
                firstName,
                lastName,
                timestamp,
                isVerificationNeeded
            } = user;
            const fullName = (firstName && firstName.length > 0 &&
                              lastName && lastName.length > 0) ?
                                `${firstName} ${lastName}` : '';
            return (
                <div className={classes.container} data-testid="user-results">
                    <div className={classes.childContainer}>
                        <Typography
                            className={classes.header}
                            variant='h6'
                        >
                            User Data
                        </Typography>
                        <UserComponent
                            className={classes.userComponent}
                            email={email}
                            fullName={fullName}
                            userName={userName ? userName : ''}
                            userCreationDate={timestamp}
                            verified={!isVerificationNeeded}
                        />
                    </div>
                    <div className={classes.childContainer} data-testid="user-teams">
                        <Typography
                            className={classes.header}
                            variant='h6'
                        >
                            User's Team
                        </Typography>
                        <SimpleList
                            className={classes.list}
                            items={items}
                            selectedItemId={currentTeamId}
                            noItemsLabel="No team available"
                            disabled={disabled}
                            onListItemClick={this.onUsersTeamClicked}
                            canDeleteListItem={true}
                            deleteAlwaysEnabled={true}
                            onDeleteItemClick={this.onDeleteItemClicked}
                        />
                    </div>
                </div>
            );
        } else {
            const isRecordError = users && (users.length > 1);
            return (
                <div className={`${classes.container} ${classes.noResultsContainer}`}>
                    <Typography
                        className={isRecordError ? classes.userRecordError : ''}
                        variant={searchResultTerm ? 'h3' : 'h2'}
                    >
                    {
                        isRecordError ? RECORD_ERROR_MESSAGE
                        : searchResultTerm ? `No results for '${searchResultTerm}'` : NO_SEARCH_RESULT_MESSAGE
                    }
                    </Typography>
                </div>
            );
        }
    }
}

const MUIComponent = withStyles(styles)(EmailSearchResults);
export {MUIComponent as EmailSearchResults}

