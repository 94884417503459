import { Module } from '../models/module';
import { HttpConnectorAdapter } from './../adapters/http-connector-adapter';

export default class ModuleProvider {
    private endpointAdmin: string;

    constructor() {
        this.endpointAdmin = `${process.env.REACT_APP_ADMIN_TOOL_API}module`;
    }

    init = () => {
        return HttpConnectorAdapter;
    };

    fetchModule = (moduleId: string) => {
        return this.init()
        .getWithCredentials(`${this.endpointAdmin}/${moduleId}`)
        .then(response => {
            const statusCode = response.status;
            const module = response.data as Module;

            return { status: statusCode, module: module };
        });
    }

    createModule = (module: Module) => {
        const newModule = {...module} as any;
        delete newModule['_id'];
        delete newModule['group_ids'];
        delete newModule['module_id'];
        delete newModule['module_type'];
        delete newModule['timestamp'];
        delete newModule['last_modified'];
        const headers = { 'Content-Type': 'application/json' };
        return this.init().postWithCredentials(`${this.endpointAdmin}/`, JSON.stringify(newModule), headers).then(response => {
            const module = response.data as Module;
            const statusCode = response.status;

            return { status: statusCode, module };
        });
    }
}
