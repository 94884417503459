import { Button, Divider, Grid, IconButton, Paper, Typography } from '@material-ui/core';
import {
    createStyles,
    WithStyles,
    withStyles,
    Theme
} from '@material-ui/core/styles';
import { CloseOutlined } from '@material-ui/icons';
import _ from 'lodash';
import * as React from 'react';
import { PickedType } from '../../features/marker-export/marker-export';
import AccountProvider from '../../providers/account.provider';
import { NodesTreeView, RenderTree } from '../../shared/components/node-tree';
import { SimpleList, SimpleListItem } from '../../shared/components/simple-list';
import { SimpleModal } from '../../shared/components/simple-modal';
import { Account, Folder } from '../../shared/domain';
import { FolderActionIntent } from '../team-form/team-form';

const styles = (theme: Theme) => createStyles({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    treePaper: {
        width: '100%',
        height: '100%',
        minHeight: '40em',
        maxHeight: '50em',
        overflowY: 'scroll'
    },
    treeContainer: {
        background: '#fff',
        padding: '1em',
        height: '100%',
        minHeight: '40em',
        maxHeight: '50em',
    },
    buttonModal: {
        margin: '1em',
    },
    list: {
        flex: '1 0',
        overflow: 'auto',
        boxShadow: 'none'
    },
    resumePopup: {
        width: '70%',
        borderRadius: 5
    },
    resumePopupHeader: {
        height: '2em',
        backgroundColor: theme.palette.primary.main,
        borderRadius: '3px 3px 0 0',
        color: 'white',
        padding: '0.5em',
        fontSize: '1.5em'
    },
    popupContentContainer :{
        display: 'flex',
        flexDirection: 'row',
        padding: theme.spacing(1),
    },
    heading: {
        fontWeight: 700,
        marginBottom: '0.5em',
        marginTop: '1em'
    },
    ellipsis: {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden'
    },
    listModal: {
        padding: '0 1em',
        position: 'relative'
    },
    addButton: {
        width: '100%',
        margin: '0'
    },
    finishButton: {
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: '2%',
        width: '95%',
        margin: 'auto',
    },
    closeBtn: {
        position: 'absolute',
        zIndex: 999999,
        right: 0,
        top: 0,
        color: 'white',
    },
    errorHeading: {
        fontWeight: 500,
        marginBottom: '0.5em',
        marginTop: '0em',
        color: theme.palette.error.main,
        fontSize: 'small',
        textAlign: 'center',
        whiteSpace: 'break-spaces'
    },
});

interface Props extends WithStyles<typeof styles> {
    currentAccount: Account;
    accountFolders: Folder[];
    folderIds: string[];
    folderKey: string;
    excludeFolderIds?: string[];
    onFolderchange?: (folderKey: string, folderIdsList: string[], intent: FolderActionIntent) => string[];
}

interface State {
    isModalOpen: boolean,
    currentNodeTree: RenderTree;
    treeIsLoading: boolean;
    pickedType: PickedType;
    pickedId: string;
    pickedName: string;
    folders: Folder[];
    processedFolders: string[];
    addList: SimpleListItem[];
    teamList: SimpleListItem[];
    isFolderLoading: boolean;
    invalidFolderMessage: string;
    treeScrollPosition: number;
}

class FolderTreePicker extends React.Component<Props, State> {

    state: State = {
        isModalOpen: false,
        currentNodeTree: {id: '', name:'', children:[], path: ''},
        treeIsLoading: false,
        pickedType: PickedType.None,
        pickedId: '',
        pickedName: '',
        folders: [],
        processedFolders: [],
        addList: [],
        teamList: [],
        isFolderLoading: false,
        invalidFolderMessage: '',
        treeScrollPosition: 0
    };

    accountProvider = new AccountProvider();

    componentDidMount() {
        this.fetchFoldersFromAccount();
        this.formatTeamList();
    };

    formatTeamList = () => {
        const { folderIds, accountFolders } = this.props;
        const teamList = folderIds.map(e => { return new SimpleListItem(e, accountFolders.find(j => j.id === e)?.name, e) });
        this.setState({ teamList });
    };

    fetchFoldersFromAccount = () => {
        const { currentAccount } = this.props;
        this.setState({
            pickedType: PickedType.None,
            pickedId: '',
            processedFolders: []
        });
        this.setState({isFolderLoading: true});
        return this.accountProvider.getRootFoldersByAccountId(currentAccount.accountId)
            .then((results) => {
                const folders: Folder[] = results;
                if(!folders) {
                    return;
                }

                // TRANSFORM THE FOLDERS INTO TREE VIEW
                const accountTree: RenderTree = {
                    id: currentAccount.accountId,
                    name: currentAccount.name,
                    children: [],
                    path: ''
                };

                const treeNodes: RenderTree[] = folders.map(element => {
                    const newItem = {
                        id: element.id,
                        name: element.name,
                        children: [],
                        path: `${element.id}__`
                    }
                    return newItem as RenderTree;
                });

                accountTree.children = treeNodes;

                this.setState({
                    folders,
                    currentNodeTree: accountTree
                });

        }).catch((error) => {
            // tslint:disable-next-line:no-console
            console.error(error);
        }).finally(() => {
            this.setState({isFolderLoading: false});
        });

    };

    deepTreeSearch = (treeData: RenderTree, searchId: string) => {
        let foundTree: RenderTree | undefined;
        let searchLevel: RenderTree[] | undefined = treeData.children;
        do {
            foundTree = searchLevel?.find((e: RenderTree) => e.id === searchId);
            let tempLevel: any = [];
            searchLevel?.forEach((element: RenderTree) => {
                tempLevel = tempLevel.concat(element.children);
            });
            searchLevel = tempLevel;
        } while(!foundTree);
        return foundTree;
    };

    updateChildrenOnPath = (treeData: RenderTree, nodeId: string, children: RenderTree[]) => {
        treeData.children!.forEach(element => {
            if (_.isEqual(element.id, nodeId)) {
                element.children = children;
            } else {
                this.updateChildrenOnPath(element, nodeId, children);
            }
        });
    };

    onNodeSelected = (treeData: RenderTree , nodeId: string, nodeLabel: string, updateCallback: (nodeIds: string[]) => void) => {
        const { folders, processedFolders } = this.state;
        const { currentAccount } = this.props;
        if(currentAccount!.accountId === nodeId) {
            this.setState({
                pickedType: PickedType.None,
                pickedId: '',
                pickedName: ''
            });
            return;
        }
        this.setState({treeIsLoading: true});
        // CHECK PICK TYPE
        // FOLDER
        const currentNodePos = this.deepTreeSearch(treeData, nodeId);
        if(folders.find(e => e.id === nodeId)) {
            // DID WE ALREADY CHECK FOR CHILDREN?
            if (processedFolders.find(e => e === nodeId)) {
                // FOLDER CHILDREN
                this.setState({
                    pickedType: PickedType.Folder,
                    pickedId: nodeId,
                    pickedName: currentNodePos.name,
                    treeIsLoading: false,
                    currentNodeTree: treeData,
                });
                return;
            }
            // EMPTY
            // CHECK FOR FOLDER CHILDREN FIRST
            return this.accountProvider.getChildrenFolderByFolderId(currentAccount!.accountId, nodeId)
                .then((results: Folder[]) => {
                    if (results.length > 0) {
                        const nodes: RenderTree[] = results.map(element => {
                            const newItem = {
                                id: element.id,
                                name: element.name,
                                children: [],
                                path: `${currentNodePos.path}${element.id}__`
                            }
                            return newItem as RenderTree;
                        });
                        this.updateChildrenOnPath(treeData, nodeId, nodes);
                        const currentPath = currentNodePos.path.slice(0, -2);
                        updateCallback([currentAccount!.accountId, ...currentPath.split('__'), ...nodes.map(e => e.id)]);
                        processedFolders.push(nodeId);
                        this.setState({
                            pickedType: PickedType.Folder,
                            pickedId: nodeId,
                            pickedName: currentNodePos.name,
                            treeIsLoading: false,
                            currentNodeTree: treeData,
                            folders: folders.concat(results),
                            processedFolders
                        });
                        return;
                    } else {
                        this.setState({
                            pickedType: PickedType.Folder,
                            pickedId: nodeId,
                            pickedName: currentNodePos.name,
                            treeIsLoading: false,
                            currentNodeTree: treeData,
                            processedFolders
                        });
                    }
            }).catch((error) => {
                // tslint:disable-next-line:no-console
                console.error(error);
            });
        }
        this.setState({
            pickedType: PickedType.FloorPlan,
            pickedId: nodeId,
            pickedName: currentNodePos.name,
            treeIsLoading: false
        });
        return;
    };

    onNodeToggled = (nodeIds: string[]) => {
        return;
    };

    onModalToggle = () => {
        const { isModalOpen } = this.state;

        this.setState({
            isModalOpen: !isModalOpen,
            pickedId: '',
            invalidFolderMessage: ''
        });
    }

    onDeleteFolderClicked = (listItem: SimpleListItem) => {
        const { onFolderchange, folderIds, folderKey } = this.props;
        const { teamList } = this.state; 

        if(!onFolderchange) {
            return;
        }

        const delIndex = folderIds.findIndex(e => e === listItem.id);
        const delInternalIndex = teamList.findIndex(e => e.id === listItem.id);
        folderIds.splice(delIndex, 1);
        teamList.splice(delInternalIndex, 1);
        
        this.setState({teamList});
        onFolderchange(folderKey, [listItem.id], 'DELETE');
    }

    onDeleteAddFolderClicked = (listItem: SimpleListItem) => {
        const { addList } = this.state;
        const delIndex = addList.findIndex(e => e.id === listItem.id);
        addList.splice(delIndex, 1);
        this.setState({addList});
    };

    addFolderList = () => {
        const { pickedId, pickedType, pickedName, addList } = this.state;

        if (!pickedId || !pickedType || !pickedName || !addList) {
            return;
        }

        if (addList.find(e => e.id === pickedId)) {
            this.setState({
                pickedId: '',
                pickedName: '',
                pickedType: PickedType.None
            });
            return;
        }

        addList.push(new SimpleListItem(pickedId, pickedName, pickedId));

        this.setState({
            addList,
            pickedId: '',
            pickedName: '',
            pickedType: PickedType.None
        });
    };

    onFinishFolderAdd = () => {
        const {
            folderKey,
            folderIds,
            onFolderchange,
            accountFolders,
            excludeFolderIds
        } = this.props;

        if (!onFolderchange) {
            return;
        }

        const { addList, teamList } = this.state;

        const existingExclude = excludeFolderIds ? excludeFolderIds : [];
        const readyList = addList.map(e => {
            if (folderIds.includes(e.id) || existingExclude.includes(e.id) || teamList.find(j => j.id === e.id)) {
                return '';
            }
            return e.id;
        }).filter(e => e !== '');

        if (readyList.length <= 0) {
            return;
        }

        const removeFolderIds = onFolderchange(folderKey, folderIds.concat(readyList), 'ADD');

        let removedFolderWarning = ''
        readyList.forEach(e => {
            if (!removeFolderIds.includes(e)){
                teamList.push(new SimpleListItem(
                    e, accountFolders.find(j => j.id === e)?.name, e));
            } else {
                removedFolderWarning += `Cannot add ${accountFolders.find(j => j.id === e)?.name} because is already set up on other fields` + '\n';
            }
        });
        
        this.setState({addList: [], teamList, isModalOpen: false, invalidFolderMessage: removedFolderWarning});
    };

    centerScroll = (evt: React.UIEvent<HTMLElement>) => {
        this.setState({
            treeScrollPosition: evt.currentTarget.scrollTop
        })
    };

    public render() {
        const {
            classes,
            folderIds,
            excludeFolderIds
        } = this.props;
        const {
            isModalOpen,
            currentNodeTree,
            treeIsLoading,
            addList,
            pickedId,
            pickedType,
            teamList,
            isFolderLoading,
            invalidFolderMessage,
            treeScrollPosition
        } = this.state;

        const existingExclude = excludeFolderIds ? excludeFolderIds : [];

        return (
            <div className={`${classes.root}`} data-testid="folder-tree-picker">
                <div className={`${classes.container}`}>
                    {
                        invalidFolderMessage !== '' &&
                        <Typography
                            variant='caption'
                            className={classes.errorHeading}
                        >
                            {invalidFolderMessage}
                        </Typography>
                    }
                    <SimpleList
                        className={classes.list}
                        items={teamList}
                        noItemsLabel={"No folders found"}
                        canDeleteListItem={true}
                        keepItemSelected={false}
                        onDeleteItemClick={this.onDeleteFolderClicked}
                        isLoading={isFolderLoading}
                    />
                    { !isFolderLoading && 
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.buttonModal}
                            onClick={this.onModalToggle}
                        >
                            Add Folders
                        </Button>
                    }
                    <SimpleModal
                        className={classes.resumePopup}
                        open={isModalOpen}
                        contentClasses={classes.popupContentContainer}
                        buttonOkLabel=""
                        buttonCancelLabel=""
                        header='Folder selection'
                        headerClassName={classes.resumePopupHeader}
                    >
                        <IconButton className={classes.closeBtn} onClick={this.onModalToggle}>
                            <CloseOutlined />
                        </IconButton>
                        <Grid container spacing={1} alignItems="flex-start">
                            <Grid item xs={8}>
                                <Paper 
                                    className={classes.treePaper}
                                    onScrollCapture={this.centerScroll}
                                >
                                    <NodesTreeView
                                        treeData={currentNodeTree}
                                        isLoading={treeIsLoading}
                                        onNodeSelected={this.onNodeSelected}
                                        onNodeToggled={this.onNodeToggled}
                                        className={classes.treeContainer}
                                        loadingOverlayPosition={treeScrollPosition}
                                    />
                                </Paper>
                            </Grid>
                            <Grid item xs={4}>
                                <Paper className={`${classes.treePaper} ${classes.listModal}`}>
                                    <Typography
                                        variant='h6'
                                        className={`${classes.heading} ${classes.ellipsis}`}
                                        >
                                        Folders to add
                                    </Typography>
                                    <SimpleList
                                        className={classes.list}
                                        items={addList}
                                        noItemsLabel={"No folders selected"}
                                        canDeleteListItem={true}
                                        keepItemSelected={false}
                                        onDeleteItemClick={this.onDeleteAddFolderClicked}
                                    />
                                    { pickedId &&
                                        pickedType === PickedType.Folder &&
                                        !folderIds.includes(pickedId) &&
                                        !existingExclude.includes(pickedId) &&
                                        !teamList.find(e => e.id === pickedId) && (
                                        <div>
                                            <Divider />
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                className={classes.addButton}
                                                onClick={this.addFolderList}
                                            >
                                                Add Folder to list
                                            </Button>
                                        </div>
                                    )}
                                    {
                                        addList.length > 0 &&
                                        <div>
                                            <Divider />
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                className={classes.finishButton}
                                                onClick={this.onFinishFolderAdd}
                                            >
                                                Add folders to team
                                            </Button>
                                        </div>
                                    }
                                </Paper>
                            </Grid>
                        </Grid>
                    </SimpleModal>
                </div>
            </div>
        );
    }
}

const MUIComponent = withStyles(styles)(FolderTreePicker);
export {MUIComponent as FolderTreePicker}
