import { Typography } from '@material-ui/core';
import {
    createStyles,
    Theme,
    WithStyles,
    withStyles
} from '@material-ui/core/styles';
import {
    Error,
    FiberManualRecord
} from '@material-ui/icons';
import {
    inject,
    observer
} from 'mobx-react';
import * as React from 'react';
import { AccountTeamInfo } from '../../components';
import AccountProvider from '../../providers/account.provider';
import { CCSpinner } from '../../shared/components/cc-spinner';
import { PageBoundary } from '../../shared/components/simple-grid-pagination';
import { SimpleListItem } from '../../shared/components/simple-list';
import { SimpleModal } from '../../shared/components/simple-modal';
import {
    Account,
    Folder,
    Team
} from '../../shared/domain';
import { RootStore } from '../../stores/root.store';

const ROWS_PER_PAGE = 10;
const TEAM_INFO_PAGING_LIMIT = 100;
const TEAMS_PAGING_LIMIT = 100;
const ACCOUNTS_PAGING_LIMIT = 20;

const styles = (theme: Theme) => createStyles({
    container: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column'
    },
    analyticsTabList: {
        height: '18.2em'
    },
    errorPopup: {
        width: '98%',
        borderRadius: 5
    },
    errorPopupHeader: {
        height: '0.6em',
        backgroundColor: theme.palette.error.main,
        borderRadius: '3px 3px 0 0'
    },
    errorPopupContentContainer :{
        display: 'flex',
        flexDirection: 'row',
        padding: theme.spacing(1),
    },
    errorPopupIcon: {
        fontSize: 40,
        marginRight: '1em'
    },
    mainSpinnerLabel: {
        paddingBottom: '30px'
    },
    progressContainer: {
        flexGrow: 1
    },
    animContainer: {
        transition: 'width 1s'
    },
    listIcon: {
        marginRight: '0.5em',
        fontSize: '1em'
    }
});

interface Props extends WithStyles<typeof styles> {
    rootStore: RootStore;
    onAuthError?: () => void;
};

interface States {
    accounts: Account[];
    currentAccount: Account | null;
    currentAccountOffset: number;
    networkError: boolean;
    dataPageIsLoading: boolean;
    isAccountsLoading: boolean;
    totalAccounts: number;
    mainSpinnerText: string;
    teams: Team[] | null;
    totalTeams: number;
    isTeamsLoading: boolean;
    currentTeam: Team | null;
    currentTeamOffset: number;
    teamInfoItems: SimpleListItem[];
    isTeamInfoLoading: boolean;
    currentTeamInfoOffset: number;
    accountFolders: Folder[];
}

@inject('rootStore')
@observer
class TeamInfo extends React.Component<Props, States> {
    public static defaultProps: Partial<Props> = {
    };

    state: States = {
        accounts: [],
        currentAccount: null,
        currentAccountOffset: 0,
        networkError: false,
        dataPageIsLoading: true,
        isAccountsLoading: false,
        totalAccounts: 0,
        mainSpinnerText: 'Please wait. Loading data.',
        teams: [],
        totalTeams: 0,
        isTeamsLoading: false,
        currentTeam: null,
        currentTeamOffset: 0,
        teamInfoItems: [],
        isTeamInfoLoading: false,
        currentTeamInfoOffset: 0,
        accountFolders: []
    };

    accountProvider = new AccountProvider();

    componentDidMount() {
        this.fetchAccounts(this.state.currentAccountOffset);
    };

    fetchAccounts = (offset:number, limit: number = ACCOUNTS_PAGING_LIMIT) => {
        return this.accountProvider.getAllAccounts(offset, limit)
          .then((results) => {
            const {accounts, totalCount} = results;
            if (!accounts) {
                if (offset === 0) {
                    this.setState({networkError: true});
                }
                return;
            }

            this.setState({
                dataPageIsLoading: false,
                isAccountsLoading: false,
                totalAccounts: totalCount,
                accounts,
                currentAccountOffset: offset
            });
        }).catch((error) => {
            const { status } = error.response;
            if (status === 401) {
                const { onAuthError } = this.props;
                if (!onAuthError) {
                    return;
                }
                onAuthError();
                return;
            }
            this.setState({networkError: true});
        });
    }

    onAccountDataPageOverBoundaryReached = (boundary: PageBoundary, nextPage: number): Promise<void> => {
        return new Promise<void>((resolve, reject) => {
            const { 
                currentAccountOffset,
                totalAccounts
            } = this.state;
            const isFirstPage = nextPage === 0;
            const isLastPage = nextPage === (Math.ceil(totalAccounts / ROWS_PER_PAGE)-1);
            const newOffset = isFirstPage ? 0
                            : isLastPage ? ACCOUNTS_PAGING_LIMIT * Math.floor(totalAccounts / ACCOUNTS_PAGING_LIMIT)
                            : (boundary === PageBoundary.Upper) ? 
                                currentAccountOffset+ACCOUNTS_PAGING_LIMIT :
                                currentAccountOffset-ACCOUNTS_PAGING_LIMIT;
            this.setState({isAccountsLoading: true});
            this.fetchAccounts(newOffset)
              .then(() => resolve())
              .catch(()=> reject());
        });
    };

    onAccountClicked = (account: Account) => {
        const { currentAccount } = this.state;
        if (currentAccount === account) {
            return;
        }

        this.setState({
            currentAccount: account,
            teamInfoItems: [],
            currentTeam: null
        });
        this.fetchTeamsFromAccount(account, this.state.currentTeamOffset);
        this.fetchFoldersFromAccount(account);
    };

    fetchTeamsFromAccount = (account: Account, offset:number, limit: number = TEAMS_PAGING_LIMIT) => {
        this.setState({isTeamsLoading: true});

        return this.accountProvider.fetchTeamsFromAccountId(account.accountId, offset, limit)
          .then((results) => {
            const { teams, totalCount } = results;
            if (!teams) {
                if (offset === 0) {
                    this.setState({networkError: true});
                }
                return;
            }

            this.setState({
                currentTeamOffset: offset,
                currentAccount: account,
                isTeamsLoading: false,
                teams,
                totalTeams: totalCount
            });
        }).catch((error) => {
            const { status } = error.response;
            if (status === 401) {
                const { onAuthError } = this.props;
                if (!onAuthError) {
                    return;
                }
                onAuthError();
                return;
            }
            this.setState({networkError: true});
        });
    };

    fetchFoldersFromAccount = (account: Account) => {
        this.setState({isTeamsLoading: true});
        return this.accountProvider.getFoldersByAccountId(account.accountId)
            .then((results) => {
                const folders: Folder[] = results;
                this.setState({
                    isTeamsLoading: false,
                    accountFolders: folders
                });
        }).catch((error) => {
            // tslint:disable-next-line:no-console
            console.error(error);
            const { status } = error.response;
            if (status === 401) {
                const { onAuthError } = this.props;
                if (!onAuthError) {
                    return;
                }
                onAuthError();
                return;
            }
            this.setState({networkError: true});
        });
    }

    onTeamDataPageOverBoundaryReached = (boundary: PageBoundary, nextPage: number): Promise<void> => {
        return new Promise<void>((resolve, reject) => {
            const {
                currentAccount,
                currentTeamOffset,
                totalTeams
            } = this.state;
            
            if (!currentAccount) {
                return;
            
            }
            const isFirstPage = nextPage === 0;
            const isLastPage = nextPage === (Math.ceil(totalTeams / ROWS_PER_PAGE)-1);
            const newOffset = isFirstPage ? 0
                            : isLastPage ? TEAMS_PAGING_LIMIT * Math.floor(totalTeams / TEAMS_PAGING_LIMIT)
                            : (boundary === PageBoundary.Upper) ? 
                                currentTeamOffset+TEAMS_PAGING_LIMIT :
                                currentTeamOffset-TEAMS_PAGING_LIMIT;
            this.setState({isTeamsLoading: true});
            this.fetchTeamsFromAccount(currentAccount, newOffset)
               .then(()=> resolve())
               .catch(() => reject());
        });
    };

    onTeamClicked = (team: Team) => {
        const { currentTeam } = this.state;
        if (currentTeam === team) {
            return;
        }
        this.setState({currentTeam: team});
        this.fetchTeamInfo(team);
    }

    fetchTeamInfo = (team: Team) => {
        // TODO translate necesary fields
        this.setState({isTeamInfoLoading: true});
        const { accountFolders } = this.state;
        const { classes } = this.props;
        const infoItems: SimpleListItem[] = [];
        let idHelper = 0;
        infoItems.push({
            id: `${team.team_id}_${idHelper}`,
            label: 'Team Id',
            secondaryLabel: team.team_id
        });
        idHelper++;
        infoItems.push({
            id: `${team.team_id}_${idHelper}`,
            label: 'Access Code',
            secondaryLabel: team.access_code
        });
        idHelper++;
        if(team.folders) {
            infoItems.push({
                id: `${team.team_id}_${idHelper}`,
                label: 'Folders'
            });
            idHelper++;
            team.folders.forEach(element => {
                infoItems.push({
                    id: `${team.team_id}_${idHelper}`,
                    label: accountFolders.find(e => e.id === element) ? accountFolders.find(e => e.id === element)!.name : undefined,
                    secondaryLabel: element,
                    icon: <FiberManualRecord className={classes.listIcon}/>
                });
                idHelper++;
            });
        }
        infoItems.push({
            id: `${team.team_id}_${idHelper}`,
            label: 'Total members',
            secondaryLabel: team.members ? Object.keys(team.members).length as unknown as string: '0'
        });
        idHelper++;
        if(team.module_groups){
            if(team.module_groups.read) {
                infoItems.push({
                    id: `${team.team_id}_${idHelper}`,
                    label: 'Read Module Groups'
                });
                idHelper++;
                const readKeys = team.module_groups.read as {};
                for (const key in readKeys) {
                    if(readKeys.hasOwnProperty(key)){
                        infoItems.push({
                            id: `${team.team_id}_${idHelper}`,
                            secondaryLabel: key,
                            icon: <FiberManualRecord className={classes.listIcon}/>
                        });
                        idHelper++;
                    }
                }
            }
            if(team.module_groups.write) {
                infoItems.push({
                    id: `${team.team_id}_${idHelper}`,
                    label: 'Write Module Groups'
                });
                idHelper++;
                const writeKeys = team.module_groups.write as {};
                for (const key in writeKeys) {
                    if(writeKeys.hasOwnProperty(key)){
                        infoItems.push({
                            id: `${team.team_id}_${idHelper}`,
                            secondaryLabel: key,
                            icon: <FiberManualRecord className={classes.listIcon}/>
                        });
                        idHelper++;
                    }
                }
            }
        }
        this.setState({
            isTeamInfoLoading: false,
            teamInfoItems: infoItems
        });
    };
    
    onTeamInfoDataPageOverBoundaryReached = (boundary: PageBoundary, nextPage: number): Promise<void> => {
        return new Promise<void>((resolve, reject) => {
            const {
                currentTeamInfoOffset,
                teamInfoItems
            } = this.state;
            const totalTasks = teamInfoItems.length;
            const isFirstPage = nextPage === 0;
            const isLastPage = nextPage === (Math.ceil(totalTasks / ROWS_PER_PAGE)-1);
            const newOffset = isFirstPage ? 0
                            : isLastPage ? TEAM_INFO_PAGING_LIMIT * Math.floor(totalTasks / TEAM_INFO_PAGING_LIMIT)
                            : (boundary === PageBoundary.Upper) ? 
                            currentTeamInfoOffset+TEAM_INFO_PAGING_LIMIT :
                            currentTeamInfoOffset-TEAM_INFO_PAGING_LIMIT;
            const nextItems = teamInfoItems;
            // MANUAL OFFSET
            this.setState({
                teamInfoItems: nextItems.splice(newOffset, nextItems.length)
            });
        });
    }

    public render() {
        const { classes } = this.props;
        const {
            accounts,
            currentAccount,
            currentAccountOffset,
            networkError,
            dataPageIsLoading,
            mainSpinnerText,
            isAccountsLoading,
            totalAccounts,
            teams,
            totalTeams,
            isTeamsLoading,
            currentTeam,
            currentTeamOffset,
            teamInfoItems,
            isTeamInfoLoading,
            currentTeamInfoOffset
        } = this.state;

        const currentAccountName = currentAccount ? currentAccount.name : '';

        return (
            <div className={classes.container} data-testid="mainRender">
                <CCSpinner
                    label={mainSpinnerText}
                    labelClassName={classes.mainSpinnerLabel}
                    className={classes.progressContainer}
                    loading={dataPageIsLoading}
                    size={200}
                >
                    <AccountTeamInfo 
                        className={`${classes.animContainer}`}
                        rowsPerPage={ROWS_PER_PAGE}
                        accounts={accounts}
                        accountsIsLoading={isAccountsLoading}
                        accountsTotalItems={totalAccounts}
                        accountsItemsOffset={currentAccountOffset}
                        currentAccountName={currentAccountName}
                        onAccountClick={this.onAccountClicked}
                        onAccountDataPageOverBoundary={
                            this.onAccountDataPageOverBoundaryReached
                        }
                        teams={teams}
                        totalTeams={totalTeams}
                        isTeamsLoading={isTeamsLoading}
                        currentTeam={currentTeam}
                        currentTeamOffset={currentTeamOffset}
                        onTeamClick={this.onTeamClicked}
                        onTeamDataPageOverBoundary={this.onTeamDataPageOverBoundaryReached}
                        teamItems={teamInfoItems}
                        isTeamInfoLoading={isTeamInfoLoading}
                        teamInfoOffset={currentTeamInfoOffset}
                        onTeamInfoDataPageOverBoundary={this.onTeamInfoDataPageOverBoundaryReached}
                    />
                </CCSpinner>
                {
                    networkError &&
                        <SimpleModal
                            className={classes.errorPopup}
                            open={networkError}
                            contentClasses= {classes.errorPopupContentContainer}
                            buttonOkLabel=""
                            buttonCancelLabel=""
                            header = ' '
                            headerClassName={classes.errorPopupHeader}
                        >
                            <Error color="error" className={classes.errorPopupIcon} />
                            <div>
                                <Typography variant="h5">
                                    {'Network Error'}
                                </Typography>
                                <Typography variant="subtitle1">
                                    {'Please reload the page.'}
                                </Typography>
                            </div>
                        </SimpleModal>
                }
            </div>
        );
    }
}

const MUIComponent = withStyles(styles)(TeamInfo);
export {MUIComponent as TeamInfo}