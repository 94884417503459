import { Button, Checkbox, FormControlLabel, IconButton, Paper, TextField, Typography } from '@material-ui/core';
import {
    createStyles,
    WithStyles,
    withStyles
} from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { CCSpinner } from '../../shared/components/cc-spinner';
import { NodesTreeView, RenderTree } from '../../shared/components/node-tree';
import { PageBoundary } from '../../shared/components/simple-grid-pagination';
import { SimpleListItem } from '../../shared/components/simple-list';
import { SimpleListPagination } from '../../shared/components/simple-list-pagination';
import { Account, Team } from '../../shared/domain';
import { ClearOutlined, SearchOutlined } from '@material-ui/icons';

const styles = (theme: Theme) => createStyles({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    container: {
        display: 'flex',
        flexDirection: 'column'
    },
    containerVertical: {
        width: '50%'
    },
    heading: {
        fontWeight: 700,
        marginBottom: '0.5em',
        marginTop: '1em'
    },
    ellipsis: {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden'
    },
    simpleList: {
        overflow: 'auto',
        height: '100%',
        minWidth: '20em'
    },
    simpleListAccounts: {
        marginRight: '1.5em',
        width:'31%',
        transition: 'width 1s',
        '&:last-child': {
            marginRight: 0
        }
    },
    expandedListAccounts: {
        marginRight: '1.5em',
        width:'62%',
        transition: 'width 1s',
        '&:last-child': {
            marginRight: 0
        }
    },
    noAccountContainer: {
        fontWeight: 'bold',
         '& > *': {
            color: 'rgba(0,0,0,0.2)'
        }
    },
    fullBtn: {
        width: '100%'
    },
    teamSpinnerContainer: {
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '3em',
        marginBottom: '4em',
        borderRadius: '0.4em',
        minHeight: '10em'
    },
    firstPaper: {
        width: '57%'
    },
    formContainer: {
        padding: '0.5em'
    },
    checkContainer: {
        marginLeft: '0.5em'
    },
    treePaper: {
        height: '80%',
        maxHeight: '60em',
        overflowY: 'scroll'
    },
    treeContainer: {
        background: '#fff',
        padding: '1em',
        height: '100%'
    },
    folderBtn: {
        width: '100%',
        marginBottom: '1em'
    },
    searchInputContainer: {
        marginBottom: '1em'
    },
    searchInput: {
        width: '100%',
        paddingLeft: '1em'
    },
});

interface Props extends WithStyles<typeof styles> {
    className?: string;
    accounts?: Account[] | null;
    accountsIsLoading: boolean;
    currentAccountName?: string;
    folderIsLoading: boolean;
    accountsTotalItems?: number;
    accountsItemsOffset: number;
    accountsRowsPerPage: number;
    teamsRowsPerPage: number;
    onAccountClick?: (account: Account) => void;
    onAccountDataPageOverBoundary?: (boundary: PageBoundary, nextPage: number) => Promise<void>;
    managerTeams: Team[];
    employeeTeams: Team[];
    totalManagerTeams: number;
    totalEmployeeTeams: number;
    isTeamsLoading: boolean;
    currentManagerTeamOffset: number;
    currentEmployeeTeamOffset: number;
    onTeamClick?: (team: Team, teamType: string) => void;
    onManagerTeamDataPageOverBoundary?: (boundary: PageBoundary, nextPage: number) => Promise<void>;
    onEmployeeTeamDataPageOverBoundary?: (boundary: PageBoundary, nextPage: number) => Promise<void>;
    exportTeam: Team | null;
    exportTeamType: string;
    exportFolder: boolean;
    onTeamExportClick?: () => void;
    onFolderExportEnable?: (enabled: boolean) => void;
    currentNodeTree: RenderTree;
    treeIsLoading: boolean;
    pickedFolder: string | null;
    runningTasks: number;
    onNodeSelected?: (treeData: RenderTree , nodeId: string, nodeLabel: string, updateCallback: (nodeIds: string[]) => void) => void;
    onNodeToggled?: (nodeIds: string[]) => void;
    onAccountSearch?: (searchName: string) => void;
};

interface States {
    accountNameSearch: string;
    treeScrollPosition: number;
};

@inject('rootStore')
@observer
class AccountUserExport extends React.Component<Props, States> {
    public static defaultProps: Partial<Props> = {
    };

    state = {
        accountNameSearch: '',
        treeScrollPosition: 0
    };

    onAccountClicked = (listItem: SimpleListItem) => {
        const {accounts, onAccountClick} = this.props;
        if(!accounts) {
            return;
        }

        const currentAccount = accounts.find((account:Account) => {
            return account.accountId === listItem.id;
        });
        if (!currentAccount) {
            return;
        }

        if (onAccountClick) {
            onAccountClick(currentAccount);
        }
    };

    onTeamClicked = (listItem: SimpleListItem) => {
        const {managerTeams, employeeTeams, onTeamClick} = this.props;
        if(!managerTeams && !employeeTeams) {
            return;
        }

        let currentTeam = managerTeams.find((team: Team) => {
            return team.team_id === listItem.id;
        });
        let teamType = 'manager';
        if (!currentTeam) {
            currentTeam = employeeTeams.find((team: Team) => {
                return team.team_id === listItem.id;
            });
            if (!currentTeam) {
                return;
            }
            teamType = 'employee';
        }

        if (onTeamClick) {
            onTeamClick(currentTeam, teamType);
        }
    };

    onExportFolderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { onFolderExportEnable } = this.props;
        if(onFolderExportEnable) {
            onFolderExportEnable(event.target.checked);
        }
    };

    onAccountNameSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        const searchValue = event.target.value;

        this.setState({
            accountNameSearch: searchValue
        });
    };

    onAccountNameKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        const { accountNameSearch } = this.state;
        const { onAccountSearch } = this.props;

        if (!onAccountSearch) {
            return;
        }

        if (event.key === 'Delete' || event.key === 'Backspace') {
            if (accountNameSearch === '') {
                onAccountSearch('');
                return;
            }
        }

        if (event.key === 'Enter') {
            onAccountSearch(accountNameSearch);
            return;
        }
    };

    onButtonSearch = () => {
        const { accountNameSearch } = this.state;
        const { onAccountSearch } = this.props;

        if (!onAccountSearch) {
            return;
        }

        onAccountSearch(accountNameSearch);
    };

    onCancelSearch = () => {
        const { onAccountSearch } = this.props;

        if (!onAccountSearch) {
            return;
        }

        this.setState({ accountNameSearch: '' }, () => {
            onAccountSearch('');
        });
    };

    centerScroll = (evt: React.UIEvent<HTMLElement>) => {
        this.setState({
            treeScrollPosition: evt.currentTarget.scrollTop
        })
    };

    public render() {
        const {
            className,
            classes,
            accounts,
            currentAccountName,
            accountsIsLoading,
            accountsTotalItems,
            accountsItemsOffset,
            accountsRowsPerPage,
            teamsRowsPerPage,
            onAccountDataPageOverBoundary,
            managerTeams,
            employeeTeams,
            totalManagerTeams,
            totalEmployeeTeams,
            isTeamsLoading,
            currentManagerTeamOffset,
            currentEmployeeTeamOffset,
            onManagerTeamDataPageOverBoundary,
            onEmployeeTeamDataPageOverBoundary,
            exportTeam,
            exportTeamType,
            onTeamExportClick,
            exportFolder,
            currentNodeTree,
            treeIsLoading,
            onNodeSelected,
            onNodeToggled,
            pickedFolder,
            runningTasks
        } = this.props;

        const {
            accountNameSearch,
            treeScrollPosition
        } = this.state;

        const accountListItems = accounts ? accounts.map((account: Account) => {
            const accountId = account.accountId;
            return new SimpleListItem(accountId, account.name, accountId);
        }) : [];
        const managerTeamListItems = managerTeams ? managerTeams.map(((team, index) => {
            const team_id = team.team_id;
            return new SimpleListItem(team_id, team.name, team_id);
        })) : [];
        const employeeTeamListItems = employeeTeams ? employeeTeams.map(((team, index) => {
            const team_id = team.team_id;
            return new SimpleListItem(team_id, team.name, team_id);
        })) : [];

        return (
            <div className={`${classes.root} ${className}`} data-testid="accountPicker">
                <div className={`${classes.container} ${classes.simpleListAccounts}`}>
                    <Typography
                        variant='h6'
                        className={`${classes.heading} ${classes.ellipsis}`}
                    >
                        Account Organizations
                    </Typography>
                    <Paper className={classes.searchInputContainer}>
                        <TextField
                            data-testid="account-searchbar"
                            className={classes.searchInput}
                            type="text"
                            placeholder="Search by name"
                            variant="standard"
                            multiline={false}
                            value={accountNameSearch}
                            InputProps={{
                                disableUnderline: true,
                                endAdornment: (
                                    <React.Fragment>
                                        {
                                            accountNameSearch !== '' &&
                                                <IconButton
                                                    aria-label="cancel"
                                                    data-testid="cancel-search"
                                                    onClick={this.onCancelSearch}
                                                >
                                                    <ClearOutlined />
                                                </IconButton>
                                        }
                                        <IconButton
                                            aria-label="search"
                                            data-testid="commit-search"
                                            onClick={this.onButtonSearch}
                                        >
                                            <SearchOutlined />
                                        </IconButton>
                                    </React.Fragment>
                                )
                            }}
                            onChange={this.onAccountNameSearch}
                            onKeyDown={this.onAccountNameKeyDown}
                        />
                    </Paper>
                    <SimpleListPagination
                        className={classes.simpleList}
                        keepItemSelected={true}
                        items={accountListItems}
                        isLoading={accountsIsLoading}
                        onListItemClick={this.onAccountClicked}
                        rowsPerPage={accountsRowsPerPage}
                        totalItems={accountsTotalItems}
                        offset={accountsItemsOffset}
                        onPageOverBoundary={onAccountDataPageOverBoundary}
                        noItemsLabel='No accounts found'
                    />
                </div>
                { currentAccountName !== '' ? 
                    <div className={`${(runningTasks > 0 && !exportFolder) ? classes.expandedListAccounts : classes.simpleListAccounts}`}>
                        { managerTeams && (managerTeams.length > 0) ?
                                <div className={`${classes.container}`} data-testid="managerTeamPicker">
                                    <Typography
                                        variant='h6'
                                        className={`${classes.heading} ${classes.ellipsis}`}
                                    >
                                        Manager Teams on {currentAccountName}
                                    </Typography>
                                        <SimpleListPagination
                                            className={classes.simpleList}
                                            keepItemSelected={false}
                                            isLoading={isTeamsLoading}
                                            items={managerTeamListItems}
                                            offset={currentManagerTeamOffset}
                                            onListItemClick={this.onTeamClicked}
                                            onPageOverBoundary={onManagerTeamDataPageOverBoundary}
                                            rowsPerPage={teamsRowsPerPage}
                                            totalItems={totalManagerTeams}
                                        />
                                </div>
                                :
                                <Paper className={`${classes.container} ${classes.teamSpinnerContainer} ${(!isTeamsLoading ? classes.noAccountContainer : '')}`}>
                                    <CCSpinner
                                        loading={isTeamsLoading}
                                        size={70}
                                    >
                                        <Typography variant={!managerTeams ? 'h4' : 'h5'}>
                                            {
                                                !managerTeams ? 'No organization selected' : `No manager teams for ${currentAccountName ? currentAccountName : 'the selected account'}`
                                            }
                                        </Typography>
                                    </CCSpinner>
                                </Paper>
                        }
                        { employeeTeams && (employeeTeams.length > 0) ?
                                <div className={`${classes.container}`} data-testid="employeeTeamPicker">
                                    <Typography
                                        variant='h6'
                                        className={`${classes.heading} ${classes.ellipsis}`}
                                    >
                                        Employee Teams on {currentAccountName}
                                    </Typography>
                                        <SimpleListPagination
                                            className={classes.simpleList}
                                            keepItemSelected={false}
                                            isLoading={isTeamsLoading}
                                            items={employeeTeamListItems}
                                            offset={currentEmployeeTeamOffset}
                                            onListItemClick={this.onTeamClicked}
                                            onPageOverBoundary={onEmployeeTeamDataPageOverBoundary}
                                            rowsPerPage={teamsRowsPerPage}
                                            totalItems={totalEmployeeTeams}
                                        />
                                </div>
                                :
                                <Paper className={`${classes.container} ${classes.teamSpinnerContainer} ${(!isTeamsLoading ? classes.noAccountContainer : '')}`}>
                                    <CCSpinner
                                        loading={isTeamsLoading}
                                        size={70}
                                    >
                                        <Typography variant={!employeeTeams ? 'h4' : 'h5'}>
                                            {
                                                !employeeTeams ? 'No organization selected' : `No employee teams for ${currentAccountName ? currentAccountName : 'the selected account'}`
                                            }
                                        </Typography>
                                    </CCSpinner>
                                </Paper>
                        }
                        <Paper className={`${classes.formContainer}`}>
                            <FormControlLabel
                                className={`${classes.checkContainer}`}
                                control={
                                    <Checkbox
                                        checked={exportFolder}
                                        onChange={this.onExportFolderChange}
                                        name="checkedFolder"
                                    />
                                }
                                label="Filter by folder"
                                labelPlacement="start"
                            />
                            { !exportFolder && exportTeam && 
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className={classes.fullBtn}
                                    onClick={onTeamExportClick}
                                >
                                    Export users from {exportTeamType} team(s)
                                </Button>
                            }
                        </Paper>
                    </div>
                : 
                    <Paper className={`${classes.container} ${classes.firstPaper} ${classes.teamSpinnerContainer} ${(!isTeamsLoading ? classes.noAccountContainer : '')}`}>
                        <CCSpinner
                            loading={isTeamsLoading}
                            size={70}
                        >
                            <Typography variant={'h5'}>
                                {
                                    'No organization selected'
                                }
                            </Typography>
                        </CCSpinner>
                    </Paper>
                }
                { exportFolder &&
                    <div className={`${classes.simpleListAccounts}`}>
                        <Typography
                            variant='h6'
                            className={`${classes.heading} ${classes.ellipsis}`}
                        >
                            Folders on {currentAccountName}
                        </Typography>

                        { pickedFolder && exportTeam && 
                            <Button
                                variant="contained"
                                color="primary"
                                className={classes.folderBtn}
                                onClick={onTeamExportClick}
                            >
                                Export users from {exportTeamType} team(s) with folder
                            </Button>
                        }

                        <Paper 
                            className={classes.treePaper}
                            onScrollCapture={this.centerScroll}
                            data-testid="folderTeamFilter"
                        >
                            <NodesTreeView
                                treeData={currentNodeTree}
                                isLoading={treeIsLoading}
                                onNodeSelected={onNodeSelected}
                                onNodeToggled={onNodeToggled}
                                className={classes.treeContainer}
                                loadingOverlayPosition={treeScrollPosition}
                            />
                        </Paper> 
                    </div>
                }
            </div>
        );

    }
}

const MUIComponent = withStyles(styles)(AccountUserExport);
export {MUIComponent as AccountUserExport}