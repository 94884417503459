import {
    Button,
    Divider,
    Grid,
    IconButton,
    ListItemText,
    Paper,
    TextField,
    Typography
} from '@material-ui/core';
import {
    createStyles,
    WithStyles,
    withStyles
} from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { KeyboardArrowLeftOutlined } from '@material-ui/icons';
import _ from 'lodash';
import * as React from 'react';
import { Marker } from '../../models';
import { ModuleGroup, UserModuleGroup } from '../../models/module-group';
import { DialogResult, SimpleDialog } from '../../shared/components/simple-dialog';
import { PageBoundary } from '../../shared/components/simple-grid-pagination';
import { SimpleListItem } from '../../shared/components/simple-list';
import { SimpleListPagination } from '../../shared/components/simple-list-pagination';
import { LocationInput } from '../location-input';
import { ModuleGroupForm } from '../module-group-form';
import { UserModule } from '../../models/user-module';
import { UserModuleForm } from '../user-module-form';

const styles = (theme: Theme) => createStyles({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    container: {
        display: 'flex',
        flexDirection: 'column'
    },
    heading: {
        fontWeight: 700,
        marginBottom: '0.5em',
        marginTop: '1em'
    },
    ellipsis: {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden'
    },
    ellipsisDetails: {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'visible'
    },
    simpleList: {
        overflow: 'auto',
        height: '100%',
        minWidth: '20em'
    },
    markerListFullContainer: {
        width: '100%'
    },
    markerListSemiContainer: {
        width: '48%',
        marginRight: '2%'
    },
    markerDetails: {
        width:'50%',
        minHeight: '57em',
        height: '100%',
        '& .ellipsis': {
            overflow: 'visible !important'
        }
    },
    simpleListTeams: {
        width: '36em'
    },
    teamSpinnerContainer: {
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '3em',
        marginBottom: '4em',
        borderRadius: '0.4em'
    },
    noAccountContainer: {
        fontWeight: 'bold',
        margin: '3em 0 0 3em',
         '& > *': {
            color: 'rgba(0,0,0,0.2)'
        }
    },
    textField: {
        padding: '1em',
        width: '100%',
        '& label': {
            fontWeight: 'bolder',
            padding: '1em 0 0 1em',
            fontSize: '1.4em',
            color: 'rgba(0, 0, 0, 0.8) !important'
        },
        '&::before': {
            borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
            borderBottomStyle: 'solid'
        }
    },
    listHeader: {
        paddingLeft: '1em',
        '& span': {
            fontWeight: 'bolder'
        }
    },
    rightFloat: {
        float: 'right'
    },
    trashIcon: {
        color: '#eb445a'
    },
    addItem: {
        color: '#2dd36f'
    },
    emptyDisplay: {
        padding: '1em',
        display: 'block',
        margin: 'auto',
    },
    buttonSave: {
        marginBottom: '1em'
    },
    detailBorder: {
        border: '1px solid #e1e1e1',
        overflowY: 'scroll',
        height: '100%'
    },
    dividerForm: {
        margin: '1em'
    },
});

interface Props extends WithStyles<typeof styles> {
    className?: string;
    markers?: Marker[] | null;
    markersIsLoading: boolean;
    currentFloorplanName?: string;
    markersTotalItems?: number;
    markersItemsOffset: number;
    rowsPerPage: number;
    moduleGroups: ModuleGroup[];
    accountModuleGroups: ModuleGroup[];
    userModules: UserModule[];
    accountUserModules: UserModule[];
    hiddenAttributes: string[];
    accountId: string;
    onMarkerClick?: (marker: Marker) => void;
    onMarkerDataPageOverBoundary?: (boundary: PageBoundary, nextPage: number) => Promise<void>;
    onMarkerSaveChanges?: (updatedMarker: Marker, markerUserModuleGroup: UserModuleGroup | null) => void;
    onCurrentMarkerClear?: () => void;
    onFormChanges?: () => void;
};

interface States {
    currentMarker: Marker | null;
    updatedMarker: Marker | null;
    markerDetailComponents: React.ReactChild[];
    hasUpdated: boolean;
    tempMarker: SimpleListItem | null;
    formHasChanges: boolean;
    isChangesModalOpen: boolean;
    markerUserModuleGroup: UserModuleGroup | null;
}

class FloorplanMarkerDetail extends React.Component<Props, States> {
    public static defaultProps = {
        currentFloorplanName: '',
        markersItemsOffset: 0
    };

    state: States = {
        currentMarker: null,
        updatedMarker: null,
        markerDetailComponents: [],
        hasUpdated: false,
        tempMarker: null,
        formHasChanges: false,
        isChangesModalOpen: false,
        markerUserModuleGroup: null
    }

    onMarkerClick = (listItem: SimpleListItem) => {
        const { markers, onMarkerClick } = this.props;
        const { formHasChanges } = this.state;

        if(!markers) {
            return;
        }

        const currentMarker = markers.find((marker:Marker) => {
            return marker.marker_id === listItem.id;
        });

        if (!currentMarker) {
            return;
        }

        if (!onMarkerClick) {
            return;
        }

        onMarkerClick(currentMarker);

        if (!formHasChanges) {
            this.setState({
                currentMarker,
                updatedMarker: JSON.parse(JSON.stringify(currentMarker)),
                hasUpdated: false,
                markerDetailComponents: [],
                markerUserModuleGroup: null
            }, () => {
                this.formatMarkerDetails();
            });
            return;
        }

        const { tempMarker } = this.state;
        
        if (tempMarker && tempMarker.id === listItem.id) {
            return
        }

        this.setState({
            tempMarker: listItem,
            isChangesModalOpen: true
        });
    };

    formatMarkerDetails = () => {
        const { updatedMarker } = this.state;
        const {
            classes,
            moduleGroups,
            hiddenAttributes,
            accountModuleGroups,
            accountUserModules,
            userModules
        } = this.props;

        if (!updatedMarker) {
            return;
        }

        const elementList: React.ReactChild[] = [];
        const markerKeys = Object.keys(updatedMarker);
        // MAKE THE FIELDS MORE USER READABLE
        const markerLbls = markerKeys.map(e => {
            const lowerWords = e.split("_").join(" ");
            return lowerWords.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
        });
        markerKeys.forEach((key, index) => {
            // UNLESS SPECIFIED WE ONLY ALLOW CERTAIN FIELDS TO BE UPDATED
            // PREVENT UPDATING THE MARKER
            if (hiddenAttributes.includes(key)) {
                return;
            }
            const keyType = typeof(updatedMarker[key as keyof Marker]);
            const keyValue = updatedMarker[key as keyof Marker];
            // EVEN IF THE USER RE-ENABLES THE FIELD WE DONT LISTEN OR CARE ABOUT
            // THE VALUES HERE
            if (keyType === 'string' || keyType === 'number') {
                // THE END USER MODULE GROUP IS A STRING CATCH IT
                if (markerLbls[index] === 'User Module Group') {
                    // ADD THE HEADER
                    elementList.push(
                        <ListItemText className={classes.listHeader} primary={markerLbls[index]} />
                    );
                    // USE THE MODULE PICKER
                    elementList.push(
                        <Grid container spacing={1} alignItems="center">
                            <Grid item xs={1}>
                            </Grid>
                            <Grid item xs={11}>
                                <UserModuleForm
                                    userModuleGroup={keyValue as string}
                                    userModules={accountUserModules.length <= 0 ? userModules : accountUserModules}
                                    moduleKey={key}
                                    canEdit={accountUserModules.length > 0}
                                    onMarkerUserModuleGroupLoad={this.onMarkerUserModuleGroupLoad}
                                    onModuleChange={this.onUserModuleChange}
                                />
                            </Grid>
                        </Grid>
                    );
                    return;
                }
                if (keyValue !== '') {
                    elementList.push(
                        <TextField
                            className={classes.textField}
                            id={`${updatedMarker.marker_id}_${key}`}
                            label={markerLbls[index]}
                            defaultValue={keyValue}
                            disabled={true}
                            multiline={true}
                        />
                    );
                }
            }
            else if (keyType === 'object') {
                if (markerLbls[index] === 'Location') {
                    // ADD THE HEADER
                    elementList.push(
                        <ListItemText className={classes.listHeader} primary={markerLbls[index]} />
                    );
                    // ADD THE NON EDITABLE VALUES
                    elementList.push(
                        <Grid container spacing={1} alignItems="center">
                            <Grid item xs={1}>
                            </Grid>
                            <Grid item xs={11}>
                                <LocationInput
                                    locationCurrentValue={keyValue as Marker['location']}
                                    locationKey={key}
                                    canEdit={false}
                                />
                            </Grid>
                        </Grid>,
                        <Divider className={classes.dividerForm} />
                    );
                } else if (markerLbls[index] === 'Module Groups') {
                    // ADD THE HEADER
                    elementList.push(
                        <ListItemText className={classes.listHeader} primary={markerLbls[index]} />
                    );
                    // USE THE MODULE PICKER
                    elementList.push(
                        <Grid container spacing={1} alignItems="center">
                            <Grid item xs={1}>
                            </Grid>
                            <Grid item xs={11}>
                                <ModuleGroupForm
                                    existingModuleList={updatedMarker.module_groups}
                                    moduleGroups={accountModuleGroups.length <= 0 ? moduleGroups : accountModuleGroups}
                                    moduleKey={"module_groups"}
                                    canEdit={accountModuleGroups.length > 0}
                                    onModuleChange={this.onModuleChange}
                                />
                            </Grid>
                        </Grid>
                    );
                } else if (markerLbls[index] === 'Public Config') {
                    // ADD THE HEADER
                    elementList.push(
                        <ListItemText className={classes.listHeader} primary={markerLbls[index]} />
                    );
                    // ADD THE FIELDS
                    // NOT EDITABLE
                    elementList.push(
                        <Grid container spacing={1} alignItems="center">
                            <Grid item xs={1}>
                            </Grid>
                            <Grid item xs={11}>
                                <TextField
                                    className={classes.textField}
                                    id="auth_read"
                                    label="Auth Read"
                                    defaultValue={updatedMarker.public_config.auth ? updatedMarker.public_config.auth.read.toString() : '-'}
                                    disabled={true}
                                    multiline={true}
                                />
                            </Grid>
                        </Grid>
                        ,
                        <Grid container spacing={1} alignItems="center">
                            <Grid item xs={1}>
                            </Grid>
                            <Grid item xs={11}>
                                <TextField
                                    className={classes.textField}
                                    id="auth_write"
                                    label="Auth Write"
                                    defaultValue={updatedMarker.public_config.auth ? updatedMarker.public_config.auth.write.toString() : '-'}
                                    disabled={true}
                                    multiline={true}
                                />
                            </Grid>
                        </Grid>
                    );
                } else if (markerLbls[index] === 'Meta') {
                    const metaKeys = Object.keys(updatedMarker.meta!);
                    if (metaKeys.length <= 0) {
                        return;
                    }

                    // ADD THE HEADER
                    elementList.push(
                        <ListItemText className={classes.listHeader} primary={markerLbls[index]} />
                    );

                    const metaKeysLbls = metaKeys.map(e => {
                        const lowerWords = e.split("_").join(" ");
                        return lowerWords.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
                    });
                    metaKeys.forEach((metaElement, metaIndex) => {
                        // ON META WE CARE ABOUT THE ROOM TYPE
                        if (metaElement === 'type') {
                            elementList.push(
                                <Grid container spacing={1} alignItems="center">
                                    <Grid item xs={1}>
                                    </Grid>
                                    <Grid item xs={11}>
                                        <TextField
                                            className={classes.textField}
                                            id={`${updatedMarker.marker_id}_${key}`}
                                            label="Type"
                                            defaultValue={updatedMarker.meta!.type}
                                            onChange={e => this.handleDetailChange(e, 'meta.type')}
                                            multiline={true}
                                            placeholder="Room Type"
                                            variant="outlined"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            );
                        } else {
                            elementList.push(
                                <Grid container spacing={1} alignItems="center">
                                    <Grid item xs={1}>
                                    </Grid>
                                    <Grid item xs={11}>
                                        <TextField
                                            className={classes.textField}
                                            id={`${updatedMarker.marker_id}_${metaKeys}`}
                                            label={metaKeysLbls[metaIndex]}
                                            defaultValue={updatedMarker.meta![metaElement].toString()}
                                            disabled={true}
                                            multiline={true}
                                        />
                                    </Grid>
                                </Grid>
                            );
                        }
                    });
                }
            }
        });
        // SAVE THE ELEMENTS
        this.setState({
            markerDetailComponents: elementList,
        });
    }

    handleDetailChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, key: string) => {
        const { updatedMarker } = this.state;
        const { onFormChanges } = this.props;

        if (!updatedMarker) {
            return;
        }
        // IMPLEMENTED LODASH FOR EASY ADDING OF EXTRA FIELDS IN THE FUTURE
        _.set(updatedMarker, key, e.target.value);
        this.setState({updatedMarker, hasUpdated: true, formHasChanges: true}, () => {
            if ( onFormChanges ) {
                onFormChanges();
            }
        });
    }

    onModuleChange = (moduleKey: string, moduleList: {[key: string]: boolean}) => {
        const { updatedMarker } = this.state;
        const { onFormChanges } = this.props;

        if (!updatedMarker) {
            return;
        }

        _.set(updatedMarker, moduleKey, moduleList);
        this.setState({updatedMarker, hasUpdated: true, formHasChanges: true}, () => {
            if ( onFormChanges ) {
                onFormChanges();
            }
        });
    }

    onMarkerSaveChanges = () => {
        const { onMarkerSaveChanges } = this.props;

        if(!onMarkerSaveChanges) {
            return;
        }

        const { updatedMarker, markerUserModuleGroup } = this.state;

        if(!updatedMarker) {
            return;
        }

        onMarkerSaveChanges(updatedMarker, markerUserModuleGroup);

        this.setState({
            currentMarker: null,
            updatedMarker: null,
            markerDetailComponents: [],
            hasUpdated: false,
            formHasChanges: false,
            tempMarker: null
        });
    }

    clearCurrentMarker = () => {
        const { onCurrentMarkerClear } = this.props;

        this.setState({currentMarker: null, markerDetailComponents: []});

        if (onCurrentMarkerClear) {
            onCurrentMarkerClear();
        }
    }

    onChangesModalClicked = (dialogResult: DialogResult) => {
        if (!dialogResult) {
            return;
        }

        if (dialogResult === DialogResult.Ok) {
            const { tempMarker } = this.state;
            if (tempMarker) {
                this.setState({
                    formHasChanges: false
                }, () => {
                    this.onMarkerClick(tempMarker);
                });
            }
        }

        this.setState({isChangesModalOpen: false});
    }

    onMarkerUserModuleGroupLoad = (markerUserModuleGroup: UserModuleGroup) => {
        const { currentMarker } = this.state;
        const { accountId } = this.props;

        if(!currentMarker) {
            return;
        }

        // CHECK IF THIS A DEDICATED USER MODULE GROUP OR NOT
        if (markerUserModuleGroup.dedicated_marker) {
            markerUserModuleGroup.flag_to_clone = false;
        } else {
            markerUserModuleGroup.flag_to_clone = true;
            markerUserModuleGroup.account_id = accountId;
            markerUserModuleGroup.dedicated_marker = currentMarker.marker_id;
        }
        this.setState({markerUserModuleGroup});
    }

    onUserModuleChange = (moduleList: string[]) => {
        const { markerUserModuleGroup } = this.state;
        const { onFormChanges } = this.props;

        if (!markerUserModuleGroup) {
            return;
        }
        
        markerUserModuleGroup.modules = moduleList;
        markerUserModuleGroup.has_updates = true;

        this.setState({markerUserModuleGroup, hasUpdated: true, formHasChanges: true}, () => {
            if ( onFormChanges ) {
                onFormChanges();
            }
        });
    }

    public render() {
        const {
            className,
            classes,
            markers,
            currentFloorplanName,
            markersIsLoading,
            markersTotalItems,
            markersItemsOffset,
            rowsPerPage,
            onMarkerDataPageOverBoundary,
        } = this.props;
        const {
            currentMarker,
            markerDetailComponents,
            hasUpdated,
            isChangesModalOpen
        } = this.state;

        const markerListItems = markers ? markers.map((marker: Marker) => {
            const markerId = marker.marker_id;
            return new SimpleListItem(markerId, marker.name, markerId);
        }) : [];

        return (
            <React.Fragment>
                { markers && (markers.length > 0) ?
                    <div className={`${classes.root} ${className}`} data-testid="markerList">
                        <div className={`${classes.container} ${currentMarker ? classes.markerListSemiContainer : classes.markerListFullContainer}`}>
                            <Typography
                                variant='h6'
                                className={`${classes.heading} ${classes.ellipsis}`}
                            >
                                Markers on {currentFloorplanName}
                            </Typography>
                            <SimpleListPagination
                                className={classes.simpleList}
                                keepItemSelected={true}
                                items={markerListItems}
                                isLoading={markersIsLoading}
                                onListItemClick={this.onMarkerClick}
                                rowsPerPage={rowsPerPage}
                                totalItems={markersTotalItems}
                                offset={markersItemsOffset}
                                onPageOverBoundary={onMarkerDataPageOverBoundary}
                            />
                        </div>
                        {
                            currentMarker && markerDetailComponents.length > 0 &&
                                <div className={`${classes.container} ${classes.markerDetails}`} data-testid="markerDetails">
                                    <Typography
                                        variant='h6'
                                        className={`${classes.heading} ${classes.ellipsisDetails}`}
                                    >
                                        <IconButton onClick={this.clearCurrentMarker}>
                                            <KeyboardArrowLeftOutlined />
                                        </IconButton>
                                        {currentMarker.name} details:
                                    </Typography>
                                    {
                                        hasUpdated && 
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                className={classes.buttonSave}
                                                onClick={this.onMarkerSaveChanges}
                                            >
                                                Save Changes
                                            </Button>
                                    }
                                    <Paper className={`${classes.container} ${classes.detailBorder}`}>
                                        { markerDetailComponents }
                                    </Paper>
                                </div>
                        }
                    </div>
               :
                    <div className={`${classes.root} ${classes.noAccountContainer}`}>
                        <Paper className={`${classes.container}`}>
                            <Typography
                                variant='h4'
                                className={classes.emptyDisplay}
                            >
                                No markers found
                            </Typography>
                        </Paper>
                    </div>
                }
                <SimpleDialog
                    open={isChangesModalOpen}
                    titleText="You have unsaved changes"
                    contentText="Do you wish to discard them?"
                    buttonCancelLabel="No"
                    buttonOkLabel="Yes"
                    onDialogResult={this.onChangesModalClicked}
                />
            </React.Fragment>
        )

    }
}

const MUIComponent = withStyles(styles)(FloorplanMarkerDetail);
export {MUIComponent as FloorplanMarkerDetail}

