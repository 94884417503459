import * as React from 'react';

import { TablePagination } from '@material-ui/core';
import {
    createStyles,
    WithStyles,
    withStyles
} from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { SimplePaginationActions } from './simple-pagination-actions';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const styles = (theme: Theme) => createStyles({
    root: {
        overflow: 'hidden',
        [theme.breakpoints.between('xs', theme.breakpoints.values.iPhone8_Landscape)]: {
            width: '100%',
            minHeight: '4em'
        }
    },
    toolbar: {
        [theme.breakpoints.between('xs', theme.breakpoints.values.iPhone8_Landscape)]: {
            position: 'fixed',
            background: 'rgba(255,255,255,0.8)',
            width: '100%',
            bottom: 0
        }
    },
    caption: {
        [theme.breakpoints.between('xs', theme.breakpoints.values.iPhone8_Landscape)]: {
            fontSize: '1.5em',
            fontWeight: '500'
        }
    }
});

interface Props extends WithStyles<typeof styles> {
    className?: string;
    count: number;
    isLoading: boolean;
    page: number;
    rowsPerPage: number;
    onPageChange?: (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => void;
}

class SimplePagination extends React.Component<Props> {
    public static defaultProps = {
        isLoading: false
    };
    
    onPageChanged = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) =>{
        const {
            isLoading,
            onPageChange
        } = this.props;
        
        if (isLoading) {
            return;
        }

        if (onPageChange) {
            onPageChange(event, page);
        }
    }
    
    public render() {
        const {
            className,
            classes,
            count, 
            isLoading,
            page,
            rowsPerPage
        } = this.props;
        
        SimplePaginationActions.defaultProps = {
            disabled: isLoading
        };

        const rootClasses = `${classes.root}${className ? ` ${className}`: ''}`;

        return (
            <TablePagination
                className={rootClasses}
                classes={{
                    caption: classes.caption,
                    toolbar: classes.toolbar
                }}
                component="div"
                count={count}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[rowsPerPage]}
                page={page}
                backIconButtonProps={{
                    'aria-label': 'Previous Page',
                }}
                nextIconButtonProps={{
                    'aria-label': 'Next Page',
                }}
                onPageChange={this.onPageChanged}
                ActionsComponent={SimplePaginationActions}
            />
        );
    }
}

const MUIComponent = withStyles(styles)(SimplePagination);
export {MUIComponent as SimplePagination};
