import {
    Typography
} from '@material-ui/core';
import {
    createStyles,
    WithStyles,
    withStyles
} from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import * as React from 'react';
import { PageBoundary } from '../../shared/components/simple-grid-pagination';
import { SimpleListItem } from '../../shared/components/simple-list';
import { SimpleListPagination } from '../../shared/components/simple-list-pagination';
import { Team } from '../../shared/domain';

const styles = (theme: Theme) => createStyles({
        root: {
            display: 'flex',
            flexWrap: 'wrap'
        },
        container: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%'
        },
        heading: {
            fontWeight: 700,
            marginBottom: '0.5em',
            marginTop: '1em'
        },
        simpleList: {
            width: '100%'
        },
        ellipsis: {
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden'
        },
        buttonClear: {
            float: 'right'
        }
    });

interface Props extends WithStyles<typeof styles> {
    className: string;
    team: Team;
    teamItems: SimpleListItem[];
    isTeamInfoLoading: boolean;
    rowsPerPage: number;
    teamInfoOffset: number;
    onTeamDataPageOverBoundary?: (boundary: PageBoundary, nextPage: number) => Promise<void>;
}

class ExpandedTeamInfo extends React.Component<Props> {
    public static defaultProps = {};

    public render() {
        const {
            className,
            classes,
            team,
            teamItems,
            isTeamInfoLoading,
            rowsPerPage,
            teamInfoOffset,
            onTeamDataPageOverBoundary
        } = this.props;

        const currentTeamName = team ? team.name : '';

        return (
            <div className={`${classes.root} ${className}`} data-testid="teamInfoDisplay">
                <div className={classes.container}>
                    <Typography
                        variant="h6"
                        className={`${classes.heading} ${classes.ellipsis}`}
                    >
                        {currentTeamName} Information
                    </Typography>
                    <div className={`${classes.container} ${classes.simpleList}`}>
                        <SimpleListPagination
                            className={classes.simpleList}
                            keepItemSelected={true}
                            items={teamItems}
                            isLoading={isTeamInfoLoading}
                            rowsPerPage={rowsPerPage}
                            totalItems={teamItems.length}
                            offset={teamInfoOffset}
                            onPageOverBoundary={onTeamDataPageOverBoundary}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

const MUIComponent = withStyles(styles)(ExpandedTeamInfo);
export { MUIComponent as ExpandedTeamInfo };
