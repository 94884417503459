import * as React from 'react';

import {
    createStyles,
    WithStyles,
    withStyles
} from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';

const styles = (theme: Theme) => createStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    },
    overlay: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: theme.ccPalette.disabled.light
    },
});

interface Props extends WithStyles<typeof styles> {
    className?: string,
    blockPageScroll: boolean,
    hideChildren: boolean,
    visible: boolean,
}

class CCOverlay extends React.Component<Props> {
    public static defaultProps = {
        blockPageScroll: true,
        hideChildren: true,
        visible: false
    };

    state = {
    };

    body: HTMLElement | null = document.body;

    public componentWillUnmount() {
        this.removeHideOverflow();
    }

    public checkOverlayVisibility() {
        const { visible } = this.props;
        if (visible) {
            this.addHideOverflow();
        } else if (!visible) {
            this.removeHideOverflow();
        }
    }

    public render() {
        const {
            classes,
            className,
            children,
            hideChildren,
            visible
        } = this.props;
        const rootClassNames = classes.root +
                               (className ? ` ${className}` : '') +
                               (visible ?  ` ${classes.overlay}` : '');
        
        this.checkOverlayVisibility();

        return (
            <div className={rootClassNames} >
                {
                    !visible && hideChildren ?
                        ''
                        : children
                }
            </div>
        );
    }
    
    protected addHideOverflow() {
        const {
            body,
            props: {
                blockPageScroll
            }
        } = this;
        if (!blockPageScroll || !body) {
            return;
        }

        body.style.overflow = 'hidden';
    }

    protected removeHideOverflow() {
        const {
            body,
            props: {
                blockPageScroll
            }
        } = this;
        if (!blockPageScroll || !body) {
            return;
        }

        body.style.removeProperty('overflow');
    }
}

const MUIComponent = withStyles(styles)(CCOverlay);
export { MUIComponent as CCOverlay };
