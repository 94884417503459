import {
    createStyles,
    WithStyles,
    withStyles
} from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { GetApp } from '@material-ui/icons';
import * as React from 'react';
import { CsvMarker } from '../../models';
import {
    CSVButton,
    ExportToCSVData
} from '../../shared/components/csv-button';

export interface MarkersListItem {
    id: string;
    name: string;
};

const styles = (theme: Theme) => createStyles({
    root: {
        // margin: '1em 0 0 0'
    },
    csvButtonButton: {
        fontSize: '0.8em',
        padding: '0.3em 0.6em 0.3em 0.2em'
    },
    exportIcon: {
        fontSize: '1.6em',
        marginRight: '0.1em'
    }
});

interface Props extends WithStyles<typeof styles> {
    className?: string;
    markers: MarkersListItem[];
    disabled: boolean;
};

class ExportMarkersButton extends React.Component<Props> {
    public static defaultProps = {
        disabled: false
    };

    onExportToCSVClicked = (): Promise<ExportToCSVData | undefined> => {
        const { markers } = this.props;
        if (markers.length < 1) {
            return Promise.reject();
        }

        const csvData: CsvMarker[] = markers.map((marker) => {
            const {
                id,
                name
            } = marker;
            return {
                marker_id: id,
                marker_name: name
            } as CsvMarker;
        });
        return new Promise((resolve, reject) => {
            const today = new Date();
            const fileName = `${today.getFullYear()}-${today.getMonth()+1}-${today.getDate()}-${today.getHours()}-${today.getMinutes()}-${today.getSeconds()}.csv`;
            return resolve({
                data: csvData,
                fileName
            } as unknown as ExportToCSVData);
        });
    };

    public render() {
        const {
            classes,
            className,
            disabled,
            markers
        } = this.props;
        const rootClassNames = classes.root + (className ? ` ${className}` : '');
        
        return (
            <CSVButton
                className={rootClassNames}
                classes={{
                    button: classes.csvButtonButton
                }}
                variant="contained"
                color="primary"
                disabled={disabled || (markers.length < 1)}
                onClick={this.onExportToCSVClicked}
            >
                <GetApp className={classes.exportIcon} />
                EXPORT TO CSV
            </CSVButton>
        );
    }
};

const MUIComponent = withStyles(styles)(ExportMarkersButton);
export { MUIComponent as ExportMarkersButton };
