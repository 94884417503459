// tslint:disable: ordered-imports
import { Button, Paper, Typography } from '@material-ui/core';
import {
    createStyles,
    WithStyles,
    withStyles
} from '@material-ui/core/styles';
import {
    Fullscreen,
    FullscreenExit,
    InfoOutlined
} from '@material-ui/icons';
import { Theme } from '@material-ui/core/styles';
import * as React from 'react';
import { XFDFMarker } from '../../features/marker-operations/marker-operations';
import { Alert, AlertTitle } from '@material-ui/lab';
import Bugsnag from '@bugsnag/js';
import { Marker } from '../../models';

declare const window: any;
const FLOORPLAN_FAILURE_MESSAGE = "Issues where detected with Floor plan, thin client was not able to be initialized";
const FLOORPLAN_CRASH_MESSAGE = "The Adobe Client has crashed unexpectedly, latests changes have NOT been saved, please reload the page and notify if the issue continues with this Floor plan";

enum ThinClientStatusCheck {
    NotInitialized = 0,
    Initialized,
    Scanning,
    ProcessingMarker,
    Failed
}

export enum MarkerOperationVerb {
    None = 0,
    Add,
    Move,
    Rename,
    Delete
}

export interface MarkerOperation {
    floorplan_id: string;
    marker_id: string;
    verb: string;
    coordinates?: string;
    name?: string;
}

export interface AdobeMarkerSchema {
    "@context": string[];
    id: string;
    type: string;
    motivation: string;
    bodyValue: string;
    target: {
        source: string,
        selector: {
            node: { index: number };
            opacity: number;
            subtype: string;
            boundingBox: number[];
            strokeColor: string;
            type: string;
        }
    };
    creator: {
        type: string;
        name: string;
    };
    created: string;
    modified: string;
}

export interface AdobeMarkerEvent {
    type: string;
    data: AdobeMarkerSchema;
}

const MarkerModel: AdobeMarkerSchema = {
    "@context": [
        "https://www.w3.org/ns/anno.jsonld",
        "https://comments.acrobat.com/ns/anno.jsonld"
    ],
    id: "",
    type: "Annotation",
    motivation: "commenting",
    bodyValue: "",
    target: {
        source: "",
        selector: {
            node: { index: 0 },
            opacity: 0.5,
            subtype: "note",
            boundingBox: [],
            // KEEP A DIFFERENT COLOR TO DIFFERENCIATE FROM NEWLY ADDED
            strokeColor: "#cf5f01",
            type: "AdobeAnnoSelector",
        }
    },
    creator: {
        type: "Person",
        name: "Parser",
    },
    created: "",
    modified: ""
};

const thinClientConfig = {
    // LEAVE STRING CONVERTION OTHERWISE ADOBE WONT START PROPERLY
    clientId: `${process.env.REACT_APP_ADOBE_PDF_EMBED_API_CREDENTIALS}`,
    divId: ''
}

const styles = (theme: Theme) => createStyles({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        transition: 'all 1s !important'
    },
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    frameContainer: {
        overflow: 'auto',
        width: '100%'
    },
    heading: {
        fontWeight: 700,
        height: 'min-content',
        display: 'inline'
    },
    ellipsis: {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden'
    },
    paperFrame: {
        height: '90%'
    },
    buttonFullscreen: {
        float: 'left',
        minWidth: '1em',
        marginRight: '1em',
    },
    buttonExport: {
        float: 'right',
        marginLeft: 'auto'
    },
    buttonContainer: {
        marginTop: '1.5em',
        marginBottom: '0.5em',
    },
    fullScreenContainer: {
        position: 'fixed',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        margin: 'auto !important',
        zIndex: 9999
    },
    buttonContainerFullscreen: {
        background: 'white',
        margin: 0,
        padding: '0.5em 0',
    },
    warningItem: {
        color: '#ffc409',
    },
    warningText: {
        color: 'darkorange'
    },
    readyItem: {
        color: '#2dd36f',
    },
    readyText: {
        color: 'darkgreen',
    },
    flexItem: {
        display: 'flex',
        alignItems: 'center'
    },
    warningToast: {
        whiteSpace: 'break-spaces'
    },
    thinClientFlag: {
        marginLeft: '1em',
        '& small': {
            marginLeft: '0.5em',
            fontWeight: 'bold',
            opacity: 0,
            transition: 'opacity 1s',
            animation: '$fadeInfo 7s',
            animationDelay: '1s'
        },
        '&:hover > small': {
            opacity: 1,
        }
    },
    "@keyframes fadeInfo": {
        "from": {
            opacity: 1
        },
        "to": {
            opacity: 0
        }
    },
    subtitle: {
        fontWeight: 'bold',
        color: 'darkgray',
        textAlign: 'center',
        margin: '40% 1em 1em 1em'
    }
});

interface Props extends WithStyles<typeof styles> {
    className: string;
    floorplanName: string;
    floorplanId: string;
    enabledEvents: string[];
    existingMarkers: XFDFMarker[];
    onFinishMarkerOperations?: (markerOperations: MarkerOperation[], updatedFileBlob: Blob) => void;
    fileBlob: Blob | null;
    isJSONReady: boolean;
    fetchFloorplanMarkers?: () => Promise<void | Marker[]> | undefined;
    warningText?: string;
};

interface States {
    viewSDKClient: any;
    filePreviewPromise: any;
    existingMarkers: AdobeMarkerSchema[];
    partialOperations: MarkerOperation[];
    updatedFile: Blob | null;
    isFullScreen: boolean;
    isSavingChanges: boolean;
    ignoredMarkers: AdobeMarkerSchema[];
    isWarning: boolean;
    warningText: string;
    duplicateMarkers: string[];
    isClientEnable: boolean;
    statusCheckElapser: number | null;
    thinClientStatus: ThinClientStatusCheck;
    statusCheckAttempts: number;
};

class ThinClient extends React.Component<Props, States> {
    public static defaultProps = {
    };

    state: States = {
        viewSDKClient: null,
        filePreviewPromise: null,
        existingMarkers: [],
        partialOperations: [],
        updatedFile: null,
        isFullScreen: false,
        isSavingChanges: false,
        ignoredMarkers: [],
        isWarning: false,
        warningText: '',
        duplicateMarkers: [],
        isClientEnable: true,
        statusCheckElapser: null,
        thinClientStatus: ThinClientStatusCheck.NotInitialized,
        statusCheckAttempts: -1
    };

    componentDidMount() {
        const { warningText } = this.props;
        if ( warningText ) {
            this.setState({isWarning: true, warningText});
        }
        this.initializeThinClient();
    };

    componentWillUnmount() {
        this.closeThinClient();
        this.clearTimeElapserInterval();
    }
    
    arrayBufferToBase64 = (buffer: Int8Array ) => {
        var binary = '';
        var bytes = new Uint8Array( buffer );
        var len = bytes.byteLength;
        for (var i = 0; i < len; i++) {
            binary += String.fromCharCode( bytes[ i ] );
        }
        return window.btoa( binary );
    }

    storeNewBlob = (fileURL: string, metaData: any) => {
        fetch(fileURL).then(res => res.blob())
            .then(blob => {
                const updatedFile = new File([blob], metaData.fileName, {type: "application/pdf"});
                // SAVE CHANGES
                this.setState({
                    updatedFile
                });
            });
    }

    initializeThinClient = () => {
        const { fileBlob, floorplanName, floorplanId } = this.props;
        // CHECK IF THE LIBRARY IS PROPERLY LOADED
        const readyPromise = new Promise((resolve) => {
            if (window.AdobeDC) {
                resolve(true);
            } else {
                 document.addEventListener("adobe_dc_view_sdk.ready", () => {
                    resolve(true);
                });
            }
        });
        // ONCE READY START INITIALIZATION
        readyPromise.then(() => {
            thinClientConfig.divId = "adobe-thin-client-holder";
            const adobeDCView = new window.AdobeDC.View(thinClientConfig);
            // MANIPULATE THE SAVE OPERATION TO PREVENT ADOBE BUILT IN SAVE BUTTON
            // AND INSTEAD USE AN EXTERNAL ONE
            // THIS OPERATION RETURN A FILE BLOB READY TO BE SEND TO THE BACKEND
            // THIS EVENT IS TRIGGERED ON EVERY SINGLE OPERATION ADOBE DOESNT BATCH MULTIPLE OPERATIONS
            adobeDCView.registerCallback(
                window.AdobeDC.View.Enum.CallbackType.SAVE_API,
                (metaData: any, content: any, options: any) => {
                    const base64PDF = this.arrayBufferToBase64(content);
                    const fileURL = `data:application/pdf;base64,${base64PDF}`;
                    this.storeNewBlob(fileURL, metaData);
                    return new Promise(resolve => {
                        // WE CANNOT RESOLVE THE PROMISE RIGHT AWAY
                        // IT CAN CAUSE THE THIN CLIENT TO FAIL THE SAVING PROCESS ON MULTIPLE OPERATIONS
                        setTimeout(() => {
                            const response = {
                                code: window.AdobeDC.View.Enum.ApiResponseCode.SUCCESS,
                                data: {
                                    metaData: Object.assign(metaData, {updatedAt: new Date().getTime()})
                                },
                            };
                            resolve(response);
                            // IS BETTER TO HAVE AN EXTRA TIME TO LET THE QUEUE OF ACTIONS TO BE PROCESSED
                            setTimeout(() => {
                                this.setState({isSavingChanges: false});
                            }, 2000);
                        }, 1000);
                    });
                },
                { 
                    autoSaveFrequency: 1,
                    enableFocusPolling: false,
                    showSaveButton: false
                }
            );
            // OPEN THE FLOORPLAN FILE
            const reader = new FileReader();
            reader.onloadend = (e) => {
                const filePromise = Promise.resolve(e.target?.result);
                
                const previewFilePromise = adobeDCView.previewFile({
                    content: {
                        promise: filePromise
                    },
                    metaData: {
                        // KEEP STRING TEMPLATES ADOBE WONT RECEIVE THE VALUES DIRECTLY
                        fileName: `${floorplanName}.pdf`,
                        id: `${floorplanId}`
                    }
                }, {
                    // QUICK SUMMARY OF CHOICES:
                    // - ENABLE ANNOTATIONS.
                    // - PREVENT THE USER TO DOWNLOAD THE PDF.
                    // - PREVENT FORM DISPLAY AND MANIPULATION
                    // - HIDE THE SIDE PANEL
                    // - PREVENT BUILT IN SAVE BUTTON
                    // - SHOW THE CONTROLS AS AS MOVING BAR
                    showAnnotationTools: true,
                    enableAnnotationAPIs: true,
                    includePDFAnnotations: true,
                    showDownloadPDF: false,
                    enableFormFilling: false,
                    showPrintPDF: false,
                    showSaveButton: false,
                    showThumbnails: false,
                    showBookmarks: false,
                    enableSearchAPIs: false
                });
                // START MARKER OPERATIONS
                this.processExistingMarkers(previewFilePromise, adobeDCView);
                // STORE THE CLIENT FOR ANOTHER USES
                this.setState({
                    viewSDKClient: adobeDCView,
                    filePreviewPromise: previewFilePromise
                }, () => {
                    this.startAdobeStatusCheck();
                });
            };
            reader.readAsArrayBuffer(fileBlob!);
        });
    }

    startAdobeStatusCheck = () => {
        // THE CURRENT THIN CLIENT PRODUCT HAS A POTENTIAL ISSUE WHERE
        // IT CAN 'SILENTLY' CRASH, THE USER CAN USE THE TOOL AND EVERYTHING 
        // APPEARS NORMAL HOWEVER ALL EVENTS LIKE ANNOTATION ADD, REMOVE, ETC
        // STOP AND WE DON'T GET INFORMATION BACK FROM THE TOOL MAKING IT
        // SO THAT CHANGES DONE TO THE DOCUMENT ARE NOT SAVED IN THE DATABASE
        // CAUSING DATA TO FALL OUT OF SYNC
        const { viewSDKClient } = this.state;

        // THIS IS AN EVENT TO CHECK IF THE FILE IS STILL 'ALIVE'
        // ITS CONFIGURED TO RUN EVERY 20 SECONDS IN THE 'filePollFrequency'
        viewSDKClient.registerCallback(
            window.AdobeDC.View.Enum.CallbackType.STATUS_API,
            (metaData: any) => {
                return new Promise((resolve, reject) => {

                    const { thinClientStatus } = this.state;
                    // FIRST RUN OF THE STATUS CHECK INITIALIZE TIMERS
                    if (thinClientStatus === ThinClientStatusCheck.NotInitialized) {
                        this.setState({
                            thinClientStatus: ThinClientStatusCheck.Initialized
                        }, () => {
                            this.startHealthCheck();
                        });
                    } else if (thinClientStatus === ThinClientStatusCheck.Initialized || thinClientStatus === ThinClientStatusCheck.ProcessingMarker) {
                        // SCANNING THING'S LOOKS GOOD
                        // WE MAY ENCOUNTER THAT THE CLIENT WAS PROCESSING THIS IS ALSO GOOD
                        this.setState({ 
                            thinClientStatus: ThinClientStatusCheck.Scanning    
                        });
                    }

                    resolve ({
                       code: window.AdobeDC.View.Enum.ApiResponseCode.SUCCESS,
                    });
                 });
            },
            {
                filePollFrequency: 20,
                keepPolling: true
            }
        )
    }

    startHealthCheck = () => {
        // THIS IS A TIMER IN CHARGE OF VALIDATING THE STATUS CHECK FOR THE THIN CLIENT
        const { statusCheckElapser } = this.state;
        if (statusCheckElapser) {
            return;
        }

        // THIN CLIENT CHECK RUNS EVERY 10 SECONDS
        // SO WE RUN EVERY 25 TO GIVE TIME TO THE CHECK TO FINISH
        // WE NEED TO GIVE TIME BECAUSE THE CALL CAN TAKE A COUPLE OF SECONDS MORE
        // DEPENDING OF THE SIZE OF THE PDF (PHYSICAL AND DIMENSIONS)
        const healthCheckInterval = window.setInterval(() => {
            const { thinClientStatus, statusCheckAttempts } = this.state;

            if (thinClientStatus === ThinClientStatusCheck.Scanning) {
                // IF WE ARE SCANNING WE ARE ALIVE BASICALLY
                this.setState({ thinClientStatus: ThinClientStatusCheck.Initialized});
            } else if (thinClientStatus === ThinClientStatusCheck.ProcessingMarker) {
                // THE STATUS CHECK IS INCONSISTENT AND WONT PROCESS
                // WHEN MARKER UPDATES ARE BEING MADE TO THE DOCUMENT
                // ADDITIONALLY IT MAY LAG BEHIND AFTER USER STOPS DOING OPERATIONS
                // OR IF THERE ARE A LOT OF OPERATIONS HAPPENING AT
                // THE SAME TIME, SO WE HAVE A NEW STATUS AND WE CLEAR IT
                // ALSO CLEAR FAILED ATTEMPTS, THE GOAL IS TO ONLY CRASH ON
                // CONSECUTIVE ATTEMPTS TO AVOID MISSINTERPRETING INCONSISTENT CLIENT
                // BEHAVIOUR AS A CRASH
                this.setState({ 
                    thinClientStatus: ThinClientStatusCheck.Initialized,
                    statusCheckAttempts: 0
                });
            } else if (thinClientStatus === ThinClientStatusCheck.Initialized) {
                // THE STATUS HASN'T CHECKED WE ARE STUCK
                // WE HAVE A THRESHOLD FOR HOW MANY TIME WE CAN FAIL
                // WE DO THIS BECAUSE ITS POSSIBLE FOR THE TOOL TO TAKE TIME 
                // TO DO A CHECK IF THE USER IS DOING A LOT OF OPERATIONS
                // OR IF THE PDF IS BIG
                this.setState({
                    statusCheckAttempts: statusCheckAttempts + 1,
                    isClientEnable: statusCheckAttempts <= 5,
                    thinClientStatus: statusCheckAttempts > 5 ? ThinClientStatusCheck.Failed : ThinClientStatusCheck.Initialized
                });

                // WE HAVE SURPASSED THE THRESHOLD 
                // IT MEANS WE HAVE CRASHED AND ARE HAVE NOT RECEIVED ANYTHING BACK
                // FROM THE THIN CLIENT
                // INFORM THE USER AND BUGSNAG
                if (statusCheckAttempts > 5) {
                    const { floorplanId, floorplanName } = this.props;
                    Bugsnag.notify((new Error(`Floorplan - ${floorplanId} THIN CLIENT CRASHED`)), event => {
                        event.severity = 'warning';
                        event.context = 'Thin Client';
                        event.addMetadata('floorplan', {
                            floorplan_id: floorplanId,
                            floorplan_name: floorplanName
                        })
                    });
                }
            }
        }, 25000);

        this.setState({statusCheckElapser: healthCheckInterval});
    }


    clearTimeElapserInterval() {
        const { statusCheckElapser } = this.state;
        if( statusCheckElapser ) {
            window.clearInterval(statusCheckElapser);
        }
    }

    verifyMarkerCoords = (markerId: string, stringCoords?: string, arrayCoords?: number[]) => {
        // THIS IS A SAFEGUARD TO PREVENT MARKERS OUT OUF BOUNDS OR WITH NEGATIVE COORDS
        // WE TAKE THE ABSOLUTE VALUE AND NOTIFY BUGSNAG TO KEEP RECORD
        let invalidMarker: {marker_id: string, coords: string | number[]} | null = null;
        let formattedCords: number[] = [];
        if (stringCoords) {
            formattedCords = stringCoords.split(',').map(e => {
                const numberCoord = parseFloat(e);
                if (numberCoord < 0) {
                    invalidMarker = {marker_id: markerId, coords: stringCoords};
                }
                return Math.abs(numberCoord);
            });
        }
        if (arrayCoords) {
            formattedCords = arrayCoords.map(e => {
                if (e < 0) {
                    invalidMarker = {marker_id: markerId, coords: arrayCoords};
                }
                return Math.abs(e);
            });
        }
        if (invalidMarker) {
            const { floorplanId, floorplanName } = this.props;
            Bugsnag.notify((new Error(`Floorplan - ${floorplanId} Markers out of bounds`)), event => {
                event.severity = 'warning';
                event.context = 'Marker operations';
                event.addMetadata('floorplan', {
                    floorplan_id: floorplanId,
                    floorplan_name: floorplanName,
                    invalid_markers: invalidMarker
                })
            });
        }
        return formattedCords;
    }

    processExistingMarkers = (previewFilePromise: any, adobeDCView: any) => {
        previewFilePromise.then((adobeViewer: any) => {
            adobeViewer.getAnnotationManager().then((annotationManager: any) => {
                annotationManager
                  .removeAnnotationsFromPDF()
                  .then((result: { annotations: any[]; }) => {
                    console.log(result.annotations);
                    // TESTING FOUND THAT ADOBE REGENERATES THE ID OF THE MARKER ON NEW SESSIONS
                    // TO PREVENT THE MARKER ID GETTING LOST STORE IT IN THE CREATOR OBJECT
                    // AND THEN MOVE TO THE ID TO PERFORM OTHER OPERATIONS
                    const readyMarkers = result.annotations.map(m => {
                        const newMarker = {
                            ...m,
                            id: m.creator.name,
                            target: {
                                ...m.target,
                                selector: {
                                    ...m.target.selector,
                                    boundingBox: this.verifyMarkerCoords(m.creator.name, undefined, m.target.selector.boundingBox),
                                    strokeColor: "#cf5f01"
                                }
                            }
                        };
                        return newMarker;
                    });
                    const { existingMarkers } = this.props;
                    // IF YOU SUPPLY ANY API WITH EMPTY ARRAYS THEY WILL ERROR
                    // IF THERE IS XFDF MARKERS AND PDF MARKERS DISPOSE OF THE PDF ONES
                    if ( readyMarkers.length <= 0 || (existingMarkers && existingMarkers.length > 0)){
                        // NOTHING TO ADD THEN PROCEED
                        // SET ENABLED EVENTS LISTENERS
                        this.setEventListeners(adobeDCView);
                        // ADD THE EXISTING MARKERS
                        if (existingMarkers && existingMarkers.length > 0) {
                            this.addExistingMarkers(previewFilePromise, existingMarkers);
                        }
                        return;
                    }
                    
                    // THE EXISTING ANNOTATIONS ARE JSON VALID ADD THEM
                    this.setState({ existingMarkers: readyMarkers});
                    annotationManager.addAnnotations(readyMarkers).then(() => {
                        // ALL READY TO PROCEED
                        // SET ENABLED EVENTS LISTENERS
                        this.setEventListeners(adobeDCView);
                        // VERIFY IF THE MARKERS ARE CORRECTLY STORED
                        const failedMarkers = readyMarkers.filter(e => e.id === 'Guest' || e.id === 'Parser');
                        
                        if (failedMarkers && failedMarkers.length > 0) {
                            this.fixFailedMarkers(failedMarkers);
                        }
                        // ADD THE EXISTING MARKERS
                        const { existingMarkers } = this.props;
                        if (existingMarkers && existingMarkers.length > 0) {
                            this.addExistingMarkers(previewFilePromise, existingMarkers);
                        }
                        }).catch((error: any) => {
                            console.error(error);
                        });
                });
            });
        });
    }

    setEventListeners = (adobeDCView: any) => {
        const { enabledEvents } = this.props;
        const eventOptions = {
            listenOn: enabledEvents,
            enableAnnotationEvents: true
        }
        adobeDCView.registerCallback(
            window.AdobeDC.View.Enum.CallbackType.EVENT_LISTENER,
            (event: AdobeMarkerEvent) => {
                this.setState({
                    isSavingChanges: true,
                    thinClientStatus: ThinClientStatusCheck.ProcessingMarker
                });
                const { existingMarkers, partialOperations, ignoredMarkers, duplicateMarkers } = this.state;
                console.log(event.type, event.data);
                if (ignoredMarkers.find(e => e.id === event.data.id)) {
                    // THIS IS A NEW MARKER THAT IS BEING PROCESSED DO NOT TRIGGER ANY EVENT
                    return;
                }
                if (event.data.target.selector.subtype !== 'note') {
                    // THIS IS A COMMENT TYPE THAT IS NOT SUPPORTED
                    // E.X.
                    // - TEXT MANIPULATION
                    // - DRAWINGS
                    // - HIGHLIGHTS
                    this.preventAddMarker(event.data, event.data.id, `Annotations of type: ${event.data.target.selector.subtype} are currently not supported`);
                    return;
                }
                switch (event.type) {
                    case window.AdobeDC.View.Enum.AnnotationEvents.ANNOTATION_ADDED:
                        // CHECK IF THIS IS A VALID ANNOTATION OR A REPLY
                        if (event.data.motivation === 'replying') {
                            this.preventAddMarker(event.data, event.data.id, 'Reply feature is currently not supported');
                            break;   
                        }
                        // CHECK THAT THE MARKER HAS A VALID NAME
                        if (event.data.bodyValue.toLocaleLowerCase() === '') {
                            this.preventAddMarker(event.data, event.data.id);
                            break;
                        }
                        // CHECK THAT WE ARE NOT ADDING THE XFDF MARKERS
                        if (existingMarkers.find(e => e.id === event.data.id)) {
                            break;
                        }
                        // CHECK THAT WE DONT HAVE A MARKER WITH THE SAME NAME
                        if (existingMarkers.find(e => e.bodyValue.toLowerCase() === event.data.bodyValue.toLowerCase().replace(/^\s+|\s+$/g, ""))) {
                            this.preventAddMarker(event.data, event.data.id);
                            break;
                        }
                        // CHECK THAT WE DONT HAVE AN OPERATION WITH THE SAME NAME
                        if (partialOperations.find(e => e.name?.toLocaleLowerCase() === event.data.bodyValue.toLowerCase().replace(/^\s+|\s+$/g, ""))) {
                            this.preventAddMarker(event.data, event.data.id);
                            break;
                        }
                        this.addNewMarker(event.data);
                        break;
                    case window.AdobeDC.View.Enum.AnnotationEvents.ANNOTATION_DELETED:
                        // CHECK IF THIS IS A NEW MARKER OR AN OLD ONE
                        if (existingMarkers.find(e => e.id === event.data.id)) {
                            // OLD ONE
                            // CHECK IF WE ALREADY HAVE AN UPDATE OPERATION
                            const existingIndex = partialOperations.findIndex(e => e.marker_id === event.data.id);
                            if (existingIndex >= 0) {
                                // DELETE IT
                                // DELETE OPERATION SUPERCEDE UPDATED
                                partialOperations.splice(existingIndex);
                            }
                            this.deleteMarker(event.data);
                            break;
                        }
                        // NEWLY ADDED
                        this.deleteAddedMarker(event.data);
                        break;
                    // MOVE AND RENAME OPERATIONS TRIGGER THE SAME EVENT
                    case window.AdobeDC.View.Enum.AnnotationEvents.ANNOTATION_UPDATED:
                        // CHECK THE OPERATION TYPE
                        const beforeData = existingMarkers.find(e => e.id === event.data.id);
                        if (beforeData) {
                            // CHECK THAT THIS IS NOT A COLOR CHANGE
                            if (beforeData.target.selector.strokeColor !== event.data.target.selector.strokeColor) {
                                // DO NOTHING WE DONT SAVE THIS CHANGES
                                beforeData.target.selector.strokeColor = event.data.target.selector.strokeColor;
                                return;
                            }
                            // RENAME OPERATION
                            if (beforeData.bodyValue.toLowerCase() !== event.data.bodyValue.toLowerCase().replace(/^\s+|\s+$/g, "")) {
                                // CHECK THAT THE MARKER HAS A VALID NAME
                                if (event.data.bodyValue.toLocaleLowerCase() === '') {
                                    this.handleExistingMarkerDuplicate(beforeData, event.data);
                                }
                                // CHECK THAT WE DONT HAVE A MARKER WITH THE SAME NAME
                                if (
                                    existingMarkers.find(e => e.bodyValue.toLowerCase() === event.data.bodyValue.toLowerCase().replace(/^\s+|\s+$/g, "")) ||
                                    partialOperations.find(e => {
                                        e.name!.toLowerCase() === event.data.bodyValue.toLowerCase().replace(/^\s+|\s+$/g, "") &&
                                        e.marker_id !== event.data.id
                                    })
                                ) {
                                    const beforeData = existingMarkers.find(e => e.id === event.data.id)!;
                                    this.handleExistingMarkerDuplicate(beforeData, event.data);
                                    break;
                                }
                                // CHECK IF THIS IS A DUPLICATE NAME MARKER
                                if (duplicateMarkers.includes(beforeData.id)) {
                                    // WE TAKE THIS UPDATE AS A CONFLICT RESOLUTION
                                    // RE-WRITE EXISTING DATA TO PREVENT GOING BACK TO ERROR STATE
                                    this.handleDuplicateMarkerFix(beforeData.id, beforeData.bodyValue, event.data.bodyValue);
                                }
                            }
                        }
                        // MOVE OPERATION
                        // CHECK IF WE ALREADY HAVE AN OPERATION
                        if (partialOperations.find(e => e.marker_id === event.data.id)) {
                            this.updateExistingOperation(
                                partialOperations.findIndex(e => e.marker_id === event.data.id),
                                event.data
                            );
                            break;
                        }
                        // ADD NEW OPERATION
                        this.addUpdateMarker(event.data);
                        break;
                    default:
                        break;
                }
            },
            eventOptions
        );
    }

    handleDuplicateMarkerFix = (markerId: string, originalName: string, newName: string) => {
        const { existingMarkers, duplicateMarkers } = this.state;
        const fixedMarker = existingMarkers.findIndex(e => e.id === markerId);
        existingMarkers[fixedMarker].bodyValue = newName;
        const remainingDupps = existingMarkers.filter(e => e.bodyValue.toLowerCase() === originalName.toLowerCase());
        const fixedDupp = duplicateMarkers.indexOf(markerId);
        duplicateMarkers.splice(fixedDupp);
        if (remainingDupps.length <= 1) {
            duplicateMarkers.splice(0, duplicateMarkers.length);
        }
        this.setState({
            existingMarkers,
            duplicateMarkers
        })
    }

    preventDeleteMarker = (markerData: AdobeMarkerSchema) => {
        const { filePreviewPromise, ignoredMarkers } = this.state;
        const oldIgnoredState = [...ignoredMarkers];
        ignoredMarkers.push(markerData);
        // SET THE ignoredMarkers TO PREVENT OVERLAPS WITH THE EVENT LISTENERS
        this.setState({ignoredMarkers, isWarning: true, warningText: 'You cannot delete a marker on this version'}, () => {
            filePreviewPromise.then((adobeViewer: { getAnnotationManager: () => Promise<any>; }) => {
                adobeViewer.getAnnotationManager().then(annotationManager => {
                    // ADD THE MARKER BACK
                    annotationManager.addAnnotations([markerData])
                        .then (()=> {
                            // ALL READY CLEAR THE ignoredMarkers
                            // LATENCY ISSUE ON ADOBE EVENTS FOUND ON TESTING
                            setTimeout(() => {
                                this.setState({ignoredMarkers: oldIgnoredState});
                            }, 500);
                        })
                        .catch((error: any) => console.error(error));
                });
            });
        });
    }

    preventAddMarker = (markerData: AdobeMarkerSchema, markerId: string, warningText?: string) => {
        const { filePreviewPromise, ignoredMarkers } = this.state;
        const oldIgnoredState = [...ignoredMarkers];
        ignoredMarkers.push(markerData);
        if (!warningText) {
            warningText = 'You cannot add a marker with a duplicate name';
        }
        // SET THE ignoredMarkers TO PREVENT OVERLAPS WITH THE EVENT LISTENERS
        this.setState({ignoredMarkers, isWarning: true, warningText }, () => {
            filePreviewPromise.then((adobeViewer: { getAnnotationManager: () => Promise<any>; }) => {
                adobeViewer.getAnnotationManager().then(annotationManager => {
                    // DELETE THE DUPLICATE
                    annotationManager.deleteAnnotations({ annotationIds: [markerId]})
                        .then (()=> {
                            // ALL READY CLEAR THE ignoredMarkers
                            // LATENCY ISSUE ON ADOBE EVENTS FOUND ON TESTING
                            setTimeout(() => {
                                this.setState({ignoredMarkers: oldIgnoredState});
                            }, 500);
                        })
                        .catch((error: any) => console.error(error));
                });
            });
        });
    }

    handleExistingMarkerDuplicate = (oldMarkerData: AdobeMarkerSchema, markerData: AdobeMarkerSchema) => {
        const { filePreviewPromise, ignoredMarkers } = this.state;
        const oldIgnoredState = [...ignoredMarkers];
        ignoredMarkers.push(oldMarkerData);
        // SET THE ignoredMarkers TO PREVENT OVERLAPS WITH THE EVENT LISTENERS
        this.setState({ignoredMarkers, isWarning: true, warningText: 'You cannot rename a marker to a duplicate name'}, () => {
            filePreviewPromise.then((adobeViewer: { getAnnotationManager: () => Promise<any>; }) => {
                adobeViewer.getAnnotationManager().then(annotationManager => {
                    // RENAME THE MARKER BACK
                    markerData.bodyValue = oldMarkerData.bodyValue;
                    annotationManager.updateAnnotation(markerData)
                        .then (()=> {
                            // ALL READY CLEAR THE ignoredMarkers
                            // LATENCY ISSUE ON ADOBE EVENTS FOUND ON TESTING
                            setTimeout(() => {
                                this.setState({ignoredMarkers: oldIgnoredState});
                            }, 500);
                        })
                        .catch((error: any) => console.error(error));
                });
            });
        });
    }

    verifyExistingMarkers = (markerOperations: AdobeMarkerSchema[]) => {
        let flag = true;
        markerOperations.forEach(element => {
            if (
                !element.target.selector.boundingBox ||
                element.target.selector.boundingBox.length !== 4
            ) {
                flag = false;
            }
            const coordinates = element.target.selector.boundingBox;
            coordinates.forEach(coord => {
                if (isNaN(coord)) {
                    flag = false;
                }
                if (coord < 0 ) {
                    flag = false;
                }
            });
        });
        return flag;
    }

    addExistingMarkers = (previewFilePromise: any, existingMarkers: XFDFMarker[]) => {
        const { floorplanId } = this.props;
        MarkerModel.target.source = floorplanId;
        MarkerModel.created = new Date().toISOString();
        MarkerModel.modified = new Date().toISOString();
        // VERIFY IF THERE ARE DUPLICATE MARKER NAMES
        const nameCounts = new Map<string, number>();
        const nameDupps = new Set<string>();
        const duppsIds: string[] = [];
        for (const marker of existingMarkers) {
            const name = marker.name;
            const count = nameCounts.get(name) || 0;
            nameCounts.set(name, count + 1);
            if (count === 1) {
                nameDupps.add(name);
            }
        }
        const parsedMarkers = existingMarkers.map(e => {
            const newItem = {
                ...MarkerModel,
                id: e.id,
                bodyValue: e.name,
                target: {
                    ...MarkerModel.target,
                    selector: {
                        ...MarkerModel.target.selector,
                        boundingBox: this.verifyMarkerCoords(e.id, e.coordinates, undefined)
                    }
                },
                // TESTING FOUND THAT ADOBE REGENERATES THE ID OF THE MARKER ON NEW SESSIONS
                // TO PREVENT THE MARKER ID GETTING LOST STORE IT IN THE CREATOR OBJECT
                // AND THEN MOVE TO THE ID TO PERFORM OTHER OPERATIONS
                creator: {
                    ...MarkerModel.creator,
                    name: e.id
                }
            };
            // RECOLOR OUT OF BOUNDS MARKERS
            if (e.isWrong) {
                newItem.target.selector.strokeColor = '#17e6f6';
            }
            // RECOLOR AND DUPPED NAME MARKERS
            if (nameDupps.has(e.name)) {
                newItem.target.selector.strokeColor = '#17e6f6';
                duppsIds.push(e.id)
            }
            return newItem;
        });
        // IN CASE WE FIND A MIXED PDF (JSON BUT NO FLAG FOR THIN CLIENT)
        // TAKE A DEFENSIVE APPROACH
        if (!this.verifyExistingMarkers(parsedMarkers)) {
            const { floorplanName } = this.props;
            Bugsnag.notify((new Error(`Floorplan - ${floorplanId} MIXED content`)), event => {
                event.severity = 'warning';
                event.context = 'Thin Client';
                event.addMetadata('floorplan', {
                    floorplan_id: floorplanId,
                    floorplan_name: floorplanName
                })
            });
            return;    
        }
        const thinExistingMarkers = this.state.existingMarkers;
        this.setState({existingMarkers: [...thinExistingMarkers, ...parsedMarkers]});
        // NOTIFY IF ANY MARKERS WITH DUPLICATE NAMES WHERE FOUND
        if (nameDupps.size !== 0){
            let warningText =
                'Markers with duplicate names where found, they have been highlighted in BRIGHT BLUE please resolve to save changes';
            nameDupps.forEach(e => {
                warningText += '\n' + `- ${e}`
            });
            this.setState({isWarning: true, warningText, duplicateMarkers: duppsIds });
        }
        previewFilePromise.then((adobeViewer: any) => {
            adobeViewer.getAnnotationManager().then((annotationManager: any) => {
                annotationManager.addAnnotations(parsedMarkers).then(() => {
                }).catch((error: {code: string, message: string}) => {
                    console.error(error);
                    // IT IS POSSIBLE TO UNDER CERTAIN CIRCUNSTANCES ADD MARKERS THAT ARE BEYOUND BOUNDARIES
                    // CATCH THE EXCEPTION
                    if (error.code === 'FAIL' && error.message.includes('Annotation selector is not valid')) {
                        // GET THE IDS AND CHECK THE DISPARITY
                        const regexIds = /\b([0-9a-fA-F]{24})\b/g;
                        const markerIds = error.message.match(regexIds);

                        if (!markerIds) {
                            // THE THIN CLIENT ERRORED OUT FOR A DIFFERENT REASON
                            // NOTIFY ON BUGSNAG
                            Bugsnag.notify((new Error(`Floorplan - ${floorplanId} Thin Client errored`)), event => {
                                event.severity = 'warning';
                                event.context = 'Thin Client';
                                event.addMetadata('floorplan', {
                                    floorplan_id: floorplanId,
                                    error
                                })
                            });
                            this.setState({ isClientEnable: false});
                            return;
                        }

                        // CHECK IF MOST OF THE MARKERS ARE OUT BOUNDS
                        if (markerIds.length >= Math.floor(existingMarkers.length /2)) {
                            // MOST OF THE MARKERS ARE IN AN INCORRECT STATE
                            // NOTIFY THE USER AND PREVENT USING THE THIN CLIENT
                            this.setState({
                                isWarning: true,
                                warningText: 'Most of the marker in this floor plan are out of bounds, please verify the uploaded data.' + '\n'
                                            + 'You can fix this floor plan through the replace tab using the same PDF or deleting and reuploading the data'
                            });
                            this.setState({ isClientEnable: false});
                        } else {
                            // SOME MARKERS ARE IN AN INCORRECT STATE
                            // WE CAN MOVE THE MARKERS TO AN IN BOUNDS POSITION
                            this.reCalcOutOfBoundsMarkers(previewFilePromise, markerIds, existingMarkers);
                        }
                    }
                });
            });
        });
    }

    reCalcOutOfBoundsMarkers = (previewFilePromise: any, markerIds: string[], existingMarkers: XFDFMarker[]) => {
        // BEFORE DOING ANYTHING WE NEED TO CHECK IF WE ARE ABLE TO PRODUCE FIXES
        // I.E WE HAVE VALID MARKERS TO USE THEIR LOCATIONS
        const { floorplanId, floorplanName } = this.props;
        if (markerIds.length === existingMarkers.length) {
            Bugsnag.notify((new Error(`Floorplan - ${floorplanId} Markers out of bounds`)), event => {
                event.severity = 'warning';
                event.context = 'Marker operations';
                event.addMetadata('floorplan', {
                    floorplan_id: floorplanId,
                    floorplan_name: floorplanName,
                    invalid_marker: markerIds,
                    was_fixable: false
                })
            });
            this.setState({
                isWarning: true,
                warningText: 'The markers out of bounds are not valid and were not able to be positioned in the PDF' + '\n'
                            + 'Please verify the uploaded data or fix this floor plan through the replace tab using the same PDF or deleting and reuploading the data'
            });
            this.setState({ isClientEnable: false});
            return;
        }

        // GET A CLEAN LIST OF VALID MARKERS
        const validMarkerList = existingMarkers.filter(e => !markerIds.includes(e.id));
        // GET POSITIONS OF INVALID MARKERS
        const invalidMarkerPositions = markerIds.map(e => existingMarkers.findIndex(j => e === j.id));
        
        // COMPOSE THE USER NOTIFICATION
        let { warningText } = this.state;
        
        if (warningText === '') {
            warningText = 'Some markers were found out of pdf bounds, they were relocated and highlighted in BRIGHT BLUE please confirm their locations:';
        }
        invalidMarkerPositions.forEach(marker => {
            // FIND A RANDOM MARKER WITHIN THE VALID ONES
            const randomMarker = Math.floor(Math.random() * validMarkerList.length);
            const randomCoords = validMarkerList[randomMarker].coordinates;
            
            existingMarkers[marker].coordinates = randomCoords;
            existingMarkers[marker].isWrong = true;

            // ADD NAMES TO THE USER NOTIFICATION
            warningText += '\n' + `- ${existingMarkers[marker].name}`;
        });
        
        // NOTIFY BUGSNAG
        Bugsnag.notify((new Error(`Floorplan - ${floorplanId} Markers out of bounds`)), event => {
            event.severity = 'warning';
            event.context = 'Marker operations';
            event.addMetadata('floorplan', {
                floorplan_id: floorplanId,
                floorplan_name: floorplanName,
                invalid_markers: markerIds,
                was_fixable: true
            })
        });
        
        // NOTIFY THE USER AND MOVE FORWARD WITH THE PROCESS
        this.setState({
            isWarning: true,
            warningText
        }, () => {
            this.addExistingMarkers(previewFilePromise, existingMarkers);   
        });
    }

    fixFailedMarkers = async (failedMarkers: AdobeMarkerSchema[]) => {
        const { fetchFloorplanMarkers, floorplanId, floorplanName } = this.props;

        if (!fetchFloorplanMarkers) {

            Bugsnag.notify((new Error(`Floorplan - ${floorplanId} Failed markers where not able to be restored`)), event => {
                event.severity = 'warning';
                event.context = 'Marker operations';
                event.addMetadata('floorplan', {
                    floorplan_id: floorplanId,
                    floorplan_name: floorplanName,
                    invalid_markers: failedMarkers
                })
            });

            return;
        }

        const allMarkers = await fetchFloorplanMarkers();
        if (!allMarkers) {

            Bugsnag.notify((new Error(`Floorplan - ${floorplanId} Failed markers where not able to be restored`)), event => {
                event.severity = 'warning';
                event.context = 'Marker operations';
                event.addMetadata('floorplan', {
                    floorplan_id: floorplanId,
                    floorplan_name: floorplanName,
                    invalid_markers: failedMarkers
                })
            });

            return;
        }

        failedMarkers.forEach(m => {
            const dbMarker = allMarkers.find(e => e.name.includes(m.bodyValue.replace(/^\s+|\s+$/g, "")));

            if (!dbMarker) {
                Bugsnag.notify((new Error(`Floorplan - ${floorplanId} Failed marker was not found on DB`)), event => {
                    event.severity = 'warning';
                    event.context = 'Marker operations';
                    event.addMetadata('floorplan', {
                        floorplan_id: floorplanId,
                        floorplan_name: floorplanName,
                        invalid_marker: m
                    })
                });
                return;       
            }
            m.creator.name = dbMarker.marker_id;
            this.updateFailedMarker(m);
        });
    }

    onFinishMarkerOperations = () => {
        const { partialOperations, updatedFile, duplicateMarkers } = this.state;
        const { onFinishMarkerOperations } = this.props;

        this.setState({
            isFullScreen: false
        });


        if ( onFinishMarkerOperations && updatedFile && duplicateMarkers.length <= 0 ) {
            onFinishMarkerOperations(partialOperations, updatedFile);
        }
    }

    addNewMarker = (markerData: any) => {
        const { floorplanId } = this.props;
        const { partialOperations } = this.state;
        if (markerData.bodyValue === '') {
            return;
        }
        const newItem: MarkerOperation = {
            floorplan_id: floorplanId,
            marker_id: markerData.id,
            verb: 'ADD',
            name: markerData.bodyValue.replace(/^\s+|\s+$/g, ""),
            coordinates: markerData.target.selector.boundingBox.join()
        };
        partialOperations.push(newItem);
        this.updateMarkerId(markerData);
        this.setState({partialOperations});
    }

    deleteAddedMarker = (markerData: any) => {
        const { partialOperations } = this.state;
        const addedMarkerIndex = partialOperations.findIndex(e => e.marker_id === markerData.id);
        if (addedMarkerIndex < 0) {
            return;
        }
        partialOperations.splice(addedMarkerIndex, 1);
        this.setState({partialOperations});
    }

    deleteMarker = (markerData: any) => {
        const { floorplanId } = this.props;
        const { partialOperations } = this.state;
        if (markerData.id === '') {
            return;
        }
        const newItem: MarkerOperation = {
            floorplan_id: floorplanId,
            marker_id: markerData.id,
            verb: 'DELETE',
            name: markerData.bodyValue
        };
        partialOperations.push(newItem);
        this.setState({partialOperations});
    }

    updateMarkerId = (markerData: AdobeMarkerSchema) => {
        const { filePreviewPromise, ignoredMarkers } = this.state;
        const updatedMarker = {
            ...markerData,
            creator: {
                ...MarkerModel.creator,
                name: markerData.id
            }
        };
        const oldIgnoredState = [...ignoredMarkers];
        ignoredMarkers.push(updatedMarker);
        // SET THE ignoredMarkers TO PREVENT OVERLAPS WITH THE EVENT LISTENERS
        this.setState({ignoredMarkers}, () => {
            filePreviewPromise.then((adobeViewer: { getAnnotationManager: () => Promise<any>; }) => {
                adobeViewer.getAnnotationManager().then(annotationManager => {
                    // DELETE THE ANNOTATION BECAUSE WE CANT UPDATE THE CREATOR FIELD WITH THE API
                    // API OPERATIONS TRIGGER EVENT LISTENERS
                    annotationManager.deleteAnnotations({ annotationIds: [markerData.id]})
                        .then (()=> {
                            // NOW ADD IT BACK WITH THE UPDATED DATA
                            annotationManager.addAnnotations([updatedMarker])
                            .then (()=> {
                                // ALL READY CLEAR THE ignoredMarkers
                                this.setState({ignoredMarkers: oldIgnoredState});
                            })
                            .catch((error: any) => console.error(error));
                        })
                        .catch((error: any) => console.error(error));
                });
            });
        });
    }

    updateFailedMarker = (updatedMarker: AdobeMarkerSchema) => {
        const { filePreviewPromise, ignoredMarkers } = this.state;
        const oldIgnoredState = [...ignoredMarkers];
        ignoredMarkers.push(updatedMarker);
        // SET THE ignoredMarkers TO PREVENT OVERLAPS WITH THE EVENT LISTENERS
        this.setState({ignoredMarkers}, () => {
            filePreviewPromise.then((adobeViewer: { getAnnotationManager: () => Promise<any>; }) => {
                adobeViewer.getAnnotationManager().then(annotationManager => {
                    // DELETE THE ANNOTATION BECAUSE WE CANT UPDATE THE CREATOR FIELD WITH THE API
                    // API OPERATIONS TRIGGER EVENT LISTENERS
                    annotationManager.deleteAnnotations({ annotationIds: [updatedMarker.id]})
                        .then (()=> {
                            updatedMarker.id = updatedMarker.creator.name;
                            // NOW ADD IT BACK WITH THE UPDATED DATA
                            annotationManager.addAnnotations([updatedMarker])
                            .then (()=> {
                                // ALL READY CLEAR THE ignoredMarkers
                                this.setState({ignoredMarkers: oldIgnoredState});
                            })
                            .catch((error: any) => console.error(error));
                        })
                        .catch((error: any) => console.error(error));
                });
            });
        });
    }

    updateExistingOperation = (opIndex: number, markerData: any) => {
        const { partialOperations } = this.state;
        const operationToUpdate = partialOperations[opIndex];
        operationToUpdate.name = markerData.bodyValue.replace(/^\s+|\s+$/g, "");
        operationToUpdate.coordinates = markerData.target.selector.boundingBox.join();
        this.setState({partialOperations});
    }

    addUpdateMarker = (markerData: any) => {
        const { floorplanId } = this.props;
        const { partialOperations } = this.state;
        if (markerData.bodyValue === '') {
            return;
        }
        const newItem: MarkerOperation = {
            floorplan_id: floorplanId,
            marker_id: markerData.id,
            verb: 'UPDATE',
            name: markerData.bodyValue.replace(/^\s+|\s+$/g, ""),
            coordinates: markerData.target.selector.boundingBox.join()
        };
        partialOperations.push(newItem);
        this.setState({partialOperations});
    }

    closeThinClient = () => {
        this.setState({
            viewSDKClient: null,
            filePreviewPromise: null,
            updatedFile: null,
            statusCheckAttempts: -1,
            thinClientStatus: ThinClientStatusCheck.NotInitialized
        });
    }

    onFullscreenClick = () => {
        const { isFullScreen } = this.state;
        this.setState({
            isFullScreen: !isFullScreen
        });
    }

    onCloseWarning = () => {
        this.setState({
            isWarning: false,
            warningText: ''
        });
    }

    public render() {
        const {
            className,
            floorplanName,
            classes,
            isJSONReady
        } = this.props;
        const {
            partialOperations,
            isFullScreen,
            isSavingChanges,
            isWarning,
            warningText,
            duplicateMarkers,
            isClientEnable,
            thinClientStatus
        } = this.state;

        return (
            <div className={`${classes.root} ${className} ${isFullScreen ? classes.fullScreenContainer : ''}`} data-testid="thin-client">
                <div className={classes.container}>
                    <div className={`${isFullScreen ? classes.buttonContainerFullscreen : classes.buttonContainer} ${classes.flexItem}`}>
                        <Button
                            className={classes.buttonFullscreen}
                            onClick={this.onFullscreenClick}
                            data-testid="thin-client-fullscreen"
                        >
                            {
                                isFullScreen ? 
                                    <FullscreenExit />
                                :
                                    <Fullscreen />
                            }
                        </Button>
                        <Typography
                            variant='h6'
                            className={`${classes.heading} ${classes.ellipsis}`}
                        >
                            {floorplanName} floorplan
                        </Typography>
                        <div className={`${classes.flexItem} ${classes.thinClientFlag}`}>
                            <InfoOutlined className={`${isJSONReady ? classes.readyItem : classes.warningItem}`} />
                            <small className={`${isJSONReady ? classes.readyText : classes.warningText}`}>
                                { isJSONReady ? 'This PDF is using JSON instead of XFDF' : 'This PDF is still using XFDF' }
                            </small>
                        </div>
                        {
                            partialOperations.length > 0 &&
                            <Button
                                variant="contained"
                                color="primary"
                                className={classes.buttonExport}
                                onClick={this.onFinishMarkerOperations}
                                disabled={isSavingChanges || duplicateMarkers.length > 0}
                            >
                                { isSavingChanges ? 'Processing...' : 'Save Changes' }
                            </Button>
                        }
                    </div>
                    { isWarning && 
                        <Alert severity="warning" className={classes.warningToast} onClose={this.onCloseWarning}>
                            <AlertTitle>Warning</AlertTitle>
                            {warningText}
                        </Alert>
                    }
                    <Paper className={classes.paperFrame}>
                        {
                            isClientEnable ?
                                <div id="adobe-thin-client-holder" className={`${classes.container} ${classes.frameContainer}`} />
                            :
                                <Typography className={classes.subtitle} variant="h5">
                                    {
                                        thinClientStatus !== ThinClientStatusCheck.Failed ? FLOORPLAN_FAILURE_MESSAGE : FLOORPLAN_CRASH_MESSAGE
                                    }
                                </Typography>
                        }
                    </Paper>
                </div>
            </div>
        )
    };
}

const MUIComponent = withStyles(styles)(ThinClient);
export {MUIComponent as ThinClient}