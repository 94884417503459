import * as React from 'react';
import {
    createStyles,
    Theme,
    WithStyles,
    withStyles
} from '@material-ui/core/styles';
import { AccountFull } from '../../models/account';
import { RoleDescription } from '../../models/role';
import { Account, Folder } from '../../shared/domain';
import { RoleRegisteredChanges } from '../../features/scheduling-permissions/scheduling-permissions';
import { formIntent } from '../../features/teams/teams';
import {
    Button,
    Divider,
    Grid,
    ListItemText,
    Paper,
    TextField,
    Typography
} from '@material-ui/core';
import {
    SimpleList,
    SimpleListItem
} from '../../shared/components/simple-list';
import { Autocomplete } from '@material-ui/lab';
import { FolderActionIntent } from '../team-form/team-form';
import { FolderLeafTreePicker } from '../folder-leaf-tree-picker';
import { CCSpinner } from '../../shared/components/cc-spinner';
import { UserFull } from '../../models/user';

const styles = (theme: Theme) =>
    createStyles({
        container: {
            marginLeft: '3em',
            marginTop: '1.5em',
            display: 'flex',
            flexDirection: 'column'
        },
        heading: {
            fontWeight: 700,
            marginBottom: '0.5em'
        },
        ellipsis: {
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden'
        },
        saveButton: {
            marginTop: '1em',
            marginBottom: '1em'
        },
        detailBorder: {
            border: '1px solid #e1e1e1',
            overflowY: 'scroll',
            height: '100%',
            width: '32.5em'
        },
        formContainer: {
            display: 'flex',
            flexDirection: 'column'
        },
        textField: {
            padding: '1em',
            width: '100%',
            '& label': {
                fontWeight: 'bolder',
                padding: '1em 0 0 1em',
                fontSize: '1.4em',
                color: 'rgba(0, 0, 0, 0.8) !important'
            },
            '&::before': {
                borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                borderBottomStyle: 'solid'
            }
        },
        dividerForm: {
            margin: '1em'
        },
        listHeader: {
            flex: 'unset',
            paddingLeft: '1em',
            '& span': {
                fontWeight: 'bolder'
            }
        },
        tooltipText: {
            marginTop: '0.5em',
            fontStyle: 'italic',
            color: 'gray',
            marginBottom: '1em',
            marginLeft: '1em'
        },
        list: {
            flex: '1 0',
            overflow: 'auto',
            boxShadow: 'none'
        },
        selectInput: {
            margin: '1em'
        },
        formLoader: {
            height: '25em',
            background: 'lightgray',
            marginTop: '1em'
        },
        errorHeading: {
            fontWeight: 500,
            marginBottom: '0.5em',
            marginTop: '0em',
            color: theme.palette.error.main,
            fontSize: 'medium',
            textAlign: 'center',
            whiteSpace: 'break-spaces'
        }
    });

interface Props extends WithStyles<typeof styles> {
    className?: string;
    rolePermissions: string[];
    currentAccount: AccountFull;
    accountUsers: UserFull[];
    isAccountUsersLoading: boolean;
    pickedRole: RoleDescription;
    isCreate: boolean;
    accountFolders: Folder[];
    setFormChanges?: () => void;
    onFormSubmit?: (
        formRole: RoleDescription,
        registerChanges: RoleRegisteredChanges[],
        intent: formIntent
    ) => void;
}

interface States {
    isFormInitializing: boolean;
    roleInForm: RoleDescription | null;
    canSubmit: boolean;
    registeredChanges: RoleRegisteredChanges[];
    autoSelectPermValue: string | null;
    autoSelectPermKey: string;
    autoSelectUserValue: UserFull | null;
    autoSelectUserKey: string;
}

class ScheduleRoleForm extends React.Component<Props, States> {

    generateRandomKey = () => {
        const randomKey = Math.random().toString(36).substring(7);
        return randomKey;
    };

    state: States = {
        isFormInitializing: true,
        roleInForm: {} as RoleDescription,
        canSubmit: false,
        registeredChanges: [] as RoleRegisteredChanges[],
        autoSelectPermValue: null,
        autoSelectPermKey: this.generateRandomKey(),
        autoSelectUserValue: null,
        autoSelectUserKey: this.generateRandomKey(),
    };

    componentDidMount() {
        const { pickedRole } = this.props;
        if (!pickedRole) {
            return;
        }

        const roleInForm = { ...pickedRole };
        roleInForm.members = [];
        roleInForm.users.forEach(e => {
            roleInForm.members.push(e.user_doc_id);
        });

        this.setState({ roleInForm }, () => {
            this.setState({ isFormInitializing: false });
        });
    }

    formatFieldName = (teamKey: string) => {
        const lowerWords = teamKey.split('_').join(' ');
        return lowerWords.replace(/(^\w{1})|(\s+\w{1})/g, letter =>
            letter.toUpperCase()
        );
    };

    getCurrentPermissionList = () => {
        const { roleInForm } = this.state;

        if (!roleInForm || !roleInForm.permissions_list) {
            return [];
        }

        return roleInForm.permissions_list.map(e => {
            return new SimpleListItem(e, e);
        });
    };

    handleStringChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        key: string
    ) => {
        const { setFormChanges } = this.props;
        const { roleInForm, registeredChanges } = this.state;

        if (!roleInForm) {
            return;
        }

        const changeValue = e.target.value;
        roleInForm.name = changeValue;
        let existingChange = registeredChanges.find(e => e.field === key);
        if (!existingChange) {
            registeredChanges.push({
                label: this.formatFieldName(key),
                field: key,
                value: changeValue
            });
        } else {
            existingChange.value = changeValue;
        }

        if (setFormChanges) {
            setFormChanges();
        }

        this.setState({ roleInForm, canSubmit: true, registeredChanges });
    };

    onPermissionAdd = (e: React.ChangeEvent<{}>, value: string | null) => {
        if (!value) {
            return;
        }

        const { setFormChanges } = this.props;
        const { roleInForm } = this.state;

        if (!roleInForm) {
            return;
        }

        if (setFormChanges) {
            setFormChanges();
        }

        roleInForm.permissions_list.push(value);
        this.setState({ roleInForm, canSubmit: true });
    };

    onPermissionDelete = (listItem: SimpleListItem) => {
        const { setFormChanges } = this.props;
        const { roleInForm } = this.state;

        if (!roleInForm) {
            return;
        }

        const delIndex = roleInForm.permissions_list.indexOf(listItem.id);
        roleInForm.permissions_list.splice(delIndex, 1);

        if (setFormChanges) {
            setFormChanges();
        }

        this.setState({ roleInForm, canSubmit: true });
    };

    getAvailablePermissions = () => {
        const { rolePermissions } = this.props;
        const { roleInForm } = this.state;

        if (!roleInForm || Object.keys(roleInForm).length === 0) {
            return [];
        }

        const availablePermissions = rolePermissions
            .filter(e => !roleInForm.permissions_list.includes(e))
            .map(e => e.replace('_', ' '));
        return availablePermissions as string[];
    };

    getAvailableRouteUsers = () => {
        const { accountUsers, isAccountUsersLoading } = this.props;
        const { roleInForm } = this.state;

        if (!roleInForm || isAccountUsersLoading || !roleInForm.members) {
            return [];
        }

        return accountUsers.filter(e => !roleInForm.members.includes(e.user_id));
    };

    onFolderChange = (
        folderKey: string,
        folderList: string[],
        intent: FolderActionIntent
    ): string[] => {
        const { accountFolders, setFormChanges } = this.props;
        const { roleInForm, registeredChanges } = this.state;

        if (!roleInForm) {
            return folderList;
        }

        const existingFolderIds = roleInForm.folder_ids;
        let clearedFolderList = folderList;
        const removedFolders: string[] = [];
        if (intent === 'ADD') {
            clearedFolderList = folderList
                .map(e => {
                    if (roleInForm.folder_ids.includes(e)) {
                        removedFolders.push(e);
                        return '';
                    }

                    return e;
                })
                .filter(e => e !== '');

            clearedFolderList = Array.from(
                new Set([...clearedFolderList, ...existingFolderIds])
            );
        } else {
            clearedFolderList = Array.from(
                new Set([
                    ...existingFolderIds.filter(
                        e => !clearedFolderList.includes(e)
                    )
                ])
            );
        }

        roleInForm.folder_ids = clearedFolderList;
        let existingChange = registeredChanges.find(e => e.field === 'folders');
        if (!existingChange) {
            registeredChanges.push({
                label: 'Folders',
                field: 'folders',
                value: folderList
                    .map(e => accountFolders.find(j => j.id === e)?.name)
                    .join(', ')
            });
        } else {
            existingChange.value = folderList
                .map(e => accountFolders.find(j => j.id === e)?.name)
                .join(', ');
        }

        if (setFormChanges) {
            setFormChanges();
        }

        this.setState({ roleInForm, registeredChanges, canSubmit: true });
        return removedFolders;
    };

    onUserChange = (e: React.ChangeEvent<{}>, value: UserFull | null) => {
        if (!value) {
            return;
        }

        const { setFormChanges } = this.props;
        const { roleInForm, registeredChanges } = this.state;
        if (!roleInForm) {
            return;
        }

        roleInForm.members.push(value.user_id)
        let existingChange = registeredChanges.find(e => e.field === 'users');
        if (!existingChange) {
            registeredChanges.push({
                label: 'Users',
                field: 'users',
                value: roleInForm.members.join(', ')
            });
        } else {
            existingChange.value = roleInForm.members.join(', ');
        }

        if (setFormChanges) {
            setFormChanges();
        }

        this.setState({ roleInForm, registeredChanges, canSubmit: true });
    };

    deleteUserInRole = (listItem: SimpleListItem) => {
        const { setFormChanges } = this.props;
        const { roleInForm, registeredChanges } = this.state;
        if (!roleInForm) {
            return;
        }

        const delIndex = roleInForm.members.indexOf(listItem.id)
        roleInForm.members.splice(delIndex, 1);

        let existingChange = registeredChanges.find(e => e.field === 'users');
        if (!existingChange) {
            registeredChanges.push({
                label: 'Users',
                field: 'users',
                value: roleInForm.members.join(', ')
            });
        } else {
            existingChange.value = roleInForm.members.join(', ');
        }

        if (setFormChanges) {
            setFormChanges();
        }

        this.setState({ roleInForm, registeredChanges, canSubmit: true });
    }
    
    getUsersInRoleList = () => {
        const { accountUsers } = this.props;
        const { roleInForm } = this.state;

        if (!roleInForm || !roleInForm.members) {
            return [];
        }

        return roleInForm.members.map(e => {
            const user = accountUsers.find(j => j.user_id === e);
            return new SimpleListItem(e, user ? user.email : 'USER NOT FOUND', e)
        });
    };

    onSubmitChanges = () => {
        const { onFormSubmit, isCreate } = this.props;

        if (!onFormSubmit) {
            return;
        }

        const { roleInForm, registeredChanges } = this.state;

        if (!roleInForm) {
            return;
        }

        let existingChange = registeredChanges.find(
            e => e.field === 'permissions'
        );
        if (!existingChange) {
            registeredChanges.push({
                label: 'Permissions',
                field: 'permissions',
                value: roleInForm.permissions_list.join(', ')
            });
        } else {
            existingChange.value = roleInForm.permissions_list.join(', ');
        }

        onFormSubmit(
            roleInForm,
            registeredChanges,
            isCreate ? 'CREATE' : 'EDIT'
        );
    };

    checkIfRoleIsValid = () => {
        const { roleInForm } = this.state;

        if (!roleInForm) {
            return true;
        }

        if (!roleInForm.name) {
            return true;
        }

        if (
            !roleInForm.permissions_list ||
            roleInForm.permissions_list.length <= 0
        ) {
            return true;
        }

        if (!roleInForm.folder_ids || roleInForm.folder_ids.length <= 0) {
            return true;
        }

        if (!roleInForm.members || Object.keys(roleInForm.members).length <= 0) {
            return true;
        }

        return false;
    };

    getInvalidRoleMessage = () => {
        const { roleInForm } = this.state;

        if (!roleInForm) {
            return '';
        }
        let message = '';

        if (!roleInForm.name) {
            message += 'Missing Name' + '\n';
        }

        if (
            !roleInForm.permissions_list ||
            roleInForm.permissions_list.length <= 0
        ) {
            message += 'Permissions cannot be empty' + '\n';
        }

        if (!roleInForm.folder_ids || roleInForm.folder_ids.length <= 0) {
            message += 'Folders cannot be empty' + '\n';
        }

        if (!roleInForm.members || Object.keys(roleInForm.members).length <= 0) {
            message += 'Users cannot be empty' + '\n';
        }

        return message;
    };

    clearAutocompleteSelector = (evt: React.FocusEvent<HTMLDivElement> | undefined, isPermSelector: boolean) => {
        if (isPermSelector) {
            this.setState({ 
                autoSelectPermValue: null,
                autoSelectPermKey: this.generateRandomKey()
            });
            return;
        }
        this.setState({ 
            autoSelectUserValue: null,
            autoSelectUserKey: this.generateRandomKey()
        });
        return;
    };

    public render() {
        const {
            classes,
            isCreate,
            accountFolders,
            currentAccount,
            pickedRole,
            isAccountUsersLoading
        } = this.props;

        const { 
            canSubmit,
            roleInForm,
            isFormInitializing,
            autoSelectPermValue,
            autoSelectPermKey,
            autoSelectUserValue,
            autoSelectUserKey
        } = this.state;

        const currentAccountFolder = new Account(
            currentAccount.account_id,
            currentAccount.name,
            currentAccount.structures,
            new Date(),
            0
        );

        return (
            <CCSpinner
                loading={isFormInitializing}
                className={classes.formLoader}
            >
                {roleInForm && (
                    <div className={classes.container} data-testid="role-form">
                        <Typography
                            variant="h6"
                            className={`${classes.heading} ${classes.ellipsis}`}
                        >
                            {isCreate
                                ? 'Create New Role'
                                : `${pickedRole.name} details`}
                        </Typography>
                        {canSubmit && (
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={this.onSubmitChanges}
                                className={classes.saveButton}
                                disabled={this.checkIfRoleIsValid()}
                                data-testid="save-form-button"
                            >
                                {isCreate ? 'Create Role' : 'Save Changes'}
                            </Button>
                        )}
                        {this.checkIfRoleIsValid() && (
                            <Typography
                                variant="h6"
                                className={classes.errorHeading}
                            >
                                {this.getInvalidRoleMessage()}
                            </Typography>
                        )}
                        <Paper
                            className={`${classes.formContainer} ${classes.detailBorder}`}
                        >
                            {!isCreate && (
                                <TextField
                                    className={classes.textField}
                                    label="ID"
                                    defaultValue={roleInForm.id}
                                    disabled={true}
                                    multiline={true}
                                />
                            )}
                            <TextField
                                className={classes.textField}
                                label="Name"
                                defaultValue={roleInForm.name}
                                multiline={true}
                                placeholder="Name"
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true
                                }}
                                onChange={e =>
                                    this.handleStringChange(e, 'name')
                                }
                            />
                            <Divider className={classes.dividerForm} />
                            <ListItemText
                                className={classes.listHeader}
                                primary="Permissions"
                            />
                            <ListItemText
                                className={classes.tooltipText}
                                primary="Select which actions users will the users be able to perfom"
                            />
                            <Grid container spacing={1} alignItems="center">
                                <Grid item xs={1} />
                                <Grid item xs={11}>
                                    <SimpleList
                                        className={classes.list}
                                        items={this.getCurrentPermissionList()}
                                        noItemsLabel={'No Permissions Selected'}
                                        canDeleteListItem={true}
                                        keepItemSelected={false}
                                        onDeleteItemClick={
                                            this.onPermissionDelete
                                        }
                                    />
                                    <Autocomplete
                                        key={autoSelectPermKey}
                                        className={classes.selectInput}
                                        options={this.getAvailablePermissions()}
                                        getOptionLabel={option => option}
                                        renderInput={params => (
                                            <TextField
                                                {...params}
                                                label="Add a permission"
                                                variant="standard"
                                            />
                                        )}
                                        onChange={(e, pickedValue) =>
                                            this.onPermissionAdd(e, pickedValue)
                                        }
                                        data-testid="permission-form-input"
                                        value={autoSelectPermValue}
                                        clearOnBlur={true}
                                        blurOnSelect={true}
                                        onBlur={(e) => this.clearAutocompleteSelector(e, true)}
                                    />
                                </Grid>
                            </Grid>
                            <Divider className={classes.dividerForm} />
                            <ListItemText
                                className={classes.listHeader}
                                primary="Folders"
                            />
                            <ListItemText
                                className={classes.tooltipText}
                                primary="Select which folders will have access to this permissions"
                            />
                            <Grid container spacing={1} alignItems="center">
                                <Grid item xs={1} />
                                <Grid item xs={11}>
                                    <FolderLeafTreePicker
                                        currentAccount={currentAccountFolder}
                                        folderIds={roleInForm.folder_ids}
                                        folderKey="placeholder"
                                        excludeFolderIds={[]}
                                        onFolderchange={this.onFolderChange}
                                        accountFolders={accountFolders}
                                    />
                                </Grid>
                            </Grid>
                            <Divider className={classes.dividerForm} />
                            <ListItemText
                                className={classes.listHeader}
                                primary="Users"
                            />
                            <Grid container spacing={1} alignItems="center">
                                <Grid item xs={1} />
                                <Grid item xs={11}>
                                    <SimpleList
                                        className={classes.list}
                                        items={this.getUsersInRoleList()}
                                        noItemsLabel={"No users found in role"}
                                        canDeleteListItem={true}
                                        keepItemSelected={false}
                                        isLoading={isAccountUsersLoading}
                                        onDeleteItemClick={this.deleteUserInRole}
                                    />
                                    <Divider className={classes.dividerForm} />
                                    <Autocomplete
                                        id="route-user-search"
                                        key={autoSelectUserKey}
                                        options={this.getAvailableRouteUsers()}
                                        getOptionLabel={(option) => option.email}
                                        renderInput={(params) => <TextField {...params} label="Search for a user" variant="standard" />}
                                        onChange={(e, pickedValue) => this.onUserChange(e, pickedValue)}
                                        data-testid="route_user_search_form_input"
                                        value={autoSelectUserValue}
                                        clearOnBlur={true}
                                        blurOnSelect={true}
                                        onBlur={(e) => this.clearAutocompleteSelector(e, true)}
                                    />
                                </Grid>
                            </Grid>
                            <Divider className={classes.dividerForm} />
                        </Paper>
                    </div>
                )}
            </CCSpinner>
        );
    }
}

const MUIComponent = withStyles(styles)(ScheduleRoleForm);
export { MUIComponent as ScheduleRoleForm };
