import { AxiosResponse } from 'axios';
import { LoginCredentials } from '../models/login-credentials';
import { UserIdentifiers } from '../providers/user.provider';
import { HttpClient } from '../shared/utils';
import { COOKIE_NAMES, LocalStorageUtil } from './../utils/local-storage';

// let singletonInstance: HttpConnectorAdapter;
let credentials: LoginCredentials | undefined;
let userIdentifiers: UserIdentifiers | undefined;

interface StandardHTTPHeaders {
    'X-Username': string;
    Authorization: string;
    userId: string;
    authToken: string;
}

const cookieRefreshEndpoint = `${process.env.REACT_APP_BASE_API_URL}login/cookie_refresh`;

export class HttpConnectorAdapter {
    // static post(url: string, data?: any, headers?: any): Promise<AxiosResponse> {
    //     return HttpClient.post(url, data, headers);
    // }

    // static get(url: string, params?: any, headers?: any): Promise<AxiosResponse> {
    //     return HttpClient.get(url, params, headers);
    // }

    static checkCookieExpire(username: string, password: string, headers?: any): Promise<StandardHTTPHeaders | AxiosResponse> {
        return new Promise((resolve, reject) => {

            const user_cookie = LocalStorageUtil.getItem(COOKIE_NAMES.USER_IDENTIFIERS) as UserIdentifiers;

            const httpHeaders = {
                'X-Username': username,
                Authorization: `Basic ${password}`,
                userId: user_cookie.user_id,
                authToken: user_cookie.auth_token
            };
            const mergedHeaders = Object.assign({}, httpHeaders, headers);

            // TEST ENVIRONMENT CHECK
            if (user_cookie.expiry === undefined) {
                return resolve(mergedHeaders);
            }

            const now = new Date().getTime();
            if (user_cookie.expiry >= now) {
                return resolve(mergedHeaders);
            }
    
            return HttpClient.get(cookieRefreshEndpoint, undefined, mergedHeaders)
                .then(response => {
                    const statusCode = response.status;

                    if (statusCode === 200) {
                        const { auth_token } = response.data;
                        user_cookie.auth_token = auth_token;
                        user_cookie.expiry = new Date().getTime() + 12 * 3600000;
                        LocalStorageUtil.setItem(COOKIE_NAMES.USER_IDENTIFIERS, user_cookie);
                        userIdentifiers = user_cookie;
                        httpHeaders.authToken = auth_token;
                        return resolve(httpHeaders);
                    }

                    if (statusCode === 401) {
                        return reject(response);
                    }
                })
                .catch(error => {
                    console.error(error);
                    return reject(error);
                });
        });
    }

    static getWithCredentials(url: string, params?: any, headers?: any): Promise<AxiosResponse> {
        if (!credentials) {
            const ERROR = 'HttpConnectorAdapter Error: Credentials are not set.';
            // tslint:disable-next-line:no-console
            console.error(ERROR);
            throw new Error(ERROR);
        }

        if (!userIdentifiers) {
            const ERROR = 'HttpConnectorAdapter Error: Identifiers are not set.';
            // tslint:disable-next-line:no-console
            console.error(ERROR);
            throw new Error(ERROR);   
        }

        const {
            password,
            username
        } = credentials;

        return new Promise ((resolve, reject) => {
            this.checkCookieExpire(username, password, headers).then(authHeaders => {
                return resolve(HttpClient.get(url, params, authHeaders));
            }).catch(error => {
                return reject(error);
            })
        });
    }

    static getFileWithCredentials(url: string, params?: any, headers?: any): Promise<AxiosResponse> {
        if (!credentials) {
            const ERROR = 'HttpConnectorAdapter Error: Credentials are not set.';
            // tslint:disable-next-line:no-console
            console.error(ERROR);
            throw new Error(ERROR);
        }

        if (!userIdentifiers) {
            const ERROR = 'HttpConnectorAdapter Error: Identifiers are not set.';
            // tslint:disable-next-line:no-console
            console.error(ERROR);
            throw new Error(ERROR);   
        }

        const {
            password,
            username
        } = credentials;

        return new Promise ((resolve, reject) => {
            this.checkCookieExpire(username, password, headers).then(authHeaders => {
                return resolve(HttpClient.getBlob(url, params, authHeaders));
            }).catch(error => {
                return reject(error);
            })
        });
    }

    static putWithCredentials(url: string, params?: any, headers?: any): Promise<AxiosResponse> {
        if (!credentials) {
            const ERROR = 'HttpConnectorAdapter Error: Credentials are not set.';
            // tslint:disable-next-line:no-console
            console.error(ERROR);
            throw new Error(ERROR);
        }

        if (!userIdentifiers) {
            const ERROR = 'HttpConnectorAdapter Error: Identifiers are not set.';
            // tslint:disable-next-line:no-console
            console.error(ERROR);
            throw new Error(ERROR);   
        }

        const {
            password,
            username
        } = credentials;

        return new Promise ((resolve, reject) => {
            this.checkCookieExpire(username, password, headers).then(authHeaders => {
                return resolve(HttpClient.put(url, params, authHeaders));
            }).catch(error => {
                return reject(error);
            })
        });
    }

    static deleteWithCredentials(url: string, params?: any, headers?: any): Promise<AxiosResponse> {
        if (!credentials) {
            const ERROR = 'HttpConnectorAdapter Error: Credentials are not set.';
            // tslint:disable-next-line:no-console
            console.error(ERROR);
            throw new Error(ERROR);
        }

        if (!userIdentifiers) {
            const ERROR = 'HttpConnectorAdapter Error: Identifiers are not set.';
            // tslint:disable-next-line:no-console
            console.error(ERROR);
            throw new Error(ERROR);   
        }

        const {
            password,
            username
        } = credentials;

        return new Promise ((resolve, reject) => {
            this.checkCookieExpire(username, password, headers).then(authHeaders => {
                return resolve(HttpClient.delete(url, params, authHeaders));
            }).catch(error => {
                return reject(error);
            })
        });
    }

    static postWithCredentials(url: string, data?: any, headers?: any): Promise<AxiosResponse> {
        if (!credentials) {
            const ERROR = 'HttpConnectorAdapter Error: Credentials are not set.';
            // tslint:disable-next-line:no-console
            console.error(ERROR);
            throw new Error(ERROR);
        }

        if (!userIdentifiers) {
            const ERROR = 'HttpConnectorAdapter Error: Identifiers are not set.';
            // tslint:disable-next-line:no-console
            console.error(ERROR);
            throw new Error(ERROR);   
        }

        const {
            password,
            username
        } = credentials;

        return new Promise ((resolve, reject) => {
            this.checkCookieExpire(username, password, headers).then(authHeaders => {
                return resolve(HttpClient.post(url, data, authHeaders));
            }).catch(error => {
                return reject(error);
            })
        });
    }

    static postFileWithCredentials(url: string, data?: any, headers?: any): Promise<AxiosResponse> {
        if (!credentials) {
            const ERROR = 'HttpConnectorAdapter Error: Credentials are not set.';
            // tslint:disable-next-line:no-console
            console.error(ERROR);
            throw new Error(ERROR);
        }

        if (!userIdentifiers) {
            const ERROR = 'HttpConnectorAdapter Error: Identifiers are not set.';
            // tslint:disable-next-line:no-console
            console.error(ERROR);
            throw new Error(ERROR);   
        }

        const {
            password,
            username
        } = credentials;

        return new Promise ((resolve, reject) => {
            this.checkCookieExpire(username, password, headers).then(authHeaders => {
                return resolve(HttpClient.postBlob(url, data, authHeaders));
            }).catch(error => {
                return reject(error);
            })
        });
    }

    static setCredentials (newCredentials: LoginCredentials | undefined) {
        credentials = newCredentials;
    }

    static setUserIdentifiers (currentuserIdentifiers: UserIdentifiers | undefined) {
        userIdentifiers = currentuserIdentifiers;
    }
}