// tslint:disable: ordered-imports
import {
    createStyles,
    WithStyles,
    withStyles
} from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import {
    HourglassEmptyOutlined,
    CancelOutlined,
    CloudDownload,
    ChevronLeftOutlined
} from '@material-ui/icons';
import * as React from 'react';
import { SimpleListItem } from '../../shared/components/simple-list';
import { SimpleListPagination } from '../../shared/components/simple-list-pagination';
import { PageBoundary } from '../../shared/components/simple-grid-pagination';
import {
    Typography,
    Button,
    IconButton
} from '@material-ui/core';
import { RunningTasks } from '../../providers/job.provider';

const styles = (theme: Theme) => createStyles({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    heading: {
        fontWeight: 700,
        marginBottom: '0.5em',
        marginTop: '1em'
    },
    simpleList: {
        overflow: 'auto',
        width: '100%'
    },
    ellipsis: {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden'
    },
    waitingItem: {
        color: '#ffc409',
        marginRight: '0.5em',
        fontSize: '2em'
    },
    errorItem: {
        color: '#eb445a',
        marginRight: '0.5em',
        fontSize: '2em'
    },
    readyItem: {
        color: '#2dd36f',
        marginRight: '0.5em',
        fontSize: '2em'
    },
    buttonClear: {
        float: 'right'
    },
});

interface Props extends WithStyles<typeof styles> {
    className: string;
    tasks: RunningTasks[];
    rowsPerPage: number;
    onTaskClick?: (task: RunningTasks) => void;
    taskItemsOffset: number;
    onTaskDataPageOverBoundary?: (boundary: PageBoundary, nextPage: number) => Promise<void>;
    onTaskClear?: () => void;
    listName?: string;
    onDrawerClose?: () => void;
};

class RunningTasksList extends React.Component<Props> {
    public static defaultProps = {
    };

    onTaskClick = (listItem: SimpleListItem) => {
        const {tasks, onTaskClick} = this.props;
        if(!tasks) {
            return;
        }

        const currentTask = tasks.find((task:RunningTasks) => {
            return task.task_id === listItem.id;
        });
        if (!currentTask) {
            return;
        }

        if (onTaskClick) {
            onTaskClick(currentTask);
        }
    }

    public render() {
        const {
            className,
            classes,
            tasks,
            rowsPerPage,
            taskItemsOffset,
            listName,
            onTaskDataPageOverBoundary,
            onTaskClear,
            onDrawerClose
        } = this.props;

        const taskListItems = tasks ? tasks.map((task: RunningTasks) => {
            const newItem: SimpleListItem = {
                id: task.task_id,
                label: task.export_name,
                secondaryLabel: (task.status === 102) ? `Processing . . . ${task.elapsedTime} Seconds` : (task.status === 200) ? `Done after ${task.elapsedTime} Seconds` : `Error ${task.elapsedTime} Seconds`,
                icon: (task.status === 102) ? 
                    <HourglassEmptyOutlined className={ classes.waitingItem } /> :
                    (task.status === 200) ?
                    <CloudDownload className={ classes.readyItem }/> :
                    <CancelOutlined className={ classes.errorItem }/>,
                hasProgress: true,
                progress: task.progress
            }
            return newItem;
        }): [];

        return (
            <div className={`${classes.root} ${className}`} data-testid="runningTasks">
                <div className={classes.container}>
                    { listName && 
                        <Typography
                        variant='h6'
                        className={`${classes.heading} ${classes.ellipsis}`}
                        >
                            {
                                onDrawerClose &&
                                <IconButton onClick={onDrawerClose}>
                                    <ChevronLeftOutlined />
                                </IconButton>
                            }
                            {listName}
                        </Typography>
                    }
                    <div className={`${classes.container} ${classes.simpleList}`}>
                        <SimpleListPagination
                            className={classes.simpleList}
                            keepItemSelected={false}
                            items={taskListItems}
                            isLoading={false}
                            rowsPerPage={rowsPerPage}
                            totalItems={tasks.length}
                            offset={taskItemsOffset}
                            onPageOverBoundary={onTaskDataPageOverBoundary}
                            onListItemClick={this.onTaskClick}
                        />
                    </div>
                    { onTaskClear && 
                        <Button
                            variant="contained"
                            color="secondary"
                            className={classes.buttonClear}
                            onClick={onTaskClear}
                        >
                            Clear finished tasks
                        </Button>
                    }
                </div>
            </div>
        )
    };
}

const MUIComponent = withStyles(styles)(RunningTasksList);
export {MUIComponent as RunningTasksList}