import {
    Button,
    Card,
    CardContent,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    Icon,
    IconButton,
    InputLabel,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Paper,
    Radio,
    RadioGroup,
    Select,
    Step,
    StepLabel,
    Stepper,
    Switch,
    TextField,
    Typography
} from '@material-ui/core';
import {
    createStyles,
    WithStyles,
    withStyles,
    Theme
} from '@material-ui/core/styles';
import {
    AddCircle,
    CancelRounded,
    ControlCameraRounded,
    CreateRounded,
    DeleteRounded,
    PhotoCameraRounded
} from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import _ from 'lodash';
import * as React from 'react';
import { AccountFull } from '../../models/account';
import {
    DisplayWidgets,
    InputWidgets,
    LabelDisplayWidget,
    LabelInputWidget,
    Module,
    PhotoTakerDisplayWidget,
    PhotoTakerInputWidget,
    PickerInputWidget,
    TextFieldDisplayWidget,
    TextFieldInputWidget,
    ToggleInputWidget
} from '../../models/module';
import { ModuleGroup } from '../../models/module-group';
import { SimpleList, SimpleListItem } from '../../shared/components/simple-list';
import { IconDictionary } from '../../svg/sprites';
import BaseTile from '../../svg/base-title.svg';
import { CCSpinner } from '../../shared/components/cc-spinner';
import { v4 as uuidv4 } from 'uuid';
import { UserModule } from '../../models/user-module';
import {
    ModuleTemplate,
    ModuleTemplateDisplayWidgets,
    ModuleTemplateInputWidgets,
    UserLabelInputWidget,
    UserPhotoTakerInputWidget,
    UserPickerInputWidget,
    UserTextFieldInputWidget
} from '../../models/module-template';
import { DragDropContext, Droppable, Draggable, DropResult } from "react-beautiful-dnd";

type ReportStatus = null | "Closed" | "In Progress" | "Scheduled" | "Unresolved" | "Urgent";
type InputType = "None" | "label" | "textfield" | "photo_taker" | "picker" | "toggle";
type DefaultReportType = "bad" | "good"

interface BasicInfoFields {
    name: string;
    feedback_title: string;
    feedback_message: string;
    default_status: ReportStatus;
    status_value: DefaultReportType;
    icon: string;
    account: AccountFull | null;
    is_user_module: boolean;
}

interface FormFields {
    id: string;
    type: InputType;
    widget_id: string;
    text?: string;
    placeholder?: string;
    items?: string[];
    items_labels?: string[];
    selected_item?: number;
    display_text?: string;
    new_item?: string;
    new_item_label?: string;
    is_selected_item?: boolean;
    selected_item_index?: number;
    default_value?: string;
    is_required: boolean;
    is_summary: boolean;
    formated_widget_id: string;
    formated_display_text: string;
}

enum FormSteps {
    BasicInfo = 0,
    FormInputs,
    Confirmation
}

const FORM_STEPS = [
    {
        id: FormSteps.BasicInfo,
        label: 'Basic Information'
    },
    {
        id: FormSteps.FormInputs,
        label: 'Form Fields'
    },
    {
        id: FormSteps.Confirmation,
        label: 'Confirmation'
    },
]

const styles = (theme: Theme) => createStyles({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    stepsHolder: {
        paddingBottom: 0
    },
    stepsContentHolder: {
        padding: '1em',
        height: '100%',
        minWidth: '70em'
    },
    heading: {
        fontWeight: 700,
        marginBottom: '0.5em',
        marginTop: '0.5em'
    },
    ellipsis: {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden'
    },
    formPaper: {
        padding: '0.5em'
    },
    textField: {
        padding: '1em',
        width: '100%',
        '& label': {
            fontWeight: 'bolder',
            padding: '1em 0 0 1em',

            fontSize: '1.4em',
            color: 'rgba(0, 0, 0, 0.8) !important'
        },
        '&::before': {
            borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
            borderBottomStyle: 'solid'
        }
    },
    selectInput: {
        width: '96%',
        display: 'block',
        margin: 'auto',
        paddingBottom: '1em',
        '& :nth-child(2)': {
            width: '100%'
        }
    },
    autocompleteInput: {
        width: '96%',
        display: 'block',
        margin: 'auto',
    },
    imageIcon: {
        display: 'flex',
        height: 'inherit',
        width: 'inherit'
    },
    continueButton: {
        width: '20%',
        marginTop: '2.5em',
        float: 'right',
        marginBottom: '2em',
    },
    backButton: {
        width: '20%',
        marginTop: '2.5em',
        float: 'left',
        marginBottom: '2em',
    },
    gridHolder: {
        width: '70em'
    },
    itemList: {
        flex: '1 0',
        overflow: 'auto',
        minWidth: '20em'
    },
    listContainer: {
        margin: '1em 1em 2em 1em',
        '& p': {
            margin: '0',
            marginBottom: '1em'
        }
    },
    inputHolder: {
        margin: '0 1em',
        fontWeight: 'bold'
    },
    addInputButton: {
        width: '100%',
        display: 'block',
        margin: 'auto'
    },
    checkContainer: {
        marginBottom: '1em'
    },
    userModuleCheck: {
        marginTop: '1em',
        marginLeft: '1.5em'
    },
    markerCard: {
        position: 'relative',
        borderRadius: 0
    },
    markerCardBody: {
        marginLeft: '2em',
        padding: '0.5em !important',
        "& h6": {
            fontSize: '1.3em'
        }
    },
    deleteFieldIcon: {
        position: 'absolute',
        right: 0,
    },
    editFieldIcon: {
        position: 'absolute',
        right: '9%',
    },
    dragHolder: {
        position: 'absolute',
        color: 'rgba(0, 0, 0, 0.54)',
        left: '2%',
        cursor: 'move',
        height: '70%'
    },
    emptyCard: {
        textAlign: 'center',
        padding: '2em 0'
    },
    toggleInputHolder: {
        border: '1px solid lightgray',
        marginTop: '0.5em',
        borderRadius: '0.5em'
    },
    wysiwygGrid: {
        border: '0.2em solid #e92d06',
    },
    titleHolder: {
        width: '100%',
        textAlign: 'center',
        '& p': {
            color: 'gray',
            marginTop: '0'
        }
    },
    reportBadge: {
        position: 'relative',
        height: '10em',
        width: '10em',
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    baseTile: {
        position: 'absolute',
        zIndex: -1
    },
    badgeInputLabel : {
        position: 'absolute',
        top: 0,
        fontSize: '0.8em',
        fontWeight: 500,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%'
    },
    badgeInputText: {
        textAlign: 'center',
        marginBottom: '2em',
        margin: '0 0.3em 2em 0.6em',
        overflowWrap: 'break-word',
        maxWidth: '8em'
    },
    baseIconSize: {
        width: '6em',
        height: '6em',
        '& img': {
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto'
        }
    },
    pickedIconSize: {
        width: '3em',
        height: '3em'
    },
    wysiwygFormHolder: {
        width: '100%',
        padding: '0.5em'
    },
    portalLabel: {
        textAlign: 'center',
        marginTop: '1.5em',
    },
    widgetTextFieldWrite: {
        flex: 1,
        minHeight: '3em',
        width: '100%'
    },
    widgetTextFieldInput: {
        flex: 1,
        alignItems: 'stretch',
        padding: '0.5em',
        minHeight: 0,
        lineHeight: 1.5
    },
    widgetToggle2WaysContainer:{
        display: 'block',
        width: 'fit-content',
        margin: 'auto'
    },
    widgetToggle2WaysColorBase: {
        '&$widgetToggle2WaysColorChecked': {
            color: theme.ccPalette.cc_colors.solid.ccLeftsilhouette.main,
            '& + $widgetToggle2WaysColorBar': {
                backgroundColor: theme.ccPalette.cc_colors.solid.ccLeftsilhouette.main,
            },
        },
    },
    widgetToggle2WaysColorBar: {},
    widgetToggle2WaysColorChecked: {},
    widgetToggleMulti: {
        display: 'block',
        width: 'fit-content',
        margin: 'auto'
    },
    processingSpinner: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        background: 'rgba(113, 113, 113, 0.47)',
        zIndex: 9
    },
    topLevelPaper: {
        position: 'relative'
    },
    warningLabel: {
        color: '#eb445a',
        margin: '1.5em',
        fontWeight: 'bold',
        fontStyle: 'italic',
    },
    cancelButton: {
        color: '#eb445a'
    },
    formInputLoader: {
        position: 'relative',
        width: '100%',
        height: '5em',
        background: 'rgba(113, 113, 113, 0.47)',
    }
});

interface Props extends WithStyles<typeof styles> {
    className?: string;
    accounts: AccountFull[];
    canEdit: boolean;
    isPreview: boolean;
    isFormProcessing: boolean;
    pickedModuleGroup?: ModuleGroup;
    pickedModule?: Module;
    onFormSubmit?: (formModuleGroup: ModuleGroup, formModule: Module, userModule?: UserModule, moduleTemplate?: ModuleTemplate) => void;
    onFormChanges?: () => void;
}

interface States {
    isProcessing: boolean;
    moduleGroupForm: ModuleGroup;
    moduleForm: Module;
    currentStage: FormSteps;
    basicInfoFields: BasicInfoFields;
    formInputFields: FormFields[];
    newInput: FormFields;
    orderingArray: string[];
    isInputEdit: boolean;
    isLoadingEditInput: boolean;
}

class ModuleGroupBuilder extends React.Component<Props, States> {

    state: States = {
        isProcessing: false,
        moduleGroupForm: {} as ModuleGroup,
        moduleForm: {} as Module,
        currentStage: FormSteps.BasicInfo,
        basicInfoFields: {
            name: '',
            feedback_title: 'Thank you for your report!',
            feedback_message: 'Thanks!',
            default_status: null,
            status_value: "bad",
            icon: '',
            account: {} as AccountFull,
            is_user_module: false
        },
        formInputFields: [],
        newInput: {
            id: uuidv4(),
            type: 'None',
            widget_id: '',
            text: '',
            placeholder: '',
            items: [],
            items_labels: [],
            selected_item: 0,
            display_text: '',
            new_item: '',
            new_item_label: '',
            is_selected_item: false,
            selected_item_index: -1,
            is_summary: false,
            is_required: false,
            formated_widget_id: '',
            formated_display_text: '',
            default_value: ''
        },
        orderingArray: [],
        isInputEdit: false,
        isLoadingEditInput: false
    }

    componentDidMount() {
        const { isPreview } = this.props;

        if (isPreview) {
            this.formatPickedModuleGroup();
        }
    }

    formatPickedModuleGroup() {
        const { pickedModule, pickedModuleGroup, accounts } = this.props;

        if (!pickedModule || !pickedModuleGroup) {
            return;
        }

        this.setState({isProcessing: true});
        const basicInfo: BasicInfoFields = {
            name: pickedModuleGroup.name,
            feedback_title: pickedModuleGroup.autovalue!.defaults.feedback_title,
            feedback_message: pickedModuleGroup.autovalue!.defaults.feedback_message,
            default_status: pickedModule.autovalue.defaults.status as ReportStatus,
            status_value: pickedModule.autovalue.defaults.value as DefaultReportType,
            icon: pickedModuleGroup.module_tag ? pickedModuleGroup.module_tag : 'default',
            account: pickedModuleGroup.account_id ? accounts.find(e => e.account_id === pickedModuleGroup.account_id)! : null,
            is_user_module: false
        }

        const formInputFields = pickedModule.report_content.widgets.map(e => {
            const defaultItem: FormFields = {
                id: '',
                type: 'None',
                widget_id: '',
                is_required: false,
                is_summary: false,
                formated_display_text: '',
                formated_widget_id: ''
            }
            switch (e.type) {
                case 'label':
                    defaultItem.text = e.properties.text;
                    defaultItem.type = 'label';
                    return defaultItem;
                case 'textfield':
                    defaultItem.placeholder = e.properties.placeholder;
                    defaultItem.type = 'textfield';
                    return defaultItem;
                case 'photo_taker':
                    defaultItem.type = 'photo_taker';
                    return defaultItem;
                case 'picker':
                    defaultItem.placeholder = 'Choose an option';
                    defaultItem.items = e.properties.picker_values;
                    defaultItem.type = 'picker';
                    return defaultItem;
                case 'toggle':
                    defaultItem.items = e.properties.segment_values;
                    defaultItem.items_labels = e.properties.segment_titles;
                    defaultItem.selected_item_index = e.properties.selected_index as unknown as number;
                    defaultItem.type = 'toggle';
                    return defaultItem;
                default:
                    return defaultItem;
            }
        });

        setTimeout(() => {
            this.setState({
                basicInfoFields: basicInfo,
                formInputFields,
                isProcessing: false,
                currentStage: FormSteps.Confirmation
            });
        }, 500);
    }

    formatFieldName = (teamKey: string) => {
        const lowerWords = teamKey.split("_").join(" ");
        return lowerWords.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
    }

    checkSelectedIcon = () => {
        const { basicInfoFields } = this.state;
        
        if (!basicInfoFields) {
            return;
        }

        const selectedIcon = IconDictionary.find(e => e.label === basicInfoFields.icon);

        if (!selectedIcon) {
            return;
        }

        return selectedIcon.icon;
    }

    handleBasicInfoStringChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, key: string) => {
        const { basicInfoFields } = this.state;
        const { onFormChanges } = this.props;

        if (!basicInfoFields) {
            return;
        }

        _.set(basicInfoFields, key, e.target.value);
        this.setState({basicInfoFields}, () => {
            if (onFormChanges) {
                onFormChanges();
            }
        });
    }

    handleDefaultStatusChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        const { basicInfoFields } = this.state;

        basicInfoFields.default_status = event.target.value as ReportStatus;
        basicInfoFields.status_value = basicInfoFields.default_status === 'Closed' ? 'good' : 'bad';

        this.setState({basicInfoFields});
    }

    handleIconChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        const { basicInfoFields } = this.state;

        basicInfoFields.icon = event.target.value as string;

        this.setState({basicInfoFields});
    }

    onAccountSelection = (e: React.ChangeEvent<{}>, value: AccountFull | null) => {
        if (!value) {
            return;
        }

        const { basicInfoFields } = this.state;

        if (!basicInfoFields) {
            return;
        }

        basicInfoFields.account = value;
        this.setState({basicInfoFields});
    }

    checkNextStageButton = () => {
        const { currentStage } = this.state;
        let flag = false;

        switch (currentStage) {
            case FormSteps.BasicInfo:
                const { basicInfoFields } = this.state;
                flag = !basicInfoFields.default_status ||
                    basicInfoFields.icon === '' ||
                    basicInfoFields.name === '' ||
                    !basicInfoFields.account ||
                    !basicInfoFields.account.account_id;
                break;
        
            case FormSteps.FormInputs:
                const { formInputFields } = this.state;
                flag = formInputFields.length <= 0 || 
                    formInputFields.filter(e => e.type !== 'label').length <= 0 ||
                    !formInputFields.find(e => e.is_summary);
                break;
            default:
                break;
        }

        return flag;
    }

    goNextStageButton = () => {
        const { currentStage } = this.state;

        switch (currentStage) {
            case FormSteps.BasicInfo:
                this.setState({ currentStage: FormSteps.FormInputs });
                break;
            case FormSteps.FormInputs:
                this.setState({ currentStage: FormSteps.Confirmation });
                break;
            case FormSteps.Confirmation:
                this.onFormSubmit();
                break;
            default:
                break;
        }

    }

    handleInputTypeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        const { newInput } = this.state;

        if (!newInput) {
            return;
        }
        
        newInput.type = event.target.value as InputType;

        this.setState({newInput});
    }

    handleNewInputTextChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, key: string) => {
        const { newInput } = this.state;

        if (!newInput) {
            return;
        }

        _.set(newInput, key, e.target.value);
        this.setState({newInput});
    }

    onDeletePickerItem = (listItem: SimpleListItem) => {
        const { newInput } = this.state;

        if (!newInput || !newInput.items) {
            return;
        }

        const itemToDelete = newInput.items.findIndex(e => e === listItem.id);
        if (itemToDelete < 0) {
            return;
        }

        newInput.items.splice(itemToDelete, 1);
        this.setState({ newInput });
    }

    onDeleteToggleItem = (listItem: SimpleListItem) => {
        const { newInput } = this.state;

        if (
            !newInput ||
            !newInput.items ||
            !newInput.items_labels
        ) {
            return;
        }

        const itemToDelete = newInput.items.findIndex(e => e === listItem.id);
        if (itemToDelete < 0) {
            return;
        }

        newInput.items.splice(itemToDelete);
        newInput.items_labels.splice(itemToDelete, 1);
        this.setState({ newInput });
    }

    onAddPickerItem = () => {
        const { newInput } = this.state;

        if (!newInput || !newInput.items || !newInput.new_item || newInput.new_item === '') {
            return;
        }

        newInput.items.push(newInput.new_item);
        newInput.new_item = '';
        this.setState({ newInput });
    }

    handlePickerEnterInput = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            this.onAddPickerItem();
            event.preventDefault;
        }
    }

    onAddToggleItem = () => {
        const { newInput } = this.state;

        if (
            !newInput ||
            !newInput.items ||
            !newInput.items_labels ||
            !newInput.new_item ||
            !newInput.new_item_label ||
            newInput.new_item === '' ||
            newInput.new_item_label === ''
        ) {
            return;
        }
        
        newInput.items.push(newInput.new_item);
        newInput.items_labels.push(newInput.new_item_label);
        
        if (newInput.is_selected_item) {
            newInput.selected_item_index = newInput.items.length - 1;
        }

        newInput.new_item = '';
        newInput.new_item_label = '';
        newInput.is_selected_item = false;
        this.setState({ newInput });
    }

    checkAddInputButton = () => {
        const { newInput } = this.state;

        let flag = false;

        switch (newInput.type) {
            case 'label':
                flag = newInput.text === '';
                break;
            case 'textfield':
                flag =  newInput.placeholder === '' ||
                        (newInput.is_summary && newInput.default_value === '');
                break;
            case 'photo_taker':
                flag =  false;
                break;
            case 'picker':
                flag =  !newInput.items ||
                        newInput.items.length <= 1 ||
                        (newInput.is_summary && !newInput.is_required);
                break;
            case 'toggle':
                flag =  !newInput.items ||
                        !newInput.items_labels ||
                        newInput.items.length <= 0 ||
                        newInput.items_labels.length <= 0 ||
                        newInput.items.length < 2 ||
                        newInput.items_labels.length < 2 ||
                        newInput.items_labels.length !== newInput.items.length ||
                        (newInput.is_summary && !newInput.is_required);
                break;
            default:
                break;
        }

        return flag;
    }

    onSummaryChange = () => {
        const { newInput } = this.state;

        if (!newInput) {
            return;
        }

        newInput.is_summary = !newInput.is_summary;
        this.setState({ newInput });
    }

    onRequiredChange = () => {
        const { newInput } = this.state;

        if (!newInput) {
            return;
        }

        newInput.is_required = !newInput.is_required;
        this.setState({ newInput });
    }

    onSelectedItemChange = () => {
        const { newInput } = this.state;

        if (!newInput) {
            return;
        }

        newInput.is_selected_item = !newInput.is_selected_item;
        this.setState({ newInput });   
    }

    checkExistingDefaultItem = () => {
        const { newInput } = this.state;
        
        return newInput.selected_item_index! >= 0;
    }

    checkExistingSummary = () => {
        const { formInputFields, isInputEdit, newInput } = this.state;

        const existingSummary = formInputFields.findIndex(e => e.is_summary === true);

        if (existingSummary < 0) {
            return false;
        }

        if (isInputEdit) {
            const originalInput = formInputFields.findIndex(e => e.id === newInput.id);

            return originalInput !== existingSummary;
        }

        return true;
    }

    onAddInputToForm = () => {
        const { formInputFields, newInput, orderingArray, isInputEdit } = this.state;

        if (!formInputFields || !newInput) {
            return;
        }

        const checkCorrectInput = this.checkAddInputButton();
        if (checkCorrectInput) {
            return;
        }

        if (isInputEdit) {
            const editIndex = formInputFields.findIndex(e => e.id === newInput.id);

            if (editIndex < 0) {
                return;
            }

            formInputFields[editIndex] = newInput;
        } else {
            newInput.widget_id = newInput.type === 'photo_taker' ? 'photo' : `${newInput.type} ${formInputFields.filter(e => e.type === newInput.type).length + 1}`;
            newInput.formated_widget_id = this.formatWidgetId(newInput.widget_id);
            newInput.formated_display_text = `$${this.formatWidgetId(newInput.widget_id)}`;
            formInputFields.push(newInput);
            orderingArray.push(newInput.id);
        }

        this.setState({
            formInputFields,
            newInput: {
                id: uuidv4(),
                type: 'None',
                widget_id: '',
                text: '',
                placeholder: '',
                items: [],
                items_labels: [],
                selected_item: 0,
                display_text: '',
                new_item: '',
                new_item_label: '',
                is_selected_item: false,
                selected_item_index: -1,
                is_summary: false,
                is_required: false,
                formated_widget_id: '',
                formated_display_text: '',
                default_value: ''
            },
            isInputEdit: false
        });
    }

    deleteFormInput = (index: number) => {
        const { formInputFields, orderingArray } = this.state;

        if (!formInputFields || !orderingArray) {
            return;
        }

        formInputFields.splice(index, 1);
        orderingArray.splice(index, 1);
        this.setState({formInputFields});
    }

    editFormInput = (index: number) => {
        const { formInputFields } = this.state;

        if (!formInputFields) {
            return;
        }

        const editInput = formInputFields[index];

        this.setState({
            newInput: {...editInput},
            isInputEdit: true,
            isLoadingEditInput: true
        }, () => {
            setTimeout(() => {
                this.setState({isLoadingEditInput: false})
            }, 500);
        });
    }

    getWysiwygComponents = () => {
        const { classes } = this.props;
        const { formInputFields, orderingArray } = this.state;

        if (!formInputFields || formInputFields.length <= 0) {
            return;
        }

        const sortedFields = [...formInputFields.sort((a,b) => orderingArray.indexOf(a.id) - orderingArray.indexOf(b.id))];
        const components = sortedFields.map(e => {
            switch (e.type) {
                case "label":
                    return (
                        <p className={classes.portalLabel}>
                            {e.text}
                        </p>
                    );
                case "textfield":
                    return (
                        <TextField
                            className={classes.widgetTextFieldWrite}
                            InputProps={{
                                className: classes.widgetTextFieldInput
                            }}
                            multiline={true}
                            rows="10"
                            placeholder={e.placeholder}
                            margin="normal"
                            variant="outlined"
                        />
                    );
                case "photo_taker":
                    return (
                        <Button
                            variant="outlined"
                            size="small"
                            color="secondary"
                            className={classes.addInputButton}
                            startIcon={<PhotoCameraRounded />}
                        >
                            Take a Photo
                        </Button>
                    );
                case "picker":
                    return (
                        <Select 
                            className={classes.selectInput}
                            placeholder={e.placeholder}
                            value="placeholder"
                            defaultValue="placeholder"
                        >
                            <MenuItem value="placeholder" disabled={true}>
                                {e.placeholder}
                            </MenuItem>
                            {e.items!.map(i => {
                                return (<MenuItem value={i}>
                                    {i}
                                </MenuItem>)
                            })}
                        </Select>
                    );
                case "toggle":
                    if (e.items!.length <= 2) {
                        return (
                            <div
                                className={classes.widgetToggle2WaysContainer}
                            >
                                <FormLabel focused={false} component="label">{e.items_labels![1]}</FormLabel>
                                <Switch
                                    classes={{
                                        switchBase: classes.widgetToggle2WaysColorBase,
                                        checked: classes.widgetToggle2WaysColorChecked,
                                        track: classes.widgetToggle2WaysColorBar,
                                    }}
                                />
                                <FormLabel focused={false} component="label">{e.items_labels![0]}</FormLabel>
                            </div>
                        )
                    } else {
                        return (
                            <RadioGroup
                                className={classes.widgetToggleMulti}
                                row={true}
                            >
                                { e.items!.map((value: string, index: number) => {
                                    return (
                                        <FormControlLabel
                                            value={value}
                                            control={<Radio color="secondary" />}
                                            label={e.items_labels![index]}
                                            labelPlacement="end"
                                        />
                                    );
                                })
                                }
                            </RadioGroup>
                        );
                    }
                default:
                    return '';
                    break;
            }
        });

        return components;
    }

    goBackStageButton = () => {
        const { currentStage } = this.state;

        switch (currentStage) {
            case FormSteps.FormInputs:
                    this.setState({ currentStage: FormSteps.BasicInfo });
                break;
            case FormSteps.Confirmation:
                this.setState({ currentStage: FormSteps.FormInputs });
            break;
            default:
                break;
        }
    }

    formatWidgetId = (widgetId: string) => {
        return widgetId.split(" ").join("_");
    }

    onFormSubmit = () => {
        const { basicInfoFields, formInputFields, orderingArray } = this.state;
        const { onFormSubmit } = this.props;

        if (!onFormSubmit) {
            return;
        }

        if (!basicInfoFields || !formInputFields || formInputFields.length === 0) {
            return;
        }

        if (formInputFields.length !== orderingArray.length) {
            return;
        }

        const formModuleGroup: ModuleGroup = {
            _id: '',
            account_id: basicInfoFields.account!.account_id,
            autovalue: {
                defaults: {
                    feedback_message: basicInfoFields.feedback_message,
                    feedback_title: basicInfoFields.feedback_title,
                    status: basicInfoFields.default_status!
                },
                meta_handler: []
            },
            channels: ['public'],
            data_visualizations: [],
            group_id: '',
            group_type: 'atomic',
            module_group_id: '',
            module_icon: `https://s3.amazonaws.com/crowd-comfort-module-icons/${basicInfoFields.icon}.png`,
            module_tag: basicInfoFields.icon,
            meta: {},
            modules: [],
            name: basicInfoFields.name,
            prompt: basicInfoFields.name,
            timestamp: '',
            type: 'module_group'
        };

        const responseValues = {} as Module['report_content']['response_values'];
        const sortedFields = [...formInputFields.sort((a,b) => orderingArray.indexOf(a.id) - orderingArray.indexOf(b.id))];
        sortedFields.forEach(e => {
            if (e.type !== 'label') {
                responseValues[e.formated_widget_id] = e.default_value ? e.default_value : '';
            }
        });

        const formModule: Module = {
            _id: '',
            account_id: basicInfoFields.account!.account_id,
            autovalue: {
                defaults: {
                    feedback_title: basicInfoFields.feedback_title,
                    status: basicInfoFields.default_status!,
                    value: basicInfoFields.status_value
                },
                handlers: [],
                meta_handler: []
            },
            channels: ['public'],
            module_id: '',
            module_tag: basicInfoFields.icon,
            module_title: basicInfoFields.name,
            module_type: '',
            name: basicInfoFields.name,
            summary: `$${sortedFields.find(e => e.is_summary)!.formated_widget_id}`,
            timestamp: '',
            type: 'module',
            report_content: {
                autosubmit: "true",
                display: "visible",
                required_fields: sortedFields.filter(e => e.is_required).map(e => {
                    return e.formated_widget_id;
                }),
                response_values: responseValues,
                widgets: sortedFields.map(e => {
                    switch (e.type) {
                        case 'label':
                            return {
                                properties: {
                                    text: e.text!
                                },
                                type: 'label'
                            } as LabelInputWidget;
                        case 'textfield':
                            return {
                                properties: {
                                    editable: 'true',
                                    placeholder: e.placeholder,
                                    widget_id: e.formated_widget_id
                                },
                                type: 'textfield'
                            } as TextFieldInputWidget;
                        case 'photo_taker':
                            return {
                                properties: {
                                    widget_id: e.formated_widget_id
                                },
                                type: 'photo_taker'
                            } as PhotoTakerInputWidget;
                        case 'picker':
                            return {
                                properties: {
                                    components: 1,
                                    picker_values: e.items!,
                                    widget_id: e.formated_widget_id
                                },
                                type: 'picker'
                            } as PickerInputWidget;
                        case 'toggle':
                            return {
                                properties: {
                                    segment_titles: e.items_labels!,
                                    segment_values: e.items!,
                                    selected_index: `${e.selected_item_index!}`,
                                    widget_id: e.formated_widget_id
                                },
                                type: 'toggle'
                            } as ToggleInputWidget;
                        default:
                            break;
                    }
                }) as InputWidgets[]
            },
            single_view: {
                display_values: {},
                widgets: sortedFields.map(e => {
                    switch (e.type) {
                        case 'label':
                            return {
                                properties: {
                                    text: e.text
                                },
                                type: 'label'
                            } as LabelDisplayWidget;
                        case 'textfield':
                            return {
                                properties: {
                                    editable: 'false',
                                    text_align: 'center',
                                    text: `$${e.formated_widget_id}`
                                },
                                type: 'textfield'
                            } as TextFieldDisplayWidget;
                        case 'photo_taker':
                            return {
                                properties: {
                                    height: 300,
                                    width: 200,
                                    image: `$${e.formated_widget_id}`
                                },
                                type: 'photo'
                            } as PhotoTakerDisplayWidget;
                        case 'picker':
                            return {
                                properties: {
                                    editable: 'false',
                                    text_align: 'center',
                                    text: `$${e.formated_widget_id}`
                                },
                                type: 'textfield'
                            } as TextFieldDisplayWidget;
                        case 'toggle':
                            return {
                                properties: {
                                    editable: 'false',
                                    text_align: 'center',
                                    text: `$${e.formated_widget_id}`
                                },
                                type: 'textfield'
                            } as TextFieldDisplayWidget;
                        default:
                            break;
                    }
                }) as DisplayWidgets[]
            }
        }

        if (!basicInfoFields.is_user_module) {
            onFormSubmit(formModuleGroup, formModule);
            return;
        }

        const formUserModule: UserModule = {
            _id: '',
            account_id: basicInfoFields.account!.account_id,
            module_group_id: '',
            module_id: '',
            module_template_id: '',
            module_tag: basicInfoFields.icon,
            module_title: "Report a problem",
            name: basicInfoFields.name,
            response_values: {},
            timestamp: '',
            type: 'user_module',
            user_module_id: ''
        };
        
        const formModuleTemplate: ModuleTemplate = {
            _id: '',
            account_id: basicInfoFields.account!.account_id,
            module_template_id: '',
            single_view: {
                display_values: {},
                widgets: sortedFields.map(e => {
                    switch (e.type) {
                        case 'photo_taker':
                            return {
                                properties: {
                                    height: 300,
                                    width: 200,
                                    image: `$${e.formated_widget_id}`
                                },
                                type: 'photo'
                            } as PhotoTakerDisplayWidget;
                        case 'label':
                            return {
                                properties: {
                                    text: e.text!
                                },
                                type: 'label'
                            } as LabelDisplayWidget;
                        default:
                            return {
                                properties: {
                                    editable: 'false',
                                    text_align: 'center',
                                    text: `$${e.formated_widget_id}`
                                },
                                type: 'textfield'
                            } as TextFieldDisplayWidget;
                            break;
                    }
                }) as ModuleTemplateDisplayWidgets[]
            },
            widgets: sortedFields.map(e => {
                switch (e.type) {
                    case 'photo_taker':
                        return {
                            hint: 'Add Photo',
                            icon: 'photo.png',
                            label: 'Upload a picture',
                            mandatory: e.is_required,
                            type: 'photo_taker',
                            widget_id: e.formated_widget_id
                        } as UserPhotoTakerInputWidget;
                    case 'picker':
                        return {
                            components: 1,
                            label: e.placeholder!,
                            mandatory: e.is_required,
                            picker_values: e.items!,
                            type: "picker",
                            widget_id: e.formated_widget_id,
                        } as UserPickerInputWidget;
                    case 'textfield':
                        return {
                            hint: e.placeholder!,
                            label: e.placeholder!,
                            mandatory: e.is_required,
                            type: 'textfield',
                            widget_id: e.formated_widget_id

                        } as UserTextFieldInputWidget;
                    case 'label':
                        return {
                            text: e.text!,
                            type: "label"
                        } as UserLabelInputWidget;
                    default:
                        break;
                }
            }) as ModuleTemplateInputWidgets[],
            summary: `$${sortedFields.find(e => e.is_summary)!.formated_widget_id}`,
            timestamp: '',
            type: 'module_template'
        }

        onFormSubmit(formModuleGroup, formModule, formUserModule, formModuleTemplate);
    }

    reArrangeInputs = (evt: DropResult) => {
        const { orderingArray } = this.state;

        if (!evt.destination) {
            return;
        }

        const result = Array.from(orderingArray);
        const [removed] = result.splice(evt.source.index, 1);
        result.splice(evt.destination.index, 0, removed);
        this.setState({ orderingArray: result });
    }

    onUserModuleChange = () => {
        const { basicInfoFields } = this.state;

        if (!basicInfoFields) {
            return;
        }

        basicInfoFields.is_user_module = !basicInfoFields.is_user_module;
        this.setState({ basicInfoFields });
    }

    cancelInputEdit = () => {
        this.setState({
            newInput: {
                id: uuidv4(),
                type: 'None',
                widget_id: '',
                text: '',
                placeholder: '',
                items: [],
                items_labels: [],
                selected_item: 0,
                display_text: '',
                new_item: '',
                new_item_label: '',
                is_selected_item: false,
                selected_item_index: -1,
                is_summary: false,
                is_required: false,
                formated_widget_id: '',
                formated_display_text: '',
                default_value: ''
            },
            isInputEdit: false
        });
    }

    public render() {
        const {
            className,
            classes,
            accounts,
            isFormProcessing,
            isPreview
        } = this.props;
        const {
            currentStage,
            basicInfoFields,
            newInput,
            formInputFields,
            isProcessing,
            isInputEdit,
            isLoadingEditInput
        } = this.state;

        const pickerInputItemsList = newInput.items ? newInput.items.map(e => {
            return new SimpleListItem(e, e);
        }) : [];
        const toggleInputItemsList = (newInput.items && newInput.items_labels) ? newInput.items.map((e, i) => {
            return new SimpleListItem(e, newInput.items_labels![i], `${e}${i === newInput.selected_item_index ? ', Default Selected' : ''}`)
        }): [];

        return (
            <div className={`${classes.root} ${className}`} data-testid="module-group-builder">
                <div className={`${classes.container}`}>
                    <Paper className={classes.topLevelPaper}>
                        <CCSpinner
                            loading={isFormProcessing || isProcessing}
                            size={70}
                            className={classes.processingSpinner}
                        />
                        {
                            !isPreview &&    
                                <Stepper activeStep={currentStage} className={classes.stepsHolder} alternativeLabel>
                                    { FORM_STEPS.map((step) => (
                                        <Step key={step.id}>
                                            <StepLabel>{step.label}</StepLabel>
                                        </Step>
                                    ))}
                                </Stepper>
                        }
                        <div className={classes.stepsContentHolder}>
                            {
                                currentStage === FormSteps.BasicInfo ? 
                                <React.Fragment data-testid="basic-info-step">
                                    <Typography
                                        variant='h6'
                                        className={`${classes.heading} ${classes.ellipsis}`}
                                    >
                                        Module Group Basic information
                                    </Typography>
                                    <TextField
                                        className={classes.textField}
                                        id="module_group_name"
                                        label="Name * "
                                        placeholder="Module Group Name"
                                        defaultValue={basicInfoFields.name}
                                        onChange={e => this.handleBasicInfoStringChange(e, "name")}
                                        multiline={true}
                                        variant="outlined"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                    <FormControl className={classes.selectInput}>
                                        <InputLabel id="module_group_default_value">Default report status value *</InputLabel>
                                        <Select
                                            labelId="module_group_default_value"
                                            id="module_group_default_value_select"
                                            data-testid="module_group_default_value_select"
                                            value={basicInfoFields.default_status}
                                            onChange={this.handleDefaultStatusChange}
                                        >
                                            <MenuItem value="Closed">Closed</MenuItem>
                                            <MenuItem value="In Progress">In Progress</MenuItem>
                                            <MenuItem value="Scheduled">Scheduled</MenuItem>
                                            <MenuItem value="Unresolved">Unresolved</MenuItem>
                                            <MenuItem value="Urgent">Urgent</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <FormControl className={classes.selectInput}>
                                        <InputLabel id="module_group_icon">Icon *</InputLabel>
                                        <Select
                                            labelId="module_group_icon"
                                            id="module_group_icon_select"
                                            data-testid="module_group_icon_select"
                                            value={basicInfoFields.icon}
                                            onChange={this.handleIconChange}
                                        >
                                            {IconDictionary.map(i => {
                                                return <MenuItem value={i.label}>
                                                    <ListItemIcon>
                                                        <Icon className={classes.imageIcon}>
                                                            <img src={i.icon}/>
                                                        </Icon>
                                                    </ListItemIcon>
                                                    <ListItemText primary={i.label} />
                                                </MenuItem>;
                                            })}
                                        </Select>
                                    </FormControl>
                                    <Autocomplete
                                        id="module_group_account_select"
                                        className={classes.autocompleteInput}
                                        options={accounts}
                                        getOptionLabel={(option) => option.name}
                                        renderInput={(params) => <TextField {...params} label="Pick an account * " variant="standard" />}
                                        value={basicInfoFields.account}
                                        onChange={(e, pickedValue) => this.onAccountSelection(e, pickedValue)}
                                        data-testid="module_group_account_select"
                                    />
                                    <FormControlLabel
                                        className={`${classes.userModuleCheck}`}
                                        control={
                                            <Checkbox
                                                onChange={this.onUserModuleChange}
                                                checked={basicInfoFields.is_user_module}
                                                name="is user Module"
                                                color="primary"
                                            />
                                        }
                                        label="Do you want to make it available for end users?"
                                        labelPlacement="start"
                                    />
                                </React.Fragment>
                                : currentStage === FormSteps.FormInputs ?
                                <Grid container spacing={1} alignItems="flex-start" className={classes.gridHolder} data-testid="form-step">
                                    <Grid item xs={5} data-testid="user-input-holder">
                                        <Typography
                                            variant='h6'
                                            className={`${classes.heading} ${classes.ellipsis}`}
                                        >
                                            Module Group Form Fields
                                        </Typography>
                                        <div data-testid="added_inputs_holder" id="added_inputs_holder">
                                            {   
                                                formInputFields.length > 0 ?
                                                    <DragDropContext onDragEnd={this.reArrangeInputs}>
                                                        <Droppable droppableId='added-field-list'>
                                                            {(provided, snapshot) => (
                                                                <div
                                                                    {...provided.droppableProps}
                                                                    ref={provided.innerRef}
                                                                >
                                                                    {
                                                                        formInputFields.map((e, i) => (
                                                                            <Draggable key={e.widget_id} draggableId={e.id} index={i}>
                                                                                {(provided, snapshot) => (
                                                                                    <Card
                                                                                        ref={provided.innerRef}
                                                                                        {...provided.draggableProps}
                                                                                        {...provided.dragHandleProps}
                                                                                        className={classes.markerCard} id={e.id}
                                                                                    >
                                                                                        <CardContent className={classes.markerCardBody}>
                                                                                            <ControlCameraRounded
                                                                                                className={`${classes.dragHolder} handle`}
                                                                                                data-testid={`drag_handler_${i}`}
                                                                                            />
                                                                                            {
                                                                                                newInput.id !== e.id &&
                                                                                                    <React.Fragment>
                                                                                                        <IconButton
                                                                                                            color="default"
                                                                                                            className={classes.deleteFieldIcon}
                                                                                                            data-testid={`delete_form_field_${i}`}
                                                                                                            onClick={() => {this.deleteFormInput(i)}}
                                                                                                        >
                                                                                                            <DeleteRounded />
                                                                                                        </IconButton>
                                                                                                        {
                                                                                                            e.type !== 'photo_taker' &&
                                                                                                                <IconButton
                                                                                                                    color="default"
                                                                                                                    className={classes.editFieldIcon}
                                                                                                                    data-testid={`edit_form_field_${i}`}
                                                                                                                    onClick={() => {this.editFormInput(i)}}
                                                                                                                >
                                                                                                                    <CreateRounded />
                                                                                                                </IconButton>
                                                                                                        }
                                                                                                    </React.Fragment>
                                                                                            }
                                                                                            <Typography variant="h6" component="h6">
                                                                                                { this.formatFieldName(e.type)}:
                                                                                            </Typography>
                                                                                            <Typography variant="body2" component="p">
                                                                                                {e.type !== 'label' ? e.widget_id : e.text }
                                                                                            </Typography>
                                                                                            {
                                                                                                e.is_summary &&
                                                                                                    <Typography color="textSecondary">
                                                                                                        <b>
                                                                                                            Is summary { e.is_summary }
                                                                                                        </b>
                                                                                                    </Typography>
                                                                                            }
                                                                                            {
                                                                                                e.is_required &&
                                                                                                    <Typography color="textSecondary">
                                                                                                        <b>
                                                                                                            Is required
                                                                                                        </b>
                                                                                                    </Typography>
                                                                                            }
                                                                                        </CardContent>
                                                                                    </Card>
                                                                                )}
                                                                            </Draggable>
                                                                        ))
                                                                    }
                                                                </div>            
                                                            )}
                                                        </Droppable>
                                                    </DragDropContext>
                                                :
                                                <Card className={`${classes.markerCard} ${classes.emptyCard}`}>
                                                    <CardContent className={classes.markerCardBody}>
                                                        <Typography variant="h6" component="h6">
                                                            No fields registered
                                                        </Typography>
                                                    </CardContent>
                                                </Card>
                                            }
                                        </div>
                                        {
                                            !formInputFields.find(e => e.is_summary) &&
                                                <p className={classes.warningLabel}>
                                                    One input is required to be the report summary
                                                </p>
                                        }
                                        <div data-testid="new_input_form_holder">
                                            {
                                                isInputEdit ?
                                                <React.Fragment>
                                                    <Typography
                                                        variant='h6'
                                                        className={`${classes.heading} ${classes.ellipsis}`}
                                                    >
                                                        <IconButton
                                                            color="default"
                                                            data-testid="cancel_edit_button"
                                                            className={classes.cancelButton}
                                                            onClick={this.cancelInputEdit}
                                                        >
                                                            <CancelRounded />
                                                        </IconButton>
                                                        Editing field: {newInput.widget_id}
                                                    </Typography>
                                                </React.Fragment>
                                                :
                                                <React.Fragment>
                                                    <Typography
                                                        variant='h6'
                                                        className={`${classes.heading} ${classes.ellipsis}`}
                                                    >
                                                        Add a Field
                                                    </Typography>
                                                    <FormControl className={classes.selectInput}>
                                                        <InputLabel id="form_field_type">Input type: </InputLabel>
                                                        <Select
                                                            labelId="form_field_type"
                                                            id="form_field_type_select"
                                                            data-testid="form_field_type_select"
                                                            value={newInput.type}
                                                            onChange={this.handleInputTypeChange}
                                                        >
                                                            <MenuItem value="label">Label</MenuItem>
                                                            <MenuItem value="textfield">Textfield</MenuItem>
                                                            <MenuItem value="photo_taker" disabled={formInputFields.find(e => e.type === 'photo_taker') ? true : false}>Photo taker</MenuItem>
                                                            <MenuItem value="picker">Picker</MenuItem>
                                                            {
                                                                !basicInfoFields.is_user_module && 
                                                                    <MenuItem value="toggle">Toggle</MenuItem>       
                                                            }
                                                        </Select>
                                                    </FormControl>
                                                </React.Fragment>
                                            }
                                            {
                                                !isLoadingEditInput ?
                                                    <React.Fragment>
                                                        {
                                                            newInput.type === 'label' ? 
                                                                <TextField
                                                                    className={classes.textField}
                                                                    id="form_text_input"
                                                                    label="Label Text * "
                                                                    value={newInput.text}
                                                                    placeholder="Label Text"
                                                                    onChange={e => this.handleNewInputTextChange(e, "text")}
                                                                    multiline={false}
                                                                    variant="outlined"
                                                                    InputLabelProps={{
                                                                        shrink: true,
                                                                    }}
                                                                />
                                                            : newInput.type === 'textfield' ?
                                                                <React.Fragment>
                                                                    <TextField
                                                                        className={classes.textField}
                                                                        id="form_text_placeholder"
                                                                        label="Input placeholder * "
                                                                        value={newInput.placeholder}
                                                                        placeholder="Input placeholder"
                                                                        onChange={e => this.handleNewInputTextChange(e, "placeholder")}
                                                                        multiline={false}
                                                                        variant="outlined"
                                                                        InputLabelProps={{
                                                                            shrink: true,
                                                                        }}
                                                                    />
                                                                    <TextField
                                                                        className={classes.textField}
                                                                        id="form_text_default_value"
                                                                        label="Default value"
                                                                        value={newInput.default_value}
                                                                        placeholder="Default value (optional)"
                                                                        onChange={e => this.handleNewInputTextChange(e, "default_value")}
                                                                        multiline={false}
                                                                        variant="outlined"
                                                                        InputLabelProps={{
                                                                            shrink: true,
                                                                        }}
                                                                    />
                                                                </React.Fragment>
                                                            : newInput.type === 'picker' ?
                                                                <React.Fragment>
                                                                    <TextField
                                                                        className={classes.textField}
                                                                        id="form_text_placeholder"
                                                                        label="Input placeholder * "
                                                                        value={newInput.placeholder}
                                                                        placeholder="Input placeholder"
                                                                        onChange={e => this.handleNewInputTextChange(e, "placeholder")}
                                                                        multiline={false}
                                                                        variant="outlined"
                                                                        InputLabelProps={{
                                                                            shrink: true,
                                                                        }}
                                                                    />
                                                                    {
                                                                        newInput.items && newInput.items.length > 0 &&
                                                                            <div className={classes.listContainer}>
                                                                                <p className={classes.inputHolder}>
                                                                                    Item list
                                                                                </p>
                                                                                <SimpleList
                                                                                    className={classes.itemList}
                                                                                    items={pickerInputItemsList}
                                                                                    canDeleteListItem={true}
                                                                                    onDeleteItemClick={this.onDeletePickerItem}
                                                                                />
                                                                            </div>
                                                                    }
                                                                    <p className={classes.inputHolder}>
                                                                        Add a picker item
                                                                    </p>
                                                                    {
                                                                        newInput.items && newInput.items.length < 1 &&
                                                                            <p className={classes.warningLabel}>
                                                                                Minimum of 2 option required
                                                                            </p>
                                                                    }
                                                                    <Grid container spacing={1} alignItems="center">
                                                                        <Grid item xs={10}>
                                                                            <TextField
                                                                                className={classes.textField}
                                                                                id="form_picker_item"
                                                                                label="Picker item"
                                                                                value={newInput.new_item}
                                                                                placeholder="Picker item"
                                                                                onChange={e => this.handleNewInputTextChange(e, "new_item")}
                                                                                onKeyPress={this.handlePickerEnterInput}
                                                                                multiline={false}
                                                                                variant="outlined"
                                                                                InputLabelProps={{
                                                                                    shrink: true,
                                                                                }}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={2}>
                                                                            <IconButton
                                                                                color="primary"
                                                                                data-testid="add-new-picker-item"
                                                                                onClick={this.onAddPickerItem}
                                                                            >
                                                                                <AddCircle />
                                                                            </IconButton>
                                                                        </Grid>
                                                                    </Grid>
                                                                </React.Fragment>
                                                            : newInput.type === 'toggle' ?
                                                                <React.Fragment>
                                                                    {
                                                                        newInput.items && newInput.items.length > 0 &&
                                                                        newInput.items_labels && newInput.items_labels.length > 0 &&
                                                                            <div className={classes.listContainer}>
                                                                                <p className={classes.inputHolder}>
                                                                                    Toggle list
                                                                                </p>
                                                                                <SimpleList
                                                                                    className={classes.itemList}
                                                                                    items={toggleInputItemsList}
                                                                                    canDeleteListItem={true}
                                                                                    onDeleteItemClick={this.onDeleteToggleItem}
                                                                                />
                                                                            </div>
            
                                                                    }
                                                                    <p className={classes.inputHolder}>
                                                                        Add a toggle item
                                                                    </p>
                                                                    {
                                                                        newInput.items && newInput.items.length < 1 &&
                                                                            <p className={classes.warningLabel}>
                                                                                Minimun of 2 options required
                                                                            </p>
                                                                    }
                                                                    <Grid container spacing={1} alignItems="center" className={classes.toggleInputHolder}>
                                                                        <Grid item xs={10}>
                                                                            <TextField
                                                                                className={classes.textField}
                                                                                id="form_toggle_item_label"
                                                                                label="Toggle item label"
                                                                                value={newInput.new_item_label}
                                                                                placeholder="Toggle item label"
                                                                                onChange={e => this.handleNewInputTextChange(e, "new_item_label")}
                                                                                multiline={false}
                                                                                variant="outlined"
                                                                                InputLabelProps={{
                                                                                    shrink: true,
                                                                                }}
                                                                            />
                                                                            <TextField
                                                                                className={classes.textField}
                                                                                id="form_toggle_item_value"
                                                                                label="Toggle item value"
                                                                                value={newInput.new_item}
                                                                                placeholder="Toggle item value"
                                                                                onChange={e => this.handleNewInputTextChange(e, "new_item")}
                                                                                multiline={false}
                                                                                variant="outlined"
                                                                                InputLabelProps={{
                                                                                    shrink: true,
                                                                                }}
                                                                            />
                                                                            <FormControlLabel
                                                                                className={`${classes.checkContainer}`}
                                                                                control={
                                                                                    <Checkbox
                                                                                        onChange={this.onSelectedItemChange}
                                                                                        value={newInput.is_selected_item}
                                                                                        disabled={this.checkExistingDefaultItem()}
                                                                                        name="is selected"
                                                                                        color="primary"
                                                                                    />
                                                                                }
                                                                                label="Is this the selected item by default?"
                                                                                labelPlacement="start"
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={2}>
                                                                            <IconButton
                                                                                color="primary"
                                                                                data-testid="add-new-toggle-item"
                                                                                onClick={this.onAddToggleItem}
                                                                                disabled={newInput.new_item === '' || newInput.new_item_label === ''}
                                                                            >
                                                                                <AddCircle />
                                                                            </IconButton>
                                                                        </Grid>
                                                                    </Grid>
                                                                </React.Fragment>
                                                            : ''
                                                        }
                                                        {
                                                            !['photo_taker', 'label', 'None'].includes(newInput.type) &&
                                                            <React.Fragment>
                                                                <FormControlLabel
                                                                    className={`${classes.checkContainer}`}
                                                                    control={
                                                                        <Checkbox
                                                                            onChange={this.onRequiredChange}
                                                                            value={newInput.is_required}
                                                                            defaultChecked={newInput.is_required}
                                                                            name="is required"
                                                                            color="primary"
                                                                        />
                                                                    }
                                                                    label="Is this field required?"
                                                                    labelPlacement="start"
                                                                />
                                                                <FormControlLabel
                                                                    className={`${classes.checkContainer}`}
                                                                    control={
                                                                        <Checkbox
                                                                            onChange={this.onSummaryChange}
                                                                            disabled={this.checkExistingSummary()}
                                                                            value={newInput.is_summary}
                                                                            defaultChecked={newInput.is_summary}
                                                                            name="check Summary"
                                                                            color="primary"
                                                                        />
                                                                    }
                                                                    label="Is this the report summary?"
                                                                    labelPlacement="start"
                                                                />
                                                            </React.Fragment>
                                                        }
                                                        {
                                                            newInput.type !== 'None' &&
                                                            <Button
                                                                variant="contained"
                                                                color="primary"
                                                                className={classes.addInputButton}
                                                                disabled={this.checkAddInputButton()}
                                                                onClick={this.onAddInputToForm}
                                                                data-testid="add-input-button"
                                                            >
                                                                { isInputEdit ? 'Save Changes' : 'Add Input'}
                                                            </Button>
                                                        }
                                                    </React.Fragment>
                                                :
                                                <CCSpinner
                                                    loading={isLoadingEditInput}
                                                    className={classes.formInputLoader}
                                                />
                                            }
                                        </div>
                                    </Grid>
                                    <Grid item xs={7} data-testid="wysiwig_holder">
                                        <Paper variant="outlined" className={classes.wysiwygGrid}>
                                            <div className={classes.titleHolder}>
                                                <Typography
                                                    variant='h6'
                                                    className={`${classes.heading} ${classes.ellipsis}`}
                                                >
                                                    Report submission preview
                                                </Typography>
                                                <p>
                                                    Keep in mind this is a preview based on Web Portal(Portal3) and may not be final or account for mobile apps.
                                                </p>
                                            </div>
                                            <div className={classes.reportBadge}>
                                                <Icon className={`${classes.imageIcon} ${classes.baseIconSize}`}>
                                                    <img src={BaseTile}/>
                                                </Icon>
                                                <label
                                                    className={classes.badgeInputLabel}
                                                    htmlFor="mockup_input_badge"
                                                >
                                                    <Icon className={`${classes.imageIcon} ${classes.pickedIconSize}`}>
                                                        <img src={this.checkSelectedIcon()}/>
                                                    </Icon>
                                                    <span className={classes.badgeInputText}>
                                                        {basicInfoFields.name}
                                                    </span>
                                                </label>
                                            </div>
                                            <div className={classes.wysiwygFormHolder}>
                                                {this.getWysiwygComponents()}
                                            </div>
                                        </Paper>
                                    </Grid>
                                </Grid>
                                :
                                <Grid container spacing={1} alignItems="flex-start" className={classes.gridHolder} data-testid="confirmation-step">
                                    <Grid item xs={3}></Grid>
                                    <Grid item xs={6}>
                                        <Paper variant="outlined" className={classes.wysiwygGrid}>
                                            <div className={classes.titleHolder}>
                                                <Typography
                                                    variant='h6'
                                                    className={`${classes.heading} ${classes.ellipsis}`}
                                                >
                                                    { isPreview ? 
                                                        `${basicInfoFields.name} preview:` :
                                                        basicInfoFields.is_user_module ?
                                                        'Confirm User Module'
                                                        : 'Confirm Module Group'
                                                    }
                                                </Typography>
                                            </div>
                                            <div className={classes.reportBadge}>
                                                <Icon className={`${classes.imageIcon} ${classes.baseIconSize}`}>
                                                    <img src={BaseTile}/>
                                                </Icon>
                                                <label
                                                    className={classes.badgeInputLabel}
                                                    htmlFor="mockup_input_badge"
                                                >
                                                    <Icon className={`${classes.imageIcon} ${classes.pickedIconSize}`}>
                                                        <img src={this.checkSelectedIcon()}/>
                                                    </Icon>
                                                    <span className={classes.badgeInputText}>
                                                        {basicInfoFields.name}
                                                    </span>
                                                </label>
                                            </div>
                                            <div className={classes.wysiwygFormHolder}>
                                                {this.getWysiwygComponents()}
                                            </div>
                                        </Paper>
                                    </Grid>
                                    <Grid item xs={3}></Grid>
                                </Grid>
                            }
                            {
                                currentStage !== FormSteps.BasicInfo && !isPreview &&
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        className={classes.backButton}
                                        onClick={this.goBackStageButton}
                                        data-testid="go-back-button"
                                    >
                                        Back
                                    </Button>
                            }
                            {
                                !isPreview &&
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className={classes.continueButton}
                                    disabled={this.checkNextStageButton()}
                                    onClick={this.goNextStageButton}
                                    data-testid="next-button"
                                >
                                    { currentStage !== FormSteps.Confirmation ? 'Continue' : 'Submit' }
                                </Button>
                            }
                        </div>
                    </Paper>
                </div>
            </div>
        );
    }
}

const MUIComponent = withStyles(styles)(ModuleGroupBuilder);
export {MUIComponent as ModuleGroupBuilder}
