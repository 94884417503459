import { HttpClient } from './../shared/utils/http-client';

const CAPTCHA_CLIENT_TYPE = {
    ADMIN_TOOL: 'ad',
    PORTAL2: 'p2',
    PORTAL3: 'p3'
};

export interface LoginResponse {
    user_id: string,
    auth_token: string,
    success: boolean
}

export default class LoginProvider {
    private endpointCore: string;

    constructor() {
        this.endpointCore = `${process.env.REACT_APP_BASE_API_URL}`;
    };

    login = async (username: string, password: string, token?: string) => {

        if (token) {
            const captchaResponse = await HttpClient.post(
                `${this.endpointCore}recaptcha_verify`,
                {
                    token,
                    client: CAPTCHA_CLIENT_TYPE.ADMIN_TOOL,
                    version: 2
                }
            );
           
            const {
                data: { success, error_code: errorCode },
                status
            } : { 
                data: {
                    success?: boolean,
                    error_code: string
                },
                status: number
            } = captchaResponse;

            if (!success || !!errorCode || status !== 200) {
                return Promise.reject({
                    response: {
                        data: {},
                        status
                    },
                    message: errorCode
                });
            }
        }

        const params = {
            username, 
            password
        }

        return HttpClient.post(`${this.endpointCore}login/`, params).then(response => {
            const statusCode = response.status;
            if (statusCode === 200) {
                const { user_id, auth_token } = response.data;
                return Promise.resolve({
                    user_id,
                    auth_token,
                    success: true
                });
            }
            
            if (statusCode === 401) {
                return Promise.reject({
                    user_id: '',
                    auth_token: '',
                    success: false,
                    status: 401,
                    message: 'Unathorized response'
                })
            }

            return Promise.reject({
                status: statusCode,
                message: statusCode === 504 ? 'Request timeout' : 'Network error'
            });
        }).catch(error => {
            const { response } = error;
            return Promise.reject({
                status: response.status,
                message: response.status === 504
                ? 'Request timeout'
                : response['data']['error']
                ? response.data.error
                : 'Network error'
            })
        });
    }
}
