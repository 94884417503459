import * as React from 'react';

import {
    Button,
    Divider,
    Typography
} from '@material-ui/core';
import {
    createStyles,
    WithStyles,
    withStyles
} from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const styles = (theme: Theme) => createStyles({
    root: {},
    divider: {},
    title: {},
    footerButton: {},
    subtitle: {},
    textField: {},
    passwordResetContainer: {}
});

interface Props extends WithStyles<typeof styles> {
    className?: string;
    email?: string;
    title: string;
    subtitle?: string;
    footerButtonText: string;
    onFooterButtonClick?: () => void;
}

class LoginWizardStepPasswordResetSuccess extends React.Component<Props> {
    public static defaultProps = {
    };

    public render() {
        const {
            classes,
            className,
            email,
            title,
            subtitle,
            footerButtonText,
            onFooterButtonClick
        } = this.props;
        
        const rootClassNames = classes.root + (className ? ` ${className}` : '');
        return (
            <div className={rootClassNames}>
                <div className={classes.passwordResetContainer}>
                    <Typography
                        className={classes.title}
                        variant="h4"
                    >
                        {title}
                    </Typography>
                    <Typography
                        className={classes.subtitle}
                        variant="subtitle1"
                    >
                        {email ? subtitle : '\u00a0'} {/* unicode non breaking space */}
                    </Typography>
                    <Typography
                        className={classes.textField}
                        variant="subtitle2"
                    >
                        {email}
                    </Typography>
                </div>
                <Divider
                    className={classes.divider}
                    variant="middle"
                />
                <Button
                    className={classes.footerButton}
                    variant="outlined"
                    onClick={onFooterButtonClick}
                >
                    {footerButtonText}
                </Button>
            </div>
        );
    }
}

const MUIComponent = withStyles(styles)(LoginWizardStepPasswordResetSuccess);
export {MUIComponent as LoginWizardStepPasswordResetSuccess};
