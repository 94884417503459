import * as React from 'react';

import {
    IconButton,
    LinearProgress,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    Paper,
    Theme,
    Typography
} from '@material-ui/core';
import {
    createStyles,
    WithStyles,
    withStyles
} from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import { CCSpinner } from '../cc-spinner';
import { SimpleListItem } from './simple-list-item';

const styles = (theme: Theme) => createStyles({
    noItemLabel: {
        padding: '1em',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& > *': {
            color: theme.ccPalette.disabled.main
        }
    },
    noItemsWhileLoading: {
        minHeight: '100px'
    },
    spinner: {
        position: 'absolute',
        height: '100%',
        width: '100%',
        backgroundColor: theme.ccPalette.disabled.light
    },
    paper: {
        position: 'relative'
    },
    list: {
        padding: 0
    },
    listItemRoot: {
        minHeight: '3.4em',

        '&$listItemSelected, &$listItemSelected:hover': {
            backgroundColor: theme.ccPalette.cc_colors.translucent.ccRightsilhouette.light
        }
    },
    listItemSelected: {}, // This empty rule is used only to attach this class to MUI component
    listItemButton: {
        '&:hover': {
            backgroundColor: theme.ccPalette.cc_colors.translucent.ccRightsilhouette[100]
        }
    },
    listItemChildWithEllipsis: {
        '& > *': {
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden'
        }
    },
    listItemText: {},
    listItemTextDisabled: {
        '& > *': {
            color: theme.ccPalette.disabled.main
        }
    },
    noLabelText: {
        '& > span': {
            color: theme.ccPalette.disabled.dark,
            fontStyle: 'Italic'
        }
    },
    progressLine: {
        width: '100%',
        position: 'absolute',
        left: 0,
        bottom: 0
    }
});

interface Props extends WithStyles<typeof styles> {
    canDeleteListItem: boolean;
    className?: string;
    deleteAlwaysEnabled: boolean;
    disabled: boolean;
    items: SimpleListItem[];
    isLoading: boolean;
    keepItemSelected: boolean;
    noItemsLabel?: string;
    noLabelText?: string;
    selectedItemId?: string;
    customItemIcon?: React.ReactChild;
    onListItemClick?: (listItem: SimpleListItem) => void;
    onDeleteItemClick?: (listItem: SimpleListItem) => void;
    onCustomItemClick?: (listItem: SimpleListItem) => void;
}

interface States {
    selectedItem: SimpleListItem | null;
}

class SimpleList extends React.Component<Props, States> {
    public static defaultProps = {
        canDeleteListItem: false,
        deleteAlwaysEnabled: false,
        disabled: false,
        isLoading: false,
        keepItemSelected: false, // Keep the item selected after click
        noLabelText: '<NO LABEL>'
    };

    state = {
        selectedItem: null
    }

    getItemFromId = (id = ''): SimpleListItem | null => {
        if (!id) {
            return null;
        }

        const {items} = this.props;
        const foundItem = items.find((item: SimpleListItem) => {
            return id === item.id;
        });

        return foundItem ? foundItem : null;
    }
    onListItemClicked = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        const {
            disabled,
            isLoading,
            keepItemSelected,
            onListItemClick
        } = this.props;

        if (disabled || isLoading) {
            return;
        }

        const { id } = event.currentTarget.dataset;
        const selectedItem: SimpleListItem | null = this.getItemFromId(id);

        if (keepItemSelected) {
            this.setState({ selectedItem });
        }

        if (onListItemClick && selectedItem) {
            onListItemClick(selectedItem);
        }
    }

    onDeleteItemClicked = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        const { onDeleteItemClick } = this.props;
        const { id } = event.currentTarget.dataset;
        const selectedItem: SimpleListItem | null = this.getItemFromId(id);

        if (onDeleteItemClick && selectedItem) {
            onDeleteItemClick(selectedItem);
        }
    }

    onCustomItemClicked = (event: React.MouseEvent<HTMLButtonElement>) => {
        const { onCustomItemClick } = this.props;
        const { id } = event.currentTarget.dataset;
        const selectedItem: SimpleListItem | null = this.getItemFromId(id);

        if (onCustomItemClick && selectedItem) {
            onCustomItemClick(selectedItem);
        }
    }

    generateTeamChildren (items:SimpleListItem[], canDeleteListItem: boolean) {
        const {
            classes,
            deleteAlwaysEnabled,
            disabled,
            isLoading,
            onListItemClick:onListItemClickedCallback,
            noLabelText,
            customItemIcon
        } = this.props;
        const onListItemClicked = this.onListItemClicked;
        const onDeleteItemClicked = this.onDeleteItemClicked;
        const hasSecondaryLabels = !!items.filter((item: SimpleListItem) => item.secondaryLabel).length;
        
        return items.map((item: SimpleListItem) => {
            const { selectedItemId } = this.props;
            const { selectedItem } = this.state;
            const currentSelectedItem = selectedItem ? 
                selectedItem :
                selectedItemId ?
                    items.find((element) => {
                        return element.id === selectedItemId;
                    })
                    : null;
            const isDisabled = disabled || isLoading;
            const itemTextClasses = `${classes.listItemText} ${classes.listItemChildWithEllipsis}` +
                                    (isDisabled ? ` ${classes.listItemTextDisabled}` : '');
            const isButtonEnabled = !isDisabled && !!onListItemClickedCallback;
            const {
                id: itemId,
                icon
            } = item;
            const isItemLabelEmpty = (item.label === '');
            const noLabelTextOrBlank = (noLabelText === '') ? '\xa0' : noLabelText ;
            const isSelected = currentSelectedItem ? (item.id === currentSelectedItem.id) : false;
            const {
                hasProgress,
                progress
            } = item;

            return (
                <ListItem
                    classes={{
                        root: classes.listItemRoot,
                        selected: classes.listItemSelected,
                        button: classes.listItemButton
                    }}
                    key={itemId}
                    data-id={itemId}
                    divider={true}
                    selected={isSelected}
                    // If the user wants to select the items and
                    // he has passed the click callback we enable the buttons
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    button={(isButtonEnabled && !isSelected) as any }
                    onClick={isButtonEnabled ? onListItemClicked : undefined}
                >
                    { icon ? icon : '' }
                    <ListItemText
                        className={`${itemTextClasses}${isItemLabelEmpty ? ' ' + classes.noLabelText : ''}`}
                        primary={isItemLabelEmpty ? noLabelTextOrBlank : item.label}
                        secondary={isItemLabelEmpty && hasSecondaryLabels && item.secondaryLabel === '' ? '\xa0' : item.secondaryLabel}
                    />
                    <ListItemSecondaryAction>
                        { canDeleteListItem ?
                            <IconButton
                                aria-label="Delete"
                                data-id={itemId}
                                disabled={isDisabled && !deleteAlwaysEnabled}
                                onClick={onDeleteItemClicked}
                                data-testid={`delete_item_${itemId}`}
                            >
                                <DeleteIcon />
                            </IconButton>
                            : (customItemIcon) ?
                                <IconButton
                                    data-id={itemId}
                                    disabled={isDisabled}
                                    onClick={this.onCustomItemClicked}
                                >
                                    {customItemIcon}
                                </IconButton>
                                : ''
                        }
                    </ListItemSecondaryAction>
                    { hasProgress &&
                        <LinearProgress variant="determinate" value={progress} className={classes.progressLine}/>
                    }
                </ListItem>
            );
            
        });
    }

    public render() {
        const {
            classes,
            className,
            items,
            isLoading,
            noItemsLabel,
            canDeleteListItem
        } = this.props;
        const areItemsPresent = items && (items.length > 0);
        const isNoItemLabelPresent = noItemsLabel && (noItemsLabel.length > 0);
        const noItemLabelClasses = !areItemsPresent && isNoItemLabelPresent ? ' ' + classes.noItemLabel : '';
        const noItemsWhileLoading = !areItemsPresent && isLoading ? ' ' + classes.noItemsWhileLoading : '';
        return (
            <Paper className={`${className ? className+' ': ''}${classes.paper}${noItemLabelClasses}${noItemsWhileLoading}`}>
                <CCSpinner
                    className={classes.spinner}
                    loading={isLoading}
                    size={70}
                />
                {
                    areItemsPresent ?
                        <List className={classes.list}>
                            { this.generateTeamChildren(items, canDeleteListItem) }
                        </List>
                        : isNoItemLabelPresent ?
                            <Typography
                                variant="h5"
                            >
                                { noItemsLabel }
                            </Typography>
                            : <span>{'\u00a0'}</span>
                }
            </Paper>
        );
    }
}

const MUIComponent = withStyles(styles)(SimpleList);
export {MUIComponent as SimpleList};
