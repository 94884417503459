import { Typography } from '@material-ui/core';
import {
    createStyles,
    Theme,
    WithStyles,
    withStyles
} from '@material-ui/core/styles';
import { Error } from '@material-ui/icons';
import _ from 'lodash';
import {
    inject,
    observer
} from 'mobx-react';
import React from 'react';
import { RunningTasksList } from '../../components';
import { AccountUserExport } from '../../components/account-user-export';
import AccountProvider from '../../providers/account.provider';
import JobProvider, { JobRequest, JobTypes, RunningTasks, STATIC_PROGRESS_LIMIT, StatusTypes } from '../../providers/job.provider';
import { CCSpinner } from '../../shared/components/cc-spinner';
import { RenderTree } from '../../shared/components/node-tree';
import { SimpleDialog } from '../../shared/components/simple-dialog';
import { PageBoundary } from '../../shared/components/simple-grid-pagination';
import { SimpleModal } from '../../shared/components/simple-modal';
import { Account, Folder, Team } from '../../shared/domain';
import { MainTabs } from '../../stores/admin.store';
import { RootStore } from '../../stores/root.store';

const ACCOUNTS_PAGING_LIMIT = 20;
const ACCOUNTS_ROWS_PER_PAGE = 10;
const TEAMS_ROWS_PER_PAGE = 10;
const TASKS_ROWS_PER_PAGE = 10;

export interface JobResultDataset {
    status: string;
    result: string;
}

const styles = (theme: Theme) => createStyles({
    container: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        width: '100%'
    },
    analyticsTabList: {
        height: '18.2em'
    },
    errorPopup: {
        width: '98%',
        borderRadius: 5
    },
    errorPopupHeader: {
        height: '0.6em',
        backgroundColor: theme.palette.error.main,
        borderRadius: '3px 3px 0 0'
    },
    errorPopupContentContainer :{
        display: 'flex',
        flexDirection: 'row',
        padding: theme.spacing(1),
    },
    errorPopupIcon: {
        fontSize: 40,
        marginRight: '1em'
    },
    mainSpinnerLabel: {
        paddingBottom: '30px'
    },
    progressContainer: {
        flexGrow: 1
    },
    animContainer: {
        transition: 'width 1s'
    },
    listIcon: {
        marginRight: '0.5em',
        fontSize: '1em'
    },
    teamPickerContainer: {
        width: "100%",
        transition: 'width 1s'
    },
    smallTeamPickerContainer: {
        width: "75%",
        transition: 'width 1s'
    },
    exportTasksContainer: {
        width: "25%",
        transition: 'width 1s'
    },
    tasksContainerSize: {
        flex: 1,
    }
});

interface Props extends WithStyles<typeof styles> {
    rootStore: RootStore;
    onAuthError?: () => void;
}

interface States {
    accounts: Account[];
    currentAccount: Account | null;
    currentAccountOffset: number;
    totalAccounts: number;
    isAccountsLoading: boolean;
    currentAccountName: string;
    allTeams: Team[];
    managerTeams: Team[];
    employeeTeams: Team[];
    totalManagerTeams: number;
    totalEmployeeTeams: number;
    isTeamsLoading: boolean;
    currentManagerTeamOffset: number;
    currentEmployeeTeamOffset: number;
    exportTeam: Team | null;
    exportTeamType: string;
    networkError: boolean,
    mainSpinnerText: string;
    runningTasks: RunningTasks[];
    currentTasksOffset: number;
    timeElapser: number | null;
    exportFolder: boolean;
    currentNodeTree: RenderTree;
    treeIsLoading: boolean;
    folders: Folder[];
    processedFolders: string[];
    pickedFolder: string | null;
    isErrorModalOpened: boolean;
    errorModalHeader: string;
    errorModalText: string;
    dataPageIsLoading: boolean;
    searchAccountName: string;
    storedAccounts: Account[];
    storedAccountOffset: number;
    storedAccountTotalCount: number;
}

@inject('rootStore')
@observer
class UserExport extends React.Component<Props> {
    public static defaultProps: Partial<Props> = {
    };

    state: States = {
        accounts: [],
        currentAccount: null,
        currentAccountOffset: 0,
        totalAccounts: 0,
        isAccountsLoading: false,
        currentAccountName: '',
        allTeams: [],
        managerTeams: [],
        employeeTeams: [],
        totalManagerTeams: 0,
        totalEmployeeTeams: 0,
        currentManagerTeamOffset: 0,
        currentEmployeeTeamOffset: 0,
        isTeamsLoading: false,
        networkError: false,
        isErrorModalOpened: false,
        errorModalHeader: '',
        errorModalText: '',
        mainSpinnerText: 'Please wait. Loading data.',
        dataPageIsLoading: true,
        exportTeam: null,
        exportTeamType: '',
        runningTasks: [],
        currentTasksOffset: 0,
        timeElapser: null,
        exportFolder: false,
        treeIsLoading: false,
        currentNodeTree: {id: '', name:'', children: [], path: ''},
        folders: [],
        processedFolders: [],
        pickedFolder: null,
        searchAccountName: '',
        storedAccounts: [],
        storedAccountOffset: 0,
        storedAccountTotalCount: 0
    }

    accountProvider = new AccountProvider();
    jobProvider = new JobProvider();

    componentDidMount() {
        this.fetchAccounts(this.state.currentAccountOffset);
        this.fetchRunningTasks();
        this.startTimeInterval();
    };

    componentWillUnmount() {
        const {
            rootStore: { adminStore }
        } = this.props;   
        const { runningTasks } = this.state;
        this.clearIntervals(runningTasks);
        adminStore.setCurrentExportTasks(runningTasks, MainTabs.User_Export);
        this.clearTimeElapserInterval();
    }

    fetchRunningTasks = () => {
        const {
            rootStore: { adminStore }
        } = this.props;
        const tasks = adminStore.currentExportTasks.find(e => e.page === MainTabs.User_Export);
        this.setState({
            runningTasks: tasks!.tasks
        }, () => {
            tasks!.tasks.forEach(element => {
                if(element.status === 102) {
                    // SET TO RESTART POOLING
                    element.set_to_stop = false;
                    this.startTaskWatcher(element);
                }
            });
        });
    }

    startTimeInterval() {
        const { timeElapser } = this.state;
        if (timeElapser) {
            return;
        }
        const timeElapserInterval = window.setInterval(() => {
            const { runningTasks } = this.state;
            const time = Date.now();
            const processing = runningTasks.filter(e => e.status === 102);
            processing.forEach(element => {
                element.elapsedTime = Math.round((time - element.startedAt) / 1000);
            });
            this.setState({runningTasks});
        }, 1000);
        this.setState({timeElapser: timeElapserInterval});
    }

    clearIntervals(tasks: RunningTasks[]) {
        tasks.forEach((element) => {
            element.set_to_stop = true;
        });
    }

    clearTimeElapserInterval() {
        const { timeElapser } = this.state;
        if( timeElapser ) {
            window.clearInterval(timeElapser);
        }
    }

    fetchAccounts = (offset:number, limit: number = ACCOUNTS_PAGING_LIMIT) => {
        return this.accountProvider.getAllAccounts(offset, limit)
          .then((results) => {
            const {accounts, totalCount} = results;
            if (!accounts) {
                if (offset === 0) {
                    this.setState({networkError: true});
                }
                return;
            }

            this.setState({
                dataPageIsLoading: false,
                isAccountsLoading: false,
                totalAccounts: totalCount,
                accounts,
                currentAccountOffset: offset
            });
        }).catch((error) => {
            const { status } = error.response;
            if (status === 401) {
                const { onAuthError } = this.props;
                if (!onAuthError) {
                    return;
                }
                onAuthError();
                return;
            }
            this.setState({networkError: true});
        });
    }

    searchAccounts = (searchName: string, offset?: number) => {
        // Nothing to search load the normal account list
        if (searchName === '') {
            const {
                storedAccounts,
                storedAccountOffset,
                storedAccountTotalCount
            } = this.state;

            // Empty search do nothing
            if (!storedAccounts.length) {
                return;
            }

            // Going back from actual seach
            this.setState({
                accounts: storedAccounts,
                currentAccountOffset: storedAccountOffset,
                totalAccounts: storedAccountTotalCount,
                searchAccountName: '',
                storedAccounts: [],
                storedAccountOffset: 0,
                storedAccountTotalCount: 0
            });
            return;
        }

        // We have a name search
        const {
            currentAccountOffset,
            accounts,
            totalAccounts,
            storedAccounts
        } = this.state;
        this.setState({
            isAccountsLoading: true,
            searchAccountName: searchName
        }, () => {
            const searchOffset = offset ? offset : 0;
            return this.accountProvider
                .searchAccountByNameShortSchema(searchName, searchOffset, ACCOUNTS_PAGING_LIMIT)
                .then(results => {
                    const { accounts: foundAccounts, totalCount } = results;
                    if (!foundAccounts) {
                        if (currentAccountOffset === 0) {
                            this.setState({ networkError: true });
                        }
                        return;
                    }
    
                    // If we haven't stored the original list do it
                    // This prevents multiple searchs overriding each others
                    if (!storedAccounts.length) {
                        this.setState({
                            storedAccounts: accounts,
                            storedAccountTotalCount: totalAccounts,
                            storedAccountOffset: currentAccountOffset
                        })
                    }

                    this.setState({
                        isAccountsLoading: false,
                        totalAccounts: totalCount,
                        accounts: foundAccounts,
                        currentAccountOffset: searchOffset
                    });
                })
                .catch(error => {
                    const { status } = error.response;
                    if (status === 401) {
                        const { onAuthError } = this.props;
                        if (!onAuthError) {
                            return;
                        }
                        onAuthError();
                        return;
                    }
                    this.setState({ networkError: true });
                });
        });
    };

    onAccountDataPageOverBoundaryReached = (boundary: PageBoundary, nextPage: number): Promise<void> => {
        return new Promise<void>((resolve, reject) => {
            const { 
                currentAccountOffset,
                totalAccounts
            } = this.state;
            const isFirstPage = nextPage === 0;
            const isLastPage = nextPage === (Math.ceil(totalAccounts / ACCOUNTS_ROWS_PER_PAGE)-1);
            const newOffset = isFirstPage ? 0
                            : isLastPage ? ACCOUNTS_PAGING_LIMIT * Math.floor(totalAccounts / ACCOUNTS_PAGING_LIMIT)
                            : (boundary === PageBoundary.Upper) ? 
                                currentAccountOffset+ACCOUNTS_PAGING_LIMIT :
                                currentAccountOffset-ACCOUNTS_PAGING_LIMIT;
            this.setState({isAccountsLoading: true});
            this.fetchAccounts(newOffset)
              .then(() => resolve())
              .catch(()=> reject());
        });
    };

    onAccountClicked = (account: Account) => {
        const { currentAccount } = this.state;
        if (currentAccount === account) {
            return;
        }

        this.setState({
            currentAccount: account,
            currentAccountName: account.name,
            teamInfoItems: [],
            exportTeam: null,
            pickedFolder: null,
            exportFolder: false,
            treeIsLoading: false,
            currentNodeTree: {id: '', name:'', children: [], path: ''},
            folders: [],
            processedFolders: [],
        });
        this.fetchTeamsFromAccount(account);
        // this.fetchFoldersFromAccount(account);
    };

    fetchTeamsFromAccount = (account: Account) => {
        this.setState({isTeamsLoading: true});
        const offset = 0;
        return this.accountProvider.fetchTeamsFromAccountIdAdmin(account.accountId, 0, 500)
          .then((results) => {
            const { teams } = results;
            if (!teams) {
                if (offset === 0) {
                    this.setState({networkError: true});
                }
                return;
            }
            const managerTeams: Team[] = [];
            const employeeTeams: Team[] = [];
            teams.forEach(element => {
                const check = _.get(element, "actions.access.management_app");
                if (check) {
                    managerTeams.push(element);
                } else {
                    employeeTeams.push(element);
                }
            });

            this.setState({
                isTeamsLoading: false,
                allTeams: teams,
                managerTeams,
                employeeTeams,
                totalManagerTeams: managerTeams.length,
                totalEmployeeTeams: employeeTeams.length,
                totalTeams: teams.length
            });
        }).catch((error) => {
            const { status } = error.response;
            if (status === 401) {
                const { onAuthError } = this.props;
                if (!onAuthError) {
                    return;
                }
                onAuthError();
                return;
            }
            this.setState({networkError: true});
        });
    };

    onManagerTeamDataPageOverBoundary = (boundary: PageBoundary, nextPage: number): Promise<void> => {
        return new Promise<void>((resolve, reject) => {
            const {
                currentManagerTeamOffset,
                managerTeams
            } = this.state;
            const totalTasks = managerTeams.length;
            const isFirstPage = nextPage === 0;
            const isLastPage = nextPage === (Math.ceil(totalTasks / TEAMS_ROWS_PER_PAGE)-1);
            const newOffset = isFirstPage ? 0
                            : isLastPage ? ACCOUNTS_PAGING_LIMIT * Math.floor(totalTasks / ACCOUNTS_PAGING_LIMIT)
                            : (boundary === PageBoundary.Upper) ? 
                            currentManagerTeamOffset+ACCOUNTS_PAGING_LIMIT :
                            currentManagerTeamOffset-ACCOUNTS_PAGING_LIMIT;
            const nextItems = managerTeams;
            // MANUAL OFFSET
            this.setState({
                managerTeams: nextItems.splice(newOffset, nextItems.length)
            });
        });
    }

    onEmployeeTeamDataPageOverBoundary = (boundary: PageBoundary, nextPage: number): Promise<void> => {
        return new Promise<void>((resolve, reject) => {
            const {
                currentEmployeeTeamOffset,
                employeeTeams
            } = this.state;
            const totalTasks = employeeTeams.length;
            const isFirstPage = nextPage === 0;
            const isLastPage = nextPage === (Math.ceil(totalTasks / TEAMS_ROWS_PER_PAGE)-1);
            const newOffset = isFirstPage ? 0
                            : isLastPage ? ACCOUNTS_PAGING_LIMIT * Math.floor(totalTasks / ACCOUNTS_PAGING_LIMIT)
                            : (boundary === PageBoundary.Upper) ? 
                            currentEmployeeTeamOffset+ACCOUNTS_PAGING_LIMIT :
                            currentEmployeeTeamOffset-ACCOUNTS_PAGING_LIMIT;
            const nextItems = employeeTeams;
            // MANUAL OFFSET
            this.setState({
                employeeTeams: nextItems.splice(newOffset, nextItems.length)
            });
        });
    }

    onTeamClick = (team: Team, teamType: string) => {
        if (teamType !== 'manager' && teamType !== 'employee') {
            return;
        }
        if(!team) {
            return;
        }
        this.setState({
            exportTeam: team,
            exportTeamType: teamType
        });
    }

    startTaskWatcher = (task: RunningTasks) => {
        const { runningTasks } = this.state;

        if (!task.export_name || !task.task_id || !task.export_id) {
            return;
        }

        const statusCallback = (taskId: string) => {
            const taskIndex = runningTasks.findIndex(e => e.task_id === taskId);
            const taskItem = runningTasks.find(e => e.task_id === taskId);

            if (!taskItem || taskItem.status !== 102) {
                return;
            }

            if (taskItem.set_to_stop) {
                return;
            }   
            
            this.jobProvider.checkJobStatus(taskItem.task_id).then(data => {
                if (data.job_status === StatusTypes.Error) {
                    taskItem.status = 500;
                    runningTasks[taskIndex] = taskItem;
                    this.setState({runningTasks});
                }
                if (data.job_status === StatusTypes.Complete) {
                    taskItem.status = 200;
                    taskItem.progress = 100.0;
                    this.jobProvider.checkJobResult(taskItem.task_id).then(res => {
                        const results = res.result as JobResultDataset;
                        taskItem.result_data = results.result;
                        runningTasks[taskIndex] = taskItem;
                        this.setState({runningTasks});
                    }).catch((error) => {
                        const { status } = error.response;
                        // CHECK IF WE TIMEOUT OR NOT
                        if (status === 408 || status === 504) {
                            // WE HAVE TIMEOUT INCREASE TIMER AND KEEP TRYING
                            taskItem.timer += 1000;
                            setTimeout(taskItem.internalCallback!, taskItem.timer, taskId);
                            return;
                        }
                        taskItem.status = 500;
                        runningTasks[taskIndex] = taskItem;
                        this.setState({runningTasks});
                    });
                } else {
                    // KEEP TRACK OF THE PROGRESS STATUS IF ITS STILL THE SAME
                    if (taskItem.progress === data.estimate_percent_complete) {
                        taskItem.static_progress_count += 1;
                    }
                    taskItem.progress = data.estimate_percent_complete;
                    // IF WE REACH A POINT WHERE THE STATUS IS THE SAME THE JOB IS SLOW
                    // MAKE FEWER REQUESTS TO NOT OVERWHELM THE STATUS ENDPOINT
                    if (taskItem.static_progress_count === STATIC_PROGRESS_LIMIT) {
                        taskItem.timer += 1000;
                        taskItem.static_progress_count = 0;
                    }
                    setTimeout(taskItem.internalCallback!, taskItem.timer, taskId);
                }
            }).catch((error) => {
                const { status } = error.response;
                // CHECK IF WE TIMEOUT OR NOT
                if (status === 408 || status === 504) {
                    // WE HAVE TIMEOUT INCREASE TIMER AND KEEP TRYING
                    taskItem.timer += 1000;
                    setTimeout(taskItem.internalCallback!, taskItem.timer, taskId);
                    return;
                }
                taskItem.status = 500;
                runningTasks[taskIndex] = taskItem;
                this.setState({runningTasks});
            });
        }

        // PREVENT GENERATING TIMERS WITHOUT PROPERTIES BEING INITIALIZED
        const taskIndex = runningTasks.findIndex(e => e.task_id === task.task_id);
        runningTasks[taskIndex].internalCallback = statusCallback;
        this.setState({runningTasks}, () => {
            setTimeout(statusCallback, task.timer, task.task_id);
        });
    }

    onTeamExportClick = () => {
        const {
            exportTeam,
            exportTeamType,
            currentAccount
        } = this.state;

        if (
            !exportTeam ||
            exportTeamType === '' ||
            !currentAccount
        ) {
            return;
        }

        const { runningTasks } = this.state;
        const {
            rootStore: { adminStore }
        } = this.props;
        this.setState({isTeamsLoading: true});
        const userIdentifiers = adminStore.userIdentifiers!;
        const jobPayload: JobRequest = {
            user_created_id: userIdentifiers.user_id,
            account_id: currentAccount.accountId,
            job_type: `${JobTypes.TeamUserExport}`,
            additional_params: {
                account_id: currentAccount.accountId,
                team_type: exportTeamType
            }
        };

        const { exportFolder, pickedFolder } = this.state;
        if (exportFolder && pickedFolder) {
            jobPayload.additional_params.folder_id = pickedFolder;
        }

        return this.jobProvider.scheduleJob(jobPayload)
            .then((result) => {
                const newTask: RunningTasks = {
                    account: currentAccount,
                    export_name: `Exporting ${exportTeamType} teams from ${currentAccount.name} ${pickedFolder ? ' with folder fitler' : ''}`,
                    export_id: exportTeam.team_id,
                    type: JobTypes.TeamUserExport,
                    task_id: result.job_id,
                    status: 102,
                    startedAt: Date.now(),
                    elapsedTime: 0,
                    progress: 0,
                    timer: 1500,
                    static_progress_count: 0,
                        set_to_stop: false
                };

                runningTasks.push(newTask);
                adminStore.setCurrentExportTasks(runningTasks, MainTabs.User_Export);
                this.setState({
                    runningTasks,
                    exportTeam: null,
                    exportTeamType: '',
                    isTeamsLoading: false,
                    pickedFolder: null
                });
                this.startTaskWatcher(newTask);
                this.startTimeInterval();
            }).catch((error) => {
                // tslint:disable-next-line:no-console
                console.error(error);
                const { status } = error.response;
                if (status === 401) {
                    const { onAuthError } = this.props;
                    if (!onAuthError) {
                        return;
                    }
                    onAuthError();
                    return;
                }
                this.setState({networkError: true});
            });
    }

    onTaskClear = () => {
        const { runningTasks } = this.state;
        const pendingTasks = runningTasks.filter(e => e.status === 102);
        const {
            rootStore: { adminStore } 
        } = this.props;
        adminStore.setCurrentExportTasks(pendingTasks, MainTabs.User_Export);
        this.setState({runningTasks: pendingTasks});
    }

    onTaskDataPageOverBoundaryReached = (boundary: PageBoundary, nextPage: number): Promise<void> => {
        return new Promise<void>((resolve, reject) => {
            const { 
                currentTasksOffset,
                runningTasks
            } = this.state;
            const totalTasks = runningTasks.length;
            const isFirstPage = nextPage === 0;
            const isLastPage = nextPage === (Math.ceil(totalTasks / TASKS_ROWS_PER_PAGE)-1);
            const newOffset = isFirstPage ? 0
                            : isLastPage ? ACCOUNTS_PAGING_LIMIT * Math.floor(totalTasks / ACCOUNTS_PAGING_LIMIT)
                            : (boundary === PageBoundary.Upper) ? 
                            currentTasksOffset+ACCOUNTS_PAGING_LIMIT :
                            currentTasksOffset-ACCOUNTS_PAGING_LIMIT;
            const nextTasks = runningTasks;
            // MANUAL OFFSET
            this.setState({
                runningTasks: nextTasks.splice(newOffset, nextTasks.length)
            });
        });
    };

    onTaskClick = (task: RunningTasks) => {
        if (task.status === 102) {
            return;
        }
        if(task.status === 200) {
            window.open(task.result_data! as string, '_blank');
            return;
        }
        this.jobProvider.checkJobError(task.task_id)
            .then((data) => {
                this.setState({
                    isErrorModalOpened: true,
                    errorModalHeader: data.error,
                    errorModalText: data.stack_trace
                });
            }).catch((error) => {
                // tslint:disable-next-line:no-console
                console.error(error);
                const { status } = error.response;
                if (status === 401) {
                    const { onAuthError } = this.props;
                    if (!onAuthError) {
                        return;
                    }
                    onAuthError();
                    return;
                }
                this.setState({networkError: true});
            });
    }

    onErrorModalClicked = () => {
        this.setState({isErrorModalOpened: false});   
    }

    onFolderExportEnable = (enabled: boolean) => {
        
        this.setState({exportFolder: enabled});
        if (!enabled) {
            return;
        }
        
        const { currentAccount } = this.state;
        
        if(!currentAccount) {
            return;
        }

        this.setState({
            folderIsLoading: true,
            pickedFolder: null,
            processedFolders: []
        });

        return this.accountProvider.getRootFoldersByAccountId(currentAccount.accountId)
        .then((results) => {
                const folders: Folder[] = results;
                if(!folders) {
                    this.setState({networkError: true});
                    return;
                }

                // TRANSFORM THE FOLDERS INTO TREE VIEW
                const accountTree: RenderTree = {
                    id: currentAccount.accountId,
                    name: currentAccount.name,
                    children: [],
                    path: ''
                };

                const treeNodes: RenderTree[] = folders.map(element => {
                    const newItem = {
                        id: element.id,
                        name: element.name,
                        children: [],
                        path: `${element.id}__`
                    }
                    return newItem as RenderTree;
                });

                accountTree.children = treeNodes;

                this.setState({
                    folderIsLoading: false,
                    folders,
                    currentNodeTree: accountTree
                });

        }).catch((error) => {
            // tslint:disable-next-line:no-console
            console.error(error);
            const { status } = error.response;
            if (status === 401) {
                const { onAuthError } = this.props;
                if (!onAuthError) {
                    return;
                }
                onAuthError();
                return;
            }
            this.setState({networkError: true});
        });
    }

    deepTreeSearch = (treeData: RenderTree, searchId: string) => {
        let foundTree: RenderTree | undefined;
        let searchLevel: RenderTree[] | undefined = treeData.children;
        do {
            foundTree = searchLevel?.find((e: RenderTree) => e.id === searchId);
            let tempLevel: any = [];
            searchLevel?.forEach((element: RenderTree) => {
                tempLevel = tempLevel.concat(element.children);
            });
            searchLevel = tempLevel;
        } while(!foundTree);
        return foundTree;
    }

    updateChildrenOnPath = (treeData: RenderTree, nodeId: string, children: RenderTree[]) => {
        treeData.children!.forEach(element => {
            if (_.isEqual(element.id, nodeId)) {
                element.children = children;
            } else {
                this.updateChildrenOnPath(element, nodeId, children);
            }
        });
    }

    onNodeSelected = (treeData: RenderTree , nodeId: string, nodeLabel: string, updateCallback: (nodeIds: string[]) => void) => {
        const { folders, currentAccount, processedFolders } = this.state;
        if(currentAccount!.accountId === nodeId) {
            this.setState({
                pickedFolder: null
            });
            return;
        }
        this.setState({treeIsLoading: true});
        // CHECK PICK TYPE
        // FOLDER
        const currentNodePos = this.deepTreeSearch(treeData, nodeId);
        if(folders.find(e => e.id === nodeId)) {
            // DID WE ALREADY CHECK FOR CHILDREN?
            if (processedFolders.find(e => e === nodeId)) {
                // FOLDER CHILDREN
                this.setState({
                    pickedFolder: nodeId,
                    treeIsLoading: false
                });
                return;
            }
            // EMPTY
            // CHECK FOR FOLDER CHILDREN FIRST
            return this.accountProvider.getChildrenFolderByFolderId(currentAccount!.accountId, nodeId)
                .then((results: Folder[]) => {
                    if (results.length <= 0) {
                        this.setState({
                            pickedFolder: nodeId,
                            treeIsLoading: false
                        });
                        return;
                    }
                    const nodes: RenderTree[] = results.map(element => {
                        const newItem = {
                            id: element.id,
                            name: element.name,
                            children: [],
                            path: `${currentNodePos.path}${element.id}__`
                        }
                        return newItem as RenderTree;
                    });
                    this.updateChildrenOnPath(treeData, nodeId, nodes);
                    const currentPath = currentNodePos.path.slice(0, -2);
                    updateCallback([currentAccount!.accountId, ...currentPath.split('__'), ...nodes.map(e => e.id)]);
                    processedFolders.push(nodeId);
                    this.setState({
                        pickedFolder: nodeId,
                        currentNodeTree: treeData,
                        treeIsLoading: false,
                        folders: folders.concat(results),
                        processedFolders
                    });
                    return;
            }).catch((error) => {
                // tslint:disable-next-line:no-console
                console.error(error);
                const { status } = error.response;
                if (status === 401) {
                    const { onAuthError } = this.props;
                    if (!onAuthError) {
                        return;
                    }
                    onAuthError();
                    return;
                }
                this.setState({networkError: true});
            });
        }
        
        return;
    }

    onNodeToggled = (nodeIds: string[]) => {
        return;
    }

    public render() {
        const {
            classes
        } = this.props;

        const {
            accounts,
            isAccountsLoading,
            totalAccounts,
            currentAccountOffset,
            currentAccountName,
            managerTeams,
            employeeTeams,
            totalManagerTeams,
            totalEmployeeTeams,
            isTeamsLoading,
            exportTeam,
            exportTeamType,
            networkError,
            runningTasks,
            currentTasksOffset,
            isErrorModalOpened,
            errorModalHeader,
            errorModalText,
            mainSpinnerText,
            dataPageIsLoading,
            treeIsLoading,
            currentNodeTree,
            exportFolder,
            pickedFolder
        } = this.state;

        return (
            <div className={classes.container} data-testid="mainRender">
                <CCSpinner
                    label={mainSpinnerText}
                    labelClassName={classes.mainSpinnerLabel}
                    className={classes.progressContainer}
                    loading={dataPageIsLoading}
                    size={200}
                >
                    <div className={`${(runningTasks.length > 0 ? classes.smallTeamPickerContainer : classes.teamPickerContainer)}`}>
                        <AccountUserExport 
                            className={`${classes.animContainer}`}
                            accountsRowsPerPage={ACCOUNTS_ROWS_PER_PAGE}
                            teamsRowsPerPage={TEAMS_ROWS_PER_PAGE}
                            accounts={accounts}
                            accountsIsLoading={isAccountsLoading}
                            accountsTotalItems={totalAccounts}
                            accountsItemsOffset={currentAccountOffset}
                            currentAccountName={currentAccountName}
                            onAccountClick={this.onAccountClicked}
                            onAccountDataPageOverBoundary={
                                this.onAccountDataPageOverBoundaryReached
                            }      
                            managerTeams={managerTeams}
                            employeeTeams={employeeTeams}
                            totalManagerTeams={totalManagerTeams}
                            totalEmployeeTeams={totalEmployeeTeams}
                            isTeamsLoading={isTeamsLoading}
                            exportTeam={exportTeam}
                            exportTeamType={exportTeamType}
                            onTeamClick={this.onTeamClick}
                            onTeamExportClick={this.onTeamExportClick}
                            onFolderExportEnable={this.onFolderExportEnable}
                            exportFolder={exportFolder}
                            treeIsLoading={treeIsLoading}
                            currentNodeTree={currentNodeTree}
                            pickedFolder={pickedFolder}
                            onNodeSelected={this.onNodeSelected}
                            onNodeToggled={this.onNodeToggled}
                            runningTasks={runningTasks.length}
                            onAccountSearch={this.searchAccounts}
                        />
                    </div>
                    {(runningTasks && (runningTasks.length > 0)) &&
                        <div className={classes.exportTasksContainer}>
                            <RunningTasksList
                                className={classes.tasksContainerSize}
                                tasks={runningTasks}
                                rowsPerPage={TASKS_ROWS_PER_PAGE}
                                taskItemsOffset={currentTasksOffset}
                                listName="Running Exports"
                                onTaskDataPageOverBoundary={this.onTaskDataPageOverBoundaryReached}
                                onTaskClick={this.onTaskClick}
                                onTaskClear={this.onTaskClear}
                            />
                        </div>
                    }
                </CCSpinner>
                <SimpleDialog
                    open={isErrorModalOpened}
                    titleText={errorModalHeader}
                    contentText={errorModalText}
                    buttonCancelLabel=""
                    onDialogResult={this.onErrorModalClicked}
                />
                {
                    networkError &&
                        <SimpleModal
                            className={classes.errorPopup}
                            open={networkError}
                            contentClasses= {classes.errorPopupContentContainer}
                            buttonOkLabel=""
                            buttonCancelLabel=""
                            header = ' '
                            headerClassName={classes.errorPopupHeader}
                        >
                            <Error color="error" className={classes.errorPopupIcon} />
                            <div>
                                <Typography variant="h5">
                                    {'Network Error'}
                                </Typography>
                                <Typography variant="subtitle1">
                                    {'Please reload the page.'}
                                </Typography>
                            </div>
                        </SimpleModal>
                }
            </div>
        );
    }
}

const MUIComponent = withStyles(styles)(UserExport);
export {MUIComponent as UserExport};
