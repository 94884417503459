import {
    createStyles,
    WithStyles,
    withStyles,
    Theme
} from '@material-ui/core/styles';
import { AddCircleOutline } from '@material-ui/icons';
import * as React from 'react';
import UserProvider from '../../providers/user.provider';
import { SimpleList, SimpleListItem } from '../../shared/components/simple-list';
import { EmailSearch } from '../email-search';

export interface userComponent {
    [key: string]: {
        timestamp: string;
        user_id: string;
    }
}

const styles = (theme: Theme) => createStyles({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    list: {
        flex: '1 0',
        overflow: 'auto',
        boxShadow: 'none',
        '& p': {
            whiteSpace: 'break-spaces'
        }
    },
    selectInput: {
        margin: '1em'
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '100%',
    },
    button: {
        margin: theme.spacing(1),
        alignSelf: 'flex-end',
        width: '100%',
    },
    readyItem: {
        color: '#2dd36f',
        marginRight: '0.5em',
        fontSize: '2em'
    },
});

interface Props extends WithStyles<typeof styles> {
    className?: string;
    existingUserList: userComponent;
    userKey: string;
    canEdit: boolean;
    onUserChange?: (userKey: string, userList: userComponent) => void;
}

interface States {
    userList: SimpleListItem[];
    listIsLoading: boolean;
    emailSearch: string;
    searchList: SimpleListItem[];
    searchLoading: boolean;
}

class UserListPicker extends React.Component<Props, States> {

    state: States = {
        userList: [],
        listIsLoading: false,
        emailSearch: '',
        searchList: [],
        searchLoading: false
    }

    userProvider = new UserProvider();

    componentDidMount() {
        this.formatUserList();
    }

    formateDate = (timestring: string) => {
        const timestampDate = new Date(timestring);
        const displayDate = `${timestampDate.toLocaleDateString('en-US')} ${Intl.DateTimeFormat('en', { hour: '2-digit', minute: '2-digit', hour12: true }).format(timestampDate)}`;
        return displayDate;
    }

    formatUserList = () => {
        const { existingUserList } = this.props;

        if (!existingUserList || Object.keys(existingUserList).length <= 0) {
            return;
        }

        const formattedList: SimpleListItem[] = [];
        const userLength = Object.keys(existingUserList).length;
        let userProcessed = 0;

        this.setState({listIsLoading: true});
        for (const key in existingUserList) {
            this.userProvider.fetchUserById(key).then(data => {
                formattedList.push(
                    new SimpleListItem(key, data.user.email, `${key} \n ${existingUserList[key].timestamp ? this.formateDate(existingUserList[key].timestamp) : ''}`)
                );
            }).catch(error => {
                console.error(error);
                formattedList.push(
                    new SimpleListItem(key, 'User not found', `${key} \n ${existingUserList[key].timestamp ? this.formateDate(existingUserList[key].timestamp) : ''}`)
                );
            }).finally(() => {
                userProcessed += 1;
                if (userProcessed === userLength) {
                    this.setState({
                        userList: formattedList,
                        listIsLoading: false
                    })
                }
            });
        }
    }

    onDeleteUserClicked = (listItem: SimpleListItem) => {
        const { onUserChange, existingUserList, userKey } = this.props;
        if (!onUserChange) {
            return;
        }
        
        const { userList } = this.state;
        delete existingUserList[listItem.id];

        onUserChange(userKey, existingUserList);
        
        const delIndex = userList.findIndex(e => e.id === listItem.id);
        userList.splice(delIndex, 1);
        this.setState({userList});
    }

    searchUser = (email: string) => {
        const { classes } = this.props;
        this.setState({searchList: [], emailSearch: email, searchLoading: true});
        this.userProvider.fetchUser(email.toLowerCase()).then(data => {
            const users = data;

            const searchList: SimpleListItem[] = [];
            users.forEach(element => {
                searchList.push(
                    new SimpleListItem(
                        element!.userId!,
                        element.email,
                        element!.userName!,
                        <AddCircleOutline className={ classes.readyItem } data-testid={`add_user_${element!.userId!}`}/>
                    )
                );
            });
            this.setState({searchList});
        }).catch(error => {
            console.error(error);
        }).finally(() => {
            this.setState({searchLoading: false})
        });
    }

    onAddUserClick = (user: SimpleListItem) => {
        const { onUserChange, existingUserList, userKey } = this.props;
        if (!onUserChange) {
            return;
        }
        
        const { userList } = this.state;

        existingUserList[user.id] = {
            timestamp: '',
            user_id: user.id
        };
        userList.push(new SimpleListItem(user.id, user.label, user.id));

        onUserChange(userKey, existingUserList);
        
        this.setState({userList, searchList: []});
    }

    public render() {
        const {
            className,
            classes,
            canEdit,
        } = this.props;
        const {
            userList,
            emailSearch,
            searchList,
            listIsLoading,
            searchLoading
        } = this.state;

        return (
            <div className={`${classes.root} ${className}`} data-testid="module-list">
                <div className={`${classes.container}`}>
                    <SimpleList
                        className={classes.list}
                        items={userList}
                        noItemsLabel={"No users found"}
                        canDeleteListItem={canEdit}
                        keepItemSelected={false}
                        onDeleteItemClick={this.onDeleteUserClicked}
                        isLoading={listIsLoading}
                    />
                    {
                        canEdit &&
                        <div>
                            <EmailSearch
                                classes={{
                                    textField: classes.textField,
                                    button: classes.button
                                }}
                                email={emailSearch}
                                onSearchClick={this.searchUser}
                            />
                            {
                                emailSearch &&
                                    <SimpleList
                                        className={classes.list}
                                        items={searchList}
                                        noItemsLabel={searchLoading ? "Searching..." : "No users found"}
                                        onListItemClick={this.onAddUserClick}
                                        isLoading={searchLoading}
                                    />
                            }
                        </div>
                    }
                </div>
            </div>
        );
    }
}

const MUIComponent = withStyles(styles)(UserListPicker);
export {MUIComponent as UserListPicker}
