import { Button, Typography } from '@material-ui/core';
import {
    createStyles,
    Theme,
    WithStyles,
    withStyles
} from '@material-ui/core/styles';
import {
    Error
} from '@material-ui/icons';
import {
    inject,
    observer
} from 'mobx-react';
import * as React from 'react';
import { AccountTeams, RunningTasksList } from '../../components';
import { TeamForm } from '../../components/team-form';
import { teamForm } from '../../components/team-form/team-form';
import { ModuleGroup } from '../../models/module-group';
import AccountProvider from '../../providers/account.provider';
import JobProvider, { RunningTasks, STATIC_PROGRESS_LIMIT, StatusTypes } from '../../providers/job.provider';
import ModuleGroupProvider from '../../providers/modulegroup.provider';
import TeamProvider from '../../providers/team.provider';
import { CCSpinner } from '../../shared/components/cc-spinner';
import { SimpleDialog } from '../../shared/components/simple-dialog';
import { PageBoundary } from '../../shared/components/simple-grid-pagination';
import { SimpleListItem } from '../../shared/components/simple-list';
import { SimpleListPagination } from '../../shared/components/simple-list-pagination';
import { SimpleModal } from '../../shared/components/simple-modal';
import { Account, Folder, Team } from '../../shared/domain';
import { MainTabs } from '../../stores/admin.store';
import { RootStore } from '../../stores/root.store';

export interface TeamRegisteredChanges {
    field: string;
    label: string;
    value: string;
}

export type formIntent = 'CREATE' | 'EDIT';

const ROWS_PER_PAGE = 10;
const TEAMS_PAGING_LIMIT = 100;
const ACCOUNTS_PAGING_LIMIT = 20;
const EDIT_TEAM_FORM: teamForm[] = [
    {
        field: 'access_code',
        required: true,
        input: 'string'
    },
    {
        field: 'excluded_folders',
        required: false,
        input: 'folders',
        helpLabel: 'Set up folders that the team WILL NOT have access',
        depedentValues: 'folders'
    },
    {
        field: 'folders',
        required: true,
        input: 'folders',
        helpLabel: 'Set up folders that the team WILL have access',
        depedentValues: 'folders'
    },
    {
        field: 'module_groups',
        required: true,
        input: 'modules'
    },
    {
        field: 'site_notifications',
        required: false,
        input: 'boolean'
    },
    {
        field: 'name',
        required: true,
        input: 'string'
    },
    {
        field: 'team_profile',
        required: false,
        input: 'select',
        items: [
            'cleaningmaps2.0'
        ]
    },
    {
        field: 'email_api_config',
        required: false,
        input: 'email'
    }
];
const NEW_TEAM_FORM: teamForm[] = [
    {
        field: 'access_code',
        required: true,
        input: 'string'
    },
    {
        field: 'excluded_folders',
        required: false,
        input: 'folders',
        helpLabel: 'Set up folders that the team WILL NOT have access',
        depedentValues: 'folders'
    },
    {
        field: 'name',
        required: true,
        input: 'string'
    },
    {
        field: 'site_notifications',
        required: false,
        input: 'boolean'
    },
    {
        field: 'folders',
        required: true,
        input: 'folders',
        helpLabel: 'Set up folders that the team WILL have access',
        depedentValues: 'folders'
    },
    {
        field: 'team_profile',
        required: true,
        input: 'select',
        items: [
            'cleaningmaps2.0'
        ]
    },
    {
        field: 'email_api_config',
        required: false,
        input: 'email'
    }
];
const EDIT_TEAM_HIDDEN_ATTRIBUTES = [
    'actions',
    'channels',
    'filters',
    'structures',
    'structure_id',
    'jobs',
    'accounts',
    '_rev',
    'type',
];
const NEW_TEAM_HIDDEN_ATTRIBUTES = [
    'actions',
    'channels',
    'filters',
    'structures',
    'structure_id',
    'jobs',
    'accounts',
    '_rev',
    'type',
    'id',
    'members',
    'module_groups',
    'timestamp',
    'team_id',
    'sso_new_users_default_team'
];

const styles = (theme: Theme) => createStyles({
    [theme.breakpoints.down('lg')]: {
        formContainer: {
            width: '35% !important'
        }
    },
    container: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row'
    },
    analyticsTabList: {
        height: '18.2em'
    },
    errorPopup: {
        width: '98%',
        borderRadius: 5
    },
    errorPopupHeader: {
        height: '0.6em',
        backgroundColor: theme.palette.error.main,
        borderRadius: '3px 3px 0 0'
    },
    errorPopupContentContainer :{
        display: 'flex',
        flexDirection: 'row',
        padding: theme.spacing(1),
    },
    errorPopupIcon: {
        fontSize: 40,
        marginRight: '1em'
    },
    mainSpinnerLabel: {
        paddingBottom: '30px'
    },
    progressContainer: {
        flexGrow: 1
    },
    animContainer: {
        transition: 'width 1s'
    },
    listIcon: {
        marginRight: '0.5em',
        fontSize: '1em'
    },
    accountMediumSize: {
        width: 'auto'
    },
    accountSmallSize: {
        width: 'auto',
    },
    formContainer: {
        width: '40%',
        marginRight: '1em',
    },
    tasksContainerSize: {
        marginLeft: 'auto'
    },
    resumePopup: {
        width: '41em',
        borderRadius: 5
    },
    resumePopupHeader: {
        height: '2em',
        backgroundColor: theme.palette.primary.main,
        borderRadius: '3px 3px 0 0',
        color: 'white',
        padding: '0.5em',
        fontSize: '1.5em'
    },
    popupContentContainer :{
        display: 'flex',
        flexDirection: 'row',
        padding: theme.spacing(1),
    },
    simpleList: {
        overflow: 'auto',
        height: '100%',
        minWidth: '35em',
        margin: '1em 0'
    },
    modalButtonContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row'
    },
    modalButton: {
        width: '49%',
        margin: '0 1em'
    },
});

interface Props extends WithStyles<typeof styles> {
    rootStore: RootStore;
    onAuthError?: () => void;
};

interface States {
    accounts: Account[];
    currentAccount: Account | null;
    currentAccountOffset: number;
    networkError: boolean;
    dataPageIsLoading: boolean;
    isAccountsLoading: boolean;
    totalAccounts: number;
    mainSpinnerText: string;
    teams: Team[] | null;
    totalTeams: number;
    isTeamsLoading: boolean;
    currentTeam: Team | null;
    currentTeamOffset: number;
    moduleGroups: ModuleGroup[];
    isCreate: boolean;
    editForm: teamForm[];
    hiddenAttributes: string[];
    isFormLoading: boolean;
    runningTasks: RunningTasks[];
    currentTasksOffset: number;
    timeElapser: number | null;
    isErrorModalOpened: boolean;
    errorModalHeader: string;
    errorModalText: string;
    isTeamSuccessModal: boolean;
    teamSuccessModalText: string;
    accountModuleGroups: ModuleGroup[];
    registeredChanges: TeamRegisteredChanges[];
    formTeam: Team | null;
    isConfirmChanges: boolean;
    submitFormIntent: formIntent;
    isFoldersLoading: boolean;
    accountFolders: Folder[];
    searchAccountName: string;
    storedAccounts: Account[];
    storedAccountOffset: number;
    storedAccountTotalCount: number;
}

@inject('rootStore')
@observer
class Teams extends React.Component<Props, States> {
    public static defaultProps: Partial<Props> = {
    };

    state: States = {
        accounts: [],
        currentAccount: null,
        currentAccountOffset: 0,
        networkError: false,
        dataPageIsLoading: true,
        isAccountsLoading: false,
        totalAccounts: 0,
        mainSpinnerText: 'Please wait. Loading data.',
        teams: [],
        totalTeams: 0,
        isTeamsLoading: false,
        currentTeam: null,
        currentTeamOffset: 0,
        moduleGroups: [],
        isCreate: false,
        isFormLoading: false,
        runningTasks: [],
        currentTasksOffset: 0,
        timeElapser: null,
        isErrorModalOpened: false,
        errorModalHeader: '',
        errorModalText: '',
        editForm: [],
        hiddenAttributes: [],
        isTeamSuccessModal: false,
        teamSuccessModalText: '',
        accountModuleGroups: [],
        registeredChanges: [],
        formTeam: null,
        isConfirmChanges: false,
        submitFormIntent: 'CREATE',
        accountFolders: [],
        isFoldersLoading: false,
        searchAccountName: '',
        storedAccounts: [],
        storedAccountOffset: 0,
        storedAccountTotalCount: 0
    };

    accountProvider = new AccountProvider();
    moduleGroupProvider = new ModuleGroupProvider();
    jobProvider = new JobProvider();
    teamProvider = new TeamProvider();

    componentDidMount() {
        this.fetchAccounts(this.state.currentAccountOffset);
        this.fetchRunningTasks();
        this.startTimeInterval();
    };
  
    componentWillUnmount() {
        const {
            rootStore: { adminStore }
        } = this.props;   
        const { runningTasks } = this.state;
        this.clearIntervals(runningTasks);
        adminStore.setCurrentExportTasks(runningTasks, MainTabs.Teams);
        this.clearTimeElapserInterval();
    }

    clearIntervals(tasks: RunningTasks[]) {
        tasks.forEach((element) => {
            element.set_to_stop = true;
        });
    }

    clearTimeElapserInterval() {
        const { timeElapser } = this.state;
        if( timeElapser ) {
            window.clearInterval(timeElapser);
        }
    }

    startTimeInterval() {
        const { timeElapser } = this.state;
        if (timeElapser) {
            return;
        }
        const timeElapserInterval = window.setInterval(() => {
            const { runningTasks } = this.state;
            const time = Date.now();
            const processing = runningTasks.filter(e => e.status === 102);
            processing.forEach(element => {
                element.elapsedTime = Math.round((time - element.startedAt) / 1000);
            });
            this.setState({runningTasks});
        }, 1000);
        this.setState({timeElapser: timeElapserInterval});
    }

    fetchRunningTasks = () => {
        const {
            rootStore: { adminStore }
        } = this.props;
        const tasks = adminStore.currentExportTasks.find(e => e.page === MainTabs.Teams);
        this.setState({
            runningTasks: tasks!.tasks
        }, () => {
            tasks!.tasks.forEach(element => {
                if(element.status === 102) {
                    // SET TO RESTART POOLING
                    element.set_to_stop = false;
                    this.startTaskWatcher(element);
                }
            });
        });
    }

    startTaskWatcher = (task: RunningTasks) => {
        const { runningTasks } = this.state;

        if (!task.export_name || !task.task_id || !task.export_id) {
            return;
        }

        const statusCallback = (taskId: string) => {
            const taskIndex = runningTasks.findIndex(e => e.task_id === taskId);
            const taskItem = runningTasks.find(e => e.task_id === taskId);

            if (!taskItem || taskItem.status !== 102) {
                return;
            }

            if (taskItem.set_to_stop) {
                return;
            }   
            
            this.jobProvider.checkJobStatus(taskItem.task_id).then(data => {
                if (data.job_status === StatusTypes.Error) {
                    taskItem.status = 500;
                    runningTasks[taskIndex] = taskItem;
                    this.setState({runningTasks});
                }
                if (data.job_status === StatusTypes.Complete) {
                    taskItem.status = 200;
                    taskItem.progress = 100.0;
                    this.jobProvider.checkJobResult(taskItem.task_id).then(res => {
                        runningTasks[taskIndex] = taskItem;
                        this.setState({runningTasks});
                    }).catch((error) => {
                        const { status } = error.response;
                        // CHECK IF WE TIMEOUT OR NOT
                        if (status === 408 || status === 504) {
                            // WE HAVE TIMEOUT INCREASE TIMER AND KEEP TRYING
                            taskItem.timer += 1000;
                            setTimeout(taskItem.internalCallback!, taskItem.timer, taskId);
                            return;
                        }
                        taskItem.status = 500;
                        runningTasks[taskIndex] = taskItem;
                        this.setState({runningTasks});
                    });
                } else {
                    // KEEP TRACK OF THE PROGRESS STATUS IF ITS STILL THE SAME
                    if (taskItem.progress === data.estimate_percent_complete) {
                        taskItem.static_progress_count += 1;
                    }
                    taskItem.progress = data.estimate_percent_complete;
                    // IF WE REACH A POINT WHERE THE STATUS IS THE SAME THE JOB IS SLOW
                    // MAKE FEWER REQUESTS TO NOT OVERWHELM THE STATUS ENDPOINT
                    if (taskItem.static_progress_count === STATIC_PROGRESS_LIMIT) {
                        taskItem.timer += 1000;
                        taskItem.static_progress_count = 0;
                    }
                    setTimeout(taskItem.internalCallback!, taskItem.timer, taskId);
                }
            }).catch((error) => {
                const { status } = error.response;
                // CHECK IF WE TIMEOUT OR NOT
                if (status === 408 || status === 504) {
                    // WE HAVE TIMEOUT INCREASE TIMER AND KEEP TRYING
                    taskItem.timer += 1000;
                    setTimeout(taskItem.internalCallback!, taskItem.timer, taskId);
                    return;
                }
                taskItem.status = 500;
                runningTasks[taskIndex] = taskItem;
                this.setState({runningTasks});
            });
        }

        // PREVENT GENERATING TIMERS WITHOUT PROPERTIES BEING INITIALIZED
        const taskIndex = runningTasks.findIndex(e => e.task_id === task.task_id);
        runningTasks[taskIndex].internalCallback = statusCallback;
        this.setState({runningTasks}, () => {
            setTimeout(statusCallback, task.timer, task.task_id);
        });
    }

    fetchAccounts = (offset:number, limit: number = ACCOUNTS_PAGING_LIMIT) => {
        return this.accountProvider.getAllAccounts(offset, limit)
          .then((results) => {
            const {accounts, totalCount} = results;
            if (!accounts) {
                if (offset === 0) {
                    this.setState({networkError: true});
                }
                return;
            }
            this.fetchModuleGroups();
            this.setState({
                isAccountsLoading: false,
                totalAccounts: totalCount,
                accounts,
                currentAccountOffset: offset
            });
        }).catch((error) => {
            const { status } = error.response;
            if (status === 401) {
                const { onAuthError } = this.props;
                if (!onAuthError) {
                    return;
                }
                onAuthError();
                return;
            }
            this.setState({networkError: true});
        });
    }

    searchAccounts = (searchName: string, offset?: number) => {
        // Nothing to search load the normal account list
        if (searchName === '') {
            const {
                storedAccounts,
                storedAccountOffset,
                storedAccountTotalCount
            } = this.state;

            // Empty search do nothing
            if (!storedAccounts.length) {
                return;
            }

            // Going back from actual seach
            this.setState({
                accounts: storedAccounts,
                currentAccountOffset: storedAccountOffset,
                totalAccounts: storedAccountTotalCount,
                searchAccountName: '',
                storedAccounts: [],
                storedAccountOffset: 0,
                storedAccountTotalCount: 0
            });
            return;
        }

        // We have a name search
        const {
            currentAccountOffset,
            accounts,
            totalAccounts,
            storedAccounts
        } = this.state;
        this.setState({
            isAccountsLoading: true,
            searchAccountName: searchName
        }, () => {
            const searchOffset = offset ? offset : 0;
            return this.accountProvider
                .searchAccountByNameShortSchema(searchName, searchOffset, ACCOUNTS_PAGING_LIMIT)
                .then(results => {
                    const { accounts: foundAccounts, totalCount } = results;
                    if (!foundAccounts) {
                        if (currentAccountOffset === 0) {
                            this.setState({ networkError: true });
                        }
                        return;
                    }
    
                    // If we haven't stored the original list do it
                    // This prevents multiple searchs overriding each others
                    if (!storedAccounts.length) {
                        this.setState({
                            storedAccounts: accounts,
                            storedAccountTotalCount: totalAccounts,
                            storedAccountOffset: currentAccountOffset
                        })
                    }

                    this.setState({
                        isAccountsLoading: false,
                        totalAccounts: totalCount,
                        accounts: foundAccounts,
                        currentAccountOffset: searchOffset
                    });
                })
                .catch(error => {
                    const { status } = error.response;
                    if (status === 401) {
                        const { onAuthError } = this.props;
                        if (!onAuthError) {
                            return;
                        }
                        onAuthError();
                        return;
                    }
                    this.setState({ networkError: true });
                });
        });
    }

    fetchModuleGroups = () => {
        return this.moduleGroupProvider.fetchModuleGroups()
          .then((results) => {
            const {modules} = results;

            this.setState({
                moduleGroups: modules,
                dataPageIsLoading: false
            });
        }).catch((error) => {
            const { status } = error.response;
            if (status === 401) {
                const { onAuthError } = this.props;
                if (!onAuthError) {
                    return;
                }
                onAuthError();
                return;
            }
            this.setState({networkError: true});
        });
    }

    onAccountDataPageOverBoundaryReached = (boundary: PageBoundary, nextPage: number): Promise<void> => {
        return new Promise<void>((resolve, reject) => {
            const { 
                currentAccountOffset,
                totalAccounts
            } = this.state;
            const isFirstPage = nextPage === 0;
            const isLastPage = nextPage === (Math.ceil(totalAccounts / ROWS_PER_PAGE)-1);
            const newOffset = isFirstPage ? 0
                            : isLastPage ? ACCOUNTS_PAGING_LIMIT * Math.floor(totalAccounts / ACCOUNTS_PAGING_LIMIT)
                            : (boundary === PageBoundary.Upper) ? 
                                currentAccountOffset+ACCOUNTS_PAGING_LIMIT :
                                currentAccountOffset-ACCOUNTS_PAGING_LIMIT;
            this.setState({isAccountsLoading: true});
            this.fetchAccounts(newOffset)
              .then(() => resolve())
              .catch(()=> reject());
        });
    };

    onAccountClicked = (account: Account) => {
        const { currentAccount } = this.state;
        if (currentAccount === account) {
            return;
        }

        this.setState({
            currentAccount: account,
            currentTeam: null
        });
        this.fetchTeamsFromAccount(account, this.state.currentTeamOffset);
        this.fetchAccountModuleGroups(account);
        this.fetchAccountFolders(account);
    };

    fetchTeamsFromAccount = (account: Account, offset:number, limit: number = TEAMS_PAGING_LIMIT) => {
        this.setState({isTeamsLoading: true});

        return this.accountProvider.fetchTeamsFromAccountIdAdmin(account.accountId, offset, limit)
          .then((results) => {
            const { teams } = results;
            if (!teams) {
                if (offset === 0) {
                    this.setState({networkError: true});
                }
                return;
            }

            this.setState({
                currentTeamOffset: offset,
                currentAccount: account,
                isTeamsLoading: false,
                teams: teams,
                totalTeams: teams.length
            });
        }).catch((error) => {
            const { status } = error.response;
            if (status === 401) {
                const { onAuthError } = this.props;
                if (!onAuthError) {
                    return;
                }
                onAuthError();
                return;
            }
            this.setState({networkError: true});
        });
    };

    fetchAccountModuleGroups = (account: Account) => {
        this.setState({accountModuleGroups: []});
        this.accountProvider.fetchModuleGroupsFromAccountId(account.accountId)
            .then((data) => {
                const { module_groups } = data;
                this.setState({
                    accountModuleGroups: module_groups
                });
            }).catch((error) => {
                // tslint:disable-next-line:no-console
                console.error(error);
                const { status } = error.response;
                if (status === 401) {
                    const { onAuthError } = this.props;
                    if (!onAuthError) {
                        return;
                    }
                    onAuthError();
                    return;
                }
                this.setState({networkError: true});
            });
    };

    fetchAccountFolders = (account: Account) => {
        this.setState({isFoldersLoading: true});
        this.accountProvider.getFoldersByAccountId(account.accountId).then(data => {
            this.setState({
                accountFolders: data,
                isFoldersLoading: false
            })
        });
    };

    onTeamDataPageOverBoundaryReached = (boundary: PageBoundary, nextPage: number): Promise<void> => {
        return new Promise<void>((resolve, reject) => {
            const {
                currentAccount,
                currentTeamOffset,
                totalTeams
            } = this.state;
            
            if (!currentAccount) {
                return;
            
            }
            const isFirstPage = nextPage === 0;
            const isLastPage = nextPage === (Math.ceil(totalTeams / ROWS_PER_PAGE)-1);
            const newOffset = isFirstPage ? 0
                            : isLastPage ? TEAMS_PAGING_LIMIT * Math.floor(totalTeams / TEAMS_PAGING_LIMIT)
                            : (boundary === PageBoundary.Upper) ? 
                                currentTeamOffset+TEAMS_PAGING_LIMIT :
                                currentTeamOffset-TEAMS_PAGING_LIMIT;
            this.setState({isTeamsLoading: true});
            this.fetchTeamsFromAccount(currentAccount, newOffset)
               .then(()=> resolve())
               .catch(() => reject());
        });
    };

    onTeamClicked = (team: Team) => {
        const { currentTeam } = this.state;

        this.setState({currentTeam: null, isFormLoading: true});
        if (currentTeam === team) {
            return;
        }
        setTimeout(() => {
            this.setState({currentTeam: team, isCreate: false, editForm: EDIT_TEAM_FORM, isFormLoading: false, hiddenAttributes: EDIT_TEAM_HIDDEN_ATTRIBUTES});
        }, 500);
    }

    onEditSubmit = (team: Team) => {
        const { currentTeam, teams } = this.state;

        if(!currentTeam || !team || !teams) {
            return;
        }

        this.setState({dataPageIsLoading: true, isConfirmChanges: false, registeredChanges: []});
        this.teamProvider.updateTeam(currentTeam.team_id!, team).then(data => {
            const { teams, currentAccount, runningTasks } = this.state;

            const updatedIndex = teams!.findIndex(e => e.team_id === team.team_id);
            teams![updatedIndex] = team;
            this.setState({
                teams,
                currentTeam: null
            });

            if (!data.team.jobs) {
                this.setState({isTeamSuccessModal: true, teamSuccessModalText: 'Team Successfully Updated'});
                return;
            }

            const {
                rootStore: { adminStore }
            } = this.props;
            const jobId = Object.keys(data.team.jobs)[0];
            
            if (!jobId) {
                this.setState({isTeamSuccessModal: true, teamSuccessModalText: 'Team Successfully Updated'});
                return;
            }

            const newTask: RunningTasks = {
                account: currentAccount,
                export_name: `Updating team user permission for: ${team.name}`,
                export_id: team.team_id!,
                type: 0,
                task_id: jobId,
                status: 102,
                startedAt: Date.now(),
                elapsedTime: 0,
                progress: 0,
                timer: 1500,
                static_progress_count: 0,
                        set_to_stop: false
            };
            runningTasks.push(newTask);
            adminStore.setCurrentExportTasks(runningTasks, MainTabs.Teams);
            this.startTaskWatcher(newTask);
            this.startTimeInterval();
            return;
        }).catch(error => {
            console.error(error);
            const { status } = error.response;
            if (status === 401) {
                const { onAuthError } = this.props;
                if (!onAuthError) {
                    return;
                }
                onAuthError();
                return;
            }
            this.setState({
                isErrorModalOpened: true,
                errorModalHeader: "Error on team update",
                errorModalText: error.toString()
            });
        }).finally(() => {
            this.setState({dataPageIsLoading: false});
        })
    }

    onCreateSubmit = (team: Team) => {
        const { currentTeam, teams } = this.state;

        if(!currentTeam || !team || !teams) {
            return;
        }

        this.setState({dataPageIsLoading: true, isConfirmChanges: false, registeredChanges: []});
        this.teamProvider.createTeam(team).then(data => {
            const { teams, currentAccount, runningTasks } = this.state;
            const { team } = data;
            teams!.push(team);
            
            const {
                rootStore: { adminStore }
            } = this.props;

            if (!team.jobs) {
                this.setState({
                    isTeamSuccessModal: true,
                    teamSuccessModalText: 'Team Successfully Created',
                    teams,
                    currentTeam: null
                });
                return;
            }
            const jobId = Object.keys(team.jobs)[0];
            if (!jobId) {
                this.setState({
                    isTeamSuccessModal: true,
                    teamSuccessModalText: 'Team Successfully Created',
                    teams,
                    currentTeam: null
                });
                return;
            }
            const newTask: RunningTasks = {
                account: currentAccount,
                export_name: `Creating team: ${team.name}`,
                export_id: team.team_id!,
                type: 0,
                task_id: jobId,
                status: 102,
                startedAt: Date.now(),
                elapsedTime: 0,
                progress: 0,
                timer: 1500,
                static_progress_count: 0,
                        set_to_stop: false
            };
            runningTasks.push(newTask);
            adminStore.setCurrentExportTasks(runningTasks, MainTabs.Teams);
            this.startTaskWatcher(newTask);
            this.startTimeInterval();
            this.setState({
                teams,
                currentTeam: null
            });
            return;
        }).catch(error => {
            console.error(error);
            const { status } = error.response;
            if (status === 401) {
                const { onAuthError } = this.props;
                if (!onAuthError) {
                    return;
                }
                onAuthError();
                return;
            }
            this.setState({
                isErrorModalOpened: true,
                errorModalHeader: "Error on team update",
                errorModalText: error.toString()
            });
        }).finally(() => {
            this.setState({dataPageIsLoading: false});
        })
    }

    onTaskClear = () => {
        const { runningTasks } = this.state;
        const pendingTasks = runningTasks.filter(e => e.status === 102);
        const {
            rootStore: { adminStore }
        } = this.props;
        adminStore.setCurrentExportTasks(pendingTasks, MainTabs.Teams);
        this.setState({runningTasks: pendingTasks});
    }

    onErrorModalClicked = () => {
        this.setState({isErrorModalOpened: false});   
    }

    onTaskClick = (task: RunningTasks) => {
        if (task.status === 102) {
            return;
        }
        if(task.status === 200) {
            return;
        }
        this.jobProvider.checkJobError(task.task_id)
            .then((data) => {
                this.setState({
                    isErrorModalOpened: true,
                    errorModalHeader: data.error,
                    errorModalText: data.stack_trace
                });
            }).catch((error) => {
                // tslint:disable-next-line:no-console
                console.error(error);
                this.setState({networkError: true});
            });
    }

    onStartAddTeamClick = () => {
        const { currentAccount } = this.state;

        if (!currentAccount) {
            return;
        }

        this.setState({currentTeam: null, isFormLoading: true});

        // CREATE A SKELETON OBJECT
        const newTeam = {
            name: '',
            access_code: '',
            site_notifications: false,
            accounts: [
                currentAccount.accountId
            ],
            actions: {
                access: {
                    management_app: false,
                    portal: false,
                },
                common: {
                    agree: true,
                    assign: false,
                    categorize: false,
                    comment: true,
                    delete_comments: false,
                    delete_reports: false,
                    marker_omniscience: true,
                    marker_visibility: true,
                    modify_folders: false,
                    modify_markers: false,
                    schedule: false,
                    status: false,
                },
                meta: {
                    create_account: false,
                    modify_account: false,
                    modify_floorplans: false,
                    modify_teams: false,
                    modify_users: false,
                },  
            },
            channels: [],
            excluded_folders: [],
            filters: [],
            folders: [],
            members: {},
            module_groups: {
                read: {},
                write: {}
            },
            structures: currentAccount.structures,
            team_profile: [],
            type: "team",
            structure_id: currentAccount.structures[0],
            email_api_config: {
                default: [],
                negative_user_feedback: []
            },
            sso_new_users_default_team: false,
            team_id: '',
            timestamp: ''
        }

        setTimeout(() => {
            this.setState({
                isCreate: true,
                currentTeam: newTeam as Team,
                editForm: NEW_TEAM_FORM,
                isFormLoading: false,
                hiddenAttributes: NEW_TEAM_HIDDEN_ATTRIBUTES
            });
        }, 500);
    }

    onTeamUpdatedClick = () => {
        this.setState({isTeamSuccessModal: false, teamSuccessModalText: ''});
    }

    onFormSubmit = (team: Team, registeredChanges: TeamRegisteredChanges[], intent: formIntent) => {
        this.setState({
            formTeam: team,
            registeredChanges,
            submitFormIntent: intent,
            isConfirmChanges: true
        });
    }

    closeResumeModal = () => {
        this.setState({ isConfirmChanges: false });
    }

    onSubmitTeamChanges = () => {
        const {formTeam, submitFormIntent} = this.state;

        if (!formTeam) {
            return;
        }

        if (submitFormIntent === 'CREATE') {
            this.onCreateSubmit(formTeam);
            return;
        }

        this.onEditSubmit(formTeam);
        return;
    }

    public render() {
        const { classes } = this.props;
        const {
            accounts,
            currentAccount,
            currentAccountOffset,
            networkError,
            dataPageIsLoading,
            mainSpinnerText,
            isAccountsLoading,
            totalAccounts,
            teams,
            totalTeams,
            isTeamsLoading,
            currentTeam,
            currentTeamOffset,
            isCreate,
            editForm,
            moduleGroups,
            isFormLoading,
            runningTasks,
            currentTasksOffset,
            isErrorModalOpened,
            errorModalHeader,
            errorModalText,
            isTeamSuccessModal,
            teamSuccessModalText,
            hiddenAttributes,
            accountModuleGroups,
            isConfirmChanges,
            registeredChanges,
            accountFolders,
            isFoldersLoading
        } = this.state;

        const currentAccountName = currentAccount ? currentAccount.name : '';
        const changesList = registeredChanges ? registeredChanges.map(e => {
            return new SimpleListItem(e.field, e.label, e.value);
        }) : [];

        return (
            <div className={classes.container} data-testid="mainRender">
                <CCSpinner
                    label={mainSpinnerText}
                    labelClassName={classes.mainSpinnerLabel}
                    className={classes.progressContainer}
                    loading={dataPageIsLoading}
                    size={200}
                >
                    <AccountTeams
                        className={`${classes.animContainer} ${currentTeam ? classes.accountSmallSize : classes.accountMediumSize}`}
                        rowsPerPage={ROWS_PER_PAGE}
                        accounts={accounts}
                        accountsIsLoading={isAccountsLoading}
                        accountsTotalItems={totalAccounts}
                        accountsItemsOffset={currentAccountOffset}
                        currentAccountName={currentAccountName}
                        onAccountClick={this.onAccountClicked}
                        onAccountDataPageOverBoundary={
                            this.onAccountDataPageOverBoundaryReached
                        }
                        teams={teams}
                        totalTeams={totalTeams}
                        isTeamsLoading={isTeamsLoading}
                        currentTeam={currentTeam}
                        currentTeamOffset={currentTeamOffset}
                        onTeamClick={this.onTeamClicked}
                        onTeamDataPageOverBoundary={this.onTeamDataPageOverBoundaryReached}
                        startAddTeam={this.onStartAddTeamClick}
                        onAccountSearch={this.searchAccounts}
                    />
                    {
                        currentTeam && currentAccount &&
                        <CCSpinner
                            labelClassName={classes.mainSpinnerLabel}
                            className={classes.progressContainer}
                            loading={isFormLoading || isFoldersLoading}
                            size={200}
                        >
                            <TeamForm 
                                className={`${classes.animContainer} ${classes.formContainer}`}
                                currentAccount={currentAccount}
                                pickedTeam={currentTeam}
                                isCreate={isCreate}
                                moduleGroups={moduleGroups}
                                accountModuleGroups={accountModuleGroups}
                                accountFolders={accountFolders}
                                isAccountFoldersLoading={isFoldersLoading}
                                formFields={editForm}
                                hiddenAttributes={hiddenAttributes}
                                onFormSubmit={this.onFormSubmit}
                            />
                        </CCSpinner>
                    }
                    {
                        (runningTasks && (runningTasks.length > 0)) &&
                        <RunningTasksList
                            className={classes.tasksContainerSize}
                            tasks={runningTasks}
                            rowsPerPage={ROWS_PER_PAGE}
                            taskItemsOffset={currentTasksOffset}
                            listName="Running Exports"
                            onTaskClick={this.onTaskClick}
                            onTaskClear={this.onTaskClear}
                        />
                    }
                </CCSpinner>
                <SimpleDialog
                    open={isErrorModalOpened}
                    titleText={errorModalHeader}
                    contentText={errorModalText}
                    buttonCancelLabel=""
                    onDialogResult={this.onErrorModalClicked}
                />
                <SimpleModal
                    className={classes.resumePopup}
                    open={isConfirmChanges}
                    contentClasses={classes.popupContentContainer}
                    buttonOkLabel=""
                    buttonCancelLabel=""
                    header='Team changes resume'
                    headerClassName={classes.resumePopupHeader}
                >
                    <div>
                        <Typography variant="subtitle1">
                        Please review the team changes before submitting, 
                        once you are sure they are correct click on the submit button
                        </Typography>
                        <SimpleListPagination
                            className={classes.simpleList}
                            keepItemSelected={true}
                            items={changesList}
                            isLoading={false}
                            rowsPerPage={ROWS_PER_PAGE}
                            totalItems={changesList.length}
                            offset={0}
                        />
                        <div className={classes.modalButtonContainer}>
                            <Button
                                variant="contained"
                                color="secondary"
                                className={classes.modalButton}
                                onClick={this.closeResumeModal}
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                className={classes.modalButton}
                                onClick={this.onSubmitTeamChanges}
                                data-testid="submit-changes-button"
                            >
                                Submit
                            </Button>
                        </div>
                    </div>
                </SimpleModal>
                {
                    isTeamSuccessModal &&
                    <SimpleDialog
                        open={isTeamSuccessModal}
                        titleText="Success"
                        contentText={teamSuccessModalText}
                        buttonCancelLabel=""
                        onDialogResult={this.onTeamUpdatedClick}
                    />
                }
                {
                    networkError &&
                        <SimpleModal
                            className={classes.errorPopup}
                            open={networkError}
                            contentClasses= {classes.errorPopupContentContainer}
                            buttonOkLabel=""
                            buttonCancelLabel=""
                            header=''
                            headerClassName={classes.errorPopupHeader}
                        >
                            <Error color="error" className={classes.errorPopupIcon} />
                            <div>
                                <Typography variant="h5">
                                    {'Network Error'}
                                </Typography>
                                <Typography variant="subtitle1">
                                    {'Please reload the page.'}
                                </Typography>
                            </div>
                        </SimpleModal>
                }
            </div>
        );
    }
}

const MUIComponent = withStyles(styles)(Teams);
export {MUIComponent as Teams}