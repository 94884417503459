import {
    Button,
    Chip,
    Drawer,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Snackbar,
    // TextField
} from '@material-ui/core';
import {
    createStyles,
    WithStyles,
    withStyles
} from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import _ from 'lodash';
import memoize from 'memoize-one';
import {
    inject,
    observer
} from 'mobx-react';
import * as React from 'react';
import { AccountFolderMarker, DeleteConfirmationPrompt, FolderIdFloorplans, RunningTasksList } from '../../components';
import { FloorplansList } from '../../components/floor-plans-list';
import { FloorplanForm } from '../../components/floorplan-form';
import { floorplanForm } from '../../components/floorplan-form/floorplan-form';
import {
    FloorPlanGenerator
} from '../../components/markers-steps';
import { MarkersListItem } from '../../components/qr-code-generator';
;
import {
    FloorplanFull,
    Marker
} from '../../models';
import {
    FloorPlanStatusResponseResultsItem
} from '../../models/floor-plan-responses';
import AccountProvider from '../../providers/account.provider';
import FloorplanProvider from '../../providers/floorplan.provider';
import FolderProvider from '../../providers/folder.provider';
import JobProvider, { JobRequest, JobTypes, RunningTasks, STATIC_PROGRESS_LIMIT, StatusTypes } from '../../providers/job.provider';
import MarkerProvider from '../../providers/marker.provider';
import { CCSpinner } from '../../shared/components/cc-spinner';
import { RenderTree } from '../../shared/components/node-tree';
import { SimpleDialog } from '../../shared/components/simple-dialog';
import { PageBoundary } from '../../shared/components/simple-grid-pagination';
import {
    TabControl,
    TabControlOrientation,
    TabDescriptor
} from '../../shared/components/tab-control';
import { Account, Floorplan, Folder } from '../../shared/domain';
import { MainTabs } from '../../stores/admin.store';
import { RootStore } from '../../stores/root.store';
import { convertMarkersResultIntoMarkerList } from '../../utils/markers-utilities';
import { PickedType } from '../marker-export/marker-export';

const ROWS_PER_PAGE = 10;
const TASKS_ROWS_PER_PAGE = 10;
const TASKS_PAGING_LIMIT = 100;
const ACCOUNTS_PAGING_LIMIT = 20;
const COMPRESSED_NOTIFICATION = 'This floorplan was compressed because it excedeed the optimal size (6MB)';
const EDIT_FLOORPLAN_FORM: floorplanForm[] = [
    {
        field: 'name',
        required: true,
        input: 'string'
    },
    {
        field: 'prefix',
        required: true,
        input: 'string'
    }
];
const EDIT_FLOORPLAN_HIDDEN_ATTRIBUTES = [
    '_rev',
    'channels',
    'floorplans_data',
    'structure_id',
    'jobs',
    'type',
];

interface JobResultDataset {
    status: string;
    result: string;
}

enum SearchType {
    None = 0,
    folderId,
    treeView
}

enum CurrentTabIndex {
    create = 0,
    minimap,
    edit,
    replace
}

const styles = (theme: Theme) => createStyles({
    root: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: '1em',
        width: '100%'
    },
    stepper: {
        backgroundColor: 'transparent'
    },
    spinnerOverlay: {
        zIndex: 2000
    },
    searchFloorPlansButton: {
        marginTop: '2em',
        marginLeft: '1em'
    },
    downloadTemplatesImages: {
        marginTop: '2em',
        marginLeft: '3em'
    },
    tabContent: {
        padding: '2em',
        minHeight: '100%'
    },
    slimTabContent: {
        padding: '1em'
    },
    floorPlanIdTextField: {
        width: '20em'
    },
    searchFloorPlansContainer: {
        marginBottom: '1em',
        display: 'flex',
        alignItems: 'end',
    },
    simpleSearchFloorPlansContainer: {
        marginBottom: '1em',
        display: 'flex',
        alignItems: 'start',
        flexDirection: 'column'
    },
    fullContainerSize: {
        width: '100%'
    },
    semiContainerSize: {
        width: '70%'
    },
    tasksContainerSize: {
        width: '30em',
        padding: '0 1em',
    },
    animContainer: {
        transition: 'all 1s'
    },
    snackBar: {
        alignItems: 'flex-start',
        top: '1em',
        right: 0
    },
    snackBarMessage: {
        fontWeight: 'bolder',
        textAlign: 'end'
    },
    [theme.breakpoints.down('lg')]: {
        downloadTemplatesImages: {
            marginLeft: '1em'
        },
    },
    selectorContainer: {
        marginTop: '1em'
    },
    searchSelectHolder: {
        width: '25em',
    },
    treeSelectorHolder: {
        width: '60em'
    },
    onlyTreeSelectorHolder: {
        width: '35em'
    },
    inputSearch: {
        width: '17.5em'
    },
    editFormHolder: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%'
    },
    treePickerContainer: {
        marginRight: '2em'
    },
    replaceWizardButton: {
        marginLeft: '23em',
        marginTop: '1em',
    },
    wizardPopup: {
        width: '80%',
        borderRadius: 5
    },
    wizardPopupHeader: {
        height: '2.5em',
        backgroundColor: theme.palette.primary.main,
        borderRadius: '3px 3px 0 0',
        color: 'white',
        padding: '0.5em',
        fontSize: '1.5em'
    },
    wizardContentContainer :{
        display: 'flex',
        flexDirection: 'row',
        padding: theme.spacing(1),
    },
    wizardHolder: {
        width: '100%'
    },
    modalHeaderBar: {
        display: 'flex',
        alignItems: 'flex-start'
    },
    closeButton: {
        display: 'block',
        marginLeft: 'auto',
        color: 'white',
        padding: '5px'
    },
    verticalTabRoot: {
        height: '100%',
        display: 'flex',
        overflow: 'auto'
    },
    mobileCollapsedTreeView: {
        width: '38% !important',
        marginRight: '2%',
    },
    jobMenuButton: {
        position: 'fixed',
        right: '6%',
        top: '3%'
    },
    notFinishedBadge: {
        marginLeft: '1em',
        background: '#ffb300',
        color: 'white',
        fontWeight: 'bolder'
    },
    finishedBadge: {
        marginLeft: '1em',
        background: '#107d00',
        color: 'white',
        fontWeight: 'bolder'
    },
});

interface Props extends WithStyles<typeof styles> {
    rootStore: RootStore;
    onAuthError?: () => void;
};

interface States {
    loading: boolean;
    progress: number;
    progressLabel: string;
    folderId: string;
    floorPlans: Floorplan[];
    floorPlansIsLoading: boolean;
    markers: Marker[];
    markersIsLoading: boolean;
    miniMapImageIsLoading: boolean;
    miniMapImage: Blob | undefined;
    currentFloorPlan: Floorplan | undefined;
    currentMarkerName: string;
    runningTasks: RunningTasks[];
    currentTasksOffset: number;
    timeElapser: number | null;
    isErrorModalOpened: boolean;
    errorModalHeader: string;
    errorModalText: string;
    wasFloorplanCompressed: boolean;
    searchBy: SearchType;
    currentFolderId: string;
    editFloorplan: FloorplanFull | null;
    currentAccount: Account | null;
    floorplanIsUpdating: boolean;
    isFloorplanSuccessModal: boolean;
    floorplanSuccessModalText: string;
    accounts: Account[];
    currentAccountOffset: number;
    isAccountsLoading: boolean;
    totalAccounts: number;
    folders: Folder[];
    isFoldersLoading: boolean;
    currentNodeTree: RenderTree;
    treeIsLoading: boolean;
    treePickedType: PickedType;
    treePickedId: string;
    treePickedName: string;
    processedFolders: string[];
    collapseAccounts: boolean;
    currentTab: CurrentTabIndex;
    isDeleteModalOpen: boolean;
    deleteFloorplan: FloorplanFull | null;
    searchAccountName: string;
    storedAccounts: Account[];
    storedAccountOffset: number;
    storedAccountTotalCount: number;
    isJobMenuOpen: boolean;
}

@inject('rootStore')
@observer
class Markers extends React.Component<Props, States> {
    public static defaultProps: Partial<Props> = {
    };

    state: States = {
        loading: false,
        progress: 0,
        progressLabel: '',
        folderId: '',
        floorPlans: [],
        floorPlansIsLoading: false,
        markers: [],
        markersIsLoading: false,
        miniMapImageIsLoading: false,
        miniMapImage: undefined,
        currentFloorPlan: undefined as Floorplan | undefined,
        currentMarkerName: '',
        runningTasks: [],
        currentTasksOffset: 0,
        timeElapser: null,
        isErrorModalOpened: false,
        errorModalHeader: '',
        errorModalText: '',
        wasFloorplanCompressed: false,
        searchBy: SearchType.treeView,
        currentFolderId: '',
        editFloorplan: null,
        currentAccount: null,
        floorplanIsUpdating: false,
        isFloorplanSuccessModal: false,
        floorplanSuccessModalText: '',
        accounts: [],
        currentAccountOffset: 0,
        isAccountsLoading: false,
        totalAccounts: 0,
        folders: [],
        isFoldersLoading: false,
        currentNodeTree: {id: '', name:'', children:[], path: ''},
        treeIsLoading: false,
        treePickedType: PickedType.None,
        treePickedId: '',
        treePickedName: '',
        processedFolders: [],
        collapseAccounts: false,
        currentTab: CurrentTabIndex.create,
        isDeleteModalOpen: false,
        deleteFloorplan: null,
        searchAccountName: '',
        storedAccounts: [],
        storedAccountOffset: 0,
        storedAccountTotalCount: 0,
        isJobMenuOpen: false,
    };

    floorplanProvider = new FloorplanProvider();
    folderProvider = new FolderProvider();
    jobProvider = new JobProvider();
    markerProvider = new MarkerProvider();
    accountProvider = new AccountProvider();

    componentDidMount() {
        this.fetchRunningTasks();
        this.startTimeInterval();
        this.fetchAccounts(this.state.currentAccountOffset);
    }

    componentWillUnmount() {
        const {
            rootStore: { adminStore }
        } = this.props;   
        const { runningTasks } = this.state;
        this.clearIntervals(runningTasks);
        adminStore.setCurrentExportTasks(runningTasks, MainTabs.Markers);
        this.clearTimeElapserInterval();
    }

    clearIntervals(tasks: RunningTasks[]) {
        tasks.forEach((element) => {
            element.set_to_stop = true;
        });
    }

    clearTimeElapserInterval() {
        const { timeElapser } = this.state;
        if( timeElapser ) {
            window.clearInterval(timeElapser);
        }
    }

    startTimeInterval() {
        const { timeElapser } = this.state;
        if (timeElapser) {
            return;
        }
        const timeElapserInterval = window.setInterval(() => {
            const { runningTasks } = this.state;
            const time = Date.now();
            const processing = runningTasks.filter(e => e.status === 102);
            processing.forEach(element => {
                element.elapsedTime = Math.round((time - element.startedAt) / 1000);
            });
            this.setState({runningTasks});
        }, 1000);
        this.setState({timeElapser: timeElapserInterval});
    }

    fetchRunningTasks = () => {
        const {
            rootStore: { adminStore }
        } = this.props;
        const tasks = adminStore.currentExportTasks.find(e => e.page === MainTabs.Markers);
        this.setState({
            runningTasks: tasks!.tasks
        }, () => {
            tasks!.tasks.forEach(element => {
                if(element.status === 102) {
                    // SET TO RESTART POOLING
                    element.set_to_stop = false;
                    this.startTaskWatcher(element);
                }
            });
        });
    }

    startTaskWatcher = (task: RunningTasks) => {
        const { runningTasks } = this.state;

        if (!task.export_name || !task.task_id || !task.export_id) {
            return;
        }

        const statusCallback = (taskId: string) => {
            const taskIndex = runningTasks.findIndex(e => e.task_id === taskId);
            const taskItem = runningTasks.find(e => e.task_id === taskId);

            if (!taskItem || taskItem.status !== 102) {
                return;
            }

            if (taskItem.set_to_stop) {
                return;
            }   
            
            this.jobProvider.checkJobStatus(taskItem.task_id).then(data => {
                if (data.job_status === StatusTypes.Error) {
                    taskItem.status = 500;
                    runningTasks[taskIndex] = taskItem;
                    this.setState({runningTasks});
                }
                if (data.job_status === StatusTypes.Complete) {
                    taskItem.status = 200;
                    taskItem.progress = 100.0;
                    this.jobProvider.checkJobResult(taskItem.task_id).then(res => {
                        const results = res.result as JobResultDataset;
                        taskItem.result_data = results.result;
                        runningTasks[taskIndex] = taskItem;
                        this.setState({runningTasks});
                    }).catch((error) => {
                        const { status } = error.response;
                        // CHECK IF WE TIMEOUT OR NOT
                        if (status === 408 || status === 504) {
                            // WE HAVE TIMEOUT INCREASE TIMER AND KEEP TRYING
                            taskItem.timer += 1000;
                            setTimeout(taskItem.internalCallback!, taskItem.timer, taskId);
                            return;
                        }
                        taskItem.status = 500;
                        runningTasks[taskIndex] = taskItem;
                        this.setState({runningTasks});
                    });
                } else {
                    // KEEP TRACK OF THE PROGRESS STATUS IF ITS STILL THE SAME
                    if (taskItem.progress === data.estimate_percent_complete) {
                        taskItem.static_progress_count += 1;
                    }
                    taskItem.progress = data.estimate_percent_complete;
                    // IF WE REACH A POINT WHERE THE STATUS IS THE SAME THE JOB IS SLOW
                    // MAKE FEWER REQUESTS TO NOT OVERWHELM THE STATUS ENDPOINT
                    if (taskItem.static_progress_count === STATIC_PROGRESS_LIMIT) {
                        taskItem.timer += 1000;
                        taskItem.static_progress_count = 0;
                    }
                    setTimeout(taskItem.internalCallback!, taskItem.timer, taskId);
                }
            }).catch((error) => {
                const { status } = error.response;
                // CHECK IF WE TIMEOUT OR NOT
                if (status === 408 || status === 504) {
                    // WE HAVE TIMEOUT INCREASE TIMER AND KEEP TRYING
                    taskItem.timer += 1000;
                    setTimeout(taskItem.internalCallback!, taskItem.timer, taskId);
                    return;
                }
                taskItem.status = 500;
                runningTasks[taskIndex] = taskItem;
                this.setState({runningTasks});
            });
        }

        // PREVENT GENERATING TIMERS WITHOUT PROPERTIES BEING INITIALIZED
        const taskIndex = runningTasks.findIndex(e => e.task_id === task.task_id);
        runningTasks[taskIndex].internalCallback = statusCallback;
        this.setState({runningTasks}, () => {
            this.onJobMenuToggle();
            setTimeout(statusCallback, task.timer, task.task_id);
        });
    }

    fetchAccounts = (offset:number, limit: number = ACCOUNTS_PAGING_LIMIT) => {
        this.setState({
            loading: true
        });
        return this.accountProvider.getAllAccounts(offset, limit)
          .then((results) => {
            const {accounts, totalCount} = results;
            if (!accounts) {
                if (offset === 0) {
                    this.setState({
                        isErrorModalOpened: true,
                        errorModalHeader: 'Error fetching accounts',
                        errorModalText: 'No accounts found'
                    });
                }
                return;
            }

            this.setState({
                isAccountsLoading: false,
                totalAccounts: totalCount,
                accounts,
                currentAccountOffset: offset
            });
        }).catch((error) => {
            console.error(error);
            const { status } = error.response;
            if (status === 401) {
                const { onAuthError } = this.props;
                if (!onAuthError) {
                    return;
                }
                onAuthError();
                return;
            }
            this.setState({
                isErrorModalOpened: true,
                errorModalHeader: `Error fetching accounts ${error}`,
                errorModalText: error.stack_trace
            });
        }).finally(() => {
            this.setState({loading: false});
        });
    }

    searchAccounts = (searchName: string, offset?: number) => {
        // Nothing to search load the normal account list
        if (searchName === '') {
            const {
                storedAccounts,
                storedAccountOffset,
                storedAccountTotalCount
            } = this.state;

            // Empty search do nothing
            if (!storedAccounts.length) {
                return;
            }

            // Going back from actual seach
            this.setState({
                accounts: storedAccounts,
                currentAccountOffset: storedAccountOffset,
                totalAccounts: storedAccountTotalCount,
                searchAccountName: '',
                storedAccounts: [],
                storedAccountOffset: 0,
                storedAccountTotalCount: 0
            });
            return;
        }

        // We have a name search
        const {
            currentAccountOffset,
            accounts,
            totalAccounts,
            storedAccounts
        } = this.state;
        this.setState({
            isAccountsLoading: true,
            searchAccountName: searchName
        }, () => {
            const searchOffset = offset ? offset : 0;
            return this.accountProvider
                .searchAccountByNameShortSchema(searchName, searchOffset, ACCOUNTS_PAGING_LIMIT)
                .then(results => {
                    const { accounts: foundAccounts, totalCount } = results;
                    if (!foundAccounts) {
                        if (currentAccountOffset === 0) {
                            this.setState({
                                isErrorModalOpened: true,
                                errorModalHeader: 'Error fetching accounts',
                                errorModalText: 'No accounts found'
                            });
                        }
                        return;
                    }
    
                    // If we haven't stored the original list do it
                    // This prevents multiple searchs overriding each others
                    if (!storedAccounts.length) {
                        this.setState({
                            storedAccounts: accounts,
                            storedAccountTotalCount: totalAccounts,
                            storedAccountOffset: currentAccountOffset
                        })
                    }

                    this.setState({
                        isAccountsLoading: false,
                        totalAccounts: totalCount,
                        accounts: foundAccounts,
                        currentAccountOffset: searchOffset
                    });
                })
                .catch(error => {
                    const { status } = error.response;
                    if (status === 401) {
                        const { onAuthError } = this.props;
                        if (!onAuthError) {
                            return;
                        }
                        onAuthError();
                        return;
                    }
                    this.setState({
                        isErrorModalOpened: true,
                        errorModalHeader: `Error fetching accounts ${error}`,
                        errorModalText: error.stack_trace
                    });
                });
        });
    }

    convertMarkersResultIntoMarkerList = memoize((markersResult: FloorPlanStatusResponseResultsItem[]): MarkersListItem[] => {
        return convertMarkersResultIntoMarkerList(markersResult);
    });

    onFloorPlanGeneratorLoading = (isLoading: boolean, progress: number, progressLabel: string) => {
    }

    onFloorPlanGeneratorSubmitted = (folderId: string, floorPlanName: string, markersPrefix: string, floorPlanFile: File, markersFile?: File) => {
        this.setState({loading: true, progressLabel: 'Creating floor plan'});

        // CHANGE TO #100
        this.floorplanProvider.generateFloorplan(
            folderId,
            floorPlanName,
            floorPlanFile,
            markersFile,
            markersPrefix
        ).then(data => {
            const { runningTasks } = this.state;
            const { job_id, floorplan_id, compressed } = data;

            if (job_id) {
                const {
                    rootStore: { adminStore }
                } = this.props;
    
                const newTask: RunningTasks = {
                    account: null,
                    export_name: `Generating floor plan: ${floorPlanName}`,
                    export_id: floorplan_id,
                    type: JobTypes.GenerateFloorplan,
                    task_id: job_id,
                    status: 102,
                    startedAt: Date.now(),
                    elapsedTime: 0,
                    progress: 0,
                    timer: 1500,
                    static_progress_count: 0,
                            set_to_stop: false
                };
    
                runningTasks.push(newTask);
                adminStore.setCurrentExportTasks(runningTasks, MainTabs.Markers);
                this.startTaskWatcher(newTask);
                this.startTimeInterval();
                this.setState({runningTasks, loading: false, wasFloorplanCompressed: compressed ? compressed : false});
                
                return;
            } else {
                this.setState({
                    loading: false,
                    isFloorplanSuccessModal: true,
                    floorplanSuccessModalText: compressed ? 'Floor plan compressed and created succesfully' : 'Floor plan created succesfully'
                });
            }

        }).catch((error) => {
            // tslint:disable-next-line:no-console
            console.error(error);
            const { status } = error.response;
            if (status === 401) {
                const { onAuthError } = this.props;
                if (!onAuthError) {
                    return;
                }
                onAuthError();
                return;
            }

            const { data } = error.response;
            this.setState({
                loading: false,
                isErrorModalOpened: true,
                errorModalHeader: 'Error on creation',
                errorModalText: data.error ? data.error : error
            });
        });
    }

    onTextChanged = (type: 'folderId') => (e: any) => {
        const fileName = e.target.value;
        const newState: {folderId?: string} = {};
        newState[type] = fileName;
        this.setState(newState as States);
    };

    onSearchClicked = () => {
        const { folderId }  = this.state;
        if (!folderId) {
            return;
        }
        
        this.setState({
            floorPlansIsLoading: true,
            currentFloorPlan: undefined,
            markers: [],
            miniMapImage: undefined
        });
        this.folderProvider.fetchFloorplans(folderId).then((response) => {
            this.setState({
                floorPlans: response as unknown as Floorplan[],
                floorPlansIsLoading: false
            });
        }).catch((error) =>{
            const { status } = error.response;
            if (status === 401) {
                const { onAuthError } = this.props;
                if (!onAuthError) {
                    return;
                }
                onAuthError();
                return;
            }
            this.setState({
                floorPlansIsLoading: false
            });
        });
        
    }

    onDownloadImagesClicked = () => {
        const { folderId, currentAccount } = this.state;
        if (!folderId) {
            return;
        }

        this.setState({
            loading: true,
            progressLabel: ''
        });
        const {
            rootStore: { adminStore }
        } = this.props;
        const userIdentifiers = adminStore.userIdentifiers!;
        const jobPayload: JobRequest = {
            user_created_id: userIdentifiers.user_id,
            account_id: currentAccount ? currentAccount.accountId : userIdentifiers.account_id,
            job_type: `${JobTypes.DownloadFloorMaps}`,
            additional_params: {
                folder_id: folderId
            }
        }
        const { runningTasks } = this.state;
        return this.jobProvider.scheduleJob(jobPayload)
        .then((result) => {
            const newTask: RunningTasks = {
                account: null,
                export_name: `Downloading floor maps images for: ${folderId}`,
                export_id: folderId,
                type: JobTypes.DownloadFloorMaps,
                task_id: result.job_id,
                status: 102,
                startedAt: Date.now(),
                elapsedTime: 0,
                progress: 0,
                timer: 1500,
                static_progress_count: 0,
                        set_to_stop: false
            };

            runningTasks.push(newTask);
            adminStore.setCurrentExportTasks(runningTasks, MainTabs.Markers);
            this.setState({runningTasks, loading: false});
            this.startTaskWatcher(newTask);
            this.startTimeInterval();
        }).catch((error) => {
            // tslint:disable-next-line:no-console
            console.error(error);
            const { status } = error.response;
            if (status === 401) {
                const { onAuthError } = this.props;
                if (!onAuthError) {
                    return;
                }
                onAuthError();
                return;
            }
            this.setState({
                isErrorModalOpened: true,
                errorModalHeader: 'Error scheduling the Job',
                errorModalText: error
            });
        });
    }

    onDownloadFloorplansClicked = () => {
        const { folderId, currentAccount } = this.state;
        if (!folderId) {
            return;
        }

        this.setState({
            loading: true,
            progressLabel: 'Scheduling job'
        });
        const {
            rootStore: { adminStore }
        } = this.props;
        const userIdentifiers = adminStore.userIdentifiers!;
        const jobPayload: JobRequest = {
            user_created_id: userIdentifiers.user_id,
            account_id: currentAccount ? currentAccount.accountId : userIdentifiers.account_id,
            job_type: `${JobTypes.DownloadFloorplans}`,
            additional_params: {
                folder_id: folderId
            }
        }
        const { runningTasks } = this.state;
        return this.jobProvider.scheduleJob(jobPayload)
        .then((result) => {
            const newTask: RunningTasks = {
                account: null,
                export_name: `Downloading floorplan files for: ${folderId}`,
                export_id: folderId,
                type: JobTypes.DownloadFloorplans,
                task_id: result.job_id,
                status: 102,
                startedAt: Date.now(),
                elapsedTime: 0,
                progress: 0,
                timer: 1500,
                static_progress_count: 0,
                        set_to_stop: false
            };

            runningTasks.push(newTask);
            adminStore.setCurrentExportTasks(runningTasks, MainTabs.Markers);
            this.setState({runningTasks, loading: false});
            this.startTaskWatcher(newTask);
            this.startTimeInterval();
        }).catch((error) => {
            // tslint:disable-next-line:no-console
            console.error(error);
            const { status } = error.response;
            if (status === 401) {
                const { onAuthError } = this.props;
                if (!onAuthError) {
                    return;
                }
                onAuthError();
                return;
            }
            this.setState({
                isErrorModalOpened: true,
                errorModalHeader: 'Error scheduling the Job',
                errorModalText: error
            });
        });
    }

    onExportDataButtonClicked = () => {
        const { currentFloorPlan, currentAccount } = this.state;
        if (!currentFloorPlan?.id) {
            return;
        }

        this.setState({
            loading: true,
            progressLabel: 'Scheduling job'
        });
        const {
            rootStore: { adminStore }
        } = this.props;
        const userIdentifiers = adminStore.userIdentifiers!;
        const jobPayload: JobRequest = {
            user_created_id: userIdentifiers.user_id,
            account_id: currentAccount ? currentAccount.accountId : userIdentifiers.account_id,
            job_type: `${JobTypes.DownloadFloorplans}`,
            additional_params: {
                floorplan_ids: currentFloorPlan.id
            }
        }
        const { runningTasks } = this.state;
        return this.jobProvider.scheduleJob(jobPayload)
        .then((result) => {
            const newTask: RunningTasks = {
                account: null,
                export_name: `Downloading floorplan files for: ${currentFloorPlan.name}`,
                export_id: currentFloorPlan.id,
                type: JobTypes.DownloadFloorplans,
                task_id: result.job_id,
                status: 102,
                startedAt: Date.now(),
                elapsedTime: 0,
                progress: 0,
                timer: 1500,
                static_progress_count: 0,
                        set_to_stop: false
            };

            runningTasks.push(newTask);
            adminStore.setCurrentExportTasks(runningTasks, MainTabs.Markers);
            this.setState({runningTasks, loading: false});
            this.startTaskWatcher(newTask);
            this.startTimeInterval();
        }).catch((error) => {
            // tslint:disable-next-line:no-console
            console.error(error);
            const { status } = error.response;
            if (status === 401) {
                const { onAuthError } = this.props;
                if (!onAuthError) {
                    return;
                }
                onAuthError();
                return;
            }
            this.setState({
                isErrorModalOpened: true,
                errorModalHeader: 'Error scheduling the Job',
                errorModalText: error
            });
        });
    }

    onKeyPressed = (event:any) => {
        if (event.key !== 'Enter') {
            return;
        }
        this.onSearchClicked();
        event.preventDefault();
    }

    onFloorPlanClicked = (floorplanId: string, floorplanName: string) => {
        this.setState({
            markersIsLoading: true,
            miniMapImage: undefined,
            currentFloorPlan: {id: floorplanId, name: floorplanName} as Floorplan
        });

        this.floorplanProvider.fetchMarkers(floorplanId).then((response) => {
            this.setState({
                markers: response,
                markersIsLoading: false
            });
        }).catch(() =>{
            this.setState({
                markersIsLoading: false
            });
        });
    }

    onMarkerClicked = (marker: Marker) => {
        const { currentMarkerName } = this.state;
        const {
            name: markerName,
            marker_id: markerId
        } = marker;

        if (currentMarkerName === markerName) {
            return;
        }

        this.setState({
            miniMapImageIsLoading: true,
            currentMarkerName: markerName
        });
        this.markerProvider.fetchMinimapUrl(markerId).then((response) => {
            this.setState({
                miniMapImage: response.file_blob,
                miniMapImageIsLoading: false
            });
        }).catch((error) =>{
            console.error(error);
            this.setState({
                miniMapImageIsLoading: false
            });
        });
    }

    onTaskDataPageOverBoundaryReached = (boundary: PageBoundary, nextPage: number): Promise<void> => {
        return new Promise<void>((resolve, reject) => {
            const { 
                currentTasksOffset,
                runningTasks
            } = this.state;
            const totalTasks = runningTasks.length;
            const isFirstPage = nextPage === 0;
            const isLastPage = nextPage === (Math.ceil(totalTasks / TASKS_ROWS_PER_PAGE)-1);
            const newOffset = isFirstPage ? 0
                            : isLastPage ? TASKS_PAGING_LIMIT * Math.floor(totalTasks / TASKS_PAGING_LIMIT)
                            : (boundary === PageBoundary.Upper) ? 
                            currentTasksOffset+TASKS_PAGING_LIMIT :
                            currentTasksOffset-TASKS_PAGING_LIMIT;
            const nextTasks = runningTasks;
            // MANUAL OFFSET
            this.setState({
                runningTasks: nextTasks.splice(newOffset, nextTasks.length)
            });
        });
    }

    onTaskClick = (task: RunningTasks) => {
        if (task.status === 102) {
            return;
        }
        if(task.status === 200) {
            switch (task.type) {
                case JobTypes.DownloadFloorplans:
                    window.open(task.result_data as string, '_blank');
                    break;

                case JobTypes.DownloadFloorMaps:
                    window.open(task.result_data as string, '_blank');
                    break;
                
                case JobTypes.DeleteFloorplan:
                    window.open(task.result_data as string, '_blank');
                    break;
            
                default:
                    break;
            }
            return;
        }
        this.jobProvider.checkJobError(task.task_id)
            .then((data) => {
                this.setState({
                    isErrorModalOpened: true,
                    errorModalHeader: data.error,
                    errorModalText: data.stack_trace
                });
            }).catch((error) => {
                // tslint:disable-next-line:no-console
                console.error(error);
                this.setState({
                    isErrorModalOpened: true,
                    errorModalHeader: 'Error getting the Job result',
                    errorModalText: error
                });
            });
    }

    onTaskClear = () => {
        const { runningTasks } = this.state;
        const pendingTasks = runningTasks.filter(e => e.status === 102);
        const {
            rootStore: { adminStore }
        } = this.props;
        adminStore.setCurrentExportTasks(pendingTasks, MainTabs.Markers);
        this.setState({runningTasks: pendingTasks});
    }

    onErrorModalClicked = () => {
        this.setState({isErrorModalOpened: false});
    }

    onSnackbarClosed = () => {
        this.setState({wasFloorplanCompressed: false});
    }

    handleSearchTypeChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        this.setState({searchBy: event.target.value as number});
    }

    onFolderSearch = (folderId: string) => {
        if (!folderId) {
            return;
        }
        
        this.setState({
            floorPlansIsLoading: true,
            currentFloorPlan: undefined,
            markers: [],
            miniMapImage: undefined
        });
        this.folderProvider.fetchFloorplans(folderId).then((response) => {
            this.setState({
                floorPlans: response as unknown as Floorplan[],
                floorPlansIsLoading: false,
                currentFolderId: folderId,
                folderId
            });
        }).catch(() =>{
            this.setState({
                floorPlansIsLoading: false
            });
        });
    }

    fetchEditFloorplan = (floorplanId: string) => {
        if (!floorplanId || floorplanId === '') {
            return;
        }

        this.setState({
            floorPlansIsLoading: true,
            editFloorplan: null,
        });
        this.floorplanProvider.getFloorplanById(floorplanId).then((data) => {
            this.setState({
                floorPlansIsLoading: false,
                editFloorplan: data.floorplan
            });
        }).catch((error) => {
            const { status } = error.response;
            if (status === 401) {
                const { onAuthError } = this.props;
                if (!onAuthError) {
                    return;
                }
                onAuthError();
                return;
            }
            this.setState({
                floorPlansIsLoading: false,
                isErrorModalOpened: true,
                errorModalHeader: `Floorplan fetch error ${status}`,
                errorModalText: error.stack_trace,
            });
        });
    }

    onEditSubmit = (editFloorplan: FloorplanFull) => {
        if (!editFloorplan) {
            return;
        }

        this.setState({
            floorplanIsUpdating: true
        });
        this.floorplanProvider.updateFloorplan(editFloorplan.floorplan_id, editFloorplan).then(data => {
            const { searchBy, currentAccount } = this.state;
            const { floorplan } = data;
            this.setState({
                floorplanIsUpdating: false,
                editFloorplan: null
            });

            switch (searchBy) {
                case SearchType.folderId:
                    const { currentFolderId } = this.state;
                    this.onFolderSearch(currentFolderId);
                    break;
                case SearchType.treeView:
                    break;
                    default:
                        break;
                    }
                    
            if (!floorplan.jobs) {
                this.setState({isFloorplanSuccessModal: true, floorplanSuccessModalText: 'Floorplan Successfully updated'});
                return;
            }

            const {
                rootStore: { adminStore }
            } = this.props;
            const { runningTasks } = this.state;
            const jobId = Object.keys(floorplan.jobs)[0];
            const newTask: RunningTasks = {
                account: currentAccount ? currentAccount : {} as Account,
                export_name: `Updating floorplan: ${editFloorplan.name}`,
                export_id: editFloorplan!.floorplan_id,
                type: 0,
                task_id: jobId,
                status: 102,
                startedAt: Date.now(),
                elapsedTime: 0,
                progress: 0,
                timer: 1500,
                static_progress_count: 0,
                        set_to_stop: false
            };
            runningTasks.push(newTask);
            adminStore.setCurrentExportTasks(runningTasks, MainTabs.Markers);
            this.startTaskWatcher(newTask);
            this.startTimeInterval();
        }).catch(error => {
            const { status } = error.response;
            if (status === 401) {
                const { onAuthError } = this.props;
                if (!onAuthError) {
                    return;
                }
                onAuthError();
                return;
            }
            this.setState({
                floorPlansIsLoading: false,
                isErrorModalOpened: true,
                errorModalHeader: `Floorplan fetch error ${status}`,
                errorModalText: error.stack_trace,
            });
        });
    }

    onFloorplanSuccessClick = () => {
        this.setState({isFloorplanSuccessModal: false});
    }

    onAccountClicked = (account: Account) => {
        const { currentAccount } = this.state;
        if (currentAccount === account) {
            return;
        }

        this.setState({
            currentAccount: account
        });
        this.fetchFoldersFromAccount(account);
    };


    fetchFoldersFromAccount = (account: Account) => {
        this.setState({
            isFoldersLoading: true,
            treePickedType: PickedType.None,
            treePickedId: '',
            processedFolders: []
        });

        return this.accountProvider.getRootFoldersByAccountId(account.accountId)
            .then((results) => {
                const folders: Folder[] = results;
                if(!folders) {
                    this.setState({
                        floorPlansIsLoading: false,
                        isErrorModalOpened: true,
                        errorModalHeader: `Folder fetch error`,
                        errorModalText: 'No folders found',
                    });
                    return;
                }

                // TRANSFORM THE FOLDERS INTO TREE VIEW
                const accountTree: RenderTree = {
                    id: account.accountId,
                    name: account.name,
                    children: [],
                    path: ''
                };

                const treeNodes: RenderTree[] = folders.map(element => {
                    const newItem = {
                        id: element.id,
                        name: element.name,
                        children: [],
                        path: `${element.id}__`
                    }
                    return newItem as RenderTree;
                });

                accountTree.children = treeNodes;

                this.setState({
                    isFoldersLoading: false,
                    folders,
                    currentNodeTree: accountTree
                });

        }).catch((error) => {
            // tslint:disable-next-line:no-console
            console.error(error);
            const { status } = error.response;
            if (status === 401) {
                const { onAuthError } = this.props;
                if (!onAuthError) {
                    return;
                }
                onAuthError();
                return;
            }
            this.setState({
                floorPlansIsLoading: false,
                isErrorModalOpened: true,
                errorModalHeader: `Floorplan fetch error ${status}`,
                errorModalText: error.stack_trace,
            });
        });

    };

    deepTreeSearch = (treeData: RenderTree, searchId: string) => {
        let foundTree: RenderTree | undefined;
        let searchLevel: RenderTree[] | undefined = treeData.children;
        do {
            foundTree = searchLevel?.find((e: RenderTree) => e.id === searchId);
            let tempLevel: any = [];
            searchLevel?.forEach((element: RenderTree) => {
                tempLevel = tempLevel.concat(element.children);
            });
            searchLevel = tempLevel;
        } while(!foundTree);
        return foundTree;
    }

    updateChildrenOnPath = (treeData: RenderTree, nodeId: string, children: RenderTree[]) => {
        treeData.children!.forEach(element => {
            if (_.isEqual(element.id, nodeId)) {
                element.children = children;
            } else {
                this.updateChildrenOnPath(element, nodeId, children);
            }
        });
    }

    onNodeSelected = (treeData: RenderTree , nodeId: string, nodeLabel: string, updateCallback: (nodeIds: string[]) => void) => {
        const { folders, currentAccount, processedFolders } = this.state;
        if(currentAccount!.accountId === nodeId) {
            this.setState({
                treePickedType: PickedType.None,
                treePickedId: '',
                treePickedName: ''
            });
            return;
        }
        this.setState({treeIsLoading: true});
        // CHECK PICK TYPE
        // FOLDER
        const currentNodePos = this.deepTreeSearch(treeData, nodeId);
        if(folders.find(e => e.id === nodeId)) {
            // DID WE ALREADY CHECK FOR CHILDREN?
            if (processedFolders.find(e => e === nodeId)) {
                // FOLDER CHILDREN
                const firstChild = currentNodePos.children![0];
                if (!firstChild) {
                    // NO CHILDREN CAN DOWNLOAD
                    this.setState({
                        treePickedType: PickedType.Folder,
                        treePickedId: '',
                        treePickedName: '',
                        currentNodeTree: treeData,
                        treeIsLoading: false,
                        folderId: nodeId
                    });
                    return;
                }
                if (folders.find(e => e.id === firstChild.id)) {
                    // DO NOTHING CAN'T DOWNLOAD FROM THIS FOLDER
                    this.setState({
                        treePickedType: PickedType.None,
                        treePickedId: '',
                        treePickedName: '',
                        currentNodeTree: treeData,
                        treeIsLoading: false,
                        folderId: ''
                    });
                    return;
                }
                // FLOORPLAN CHILDREN CAN DOWNLOAD
                this.setState({
                    treePickedType: PickedType.Folder,
                    treePickedId: nodeId,
                    treePickedName: currentNodePos.name,
                    currentNodeTree: treeData,
                    treeIsLoading: false,
                    folderId: nodeId
                });
                return;
            }
            // EMPTY
            // CHECK FOR FOLDER CHILDREN FIRST
            return this.accountProvider.getChildrenFolderByFolderId(currentAccount!.accountId, nodeId)
                .then((results: Folder[]) => {
                    if (results.length > 0) {
                        const nodes: RenderTree[] = results.map(element => {
                            const newItem = {
                                id: element.id,
                                name: element.name,
                                children: [],
                                path: `${currentNodePos.path}${element.id}__`
                            }
                            return newItem as RenderTree;
                        });
                        this.updateChildrenOnPath(treeData, nodeId, nodes);
                        const currentPath = currentNodePos.path.slice(0, -2);
                        updateCallback([currentAccount!.accountId, ...currentPath.split('__'), ...nodes.map(e => e.id)]);
                        processedFolders.push(nodeId);
                        this.setState({
                            treePickedType: PickedType.None,
                            treePickedId: '',
                            treePickedName: '',
                            currentNodeTree: treeData,
                            treeIsLoading: false,
                            folders: folders.concat(results),
                            processedFolders,
                            folderId: ''
                        });
                        return;
                    } else {
                        return this.folderProvider.fetchFloorplans(nodeId)
                            .then((floorplans) => {
                                const nodes: RenderTree[] = floorplans.map((element: RenderTree) => {
                                    const newItem = {...element} as RenderTree;
                                    newItem.children = [];
                                    newItem.path = `${currentNodePos.path}${element.id}__`;
                                    return newItem;
                                });
                                this.updateChildrenOnPath(treeData, nodeId, nodes);
                                const currentPath = currentNodePos.path.slice(0, -2);
                                updateCallback([currentAccount!.accountId, ...currentPath.split('__'), ...nodes.map(e => e.id)]);
                                processedFolders.push(nodeId);
                                // FOLDERS ARE NOT ALLOWED TO LOAD MARKERS BUT THEY CAN DOWNLOAD FILES
                                this.setState({
                                    treePickedType: PickedType.None,
                                    treePickedId: '',
                                    treePickedName: '',
                                    currentNodeTree: treeData,
                                    treeIsLoading: false,
                                    processedFolders,
                                    folderId: nodeId
                                });
                                return;
                        }).catch((error) => {
                            // tslint:disable-next-line:no-console
                            console.error(error);
                            const { status } = error.response;
                            if (status === 401) {
                                const { onAuthError } = this.props;
                                if (!onAuthError) {
                                    return;
                                }
                                onAuthError();
                                return;
                            }
                            this.setState({
                                floorPlansIsLoading: false,
                                isErrorModalOpened: true,
                                errorModalHeader: 'Floorplan fetch error',
                                errorModalText: error.stack_trace,
                            });
                        });
                    }
            }).catch((error) => {
                // tslint:disable-next-line:no-console
                console.error(error);
                const { status } = error.response;
                if (status === 401) {
                    const { onAuthError } = this.props;
                    if (!onAuthError) {
                        return;
                    }
                    onAuthError();
                    return;
                }
                this.setState({
                    floorPlansIsLoading: false,
                    isErrorModalOpened: true,
                    errorModalHeader: 'Floorplan fetch error',
                    errorModalText: error.stack_trace,
                });
            });
        }
        this.setState({
            treePickedType: PickedType.FloorPlan,
            treePickedId: nodeId,
            treePickedName: currentNodePos.name,
            treeIsLoading: false,
            collapseAccounts: true
        });
        const { currentTab } = this.state;

        if (currentTab === CurrentTabIndex.minimap) {
            this.onFloorPlanClicked(nodeId, nodeLabel);
        } else if (currentTab === CurrentTabIndex.edit) {
            this.fetchEditFloorplan(nodeId);
        }

        return;
    }

    onNodeToggled = (nodeIds: string[]) => {
        return;
    }

    onAccountDataPageOverBoundaryReached = (boundary: PageBoundary, nextPage: number): Promise<void> => {
        return new Promise<void>((resolve, reject) => {
            const { 
                currentAccountOffset,
                totalAccounts
            } = this.state;
            const isFirstPage = nextPage === 0;
            const isLastPage = nextPage === (Math.ceil(totalAccounts / ROWS_PER_PAGE)-1);
            const newOffset = isFirstPage ? 0
                            : isLastPage ? ACCOUNTS_PAGING_LIMIT * Math.floor(totalAccounts / ACCOUNTS_PAGING_LIMIT)
                            : (boundary === PageBoundary.Upper) ? 
                                currentAccountOffset+ACCOUNTS_PAGING_LIMIT :
                                currentAccountOffset-ACCOUNTS_PAGING_LIMIT;
            this.setState({isAccountsLoading: true});
            this.fetchAccounts(newOffset)
              .then(() => resolve())
              .catch(()=> reject());
        });
    }

    onCollapseAccounts = (collapseAccounts: boolean) => {
        this.setState({collapseAccounts});

        if (!collapseAccounts) {
            const { currentTab } = this.state;
            if (currentTab === CurrentTabIndex.edit) {
                this.setState({editFloorplan: null});
            } else if (currentTab === CurrentTabIndex.minimap) {
                this.setState({currentFloorPlan: undefined});
            }
        }
    }

    onTabChange = (selectedTabIndex: number, selectedTab: TabDescriptor) => {
        this.setState({
            currentTab: selectedTabIndex,
            folders: [],
            markers: [],
            floorPlans: [],
            currentFloorPlan: undefined,
            currentNodeTree: {id: '', name:'', children:[], path: ''},
            treePickedType: PickedType.None,
            treePickedId: '',
            treePickedName: '',
            collapseAccounts: false,
            currentAccount: null
        });
    }

    onDeleteFloorplanClick = (deleteFloorplan: FloorplanFull) => {
        this.setState({ isDeleteModalOpen: true, deleteFloorplan });
    }

    onDeleteFloorplanClose = () => {
        this.setState({ isDeleteModalOpen: false, deleteFloorplan: null });
    }

    onDeleteFloorplanSubmit = () => {
        const { deleteFloorplan } = this.state;

        if (!deleteFloorplan) {
            return;
        }

        this.setState({loading: true, progressLabel: 'Deleting floorplan'});
        this.floorplanProvider.deleteFloorplan(deleteFloorplan.floorplan_id).then(data => {
            const { searchBy, currentAccount } = this.state;

            if (searchBy === SearchType.folderId) {
                const { floorPlans } = this.state;

                const delIndex = floorPlans.findIndex(e => e.id === deleteFloorplan.floorplan_id);

                floorPlans.splice(delIndex, 1);
                this.setState({ floorPlans, searchBy: SearchType.None });
                setTimeout(() => {
                    this.setState({searchBy: SearchType.folderId});
                }, 500);
            } else {
                const { currentNodeTree } = this.state;
                const folderData = this.deepTreeSearch(currentNodeTree, deleteFloorplan.folder_id);
                const delNodeIndex = folderData.children!.findIndex(e => e.id === deleteFloorplan.floorplan_id);
                folderData.children!.splice(delNodeIndex, 1);
                this.updateChildrenOnPath(currentNodeTree, deleteFloorplan.folder_id, folderData.children!);
                this.setState({ currentNodeTree });
            }

            const {
                rootStore: { adminStore }
            } = this.props;
            const { runningTasks } = this.state;
            const { job } = data;
            const newTask: RunningTasks = {
                account: currentAccount ? currentAccount : {} as Account,
                export_name: `Deleting floorplan: ${deleteFloorplan.name}`,
                export_id: deleteFloorplan.floorplan_id,
                type: JobTypes.DeleteFloorplan,
                task_id: job,
                status: 102,
                startedAt: Date.now(),
                elapsedTime: 0,
                progress: 0,
                timer: 1500,
                static_progress_count: 0,
                set_to_stop: false
            };
            runningTasks.push(newTask);
            adminStore.setCurrentExportTasks(runningTasks, MainTabs.Markers);
            this.startTaskWatcher(newTask);
            this.startTimeInterval();
            
            this.setState({
                deleteFloorplan: null,
                editFloorplan: null,
                loading: false,
                progressLabel: '',
                isFloorplanSuccessModal: true,
                floorplanSuccessModalText: 'Delete task starting, you can download a recovery file at the end'
            })
        }).catch(error => {
            // tslint:disable-next-line:no-console
            console.error(error);
            const { status } = error.response;
            if (status === 401) {
                const { onAuthError } = this.props;
                if (!onAuthError) {
                    return;
                }
                onAuthError();
                return;
            }
            this.setState({
                floorPlansIsLoading: false,
                isErrorModalOpened: true,
                errorModalHeader: 'Floorplan delete error',
                errorModalText: error.stack_trace,
            });
        });
    };

    onJobMenuToggle = () => {
        const { isJobMenuOpen } = this.state;
        this.setState({ isJobMenuOpen: !isJobMenuOpen });
    };

    getJobCount = (needBoolean: Boolean) => {
        const { runningTasks } = this.state;

        const finishedCount = runningTasks.filter(e => e.status !== 102).length;
        const totalCount = runningTasks.length;

        if (needBoolean) {
            return finishedCount === totalCount;
        }

        return `${finishedCount}/${totalCount}`;
    };

    onErrorModalDisplay = (errorHeader: string, errorBody: string) => {
        this.setState({
            isErrorModalOpened: true,
            errorModalHeader: errorHeader,
            errorModalText: errorBody,
        });
    };

    public render() {
        const {
            classes
        } = this.props;
        const {
            loading,
            progress,
            progressLabel,
            floorPlans,
            floorPlansIsLoading,
            markers,
            markersIsLoading,
            miniMapImageIsLoading,
            miniMapImage,
            currentFloorPlan,
            currentMarkerName,
            runningTasks,
            currentTasksOffset,
            isErrorModalOpened,
            errorModalHeader,
            errorModalText,
            wasFloorplanCompressed,
            searchBy,
            editFloorplan,
            currentAccount,
            floorplanIsUpdating,
            isFloorplanSuccessModal,
            floorplanSuccessModalText,
            accounts,
            isAccountsLoading,
            totalAccounts,
            currentAccountOffset,
            folders,
            isFoldersLoading,
            currentNodeTree,
            treeIsLoading,
            treePickedType,
            treePickedId,
            collapseAccounts,
            folderId,
            isDeleteModalOpen,
            deleteFloorplan,
            isJobMenuOpen
        } = this.state;

        const container =
            window !== undefined ? () => window.document.body : undefined;

        return (
            <div className={classes.root} data-testid="mainRender">
                <CCSpinner
                    classes={{ darkOverlay: classes.spinnerOverlay }}
                    completion={progress}
                    loading={loading}
                    label={progressLabel ? progressLabel : 'Fetching accounts ...'}
                    overlayVisible={true}
                    size={100}
                />
                <div className={`${classes.animContainer} ${classes.fullContainerSize}`}>
                    <TabControl
                        orientation={TabControlOrientation.Vertical}
                        tabsDescriptors={[
                            {label: 'Create'},
                            {label: 'Minimaps'},
                            {label: 'Edit'}
                        ]}
                        onTabChange={this.onTabChange}
                        classes={{
                            verticalTabRoot: classes.verticalTabRoot
                        }}
                    >
                        {/* CREATE FORM */}
                        <Paper className={classes.tabContent} data-testid="create-tab">
                            <FloorPlanGenerator
                                accountProvider={this.accountProvider}
                                rowsPerPage={ROWS_PER_PAGE}
                                accounts={accounts}
                                accountsIsLoading={isAccountsLoading}
                                accountsTotalItems={totalAccounts}
                                accountsItemsOffset={currentAccountOffset}
                                onAccountDataPageOverBoundary={
                                    this.onAccountDataPageOverBoundaryReached
                                }
                                loading={loading}
                                onLoading={this.onFloorPlanGeneratorLoading}
                                onAccountSearch={this.searchAccounts}
                                onSubmit={this.onFloorPlanGeneratorSubmitted}
                            />
                        </Paper>
                        {/* MINIMAP SEARCH AND FILE DOWNLOAD */}
                        <Paper className={classes.tabContent} data-testid="minimap-tab">
                            <div className={classes.searchFloorPlansContainer}>
                                <div>
                                    <InputLabel id="select-search-minimap-label">Search by:</InputLabel>
                                    <Select
                                        labelId="select-search-minimap-label"
                                        id="select-search-minimap"
                                        data-testid="select-search-minimap"
                                        value={searchBy}
                                        onChange={this.handleSearchTypeChange}
                                        label="Search by"
                                        className={classes.inputSearch}
                                    >
                                        <MenuItem value={SearchType.folderId}>Folder Id</MenuItem>
                                        <MenuItem value={SearchType.treeView}>Tree View</MenuItem>
                                    </Select>
                                </div>
                                <Button
                                    className={classes.downloadTemplatesImages}
                                    variant="contained"
                                    color="secondary"
                                    onClick={this.onDownloadImagesClicked}
                                    disabled={folderId === ''}
                                >
                                    Download Floor maps Images
                                </Button>
                                <Button
                                    className={classes.downloadTemplatesImages}
                                    variant="contained"
                                    color="secondary"
                                    onClick={this.onDownloadFloorplansClicked}
                                    disabled={folderId === ''}
                                >
                                    Download Floor plan Files
                                </Button>
                            </div>
                            <div className={`${classes.selectorContainer} ${classes.editFormHolder}`}>
                                {
                                    searchBy === SearchType.folderId ?
                                        <FolderIdFloorplans
                                            className={classes.treePickerContainer}
                                            floorplans={floorPlans}
                                            floorplanIsLoading={floorPlansIsLoading}
                                            onFloorplanClick={this.onFloorPlanClicked}
                                            onFolderSearch={this.onFolderSearch}
                                            rowsPerPage={ROWS_PER_PAGE}
                                        />
                                        :
                                        <AccountFolderMarker
                                            className={`${classes.animContainer} ${classes.treePickerContainer}`}
                                            rowsPerPage={ROWS_PER_PAGE}
                                            accounts={accounts}
                                            accountsIsLoading={isAccountsLoading}
                                            accountsTotalItems={totalAccounts}
                                            accountsItemsOffset={currentAccountOffset}
                                            currentAccountName={currentAccount ? currentAccount.name : ''}
                                            folders={folders}
                                            folderIsLoading={isFoldersLoading}
                                            onAccountClick={this.onAccountClicked}
                                            onAccountDataPageOverBoundary={
                                                this.onAccountDataPageOverBoundaryReached
                                            }
                                            currentNodeTree={currentNodeTree}
                                            treeIsLoading={treeIsLoading}
                                            onNodeSelected={this.onNodeSelected}
                                            onNodeToggled={this.onNodeToggled}
                                            pickedType={treePickedType}
                                            pickedId={treePickedId}
                                            collapseAccounts={collapseAccounts}
                                            onCollapseAccountClick={this.onCollapseAccounts}
                                            onAccountSearch={this.searchAccounts}
                                        />
                                }
                                {
                                    currentFloorPlan &&
                                        <FloorplansList
                                            exportDataButtonText="Download XFDF &amp; PDF"
                                            exportDataButtonDisabled={!currentFloorPlan || markersIsLoading || floorPlansIsLoading}
                                            currentFloorplan={currentFloorPlan}
                                            currentMarkerName={currentMarkerName}
                                            floorPlans={floorPlans}
                                            floorPlansIsLoading={floorPlansIsLoading}
                                            markers={markers}
                                            markersIsLoading={markersIsLoading}
                                            miniMapImageIsLoading={miniMapImageIsLoading}
                                            miniMapImage={miniMapImage}
                                            onMarkerClick={this.onMarkerClicked}
                                            onExportDataButtonClick={this.onExportDataButtonClicked}
                                            canSearchFloorplan={false}
                                        />
                                }
                            </div>
                        </Paper>
                        {/* EDIT FORM */}
                        <Paper className={`${classes.tabContent} ${classes.editFormHolder}`} data-testid="edit-tab">
                            <div className={`${searchBy === SearchType.folderId ? collapseAccounts ? classes.onlyTreeSelectorHolder : classes.searchSelectHolder : classes.treeSelectorHolder}`}>
                                <div>
                                    <InputLabel id="select-search-edit-label">Search by:</InputLabel>
                                    <Select
                                        labelId="select-search-edit-label"
                                        id="select-search-edit"
                                        data-testid="select-search-edit"
                                        value={searchBy}
                                        onChange={this.handleSearchTypeChange}
                                        label="Search by"
                                        className={classes.inputSearch}
                                    >
                                        <MenuItem value={SearchType.folderId}>Folder Id</MenuItem>
                                        <MenuItem value={SearchType.treeView}>Tree View</MenuItem>
                                    </Select>
                                </div>
                                <div className={classes.selectorContainer}>
                                    {
                                        searchBy === SearchType.folderId ?
                                            <FolderIdFloorplans
                                                floorplans={floorPlans}
                                                floorplanIsLoading={floorPlansIsLoading}
                                                onFloorplanClick={this.fetchEditFloorplan}
                                                onFolderSearch={this.onFolderSearch}
                                                rowsPerPage={ROWS_PER_PAGE}
                                            />
                                            :
                                            <AccountFolderMarker
                                                className={`${classes.animContainer} ${classes.treePickerContainer}`}
                                                rowsPerPage={ROWS_PER_PAGE}
                                                accounts={accounts}
                                                accountsIsLoading={isAccountsLoading}
                                                accountsTotalItems={totalAccounts}
                                                accountsItemsOffset={currentAccountOffset}
                                                currentAccountName={currentAccount ? currentAccount.name : ''}
                                                folders={folders}
                                                folderIsLoading={isFoldersLoading}
                                                onAccountClick={this.onAccountClicked}
                                                onAccountDataPageOverBoundary={
                                                    this.onAccountDataPageOverBoundaryReached
                                                }
                                                currentNodeTree={currentNodeTree}
                                                treeIsLoading={treeIsLoading}
                                                onNodeSelected={this.onNodeSelected}
                                                onNodeToggled={this.onNodeToggled}
                                                pickedType={treePickedType}
                                                pickedId={treePickedId}
                                                collapseAccounts={collapseAccounts}
                                                onCollapseAccountClick={this.onCollapseAccounts}
                                                onAccountSearch={this.searchAccounts}
                                            />
                                    }
                                        </div>
                                    </div>
                                    {
                                        editFloorplan &&
                                            <FloorplanForm
                                                currentAccount={currentAccount}
                                                pickedFloorplan={editFloorplan}
                                                formFields={EDIT_FLOORPLAN_FORM}
                                                hiddenAttributes={EDIT_FLOORPLAN_HIDDEN_ATTRIBUTES}
                                                isProcessing={floorplanIsUpdating}
                                                onEditSubmit={this.onEditSubmit}
                                                canDelete={true}
                                                onDeleteSubmit={this.onDeleteFloorplanClick}
                                            />
                                    }
                        </Paper>
                    </TabControl>
                    <SimpleDialog
                        open={isErrorModalOpened}
                        titleText={errorModalHeader}
                        contentText={errorModalText}
                        buttonCancelLabel=""
                        onDialogResult={this.onErrorModalClicked}
                    />
                </div>
                {runningTasks && runningTasks.length > 0 && (
                    <React.Fragment>
                        <Button
                            className={classes.jobMenuButton}
                            variant="contained"
                            color="primary"
                            data-testid="floorplan-operations-job-drawer-button"
                            onClick={this.onJobMenuToggle}
                        >
                            Running Jobs
                            <Chip
                                label={this.getJobCount(false)}
                                className={`${
                                    this.getJobCount(true)
                                        ? classes.finishedBadge
                                        : classes.notFinishedBadge
                                }`}
                            />
                        </Button>
                        <Drawer
                            container={container}
                            variant={'temporary'}
                            anchor={'right'}
                            open={isJobMenuOpen}
                            onClose={this.onJobMenuToggle}
                            classes={{
                                paper: classes.paper
                            }}
                        >
                            <RunningTasksList
                                className={classes.tasksContainerSize}
                                tasks={runningTasks}
                                rowsPerPage={ROWS_PER_PAGE}
                                taskItemsOffset={currentTasksOffset}
                                listName="Running Floor plan operations"
                                onTaskClear={this.onTaskClear}
                                onDrawerClose={this.onJobMenuToggle}
                                onTaskClick={this.onTaskClick}
                            />
                        </Drawer>
                    </React.Fragment>
                )}
                {
                    isFloorplanSuccessModal &&
                    <SimpleDialog
                        open={isFloorplanSuccessModal}
                        titleText="Success"
                        contentText={floorplanSuccessModalText}
                        buttonCancelLabel=""
                        onDialogResult={this.onFloorplanSuccessClick}
                    />
                }
                {
                    isDeleteModalOpen && deleteFloorplan &&
                    <DeleteConfirmationPrompt
                        isDeleteModalOpen={isDeleteModalOpen}
                        deleteEntity="floorplan"
                        confirmationPrompt={deleteFloorplan.name}
                        confirmationPromptField="name"
                        entityName={deleteFloorplan.name}
                        closeDeleteModal={this.onDeleteFloorplanClose}
                        onSubmitDelete={this.onDeleteFloorplanSubmit}
                    />
                }
                <Snackbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    open={wasFloorplanCompressed}
                    autoHideDuration={6000}
                    onClose={this.onSnackbarClosed}
                    data-testid="compressed-alert"
                >
                    <Alert onClose={this.onSnackbarClosed} severity="info">
                        {COMPRESSED_NOTIFICATION}
                    </Alert>
                </Snackbar>
            </div>
        );
    }
};

const MUIComponent = withStyles(styles)(Markers);
export { MUIComponent as Markers };
