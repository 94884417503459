import { Button, Divider, IconButton, TextField } from '@material-ui/core';
import {
    createStyles,
    WithStyles,
    withStyles,
    Theme
} from '@material-ui/core/styles';
import { DeleteRounded } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import * as React from 'react';
import { FolderFull } from '../../models/folder';
import { Account, Team } from '../../shared/domain';
import { SimpleList, SimpleListItem } from '../../shared/components/simple-list';

const styles = (theme: Theme) => createStyles({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        border: '1px solid #e1e1e1',
        margin: '0.5em',
        borderRadius: '0.5em'
    },
    simpleFlex: {
        display: 'flex'
    },
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    list: {
        flex: '1 0',
        overflow: 'auto',
        boxShadow: 'none'
    },
    selectInput: {
        margin: '1em'
    },
    textField: {
        padding: '1em',
        width: '100%',
        '& label': {
            fontWeight: 'bolder',
            padding: '1em 0 0 1em',

            fontSize: '1.4em',
            color: 'rgba(0, 0, 0, 0.8) !important'
        },
        '&::before': {
            borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
            borderBottomStyle: 'solid'
        }
    },
    dividerForm: {
        margin: '1em'
    },
    deleteIcon: {
        color: '#eb445a'
    },
    tooltipText: {
        marginTop: '0.5em',
        fontStyle: 'italic',
        color: 'gray',
        marginBottom: '0',
        marginLeft: '1em',
    },
    addButton: {
        width: '95%',
        margin: 'auto',
        display: 'block',
    },
    mainSpinnerLabel: {
        paddingBottom: '30px'
    },
    progressContainer: {
        flexGrow: 1
    },
    teamListContainer: {
        width: '93%',
        display: 'block',
        margin: '1em auto',
    }
});

interface Props extends WithStyles<typeof styles> {
    className?: string;
    currentAccount: Account;
    existingEmailDList: FolderFull['email_domains'];
    teams: Team[];
    emailDKey: string;
    canEdit: boolean;
    excludedEmailDomains: string[];
    onEmailDomainChange?: (emailDKey: string, emailDList: FolderFull['email_domains']) => void;
}

interface States {
    internalEmailDList: FolderFull['email_domains'];
    newEmailValue: string;
    newEmailTeam: Team | null;
    newEmailTeamList: SimpleListItem[];
    isValidEmailDomain: boolean;
    autoCompleteValue: Team | null | undefined;
    autoCompleteKey: string;
}


class EmailDomainForm extends React.Component<Props, States> {

    generateRandomKey = () => {
        const randomKey = Math.random().toString(36).substring(7);
        return randomKey;
    };

    state: States = {
        internalEmailDList: {},
        newEmailValue: '',
        newEmailTeam: null,
        newEmailTeamList: [],
        isValidEmailDomain: false,
        autoCompleteValue: null,
        autoCompleteKey: this.generateRandomKey()
    }

    componentDidMount() {
        const { existingEmailDList } = this.props;
        this.setState({
            internalEmailDList: {...existingEmailDList}
        });
    }

    handleDomainChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, keyIndex: number) => {
        const { onEmailDomainChange, emailDKey } = this.props;

        if (!onEmailDomainChange || !emailDKey) {
            return;
        }

        const { internalEmailDList } = this.state;
        
        if (!internalEmailDList) {
            return;
        }

        const newKeyName = e.target.value;
        const keyToUpdate = Object.keys(internalEmailDList)[keyIndex];

        Object.defineProperty(internalEmailDList, newKeyName,
            Object.getOwnPropertyDescriptor(internalEmailDList, keyToUpdate)!);
        delete internalEmailDList[keyToUpdate];
        onEmailDomainChange(emailDKey, internalEmailDList);
        this.setState({ internalEmailDList });
    }

    onTeamChangeSearch = (e: React.ChangeEvent<{}>, value: Team | null, key: string) => {
        if (!value) {
            return;
        }

        const { onEmailDomainChange, emailDKey } = this.props;
        const { internalEmailDList } = this.state;

        if (!onEmailDomainChange || !emailDKey || !internalEmailDList) {
            return;
        }

        internalEmailDList[key].push(value.team_id);
        onEmailDomainChange(emailDKey, internalEmailDList);
        this.setState({internalEmailDList});
    }

    handleNewEmail = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { excludedEmailDomains } = this.props;

        const match = e.target.value.match(/@(.+)/);
        this.setState({
            newEmailValue: e.target.value,
            isValidEmailDomain: match && excludedEmailDomains.includes(match[1]) ? false : true
        });
    }

    onNewTeamSearch = (e: React.ChangeEvent<{}>, value: Team | null) => {
        if (!value) {
            return;
        }
        
        const { newEmailTeamList } = this.state;

        newEmailTeamList.push(new SimpleListItem(value.team_id, value.name, value.team_id));

        this.setState({ newEmailTeamList, newEmailTeam: null });
    }

    deleteEmailDomain = (keyIndex: number) => {
        const { onEmailDomainChange, emailDKey } = this.props;

        if (!onEmailDomainChange || !emailDKey) {
            return;
        }

        const { internalEmailDList } = this.state;

        if (!internalEmailDList) {
            return;
        }

        const keyToDelete = Object.keys(internalEmailDList)[keyIndex];
        delete internalEmailDList[keyToDelete];
        onEmailDomainChange(emailDKey, internalEmailDList);
        this.setState({
            internalEmailDList
        });
    }

    addNewEmailDomain = () => {
        const { onEmailDomainChange, emailDKey } = this.props;
        const { newEmailTeamList, newEmailValue, internalEmailDList } = this.state;
        if (!internalEmailDList || !onEmailDomainChange) {
            return;
        }

        internalEmailDList[newEmailValue] = newEmailTeamList.map(e => e.id);
        onEmailDomainChange(emailDKey, internalEmailDList);
        setTimeout(() => {
            this.setState({
                newEmailTeam: null,
                newEmailValue: '',
                newEmailTeamList: [],
                internalEmailDList
            });
        }, 500);
    }

    onNewDomainDeleteTeam = (listItem: SimpleListItem) => {
        const { newEmailTeamList } = this.state;

        const delIndex = newEmailTeamList.findIndex(e => e.id === listItem.id);
        newEmailTeamList.splice(delIndex, 1);

        this.setState({ newEmailTeamList });
    }

    onExistingDomainDeleteTeam = (listItem: SimpleListItem, domain: string) => {
        const { onEmailDomainChange, emailDKey } = this.props;

        if (!onEmailDomainChange || !emailDKey) {
            return;
        }

        const { internalEmailDList } = this.state;

        if (!internalEmailDList) {
            return;
        }

        const selectedDomain = internalEmailDList[domain];

        const delIndex = selectedDomain.findIndex(e => e === listItem.id);
        selectedDomain.splice(delIndex, 1);

        onEmailDomainChange(emailDKey, internalEmailDList);
        this.setState({ internalEmailDList });
    }

    filterAvailableTeams = (key?: string) => {
        const { teams } = this.props;

        if (!teams) {
            return [];
        }

        if (!key) {
            const { newEmailTeamList } = this.state;

            return teams.filter(e => newEmailTeamList.findIndex(j => j.id === e.team_id) === -1);
        }

        const { internalEmailDList } = this.state;

        if (!internalEmailDList) {
            return [];
        }

        const currentDomain = internalEmailDList[key];
        return teams.filter(e => currentDomain.indexOf(e.team_id) === -1);
    }

    formatExistingDomainTeams = (domain: string) => {
        const { internalEmailDList } = this.state;
        const { teams } = this.props;

        if (!internalEmailDList) {
            return [];
        }

        const selectedDomain = internalEmailDList[domain];

        return selectedDomain.map(e => {
            return new SimpleListItem(e, teams.find(j => j.team_id === e)!.name, e);
        });
    }

    clearAutocompleteSelector = (evt: React.FocusEvent<HTMLDivElement> | undefined) => {
        this.setState({
            autoCompleteValue: null,
            autoCompleteKey: this.generateRandomKey()
        });
        return;
    }

    public render() {
        const {
            className,
            classes,
            canEdit
        } = this.props;
        const {
            newEmailTeam,
            newEmailValue,
            newEmailTeamList,
            internalEmailDList,
            isValidEmailDomain,
            autoCompleteKey,
            autoCompleteValue
        } = this.state;

        return (
            <div className={`${classes.root} ${className}`} data-testid="module-list">
                <div className={`${classes.container}`}>
                    {
                        Object.keys(internalEmailDList!).map((e, index) => {
                            return <React.Fragment>
                                <div className={classes.simpleFlex}>
                                    <TextField
                                        className={classes.textField}
                                        id={`${e}`}
                                        label={'Email domain'}
                                        defaultValue={e}
                                        onChange={j => this.handleDomainChange(j, index)}
                                        multiline={true}
                                        placeholder={e}
                                        variant="outlined"
                                        disabled={!canEdit}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        helperText="Public domains are not allowed"
                                    />
                                    <IconButton
                                        className={classes.deleteIcon}
                                        aria-label="delete key from email domain"
                                        onClick={j => this.deleteEmailDomain(index)}
                                    >
                                        <DeleteRounded />
                                    </IconButton>
                                </div>
                                <p className={`${classes.tooltipText}`}>
                                    Team list
                                </p>
                                <SimpleList
                                    className={classes.teamListContainer}
                                    items={this.formatExistingDomainTeams(e)}
                                    noItemsLabel={"No teams added"}
                                    canDeleteListItem={true}
                                    keepItemSelected={false}
                                    onDeleteItemClick={(j) => this.onExistingDomainDeleteTeam(j, e)}
                                />
                                <Autocomplete
                                    id={`combo-box-edit-email-${e}`}
                                    key={autoCompleteKey}
                                    className={classes.selectInput}
                                    options={this.filterAvailableTeams(e)}
                                    getOptionLabel={(option) => option.name}
                                    renderInput={(params) => <TextField {...params} label="Pick a team" variant="standard" />}
                                    onChange={(j, pickedValue) => this.onTeamChangeSearch(j, pickedValue, e)}
                                    data-testid={`combo-box-edit-email-${e}`}
                                    value={autoCompleteValue}
                                    disabled={!canEdit}
                                    clearOnBlur={true}
                                    blurOnSelect={true}
                                    onBlur={this.clearAutocompleteSelector}
                                />
                                <Divider className={classes.dividerForm} />
                            </React.Fragment>
                        })
                    }
                    {
                        canEdit &&
                        <div>
                            <p className={`${classes.tooltipText}`}>
                                Add a new email domain
                            </p>
                            <TextField
                                className={classes.textField}
                                id="new_email_domain"
                                label={'Email domain'}
                                value={newEmailValue}
                                onChange={e => this.handleNewEmail(e)}
                                multiline={true}
                                placeholder={'New email domain'}
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={!isValidEmailDomain && newEmailValue !== ''}
                                helperText="Public domains are not allowed"
                            />
                            <p className={`${classes.tooltipText}`}>
                                Team list
                            </p>
                            <SimpleList
                                className={classes.teamListContainer}
                                items={newEmailTeamList}
                                noItemsLabel={"No teams added"}
                                canDeleteListItem={true}
                                keepItemSelected={false}
                                onDeleteItemClick={this.onNewDomainDeleteTeam}
                            />
                            <Autocomplete
                                id={`combo-box-new-email-team`}
                                key={autoCompleteKey}
                                className={classes.selectInput}
                                options={this.filterAvailableTeams()}
                                getOptionLabel={(option) => option.name}
                                renderInput={(params) => <TextField {...params} label="Pick a team" variant="standard" />}
                                onChange={(e, pickedValue) => this.onNewTeamSearch(e, pickedValue)}
                                data-testid={`combo-box-new-email-team`}
                                disabled={!canEdit}
                                placeholder={'Email domain team'}
                                value={newEmailTeam}
                                blurOnSelect={true}
                                clearOnBlur={true}
                                onBlur={this.clearAutocompleteSelector}
                            />
                            {
                                (newEmailTeamList.length >= 1 && newEmailValue !== '' && isValidEmailDomain && newEmailValue[0] === '@') &&
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        className={`${classes.addButton}`}
                                        onClick={this.addNewEmailDomain}
                                    >
                                        Add email domain to Folder
                                    </Button>
                            }
                        </div>
                    }
                </div>
            </div>
        );
    }
}

const MUIComponent = withStyles(styles)(EmailDomainForm);
export {MUIComponent as EmailDomainForm}