import { EncryptionHelper } from "../shared/utils";

export const COOKIE_NAMES = {
    ROUTE: 'r',
    LOGIN_CREDENTIALS: 'lc',
    USER_IDENTIFIERS: 'ui',
    STORED_TASKS: 'st'
}

const aesKey = EncryptionHelper.getAESKey('3hdqN0GrzSKK3');

export class LocalStorageUtil {

    static getItem(keyName: string): unknown | null {
        const itemValue = localStorage.getItem(keyName);
        if (!itemValue || itemValue === '') {
            return null;
        }
        const decryptedItem = EncryptionHelper.decryptText(aesKey, itemValue);
        return JSON.parse(decryptedItem);
    }

    static setItem(keyName: string, itemValue: unknown): void {
        const formattedItem = JSON.stringify(itemValue);
        const encryptedItem = EncryptionHelper.encryptText(aesKey, formattedItem);
        localStorage.setItem(keyName, encryptedItem);
    }

    static clearItem(keyName: string): void {
        localStorage.setItem(keyName, '');
    }

    static clearStorage(): void {
        localStorage.clear();
    }
}