import { Button, Typography } from '@material-ui/core';
import {
    createStyles,
    WithStyles,
    withStyles
} from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import * as React from 'react';
import { CCSpinner } from '../../shared/components/cc-spinner';
import { SimpleListItem } from '../../shared/components/simple-list';
import { PageBoundary, SimpleListPagination } from '../../shared/components/simple-list-pagination';
import { SimpleModal } from '../../shared/components/simple-modal';
import { MarkerOperation } from '../thin-client/thin-client';

const ROWS_PER_PAGE = 10;

const styles = (theme: Theme) => createStyles({
    resumePopup: {
        width: '41em',
        maxHeight: '90%',
        overflowY: 'auto',
        borderRadius: 5
    },
    resumePopupHeader: {
        height: '2em',
        backgroundColor: theme.palette.primary.main,
        borderRadius: '3px 3px 0 0',
        color: 'white',
        padding: '0.5em',
        fontSize: '1.5em'
    },
    popupContentContainer :{
        display: 'flex',
        flexDirection: 'row',
        padding: theme.spacing(1),
    },
    mainSpinnerLabel: {
        paddingBottom: '30px'
    },
    simpleList: {
        overflow: 'auto',
        height: '100%',
        minWidth: '40em',
        margin: '1em 0'
    },
    progressContainer: {
        display: 'block',
        width: '40em'
    },
    modalButtonContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row'
    },
    modalButton: {
        width: '49%',
        margin: '0 1em'
    },
    overrideList: {
        display: 'flex',
        flexDirection: 'column',
        maxHeight: '75%',
        '&.container' : {
            overflow: 'inherit',
            height: 'inherit'
        }
    }
});

interface Props extends WithStyles<typeof styles> {
    isMarkerOperationsResume: boolean;
    markerOperations: MarkerOperation[];
    onMarkerResumePageOverBoundaryReached?: (boundary: PageBoundary, nextPage: number) => Promise<void>;
    isFinalProcessing: boolean;
    closeResumeModal?: () => void;
    onSubmitMarkerChanges?: () => void;

}

class MarkerOperationList extends React.Component<Props> {
    public static defaultProps = {
    };

    public render() {
        const {
            classes,
            isMarkerOperationsResume,
            markerOperations,
            onMarkerResumePageOverBoundaryReached,
            isFinalProcessing,
            closeResumeModal,
            onSubmitMarkerChanges
        } = this.props;

        const currentMarkerResume = markerOperations ? markerOperations.map((marker: MarkerOperation) => {
            return new SimpleListItem(marker.marker_id, `${marker.verb}: ${marker.name}`, marker.marker_id);
        }): [];
        return (
            <SimpleModal
                className={classes.resumePopup}
                open={isMarkerOperationsResume}
                contentClasses={classes.popupContentContainer}
                buttonOkLabel=""
                buttonCancelLabel=""
                header='Marker operations resume'
                headerClassName={classes.resumePopupHeader}
            >
                <div>
                    <CCSpinner
                        label="Submitting changes"
                        labelClassName={classes.mainSpinnerLabel}
                        className={classes.progressContainer}
                        loading={isFinalProcessing}
                        size={200}
                    >
                        <Typography variant="subtitle1">
                        Please review the marker changes before submitting, 
                        once you are sure they are correct click on the submit button
                        </Typography>
                        <SimpleListPagination
                            className={classes.simpleList}
                            classes={{
                                root: classes.overrideList
                            }}
                            keepItemSelected={true}
                            items={currentMarkerResume}
                            isLoading={false}
                            rowsPerPage={ROWS_PER_PAGE}
                            totalItems={currentMarkerResume.length}
                            offset={0}
                            onPageOverBoundary={onMarkerResumePageOverBoundaryReached}
                        />
                        <div className={classes.modalButtonContainer}>
                            <Button
                                variant="contained"
                                color="secondary"
                                className={classes.modalButton}
                                onClick={closeResumeModal}
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                className={classes.modalButton}
                                onClick={onSubmitMarkerChanges}
                            >
                                Submit
                            </Button>
                        </div>
                    </CCSpinner>
                </div>
            </SimpleModal>
        );
    }

};

const MUIComponent = withStyles(styles)(MarkerOperationList);
export {MUIComponent as MarkerOperationList}