import {
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField
} from '@material-ui/core';
import {
    createStyles,
    WithStyles,
    withStyles
} from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import * as React from 'react';
import { Account, Folder } from '../../shared/domain';
import { PageBoundary } from '../../shared/components/simple-grid-pagination';
import { RenderTree } from '../../shared/components/node-tree';
import { PickedType } from '../../features/folders/folders';
import { AccountFolders } from '../account-folders';
import AccountProvider from '../../providers/account.provider';
import _ from 'lodash';

type CreationType = "folder_id" | "tree_view";

const styles = (theme: Theme) => createStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        marginLeft: '2em'
    },
    textField: {
        maxWidth: 400
    },
    textFieldPrefix: {
        maxWidth: 200
    },
    genericButton: {
        alignSelf: 'flex-start',
        marginTop: 20
    },
    searchSelector: {
        width: '20em'
    },
    legacyContainer: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
    },
    subContainer: {
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
    },
    animContainer: {
        transition: 'width 1s'
    },
    treeContainer: {
        width: '70%',
        minHeight: '45em'
    },
    formContainer: {
        width: '30%',
        display: 'flex',
        flex: '1',
        flexDirection: 'column',
        paddingLeft: '2em',
    },
    labelTextField: {
        color: 'rgba(0, 0, 0, 0.87) !important'
    }
});

interface Props extends WithStyles<typeof styles> {
    accountProvider: AccountProvider;
    accounts?: Account[] | null;
    accountsIsLoading: boolean;
    accountsTotalItems?: number;
    accountsItemsOffset: number;
    rowsPerPage: number;
    loading: boolean;
    onErrorModalDisplay?: (errorHeader: string, errorBody: string) => void;
    onAccountDataPageOverBoundary?: (boundary: PageBoundary, nextPage: number) => Promise<void>;
    onLoading?: (isLoading: boolean, progress: number, progressLabel: string) => void;
    onAccountSearch?: (searchName: string) => void;
    onSubmit: (folderId: string, floorPlanName: string, markersPrefix:string, templateFile: File, markersFile?: File) => void;
};

interface States {
    loading: boolean;
    floorPlanName: string;
    folderId: string;
    folderName: string;
    markersPrefix: string;
    markersFile: File | undefined;
    templateFile: File | undefined;
    creationType: CreationType;
    currentAccount: Account | null;
    folderIsLoading: boolean;
    currentNodeTree: RenderTree;
    treeIsLoading: boolean;
    pickedType: PickedType;
    pickedId: string;
    pickedName: string;
    folders: Folder[];
    processedFolders: string[];
    deniedFolders: string[];
    toggledFolders: string[];
    folderPath: string;
    treeScrollPosition: number;
}

class FloorPlanGenerator extends React.Component<Props, States> {
    public static defaultProps = {};

    state: States = {
        loading: false,
        folderId: '',
        folderName: '',
        floorPlanName: '',
        markersPrefix: '',
        markersFile: undefined as File | undefined,
        templateFile: undefined as File | undefined,
        creationType: "tree_view",
        currentAccount: null,
        folderIsLoading: false,
        currentNodeTree: {id: '', name:'', children:[], path: ''},
        treeIsLoading: false,
        pickedType: PickedType.None,
        pickedId: '',
        pickedName: '',
        folders: [],
        processedFolders: [],
        deniedFolders: [],
        toggledFolders: [],
        folderPath: '',
        treeScrollPosition: 0
    };

    setLoading = (isLoading: boolean, progress: number, progressLabel: string) => {
        const { onLoading } = this.props;
        if (onLoading) {
            onLoading(isLoading, progress, progressLabel);
        }
    };

    onTextChanged = (type: 'floorPlanName' | 'folderId' | 'markersPrefix') => (e: any) => {
        const fileName = e.target.value;
        const newState: {floorPlanName?: string, folderId?: string, markersPrefix?: string} = {};
        newState[type] = fileName;
        this.setState(newState as States);
    };

    onFileChanged = (type: 'markersFile' | 'templateFile') => (e: any) => {
        const fileName = e.target.files[0];
        const newState: {markersFile?: File, templateFile?: File} = {};
        newState[type] = fileName;
        this.setState(newState as States);
    };

    onSubmitClicked = () => {
        const { onSubmit } = this.props;
        const {
            floorPlanName,
            folderId,
            markersPrefix,
            markersFile,
            templateFile
        } = this.state;

        this.setLoading(true, 0, '');
        
        if (onSubmit && templateFile) {
            onSubmit(folderId, floorPlanName, markersPrefix, templateFile, markersFile);
        }
    };

    changeFolderOrigin = (event: React.ChangeEvent<{ value: unknown }>) => {
        this.setState({creationType: event.target.value as CreationType});
    };

    onAccountClick = (account: Account, isRefresh?: boolean) => {
        const { currentAccount } = this.state;
        if (currentAccount === account && !isRefresh) {
            return;
        }

        this.setState({
            currentAccount: account,
            folderId: '',
            folderName: ''
        });
        this.fetchFoldersFromAccount(account);
    };

    fetchFoldersFromAccount = (account: Account) => {
        const { accountProvider, onErrorModalDisplay } = this.props;
        this.setState({
            folderIsLoading: true,
            pickedType: PickedType.None,
            pickedId: '',
            processedFolders: []
        });

        return accountProvider.getRootFoldersByAccountId(account.accountId)
            .then((results) => {
                const folders: Folder[] = results;
                if(!folders) {
                    if (onErrorModalDisplay) {
                        onErrorModalDisplay(
                            'Error on fetching folders',
                            'No folders found on this account'
                        );
                    }
                    return;
                }

                // TRANSFORM THE FOLDERS INTO TREE VIEW
                const accountTree: RenderTree = {
                    id: account.accountId,
                    name: account.name,
                    children: [],
                    path: ''
                };

                const treeNodes: RenderTree[] = folders.map(element => {
                    const newItem = {
                        id: element.id,
                        name: element.name,
                        children: [],
                        path: `${element.id}__`
                    }
                    return newItem as RenderTree;
                });

                accountTree.children = treeNodes;

                this.setState({
                    folders,
                    currentNodeTree: accountTree,
                    folderIsLoading: false
                });

        }).catch((error) => {
            // tslint:disable-next-line:no-console
            console.error(error);
            const { status } = error.response;
            if (onErrorModalDisplay) {
                onErrorModalDisplay(
                    `Folder fetch error ${status}`,
                    error.stack_trace,
                );
            }
            this.setState({folderIsLoading: false});
        });

    };

    deepTreeSearch = (treeData: RenderTree, searchId: string) => {
        let foundTree: RenderTree | undefined;
        let searchLevel: RenderTree[] | undefined = treeData.children;
        do {
            foundTree = searchLevel?.find((e: RenderTree) => e.id === searchId);
            let tempLevel: any = [];
            searchLevel?.forEach((element: RenderTree) => {
                tempLevel = tempLevel.concat(element.children);
            });
            searchLevel = tempLevel;
        } while(!foundTree);
        return foundTree;
    };

    updateChildrenOnPath = (treeData: RenderTree, nodeId: string, children: RenderTree[]) => {
        treeData.children!.forEach(element => {
            if (_.isEqual(element.id, nodeId)) {
                element.children = children;
            } else {
                this.updateChildrenOnPath(element, nodeId, children);
            }
        });
    };

    onNodeSelected = (treeData: RenderTree , nodeId: string, nodeLabel: string, updateCallback: (nodeIds: string[]) => void) => {
        const { folders, processedFolders, deniedFolders, currentAccount } = this.state;
        const { accountProvider } = this.props;
        // WE CANNOT PICK THE ROOT FOLDER
        if(currentAccount!.accountId === nodeId) {
            this.setState({
                pickedType: PickedType.None,
                pickedId: '',
                pickedName: '',
                treeIsLoading: false,
                folderPath: ''
            });
            return;
        }
        this.setState({
            pickedType: PickedType.None,
            pickedId: '',
            pickedName: '',
            treeIsLoading: true,
            folderPath: ''
        });
        // CHECK PICK TYPE
        // FOLDER
        const currentNodePos = this.deepTreeSearch(treeData, nodeId);
        if(folders.find(e => e.id === nodeId)) {
            // DID WE ALREADY CHECK FOR CHILDREN?
            if (processedFolders.find(e => e === nodeId)) {
                // IS IT VALID?
                // FOLDER CHILDREN
                if (deniedFolders.find(e => e === nodeId)) {
                    this.setState({treeIsLoading: false});
                    return;
                }
                // FLOORPLAN CHILDREN
                // WE CAN USE THIS KIND OF FOLDERS TO BE SET AS LOCATION
                this.setState({
                    pickedType: PickedType.Folder,
                    pickedId: nodeId,
                    pickedName: currentNodePos.name,
                    currentNodeTree: treeData,
                    treeIsLoading: false,
                    processedFolders,
                    folderPath: currentNodePos.path
                });
                return;
            }
            // EMPTY
            // CHECK FOR FOLDER CHILDREN FIRST
            return accountProvider.getChildrenFolderByFolderId(currentAccount!.accountId, nodeId)
                .then((results: Folder[]) => {
                    if (results.length > 0) {
                        const nodes: RenderTree[] = results.map(element => {
                            const newItem = {
                                id: element.id,
                                name: element.name,
                                children: [],
                                path: `${currentNodePos.path}${element.id}__`
                            }
                            return newItem as RenderTree;
                        });
                        this.updateChildrenOnPath(treeData, nodeId, nodes);
                        const currentPath = currentNodePos.path.slice(0, -2);
                        updateCallback([currentAccount!.accountId, ...currentPath.split('__'), ...nodes.map(e => e.id)]);
                        processedFolders.push(nodeId);
                        deniedFolders.push(nodeId);
                        // WE GOT CHILDREN FOLDER WE CANNOT USE THIS AS LOCATION
                        this.setState({
                            pickedType: PickedType.None,
                            pickedId: '',
                            pickedName: '',
                            currentNodeTree: treeData,
                            treeIsLoading: false,
                            processedFolders,
                            folders: folders.concat(results),
                            folderPath: currentNodePos.path
                        });
                        return;
                    } else {
                        // IF WE DON'T HAVE CHILDREN FOLDER THEN ITS VALID LOCATION FOR FLOORPLANS
                        processedFolders.push(nodeId);
                        this.setState({
                            pickedType: PickedType.Folder,
                            pickedId: nodeId,
                            pickedName: currentNodePos.name,
                            currentNodeTree: treeData,
                            treeIsLoading: false,
                            processedFolders,
                            folderPath: currentNodePos.path
                        });
                    }
            }).catch((error) => {
                // tslint:disable-next-line:no-console
                console.error(error);
            });
        }
        this.setState({
            pickedType: PickedType.None,
            pickedId: '',
            pickedName: '',
            currentNodeTree: treeData,
            treeIsLoading: false,
            folderPath: currentNodePos.path
        });
        return;
    }

    onNodeToggled = (nodeIds: string[]) => {
        this.setState({toggledFolders: nodeIds});
    };

    onFolderSelect = () => {
        const { pickedId, pickedName, pickedType } = this.state;

        if (!pickedId || pickedType !== PickedType.Folder) {
            return;
        }

        this.setState({folderId: pickedId, folderName: pickedName});
    };

    public render() {
        const {
            classes,
            loading,
            rowsPerPage,
            accounts,
            accountsIsLoading,
            accountsTotalItems,
            accountsItemsOffset,
            onAccountDataPageOverBoundary,
            onAccountSearch
        } = this.props;
        
        const {
            floorPlanName,
            folderId,
            markersPrefix,
            templateFile,
            creationType,
            currentNodeTree,
            treeIsLoading,
            pickedType,
            pickedId,
            folderName,
            folders,
            currentAccount,
            folderIsLoading
        } = this.state;


        const isButtonDisabled = (
            (floorPlanName.length < 1) ||
            (folderId.length < 1) ||
            !templateFile || loading);

        const BASIC_FORM_COMPONENT = <>
            <TextField
                id="floorplan-name"
                label="Floor Plan Name * "
                placeholder="My Floor Plan"
                className={classes.textField}
                value={floorPlanName}
                onChange={this.onTextChanged('floorPlanName')}
                margin="normal"
                InputLabelProps={{
                    shrink: true,
                }}
            />
            <h4>Blank Floor Plan (PDF) * :</h4>
            <input
                type="file"
                id="template-path"
                data-testid="template-path"
                className="input-file"
                accept=".pdf"
                disabled={loading}
                onChange={this.onFileChanged('templateFile')}
            />
            <h4>Floor Plan Markers (XFDF) :</h4>
            <input
                type="file"
                id="markers-path"
                data-testid="markers-path"
                className="input-file"
                accept=".xfdf"
                disabled={loading}
                onChange={this.onFileChanged('markersFile')}
            />
            <TextField
                id="markers-prefix"
                label="Markers Prefix"
                placeholder="1st FL -"
                className={classes.textFieldPrefix}
                value={markersPrefix}
                onChange={this.onTextChanged('markersPrefix')}
                margin="normal"
                InputLabelProps={{
                    shrink: true,
                }}
            />
            <Button
                className={classes.genericButton}
                variant="contained"
                color="primary"
                disabled={isButtonDisabled}
                onClick={this.onSubmitClicked}
            >
            { loading ? 'Submitting' : 'Submit' }
            </Button>
        </>

        const isFolderValid = pickedId !== '' && pickedId !== '__';
        
        return (
            <div className={classes.root}>
                <div>
                    <h2>Select the folder from:</h2>
                    <FormControl className={classes.searchSelector}>
                        <InputLabel id="creation_type_selector">Folder selection: </InputLabel>
                        <Select
                            labelId="creation_type_selector"
                            id="folder-origin-selector"
                            data-testid="folder-origin-selector"
                            value={creationType}
                            onChange={this.changeFolderOrigin}
                        >
                            <MenuItem value="tree_view">Tree View</MenuItem>
                            <MenuItem value="folder_id">Folder Id</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                {
                    creationType === "folder_id" ? 
                        <div className={classes.legacyContainer}>
                            <TextField
                                id="folder-id"
                                label="Folder ID * "
                                placeholder="<Folder ID>"
                                className={classes.textField}
                                value={folderId}
                                onChange={this.onTextChanged('folderId')}
                                margin="normal"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                            {BASIC_FORM_COMPONENT}
                        </div>
                    : <div className={classes.subContainer}>
                        <AccountFolders
                            className={`${classes.animContainer} ${classes.treeContainer}`}
                            rowsPerPage={rowsPerPage}
                            accounts={accounts}
                            accountsIsLoading={accountsIsLoading}
                            accountsTotalItems={accountsTotalItems}
                            accountsItemsOffset={accountsItemsOffset}
                            currentAccountName={currentAccount?.name}
                            folders={folders}
                            folderIsLoading={folderIsLoading}
                            onAccountClick={this.onAccountClick}
                            onAccountDataPageOverBoundary={
                                onAccountDataPageOverBoundary
                            }
                            currentNodeTree={currentNodeTree}
                            treeIsLoading={treeIsLoading}
                            onNodeSelected={this.onNodeSelected}
                            onNodeToggled={this.onNodeToggled}
                            pickedType={pickedType}
                            pickedId={pickedId}
                            onFolderActionButtonClick={this.onFolderSelect}
                            isActionButtonEnabled={isFolderValid}
                            folderActionButtonLabel="Select this Folder"
                            onAccountSearch={onAccountSearch}
                        />
                        {
                            folderId !== '' && 
                                <div className={classes.formContainer}>
                                    <TextField
                                        id="folder-id"
                                        label="Selected Folder"
                                        className={classes.labelTextField}
                                        value={folderName}
                                        margin="normal"
                                        disabled={true}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                    {BASIC_FORM_COMPONENT}
                                </div>
                        }
                    </div>
                }
            </div>
        );
    }
};

const MUIComponent = withStyles(styles)(FloorPlanGenerator);
export { MUIComponent as FloorPlanGenerator };
