import * as React from 'react';

import {
    Button,
    FormControl,
    FormHelperText,
    IconButton,
    Input,
    InputLabel,
    MenuItem,
    TextField,
    Typography
} from '@material-ui/core';
import {
    createStyles,
    WithStyles,
    withStyles
} from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { Close } from '@material-ui/icons';
import memoize from 'memoize-one';
import { SimpleModal } from '../../shared/components/simple-modal';
import { Account } from '../../shared/domain';

const REFRESH_RATE_MIN_VALUE = 60;

const styles = (theme: Theme) => createStyles({
    field: {
        width: '100%'
    },
    textField: {},
    numberField: {},
    closeButton: {},
    root: {
        padding: '1em',
        width: '35em'
    },
    body: {},
    header: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between'
    },
    footer: {
        margin: '0.5em',
        width: '100%'
    },
    readOnlyInputLabel: {
        fontSize: '0.85em'
    },
    button: {
        alignSelf: 'flex-start',
        marginRight: '1em'
    },
    submitButton: {},
    cancelButton: {}
});

export interface AnalyticsTabForm {
    accountId: string
    id?: string,
    refreshRate: number,
    tabName: string,
    tabOrder: number,
    url: string,
    userId?: string,
    userName: string
}

interface Props extends WithStyles<typeof styles> {
    open: boolean;
    accounts: Account[];
    headerText: string;
    className?: string;
    defaultAnalyticsTabForm: AnalyticsTabForm;
    submitButtonText: string;
    onSubmit?:  (analyticsTabForm: AnalyticsTabForm) => void;
    onClose?: () => void;
};

interface States {
    analyticsTabForm: AnalyticsTabForm;
}

class AnalyticsTabModal extends React.Component<Props, States> {
    public static defaultProps = {
        open: false,
        headerText: 'Create new tab',
        submitButtonText: "Submit",
        defaultAnalyticsTabForm: {
            tabName: '',
            url: '',
            userName: '',
            refreshRate: 300,
            tabOrder: 0,
            accountId: ''
        }
    };

    getAccountItems = memoize((accounts: Account[] | undefined) => {
        return accounts ? accounts.map((account, index) => {
            return (
                <MenuItem
                    key={`account-${index}${account.accountId}`}
                    value={account.accountId}
                >
                    {account.name}
                </MenuItem>
            );
        }) : [];
    });

    state = {
        analyticsTabForm: this.props.defaultAnalyticsTabForm
    };

    normalizeRefreshRate = (refreshRateValue: string | number): number => {
        let newRefreshRateValue = Number(refreshRateValue);
        if (newRefreshRateValue < 0) {
            newRefreshRateValue = 0;
        } else if ((newRefreshRateValue > 0) && (newRefreshRateValue < REFRESH_RATE_MIN_VALUE)){
            newRefreshRateValue =  REFRESH_RATE_MIN_VALUE;
        }

        return newRefreshRateValue;
    }

    normalizeTabOrderValue = (tabOrderValue: string | number): number => {
        let newTabOrderValue = Number(tabOrderValue);
        if (isNaN(newTabOrderValue) || (newTabOrderValue < 0)) {
            newTabOrderValue = 0;
        }

        return newTabOrderValue;
    }

    onAnalyticsTabFormFieldChanged = 
        (type: 'tabName' | 'tabOrder' | 'url' | 'userName' | 'refreshRate' | 'accountId') => 
            (event: React.ChangeEvent<HTMLInputElement>) => {
                const { analyticsTabForm } = this.state;
                const stateValue = event.target.value;
                const newState: {[id: string] : string | number} = {};
                newState[type] = stateValue;

                this.setState({analyticsTabForm: {...analyticsTabForm, ...newState}});
            };

    onRefreshRateBlurred = (event: React.FocusEvent<HTMLInputElement>) => {
        const newStateValue = event.target.value;
        const { analyticsTabForm } = this.state;
        const { refreshRate } = analyticsTabForm;

        const newStateValueNumber = this.normalizeRefreshRate(newStateValue);

        if (newStateValueNumber.toString() === refreshRate.toString()) {
            return;
        }

        this.setState({analyticsTabForm: {...analyticsTabForm, ...{refreshRate: newStateValueNumber}}});
    }

    onTabOrderBlurred = (event: React.FocusEvent<HTMLInputElement>) => {
        const newStateValue = event.target.value;
        const { analyticsTabForm } = this.state;
        const { tabOrder } = analyticsTabForm;

        const newStateValueNumber = this.normalizeTabOrderValue(newStateValue);
        if (!newStateValueNumber ||
            !tabOrder ||
            (newStateValueNumber.toString() === tabOrder.toString())
           ) {
            return;
        }

        this.setState({analyticsTabForm: {...analyticsTabForm, ...{tabOrder: newStateValueNumber}}});
    }

    onSubmit = () => {
        const {
            accounts,
            onSubmit,
            defaultAnalyticsTabForm
        } = this.props;
        const { analyticsTabForm } = this.state;

        if (!analyticsTabForm.accountId && accounts && (accounts.length === 1)) {
            analyticsTabForm.accountId = accounts[0].accountId;
        }
        
        analyticsTabForm.refreshRate = this.normalizeRefreshRate(analyticsTabForm.refreshRate);
        analyticsTabForm.tabOrder = this.normalizeTabOrderValue(analyticsTabForm.tabOrder);

        this.setState({
            analyticsTabForm: defaultAnalyticsTabForm
        });

        if (onSubmit) {
            onSubmit(analyticsTabForm);
        }
    }

    onClose = () => {
        const { onClose, defaultAnalyticsTabForm } = this.props;
        this.setState({
            analyticsTabForm: defaultAnalyticsTabForm
        });

        if (onClose) {
            onClose();
        }
    }

    public render() {
        const {
            classes,
            className,
            open,
            headerText,
            accounts,
            submitButtonText
        } = this.props;

        const {
            analyticsTabForm: {
                tabName,
                url,
                userName,
                refreshRate,
                tabOrder,
                accountId
            }
        } = this.state;

        const computedAccountId = 
            accountId ? 
                accountId
            : (accounts && (accounts.length === 1)) ?
                accounts[0].accountId
                : '';

        const isSubmitButtonDisabled = (
            (tabName === '') ||
            (url === '') ||
            (userName === '') ||
            (refreshRate?.toString() === '') ||
            (tabOrder?.toString() === '') ||
            (computedAccountId === ''));

        const submitButtonStyles = `${classes.button} ${classes.submitButton}`;
        const cancelButtonStyles = `${classes.button} ${classes.cancelButton}`;
        const textFieldStyles = `${classes.field} ${classes.textField}`;
        const numberFieldStyles = `${classes.field} ${classes.numberField}`;

        return (
            <SimpleModal
                open={open}
                className={`${classes.root}${className ? ` ${className}` : ''}`}
                header={
                    <div className={classes.header}>
                        <Typography variant="h5">
                            {headerText}
                        </Typography>
                        <IconButton
                            className={classes.closeButton}
                            onClick={this.onClose}
                        >
                            <Close />
                        </IconButton>
                    </div>
                }
                footer={
                    <div className={classes.footer}>
                        <Button
                            className={submitButtonStyles}
                            variant="contained"
                            color="secondary"
                            disabled={isSubmitButtonDisabled}
                            onClick={this.onSubmit}
                        >
                            {submitButtonText}
                        </Button>
                        <Button
                            className={cancelButtonStyles}
                            variant="contained"
                            color="default"
                            onClick={this.onClose}
                        >
                            Cancel
                        </Button>
                    </div>
                }
            >
                <div className={classes.body}>
                { accounts.length < 2 ?
                    <React.Fragment>
                        <InputLabel className={classes.readOnlyInputLabel}>
                            Account
                        </InputLabel>
                        <Typography variant="subtitle1">{accounts[0].name}</Typography>
                    </React.Fragment>
                  :
                    <TextField
                        id="account-id"
                        label="Account"
                        className={textFieldStyles}
                        select={true}
                        value={computedAccountId}
                        onChange={this.onAnalyticsTabFormFieldChanged('accountId')}
                    >
                        {
                            this.getAccountItems(accounts)
                        }
                    </TextField>
                }
                    <TextField
                        id="tab-name"
                        label="Tab Name"
                        placeholder="<Tab Name>"
                        InputLabelProps={{
                            shrink: true
                        }}
                        className={textFieldStyles}
                        value={tabName}
                        onChange={this.onAnalyticsTabFormFieldChanged('tabName')}
                        margin="normal"
                    />
                    <FormControl
                        className={numberFieldStyles}
                        margin="normal">
                        <InputLabel
                            shrink={true}
                        >
                            Tab Order
                        </InputLabel>
                        <Input
                            id="tab-order"
                            placeholder="<Tab Order>"
                            type="number"
                            className={numberFieldStyles}
                            value={tabOrder}
                            onChange={this.onAnalyticsTabFormFieldChanged('tabOrder')}
                            onBlur={this.onTabOrderBlurred}
                        />
                        <FormHelperText>
                            Allowed values are zero or greater. {'>'}100 on the right of report tab, {'<'}=100 on the left.
                        </FormHelperText>
                    </FormControl>
                    <FormControl
                        className={numberFieldStyles}
                        margin="normal">
                        <InputLabel>Refresh Rate (in seconds)</InputLabel>
                        <Input
                            id="refresh-rate"
                            placeholder="<Refresh Rate>"
                            type="number"
                            className={numberFieldStyles}
                            value={refreshRate}
                            onChange={this.onAnalyticsTabFormFieldChanged('refreshRate')}
                            onBlur={this.onRefreshRateBlurred}
                        />
                        <FormHelperText>
                            0=Disable Refresh - Minimum Refresh is {REFRESH_RATE_MIN_VALUE}s
                        </FormHelperText>
                    </FormControl>
                    <TextField
                        id="url"
                        label="Url"
                        placeholder="<Url>"
                        InputLabelProps={{
                            shrink: true
                        }}
                        className={textFieldStyles}
                        value={url}
                        onChange={this.onAnalyticsTabFormFieldChanged('url')}
                        margin="normal"
                    />
                    <TextField
                        id="user-name"
                        label="User Name"
                        placeholder="<User Name>"
                        InputLabelProps={{
                            shrink: true
                        }}
                        className={textFieldStyles}
                        value={userName}
                        onChange={this.onAnalyticsTabFormFieldChanged('userName')}
                        margin="normal"
                    />
                </div>
            </SimpleModal>
        );
    }
};

const MUIComponent = withStyles(styles)(AnalyticsTabModal);
export { MUIComponent as AnalyticsTabModal };
