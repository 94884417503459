import {
    Button,
    IconButton,
    Paper,
    TextField,
    Typography
} from '@material-ui/core';
import {
    createStyles,
    WithStyles,
    withStyles
} from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { AddOutlined, ClearOutlined, SearchOutlined } from '@material-ui/icons';
import * as React from 'react';
import { PickedType } from '../../features/marker-export/marker-export';
import { CCSpinner } from '../../shared/components/cc-spinner';
import { NodesTreeView, RenderTree } from '../../shared/components/node-tree';
import { PageBoundary } from '../../shared/components/simple-grid-pagination';
import { SimpleListItem } from '../../shared/components/simple-list';
import { SimpleListPagination } from '../../shared/components/simple-list-pagination';
import { Account, Folder } from '../../shared/domain';

const styles = (theme: Theme) => createStyles({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    container: {
        display: 'flex',
        flexDirection: 'column'
    },
    heading: {
        fontWeight: 700,
        marginBottom: '0.5em',
        marginTop: '1em'
    },
    ellipsis: {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden'
    },
    simpleList: {
        overflow: 'auto',
        height: '100%',
        minWidth: '20em'
    },
    simpleListAccounts: {
        marginRight: '2em',
        width:'22em'
    },
    simpleListTeams: {
        width: '36em'
    },
    teamSpinnerContainer: {
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '3em',
        marginBottom: '4em',
        borderRadius: '0.4em'
    },
    noAccountContainer: {
        fontWeight: 'bold',
         '& > *': {
            color: 'rgba(0,0,0,0.2)'
        }
    },
    stretchContainer: {
        flex: '1',
        transition: 'width 1s'
    },
    treeContainer: {
        background: '#fff',
        padding: '1em',
        height: '100%'
    },
    treePaper: {
        height: '80%',
        maxHeight: '60em',
        overflowY: 'scroll'
    },
    mainSpinnerLabel: {
        paddingBottom: '30px'
    },
    progressContainer: {
        flexGrow: 1
    },
    titleContainer: {
        marginTop: '1.5em',
        marginBottom: '0.5em'
    },
    buttonContainer: {
        marginTop: '0.5em',
        marginBottom: '1.5em'
    },
    buttonExport: {
        float: 'right'
    },
    buttonTitle: {
        display: 'inline'
    },
    middleButton: {
        marginLeft: '1em'
    },
    searchInputContainer: {
        marginBottom: '1em'
    },
    searchInput: {
        width: '100%',
        paddingLeft: '1em'
    },
});

interface Props extends WithStyles<typeof styles> {
    className?: string;
    accounts?: Account[] | null;
    accountsIsLoading: boolean;
    currentAccountName?: string;
    folderIsLoading: boolean;
    accountsTotalItems?: number;
    accountsItemsOffset: number;
    rowsPerPage: number;
    folders?: Folder[] | null;
    onAccountClick?: (account: Account) => void ;
    onAccountDataPageOverBoundary?: (boundary: PageBoundary, nextPage: number) => Promise<void>;
    currentNodeTree: RenderTree;
    treeIsLoading: boolean;
    onNodeSelected?: (treeData: RenderTree , nodeId: string, nodeLabel: string, updateCallback: (nodeIds: string[]) => void) => void;
    onNodeToggled?: (nodeIds: string[]) => void;
    pickedType: PickedType;
    pickedId: string;
    isActionButtonEnabled: boolean;
    folderActionButtonLabel: string;
    onFolderClick?: (pickedTypeJob: PickedType) => void;
    onFolderActionButtonClick?: () => void;
    onAccountSearch?: (searchName: string) => void;
};

interface States {
    folderIsLoading: boolean;
    accountNameSearch: string;
    treeScrollPosition: number;
};

class AccountFolders extends React.Component<Props, States> {
    public static defaultProps = {
        currentAccountName: '',
        accountsItemsOffset: 0
    };

    state = {
        folderIsLoading: false,
        accountNameSearch: '',
        treeScrollPosition: 0
    };

    onAccountClicked = (listItem: SimpleListItem) => {
        const {accounts, onAccountClick} = this.props;
        if(!accounts) {
            return;
        }

        const currentAccount = accounts.find((account:Account) => {
            return account.accountId === listItem.id;
        });
        if (!currentAccount) {
            return;
        }

        if (onAccountClick) {
            onAccountClick(currentAccount);
        }
    };

    onFolderClicked = () => {
        const { onFolderClick } = this.props;
        if( onFolderClick ) {
            onFolderClick(PickedType.Folder);
        }
    };

    onAccountNameSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        const searchValue = event.target.value;

        this.setState({
            accountNameSearch: searchValue
        });
    };

    onAccountNameKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        const { accountNameSearch } = this.state;
        const { onAccountSearch } = this.props;

        if (!onAccountSearch) {
            return;
        }

        if (event.key === 'Delete' || event.key === 'Backspace') {
            if (accountNameSearch === '') {
                onAccountSearch('');
                return;
            }
        }

        if (event.key === 'Enter') {
            onAccountSearch(accountNameSearch);
            return;
        }
    };

    onButtonSearch = () => {
        const { accountNameSearch } = this.state;
        const { onAccountSearch } = this.props;

        if (!onAccountSearch) {
            return;
        }

        onAccountSearch(accountNameSearch);
    };

    onCancelSearch = () => {
        const { onAccountSearch } = this.props;

        if (!onAccountSearch) {
            return;
        }

        this.setState({ accountNameSearch: '' }, () => {
            onAccountSearch('');
        });
    };

    centerScroll = (evt: React.UIEvent<HTMLElement>) => {
        this.setState({
            treeScrollPosition: evt.currentTarget.scrollTop
        })
    };

    public render() {
        const {
            className,
            classes,
            accounts,
            currentAccountName,
            accountsIsLoading,
            accountsTotalItems,
            accountsItemsOffset,
            rowsPerPage,
            onAccountDataPageOverBoundary,
            folders,
            folderIsLoading,
            currentNodeTree,
            treeIsLoading,
            onNodeSelected,
            onNodeToggled,
            onFolderActionButtonClick,
            folderActionButtonLabel,
            isActionButtonEnabled
        } = this.props;

        const {
            accountNameSearch,
            treeScrollPosition
        } = this.state;

        const accountListItems = accounts ? accounts.map((account: Account) => {
            const accountId = account.accountId;
            return new SimpleListItem(accountId, account.name, accountId);
        }) : [];

        return (
            <div className={`${classes.root} ${className}`} data-testid="accountPicker">
                <div className={`${classes.container} ${classes.simpleListAccounts}`}>
                    <Typography
                        variant='h6'
                        className={`${classes.heading} ${classes.ellipsis}`}
                    >
                        Customer Organizations
                    </Typography>
                    <Paper className={classes.searchInputContainer}>
                        <TextField
                            data-testid="account-searchbar"
                            className={classes.searchInput}
                            type="text"
                            placeholder="Search by name"
                            variant="standard"
                            multiline={false}
                            value={accountNameSearch}
                            InputProps={{
                                disableUnderline: true,
                                endAdornment: (
                                    <React.Fragment>
                                        {
                                            accountNameSearch !== '' &&
                                                <IconButton
                                                    aria-label="cancel"
                                                    data-testid="cancel-search"
                                                    onClick={this.onCancelSearch}
                                                >
                                                    <ClearOutlined />
                                                </IconButton>
                                        }
                                        <IconButton
                                            aria-label="search"
                                            data-testid="commit-search"
                                            onClick={this.onButtonSearch}
                                        >
                                            <SearchOutlined />
                                        </IconButton>
                                    </React.Fragment>
                                )
                            }}
                            onChange={this.onAccountNameSearch}
                            onKeyDown={this.onAccountNameKeyDown}
                        />
                    </Paper>
                    <SimpleListPagination
                        className={classes.simpleList}
                        keepItemSelected={true}
                        items={accountListItems}
                        isLoading={accountsIsLoading}
                        onListItemClick={this.onAccountClicked}
                        rowsPerPage={rowsPerPage}
                        totalItems={accountsTotalItems}
                        offset={accountsItemsOffset}
                        onPageOverBoundary={onAccountDataPageOverBoundary}
                        noItemsLabel='No accounts found'
                    />
                </div>
                { folders && (folders.length > 0) ?
                    <div className={`${classes.container} ${classes.stretchContainer}`}>
                        <CCSpinner
                            label='Loading folders'
                            labelClassName={classes.mainSpinnerLabel}
                            className={classes.progressContainer}
                            loading={folderIsLoading}
                            size={200}
                        >
                            <div className={classes.titleContainer}>
                                <Typography
                                    variant='h6'
                                    className={`${classes.heading} ${classes.ellipsis} ${classes.buttonTitle}`}
                                >
                                    Folders on {currentAccountName}
                                </Typography>
                            </div>

                            {
                                isActionButtonEnabled &&
                                    <div className={classes.buttonContainer}>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            className={classes.middleButton}
                                            startIcon={<AddOutlined />}
                                            onClick={onFolderActionButtonClick}
                                            >
                                            {folderActionButtonLabel}
                                        </Button>
                                    </div>
                            }

                            <Paper 
                                className={classes.treePaper}
                                onScrollCapture={this.centerScroll}
                            >
                                <NodesTreeView
                                    treeData={currentNodeTree}
                                    isLoading={treeIsLoading}
                                    onNodeSelected={onNodeSelected}
                                    onNodeToggled={onNodeToggled}
                                    className={classes.treeContainer}
                                    loadingOverlayPosition={treeScrollPosition}
                                />
                            </Paper>
                            
                        </CCSpinner>
                    </div>
                    : folderIsLoading ?
                        <div className={`${classes.container} ${classes.stretchContainer}`}>
                            <CCSpinner
                                label='Loading folders'
                                labelClassName={classes.mainSpinnerLabel}
                                className={classes.progressContainer}
                                loading={folderIsLoading}
                                size={200}
                            />
                        </div>
                    :
                    <Paper className={`${classes.container} ${classes.simpleListTeams} ${classes.teamSpinnerContainer} ${(!folderIsLoading ? classes.noAccountContainer : '')} ${classes.stretchContainer}`}>
                        <CCSpinner
                            loading={folderIsLoading}
                            size={70}
                        >
                            <Typography variant={!folders ? 'h4' : 'h5'}>
                                {
                                    !folders ? 'No organization selected' : `No folders for ${currentAccountName ? currentAccountName : 'the selected account'}`
                                }
                            </Typography>
                        </CCSpinner>
                    </Paper>
                }
            </div>
        );
    }
}

const MUIComponent = withStyles(styles)(AccountFolders);
export {MUIComponent as AccountFolders}