export function sortByKey(key: string) {
    return (a: any, b: any) => {
        if (typeof a[key] === 'string') {
            const keyA = a[key].toUpperCase(); // ignore upper and lowercase
            const keyB = b[key].toUpperCase(); // ignore upper and lowercase
            
            if (keyA < keyB) {
                return -1;
            }
            if (keyA > keyB) {
                return 1;
            }

            // keys must be equal
            return 0;
        } else if (typeof a[key] === 'number') {
            return a[key] - b[key];
        } else {
            return false
        }
    }
}
