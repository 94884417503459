import { Button, createStyles, IconButton, InputLabel, Paper, TextField, Theme, Typography, withStyles } from "@material-ui/core";
import { WithStyles } from "@material-ui/styles";
import { FloorplanFull } from "../../models";
import { Account } from "../../shared/domain";
import * as React from 'react';
import _ from "lodash";
import { CCSpinner } from "../../shared/components/cc-spinner";
import { DeleteForeverRounded } from "@material-ui/icons";


export type FloorplanFormInput = 'string';
export interface floorplanForm {
    field: string;
    required: boolean;
    input: FloorplanFormInput;
    items?: string[];
}

const styles = (theme: Theme) => createStyles({
    [theme.breakpoints.down('lg')]: {
        simpleList: {
            minWidth: '32em !important'
        },
    },
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    container: {
        display: 'flex',
        flexDirection: 'column'
    },
    heading: {
        fontWeight: 700,
        marginBottom: '0.5em',
        marginTop: '1em'
    },
    ellipsisDetails: {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'visible'
    },
    simpleList: {
        height: '100%',
        overflowY: 'scroll',
        overflowX: 'hidden',
        minWidth: '35em',
    },
    textField: {
        padding: '1em',
        width: '100%',
        '& label': {
            fontWeight: 'bolder',
            padding: '1em 0 0 1em',

            fontSize: '1.4em',
            color: 'rgba(0, 0, 0, 0.8) !important'
        },
        '&::before': {
            borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
            borderBottomStyle: 'solid'
        }
    },
    listHeader: {
        paddingLeft: '1em',
        '& span': {
            fontWeight: 'bolder'
        }
    },
    dividerForm: {
        margin: '1em'
    },
    detailBorder: {
        border: '1px solid #e1e1e1'
    },
    processingSpinner: {
        position: 'absolute',
        width: '35em',
        height: '100%',
        background: 'rgba(113, 113, 113, 0.47)',
        zIndex: 9
    },
    saveButton: {
        marginTop: '1em'
    },
    errorPrefixAlert: {
        paddingLeft: '1em'
    },
    deleteButton: {
        color: '#eb445a',
    }
});

interface Props extends WithStyles<typeof styles> {
    className?: string;
    currentAccount?: Account | null;
    pickedFloorplan: FloorplanFull | null;
    formFields: floorplanForm[];
    hiddenAttributes: string[];
    isProcessing: boolean;
    canDelete: boolean;
    onEditSubmit?: (updatedFloorplan: FloorplanFull) => void;
    onDeleteSubmit?: (pickedFloorplan: FloorplanFull) => void;
}

interface States {
    floorplanInForm: FloorplanFull;
    formList: React.ReactChild[];
    canSubmit: boolean;
    isManager: boolean;
}

class FloorplanForm extends React.Component<Props, States> {

    state: States = {
        floorplanInForm: {} as FloorplanFull,
        formList: [],
        canSubmit: false,
        isManager: false,
    }

    componentDidMount() {
        const { pickedFloorplan } = this.props;
        if (!pickedFloorplan) {
            return;
        }
        
        this.setState({floorplanInForm: {...pickedFloorplan}}, () => {
            this.formatFloorplanForm();
        });
    }

    handleStringChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, key: string) => {
        const { floorplanInForm } = this.state;

        if (!floorplanInForm) {
            return;
        }

        _.set(floorplanInForm, key, e.target.value);
        this.setState({floorplanInForm, canSubmit: true});
    }

    formatFieldName = (teamKey: string) => {
        const lowerWords = teamKey.split("_").join(" ");
        return lowerWords.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
    }

    formatFloorplanForm = () => {
        const { formFields, classes, hiddenAttributes } = this.props;
        const { floorplanInForm } = this.state;
        const formattedFormFields: React.ReactChild[] = [];
        for (const key in floorplanInForm) {
            if (hiddenAttributes.includes(key)) {
                continue;
            }
            const keyValue = floorplanInForm[key as keyof FloorplanFull];
            const keyName = this.formatFieldName(key);
            // CHECK IF THE FIELD IS EDITABLE OR NOT
            const formType = formFields.find(e => e.field === key);
            if (formType) {
                // IS EDITABLE
                // CHECK TYPE
                switch (formType.input) {
                    case 'string':
                        formattedFormFields.push(
                            <TextField
                                className={classes.textField}
                                id={`${floorplanInForm.floorplan_id}_${key}`}
                                label={keyName}
                                defaultValue={keyValue}
                                onChange={e => this.handleStringChange(e, key)}
                                multiline={true}
                                placeholder={keyName}
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        );
                        if (keyName === 'Prefix') {
                            formattedFormFields.push(
                                <InputLabel className={classes.errorPrefixAlert} error id="prefixErrorContainer"></InputLabel>
                            );
                        }
                        break;
                    default:
                        break;
                }
            } else {
                // NOT EDITABLE
                if (typeof keyValue === 'string' || typeof keyValue === 'number') {
                    if (keyValue !== '') {
                        formattedFormFields.push(
                            <TextField
                                className={classes.textField}
                                id={`${floorplanInForm.floorplan_id}_${key}`}
                                label={keyName}
                                defaultValue={keyValue !== '' ? keyValue : '-'}
                                disabled={true}
                                multiline={true}
                            />
                        );
                    }
                }
            }
        }
        this.setState({formList: formattedFormFields});
    }

    onSubmitChanges = () => {
        const { onEditSubmit } = this.props;
        const { floorplanInForm } = this.state;

        if (!onEditSubmit) {
            return;
        }

        onEditSubmit(floorplanInForm);
    }

    checkValidPrefix = () => {
        const { floorplanInForm } = this.state;

        let flag = false;

        // DUE TO THE CURRENT REACT VERSION THIS IS THE BEST WAY TO DISPLAY AN ERROR DIV BASED ON THE DYNAMIC FORM GENERACION
        // ALSO MATERIAL UI HAS AN ERROR STATE THAT ITS NOT REACTIVE IN THIS CURRENT VERSION
        const alertComponent = document.getElementById('prefixErrorContainer');
        if (floorplanInForm.prefix !== '' && !floorplanInForm.prefix.endsWith(' - ')) {
            flag = true;
        }
        
        if (alertComponent) {
            alertComponent.innerText = flag ? "Incorrect prefix, make sure it ends with ' - '" : '';
        }

        return flag;
    }

    checkValidForm = () => {
        const { floorplanInForm } = this.state;

        let flag = false;

        if (floorplanInForm.name === '' || this.checkValidPrefix()) {
            flag = true;
        }

        return flag;
    }

    onDeleteClicked = () => {
        const { canDelete, onDeleteSubmit } = this.props;

        if (!canDelete || !onDeleteSubmit) {
            return;
        }

        const { floorplanInForm } = this.state;

        onDeleteSubmit(floorplanInForm);
    }

    public render() {
        const {
            className,
            classes,
            pickedFloorplan,
            isProcessing,
            canDelete
        } = this.props;
        const {
            formList,
            canSubmit,
        } = this.state;

        return (
            <div className={`${classes.root} ${className}`} data-testid="floorplan-form">
                <div className={`${classes.container} ${classes.simpleList}`}>
                    <CCSpinner
                        loading={isProcessing}
                        size={70}
                        className={classes.processingSpinner}
                    />
                    <Typography
                        variant='h6'
                        className={`${classes.heading}`}
                    >
                        {
                            canDelete &&
                                <IconButton 
                                    className={classes.deleteButton}
                                    onClick={this.onDeleteClicked}
                                    data-testid="delete-floorplan-button"
                                >
                                    <DeleteForeverRounded fontSize="large"/>
                                </IconButton>
                        }
                        { `Editing: ${pickedFloorplan!.name}` }
                    </Typography>
                    <Paper className={`${classes.container} ${classes.detailBorder}`}>
                        { formList }
                    </Paper>
                    { canSubmit && 
                        <Button
                            className={classes.saveButton}
                            variant="contained"
                            color="primary"
                            onClick={this.onSubmitChanges}
                            disabled={this.checkValidForm()}
                        >
                            Save Changes
                        </Button>
                    }
                </div>
            </div>
        );
    }
}

const MUIComponent = withStyles(styles)(FloorplanForm);
export {MUIComponent as FloorplanForm};
