import { AccountFull } from '../models/account';
import { ModuleGroup, UserModuleGroup } from '../models/module-group';
import { HttpConnectorAdapter } from './../adapters/http-connector-adapter';

export default class ModuleGroupProvider {
    private endpointAdmin: string;

    constructor() {
        this.endpointAdmin = `${process.env.REACT_APP_ADMIN_TOOL_API}module_group`;
    }

    init = () => {
        return HttpConnectorAdapter;
    };

    fetchModuleGroups = () => {
        return this.init()
            .getWithCredentials(`${this.endpointAdmin}/`)
            .then(response => {
                const statusCode = response.status;
                const modules = response.data as ModuleGroup[];

                return { status: statusCode, modules: modules };
            });
    };

    fetchModuleGroup = (moduleId: string) => {
        return this.init()
        .getWithCredentials(`${this.endpointAdmin}/${moduleId}`)
        .then(response => {
            const statusCode = response.status;
            const module = response.data as ModuleGroup;

            return { status: statusCode, module: module };
        });
    };

    fetchIsolatedAccounts = () => {
        return this.init()
        .getWithCredentials(`${this.endpointAdmin}/isolated_accounts`)
        .then(response => {
            const statusCode = response.status;
            const { docs, total } = response.data;
            return { status: statusCode, accounts: docs as AccountFull[], total_account: total };
        });
    };

    createModuleGroup = (moduleGroup: ModuleGroup) => {
        const newModuleGroup = {...moduleGroup} as any;
        delete newModuleGroup['timestamp'];
        const headers = { 'Content-Type': 'application/json' };
        return this.init().postWithCredentials(`${this.endpointAdmin}/`, JSON.stringify(newModuleGroup), headers).then(response => {
            const moduleGroup = response.data as ModuleGroup;
            const statusCode = response.status;

            return { status: statusCode, moduleGroup };
        });
    };

    updateUserModuleGroup = (moduleGroup: UserModuleGroup) => {
        const newModuleGroup = {...moduleGroup} as any;
        delete newModuleGroup['_rev'];
        delete newModuleGroup['_sync'];
        delete newModuleGroup['flag_to_clone'];
        delete newModuleGroup['has_updates'];
        const headers = { 'Content-Type': 'application/json' };
        return this.init().putWithCredentials(`${this.endpointAdmin}/${moduleGroup.module_group_id}`, JSON.stringify(newModuleGroup), headers).then(response => {
            const moduleGroup = response.data as UserModuleGroup;
            const statusCode = response.status;

            return { status: statusCode, moduleGroup };
        });
    };


    createUserModuleGroup = (moduleGroup: UserModuleGroup) => {
        const newModuleGroup = {...moduleGroup} as any;
        delete newModuleGroup['_id'];
        delete newModuleGroup['timestamp'];
        delete newModuleGroup['module_group_id'];
        delete newModuleGroup['_rev'];
        delete newModuleGroup['_sync'];
        delete newModuleGroup['flag_to_clone'];
        delete newModuleGroup['has_updates'];
        const headers = { 'Content-Type': 'application/json' };
        return this.init().postWithCredentials(`${this.endpointAdmin}/user_module_group`, JSON.stringify(newModuleGroup), headers).then(response => {
            const moduleGroup = response.data as UserModuleGroup;
            const statusCode = response.status;

            return { status: statusCode, moduleGroup };
        });
    };
}
