import {
    IconButton,
    Paper,
    TextField,
    Typography
} from '@material-ui/core';
import {
    createStyles,
    WithStyles,
    withStyles
} from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import * as React from 'react';
import { AccountFull } from '../../models/account';
import { PageBoundary } from '../../shared/components/simple-grid-pagination';
import { SimpleListItem } from '../../shared/components/simple-list';
import { SimpleListPagination } from '../../shared/components/simple-list-pagination';
import { ClearOutlined, SearchOutlined } from '@material-ui/icons';

const styles = (theme: Theme) => createStyles({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    container: {
        display: 'flex',
        flexDirection: 'column'
    },
    heading: {
        fontWeight: 700,
        marginBottom: '0.5em',
        marginTop: '1em'
    },
    ellipsis: {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden'
    },
    simpleList: {
        overflow: 'auto',
        height: '100%',
        minWidth: '20em'
    },
    simpleListAccounts: {
        marginRight: '2em',
        width:'22em'
    },
    simpleListTeams: {
        width: '36em'
    },
    teamSpinnerContainer: {
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '3em',
        marginBottom: '4em',
        borderRadius: '0.4em'
    },
    noAccountContainer: {
        fontWeight: 'bold',
         '& > *': {
            color: 'rgba(0,0,0,0.2)'
        }
    },
    stretchContainer: {
        flex: '1',
        transition: 'width 1s'
    },
    treeContainer: {
        background: '#fff',
        padding: '1em',
        height: '100%'
    },
    treePaper: {
        height: '80%',
        maxHeight: '60em',
        overflowY: 'scroll'
    },
    mainSpinnerLabel: {
        paddingBottom: '30px'
    },
    progressContainer: {
        flexGrow: 1
    },
    titleContainer: {
        marginTop: '1.5em',
        marginBottom: '0.5em'
    },
    buttonContainer: {
        marginTop: '0.5em',
        marginBottom: '1.5em'
    },
    buttonExport: {
        float: 'right'
    },
    buttonTitle: {
        display: 'inline'
    },
    middleButton: {
        marginLeft: '1em'
    },
    searchInputContainer: {
        marginBottom: '1em'
    },
    searchInput: {
        width: '100%',
        paddingLeft: '1em'
    },
});

interface Props extends WithStyles<typeof styles> {
    className?: string;
    accounts?: AccountFull[] | null;
    accountsIsLoading: boolean;
    accountsTotalItems?: number;
    accountsItemsOffset: number;
    rowsPerPage: number;
    onAccountClick?: (account: AccountFull) => void;
    onAccountDataPageOverBoundary?: (boundary: PageBoundary, nextPage: number) => Promise<void>;
    onAccountSearch?: (searchName: string) => void;
};

interface States {
    accountNameSearch: string;
}

class AccountPicker extends React.Component<Props, States> {
    public static defaultProps = {
        currentAccountName: '',
        accountsItemsOffset: 0
    };

    state: States = {
        accountNameSearch: ''
    }

    onAccountClicked = (listItem: SimpleListItem) => {
        const {accounts, onAccountClick} = this.props;
        if(!accounts) {
            return;
        }

        const currentAccount = accounts.find((account:AccountFull) => {
            return account.account_id === listItem.id;
        });
        if (!currentAccount) {
            return;
        }

        if (onAccountClick) {
            onAccountClick(currentAccount);
        }
    };

    onAccountNameSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        const searchValue = event.target.value;

        this.setState({
            accountNameSearch: searchValue
        });
    };

    onAccountNameKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        const { accountNameSearch } = this.state;
        const { onAccountSearch } = this.props;

        if (!onAccountSearch) {
            return;
        }

        if (event.key === 'Delete' || event.key === 'Backspace') {
            if (accountNameSearch === '') {
                onAccountSearch('');
                return;
            }
        }

        if (event.key === 'Enter') {
            onAccountSearch(accountNameSearch);
            return;
        }
    };

    onButtonSearch = () => {
        const { accountNameSearch } = this.state;
        const { onAccountSearch } = this.props;

        if (!onAccountSearch) {
            return;
        }

        onAccountSearch(accountNameSearch);
    };

    onCancelSearch = () => {
        const { onAccountSearch } = this.props;

        if (!onAccountSearch) {
            return;
        }

        this.setState({ accountNameSearch: '' }, () => {
            onAccountSearch('');
        });
    };

    public render() {
        const {
            className,
            classes,
            accounts,
            accountsIsLoading,
            accountsTotalItems,
            accountsItemsOffset,
            rowsPerPage,
            onAccountDataPageOverBoundary,
            onAccountSearch
        } = this.props;

        const {
            accountNameSearch
        } = this.state;

        const accountListItems = accounts ? accounts.map((account: AccountFull) => {
            const accountId = account.account_id;
            return new SimpleListItem(accountId, account.name, accountId);
        }) : [];

        return (
            <div className={`${classes.root} ${className}`} data-testid="accountPicker">
                <div className={`${classes.container} ${classes.simpleListAccounts}`}>
                    <Typography
                        variant='h6'
                        className={`${classes.heading} ${classes.ellipsis}`}
                    >
                        Customer Organizations
                    </Typography>
                    {
                        onAccountSearch &&
                            <Paper className={classes.searchInputContainer}>
                                <TextField
                                    data-testid="account-searchbar"
                                    className={classes.searchInput}
                                    type="text"
                                    placeholder="Search by name"
                                    variant="standard"
                                    multiline={false}
                                    value={accountNameSearch}
                                    InputProps={{
                                        disableUnderline: true,
                                        endAdornment: (
                                            <React.Fragment>
                                                {
                                                    accountNameSearch !== '' &&
                                                        <IconButton
                                                            aria-label="cancel"
                                                            data-testid="cancel-search"
                                                            onClick={this.onCancelSearch}
                                                        >
                                                            <ClearOutlined />
                                                        </IconButton>
                                                }
                                                <IconButton
                                                    aria-label="search"
                                                    data-testid="commit-search"
                                                    onClick={this.onButtonSearch}
                                                >
                                                    <SearchOutlined />
                                                </IconButton>
                                            </React.Fragment>
                                        )
                                    }}
                                    onChange={this.onAccountNameSearch}
                                    onKeyDown={this.onAccountNameKeyDown}
                                />
                            </Paper>
                    }
                    <SimpleListPagination
                        className={classes.simpleList}
                        keepItemSelected={true}
                        items={accountListItems}
                        isLoading={accountsIsLoading}
                        onListItemClick={this.onAccountClicked}
                        rowsPerPage={rowsPerPage}
                        totalItems={accountsTotalItems}
                        offset={accountsItemsOffset}
                        onPageOverBoundary={onAccountDataPageOverBoundary}
                        noItemsLabel='No accounts found'
                    />
                </div>
            </div>
        );
    }
}

const MUIComponent = withStyles(styles)(AccountPicker);
export {MUIComponent as AccountPicker}