import {
    AnalyticsTab,
    EntityAnalyticsTab
} from "../shared/domain";
import { HttpConnectorAdapter } from './../adapters/http-connector-adapter';


export default class AnalyticsProvider {
    private endpointAdmin: string;

    constructor() {
        this.endpointAdmin = `${process.env.REACT_APP_ADMIN_TOOL_API}analytics/tabs`;
    };

    init = () => {
        return HttpConnectorAdapter;
    };

    getTabs = (accountId: string, limit: number = 10, offset:number= 0) => {
        return this.init().getWithCredentials(`${this.endpointAdmin}/accounts/${accountId}?limit=${limit}&offset=${offset}`).then(response => {
            const tabs = response.data?.tabs;
            if (!tabs) {
                return [] as AnalyticsTab[];
            }

            const results = tabs.reduce((tabsArray: AnalyticsTab[], tabModel:EntityAnalyticsTab) => {
                const tab = AnalyticsTab.create(tabModel);
                if (tab) {
                    tabsArray.push(tab);
                }
                return tabsArray;
            }, [] as AnalyticsTab[]);

            return results;
        });
    }

    getTab = (tabId: string) => {
        return this.init().getWithCredentials(`${this.endpointAdmin}/${tabId}`).then(response => {
            const model = response.data as EntityAnalyticsTab;
                const tab = AnalyticsTab.create(model)
                if (!tab) {
                    throw {
                        error: "Error loading user's tab",
                        errorMessage: `empty response for tab id: ${tabId}`
                    };
                }
                return tab;
        });
    }

    createNewTab = (
        accountId: string,
        tabName: string,
        refreshRate: number,
        userName: string,
        url:string,
        userId: string
    ) => {
        const newTab = new FormData();
        newTab.set('account_id', accountId);
        newTab.set('name', tabName);
        newTab.set('refresh_rate', `${refreshRate}`);
        newTab.set('url', url);
        newTab.set('user_name', userName);
        newTab.set('user_id', userId);
        const headers = { 'Content-Type': 'multipart/form-data' };

        return this.init().postWithCredentials(`${this.endpointAdmin}`, newTab, headers).then(response => {
            const { id } = response.data;
            if (!id || id === '') {
                throw {
                    error: 'Error saving tab',
                    errorMessage: 'Tab id is not defined.'
                };
            }

            return id;
        });
    }

    editTab = (
        tabId: string,
        accountId: string,
        tabName: string,
        refreshRate: number,
        userName: string,
        url:string,
        userId: string
    ) => {
        const newTab = new FormData();
        newTab.set('id', tabId);
        newTab.set('account_id', accountId);
        newTab.set('name', tabName);
        newTab.set('refresh_rate', `${refreshRate}`);
        newTab.set('url', url);
        newTab.set('user_name', userName);
        newTab.set('user_id', userId);
        const headers = { 'Content-Type': 'multipart/form-data' };

        return this.init().putWithCredentials(`${this.endpointAdmin}`, newTab, headers).then(response => {
            const { results } = response.data;

            if (!results) {
                throw {
                    error: 'Error editing tab',
                    errorMessage: 'Server returned empty response.'
                };
            }

            return results;
        });
    }

    deleteTab = (tabId: string) => {
        return this.init().deleteWithCredentials(`${this.endpointAdmin}?id=${tabId}`).then(response => {
            const { id } = response.data;
            if (!id) {
                throw {
                    error: 'Error deleting tab',
                    errorMessage: 'Tab id is not defined.'
                };
            }

            return id;
        });
    }

}