export interface FloorPlanModel {
    floorplan_id: string;
    name: string;
    path: string;
}
export class FloorPlan {
    static create(model: FloorPlanModel): FloorPlan {
        const { floorplan_id, name } = model;
        return new FloorPlan(floorplan_id, name, "");
    }
    constructor(public readonly id: string, public readonly name: string, public readonly path: string) {}
}

export interface FloorplanFull {
    _id: string;
    _rev?: string;
    _sync?: {
        [key: string]: unknown;
    };
    channels?: string[];
    floorplan_id: string;
    floorplans_data: {};
    folder_id: string;
    jobs?: {
        [key: string]: boolean;
    };
    last_modified: string;
    name: string;
    prefix: string;
    s3key_floorplan: string;
    s3key_markers: string;
    s3key_template: string;
    structure_id: string;
    timestamp: string;
    type: string;
}
