import { Typography } from '@material-ui/core';
import {
    createStyles,
    Theme,
    WithStyles,
    withStyles
} from '@material-ui/core/styles';
import { Error } from '@material-ui/icons';
import _ from 'lodash';
import {
    inject,
    observer
} from 'mobx-react';
import React from 'react';
import { AccountFolderExport, RunningTasksList } from '../../components';
import AccountProvider from '../../providers/account.provider';
import FolderProvider from '../../providers/folder.provider';
import JobProvider, { JobRequest, JobTypes, RunningTasks, STATIC_PROGRESS_LIMIT, StatusTypes } from '../../providers/job.provider';
import { CCSpinner } from '../../shared/components/cc-spinner';
import { RenderTree } from '../../shared/components/node-tree';
import {
    DialogResult,
    SimpleDialog
} from '../../shared/components/simple-dialog';
import { PageBoundary } from '../../shared/components/simple-grid-pagination';
import { SimpleModal } from '../../shared/components/simple-modal';
import {
    Account,
    Folder,
} from '../../shared/domain';
import { MainTabs } from '../../stores/admin.store';
import { RootStore } from '../../stores/root.store';

const ROWS_PER_PAGE = 10;
const TASKS_ROWS_PER_PAGE = 10;
const ACCOUNTS_PAGING_LIMIT = 20;
const TASKS_PAGING_LIMIT = 100;

export enum PickedType {
    None = 0,
    Folder,
    FloorPlan
}

export interface JobResultDataset {
    status: string;
    result: string;
}

const styles = (theme: Theme) => createStyles({
    container: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row'
    },
    analyticsTabList: {
        height: '18.2em'
    },
    errorPopup: {
        width: '98%',
        borderRadius: 5
    },
    errorPopupHeader: {
        height: '0.6em',
        backgroundColor: theme.palette.error.main,
        borderRadius: '3px 3px 0 0'
    },
    errorPopupContentContainer :{
        display: 'flex',
        flexDirection: 'row',
        padding: theme.spacing(1),
    },
    errorPopupIcon: {
        fontSize: 40,
        marginRight: '1em'
    },
    mainSpinnerLabel: {
        paddingBottom: '30px'
    },
    progressContainer: {
        flexGrow: 1
    },
    animContainer: {
        transition: 'width 1s'
    },
    fullContainerSize: {
        width: '100%'
    },
    semiContainerSize: {
        width: '70%'
    },
    tasksContainerSize: {
        flex: 1,
        marginLeft: '2em'
    }
});

interface Props extends WithStyles<typeof styles> {
    rootStore: RootStore;
    onAuthError?: () => void;
}

interface States {
    accounts: Account[];
    currentAccount: Account | null;
    currentAccountOffset: number;
    networkError: boolean;
    dataPageIsLoading: boolean;
    isAccountsLoading: boolean;
    totalAccounts: number;
    modalOpen: boolean;
    modalText: string;
    folderIsLoading: boolean;
    mainSpinnerText: string;
    folders: Folder[];
    isFoldersLoading: boolean;
    currentNodeTree: RenderTree;
    treeIsLoading: boolean;
    pickedType: PickedType;
    pickedId: string;
    pickedName: string;
    runningTasks: RunningTasks[];
    currentTasksOffset: number;
    timeElapser: number | null;
    isErrorModalOpened: boolean;
    errorModalHeader: string;
    errorModalText: string;
    processedFolders: string[];
    searchAccountName: string;
    storedAccounts: Account[];
    storedAccountOffset: number;
    storedAccountTotalCount: number;
}

@inject('rootStore')
@observer
class MarkerExport extends React.Component<Props, States> {
    public static defaultProps: Partial<Props> = {
    };

    state: States = {
        accounts: [],
        currentAccount: null,
        currentAccountOffset: 0,
        networkError: false,
        dataPageIsLoading: true,
        isAccountsLoading: false,
        totalAccounts: 0,
        modalOpen: false,
        modalText: '',
        folderIsLoading: false,
        mainSpinnerText: 'Please wait. Loading data.',
        folders: [],
        isFoldersLoading: false,
        currentNodeTree: {id: '', name:'', children:[], path: ''},
        treeIsLoading: false,
        pickedType: PickedType.None,
        pickedId: '',
        pickedName: '',
        runningTasks: [],
        currentTasksOffset: 0,
        timeElapser: null,
        isErrorModalOpened: false,
        errorModalHeader: '',
        errorModalText: '',
        processedFolders: [],
        searchAccountName: '',
        storedAccounts: [],
        storedAccountOffset: 0,
        storedAccountTotalCount: 0
    }

    folderProvider = new FolderProvider();
    accountProvider = new AccountProvider();
    jobProvider = new JobProvider();

    componentDidMount() {
        this.fetchAccounts(this.state.currentAccountOffset);
        this.fetchRunningTasks();
        this.startTimeInterval();
    };
  
    componentWillUnmount() {
        const {
            rootStore: { adminStore }
        } = this.props;   
        const { runningTasks } = this.state;
        this.clearIntervals(runningTasks);
        adminStore.setCurrentExportTasks(runningTasks, MainTabs.Marker_Export);
        this.clearTimeElapserInterval();
    }

    clearIntervals(tasks: RunningTasks[]) {
        tasks.forEach((element) => {
            element.set_to_stop = true;
        });
    }

    clearTimeElapserInterval() {
        const { timeElapser } = this.state;
        if( timeElapser ) {
            window.clearInterval(timeElapser);
        }
    }

    startTimeInterval() {
        const { timeElapser } = this.state;
        if (timeElapser) {
            return;
        }
        const timeElapserInterval = window.setInterval(() => {
            const { runningTasks } = this.state;
            const time = Date.now();
            const processing = runningTasks.filter(e => e.status === 102);
            processing.forEach(element => {
                element.elapsedTime = Math.round((time - element.startedAt) / 1000);
            });
            this.setState({runningTasks});
        }, 1000);
        this.setState({timeElapser: timeElapserInterval});
    }

    fetchAccounts = (offset:number, limit: number = ACCOUNTS_PAGING_LIMIT) => {
        return this.accountProvider.getAllAccounts(offset, limit)
          .then((results) => {
            const {accounts, totalCount} = results;
            if (!accounts) {
                if (offset === 0) {
                    this.setState({networkError: true});
                }
                return;
            }

            this.setState({
                dataPageIsLoading: false,
                isAccountsLoading: false,
                totalAccounts: totalCount,
                accounts,
                currentAccountOffset: offset
            });
        }).catch((error) => {
            const { status } = error.response;
            if (status === 401) {
                const { onAuthError } = this.props;
                if (!onAuthError) {
                    return;
                }
                onAuthError();
                return;
            }
            this.setState({networkError: true});
        });
    }

    searchAccounts = (searchName: string, offset?: number) => {
        // Nothing to search load the normal account list
        if (searchName === '') {
            const {
                storedAccounts,
                storedAccountOffset,
                storedAccountTotalCount
            } = this.state;

            // Empty search do nothing
            if (!storedAccounts.length) {
                return;
            }

            // Going back from actual seach
            this.setState({
                accounts: storedAccounts,
                currentAccountOffset: storedAccountOffset,
                totalAccounts: storedAccountTotalCount,
                searchAccountName: '',
                storedAccounts: [],
                storedAccountOffset: 0,
                storedAccountTotalCount: 0
            });
            return;
        }

        // We have a name search
        const {
            currentAccountOffset,
            accounts,
            totalAccounts,
            storedAccounts
        } = this.state;
        this.setState({
            isAccountsLoading: true,
            searchAccountName: searchName
        }, () => {
            const searchOffset = offset ? offset : 0;
            return this.accountProvider
                .searchAccountByNameShortSchema(searchName, searchOffset, ACCOUNTS_PAGING_LIMIT)
                .then(results => {
                    const { accounts: foundAccounts, totalCount } = results;
                    if (!foundAccounts) {
                        if (currentAccountOffset === 0) {
                            this.setState({ networkError: true });
                        }
                        return;
                    }
    
                    // If we haven't stored the original list do it
                    // This prevents multiple searchs overriding each others
                    if (!storedAccounts.length) {
                        this.setState({
                            storedAccounts: accounts,
                            storedAccountTotalCount: totalAccounts,
                            storedAccountOffset: currentAccountOffset
                        })
                    }

                    this.setState({
                        isAccountsLoading: false,
                        totalAccounts: totalCount,
                        accounts: foundAccounts,
                        currentAccountOffset: searchOffset
                    });
                })
                .catch(error => {
                    const { status } = error.response;
                    if (status === 401) {
                        const { onAuthError } = this.props;
                        if (!onAuthError) {
                            return;
                        }
                        onAuthError();
                        return;
                    }
                    this.setState({ networkError: true });
                });
        });
    }

    fetchRunningTasks = () => {
        const {
            rootStore: { adminStore }
        } = this.props;
        const tasks = adminStore.currentExportTasks.find(e => e.page === MainTabs.Marker_Export);
        this.setState({
            runningTasks: tasks!.tasks
        }, () => {
            tasks!.tasks.forEach(element => {
                if(element.status === 102) {
                    // SET TO RESTART POOLING
                    element.set_to_stop = false;
                    this.startTaskWatcher(element);
                }
            });
        });
    }

    onAccountClicked = (account: Account) => {
        const { currentAccount } = this.state;
        if (currentAccount === account) {
            return;
        }

        this.setState({currentAccount: account});
        this.fetchFoldersFromAccount(account);
    };

    fetchFoldersFromAccount = (account: Account) => {
        this.setState({
            folderIsLoading: true,
            pickedType: PickedType.None,
            pickedId: '',
            processedFolders: []
        });

        return this.accountProvider.getRootFoldersByAccountId(account.accountId)
            .then((results) => {
                const folders: Folder[] = results;
                if(!folders) {
                    this.setState({networkError: true});
                    return;
                }

                // TRANSFORM THE FOLDERS INTO TREE VIEW
                const accountTree: RenderTree = {
                    id: account.accountId,
                    name: account.name,
                    children: [],
                    path: ''
                };

                const treeNodes: RenderTree[] = folders.map(element => {
                    const newItem = {
                        id: element.id,
                        name: element.name,
                        children: [],
                        path: `${element.id}__`
                    }
                    return newItem as RenderTree;
                });

                accountTree.children = treeNodes;

                this.setState({
                    folderIsLoading: false,
                    folders,
                    currentNodeTree: accountTree
                });

        }).catch((error) => {
            // tslint:disable-next-line:no-console
            console.error(error);
            const { status } = error.response;
            if (status === 401) {
                const { onAuthError } = this.props;
                if (!onAuthError) {
                    return;
                }
                onAuthError();
                return;
            }
            this.setState({networkError: true});
        });

    };

    onAccountDataPageOverBoundaryReached = (boundary: PageBoundary, nextPage: number): Promise<void> => {
        return new Promise<void>((resolve, reject) => {
            const { 
                currentAccountOffset,
                totalAccounts
            } = this.state;
            const isFirstPage = nextPage === 0;
            const isLastPage = nextPage === (Math.ceil(totalAccounts / ROWS_PER_PAGE)-1);
            const newOffset = isFirstPage ? 0
                            : isLastPage ? ACCOUNTS_PAGING_LIMIT * Math.floor(totalAccounts / ACCOUNTS_PAGING_LIMIT)
                            : (boundary === PageBoundary.Upper) ? 
                                currentAccountOffset+ACCOUNTS_PAGING_LIMIT :
                                currentAccountOffset-ACCOUNTS_PAGING_LIMIT;
            this.setState({isAccountsLoading: true});
            this.fetchAccounts(newOffset)
              .then(() => resolve())
              .catch(()=> reject());
        });
    };

    onTaskDataPageOverBoundaryReached = (boundary: PageBoundary, nextPage: number): Promise<void> => {
        return new Promise<void>((resolve, reject) => {
            const { 
                currentTasksOffset,
                runningTasks
            } = this.state;
            const totalTasks = runningTasks.length;
            const isFirstPage = nextPage === 0;
            const isLastPage = nextPage === (Math.ceil(totalTasks / TASKS_ROWS_PER_PAGE)-1);
            const newOffset = isFirstPage ? 0
                            : isLastPage ? TASKS_PAGING_LIMIT * Math.floor(totalTasks / TASKS_PAGING_LIMIT)
                            : (boundary === PageBoundary.Upper) ? 
                            currentTasksOffset+TASKS_PAGING_LIMIT :
                            currentTasksOffset-TASKS_PAGING_LIMIT;
            const nextTasks = runningTasks;
            // MANUAL OFFSET
            this.setState({
                runningTasks: nextTasks.splice(newOffset, nextTasks.length)
            });
        });
    };

    onConfirmationDialogClicked = (result: DialogResult) => {
        // Closes the dialog
        this.setState({modalOpen: false});
    };

    deepTreeSearch = (treeData: RenderTree, searchId: string) => {
        let foundTree: RenderTree | undefined;
        let searchLevel: RenderTree[] | undefined = treeData.children;
        do {
            foundTree = searchLevel?.find((e: RenderTree) => e.id === searchId);
            let tempLevel: any = [];
            searchLevel?.forEach((element: RenderTree) => {
                tempLevel = tempLevel.concat(element.children);
            });
            searchLevel = tempLevel;
        } while(!foundTree);
        return foundTree;
    }

    updateChildrenOnPath = (treeData: RenderTree, nodeId: string, children: RenderTree[]) => {
        treeData.children!.forEach(element => {
            if (_.isEqual(element.id, nodeId)) {
                element.children = children;
            } else {
                this.updateChildrenOnPath(element, nodeId, children);
            }
        });
    }

    onNodeSelected = (treeData: RenderTree , nodeId: string, nodeLabel: string, updateCallback: (nodeIds: string[]) => void) => {
        const { folders, currentAccount, processedFolders } = this.state;
        if(currentAccount!.accountId === nodeId) {
            this.setState({
                pickedType: PickedType.None,
                pickedId: '',
                pickedName: ''
            });
            return;
        }
        this.setState({treeIsLoading: true});
        // CHECK PICK TYPE
        // FOLDER
        const currentNodePos = this.deepTreeSearch(treeData, nodeId);
        if(folders.find(e => e.id === nodeId)) {
            // DID WE ALREADY CHECK FOR CHILDREN?
            if (processedFolders.find(e => e === nodeId)) {
                // FOLDER CHILDREN
                const firstChild = currentNodePos.children![0];
                if (!firstChild) {
                    // NO CHILDREN CAN EXPORT
                    this.setState({
                        pickedType: PickedType.Folder,
                        pickedId: nodeId,
                        pickedName: currentNodePos.name,
                        currentNodeTree: treeData,
                        treeIsLoading: false
                    });
                    return;
                }
                if (folders.find(e => e.id === firstChild.id)) {
                    // DO NOTHING CAN'T EXPORT THIS FOLDER
                    this.setState({
                        pickedType: PickedType.None,
                        pickedId: '',
                        pickedName: '',
                        currentNodeTree: treeData,
                        treeIsLoading: false
                    });
                    return;
                }
                // FLOORPLAN CHILDREN
                this.setState({
                    pickedType: PickedType.Folder,
                    pickedId: nodeId,
                    pickedName: currentNodePos.name,
                    currentNodeTree: treeData,
                    treeIsLoading: false
                });
                return;
            }
            // EMPTY
            // CHECK FOR FOLDER CHILDREN FIRST
            return this.accountProvider.getChildrenFolderByFolderId(currentAccount!.accountId, nodeId)
                .then((results: Folder[]) => {
                    if (results.length > 0) {
                        const nodes: RenderTree[] = results.map(element => {
                            const newItem = {
                                id: element.id,
                                name: element.name,
                                children: [],
                                path: `${currentNodePos.path}${element.id}__`
                            }
                            return newItem as RenderTree;
                        });
                        this.updateChildrenOnPath(treeData, nodeId, nodes);
                        const currentPath = currentNodePos.path.slice(0, -2);
                        updateCallback([currentAccount!.accountId, ...currentPath.split('__'), ...nodes.map(e => e.id)]);
                        processedFolders.push(nodeId);
                        this.setState({
                            pickedType: PickedType.None,
                            pickedId: '',
                            pickedName: '',
                            currentNodeTree: treeData,
                            treeIsLoading: false,
                            folders: folders.concat(results),
                            processedFolders
                        });
                        return;
                    } else {
                        return this.folderProvider.fetchFloorplans(nodeId)
                            .then((floorplans) => {
                                const nodes: RenderTree[] = floorplans.map((element: RenderTree) => {
                                    const newItem = {...element} as RenderTree;
                                    newItem.children = [];
                                    newItem.path = `${currentNodePos.path}${element.id}__`;
                                    return newItem;
                                });
                                this.updateChildrenOnPath(treeData, nodeId, nodes);
                                const currentPath = currentNodePos.path.slice(0, -2);
                                updateCallback([currentAccount!.accountId, ...currentPath.split('__'), ...nodes.map(e => e.id)]);
                                processedFolders.push(nodeId);
                                this.setState({
                                    pickedType: PickedType.Folder,
                                    pickedId: nodeId,
                                    pickedName: currentNodePos.name,
                                    currentNodeTree: treeData,
                                    treeIsLoading: false,
                                    processedFolders
                                });
                        }).catch((error) => {
                            // tslint:disable-next-line:no-console
                            console.error(error);
                            const { status } = error.response;
                            if (status === 401) {
                                const { onAuthError } = this.props;
                                if (!onAuthError) {
                                    return;
                                }
                                onAuthError();
                                return;
                            }
                            this.setState({networkError: true});
                        });
                    }
            }).catch((error) => {
                // tslint:disable-next-line:no-console
                console.error(error);
                const { status } = error.response;
                if (status === 401) {
                    const { onAuthError } = this.props;
                    if (!onAuthError) {
                        return;
                    }
                    onAuthError();
                    return;
                }
                this.setState({networkError: true});
            });
        }
        this.setState({
            pickedType: PickedType.FloorPlan,
            pickedId: nodeId,
            pickedName: currentNodePos.name,
            treeIsLoading: false
        });
        return;
    }

    onNodeToggled = (nodeIds: string[]) => {
        return;
    }

    onExportTask = (pickedTypeJob: PickedType, jobType: number) => {
        const { pickedType, pickedId, pickedName } = this.state;
        if(
            pickedType !== pickedTypeJob ||
            !pickedId ||
            !pickedName ||
            pickedId === '' ||
            pickedName === ''
        ) {
            return;
        }
        const { currentAccount, runningTasks } = this.state;
        const {
            rootStore: { adminStore }
        } = this.props;
        this.setState({treeIsLoading: true});
        const userIdentifiers = adminStore.userIdentifiers!;
        const jobPayload: JobRequest = {
            user_created_id: userIdentifiers.user_id,
            account_id: currentAccount ? currentAccount.accountId : userIdentifiers.account_id,
            job_type: `${jobType}`,
            additional_params: {}
        };

        if (jobType === JobTypes.FolderExport) {
            jobPayload.additional_params!.folder_id = pickedId;
        } else if (jobType === JobTypes.FloorplanExport) {
            jobPayload!.additional_params!.floorplan_id = pickedId;
        }

        return this.jobProvider.scheduleJob(jobPayload)
            .then((result) => {
                const newTask: RunningTasks = {
                    account: currentAccount,
                    export_name: `Exporting: ${pickedName} ${pickedType === PickedType.Folder ? 'folder' : 'floorplan'}`,
                    export_id: pickedId,
                    type: pickedType,
                    task_id: result.job_id,
                    status: 102,
                    startedAt: Date.now(),
                    elapsedTime: 0,
                    progress: 0,
                    timer: 1500,
                    static_progress_count: 0,
                        set_to_stop: false
                };

                runningTasks.push(newTask);
                adminStore.setCurrentExportTasks(runningTasks, MainTabs.Marker_Export);
                this.setState({
                    runningTasks,
                    pickedId: '',
                    pickedName: '',
                    pickedType: PickedType.None,
                    treeIsLoading: false
                });
                this.startTaskWatcher(newTask);
                this.startTimeInterval();
            }).catch((error) => {
                // tslint:disable-next-line:no-console
                console.error(error);
                const { status } = error.response;
                if (status === 401) {
                    const { onAuthError } = this.props;
                    if (!onAuthError) {
                        return;
                    }
                    onAuthError();
                    return;
                }
                this.setState({networkError: true});
            });
    }

    startTaskWatcher = (task: RunningTasks) => {
        const { runningTasks } = this.state;

        if (!task.export_name || !task.task_id || !task.export_id) {
            return;
        }

        const statusCallback = (taskId: string) => {
            const taskIndex = runningTasks.findIndex(e => e.task_id === taskId);
            const taskItem = runningTasks.find(e => e.task_id === taskId);

            if (!taskItem || taskItem.status !== 102) {
                return;
            }

            if (taskItem.set_to_stop) {
                return;
            }   
            
            this.jobProvider.checkJobStatus(taskItem.task_id).then(data => {
                if (data.job_status === StatusTypes.Error) {
                    taskItem.status = 500;
                    runningTasks[taskIndex] = taskItem;
                    this.setState({runningTasks});
                }
                if (data.job_status === StatusTypes.Complete) {
                    taskItem.status = 200;
                    taskItem.progress = 100.0;
                    this.jobProvider.checkJobResult(taskItem.task_id).then(res => {
                        const results = res.result as JobResultDataset;
                        taskItem.result_data = results.result;
                        runningTasks[taskIndex] = taskItem;
                        this.setState({runningTasks});
                    }).catch((error) => {
                        const { status } = error.response;
                        // CHECK IF WE TIMEOUT OR NOT
                        if (status === 408 || status === 504) {
                            // WE HAVE TIMEOUT INCREASE TIMER AND KEEP TRYING
                            taskItem.timer += 1000;
                            setTimeout(taskItem.internalCallback!, taskItem.timer, taskId);
                            return;
                        }
                        taskItem.status = 500;
                        runningTasks[taskIndex] = taskItem;
                        this.setState({runningTasks});
                    });
                } else {
                    // KEEP TRACK OF THE PROGRESS STATUS IF ITS STILL THE SAME
                    if (taskItem.progress === data.estimate_percent_complete) {
                        taskItem.static_progress_count += 1;
                    }
                    taskItem.progress = data.estimate_percent_complete;
                    // IF WE REACH A POINT WHERE THE STATUS IS THE SAME THE JOB IS SLOW
                    // MAKE FEWER REQUESTS TO NOT OVERWHELM THE STATUS ENDPOINT
                    if (taskItem.static_progress_count === STATIC_PROGRESS_LIMIT) {
                        taskItem.timer += 1000;
                        taskItem.static_progress_count = 0;
                    }
                    setTimeout(taskItem.internalCallback!, taskItem.timer, taskId);
                }
            }).catch((error) => {
                const { status } = error.response;
                // CHECK IF WE TIMEOUT OR NOT
                if (status === 408 || status === 504) {
                    // WE HAVE TIMEOUT INCREASE TIMER AND KEEP TRYING
                    taskItem.timer += 1000;
                    setTimeout(taskItem.internalCallback!, taskItem.timer, taskId);
                    return;
                }
                taskItem.status = 500;
                runningTasks[taskIndex] = taskItem;
                this.setState({runningTasks});
            });
        }

        // PREVENT GENERATING TIMERS WITHOUT PROPERTIES BEING INITIALIZED
        const taskIndex = runningTasks.findIndex(e => e.task_id === task.task_id);
        runningTasks[taskIndex].internalCallback = statusCallback;
        this.setState({runningTasks}, () => {
            setTimeout(statusCallback, task.timer, task.task_id);
        });
    }

    onTaskClick = (task: RunningTasks) => {
        if (task.status === 102) {
            return;
        }
        if(task.status === 200) {
            window.open(task.result_data! as string, '_blank');
            return;
        }
        this.jobProvider.checkJobError(task.task_id)
            .then((data) => {
                this.setState({
                    isErrorModalOpened: true,
                    errorModalHeader: data.error,
                    errorModalText: data.stack_trace
                });
            }).catch((error) => {
                // tslint:disable-next-line:no-console
                console.error(error);
                this.setState({networkError: true});
            });
    }

    onTaskClear = () => {
        const { runningTasks } = this.state;
        const pendingTasks = runningTasks.filter(e => e.status === 102);
        const {
            rootStore: { adminStore }
        } = this.props;
        adminStore.setCurrentExportTasks(pendingTasks, MainTabs.Marker_Export);
        this.setState({runningTasks: pendingTasks});
    }

    onErrorModalClicked = () => {
        this.setState({isErrorModalOpened: false});   
    }

    public render() {
        const {
            classes
        } = this.props;
        const {
            accounts,
            currentAccount,
            currentAccountOffset,
            networkError,
            dataPageIsLoading,
            isAccountsLoading,
            totalAccounts,
            modalOpen,
            modalText,
            folderIsLoading,
            mainSpinnerText,
            folders,
            currentNodeTree,
            treeIsLoading,
            pickedType,
            pickedId,
            runningTasks,
            currentTasksOffset,
            isErrorModalOpened,
            errorModalHeader,
            errorModalText
        } = this.state

        const currentAccountName = currentAccount ? currentAccount.name : '';

        return (
            <div className={classes.container} data-testid="mainRender">
                <CCSpinner
                    label ={mainSpinnerText}
                    labelClassName={classes.mainSpinnerLabel}
                    className={classes.progressContainer}
                    loading={dataPageIsLoading}
                    size={200}
                >
                    <AccountFolderExport
                        className={`${classes.animContainer} ${runningTasks.length > 0 ? classes.semiContainerSize : classes.fullContainerSize}`}
                        rowsPerPage={ROWS_PER_PAGE}
                        accounts={accounts}
                        accountsIsLoading={isAccountsLoading}
                        accountsTotalItems={totalAccounts}
                        accountsItemsOffset={currentAccountOffset}
                        currentAccountName={currentAccountName}
                        folders={folders}
                        folderIsLoading={folderIsLoading}
                        onAccountClick={this.onAccountClicked}
                        onAccountDataPageOverBoundary={
                            this.onAccountDataPageOverBoundaryReached
                        }
                        currentNodeTree={currentNodeTree}
                        treeIsLoading={treeIsLoading}
                        onNodeSelected={this.onNodeSelected}
                        onNodeToggled={this.onNodeToggled}
                        onExportClick={this.onExportTask}
                        pickedType={pickedType}
                        pickedId={pickedId}
                        onAccountSearch={this.searchAccounts}
                    />
                    {
                        (runningTasks && (runningTasks.length > 0)) &&
                        <RunningTasksList
                            className={classes.tasksContainerSize}
                            tasks={runningTasks}
                            rowsPerPage={TASKS_ROWS_PER_PAGE}
                            taskItemsOffset={currentTasksOffset}
                            listName="Running Exports"
                            onTaskDataPageOverBoundary={this.onTaskDataPageOverBoundaryReached}
                            onTaskClick={this.onTaskClick}
                            onTaskClear={this.onTaskClear}
                        />
                    }
                </CCSpinner>
                <SimpleDialog
                    open={isErrorModalOpened}
                    titleText={errorModalHeader}
                    contentText={errorModalText}
                    buttonCancelLabel=""
                    onDialogResult={this.onErrorModalClicked}
                />
                {
                    networkError ?
                        <SimpleModal
                            className={classes.errorPopup}
                            open={networkError}
                            contentClasses= {classes.errorPopupContentContainer}
                            buttonOkLabel=""
                            buttonCancelLabel=""
                            header = ' '
                            headerClassName={classes.errorPopupHeader}
                        >
                            <Error color="error" className={classes.errorPopupIcon} />
                            <div>
                                <Typography variant="h5">
                                    {'Network Error'}
                                </Typography>
                                <Typography variant="subtitle1">
                                    {'Please reload the page.'}
                                </Typography>
                            </div>
                        </SimpleModal>
                    :
                        <SimpleDialog
                            open={modalOpen}
                            titleText="Please confirm"
                            contentText={modalText}
                            onDialogResult={this.onConfirmationDialogClicked}
                        />
                }
            </div>
        );
    }
}

const MUIComponent = withStyles(styles)(MarkerExport);
export {MUIComponent as MarkerExport};
