// tslint:disable: ordered-imports
import {
    createStyles,
    WithStyles,
    withStyles
} from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import {
    HourglassEmptyOutlined,
    WarningOutlined,
    CheckCircleOutline,
    CloseOutlined
} from '@material-ui/icons';
import * as React from 'react';
import { SimpleListItem } from '../../shared/components/simple-list';
import { SimpleListPagination } from '../../shared/components/simple-list-pagination';
import { PageBoundary } from '../../shared/components/simple-grid-pagination';
import {
    Typography,
    Button,
    DialogTitle
} from '@material-ui/core';
import { RunningTasks, TaskGroup } from '../../providers/job.provider';
import { SimpleModal } from '../../shared/components/simple-modal';
import { RunningTasksList } from '../running-tasks-list';

const styles = (theme: Theme) => createStyles({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    heading: {
        fontWeight: 700,
        marginBottom: '0.5em',
        marginTop: '1em'
    },
    simpleList: {
        overflow: 'auto',
        maxHeight: '20em',
        width: '100%'
    },
    ellipsis: {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden'
    },
    waitingItem: {
        color: '#ffc409',
        marginRight: '0.5em',
        fontSize: '2em'
    },
    readyItem: {
        color: '#2dd36f',
        marginRight: '0.5em',
        fontSize: '2em'
    },
    buttonClear: {
        float: 'right'
    },
    resumePopup: {
        width: '41em',
        borderRadius: 5
    },
    resumePopupHeader: {
        height: '2em',
        backgroundColor: theme.palette.primary.main,
        borderRadius: '3px 3px 0 0',
        color: 'white',
        padding: '0.5em',
        fontSize: '1.5em'
    },
    popupContentContainer :{
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(1),
    },
    buttonFullscreen: {
        float: 'right',
        minWidth: '1em',
        marginRight: '1em',
    },
});

interface Props extends WithStyles<typeof styles> {
    className: string;
    tasks: RunningTasks[];
    groups: TaskGroup[];
    rowsPerPage: number;
    groupItemsOffset: number;
    listName: string;
    onGroupDataPageOverBoundary?: (boundary: PageBoundary, nextPage: number) => Promise<void>;
    onGroupClear?: () => void;
    onJobItemClick?: (task: RunningTasks) => void;
};

interface States {
    filteredTasks: RunningTasks[];
    isFilteredTasks: boolean;
};

class RunningGroupTasksList extends React.Component<Props> {
    public static defaultProps = {
    };

    state: States = {
        filteredTasks: [],
        isFilteredTasks: false
    }

    onGroupClick = (listItem: SimpleListItem) => {
        const {tasks, groups} = this.props;

        if(!tasks || !groups) {
            return;
        }


        const currentGroup = groups.find((group: TaskGroup) => {
            return group.id === listItem.id;
        });

        if (!currentGroup) {
            return;
        }

        const filteredTasks = tasks.filter(e => e.group_id === currentGroup.id);

        this.setState({filteredTasks, isFilteredTasks: true});
    }

    onDetailModalClose = () => {
        this.setState({filteredTasks: [], isFilteredTasks: false});
    }

    public render() {
        const {
            className,
            classes,
            groups,
            rowsPerPage,
            listName,
            groupItemsOffset,
            onGroupDataPageOverBoundary,
            onGroupClear,
            onJobItemClick,
        } = this.props;
        const {
            filteredTasks,
            isFilteredTasks
        } = this.state;

        const groupListItems = groups ? groups.map((group: TaskGroup) => {
            const elapsedTasks = group.done.length + group.errored.length;
            const newItem: SimpleListItem = {
                id: group.id,
                label: group.name,
                secondaryLabel: (group.total !== elapsedTasks) ? `Processing (${elapsedTasks}/${group.total}) . . . ${group.elapsedTime} Seconds`
                    : `Finished (${elapsedTasks}/${group.total}) ${group.elapsedTime} Seconds`,
                icon: (group.total !== elapsedTasks) ? 
                    <HourglassEmptyOutlined className={ classes.waitingItem } /> :
                    (group.errored.length > 0) ?
                    <WarningOutlined className={ classes.waitingItem }/> :
                    <CheckCircleOutline className={ classes.readyItem } data-testid="marker-operations-finished"/>,
                hasProgress: true,
                progress: (elapsedTasks * 100) / group.total
            }
            return newItem;
        }): [];

        return (
            <div className={`${classes.root} ${className}`} data-testid="runningTasks">
                <div className={classes.container}>
                    <Typography
                        variant='h6'
                        className={`${classes.heading} ${classes.ellipsis}`}
                    >
                        {listName}
                    </Typography>
                    <div className={`${classes.container} ${classes.simpleList}`}>
                        <SimpleListPagination
                            className={classes.simpleList}
                            keepItemSelected={false}
                            items={groupListItems}
                            isLoading={false}
                            rowsPerPage={rowsPerPage}
                            totalItems={groupListItems.length}
                            offset={groupItemsOffset}
                            onPageOverBoundary={onGroupDataPageOverBoundary}
                            onListItemClick={this.onGroupClick}
                        />
                    </div>
                    <Button
                        variant="contained"
                        color="secondary"
                        className={classes.buttonClear}
                        onClick={onGroupClear}
                    >
                        Clear finished tasks
                    </Button>
                </div>
                {
                    isFilteredTasks && filteredTasks && filteredTasks.length > 0 &&
                    <SimpleModal
                        className={classes.resumePopup}
                        open={isFilteredTasks}
                        contentClasses={classes.popupContentContainer}
                        headerClassName={classes.resumePopupHeader}
                        onClose={this.onDetailModalClose}
                        buttonOkLabel=""
                        buttonCancelLabel=""
                    >
                        <DialogTitle>
                            Running jobs
                            <Button
                                className={classes.buttonFullscreen}
                                onClick={this.onDetailModalClose}
                            >
                                <CloseOutlined />
                            </Button>
                        </DialogTitle>
                        <RunningTasksList
                            className={classes.container}
                            tasks={filteredTasks}
                            rowsPerPage={10}
                            taskItemsOffset={0}
                            onTaskClick={onJobItemClick}
                        />
                    </SimpleModal>
                }
            </div>
        )
    };
}

const MUIComponent = withStyles(styles)(RunningGroupTasksList);
export {MUIComponent as RunningGroupTasksList}