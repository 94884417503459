import * as React from 'react';
import { createStyles, TextField, Theme, withStyles } from "@material-ui/core";
import { WithStyles } from '@material-ui/styles';
import { FolderFull } from '../../models/folder';
import { Marker } from '../../models';

const styles = (theme: Theme) => createStyles({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    textField: {
        padding: '1em',
        width: '100%',
        '& label': {
            fontWeight: 'bolder',
            padding: '1em 0 0 1em',

            fontSize: '1.4em',
            color: 'rgba(0, 0, 0, 0.8) !important'
        },
        '&::before': {
            borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
            borderBottomStyle: 'solid'
        }
    },
});


interface Props extends WithStyles<typeof styles> {
    className?: string;
    locationCurrentValue: FolderFull['location'] | Marker['location'];
    locationKey: string;
    canEdit: boolean;
    onLocationChange?: (formKey: string, locationValue: FolderFull['location']) => void;
}

interface States {
    editLocationValue: FolderFull['location'];
}

class LocationInput extends React.Component<Props, States> {

    state: States = {
        editLocationValue: {
            coordinates: [0, 0],
            type: 'point'
        }
    }

    componentDidMount() {
        const { locationCurrentValue } = this.props;
        this.setState({ editLocationValue: locationCurrentValue });
    }

    handleCoordsChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, position: number) => {
        const { onLocationChange, locationKey } = this.props;

        if (!onLocationChange) {
            return;
        }

        const { editLocationValue } = this.state;

        if (!editLocationValue) {
            return;
        }

        editLocationValue.coordinates[position] = Number(event.target.value);

        this.setState({editLocationValue});
        onLocationChange(locationKey, editLocationValue);
    }
 
    public render() {
        const {
            classes,
            className,
            locationCurrentValue,
            canEdit
        } = this.props;

        return (
            <div className={`${classes.root} ${className}`} data-testid="location-form">
                <div className={`${classes.container}`}>
                    <TextField
                        className={classes.textField}
                        id="folder_location_lat"
                        label="Latitude"
                        defaultValue={locationCurrentValue?.coordinates[1]}
                        onChange={e => this.handleCoordsChange(e, 1)}
                        multiline={true}
                        placeholder={"Latitude Coordinates"}
                        variant="outlined"
                        disabled={!canEdit}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <TextField
                        className={classes.textField}
                        id="folder_location_lon"
                        label="Longitude"
                        defaultValue={locationCurrentValue?.coordinates[0]}
                        onChange={e => this.handleCoordsChange(e, 0)}
                        multiline={true}
                        placeholder={"Longitude Coordinates"}
                        variant="outlined"
                        disabled={!canEdit}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </div>
            </div>
        );
    }
}

const MUIComponent = withStyles(styles)(LocationInput);
export {MUIComponent as LocationInput}