import * as React from 'react';
import { Button, createStyles, Divider, ListItemText, TextField, Theme, withStyles } from "@material-ui/core";
import { WithStyles } from '@material-ui/styles';
import { SimpleList, SimpleListItem } from '../../shared/components/simple-list';
import { Team } from '../../shared/domain';

enum ChangeField {
    None = 0,
    emailD,
    nameD,
    emailN
}

const styles = (theme: Theme) => createStyles({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        border: '1px solid #e1e1e1',
        margin: '0.5em',
        borderRadius: '0.5em'
    },
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    list: {
        flex: '1 0',
        boxShadow: 'none',
        '& p': {
            whiteSpace: 'break-spaces'
        }
    },
    selectInput: {
        margin: '1em'
    },
    listHeader: {
        paddingLeft: '1em',
        '& span': {
            fontWeight: 'bolder'
        }
    },
    textField: {
        padding: '1em',
        width: '100%',
        '& label': {
            fontWeight: 'bolder',
            padding: '1em 0 0 1em',

            fontSize: '1.4em',
            color: 'rgba(0, 0, 0, 0.8) !important'
        },
        '&::before': {
            borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
            borderBottomStyle: 'solid'
        }
    },
    dividerForm: {
        margin: '1em'
    },
    addButton: {
        width: '95%',
        margin: 'auto',
        display: 'block',
    },
    tooltipText: {
        marginTop: '0.5em',
        fontStyle: 'italic',
        color: 'gray',
        marginBottom: '0',
        marginLeft: '1em',
    }
});


interface Props extends WithStyles<typeof styles> {
    className?: string;
    emailCurrentValue: Team['email_api_config'];
    emailKey: string;
    canEdit: boolean;
    onEmailChange?: (formKey: string, emailValue: Team['email_api_config']) => void;
}

interface States {
    defaultList: SimpleListItem[];
    negativeList: SimpleListItem[];
    isLoadingLists: boolean;
    emailDValue: string;
    nameDValue: string;
    emailNValue: string;
}


class EmailConfigForm extends React.Component<Props, States> {

    state: States = {
        defaultList: [],
        negativeList: [],
        isLoadingLists: false,
        emailDValue: '',
        nameDValue: '',
        emailNValue: ''
    }

    componentDidMount() {
        this.formatEmailLists();
    }

    formatEmailLists = () => {
        this.setState({ isLoadingLists: true });

        const { emailCurrentValue } = this.props;

        if (!emailCurrentValue) {
            return;
        }
        this.setState({isLoadingLists: true});
        const defaultList = emailCurrentValue.default ? emailCurrentValue.default.map(e => {
            return new SimpleListItem(e.email, e.email, e.name);
        }) : [];
        const negativeList = emailCurrentValue.negative_user_feedback ? emailCurrentValue.negative_user_feedback.map(e => {
            return new SimpleListItem(e.email, e.email);
        }) : [];

        this.setState({
            defaultList,
            negativeList,
            isLoadingLists: false
        });
    }

    onDeleteDefaultClicked = (listItem: SimpleListItem) => {
        const { onEmailChange, emailKey, emailCurrentValue } = this.props;

        if (!onEmailChange || !emailCurrentValue) {
            return;
        }

        const { defaultList } = this.state;
        const delIndex = defaultList.findIndex(e => e.id === listItem.id);
        defaultList.splice(delIndex, 1);

        const delIndexTeam = emailCurrentValue.default!.findIndex(e => e.email === listItem.id);
        emailCurrentValue.default!.splice(delIndexTeam, 1);

        this.setState({ defaultList });
        onEmailChange(emailKey, emailCurrentValue);
    }
    
    onDeleteNegativeClicked = (listItem: SimpleListItem) => {
        const { onEmailChange, emailKey, emailCurrentValue } = this.props;

        if (!onEmailChange || !emailCurrentValue) {
            return;
        }

        const { negativeList } = this.state;
        const delIndex = negativeList.findIndex(e => e.id === listItem.id);
        negativeList.splice(delIndex, 1);

        const delIndexTeam = emailCurrentValue.negative_user_feedback!.findIndex(e => e.email === listItem.id);
        emailCurrentValue.negative_user_feedback!.splice(delIndexTeam, 1);

        this.setState({ negativeList });
        onEmailChange(emailKey, emailCurrentValue);
    }

    onHandleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, field: ChangeField) => {
        if (!field) {
            return;
        }

        switch (field) {
            case ChangeField.emailD:
                this.setState({emailDValue: event.target.value});
                break;
            case ChangeField.nameD:
                this.setState({nameDValue: event.target.value});
            break;
            case ChangeField.emailN:
                this.setState({emailNValue: event.target.value});
            break;
            default:
                break;
        }
    }

    onAddDefaultClick = () => {
        const { onEmailChange, emailKey, emailCurrentValue } = this.props;

        if (!onEmailChange || !emailCurrentValue) {
            return;
        }

        const { defaultList, emailDValue, nameDValue } = this.state;
        const emailName = nameDValue ? nameDValue : 'CrowdManager'; 
        defaultList.push(
            new SimpleListItem(emailDValue, emailDValue, emailName)
        );
        emailCurrentValue.default!.push({
            email: emailDValue,
            name: emailName
        });

        this.setState({
            defaultList,
            emailDValue: '',
            nameDValue: ''
        });
        onEmailChange(emailKey, emailCurrentValue);
    }

    onAddNegativeClick = () => {
        const { onEmailChange, emailKey, emailCurrentValue } = this.props;

        if (!onEmailChange || !emailCurrentValue) {
            return;
        }

        const { negativeList, emailNValue } = this.state;
        negativeList.push(
            new SimpleListItem(emailNValue, emailNValue)
        );
        emailCurrentValue.negative_user_feedback!.push({
            email: emailNValue
        });

        this.setState({
            negativeList,
            emailNValue: ''
        });
        onEmailChange(emailKey, emailCurrentValue);
    }

    validateEmail = (type: number) => {
        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (type === 0) {
            const { emailDValue } = this.state;
            return re.test(emailDValue);
        }

        const { emailNValue } = this.state;
        return re.test(emailNValue);
    }

    public render() {
        const {
            className,
            classes,
            canEdit,
            emailCurrentValue
        } = this.props;
        const {
            defaultList,
            negativeList,
            isLoadingLists,
            emailDValue,
            nameDValue,
            emailNValue
        } = this.state;

        return (
            <div className={`${classes.root} ${className}`} data-testid="email-config-form">
                <div className={`${classes.container}`}>
                    {
                        emailCurrentValue ? 
                        <React.Fragment>
                            <ListItemText className={classes.listHeader} primary="Default" />
                            <SimpleList
                                className={classes.list}
                                items={defaultList}
                                noItemsLabel={"No emails found"}
                                canDeleteListItem={canEdit}
                                keepItemSelected={false}
                                onDeleteItemClick={this.onDeleteDefaultClicked}
                                isLoading={isLoadingLists}
                            />
                            {
                                canEdit &&
                                <div>
                                    <p className={`${classes.tooltipText}`}>
                                        Add a new record to default emails
                                    </p>
                                    <TextField
                                        className={classes.textField}
                                        id="new_default_email"
                                        label="Email"
                                        value={emailDValue}
                                        onChange={e => this.onHandleInputChange(e, ChangeField.emailD)}
                                        multiline={true}
                                        placeholder={"Default email"}
                                        variant="outlined"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                    <TextField
                                        className={classes.textField}
                                        id="new_default_name"
                                        label="Name"
                                        value={nameDValue}
                                        onChange={e => this.onHandleInputChange(e, ChangeField.nameD)}
                                        multiline={true}
                                        placeholder={"CrowdManager"}
                                        variant="outlined"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                    {
                                        emailDValue !== '' &&
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                className={`${classes.addButton}`}
                                                onClick={this.onAddDefaultClick}
                                                disabled={emailDValue === '' || !this.validateEmail(0)}
                                            >
                                                Add email to Default
                                            </Button>
                                    }
                                    <Divider className={classes.dividerForm} />
                                </div>
                            }
                            <ListItemText className={classes.listHeader} primary="Negative User Feedback" />
                            <SimpleList
                                className={classes.list}
                                items={negativeList}
                                noItemsLabel={"No emails found"}
                                canDeleteListItem={canEdit}
                                keepItemSelected={false}
                                onDeleteItemClick={this.onDeleteNegativeClicked}
                                isLoading={isLoadingLists}
                            />
                            {
                                canEdit &&
                                <div>
                                    <p className={`${classes.tooltipText}`}>
                                        Add a new record to the negative feedback emails
                                    </p>
                                    <TextField
                                        className={classes.textField}
                                        id="new_negative_email"
                                        label="Email"
                                        value={emailNValue}
                                        onChange={e => this.onHandleInputChange(e, ChangeField.emailN)}
                                        multiline={true}
                                        placeholder={"Negative feedback email"}
                                        variant="outlined"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                    {
                                        emailNValue !== '' &&
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                className={`${classes.addButton}`}
                                                onClick={this.onAddNegativeClick}
                                                disabled={!this.validateEmail(1)}
                                            >
                                                Add email to Negative feedback
                                            </Button>
                                    }
                                    <Divider className={classes.dividerForm} />
                                </div>
                            }
                        </React.Fragment>
                        : ''
                    }
                </div>
            </div>
        );
    }
}

const MUIComponent = withStyles(styles)(EmailConfigForm);
export {MUIComponent as EmailConfigForm}