import * as React from 'react';

import {
    Button,
    Divider,
    TextField,
    Tooltip,
    Typography
} from '@material-ui/core';
import {
    createStyles,
    WithStyles,
    withStyles
} from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import {
    TextValidator,
    ValidatorForm
} from 'react-material-ui-form-validator';
import { withPasswordShowHide } from '../../with-password-show-hide';
import { LoginWizardNewAccountData } from './login-wizard-new-account-data';

const TOOLTIP_TEXT_USER_NAME = 'Username must have at least 5 characters.';
const TOOLTIP_TEXT_PASSWORD = `Password must have at least 8 characters, including an uppercase letter,
a lowercase letter, a number, and a special character (!@#$%^&*()-_=+).`;

const TextValidatorWithPasswordShowHide = withPasswordShowHide(TextValidator);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const styles = (theme: Theme) => createStyles({
    root: {
        '& $textField': {
            marginBottom: '2.2em'
        }
    },
    divider: {},
    title: {},
    error: {},
    footerTitle: {},
    footerButton: {},
    input: {},
    inputLabel: {},
    wizardContainer: {},
    textField: {},
    tooltip: {
        fontSize: '0.9em'
    },
    validationError: {
        position: 'absolute',
        bottom: '-2.3em',
        maxWidth: '31em'
    },
    hidePasswordButton: {
        padding: 0,
        '&:hover': {
            backgroundColor: 'transparent'
        }
    }
});

interface Props extends WithStyles<typeof styles> {
    accessCode?: string;
    hideAccessCode: boolean;
    className?: string;
    error?: string;
    email?: string;
    title: string;
    buttonText: string;
    footerTitleText: string;
    footerButtonText: string;
    onMainButtonClick?: (newAccountData: LoginWizardNewAccountData) => void;
    onFooterButtonClick?: () => void;
}

interface States {
    email: string;
    username: string;
    password: string;
    repeatPassword: string;
    accessCode: string;
    areFieldsValid: boolean;
    [key: string]: string | boolean;
}

class LoginWizardStepNewAccount extends React.Component<Props, States> {
    public static defaultProps = {
        hideAccessCode: false
    };

    state = {
        email: this.props.email ? this.props.email : '',
        username: '',
        password: '',
        repeatPassword: '',
        accessCode: '',
        areFieldsValid: false,
    };

    componentDidMount() {
        // custom rule will have name 'isPasswordMatch'
        ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
            return (value === this.state.password);
        });

        ValidatorForm.addValidationRule('isPasswordRulesValid', (value) => {
            return /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*)(\-_=+])/.test(value);
        });
    }

    onTextFieldChanged = (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const newState = {} as States;
        newState[name] =  event.target.value as string;
        this.setState(newState);
    };

    onMainButtonClicked = (event: React.FormEventHandler | React.SyntheticEvent<unknown>) => {
        const {
            accessCode: accessCodeProp,
            onMainButtonClick
        } = this.props;
        const { 
            email,
            username,
            password,
            accessCode
        } = this.state;
        
        (event as React.SyntheticEvent<unknown>).preventDefault();

        if (onMainButtonClick) {
            onMainButtonClick({
                email,
                username,
                password,
                accessCode: accessCodeProp ? accessCodeProp : accessCode
            } as LoginWizardNewAccountData);
        }
    }

    onValidationChanged = (isValid: boolean) => {
        const { areFieldsValid } = this.state;
        if (!areFieldsValid) {
            return;
        }
        this.setState({areFieldsValid: isValid});
    };

    public render() {
        const {
            classes,
            className,
            accessCode: accessCodeProp,
            hideAccessCode,
            error,
            buttonText,
            title,
            footerTitleText,
            footerButtonText,
            onFooterButtonClick
        } = this.props;
        const {
            accessCode,
            email,
            password,
            repeatPassword,
            username
        } = this.state;

        const INPUT_PROPS_CLASSES = { 
            classes: { 
                input: classes.input
            }
        };
        const INPUT_LABEL_PROPS_CLASSES = {
            classes: {
                root: classes.inputLabel
            }
        };
        const FORM_HELPER_TEXT_PROPS = {
            classes: {
                error: classes.validationError
            }
        };

        const rootClassNames = classes.root + (className ? ` ${className}` : '');
        return (
            <div className={rootClassNames}>
                <Typography
                    variant="h6"
                    className={classes.title}
                >
                    {title}
                </Typography>
                <Typography
                    variant="h6"
                    className={classes.error}
                >
                    {((error!== undefined) && (error.length > 0)) ? error : '\u00a0'} {/* unicode non breaking space */}
                </Typography>
                <ValidatorForm
                    className={classes.wizardContainer}
                    instantValidate={true}
                    onSubmit={this.onMainButtonClicked}
                >    
                    <TextValidator
                        id="email"
                        className={classes.textField}
                        label="Email Address"
                        type="email"
                        name="email"
                        autoComplete="email"
                        placeholder="email"
                        margin="normal"
                        variant="outlined"
                        value={email}
                        onChange={this.onTextFieldChanged('email')}
                        InputProps={INPUT_PROPS_CLASSES}
                        InputLabelProps={INPUT_LABEL_PROPS_CLASSES}
                        FormHelperTextProps={FORM_HELPER_TEXT_PROPS}
                        validators={['required', 'isEmail']}
                        errorMessages={['Email is required', 'Please enter a valid email address']}
                    />
                    <Tooltip
                        title={TOOLTIP_TEXT_USER_NAME}
                        classes={{tooltip: classes.tooltip}}
                    >
                        <TextValidator
                            id="username"
                            className={classes.textField}
                            label="Username"
                            type="username"
                            name="username"
                            margin="normal"
                            variant="outlined"
                            value={username}
                            onChange={this.onTextFieldChanged('username')}
                            InputProps={INPUT_PROPS_CLASSES}
                            InputLabelProps={INPUT_LABEL_PROPS_CLASSES}
                            FormHelperTextProps={FORM_HELPER_TEXT_PROPS}
                            validators={['required', 'minStringLength:5']}
                            errorMessages={['Username is required', 'Must be at least 5 characters']}
                        />
                    </Tooltip>
                    <Tooltip
                        title={TOOLTIP_TEXT_PASSWORD}
                        classes={{tooltip: classes.tooltip}}
                    >
                        <TextValidatorWithPasswordShowHide
                            id="password"
                            className={classes.textField}
                            iconClassName={classes.hidePasswordButton}
                            label="Password"
                            type="password"
                            name="password"
                            autoComplete="current-password"
                            margin="normal"
                            value={password}
                            onChange={this.onTextFieldChanged('password')}
                            InputProps={INPUT_PROPS_CLASSES}
                            InputLabelProps={INPUT_LABEL_PROPS_CLASSES}
                            FormHelperTextProps={FORM_HELPER_TEXT_PROPS}
                            validators={['required', 'minStringLength:8', 'isPasswordRulesValid']}
                            errorMessages={[
                                'Password is required',
                                'Must be at least 8 characters',
                                'Must include an uppercase letter, a lowercase letter, a number, and a special character (!@#$%^&*()-_=+)'
                            ]}
                        />
                    </Tooltip>
                    <Tooltip
                        title={TOOLTIP_TEXT_PASSWORD}
                        classes={{tooltip: classes.tooltip}}
                    >
                        <TextValidatorWithPasswordShowHide
                            id="confirmPassword"
                            className={classes.textField}
                            iconClassName={classes.hidePasswordButton}
                            label="Confirm Password"
                            type="password"
                            name="confirmPassword"
                            autoComplete="current-password"
                            margin="normal"
                            value={repeatPassword}
                            onChange={this.onTextFieldChanged('repeatPassword')}
                            InputProps={INPUT_PROPS_CLASSES}
                            InputLabelProps={INPUT_LABEL_PROPS_CLASSES}
                            FormHelperTextProps={FORM_HELPER_TEXT_PROPS}
                            validators={['required', 'minStringLength:8', 'isPasswordMatch']}
                            errorMessages={[
                                'Password is required',
                                'Must be at least 8 characters',
                                'Passwords do not match'
                            ]}
                        />
                    </Tooltip>
                    {
                        hideAccessCode || accessCodeProp ? '' :
                            <TextField
                                id="code"
                                className={classes.textField}
                                label="Access Code (For Managers Only)"
                                type="code"
                                name="code"
                                margin="normal"
                                variant="outlined"
                                value={accessCode}
                                onChange={this.onTextFieldChanged('accessCode')}
                                InputProps={INPUT_PROPS_CLASSES}
                                InputLabelProps={INPUT_LABEL_PROPS_CLASSES}
                                FormHelperTextProps={FORM_HELPER_TEXT_PROPS}
                            />
                    }
                    <Button
                        variant="contained"
                        color="secondary"
                        type="submit"
                    >
                        {buttonText}
                    </Button>
                    {
                        accessCodeProp ? '' :
                            <Divider
                                className={classes.divider}
                                variant="middle"
                            />
                    }
                    {
                        accessCodeProp ? '' :
                            <div className={classes.wizardContainer}>
                                <Typography
                                    className={classes.footerTitle}
                                    variant="h6"
                                >
                                    {footerTitleText}
                                </Typography>
                                <Button
                                    className={classes.footerButton}
                                    variant="outlined"
                                    onClick={onFooterButtonClick}
                                >
                                    {footerButtonText}
                                </Button>
                            </div>
                    }
                </ValidatorForm>
            </div>
        );
    }
}

const MUIComponent = withStyles(styles)(LoginWizardStepNewAccount);
export {MUIComponent as LoginWizardStepNewAccount};
