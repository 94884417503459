import Button from '@material-ui/core/Button';
import {
    createStyles,
    WithStyles,
    withStyles
} from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/SearchOutlined';
import * as React from 'react';

const styles = (theme: Theme) => createStyles({
    [theme.breakpoints.down('lg')]: {
        textField: {
            width: '60% !important'
        }
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '30em',
    },
    button: {
        margin: theme.spacing(1),
        alignSelf: 'flex-end'
    },
    rightIcon: {
        marginLeft: theme.spacing(1),
    }
});

interface Props extends WithStyles<typeof styles> {
    email: string;
    onSearchClick?: (email: string) => void;
};

interface States {
    email: string
}

class EmailSearch extends React.Component<Props, States> {
    state = {
        email: this.props.email,
    };
    onKeyPressed = (event:any) => {
        if (event.key !== 'Enter') {
            return;
        }
        this.performSearch();
        event.preventDefault();
    }

    onChange = (event:any) => {
        this.setState({
          email: event.target.value,
        });
    };

    onFindUserClicked = () => {
        this.performSearch();
    };

    performSearch = () => {
        const { onSearchClick } = this.props;
        if (onSearchClick) {
            const { email } = this.state;
            onSearchClick(email);
        }
    };

    public render() {
        const { classes } = this.props;
        return (
            <div className={classes.container}>
                <TextField
                    id="standard-name"
                    label="Email"
                    placeholder="email@crowdcomfort.com"
                    className={classes.textField}
                    value={this.state.email}
                    onChange={this.onChange}
                    onKeyPress={this.onKeyPressed}
                    margin="normal"
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <Button
                    variant="contained"
                    className={classes.button}
                    color="primary"
                    onClick={this.onFindUserClicked}
                >
                    Find User
                    <SearchIcon className={classes.rightIcon}/>
                </Button>
            </div>
        );
    }
}

const MUIComponent = withStyles(styles)(EmailSearch);
export {MUIComponent as EmailSearch}

