import { Theme, Typography, withStyles } from "@material-ui/core";
import { createStyles, WithStyles } from "@material-ui/styles";
import { inject, observer } from "mobx-react";
import { RootStore } from "../../stores/root.store";
import React from 'react';
import { CCSpinner } from "../../shared/components/cc-spinner";
import { AccountFolderPDF, ThinClient, RunningGroupTasksList } from "../../components";
import { Account, Folder } from "../../shared/domain";
import { RenderTree } from "../../shared/components/node-tree";
import { JobResultDataset, PickedType } from "../marker-export/marker-export";
import AccountProvider from "../../providers/account.provider";
import { PageBoundary } from "../../shared/components/simple-grid-pagination";
import _ from "lodash";
import { SimpleModal } from "../../shared/components/simple-modal";
import { Error as ErrorIcon } from "@material-ui/icons";
import { DialogResult, SimpleDialog } from "../../shared/components/simple-dialog";
import { MarkerOperation } from "../../components/thin-client/thin-client";
import FloorplanProvider from "../../providers/floorplan.provider";
import JobProvider, { JobRequest, JobTypes, RunningTasks, STATIC_PROGRESS_LIMIT, StatusTypes, TaskGroup } from "../../providers/job.provider";
import { UserIdentifiers } from "../../providers/user.provider";
import { MainTabs } from "../../stores/admin.store";
import { MarkerOperationList } from "../../components/marker-operation-list";
import FolderProvider from "../../providers/folder.provider";
import { v4 as uuidv4 } from 'uuid';
import Bugsnag from "@bugsnag/js";
import { Marker } from "../../models";

const ROWS_PER_PAGE = 10;
const ITEMS_PAGING_LIMIT = 20;
const TIMEOUT_FLOORPLAN_MESSAGE = 
    'This floorplan is taking longer than expected to load, do you want to convert it?' +
    '\n' + 
    '(This is a one time process it will be available to use after is done)'

export interface XFDFMarker {
    id: string;
    room_type: string;
    name: string;
    coordinates: string;
    isWrong?: boolean;
    out_of_bounds?: boolean;
}

interface MarkerJobOperationList {
    payload: JobRequest,
    name: string,
    group: string
}

const styles = (theme: Theme) => createStyles({
    container: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row'
    },
    errorPopup: {
        width: '98%',
        borderRadius: 5
    },
    errorPopupHeader: {
        height: '0.6em',
        backgroundColor: theme.palette.error.main,
        borderRadius: '3px 3px 0 0'
    },
    popupContentContainer :{
        display: 'flex',
        flexDirection: 'row',
        padding: theme.spacing(1),
    },
    errorPopupIcon: {
        fontSize: 40,
        marginRight: '1em'
    },
    mainSpinnerLabel: {
        paddingBottom: '30px'
    },
    progressContainer: {
        flexGrow: 1
    },
    animContainer: {
        transition: 'width 1s'
    },
    fullContainerSize: {
        width: '100%'
    },
    thinContainerSize: {
        width: '50%'
    },
    taskContainerSize: {
        width: '70%'
    },
    thinClientContainerSize: {
        flex: 1,
        marginLeft: '2em'
    },
    tasksContainerSize: {
        flex: 1,
        marginLeft: '2em'
    }
});

interface Props extends WithStyles<typeof styles> {
    rootStore: RootStore;
    onAuthError?: () => void;
}

interface States {
    accounts: Account[];
    currentAccount: Account | null;
    currentAccountOffset: number;
    networkError: boolean;
    dataPageIsLoading: boolean;
    isAccountsLoading: boolean;
    totalAccounts: number;
    folderIsLoading: boolean;
    mainSpinnerText: string;
    folders: Folder[];
    isFoldersLoading: boolean;
    currentNodeTree: RenderTree;
    treeIsLoading: boolean;
    pickedType: PickedType;
    pickedId: string;
    pickedName: string;
    fileBlob: Blob | null;
    updatedFileBlob: Blob | null;
    loadedFloorplanId: string;
    loadedFloorplanName: string;
    isThinClient: boolean;
    processedFolders: string[];
    existingMarkers: XFDFMarker[];
    finalMarkerOperations: MarkerOperation[];
    currentMarkerOperationsResumeOffset: number;
    isMarkerOperationsResume: boolean;
    runningTasksGroup: TaskGroup[];
    runningTasks: RunningTasks[];
    currentGroupOffset: number;
    timeElapser: number | null;
    isFinalProcessing: boolean;
    enabledEvents: string[];
    isErrorModalOpened: boolean;
    errorModalHeader: string;
    errorModalText: string;
    isJSONReady: boolean;
    isTimeoutModalOpened: boolean;
    searchAccountName: string;
    storedAccounts: Account[];
    storedAccountOffset: number;
    storedAccountTotalCount: number;
}

@inject('rootStore')
@observer
class MarkerOperations extends React.Component<Props, States> {
    public static defaultProps: Partial<Props> = {
    };
    
    state: States = {
        accounts: [],
        currentAccount: null,
        currentAccountOffset: 0,
        networkError: false,
        dataPageIsLoading: true,
        isAccountsLoading: false,
        totalAccounts: 0,
        folderIsLoading: false,
        mainSpinnerText: 'Please wait. Loading data.',
        folders: [],
        isFoldersLoading: false,
        currentNodeTree: {id: '', name:'', children:[], path: ''},
        treeIsLoading: false,
        pickedType: PickedType.None,
        pickedId: '',
        pickedName: '',
        fileBlob: null,
        updatedFileBlob: null,
        loadedFloorplanId: '',
        loadedFloorplanName: '',
        isThinClient: false,
        processedFolders: [],
        existingMarkers: [],
        finalMarkerOperations: [],
        currentMarkerOperationsResumeOffset: 0,
        isMarkerOperationsResume: false,
        runningTasksGroup: [],
        runningTasks: [],
        currentGroupOffset: 0,
        timeElapser: null,
        isFinalProcessing: false,
        enabledEvents: [
            'ANNOTATION_ADDED',
            'ANNOTATION_DELETED',
            'ANNOTATION_UPDATED'
        ],
        isErrorModalOpened: false,
        errorModalHeader: '',
        errorModalText: '',
        isJSONReady: false,
        isTimeoutModalOpened: false,
        searchAccountName: '',
        storedAccounts: [],
        storedAccountOffset: 0,
        storedAccountTotalCount: 0
    }
    
    folderProvider = new FolderProvider();
    accountProvider = new AccountProvider();
    floorplanProvider = new FloorplanProvider();
    jobProvider = new JobProvider();

    componentDidMount() {
        this.fetchAccounts(this.state.currentAccountOffset);
        this.fetchRunningTasks();
        this.startTimeInterval();
    }

    componentWillUnmount() {
        const {
            rootStore: { adminStore }
        } = this.props;   
        const { runningTasks, runningTasksGroup } = this.state;
        this.clearIntervals(runningTasks);
        adminStore.setCurrentExportTasks(runningTasks, MainTabs.Marker_Operations);
        adminStore.setCurrentExportGroups(runningTasksGroup, MainTabs.Marker_Operations);
        this.clearTimeElapserInterval();
    }

    clearIntervals(tasks: RunningTasks[]) {
        tasks.forEach((element) => {
            element.set_to_stop = true;
        });
    }

    clearTimeElapserInterval() {
        const { timeElapser } = this.state;
        if( timeElapser ) {
            window.clearInterval(timeElapser);
        }
    }

    startTimeInterval() {
        const { timeElapser } = this.state;
        if (timeElapser) {
            return;
        }
        const timeElapserInterval = window.setInterval(() => {
            const { runningTasks, runningTasksGroup } = this.state;
            const time = Date.now();
            const processing = runningTasks.filter(e => e.status === 102);
            processing.forEach(element => {
                element.elapsedTime = Math.round((time - element.startedAt) / 1000);
            });
            const processingGroups = runningTasksGroup.filter(e => (e.done.length + e.errored.length) !== e.total);
            processingGroups.forEach(element => {
                element.elapsedTime = Math.round((time - element.startedAt) / 1000);
            })
            this.setState({runningTasks, runningTasksGroup});
        }, 1000);
        this.setState({timeElapser: timeElapserInterval});
    }

    fetchRunningTasks = () => {
        const {
            rootStore: { adminStore }
        } = this.props;
        const tasks = adminStore.currentExportTasks.find(e => e.page === MainTabs.Marker_Operations);
        this.setState({
            runningTasks: tasks!.tasks,
            runningTasksGroup: tasks!.groups ? tasks!.groups : [] 
        }, () => {
            tasks!.tasks.forEach((element, index) => {
                if(element.status === 102) {
                    // SET TO RESTART POOLING
                    element.set_to_stop = false;
                    this.startTaskWatcher(element, index);
                }
            });
        });
    }

    fetchAccounts = (offset:number, limit: number = ITEMS_PAGING_LIMIT) => {
        return this.accountProvider.getAllAccounts(offset, limit)
          .then((results) => {
            const {accounts, totalCount} = results;
            if (!accounts) {
                if (offset === 0) {
                    this.setState({networkError: true});
                }
                return;
            }

            this.setState({
                dataPageIsLoading: false,
                isAccountsLoading: false,
                totalAccounts: totalCount,
                accounts,
                currentAccountOffset: offset
            });
        }).catch((error) => {
            const { status } = error.response;
            if (status === 401) {
                const { onAuthError } = this.props;
                if (!onAuthError) {
                    return;
                }
                onAuthError();
                return;
            }
            this.setState({networkError: true});
        });
    }
    
    searchAccounts = (searchName: string, offset?: number) => {
        // Nothing to search load the normal account list
        if (searchName === '') {
            const {
                storedAccounts,
                storedAccountOffset,
                storedAccountTotalCount
            } = this.state;

            // Empty search do nothing
            if (!storedAccounts.length) {
                return;
            }

            // Going back from actual seach
            this.setState({
                accounts: storedAccounts,
                currentAccountOffset: storedAccountOffset,
                totalAccounts: storedAccountTotalCount,
                searchAccountName: '',
                storedAccounts: [],
                storedAccountOffset: 0,
                storedAccountTotalCount: 0
            });
            return;
        }

        // We have a name search
        const {
            currentAccountOffset,
            accounts,
            totalAccounts,
            storedAccounts
        } = this.state;
        this.setState({
            isAccountsLoading: true,
            searchAccountName: searchName
        }, () => {
            const searchOffset = offset ? offset : 0;
            return this.accountProvider
                .searchAccountByNameShortSchema(searchName, searchOffset, ITEMS_PAGING_LIMIT)
                .then(results => {
                    const { accounts: foundAccounts, totalCount } = results;
                    if (!foundAccounts) {
                        if (currentAccountOffset === 0) {
                            this.setState({ networkError: true });
                        }
                        return;
                    }
    
                    // If we haven't stored the original list do it
                    // This prevents multiple searchs overriding each others
                    if (!storedAccounts.length) {
                        this.setState({
                            storedAccounts: accounts,
                            storedAccountTotalCount: totalAccounts,
                            storedAccountOffset: currentAccountOffset
                        })
                    }

                    this.setState({
                        isAccountsLoading: false,
                        totalAccounts: totalCount,
                        accounts: foundAccounts,
                        currentAccountOffset: searchOffset
                    });
                })
                .catch(error => {
                    const { status } = error.response;
                    if (status === 401) {
                        const { onAuthError } = this.props;
                        if (!onAuthError) {
                            return;
                        }
                        onAuthError();
                        return;
                    }
                    this.setState({ networkError: true });
                });
        });
    }

    onAccountClicked = (account: Account) => {
        const { currentAccount } = this.state;
        if (currentAccount === account) {
            return;
        }

        this.setState({currentAccount: account});
        this.fetchFoldersFromAccount(account);
    };

    fetchFoldersFromAccount = (account: Account) => {
        this.setState({
            folderIsLoading: true,
            pickedType: PickedType.None,
            pickedId: '',
            processedFolders: []
        });

        return this.accountProvider.getRootFoldersByAccountId(account.accountId)
            .then((results) => {
                const folders: Folder[] = results;
                if(!folders) {
                    this.setState({networkError: true});
                    return;
                }

                // TRANSFORM THE FOLDERS INTO TREE VIEW
                const accountTree: RenderTree = {
                    id: account.accountId,
                    name: account.name,
                    children: [],
                    path: ''
                };

                const treeNodes: RenderTree[] = folders.map(element => {
                    const newItem = {
                        id: element.id,
                        name: element.name,
                        children: [],
                        path: `${element.id}__`
                    }
                    return newItem as RenderTree;
                });

                accountTree.children = treeNodes;

                this.setState({
                    folderIsLoading: false,
                    folders,
                    currentNodeTree: accountTree
                });

        }).catch((error) => {
            // tslint:disable-next-line:no-console
            console.error(error);
            const { status } = error.response;
            if (status === 401) {
                const { onAuthError } = this.props;
                if (!onAuthError) {
                    return;
                }
                onAuthError();
                return;
            }
            this.setState({networkError: true});
        });

    };

    onAccountDataPageOverBoundaryReached = (boundary: PageBoundary, nextPage: number): Promise<void> => {
        return new Promise<void>((resolve, reject) => {
            const { 
                currentAccountOffset,
                totalAccounts
            } = this.state;
            const isFirstPage = nextPage === 0;
            const isLastPage = nextPage === (Math.ceil(totalAccounts / ROWS_PER_PAGE)-1);
            const newOffset = isFirstPage ? 0
                            : isLastPage ? ITEMS_PAGING_LIMIT * Math.floor(totalAccounts / ITEMS_PAGING_LIMIT)
                            : (boundary === PageBoundary.Upper) ? 
                                currentAccountOffset+ITEMS_PAGING_LIMIT :
                                currentAccountOffset-ITEMS_PAGING_LIMIT;
            this.setState({isAccountsLoading: true});
            this.fetchAccounts(newOffset)
              .then(() => resolve())
              .catch(()=> reject());
        });
    };

    deepTreeSearch = (treeData: RenderTree, searchId: string) => {
        let foundTree: RenderTree | undefined;
        let searchLevel: RenderTree[] | undefined = treeData.children;
        do {
            foundTree = searchLevel?.find((e: RenderTree) => e.id === searchId);
            let tempLevel: any = [];
            searchLevel?.forEach((element: RenderTree) => {
                tempLevel = tempLevel.concat(element.children);
            });
            searchLevel = tempLevel;
        } while(!foundTree);
        return foundTree;
    }

    updateChildrenOnPath = (treeData: RenderTree, nodeId: string, children: RenderTree[]) => {
        treeData.children!.forEach(element => {
            if (_.isEqual(element.id, nodeId)) {
                element.children = children;
            } else {
                this.updateChildrenOnPath(element, nodeId, children);
            }
        });
    }

    onNodeSelected = (treeData: RenderTree , nodeId: string, nodeLabel: string, updateCallback: (nodeIds: string[]) => void) => {
        const { folders, currentAccount, processedFolders } = this.state;
        if(currentAccount!.accountId === nodeId) {
            this.setState({
                pickedType: PickedType.None,
                pickedId: '',
                pickedName: ''
            });
            return;
        }
        this.setState({treeIsLoading: true});
        // CHECK PICK TYPE
        // FOLDER
        const currentNodePos = this.deepTreeSearch(treeData, nodeId);
        if(folders.find(e => e.id === nodeId)) {
            // DID WE ALREADY CHECK FOR CHILDREN?
            if (processedFolders.find(e => e === nodeId)) {
                // FOLDER CHILDREN
                // WE DONT DO FOLDERS FOR PDF
                this.setState({
                    pickedType: PickedType.None,
                    pickedId: '',
                    pickedName: '',
                    currentNodeTree: treeData,
                    treeIsLoading: false
                });
                return;
            }
            // EMPTY
            // CHECK FOR FOLDER CHILDREN FIRST
            return this.accountProvider.getChildrenFolderByFolderId(currentAccount!.accountId, nodeId)
                .then((results: Folder[]) => {
                    if (results.length > 0) {
                        const nodes: RenderTree[] = results.map(element => {
                            const newItem = {
                                id: element.id,
                                name: element.name,
                                children: [],
                                path: `${currentNodePos.path}${element.id}__`
                            }
                            return newItem as RenderTree;
                        });
                        this.updateChildrenOnPath(treeData, nodeId, nodes);
                        const currentPath = currentNodePos.path.slice(0, -2);
                        updateCallback([currentAccount!.accountId, ...currentPath.split('__'), ...nodes.map(e => e.id)]);
                        processedFolders.push(nodeId);
                        this.setState({
                            pickedType: PickedType.None,
                            pickedId: '',
                            pickedName: '',
                            currentNodeTree: treeData,
                            treeIsLoading: false,
                            folders: folders.concat(results),
                            processedFolders
                        });
                        return;
                    } else {
                        return this.folderProvider.fetchFloorplans(nodeId)
                            .then((floorplans) => {
                                const nodes: RenderTree[] = floorplans.map((element: RenderTree) => {
                                    const newItem = {...element} as RenderTree;
                                    newItem.children = [];
                                    newItem.path = `${currentNodePos.path}${element.id}__`;
                                    return newItem;
                                });
                                this.updateChildrenOnPath(treeData, nodeId, nodes);
                                const currentPath = currentNodePos.path.slice(0, -2);
                                updateCallback([currentAccount!.accountId, ...currentPath.split('__'), ...nodes.map(e => e.id)]);
                                processedFolders.push(nodeId);
                                // FOLDERS ARE NOT ALLOWED TO LOAD PDF
                                this.setState({
                                    pickedType: PickedType.None,
                                    pickedId: '',
                                    pickedName: '',
                                    currentNodeTree: treeData,
                                    treeIsLoading: false,
                                    processedFolders
                                });
                        }).catch((error) => {
                            // tslint:disable-next-line:no-console
                            console.error(error);
                            const { status } = error.response;
                            if (status === 401) {
                                const { onAuthError } = this.props;
                                if (!onAuthError) {
                                    return;
                                }
                                onAuthError();
                                return;
                            }
                            this.setState({networkError: true});
                        });
                    }
            }).catch((error) => {
                // tslint:disable-next-line:no-console
                console.error(error);
                const { status } = error.response;
                if (status === 401) {
                    const { onAuthError } = this.props;
                    if (!onAuthError) {
                        return;
                    }
                    onAuthError();
                    return;
                }
                this.setState({networkError: true});
            });
        }
        this.setState({
            pickedType: PickedType.FloorPlan,
            pickedId: nodeId,
            pickedName: currentNodePos.name,
            treeIsLoading: false
        });
        return;
    }

    onNodeToggled = (nodeIds: string[]) => {
        return;
    }

    onFloorplanClick = () => {
        const { pickedId, pickedName } = this.state;
        this.setState({
            treeIsLoading: true,
            isThinClient: false,
            loadedFloorplanId: '',
            loadedFloorplanName: ''
        });
        // DOWNLOAD THE PDF TO DISPLAY
        this.floorplanProvider.downloadFloorplanPDF(pickedId).then(data => {
            const { file_blob, status } = data;
            if (status === 204) {
                // THIS FLOORPLAN DOES NOT HAVE A VALID PDF
                this.setState({
                    fileBlob: null,
                    isThinClient: false,
                    treeIsLoading: false,
                    pickedType: PickedType.None,
                    isErrorModalOpened: true,
                    existingMarkers: [],
                    finalMarkerOperations: [],
                    errorModalHeader: 'Error downloading the PDF',
                    errorModalText: 'This floorplan doesn`t have a PDF or it`s corrupt',
                    isJSONReady: false
                });
                return;
            }
            
            // GET THE MARKERS IN THE XFDF
            // TODO: check if we already processed this floorplan
            this.floorplanProvider.fetchMarkersInXFDF(pickedId).then(data => {
                const { markers, status } = data;
                // EITHER THE XFDF DOES NOT EXISTS OR THE PDF IS THIN CLIENT READY
                // DONT LOAD THE MARKERS
                if (status === 204) {
                    this.setState({
                        fileBlob: file_blob,
                        isThinClient: true,
                        treeIsLoading: false,
                        pickedType: PickedType.None,
                        existingMarkers: [],
                        finalMarkerOperations: [],
                        isJSONReady: true,
                        loadedFloorplanId: `${pickedId}`,
                        loadedFloorplanName: `${pickedName}`,
                    });
                    return;
                }

                const existingMarkers = markers.map((e: XFDFMarker) => {
                    const item = e as XFDFMarker;
                    item.isWrong = e.out_of_bounds ? true : false;
                    return item;
                });
                this.setState({
                    fileBlob: file_blob,
                    isThinClient: true,
                    treeIsLoading: false,
                    pickedType: PickedType.None,
                    existingMarkers,
                    finalMarkerOperations: [],
                    isJSONReady: false,
                    loadedFloorplanId: `${pickedId}`,
                    loadedFloorplanName: `${pickedName}`,
                });
                
            }).catch((error) => {
                const { status } = error.response;

                // TIMEOUT DISPLAY MODAL
                if (status === 504) {
                    const { pickedName, currentAccount } = this.state;
                    // NOTIFY BUGSNAG TO KEEP RECORD
                    Bugsnag.notify((new Error(`Floorplan - ${pickedId} XFDF timeout`)), event => {
                        event.severity = 'warning';
                        event.context = 'Marker operations';
                        event.addMetadata('floorplan', {
                            floorplan_id: pickedId,
                            floorplan_name: pickedName,
                            account_id: currentAccount
                        })
                    });
                    this.setState({isTimeoutModalOpened: true});
                    return;
                }
                if (status === 401) {
                    const { onAuthError } = this.props;
                    if (!onAuthError) {
                        return;
                    }
                    onAuthError();
                    return;
                }
                // tslint:disable-next-line:no-console
                console.error(error);
                this.setState({networkError: true});
            });
            
        }).catch((error) => {
            // tslint:disable-next-line:no-console
            console.error(error);
            const { status } = error.response;
            if (status === 401) {
                const { onAuthError } = this.props;
                if (!onAuthError) {
                    return;
                }
                onAuthError();
                return;
            }
            this.setState({networkError: true});
        });
    }

    onMarkerResumePageOverBoundaryReached = (boundary: PageBoundary, nextPage: number): Promise<void> => {
        return new Promise<void>((resolve, reject) => {
            const { 
                currentMarkerOperationsResumeOffset,
                finalMarkerOperations
            } = this.state;
            const totalTasks = finalMarkerOperations.length;
            const isFirstPage = nextPage === 0;
            const isLastPage = nextPage === (Math.ceil(totalTasks / ROWS_PER_PAGE)-1);
            const newOffset = isFirstPage ? 0
                            : isLastPage ? ITEMS_PAGING_LIMIT * Math.floor(totalTasks / ITEMS_PAGING_LIMIT)
                            : (boundary === PageBoundary.Upper) ? 
                            currentMarkerOperationsResumeOffset+ITEMS_PAGING_LIMIT :
                            currentMarkerOperationsResumeOffset-ITEMS_PAGING_LIMIT;
            const nextTasks = finalMarkerOperations;
            // MANUAL OFFSET
            this.setState({
                finalMarkerOperations: nextTasks.splice(newOffset, nextTasks.length)
            });
        });
    };

    onGroupTaskDataPageOverBoundaryReached = (boundary: PageBoundary, nextPage: number): Promise<void> => {
        return new Promise<void>((resolve, reject) => {
            const { 
                currentGroupOffset,
                runningTasksGroup
            } = this.state;
            const totalTasks = runningTasksGroup.length;
            const isFirstPage = nextPage === 0;
            const isLastPage = nextPage === (Math.ceil(totalTasks / ROWS_PER_PAGE)-1);
            const newOffset = isFirstPage ? 0
                            : isLastPage ? ITEMS_PAGING_LIMIT * Math.floor(totalTasks / ITEMS_PAGING_LIMIT)
                            : (boundary === PageBoundary.Upper) ? 
                            currentGroupOffset+ITEMS_PAGING_LIMIT :
                            currentGroupOffset-ITEMS_PAGING_LIMIT;
            const nextTasks = runningTasksGroup;
            // MANUAL OFFSET
            this.setState({
                runningTasksGroup: nextTasks.splice(newOffset, nextTasks.length)
            });
        });
    };

    onFinishMarkerOperations = (markerOperations: MarkerOperation[], updatedFileBlob: Blob) => {
        this.setState({
            finalMarkerOperations: markerOperations,
            isMarkerOperationsResume: true,
            updatedFileBlob
        });
    }

    onGroupClear = () => {
        const { runningTasks, runningTasksGroup } = this.state;
        const pendingTasks = runningTasks.filter(e => e.status === 102);
        const pendingGroups = runningTasksGroup.filter(e => (e.done.length + e.errored.length) !== e.total);
        const {
            rootStore: { adminStore }
        } = this.props;
        adminStore.setCurrentExportTasks(pendingTasks, MainTabs.Marker_Operations);
        adminStore.setCurrentExportGroups(pendingGroups, MainTabs.Marker_Operations);
        this.setState({runningTasks: pendingTasks, runningTasksGroup: pendingGroups});
    }

    closeResumeModal = () => {
        this.setState({isMarkerOperationsResume: false});
    }

    addCountToGroup = (groupId: string, taskId: string, category: string) => {
        if (category !== 'done' && category !== 'errored') {
            return;
        }

        const { runningTasksGroup } = this.state;
        const group = runningTasksGroup.find(e => e.id === groupId);

        if (!group) {
            return;
        }
        
        if (!group.tasks.find(e => e.task_id === taskId)) {
            return;
        }

        if (group[category].includes(taskId)) {
            return;
        }

        group[category].push(taskId);

        this.setState({runningTasksGroup});
    }

    startTaskWatcher = (task: RunningTasks, position: number) => {
        const { runningTasks, runningTasksGroup } = this.state;

        if (!task.export_name || !task.task_id || !task.export_id) {
            return;
        }

        const statusCallback = (taskId: string) => {
            const taskIndex = runningTasks.findIndex(e => e.task_id === taskId);
            const taskItem = runningTasks.find(e => e.task_id === taskId);
            const taskGroup = runningTasksGroup.find(e => e.id === taskItem!.group_id);

            if (!taskItem || taskItem.status !== 102 || !taskGroup) {
                return;
            }   

            this.jobProvider.checkJobStatus(taskItem.task_id).then(data => {
                if (data.job_status === StatusTypes.Error) {
                    taskItem.status = 500;
                    runningTasks[taskIndex] = taskItem;
                    this.addCountToGroup(taskGroup.id, taskItem.task_id, 'errored');
                    this.setState({runningTasks});
                }
                if (data.job_status === StatusTypes.Complete) {
                    taskItem.status = 200;
                    taskItem.progress = 100.0;
                    this.addCountToGroup(taskGroup.id, taskItem.task_id, 'done');
                    this.jobProvider.checkJobResult(taskItem.task_id).then(res => {
                        const results = res.result as JobResultDataset;
                        taskItem.result_data = results.result;
                        runningTasks[taskIndex] = taskItem;
                        this.setState({runningTasks});
                    }).catch((error) => {
                        const { status } = error.response;
                        // CHECK IF WE TIMEOUT OR NOT
                        if (status === 408 || status === 504) {
                            // WE HAVE TIMEOUT INCREASE TIMER AND KEEP TRYING
                            taskItem.timer += 1000;
                            setTimeout(taskItem.internalCallback!, taskItem.timer, taskId);
                            return;
                        }
                        taskItem.status = 500;
                        this.addCountToGroup(taskGroup.id, taskItem.task_id, 'errored');
                        runningTasks[taskIndex] = taskItem;
                        this.setState({runningTasks});
                    });
                } else {
                    // KEEP TRACK OF THE PROGRESS STATUS IF ITS STILL THE SAME
                    if (taskItem.progress === data.estimate_percent_complete) {
                        taskItem.static_progress_count += 1;
                    }
                    taskItem.progress = data.estimate_percent_complete;
                    // IF WE REACH A POINT WHERE THE STATUS IS THE SAME THE JOB IS SLOW
                    // MAKE FEWER REQUESTS TO NOT OVERWHELM THE STATUS ENDPOINT
                    if (taskItem.static_progress_count === STATIC_PROGRESS_LIMIT) {
                        taskItem.timer += 1000;
                        taskItem.static_progress_count = 0;
                    }
                    setTimeout(taskItem.internalCallback!, taskItem.timer, taskId);
                }
            }).catch((error) => {
                const { status } = error.response;
                // CHECK IF WE TIMEOUT OR NOT
                if (status === 408 || status === 504) {
                    // WE HAVE TIMEOUT INCREASE TIMER AND KEEP TRYING
                    taskItem.timer += 1000;
                    setTimeout(taskItem.internalCallback!, taskItem.timer, taskId);
                    return;
                }
                taskItem.status = 500;
                this.addCountToGroup(taskGroup!.id, taskItem.task_id, 'errored');
                runningTasks[taskIndex] = taskItem;
                this.setState({runningTasks});
            });
        }

        const taskIndex = runningTasks.findIndex(e => e.task_id === task.task_id);
        runningTasks[taskIndex].internalCallback = statusCallback;
        runningTasks[taskIndex].timer = position * 500;
        this.setState({runningTasks}, () => {
            setTimeout(statusCallback, task.timer, task.task_id);
        });
    }

    submitMarkerJob = (jobPayload: JobRequest, exportName: string, groupId: string) => {
        const { currentAccount, runningTasks, runningTasksGroup } = this.state;
        return this.jobProvider.scheduleJob(jobPayload)
            .then((result) => {
                const {
                    rootStore: { adminStore }
                } = this.props;
                const newTask: RunningTasks = {
                    account: currentAccount,
                    export_name: exportName,
                    export_id: jobPayload.additional_params.marker_id,
                    type: JobTypes.MarkerOperations,
                    task_id: result.job_id,
                    status: 102,
                    startedAt: Date.now(),
                    elapsedTime: 0,
                    progress: 0,
                    group_id: groupId,
                    timer: 0,
                    static_progress_count: 0,
                        set_to_stop: false
                };

                runningTasks.push(newTask);
                adminStore.setCurrentExportTasks(runningTasks, MainTabs.Marker_Operations);
                const taskGroup = runningTasksGroup.find(e => e.id === groupId);
                taskGroup!.total += 1;
                const taskPos = taskGroup!.tasks.push(newTask);
                adminStore.setCurrentExportGroups(runningTasksGroup, MainTabs.Marker_Operations);
                this.setState({runningTasks});
                this.startTaskWatcher(newTask, (taskPos - 1));
                this.startTimeInterval();
            }).catch((error) => {
                // tslint:disable-next-line:no-console
                console.error(error);
                const { status } = error.response;
                if (status === 401) {
                    const { onAuthError } = this.props;
                    if (!onAuthError) {
                        return;
                    }
                    onAuthError();
                    return;
                }
                this.setState({networkError: true});
            });
    }

    onSubmitMarkerChanges = () => {
        const { finalMarkerOperations, loadedFloorplanId, loadedFloorplanName, updatedFileBlob, currentAccount, runningTasksGroup } = this.state;

        if (!updatedFileBlob || !loadedFloorplanId || !currentAccount || loadedFloorplanId === '') {
            return;
        }

        const {
            rootStore: { adminStore }
        } = this.props;
        this.setState({isFinalProcessing: true});
        const userIdentifiers: UserIdentifiers = adminStore.userIdentifiers!;

        // MAKE A GROUP FOR DISPLAY
        const newGroup = {
            id: uuidv4(),
            name: `Updating floorplan: ${loadedFloorplanName}`,
             progress: 0,
            startedAt: Date.now(),
            elapsedTime: 0,
            total: 0,
            done: [],
            errored: [],
            tasks: [],
        } as TaskGroup;
        runningTasksGroup.push(newGroup);
        this.setState({runningTasksGroup});

        // MAKE THE PAYLOADS
        const markerJobs: MarkerJobOperationList[] = finalMarkerOperations.map(e => {
            const payloadItem: JobRequest = {
                user_created_id: userIdentifiers.user_id,
                account_id: currentAccount ? currentAccount.accountId : userIdentifiers.account_id,
                job_type: `${JobTypes.MarkerOperations}`,
                additional_params: {...e}
            };
            return {
                payload: payloadItem,
                name: `${e.verb}: ${e.name}`,
                group: newGroup.id
            } as MarkerJobOperationList
        });
        // NOW THE MINIMAP CREATION IS DONE ON EACH OPERATION
        // UPLOAD THE FLOORPLAN BEFORE SENDING ANY JOB
        this.floorplanProvider.uploadFloorplanPDF(loadedFloorplanId, updatedFileBlob).then(data => {
            markerJobs.forEach(element => {
                this.submitMarkerJob(element.payload, element.name, element.group);
            });
            this.setState({
                isFinalProcessing: false,
                isThinClient: false,
                isMarkerOperationsResume: false,
                loadedFloorplanId: '',
                loadedFloorplanName: '',
                pickedType: PickedType.None,
                treeIsLoading: false
            });
        }).catch((error) => {
            // tslint:disable-next-line:no-console
            console.error(error);
            const { status } = error.response;
            if (status === 401) {
                const { onAuthError } = this.props;
                if (!onAuthError) {
                    return;
                }
                onAuthError();
                return;
            }
            this.setState({networkError: true});
        });
    }

    onErrorModalClicked = (result: DialogResult) => {
        // Closes the dialog
        this.setState({isErrorModalOpened: false});
    };

    onTaskClick = (task: RunningTasks) => {
        if (task.status === 102) {
            return;
        }
        if(task.status === 200) {
            return;
        }
        this.jobProvider.checkJobError(task.task_id)
            .then((data) => {
                this.setState({
                    isErrorModalOpened: true,
                    errorModalHeader: data.error,
                    errorModalText: data.stack_trace
                });
            }).catch((error) => {
                // tslint:disable-next-line:no-console
                console.error(error);
                this.setState({networkError: true});
            });
    }

    onTimeoutModalClicked = (result: DialogResult) => {
        this.setState({isTimeoutModalOpened: false, treeIsLoading: false});

        if (!result) {
            return;
        }

        if (result === DialogResult.Cancel) {
            return;
        }

        // IS A FLOORPLAN TIMEOUTS ON THE XFDF MARKERS PROCESS THE USER CAN CHOOSE TO
        // GO THROUGH THE CONVERTION PROCESS TO AVOID PROBLEMS
        const {
            rootStore: { adminStore }
        } = this.props;
        const userIdentifiers: UserIdentifiers = adminStore.userIdentifiers!;

        const { pickedName, pickedId, runningTasks, runningTasksGroup, currentAccount } = this.state;

        if(!currentAccount || !pickedId || !pickedName) {
            return;
        }

        // MAKE A GROUP FOR DISPLAY
        const newGroup = {
            id: uuidv4(),
            name: `Converting ${currentAccount.name} floorplans`,
                progress: 0,
            startedAt: Date.now(),
            elapsedTime: 0,
            total: 0,
            done: [],
            errored: [],
            tasks: [],
        } as TaskGroup;
        runningTasksGroup.push(newGroup);
        this.setState({runningTasksGroup});

        const payloadItem: JobRequest = {
            user_created_id: userIdentifiers.user_id,
            account_id: currentAccount.accountId,
            job_type: `${JobTypes.ThinClientConvertion}`,
            additional_params: {
                floorplan_ids: pickedId
            }
        };

        this.jobProvider.scheduleJob(payloadItem)
            .then((result) => {
                const {
                    rootStore: { adminStore }
                } = this.props;

                const newTask: RunningTasks = {
                    account: currentAccount,
                    export_name: `Converting floorplan: ${pickedName}`,
                    export_id: pickedId,
                    type: JobTypes.ThinClientConvertion,
                    task_id: result.job_id,
                    status: 102,
                    startedAt: Date.now(),
                    elapsedTime: 0,
                    progress: 0,
                    group_id: newGroup.id,
                    timer: 0,
                    static_progress_count: 0,
                        set_to_stop: false
                };

                runningTasks.push(newTask);
                adminStore.setCurrentExportTasks(runningTasks, MainTabs.Marker_Operations);
                const taskGroup = runningTasksGroup.find(e => e.id === newGroup.id);
                taskGroup!.total += 1;
                const taskPos = taskGroup!.tasks.push(newTask);
                adminStore.setCurrentExportGroups(runningTasksGroup, MainTabs.Marker_Operations);
                this.setState({runningTasks});
                this.startTaskWatcher(newTask, (taskPos - 1));
                this.startTimeInterval();
            }).catch((error) => {
                // tslint:disable-next-line:no-console
                console.error(error);
                const { status } = error.response;
                if (status === 401) {
                    const { onAuthError } = this.props;
                    if (!onAuthError) {
                        return;
                    }
                    onAuthError();
                    return;
                }
                this.setState({networkError: true});
            });
    }

    fetchFloorplanMarkers = () => {
        const { loadedFloorplanId } = this.state;
        if (!loadedFloorplanId) {
            return;
        }

        return this.floorplanProvider.fetchMarkers(loadedFloorplanId).then(data => {
            const markers = data as Marker[];
            return markers;
        }).catch((error) => {
            // tslint:disable-next-line:no-console
            console.error(error);
            const { status } = error.response;
            if (status === 401) {
                const { onAuthError } = this.props;
                if (!onAuthError) {
                    return;
                }
                onAuthError();
                return;
            }
            this.setState({networkError: true});
        });
    }

    public render() {
        const {
            classes
        } = this.props;
        const {
            accounts,
            currentAccount,
            currentAccountOffset,
            networkError,
            dataPageIsLoading,
            isAccountsLoading,
            totalAccounts,
            folderIsLoading,
            mainSpinnerText,
            folders,
            currentNodeTree,
            treeIsLoading,
            pickedType,
            pickedId,
            pickedName,
            fileBlob,
            isThinClient,
            isMarkerOperationsResume,
            existingMarkers,
            finalMarkerOperations,
            runningTasksGroup,
            runningTasks,
            currentGroupOffset,
            isFinalProcessing,
            enabledEvents,
            isErrorModalOpened,
            errorModalHeader,
            errorModalText,
            isJSONReady,
            isTimeoutModalOpened,
            loadedFloorplanId,
            loadedFloorplanName
        } = this.state;

        const currentAccountName = currentAccount ? currentAccount.name : '';

        return (
            <div className={classes.container} data-testid="mainRender">
                <CCSpinner
                    label ={mainSpinnerText}
                    labelClassName={classes.mainSpinnerLabel}
                    className={classes.progressContainer}
                    loading={dataPageIsLoading}
                    size={200}
                >
                    <AccountFolderPDF
                        className={`${classes.animContainer} ${isThinClient ? classes.thinContainerSize : runningTasks.length > 0 ? classes.taskContainerSize : classes.fullContainerSize}`}
                        rowsPerPage={ROWS_PER_PAGE}
                        accounts={accounts}
                        accountsIsLoading={isAccountsLoading}
                        accountsTotalItems={totalAccounts}
                        accountsItemsOffset={currentAccountOffset}
                        currentAccountName={currentAccountName}
                        folders={folders}
                        folderIsLoading={folderIsLoading}
                        onAccountClick={this.onAccountClicked}
                        onAccountDataPageOverBoundary={
                            this.onAccountDataPageOverBoundaryReached
                        }
                        currentNodeTree={currentNodeTree}
                        treeIsLoading={treeIsLoading}
                        onNodeSelected={this.onNodeSelected}
                        onNodeToggled={this.onNodeToggled}
                        onExportClick={this.onFloorplanClick}
                        pickedType={pickedType}
                        pickedId={pickedId}
                        onAccountSearch={this.searchAccounts}
                    />
                    {
                        isThinClient &&
                        <ThinClient 
                            className={`${classes.animContainer} ${classes.thinClientContainerSize}`}
                            floorplanName={loadedFloorplanName}
                            floorplanId={loadedFloorplanId}
                            fileBlob={fileBlob}
                            enabledEvents={enabledEvents}
                            existingMarkers={existingMarkers}
                            onFinishMarkerOperations={this.onFinishMarkerOperations}
                            isJSONReady={isJSONReady}
                            fetchFloorplanMarkers={this.fetchFloorplanMarkers}
                        />
                    }
                    {
                        (runningTasks && (runningTasks.length > 0) && runningTasksGroup && (runningTasksGroup.length > 0)) &&
                        <RunningGroupTasksList
                            className={classes.tasksContainerSize}
                            tasks={runningTasks}
                            groups={runningTasksGroup}
                            rowsPerPage={ROWS_PER_PAGE}
                            groupItemsOffset={currentGroupOffset}
                            listName="Running tasks"
                            onGroupDataPageOverBoundary={this.onGroupTaskDataPageOverBoundaryReached}
                            onGroupClear={this.onGroupClear}
                            onJobItemClick={this.onTaskClick}
                        />
                    }
                    <SimpleDialog 
                        open={isTimeoutModalOpened}
                        titleText={`Timeout exception for floorplan: ${pickedName}`}
                        contentText={TIMEOUT_FLOORPLAN_MESSAGE}
                        buttonCancelLabel="No, don't convert"
                        buttonOkLabel="Convert"
                        onDialogResult={this.onTimeoutModalClicked}
                        data-testid="timeout-modal"
                    />
                </CCSpinner>
                <SimpleDialog
                    open={isErrorModalOpened}
                    titleText={errorModalHeader}
                    contentText={errorModalText}
                    buttonCancelLabel=""
                    onDialogResult={this.onErrorModalClicked}
                    data-testid="errorModal"
                />
                {
                    networkError &&
                        <SimpleModal
                            className={classes.errorPopup}
                            open={networkError}
                            contentClasses= {classes.popupContentContainer}
                            buttonOkLabel=""
                            buttonCancelLabel=""
                            header=""
                            headerClassName={classes.errorPopupHeader}
                        >
                            <ErrorIcon color="error" className={classes.errorPopupIcon} />
                            <div>
                                <Typography variant="h5">
                                    {'Network Error'}
                                </Typography>
                                <Typography variant="subtitle1">
                                    {'Please reload the page.'}
                                </Typography>
                            </div>
                        </SimpleModal>
                }
                <MarkerOperationList 
                    isMarkerOperationsResume={isMarkerOperationsResume}
                    markerOperations={finalMarkerOperations}
                    onMarkerResumePageOverBoundaryReached={this.onMarkerResumePageOverBoundaryReached}
                    isFinalProcessing={isFinalProcessing}
                    closeResumeModal={this.closeResumeModal}
                    onSubmitMarkerChanges={this.onSubmitMarkerChanges}
                />
            </div>
        );
    }
}

const MUIComponent = withStyles(styles)(MarkerOperations);
export {MUIComponent as MarkerOperations};