import { HttpConnectorAdapter } from './../adapters/http-connector-adapter';

export default class QRProvider {
    private endpointAdmin: string;

    constructor() {
        this.endpointAdmin = `${process.env.REACT_APP_ADMIN_TOOL_API}qr`;
    };

    init = () => {
        return HttpConnectorAdapter;
    };
    
    uploadQRCodeFiles = (qrPositionFile: File, qrTemplateFile: File) => {
        const formData = new FormData();
        formData.append('position_file', qrPositionFile);
        formData.append('template_file', qrTemplateFile);
        return this.init().postWithCredentials(`${this.endpointAdmin}/upload_templates`, formData).then(response => {
            const { template_url, position_url } = response.data;
            const statusCode = response.status;

            return { status: statusCode, template_url, position_url };
        });
    }

}