import { Button, Grid, IconButton, Paper } from '@material-ui/core';
import {
    createStyles,
    WithStyles,
    withStyles,
    Theme
} from '@material-ui/core/styles';
import { CloseOutlined } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import _ from 'lodash';
import * as React from 'react';
import { PickedType } from '../../features/marker-export/marker-export';
import AccountProvider from '../../providers/account.provider';
import FolderProvider from '../../providers/folder.provider';
import { NodesTreeView, RenderTree } from '../../shared/components/node-tree';
import { SimpleList, SimpleListItem } from '../../shared/components/simple-list';
import { SimpleModal } from '../../shared/components/simple-modal';
import { Account, Folder } from '../../shared/domain';

export interface StoredTreeSelection {
    nodeTree: RenderTree;
    folders: Folder[];
    processedFolders: string[];
    deniedFolders: string[];
    toggledFolders: string[];
}
const styles = (theme: Theme) => createStyles({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    treePaper: {
        width: '100%',
        height: '100%',
        minHeight: '40em',
        maxHeight: '50em',
        overflowY: 'scroll'
    },
    treeContainer: {
        background: '#fff',
        padding: '1em',
        height: '100%',
        minHeight: '40em',
        maxHeight: '50em',
    },
    buttonModal: {
        margin: '1em',
    },
    list: {
        flex: '1 0',
        overflow: 'auto',
        boxShadow: 'none'
    },
    resumePopup: {
        width: '50%',
        borderRadius: 5
    },
    resumePopupHeader: {
        height: '2em',
        backgroundColor: theme.palette.primary.main,
        borderRadius: '3px 3px 0 0',
        color: 'white',
        padding: '0.5em',
        fontSize: '1.5em'
    },
    popupContentContainer :{
        display: 'flex',
        flexDirection: 'row',
        padding: theme.spacing(1),
    },
    heading: {
        fontWeight: 700,
        marginBottom: '0.5em',
        marginTop: '1em'
    },
    ellipsis: {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden'
    },
    listModal: {
        padding: '0 1em',
        position: 'relative'
    },
    addButton: {
        width: '100%',
        margin: '0'
    },
    finishButton: {
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: '2%',
        width: '95%',
        margin: 'auto',
    },
    closeBtn: {
        position: 'absolute',
        zIndex: 999999,
        right: 0,
        top: 0,
        color: 'white',
    }
});

interface Props extends WithStyles<typeof styles> {
    currentAccount: Account;
    previousSelection?: StoredTreeSelection;
    onFolderPick?: (folderPath: string, currentSelection: StoredTreeSelection) => void;
}

interface State {
    isModalOpen: boolean,
    currentNodeTree: RenderTree;
    treeIsLoading: boolean;
    pickedType: PickedType;
    pickedId: string;
    pickedName: string;
    folders: Folder[];
    processedFolders: string[];
    deniedFolders: string[];
    toggledFolders: string[];
    folderPath: string;
    treeScrollPosition: number;
}

class FolderPositionPicker extends React.Component<Props, State> {

    state: State = {
        isModalOpen: false,
        currentNodeTree: {id: '', name:'', children:[], path: ''},
        treeIsLoading: false,
        pickedType: PickedType.None,
        pickedId: '',
        pickedName: '',
        folders: [],
        processedFolders: [],
        deniedFolders: [],
        toggledFolders: [],
        folderPath: '',
        treeScrollPosition: 0
    };

    accountProvider = new AccountProvider();
    folderProvider = new FolderProvider();

    componentDidMount() {
        const { previousSelection } = this.props;
        if (previousSelection) {
            this.setState({
                currentNodeTree: previousSelection.nodeTree,
                folders: previousSelection.folders,
                processedFolders: previousSelection.processedFolders,
                deniedFolders: previousSelection.deniedFolders,
                toggledFolders: previousSelection.toggledFolders,
                pickedType: PickedType.None,
                pickedId: '',
                folderPath: ''
            })
            return;
        }
        this.fetchFoldersFromAccount();
    };

    fetchFoldersFromAccount = () => {
        const { currentAccount } = this.props;
        this.setState({
            pickedType: PickedType.None,
            pickedId: '',
            processedFolders: [],
            folderPath: ''
        });
        return this.accountProvider.getRootFoldersByAccountId(currentAccount.accountId)
            .then((results) => {
                const folders: Folder[] = results;
                if(!folders) {
                    return;
                }

                // TRANSFORM THE FOLDERS INTO TREE VIEW
                const accountTree: RenderTree = {
                    id: currentAccount.accountId,
                    name: currentAccount.name,
                    children: [],
                    path: ''
                };

                const treeNodes: RenderTree[] = folders.map(element => {
                    const newItem = {
                        id: element.id,
                        name: element.name,
                        children: [],
                        path: `${element.id}__`
                    }
                    return newItem as RenderTree;
                });

                accountTree.children = treeNodes;

                this.setState({
                    folders,
                    currentNodeTree: accountTree
                });

        }).catch((error) => {
            // tslint:disable-next-line:no-console
            console.error(error);
        });

    };

    deepTreeSearch = (treeData: RenderTree, searchId: string) => {
        let foundTree: RenderTree | undefined;
        let searchLevel: RenderTree[] | undefined = treeData.children;
        do {
            foundTree = searchLevel?.find((e: RenderTree) => e.id === searchId);
            let tempLevel: any = [];
            searchLevel?.forEach((element: RenderTree) => {
                tempLevel = tempLevel.concat(element.children);
            });
            searchLevel = tempLevel;
        } while(!foundTree);
        return foundTree;
    };

    updateChildrenOnPath = (treeData: RenderTree, nodeId: string, children: RenderTree[]) => {
        treeData.children!.forEach(element => {
            if (_.isEqual(element.id, nodeId)) {
                element.children = children;
            } else {
                this.updateChildrenOnPath(element, nodeId, children);
            }
        });
    };

    onNodeSelected = (treeData: RenderTree , nodeId: string, nodeLabel: string, updateCallback: (nodeIds: string[]) => void) => {
        const { folders, processedFolders, deniedFolders } = this.state;
        const { currentAccount } = this.props;
        // WE ALLOW PICKING THE ROOT ACCOUNT
        if(currentAccount!.accountId === nodeId) {
            this.setState({
                pickedType: PickedType.Folder,
                pickedId: '__',
                pickedName: 'Root',
                treeIsLoading: false,
                folderPath: '__'
            });
            return;
        }
        this.setState({
            pickedType: PickedType.None,
            pickedId: '',
            pickedName: '',
            treeIsLoading: true,
            folderPath: ''
        });
        // CHECK PICK TYPE
        // FOLDER
        const currentNodePos = this.deepTreeSearch(treeData, nodeId);
        if(folders.find(e => e.id === nodeId)) {
            // DID WE ALREADY CHECK FOR CHILDREN?
            if (processedFolders.find(e => e === nodeId)) {
                // IS IT VALID?
                // FLOORPLAN CHILDREN
                if (deniedFolders.find(e => e === nodeId)) {
                    this.setState({treeIsLoading: false});
                    return;
                }
                // FOLDER CHILDREN
                // WE CAN THIS KIND OF FOLDERS TO BE SET AS LOCATION
                this.setState({
                    pickedType: PickedType.Folder,
                    pickedId: nodeId,
                    pickedName: currentNodePos.name,
                    currentNodeTree: treeData,
                    treeIsLoading: false,
                    processedFolders,
                    folderPath: currentNodePos.path
                });
                return;
            }
            // EMPTY
            // CHECK FOR FOLDER CHILDREN FIRST
            return this.accountProvider.getChildrenFolderByFolderId(currentAccount!.accountId, nodeId)
                .then((results: Folder[]) => {
                    if (results.length > 0) {
                        const nodes: RenderTree[] = results.map(element => {
                            const newItem = {
                                id: element.id,
                                name: element.name,
                                children: [],
                                path: `${currentNodePos.path}${element.id}__`
                            }
                            return newItem as RenderTree;
                        });
                        this.updateChildrenOnPath(treeData, nodeId, nodes);
                        const currentPath = currentNodePos.path.slice(0, -2);
                        updateCallback([currentAccount!.accountId, ...currentPath.split('__'), ...nodes.map(e => e.id)]);
                        processedFolders.push(nodeId);
                        // WE GOT CHILDREN FOLDER WE CAN USE THIS AS LOCATION
                        this.setState({
                            pickedType: PickedType.Folder,
                            pickedId: nodeId,
                            pickedName: currentNodePos.name,
                            currentNodeTree: treeData,
                            treeIsLoading: false,
                            processedFolders,
                            folders: folders.concat(results),
                            folderPath: currentNodePos.path
                        });
                        return;
                    } else {
                        return this.folderProvider.fetchFloorplans(nodeId)
                            .then((floorplans) => {
                                processedFolders.push(nodeId);
                                if (floorplans.length > 0) {
                                    deniedFolders.push(nodeId);
                                    this.setState({
                                        pickedType: PickedType.None,
                                        pickedId: '',
                                        pickedName: '',
                                        currentNodeTree: treeData,
                                        treeIsLoading: false,
                                        folderPath: '',
                                        processedFolders
                                    });
                                    return;
                                }
                                // THIS FOLDER DOESN'T HAVE FLOORPLANS IS VALID LOCATION
                                this.setState({
                                    pickedType: PickedType.Folder,
                                    pickedId: nodeId,
                                    pickedName: currentNodePos.name,
                                    currentNodeTree: treeData,
                                    treeIsLoading: false,
                                    processedFolders,
                                    folderPath: currentNodePos.path
                                });
                                return
                        }).catch((error) => {
                            // tslint:disable-next-line:no-console
                            console.error(error);
                        });
                    }
            }).catch((error) => {
                // tslint:disable-next-line:no-console
                console.error(error);
            });
        }
        this.setState({
            pickedType: PickedType.None,
            pickedId: '',
            pickedName: '',
            currentNodeTree: treeData,
            treeIsLoading: false,
            folderPath: currentNodePos.path
        });
        return;
    }

    onNodeToggled = (nodeIds: string[]) => {
        this.setState({toggledFolders: nodeIds});
    };

    onModalToggle = () => {
        const { isModalOpen } = this.state;

        this.setState({isModalOpen: !isModalOpen});
    };

    pickFolderLocation = () => {
        const {
            folderPath,
            folders,
            currentNodeTree,
            deniedFolders,
            processedFolders,
        } = this.state;
        const { onFolderPick, currentAccount } = this.props;

        if (!folderPath || !onFolderPick || folderPath === '') {
            return;
        }
        
        let finalPath = folderPath.split("__").join(".");
        finalPath = finalPath.slice(0, -1);

        const toggledFolders = [currentAccount.accountId].concat(folderPath.split("__"));
        this.setState({isModalOpen: false, toggledFolders});
        const storeSelection: StoredTreeSelection = {
            nodeTree: currentNodeTree,
            folders,
            deniedFolders,
            processedFolders,
            toggledFolders
        }
        onFolderPick(finalPath, storeSelection);
    };

    centerScroll = (evt: React.UIEvent<HTMLElement>) => {
        this.setState({
            treeScrollPosition: evt.currentTarget.scrollTop
        })
    };

    public render() {
        const {
            classes
        } = this.props;
        const {
            isModalOpen,
            currentNodeTree,
            treeIsLoading,
            pickedId,
            pickedName,
            pickedType,
            toggledFolders,
            treeScrollPosition
        } = this.state;

        const folderList = (pickedId && pickedType === PickedType.Folder) ? [new SimpleListItem(pickedId, `Below: ${pickedName}`, pickedId)] : [];
        return (
            <div className={`${classes.root}`} data-testid="folder-position-picker">
                <div className={`${classes.container}`}>
                    <SimpleList
                        className={classes.list}
                        items={folderList}
                        noItemsLabel={"No position selected "}
                        canDeleteListItem={false}
                        keepItemSelected={false}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        className={classes.buttonModal}
                        onClick={this.onModalToggle}
                    >
                        Select folder position
                    </Button>
                    <SimpleModal
                        className={classes.resumePopup}
                        open={isModalOpen}
                        contentClasses={classes.popupContentContainer}
                        buttonOkLabel=""
                        buttonCancelLabel=""
                        header='Folder selection'
                        headerClassName={classes.resumePopupHeader}
                    >
                        <IconButton className={classes.closeBtn} onClick={this.onModalToggle}>
                            <CloseOutlined />
                        </IconButton>
                        <Grid container spacing={1} alignItems="flex-start" data-testid="folder-position-picker-modal">
                            <Grid item xs={12}>
                                <Alert severity="info">
                                    Keep in mind that you CANNOT add a folder on a location that already contains floorplans
                                </Alert>
                                <Paper
                                    className={classes.treePaper}
                                    onScrollCapture={this.centerScroll}
                                >
                                    <NodesTreeView
                                        treeData={currentNodeTree}
                                        isLoading={treeIsLoading}
                                        nodesExpanded={toggledFolders}
                                        onNodeSelected={this.onNodeSelected}
                                        onNodeToggled={this.onNodeToggled}
                                        className={classes.treeContainer}
                                        loadingOverlayPosition={treeScrollPosition}
                                    />
                                </Paper>
                                {
                                    (pickedId && pickedType === PickedType.Folder) &&
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            className={classes.addButton}
                                            onClick={this.pickFolderLocation}
                                        >
                                            Pick Location
                                        </Button>
                                }
                            </Grid>
                        </Grid>
                    </SimpleModal>
                </div>
            </div>
        );
    }
}

const MUIComponent = withStyles(styles)(FolderPositionPicker);
export {MUIComponent as FolderPositionPicker}
