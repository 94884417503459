import { Typography } from '@material-ui/core';
import {
  createStyles,
  WithStyles,
  withStyles
} from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import {
  inject,
  observer
} from 'mobx-react';
import * as React from 'react';
import {
  LoginWizard,
  LoginWizardSteps
} from '../../shared/components/login-wizard';
import { CAPTCHA_SITE_KEY } from '../../shared/constants';
import { versionHelper } from '../../shared/utils';
import { RootStore } from '../../stores/root.store';

const styles = (theme: Theme) => createStyles({
    root: {
        backgroundColor: '#f9f9f9',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: '2.24em'
    },
    ccLogo: {
        height: '7.2em',
        width: '12.15em',
        marginBottom: '1.3em'
    },
    subtitle: {
      fontSize: "0.8em",
      fontWeight: "bold",
      textAlign: "right",
      width: "75%"
    },
  });

interface Props extends WithStyles<typeof styles> {
    rootStore: RootStore;
}

interface States {
  email: string;
  step: LoginWizardSteps;
}

@inject('rootStore')
@observer
class Login extends React.Component<Props, States> {

    public static defaultProps: Partial<Props> = {
    };

    state = {
        email: '',
        step: LoginWizardSteps.Login
    };

    onCheckEmailClicked = (email: string) => {
        this.setState({
            email,
            step: LoginWizardSteps.Password
        });
    };

    onLoginClicked = (password: string, captcha: string) => {
        const { email } = this.state;

        const {
            rootStore: { adminStore }
        } = this.props;

        adminStore.login(email, password, captcha);
    };

    checkCaptchaRequired = () => {
      const {
          rootStore: { adminStore }
      } = this.props;
      const currentApiUrl = process.env.REACT_APP_ADMIN_TOOL_API;
      if(
        currentApiUrl?.includes('localhost') ||
        currentApiUrl?.includes(':3300') ||
        currentApiUrl?.includes('qa-comfort')
      ) {
        return false;
      }
      return adminStore.isAlternateLoginRequired;
    }

    public render() {
        const {
          classes
        } = this.props;
        const {
          email,
          step
        } = this.state;

        const {
          rootStore
        } = this.props;
        const {
            routerStore,
            adminStore: {
              isLoginLoading,
              loginError
            }
        } = rootStore;
        const {
            params: { accessCode }
        } = routerStore.routerState;
        const captchaSiteKey = CAPTCHA_SITE_KEY;

    return (
      <div className={classes.root}>
        <div className={`${classes.ccLogo} cc-logo`} />
        <LoginWizard
          title="Log in to AdminTool"
          email={email}
          accessCode={accessCode}
          loading={isLoginLoading}
          error={loginError}
          step={step}
          captchaEnabled={this.checkCaptchaRequired()}
          captchaSiteKey={captchaSiteKey}
          onCheckEmail={this.onCheckEmailClicked}
          onLogin={this.onLoginClicked}
        />
        <Typography className={classes.subtitle} variant="h6"  data-testid="versionText">
          V{versionHelper.getVersionNumer()}
        </Typography>
      </div>
    );
  }
}

const MUIComponent = withStyles(styles)(Login);
export {MUIComponent as Login};
