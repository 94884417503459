import * as React from 'react';

import { Button, Typography } from '@material-ui/core';
import {
    createStyles,
    WithStyles,
    withStyles
} from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import memoize from 'memoize-one';
import { SimpleList, SimpleListItem } from '../../shared/components/simple-list';
import { AnalyticsTab } from '../../shared/domain';

const styles = (theme: Theme) => createStyles({
    [theme.breakpoints.down('lg')]: {
        headerContainer: {
            margin: '0.5em 0 1.5em 0 !important'
        }
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        marginBottom: '0.44em',
        maxWidth: '30em'
    },
    title: {
        maxWidth: '18em',
        alignSelf: 'center'
    },
    headerContainer: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: '0.5em',
        width: '100%'
    },
    button: {
        alignSelf: 'flex-end',
        margin: 'auto 0 auto auto'
    },
    list: {
        flex: '1 0',
        overflow: 'auto',
        width: '100%'
    }
});

interface Props extends WithStyles<typeof styles> {
    className?: string;
    disabled: boolean;
    headerText: string;
    isLoading: boolean;
    noItemsLabel: string;
    tabs: AnalyticsTab[];
    visible: boolean;
    onAddTabClick?: () => void;
    onClick?:  (analyticsTab: AnalyticsTab) => void;
    onDelete?: (analyticsTab: AnalyticsTab) => void;
};

// tslint:disable-next-line: no-empty-interface
interface States {
}

class AnalyticsTabList extends React.Component<Props, States> {
    public static defaultProps = {
        disabled: false,
        isLoading: false,
        headerText: '',
        noItemsLabel: '',
        visible: true
    };

    state = {
    };

    getAnalyticsTabItems = memoize((analyticsTabs: AnalyticsTab[] | undefined) => {
        return analyticsTabs ? analyticsTabs.map((analyticsTab: AnalyticsTab) => {
            return new SimpleListItem(analyticsTab.id, analyticsTab.name, analyticsTab.url);
        }) : [];
    });

    getTabFromId = (tabId: string): AnalyticsTab | undefined => {
        if (!tabId){
            return undefined;
        }
    
        const { tabs } = this.props;

        return tabs.find((value: AnalyticsTab) => {
            return tabId === value.id;
        });
    }

    onAddTabClicked = () => {
        const { onAddTabClick } =this.props;
        if (onAddTabClick) {
            onAddTabClick();
        }
    }

    onItemClicked = (listItem: SimpleListItem) => {
        const { onClick } = this.props;

        if (!onClick) {
            return;
        }

        const analyticsTab = this.getTabFromId(listItem.id);

        if (analyticsTab) {
            onClick(analyticsTab);
        }
    }

    onDeleteItemClicked = (listItem: SimpleListItem) => {
        const { onDelete } = this.props;

        if (!onDelete) {
            return;
        }

        const analyticsTab = this.getTabFromId(listItem.id);

        if (analyticsTab) {
            onDelete(analyticsTab);
        }
    }

    public render() {
        const {
            className,
            classes,
            disabled,
            headerText,
            isLoading,
            noItemsLabel,
            tabs,
            visible
        } = this.props;
        
        if (!visible) {
            return ('');
        }

        const items = this.getAnalyticsTabItems(tabs);
        // TODO: Remove this limit when we decide to enable a number of tabs grater than 1
        const isTabCountOverLimit = !!tabs && (tabs.length > 30);

        return (
            <div className={`${className ? `${className} `: ''}${classes.container}`}>
                <div className={classes.headerContainer}>
                    <Typography
                        className={classes.title}
                        variant='h6'
                    >
                        {headerText}
                    </Typography>
                    <Button
                        className={classes.button}
                        disabled={disabled || isLoading || isTabCountOverLimit}
                        color="primary"
                        variant="contained"
                        onClick={this.onAddTabClicked}
                    >
                        Add Tab
                    </Button>
                </div>
                <SimpleList
                    className={classes.list}
                    isLoading={isLoading}
                    items={items}
                    noItemsLabel={noItemsLabel}
                    disabled={disabled}
                    canDeleteListItem={true}
                    onListItemClick={this.onItemClicked}
                    onDeleteItemClick={this.onDeleteItemClicked}
                />
            </div>
        );
    }
};

const MUIComponent = withStyles(styles)(AnalyticsTabList);
export { MUIComponent as AnalyticsTabList };
