import { UserModule } from '../models/user-module';
import { HttpConnectorAdapter } from './../adapters/http-connector-adapter';

export default class UserModuleProvider {
    private endpointAdmin: string;

    constructor() {
        this.endpointAdmin = `${process.env.REACT_APP_ADMIN_TOOL_API}user_module`;
    }

    init = () => {
        return HttpConnectorAdapter;
    };

    fetchUserModules = () => {
        return this.init()
            .getWithCredentials(`${this.endpointAdmin}/`)
            .then(response => {
                const statusCode = response.status;
                const modules = response.data as UserModule[];

                return { status: statusCode, modules: modules };
            });
    };

    fetchUserModule = (moduleId: string) => {
        return this.init()
        .getWithCredentials(`${this.endpointAdmin}/${moduleId}`)
        .then(response => {
            const statusCode = response.status;
            const module = response.data as UserModule;

            return { status: statusCode, module: module };
        });
    }

    createUserModule = (UserModule: UserModule) => {
        const newUserModule = {...UserModule} as any;
        delete newUserModule['timestamp'];
        const headers = { 'Content-Type': 'application/json' };
        return this.init().postWithCredentials(`${this.endpointAdmin}/`, JSON.stringify(newUserModule), headers).then(response => {
            const UserModule = response.data as UserModule;
            const statusCode = response.status;

            return { status: statusCode, UserModule };
        });
    }
}
