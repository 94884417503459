import * as React from 'react';
import * as ReactDOM from 'react-dom';
import './shared/components/index.css';
// tslint:disable-next-line:ordered-imports
import 'react-app-polyfill/ie11'; // The polyfills should always come after importing react
import 'string-polyfills';
import App from './App';
import './index.css';
import BugsnagProvider from './providers/bugsnag.provider';

const bugsnagProvider = new BugsnagProvider();

bugsnagProvider.startBugsnag();
const ErrorWrapper = bugsnagProvider.createErrorWrapper();

ReactDOM.render(
  <ErrorWrapper>
    <App />
  </ErrorWrapper>,
  document.getElementById('root') as HTMLElement
);