import { MarkersListItem } from "../components/export-markers-button";
import { FloorPlanStatusResponseResultsItem } from "../models/floor-plan-responses";

export const convertMarkersResultIntoMarkerList = (markersResult: FloorPlanStatusResponseResultsItem[]): MarkersListItem[] => {
    if (markersResult.length < 1) {
        return [];
    }

    return markersResult.map((marker: FloorPlanStatusResponseResultsItem) => {
        const { 
            markerId: id,
            markerName: name
        } = marker;
        return { id, name } as MarkersListItem;
    });
};
