import * as React from 'react';
import { WithStyles } from '@material-ui/styles';
import {
    Button,
    Card,
    CardContent,
    CircularProgress,
    Grid,
    Step,
    StepLabel,
    Stepper,
    Theme,
    Typography,
    createStyles,
    withStyles
} from '@material-ui/core';
import { FloorplanFull } from '../../models';
import FloorplanProvider, {
    PDF2JPGConvertion
} from '../../providers/floorplan.provider';
import { SimpleListPagination } from '../../shared/components/simple-list-pagination';
import { SimpleListItem } from '../../shared/components/simple-list';
import { Alert } from '@material-ui/lab';
import { XFDFMarker } from '../../features/marker-operations/marker-operations';
import { MarkerOperation, ThinClient } from '../thin-client/thin-client';
import JobProvider, {
    JobRequest,
    JobTypes,
    RunningTasks
} from '../../providers/job.provider';
import { UserIdentifiers } from '../../providers/user.provider';
import { Account } from '../../shared/domain';
import { CheckCircleOutline } from '@material-ui/icons';

export interface CanvasMarker {
    id: string;
    room_type: string;
    name: string;
    coordinates: string;
    xfdf_coordinates: string;
    final_coordinates: number[];
    fits: boolean;
}

enum WizardSteps {
    NewSelection = 0,
    FloorplanEdit,
    Confirmation,
    Result
}

const ENABLED_EVENTS = [
    'ANNOTATION_ADDED',
    'ANNOTATION_DELETED',
    'ANNOTATION_UPDATED'
];

const WIZARD_STEPS = [
    {
        id: WizardSteps.NewSelection,
        label: 'Replacement selection'
    },
    {
        id: WizardSteps.FloorplanEdit,
        label: 'Marker Corrections'
    },
    {
        id: WizardSteps.Confirmation,
        label: 'Confirm changes'
    },
    {
        id: WizardSteps.Result,
        label: 'Results'
    }
];

const styles = (theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexWrap: 'wrap',
            maxHeight: '60em',
            overflow: 'auto',
            [theme.breakpoints.down('lg')]: {
                maxHeight: '45em'
            }
        },
        container: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column'
        },
        transferButton: {
            padding: '0.5em 0',
            margin: '1em 0'
        },
        stepsContentHolder: {
            padding: '1em'
        },
        stepsHolder: {
            paddingBottom: 0
        },
        heading: {
            fontWeight: 700,
            marginBottom: '0.5em',
            marginTop: '0.5em'
        },
        ellipsis: {
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden'
        },
        simpleList: {
            overflow: 'auto',
            width: '95%',
            height: '100%',
            marginRight: '1em'
        },
        animContainer: {
            transition: 'width 1s'
        },
        flexRow: {
            display: 'flex',
            flexDirection: 'row',
            marginTop: '1em'
        },
        relativeRow: {
            position: 'relative'
        },
        continueButton: {
            marginLeft: '1%',
            float: 'right',
            minWidth: '10em'
        },
        replacementInfoAlert: {
            width: '75%',
            marginLeft: '5%'
        },
        markerHoverDisplay: {
            position: 'absolute',
            border: '2px solid',
            fontWeight: 'bold',
            padding: '0.5em 1.5em',
            borderRadius: '5px',
            marginLeft: 'auto',
            marginRight: 'auto',
            left: 0,
            right: 0,
            marginTop: '1.5em',
            maxWidth: 'max-content'
        },
        replacementCanvas: {
            border: '2px solid red',
            borderRadius: '1em',
            display: 'block',
            margin: 'auto',
            marginTop: '1.5em'
        },
        thinClientContainer: {
            height: '50em',
            marginBottom: '3em',
            transition: 'width 1s',
            [theme.breakpoints.down('lg')]: {
                maxHeight: '35em'
            }
        },
        backButton: {
            marginLeft: '1%',
            position: 'absolute',
            bottom: '1em'
        },
        nextButton: {
            position: 'absolute',
            bottom: '1em',
            right: '1em'
        },
        confirmationContainer: {
            height: '50em',
            [theme.breakpoints.down('lg')]: {
                maxHeight: '35em'
            }
        },
        successColor: {
            color: '#2dd36f'
        },
        statusCard: {
            maxWidth: 'max-content',
            margin: 'auto'
        },
        statusDisplay: {
            margin: '1em'
        }
    });

interface Props extends WithStyles<typeof styles> {
    className?: string;
    currentAccount: Account | null;
    userIdentifiers: UserIdentifiers;
    originalFloorplan: FloorplanFull;
    floorplanProvider: FloorplanProvider;
    jobProvider: JobProvider;
    onReplacementFinished: (tasks: RunningTasks[]) => void;
    onAuthError?: () => void;
}

interface States {
    currentStage: WizardSteps;
    originalMarkerList: CanvasMarker[];
    isMarkersLoading: boolean;
    newPDFFile: File | null;
    newPDFImage: string | null;
    hoveringMarker: CanvasMarker | null;
    thinClientMarkerList: XFDFMarker[];
    fileBlob: Blob | null;
    correctionMarkerOperations: MarkerOperation[];
    displayImage: HTMLImageElement | null;
    replacementBlob: Blob | null;
    candidateDimensions: PDF2JPGConvertion | null;
    warningText: string | undefined;
    isPDFUploading: boolean;
    isMarkerjobsUploading: boolean;
    returnJobs: RunningTasks[];
    isErrorModal: boolean;
    errorModalTitle: string;
    errorModalDesc: string;
}

class FloorplanReplaceWizard extends React.Component<Props, States> {
    state: States = {
        currentStage: WizardSteps.NewSelection,
        originalMarkerList: [],
        isMarkersLoading: false,
        newPDFFile: null,
        newPDFImage: null,
        hoveringMarker: null,
        thinClientMarkerList: [],
        fileBlob: null,
        correctionMarkerOperations: [],
        displayImage: null,
        replacementBlob: null,
        candidateDimensions: null,
        warningText: undefined,
        isPDFUploading: false,
        isMarkerjobsUploading: false,
        returnJobs: [],
        isErrorModal: false,
        errorModalTitle: '',
        errorModalDesc: ''
    };

    canvasRef: React.RefObject<any> = React.createRef();

    componentDidMount() {
        this.getOriginalMarkerList();
    }

    getOriginalMarkerList = () => {
        const { floorplanProvider, originalFloorplan } = this.props;
        this.setState({
            isMarkersLoading: true
        });
        floorplanProvider
            .fetchMarkersInCanvas(originalFloorplan.floorplan_id)
            .then(data => {
                const originalMarkerList = data.markers.map(
                    (e: CanvasMarker) => {
                        const markerItem = { ...e } as CanvasMarker;
                        markerItem.fits = true;
                        markerItem.final_coordinates = [0, 0];
                        return markerItem;
                    }
                );
                this.setState({
                    originalMarkerList
                });
            })
            .catch(error => {
                console.error(error);
                const { status } = error.response;
                if (status === 401) {
                    const { onAuthError } = this.props;
                    if (!onAuthError) {
                        return;
                    }
                    onAuthError();
                    return;
                }
                this.setState({
                    isErrorModal: true,
                    errorModalTitle: 'Error retrieving floorplan markers',
                    errorModalDesc: error.message
                });
            })
            .finally(() => {
                this.setState({
                    isMarkersLoading: false
                });
            });
    };

    onFileChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { floorplanProvider, originalFloorplan } = this.props;
        const { originalMarkerList } = this.state;
        const inputFiles = event.target.files;
        if (inputFiles && inputFiles.length > 0) {
            const pdfFile = inputFiles[0];
            floorplanProvider
                .convertPDF2JPG(originalFloorplan.floorplan_id, pdfFile)
                .then(data => {
                    this.setState({
                        newPDFFile: pdfFile,
                        candidateDimensions: data.image,
                        originalMarkerList: originalMarkerList.map(e => {
                            e.fits = true;
                            return e;
                        })
                    });
                    this.paintImageWithMarkers(data.image.image);
                })
                .catch(error => {
                    console.error(error);
                });
        }
    };

    paintImageWithMarkers = (image: string) => {
        const { originalMarkerList } = this.state;
        const canvas: HTMLCanvasElement = this.canvasRef.current;
        const ctx = canvas.getContext('2d');

        if (!ctx) {
            return;
        }

        const paintImage = new Image();
        paintImage.src = `data:image/png;base64,${image}`;

        paintImage.onload = () => {
            canvas.width = paintImage.width;
            canvas.height = paintImage.height;

            ctx.drawImage(paintImage, 0, 0);

            let verticalPadding = 12;
            originalMarkerList.forEach(marker => {
                const coords = marker.coordinates.split(',');
                const mx = parseFloat(coords[0]);
                const my = parseFloat(coords[1]);

                if (mx < paintImage.width && my < paintImage.height) {
                    ctx.fillStyle = 'green';
                    ctx.beginPath();
                    ctx.arc(mx, my, 10, 0, 2 * Math.PI);
                    ctx.fill();
                    marker.final_coordinates = [mx, my];
                } else {
                    ctx.fillStyle = 'red';
                    ctx.beginPath();
                    ctx.arc(
                        paintImage.width - 12,
                        verticalPadding,
                        10,
                        0,
                        2 * Math.PI
                    );
                    ctx.fill();
                    marker.fits = false;
                    marker.final_coordinates = [
                        paintImage.width - 12,
                        verticalPadding
                    ];
                    verticalPadding += 23;
                }
            });

            this.setState({
                originalMarkerList,
                newPDFImage: `data:image/jpeg;base64,${image}`,
                displayImage: paintImage
            });
        };
    };

    handleMouseMove = (event: React.MouseEvent<HTMLCanvasElement>) => {
        const { originalMarkerList } = this.state;
        const canvas = this.canvasRef.current;
        const rect = canvas.getBoundingClientRect();
        const x = event.clientX - rect.left;
        const y = event.clientY - rect.top;

        const hoveringMarker = originalMarkerList.find(marker => {
            const dx = x - marker.final_coordinates[0];
            const dy = y - marker.final_coordinates[1];
            return dx * dx + dy * dy < 45;
        });

        this.setState({
            hoveringMarker: hoveringMarker ? hoveringMarker : null
        });
    };

    handleMouseOut = () => {
        this.setState({ hoveringMarker: null });
    };

    fileToBlob = async (file: File) => {
        const arrayBuffet = await file.arrayBuffer();
        return new Blob([arrayBuffet], { type: file.type });
    };

    openThinClient = () => {
        const {
            originalMarkerList,
            newPDFFile,
            candidateDimensions
        } = this.state;

        if (!newPDFFile || !candidateDimensions) {
            return;
        }

        const boundingBox = candidateDimensions.dimensions.cropbox;
        const thinClientMarkerList = originalMarkerList.map(e => {
            const xfdf_marker: XFDFMarker = {
                id: e.id,
                room_type: e.room_type,
                name: e.name,
                coordinates: this.translateCanvas2PDFCoords(
                    boundingBox,
                    e.final_coordinates,
                    e.fits
                ),
                isWrong: !e.fits
            };
            return xfdf_marker;
        });
        let warningText: string | undefined;
        const notFittingCount = originalMarkerList.filter(e => !e.fits).length;
        if (notFittingCount > 0) {
            warningText = `There are ${notFittingCount} markers that didn't fit, they have been placed on random locations and have been highlighted in BRIGHT BLUE please adjust accordingly to save changes`;
        }
        this.fileToBlob(newPDFFile).then(blob => {
            this.setState({
                currentStage: WizardSteps.FloorplanEdit,
                thinClientMarkerList,
                fileBlob: blob,
                warningText
            });
        });
    };

    translateCanvas2PDFCoords = (
        boundingBox: number[],
        markerCanvasCoords: number[],
        fits: boolean
    ) => {
        // THIS FUNCTION TRANSLATE CANVAS COORDS BACK TO XFDF
        // 24 IS THE SIZE OF THE ANNOTATION ICON
        const height = boundingBox[3] - boundingBox[1];
        let newX, newY;
        if (fits) {
            newX = markerCanvasCoords[0] + boundingBox[0];
            newY = height - markerCanvasCoords[1];
        } else {
            // GENERATE RANDOM COORDINATES IF MARKER DOESN'T FIT
            newX =
                Math.random() * (boundingBox[2] - boundingBox[0]) +
                boundingBox[0];
            newY =
                Math.random() * (boundingBox[3] - boundingBox[1]) +
                boundingBox[1];
        }
        return `${newX},${newY},${newX + 24},${newY + 24}`;
    };

    onFinishMarkerOperations = (
        markerOperations: MarkerOperation[],
        updatedFileBlob: Blob
    ) => {
        this.setState({
            correctionMarkerOperations: markerOperations,
            currentStage: WizardSteps.Confirmation,
            replacementBlob: updatedFileBlob
        });
    };

    goBackButton = () => {
        const { currentStage } = this.state;
        switch (currentStage) {
            case WizardSteps.FloorplanEdit:
                this.setState({
                    currentStage: WizardSteps.NewSelection,
                    newPDFFile: null,
                    newPDFImage: null
                });
                break;
            case WizardSteps.Confirmation:
                this.setState({ currentStage: WizardSteps.FloorplanEdit });
                break;
        }
    };

    onFinishProgress = () => {
        const { originalFloorplan } = this.props;
        const { replacementBlob } = this.state;

        if (!replacementBlob) {
            return;
        }

        this.setState(
            {
                currentStage: WizardSteps.Result,
                isMarkerjobsUploading: true,
                isPDFUploading: true
            },
            () => {
                this.sendFloorplanPayload(
                    originalFloorplan.floorplan_id,
                    replacementBlob
                );
            }
        );
    };

    sendFloorplanPayload = (floorplanId: string, updatedFileBlob: Blob) => {
        const { floorplanProvider } = this.props;

        floorplanProvider
            .uploadFloorplanPDF(floorplanId, updatedFileBlob)
            .then(data => {
                const { correctionMarkerOperations } = this.state;

                if (
                    correctionMarkerOperations &&
                    correctionMarkerOperations.length > 0
                ) {
                    this.sendMarkerPayloads(correctionMarkerOperations);
                }
                this.setState({
                    isPDFUploading: false,
                    isMarkerjobsUploading: false,
                    replacementBlob: null,
                    newPDFFile: null,
                    newPDFImage: null,
                    fileBlob: null,
                    displayImage: null,
                    candidateDimensions: null
                });
            })
            .catch(error => {
                console.error(error);
                const { status } = error.response;
                if (status === 401) {
                    const { onAuthError } = this.props;
                    if (!onAuthError) {
                        return;
                    }
                    onAuthError();
                    return;
                }
                this.setState({
                    isErrorModal: true,
                    errorModalTitle: 'Error uploading replacement floorplan',
                    errorModalDesc: error.message
                });
            });
    };

    sendMarkerPayloads = (markerPayloads: MarkerOperation[]) => {
        this.setState({ isMarkerjobsUploading: true });
        const { userIdentifiers, currentAccount, jobProvider } = this.props;
        let processed = 0;
        markerPayloads.forEach(m => {
            const { returnJobs } = this.state;
            const payloadItem: JobRequest = {
                user_created_id: userIdentifiers.user_id,
                account_id: currentAccount ? currentAccount.accountId : userIdentifiers.account_id,
                job_type: `${JobTypes.MarkerOperations}`,
                additional_params: { ...m }
            };

            jobProvider
                .scheduleJob(payloadItem)
                .then(result => {
                    const newTask: RunningTasks = {
                        account: currentAccount,
                        export_name: `${m.verb}: ${m.name}`,
                        export_id: m.marker_id,
                        type: JobTypes.MarkerOperations,
                        task_id: result.job_id,
                        status: 102,
                        startedAt: Date.now(),
                        elapsedTime: 0,
                        progress: 0,
                        timer: 0,
                        static_progress_count: 0,
                        set_to_stop: false
                    };

                    returnJobs.push(newTask);
                    this.setState({ returnJobs });
                })
                .catch(error => {
                    console.error(error);
                    const { status } = error.response;
                    if (status === 401) {
                        const { onAuthError } = this.props;
                        if (!onAuthError) {
                            return;
                        }
                        onAuthError();
                        return;
                    }
                    this.setState({
                        isErrorModal: true,
                        errorModalTitle: 'Error creating marker jobs',
                        errorModalDesc: error.message
                    });
                })
                .finally(() => {
                    processed += 1;
                    if (processed === markerPayloads.length) {
                        const { onReplacementFinished } = this.props;
                        this.setState({ isMarkerjobsUploading: false });
                        onReplacementFinished(returnJobs);
                    }
                });
        });
    };

    public render() {
        const { className, classes, originalFloorplan } = this.props;
        const {
            currentStage,
            originalMarkerList,
            hoveringMarker,
            isMarkersLoading,
            newPDFFile,
            newPDFImage,
            fileBlob,
            thinClientMarkerList,
            correctionMarkerOperations,
            displayImage,
            warningText,
            isPDFUploading,
            isMarkerjobsUploading
        } = this.state;

        const markerListItem = originalMarkerList.map(e => {
            return new SimpleListItem(e.id, e.name, e.id);
        });
        const failedCount = originalMarkerList.filter(e => !e.fits).length;
        const failedCheck = failedCount <= 0;
        const failedList = originalMarkerList
            .filter(e => !e.fits)
            .map(e => {
                return new SimpleListItem(e.id, e.name, e.id);
            });
        const correctionList = correctionMarkerOperations.map(e => {
            return new SimpleListItem(
                e.marker_id,
                `${e.verb} ${e.name}`,
                e.marker_id
            );
        });

        return (
            <div
                className={`${classes.root} ${className}`}
                data-testid="marker-module-groups-wizard"
            >
                <div className={`${classes.container}`}>
                    <Stepper
                        activeStep={currentStage}
                        className={classes.stepsHolder}
                        alternativeLabel
                    >
                        {WIZARD_STEPS.map(step => (
                            <Step key={step.id}>
                                <StepLabel>{step.label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                    <div className={classes.stepsContentHolder}>
                        {currentStage === WizardSteps.NewSelection ? (
                            <React.Fragment>
                                <Grid
                                    container
                                    spacing={1}
                                    alignItems="flex-start"
                                >
                                    <Grid item xs={4}>
                                        <Typography
                                            variant="h6"
                                            className={`${classes.heading} ${classes.ellipsis}`}
                                        >
                                            Floorplan markers
                                        </Typography>
                                        <SimpleListPagination
                                            className={classes.simpleList}
                                            keepItemSelected={false}
                                            items={markerListItem}
                                            canDeleteListItem={false}
                                            rowsPerPage={10}
                                            totalItems={
                                                originalMarkerList.length
                                            }
                                            offset={0}
                                            isLoading={isMarkersLoading}
                                            noItemsLabel="No markers found"
                                        />
                                    </Grid>
                                    <Grid container alignItems="center" xs={8}>
                                        <Grid item xs={12}>
                                            <Typography
                                                variant="h6"
                                                className={`${classes.heading} ${classes.ellipsis}`}
                                            >
                                                Select replacement PDF
                                            </Typography>
                                            <input
                                                type="file"
                                                id="markers-path"
                                                className="input-file"
                                                accept=".pdf"
                                                onChange={this.onFileChanged}
                                                disabled={isMarkersLoading}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            className={classes.flexRow}
                                            xs={12}
                                        >
                                            {newPDFFile && newPDFImage && (
                                                <React.Fragment>
                                                    <Alert
                                                        severity={`${
                                                            failedCheck
                                                                ? 'success'
                                                                : 'warning'
                                                        }`}
                                                        className={
                                                            classes.replacementInfoAlert
                                                        }
                                                    >
                                                        {failedCheck
                                                            ? 'The replacement floorplan is compatible.'
                                                            : `There are ${failedCount} markers that don't fit in the replacement PDF, Do you wish to continue?`}
                                                    </Alert>
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        disabled={
                                                            !newPDFFile &&
                                                            !newPDFImage
                                                        }
                                                        className={
                                                            classes.continueButton
                                                        }
                                                        onClick={
                                                            this.openThinClient
                                                        }
                                                    >
                                                        Continue
                                                    </Button>
                                                </React.Fragment>
                                            )}
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            className={classes.relativeRow}
                                        >
                                            {hoveringMarker && (
                                                <div
                                                    style={{
                                                        borderColor: `${
                                                            hoveringMarker.fits
                                                                ? 'green'
                                                                : 'red'
                                                        }`,
                                                        backgroundColor: `${
                                                            hoveringMarker.fits
                                                                ? 'lightgreen'
                                                                : 'orange'
                                                        }`
                                                    }}
                                                    className={
                                                        classes.markerHoverDisplay
                                                    }
                                                >
                                                    {hoveringMarker.name}
                                                </div>
                                            )}
                                            <canvas
                                                ref={this.canvasRef}
                                                id="canvas-pdf"
                                                width={600}
                                                height={500}
                                                className={
                                                    classes.replacementCanvas
                                                }
                                                onMouseMove={
                                                    this.handleMouseMove
                                                }
                                                onMouseOut={this.handleMouseOut}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </React.Fragment>
                        ) : currentStage === WizardSteps.FloorplanEdit ? (
                            <React.Fragment>
                                <Grid
                                    container
                                    spacing={1}
                                    alignItems="flex-start"
                                >
                                    <Grid item xs={failedCheck ? 1 : 3}>
                                        {!failedCheck && (
                                            <React.Fragment>
                                                <Typography
                                                    variant="h6"
                                                    className={`${classes.heading} ${classes.ellipsis}`}
                                                >
                                                    Failed markers
                                                </Typography>
                                                <SimpleListPagination
                                                    className={
                                                        classes.simpleList
                                                    }
                                                    keepItemSelected={false}
                                                    items={failedList}
                                                    canDeleteListItem={false}
                                                    rowsPerPage={10}
                                                    totalItems={failedCount}
                                                    offset={0}
                                                    noItemsLabel="No failed markers"
                                                />
                                            </React.Fragment>
                                        )}
                                    </Grid>
                                    <Grid item xs={failedCheck ? 10 : 8}>
                                        <ThinClient
                                            className={
                                                classes.thinClientContainer
                                            }
                                            floorplanName={`${originalFloorplan.name} corrections`}
                                            floorplanId={
                                                originalFloorplan.floorplan_id
                                            }
                                            fileBlob={fileBlob}
                                            enabledEvents={ENABLED_EVENTS}
                                            existingMarkers={
                                                thinClientMarkerList
                                            }
                                            onFinishMarkerOperations={
                                                this.onFinishMarkerOperations
                                            }
                                            isJSONReady={true}
                                            warningText={warningText}
                                        />
                                    </Grid>
                                    <Grid item xs={1}></Grid>
                                </Grid>
                            </React.Fragment>
                        ) : currentStage === WizardSteps.Confirmation ? (
                            <React.Fragment>
                                <Grid
                                    container
                                    spacing={1}
                                    alignItems="flex-start"
                                    className={classes.confirmationContainer}
                                >
                                    <Grid item xs={1}></Grid>
                                    <Grid item xs={4}>
                                        <Typography
                                            variant="h6"
                                            className={`${classes.heading} ${classes.ellipsis}`}
                                        >
                                            Marker correction operations
                                        </Typography>
                                        <SimpleListPagination
                                            className={classes.simpleList}
                                            keepItemSelected={false}
                                            items={correctionList}
                                            canDeleteListItem={false}
                                            rowsPerPage={10}
                                            totalItems={correctionList.length}
                                            offset={0}
                                            noItemsLabel="No markers corrections done"
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={6}
                                        style={{ paddingLeft: '1em' }}
                                    >
                                        <Typography
                                            variant="h6"
                                            className={`${classes.heading} ${classes.ellipsis}`}
                                        >
                                            New Floorplan image
                                        </Typography>
                                        <br />
                                        {displayImage && (
                                            <img src={displayImage.src} />
                                        )}
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            className={classes.nextButton}
                                            onClick={this.onFinishProgress}
                                        >
                                            Submit replacement
                                        </Button>
                                    </Grid>
                                    <Grid item xs={1}></Grid>
                                </Grid>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <Grid container spacing={1} alignItems="center">
                                    <Grid item xs={1}></Grid>
                                    <Grid item xs={10}>
                                        <Card className={classes.statusCard}>
                                            <CardContent>
                                                <Typography
                                                    variant="h5"
                                                    component="h2"
                                                >
                                                    New Floorplan pdf upload
                                                </Typography>
                                                <Typography
                                                    color="textSecondary"
                                                    className={
                                                        classes.statusDisplay
                                                    }
                                                >
                                                    {isPDFUploading ? (
                                                        <React.Fragment>
                                                            <CircularProgress />{' '}
                                                            Uploading . . .
                                                        </React.Fragment>
                                                    ) : (
                                                        <React.Fragment>
                                                            <CheckCircleOutline
                                                                className={
                                                                    classes.successColor
                                                                }
                                                            />{' '}
                                                            Succesfully uploaded
                                                        </React.Fragment>
                                                    )}
                                                </Typography>
                                                <Typography
                                                    variant="h5"
                                                    component="h2"
                                                >
                                                    Marker update jobs
                                                </Typography>
                                                <Typography
                                                    color="textSecondary"
                                                    className={
                                                        classes.statusDisplay
                                                    }
                                                >
                                                    {!correctionMarkerOperations ||
                                                    correctionMarkerOperations.length <=
                                                        0 ? (
                                                        <React.Fragment>
                                                            No marker updates
                                                            were made
                                                        </React.Fragment>
                                                    ) : isMarkerjobsUploading ? (
                                                        <React.Fragment>
                                                            <CircularProgress />{' '}
                                                            Creating jobs . . .
                                                        </React.Fragment>
                                                    ) : (
                                                        <React.Fragment>
                                                            <CheckCircleOutline
                                                                className={
                                                                    classes.successColor
                                                                }
                                                            />{' '}
                                                            Succesfully created
                                                            jobs you can track
                                                            their process once
                                                            you close the wizard
                                                        </React.Fragment>
                                                    )}
                                                </Typography>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                    <Grid item xs={1}></Grid>
                                </Grid>
                            </React.Fragment>
                        )}
                        {currentStage !== WizardSteps.NewSelection &&
                            currentStage !== WizardSteps.Result && (
                                <Button
                                    variant="contained"
                                    color="primary"
                                    className={classes.backButton}
                                    onClick={this.goBackButton}
                                >
                                    Back
                                </Button>
                            )}
                    </div>
                </div>
            </div>
        );
    }
}

const MUIComponent = withStyles(styles)(FloorplanReplaceWizard);
export { MUIComponent as FloorplanReplaceWizard };
