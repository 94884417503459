import { ModuleTemplate } from '../models/module-template';
import { HttpConnectorAdapter } from './../adapters/http-connector-adapter';

export default class ModuleTemplateProvider {
    private endpointAdmin: string;

    constructor() {
        this.endpointAdmin = `${process.env.REACT_APP_ADMIN_TOOL_API}module_template`;
    }

    init = () => {
        return HttpConnectorAdapter;
    };

    createModuleTemplate = (moduleTemplate: ModuleTemplate) => {
        const newModuleTemplate = {...moduleTemplate} as any;
        delete newModuleTemplate['timestamp'];
        delete newModuleTemplate['_id'];
        delete newModuleTemplate['module_template_id'];
        const headers = { 'Content-Type': 'application/json' };
        return this.init().postWithCredentials(`${this.endpointAdmin}/`, JSON.stringify(newModuleTemplate), headers).then(response => {
            const moduleTemplate = response.data as ModuleTemplate;
            const statusCode = response.status;

            return { status: statusCode, moduleTemplate };
        });
    }
}