import { SimpleGridCellDescriptor } from './simple-grid-cell-descriptor';
import { SimpleGridCellType } from './simple-grid-cell-type';
import {
    SIMPLE_GRID_ORDER_DESC,
    SimpleGridOrder
} from './simple-grid-order-type';
import { SimpleGridRowDescriptor } from './simple-grid-row-descriptor';

export class SimpleGridUtilities {
    public static sortRowsDataById( data: SimpleGridRowDescriptor[],
                                    order: SimpleGridOrder,
                                    headersData?: SimpleGridCellDescriptor[],
                                    orderBy?: string) : SimpleGridRowDescriptor[] {
        if (!orderBy || !data || (data.length < 1)) {
            return data;
        }

        const compare = (order === SIMPLE_GRID_ORDER_DESC) ?
            (a: SimpleGridRowDescriptor, b: SimpleGridRowDescriptor) => 
                this.sortRowsDescending(a, b, orderBy, headersData)
        :   (a: SimpleGridRowDescriptor, b: SimpleGridRowDescriptor) =>
                -(this.sortRowsDescending(a, b, orderBy, headersData));
        const mappedData = data.map((descriptor, index) => ({descriptor, index}));

        mappedData.sort((a, b) => {
            const item = compare(a.descriptor, b.descriptor);
            if (item !== 0) {
                return item;
            }
            return a.index - b.index;
            });
        return mappedData.map(el => el.descriptor);
    }
    protected static sortRowsDescending(a: SimpleGridRowDescriptor,
                                        b: SimpleGridRowDescriptor,
                                        orderBy: string,
                                        headersData?: SimpleGridCellDescriptor[]
                                        ): number {
        // If there are no headers it keeps the data unsorted.
        if (!headersData) {
            return 0;
        }
        const columnPosition = headersData.findIndex((descriptor: SimpleGridCellDescriptor) => {
            return descriptor.id === orderBy;
        });
        // If the ID isn't found it keeps the data unsorted.
        if (columnPosition < 0) {
            return 0;
        }

        const aGridCell = a.cells[columnPosition];
        const aGridCellType = aGridCell.cellType;
        let aGridCellValue = 
            (aGridCellType && (aGridCellType === SimpleGridCellType.numeric)) ? 
                parseInt(aGridCell.label, 10) :
                aGridCell.label;

        const bGridCell = b.cells[columnPosition];
        const bGridCellType = bGridCell.cellType;
        let bGridCellValue =
            (bGridCellType && (bGridCellType === SimpleGridCellType.numeric)) ?
                parseInt(bGridCell.label, 10) :
                bGridCell.label;

        if (aGridCellType !== bGridCellType) {
            aGridCellValue = aGridCell.label;
            bGridCellValue = bGridCell.label;
        }

        if (bGridCellValue < aGridCellValue) {
            return -1;
        }
        if (bGridCellValue > aGridCellValue) {
            return 1;
        }
        return 0;
    }
}