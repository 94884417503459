import { AccountFull } from './../models/account';
import { Team } from './../shared/domain/team';
import { HttpConnectorAdapter } from './../adapters/http-connector-adapter';
import { Account, EntityAccount } from './../shared/domain/account';
import {
    EntityFolder,
    Folder
} from './../shared/domain/folder';
import { ModuleGroup } from '../models/module-group';
import { UserModule } from '../models/user-module';
import { FloorplanFull, Marker } from '../models';
import _ from "lodash";
import { RoleDescription } from '../models/role';

interface MarkerWizardList {
    folder: string;
    floorplan: string;
    marker: Marker;
}

export default class AccountProvider {
    private endpointAdmin: string;
    private endpointCore: string;
    private memoizedSort = _.memoize((arr: any[], sortAttribute: string) => arr.sort((a, b) => a[sortAttribute].localeCompare(b[sortAttribute])));

    constructor() {
        this.endpointAdmin = `${process.env.REACT_APP_ADMIN_TOOL_API}account`;
        this.endpointCore = `${process.env.REACT_APP_BASE_API_URL}accounts`;
    };

    init = () => {
        return HttpConnectorAdapter;
    };

    getAllAccounts = (offset: number, limit: number) => {
        return this.init().getWithCredentials(`${this.endpointCore}?offset=${offset}&limit=${limit}`).then(response => {
            const { results } = response.data;
            const { total_count: totalCount } = results
            if (!totalCount || (totalCount < 1)){
                return { accounts: [], totalCount};
            }
            const accounts: EntityAccount[] = results.accounts as EntityAccount[];
            return {
                accounts: accounts.map((account: EntityAccount) => {
                            return Account.create(account);
                        }),
                totalCount
            };
        });
    }

    getAllAccountsFullSchema = (offset: number, limit: number) => {
        return this.init().getWithCredentials(`${this.endpointAdmin}/?offset=${offset}&limit=${limit}`).then(response => {
            const statusCode = response.status;
            const { docs, total } = response.data;
            return { status: statusCode, accounts: docs as AccountFull[], total_account: total };
        });
    }

    fetchAccount = (accountId: string) => {
        return this.init().getWithCredentials(`${this.endpointCore}/${accountId}/datum`).then(response => {
            const { results } = response.data;
            return Account.create(results);
        });
    }

    searchAccountByNameFullSchema = (name: string, offset: number, limit: number) => {
        const limitParam = limit > 0 ? `&limit=${limit}` : '';
        const offsetParam = offset > 0 ? `&offset=${offset}` : '';
        const formattedName = encodeURIComponent(name.replace(/'/, '%27').toLowerCase());
        return this.init().getWithCredentials(`${this.endpointAdmin}/?name=${formattedName}${limitParam}${offsetParam}`).then(response => {
            const statusCode = response.status;
            const { docs, total } = response.data;
            return { status: statusCode, accounts: docs as AccountFull[], total_account: total };
        });
    }

    searchAccountByNameShortSchema = (name: string, offset: number, limit: number) => {
        const limitParam = limit > 0 ? `&limit=${limit}` : '';
        const offsetParam = offset > 0 ? `&offset=${offset}` : '';
        const formattedName = encodeURIComponent(name.replace(/'/, '%27').toLowerCase());
        return this.init().getWithCredentials(`${this.endpointAdmin}/?name=${formattedName}${limitParam}${offsetParam}`).then(response => {
            const { docs, total } = response.data;

            const accounts: EntityAccount[] = docs as EntityAccount[];

            return {
                accounts: accounts.map((account: EntityAccount) => {
                    return Account.create(account);
                }),
                totalCount: total
            };
        });
    }

    getFoldersByAccountId = (accountId: string) => {
        return this.init().getWithCredentials(`${this.endpointAdmin}/${accountId}/folders`).then(response => {
            const { folder_ids } = response.data;
            const sortedFolderIds = this.memoizedSort([...folder_ids], "name");
            const folders: EntityFolder[] = sortedFolderIds.map((folder: { folder_id: string, name: string }) => {
                const newFolder = { folder_id: folder.folder_id, folder_name: folder.name };
                return newFolder as EntityFolder;
            });
            return folders.map((folder: EntityFolder) => {
                return Folder.create(folder);
            });
        });
    }

    getFloorplansByAccountId = (accountId: string) => {
        return this.init().getWithCredentials(`${this.endpointAdmin}/${accountId}/floorplans`).then(response => {
            const floorplans = response.data as FloorplanFull[];
            const statusCode = response.status;

            return { status: statusCode, floorplans };
            
        });
    }

    getRootFoldersByAccountId = (accountId: string) => {
        return this.init().getWithCredentials(`${this.endpointCore}/${accountId}/folders`).then(response => {
            const { folders } = response.data.results;
            const sortedFolders = this.memoizedSort([...folders], "folder_name")
            return sortedFolders.map((folder: EntityFolder) => {
                return Folder.create(folder);
            });
        });
    }

    getChildrenFolderByFolderId = (accountId: string, folderId: string) => {
        return this.init().getWithCredentials(`${this.endpointCore}/${accountId}/folders/${folderId}`).then(response => {
            const { folders } = response.data.results;
            const sortedFolders = this.memoizedSort([...folders], "folder_name")
            return sortedFolders.map((folder: EntityFolder) => {
                return Folder.create(folder);
            });
        });
    }

    fetchTeamsFromAccountId = (accountId: string, offset: number = 0, limit: number = 100) => {
        return this.init().getWithCredentials(`${this.endpointCore}/${accountId}/teams?limit=${limit}&offset=${offset}`).then(response => {
            const { results } = response.data;
            const { total_count: totalCount } = results;
            if (!totalCount || (totalCount < 1)){
                return {teams: [], totalCount};
            }
            const teams: Team[] = this.memoizedSort(results.teams, "name") as Team[];
            return {
                teams,
                totalCount
            }
        });
    }

    fetchTeamsFromAccountIdAdmin = (accountId: string, offset: number = 0, limit: number = 100) => {
        return this.init().getWithCredentials(`${this.endpointAdmin}/${accountId}/teams?limit=${limit}&offset=${offset}`).then(response => {
            const statusCode = response.status;
            const teams: Team[] = this.memoizedSort(response.data, "name") as Team[];
            return { status: statusCode, teams };
        });   
    }

    fetchModuleGroupsFromAccountId = (accountId: string) => {
        return this.init().getWithCredentials(`${this.endpointAdmin}/${accountId}/module_groups`).then(response => {
            const statusCode = response.status;

            return { status: statusCode, module_groups: response.data as ModuleGroup[] };
        });
    }

    fetchUserModulesFromAccountId = (accountId: string) => {
        return this.init().getWithCredentials(`${this.endpointAdmin}/${accountId}/user_modules`).then(response => {
            const statusCode = response.status;

            return { status: statusCode, user_modules: response.data as UserModule[] };
        });
    }

    getAllMarkers = (accountId: string) => {
        return this.init().getWithCredentials(`${this.endpointAdmin}/${accountId}/all_markers`).then(response => {
            const statusCode = response.status;
            return { status: statusCode, markers: response.data as MarkerWizardList[] };
        });  
    }


    fetchAccountRoles = (accountId: string) => {
        return this.init()
            .getWithCredentials(`${this.endpointAdmin}/${accountId}/scheduling_roles`)
            .then(response => {
                const status = response.status;
                const roles = [] as RoleDescription[];
                response.data.forEach((_role: RoleDescription) => {
                    const folder_ids: string[] = [];
                    const permissions_list: string[] = [];
                    _role.permissions.forEach(permission => {
                        const formattedFolderId = permission.target.replace(
                            'folder::',
                            ''
                        );
                        if (!folder_ids.includes(formattedFolderId)) {
                            folder_ids.push(formattedFolderId);
                        }
                        const formattedPermission = permission.action
                            .replace('_', ' ')
                            .split(' ')
                            .map(word => {
                                return (
                                    word.charAt(0).toUpperCase() + word.slice(1)
                                );
                            })
                            .join(' ');
                        if (!permissions_list.includes(formattedPermission)) {
                            permissions_list.push(formattedPermission);
                        }
                    });
                    roles.push({
                        ...(_role as RoleDescription),
                        folder_ids,
                        permissions_list
                    });
                });

                return { status, roles };
            });
    };

    createAccount = (account: AccountFull) => {
        const newAccount = {...account} as any;
        delete newAccount['_id'];
        delete newAccount['_rev'];
        delete newAccount['account_id'];
        const headers = { 'Content-Type': 'application/json' };
        return this.init().postWithCredentials(`${this.endpointAdmin}/`, JSON.stringify(newAccount), headers).then(response => {
            const account = response.data as AccountFull;
            const statusCode = response.status;

            return { status: statusCode, account };
        });
    }

    updateAccount = (account: AccountFull) => {
        delete account['_rev'];
        delete account['_sync'];
        const headers = { 'Content-Type': 'application/json' };
        return this.init().putWithCredentials(`${this.endpointAdmin}/${account.account_id}`, JSON.stringify(account), headers).then(response => {
            const account = response.data as AccountFull;
            const statusCode = response.status;

            return { status: statusCode, account };
        });
    }
}