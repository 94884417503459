import Bugsnag from '@bugsnag/js';
import { Button, List, ListItem, ListItemText, Typography } from '@material-ui/core';
import {
    createStyles,
    WithStyles,
    withStyles
} from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import { Error as ErrorIcon } from '@material-ui/icons';
import {
    inject,
    observer
} from 'mobx-react';
import * as React from 'react';
import { RunningTasksList } from '../../components';
import {
    MarkersListItem,
    QrCodeGenerator
} from '../../components/qr-code-generator';
import JobProvider, { JobRequest, JobTypes, RunningTasks, STATIC_PROGRESS_LIMIT, StatusTypes } from '../../providers/job.provider';
import QRProvider from '../../providers/qr.provider';
import { CCSpinner } from '../../shared/components/cc-spinner';
import { SimpleDialog } from '../../shared/components/simple-dialog';
import { PageBoundary } from '../../shared/components/simple-grid-pagination';
import { SimpleModal } from '../../shared/components/simple-modal';
import { MainTabs } from '../../stores/admin.store';
import { RootStore } from '../../stores/root.store';

const TASKS_ROWS_PER_PAGE = 10;
const TASKS_PAGING_LIMIT = 100;
const MAIN_SPINNER_TEXT = 'Scheduling job';

interface JobResultDataset {
    status: boolean;
    url: string;
    markers_processed: string;
    markers_failed: string;
    total: string;
}

const styles = (theme: Theme) => createStyles({
    root: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: '4em',
        width: '100%'
    },
    spinnerOverlay: {
        zIndex: 2000
    },
    errorPopup: {
        width: '98%',
        borderRadius: 5
    },
    errorPopupHeader: {
        height: '0.6em',
        backgroundColor: theme.palette.error.main,
        borderRadius: '3px 3px 0 0'
    },
    popupContentContainer :{
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(1),
    },
    errorPopupIcon: {
        fontSize: 40,
        marginRight: '1em'
    },
    animContainer: {
        transition: 'width 1s'
    },
    fullContainerSize: {
        width: '100%'
    },
    semiContainerSize: {
        width: '70%'
    },
    tasksContainerSize: {
        flex: 1,
        marginLeft: '2em'
    },
    mainSpinnerLabel: {
        paddingBottom: '30px'
    },
    progressContainer: {
        flexGrow: 1
    },
    resumePopup: {
        width: '35em',
        borderRadius: 5
    },
    resumePopupHeader: {
        height: '2em',
        backgroundColor: theme.palette.primary.main,
        borderRadius: '3px 3px 0 0',
        color: 'white',
        padding: '0.5em',
        fontSize: '1.5em'
    },
    resultList: {
        width: '100%'
    }
});

interface Props extends WithStyles<typeof styles> {
    rootStore: RootStore;
    onAuthError?: () => void;
};

interface States {
    loading: boolean;
    networkError: boolean;
    runningTasks: RunningTasks[];
    currentTasksOffset: number;
    timeElapser: number | null;
    isErrorModalOpened: boolean;
    errorModalHeader: string;
    errorModalText: string;
    detailModal: boolean;
    clickedTaskData: JobResultDataset | null;
}

@inject('rootStore')
@observer
class QRCodes extends React.Component<Props, States> {
    public static defaultProps: Partial<Props> = {
    };

    state: States = {
        loading: false,
        networkError: false,
        runningTasks: [],
        currentTasksOffset: 0,
        timeElapser: null,
        isErrorModalOpened: false,
        errorModalHeader: '',
        errorModalText: '',
        detailModal: false,
        clickedTaskData: null
    };

    qrProvider = new QRProvider();
    jobProvider = new JobProvider();

    componentDidMount() {
        this.fetchRunningTasks();
        this.startTimeInterval();
    }

    componentWillUnmount() {
        const {
            rootStore: { adminStore }
        } = this.props;   
        const { runningTasks } = this.state;
        this.clearIntervals(runningTasks);
        adminStore.setCurrentExportTasks(runningTasks, MainTabs.QR_Code);
        this.clearTimeElapserInterval();
    }

    clearIntervals(tasks: RunningTasks[]) {
        tasks.forEach((element) => {
            element.set_to_stop = true;
        });
    }

    clearTimeElapserInterval() {
        const { timeElapser } = this.state;
        if( timeElapser ) {
            window.clearInterval(timeElapser);
        }
    }

    startTimeInterval() {
        const { timeElapser } = this.state;
        if (timeElapser) {
            return;
        }
        const timeElapserInterval = window.setInterval(() => {
            const { runningTasks } = this.state;
            const time = Date.now();
            const processing = runningTasks.filter(e => e.status === 102);
            processing.forEach(element => {
                element.elapsedTime = Math.round((time - element.startedAt) / 1000);
            });
            this.setState({runningTasks});
        }, 1000);
        this.setState({timeElapser: timeElapserInterval});
    }

    fetchRunningTasks = () => {
        const {
            rootStore: { adminStore }
        } = this.props;
        const tasks = adminStore.currentExportTasks.find(e => e.page === MainTabs.QR_Code);
        this.setState({
            runningTasks: tasks!.tasks
        }, () => {
            tasks!.tasks.forEach(element => {
                if(element.status === 102) {
                    // SET TO RESTART POOLING
                    element.set_to_stop = false;
                    this.startTaskWatcher(element);
                }
            });
        });
    }

    startTaskWatcher = (task: RunningTasks) => {
        const { runningTasks } = this.state;

        if (!task.export_name || !task.task_id || !task.export_id) {
            return;
        }

        const statusCallback = (taskId: string) => {
            const taskIndex = runningTasks.findIndex(e => e.task_id === taskId);
            const taskItem = runningTasks.find(e => e.task_id === taskId);

            if (!taskItem || taskItem.status !== 102) {
                return;
            }

            if (taskItem.set_to_stop) {
                return;
            }   
            
            this.jobProvider.checkJobStatus(taskItem.task_id).then(data => {
                if (data.job_status === StatusTypes.Error) {
                    taskItem.status = 500;
                    runningTasks[taskIndex] = taskItem;
                    this.setState({runningTasks});
                }
                if (data.job_status === StatusTypes.Complete) {
                    taskItem.status = 200;
                    taskItem.progress = 100.0;
                    this.jobProvider.checkJobResult(taskItem.task_id).then(res => {
                        const results = res.result as JobResultDataset;
                        taskItem.result_data = results;
                        runningTasks[taskIndex] = taskItem;
                        this.setState({runningTasks});
                    }).catch((error) => {
                        const { status } = error.response;
                        // CHECK IF WE TIMEOUT OR NOT
                        if (status === 408 || status === 504) {
                            // WE HAVE TIMEOUT INCREASE TIMER AND KEEP TRYING
                            taskItem.timer += 1000;
                            setTimeout(taskItem.internalCallback!, taskItem.timer, taskId);
                            return;
                        }
                        taskItem.status = 500;
                        runningTasks[taskIndex] = taskItem;
                        this.setState({runningTasks});
                    });
                } else {
                    // KEEP TRACK OF THE PROGRESS STATUS IF ITS STILL THE SAME
                    if (taskItem.progress === data.estimate_percent_complete) {
                        taskItem.static_progress_count += 1;
                    }
                    taskItem.progress = data.estimate_percent_complete;
                    // IF WE REACH A POINT WHERE THE STATUS IS THE SAME THE JOB IS SLOW
                    // MAKE FEWER REQUESTS TO NOT OVERWHELM THE STATUS ENDPOINT
                    if (taskItem.static_progress_count === STATIC_PROGRESS_LIMIT) {
                        taskItem.timer += 1000;
                        taskItem.static_progress_count = 0;
                    }
                    setTimeout(taskItem.internalCallback!, taskItem.timer, taskId);
                }
            }).catch((error) => {
                const { status } = error.response;
                // CHECK IF WE TIMEOUT OR NOT
                if (status === 408 || status === 504) {
                    // WE HAVE TIMEOUT INCREASE TIMER AND KEEP TRYING
                    taskItem.timer += 1000;
                    setTimeout(taskItem.internalCallback!, taskItem.timer, taskId);
                    Bugsnag.notify((new Error(`QR GENERATION - ${taskItem!.export_id} job timeout`)), event => {
                        event.severity = 'warning';
                        event.context = 'QR codes';
                        event.addMetadata('job_item', taskItem!)
                    });
                    return;
                }
                taskItem.status = 500;
                runningTasks[taskIndex] = taskItem;
                this.setState({runningTasks});
            });
        }

        // PREVENT GENERATING TIMERS WITHOUT PROPERTIES BEING INITIALIZED
        const taskIndex = runningTasks.findIndex(e => e.task_id === task.task_id);
        runningTasks[taskIndex].internalCallback = statusCallback;
        this.setState({runningTasks}, () => {
            setTimeout(statusCallback, task.timer, task.task_id);
        });
    }
    
    setCurrentMarkersList = (markers: MarkersListItem[]) => {
        const {
            rootStore: { adminStore }
        } = this.props;
        adminStore.setMarkersList(markers);
    }

    onQrCodeGeneratorSubmitted = (markers: MarkersListItem[], qrTemplateFile: File, qrPositionFile: File, fontSize: number) => {
        const markerIds = [] as string[];
        const markerNames = [] as string[];
        
        markers.forEach((marker: MarkersListItem) => {
            markerIds.push(marker.id);
            markerNames.push(marker.name);
        });
        
        this.setState({loading: true});

        // FIRST UPLOAD THE FILES TO S3
        this.qrProvider.uploadQRCodeFiles(qrPositionFile, qrTemplateFile).then(data => {
            const {position_url, template_url} = data;
            this.submitQRJob(
                markerIds,
                markerNames,
                position_url,
                template_url, 
                isNaN(fontSize) ? 12 : fontSize
            );
        }).catch((error) => {
            const { status } = error.response;
            if (status === 401) {
                const { onAuthError } = this.props;
                if (!onAuthError) {
                    return;
                }
                onAuthError();
                return;
            }
            this.setState({networkError: true});
        });
    }

    submitQRJob = (markerIds: string[], markerNames: string[], positionURL: string, templateURL: string, fontSize: number) => {
        const {
            rootStore: { adminStore }
        } = this.props;
        const userIdentifiers = adminStore.userIdentifiers!;
        const jobPayload: JobRequest = {
            user_created_id: userIdentifiers.user_id,
            account_id: 'QR CODE GENERATION',
            job_type: `${JobTypes.QRCodeGeneration}`,
            additional_params: {
                marker_ids: markerIds,
                marker_names: markerNames,
                xfdf_path: positionURL,
                pdf_template_path: templateURL,
                font_size: fontSize,
            }
        };
        const { runningTasks } = this.state;
        return this.jobProvider.scheduleJob(jobPayload)
        .then((result) => {
            const newTask: RunningTasks = {
                account: null,
                export_name: `Generating (${markerIds.length}) markers`,
                export_id: result.job_id,
                type: JobTypes.QRCodeGeneration,
                task_id: result.job_id,
                status: 102,
                startedAt: Date.now(),
                elapsedTime: 0,
                progress: 0,
                timer: 1500,
                static_progress_count: 0,
                        set_to_stop: false
            };

            runningTasks.push(newTask);
            adminStore.setCurrentExportTasks(runningTasks, MainTabs.QR_Code);
            this.setState({runningTasks, loading: false});
            this.startTaskWatcher(newTask);
            this.startTimeInterval();
        }).catch((error) => {
            // tslint:disable-next-line:no-console
            console.error(error);
            this.setState({networkError: true});
        });
    }

    onCSVImportClicked = (markers: MarkersListItem[]) => {
        this.setCurrentMarkersList(markers);
    }

    onClearMarkersClicked = () => {
        this.setCurrentMarkersList([]);
    }

    onTaskDataPageOverBoundaryReached = (boundary: PageBoundary, nextPage: number): Promise<void> => {
        return new Promise<void>((resolve, reject) => {
            const { 
                currentTasksOffset,
                runningTasks
            } = this.state;
            const totalTasks = runningTasks.length;
            const isFirstPage = nextPage === 0;
            const isLastPage = nextPage === (Math.ceil(totalTasks / TASKS_ROWS_PER_PAGE)-1);
            const newOffset = isFirstPage ? 0
                            : isLastPage ? TASKS_PAGING_LIMIT * Math.floor(totalTasks / TASKS_PAGING_LIMIT)
                            : (boundary === PageBoundary.Upper) ? 
                            currentTasksOffset+TASKS_PAGING_LIMIT :
                            currentTasksOffset-TASKS_PAGING_LIMIT;
            const nextTasks = runningTasks;
            // MANUAL OFFSET
            this.setState({
                runningTasks: nextTasks.splice(newOffset, nextTasks.length)
            });
        });
    }

    onTaskClick = (task: RunningTasks) => {
        if (task.status === 102) {
            return;
        }
        if(task.status === 200) {
            this.setState({
                detailModal: true,
                clickedTaskData: task.result_data! as JobResultDataset
            });
            return;
        }
        this.jobProvider.checkJobError(task.task_id)
            .then((data) => {
                this.setState({
                    isErrorModalOpened: true,
                    errorModalHeader: data.error,
                    errorModalText: data.stack_trace
                });
            }).catch((error) => {
                // tslint:disable-next-line:no-console
                console.error(error);
                this.setState({networkError: true});
            });
    }

    onTaskClear = () => {
        const { runningTasks } = this.state;
        const pendingTasks = runningTasks.filter(e => e.status === 102);
        const {
            rootStore: { adminStore }
        } = this.props;
        adminStore.setCurrentExportTasks(pendingTasks, MainTabs.QR_Code);
        this.setState({runningTasks: pendingTasks});
    }

    downloadJobResults = () => {
        const { clickedTaskData } = this.state;
        window.open(clickedTaskData?.url, '_blank');
        this.setState({detailModal: false, clickedTaskData: null});
    }

    onErrorModalClicked = () => {
        this.setState({isErrorModalOpened: false});
    }

    public render() {
        const {
            classes,
            rootStore: { 
                adminStore: { currentMarkersList }
            }
        } = this.props
        const {
            loading,
            networkError,
            runningTasks,
            currentTasksOffset,
            detailModal,
            clickedTaskData,
            isErrorModalOpened,
            errorModalHeader,
            errorModalText
        } = this.state;

        return (
            <div className={classes.root} data-testid="mainRender">
                <CCSpinner
                    label={MAIN_SPINNER_TEXT}
                    labelClassName={classes.mainSpinnerLabel}
                    className={classes.progressContainer}
                    loading={loading}
                    size={200}
                >
                    <div className={`${classes.animContainer} ${runningTasks.length > 0 ? classes.semiContainerSize : classes.fullContainerSize}`}>
                        <QrCodeGenerator
                            markers={currentMarkersList}
                            onSubmit={this.onQrCodeGeneratorSubmitted}
                            onClearMarkers={this.onClearMarkersClicked}
                            onCSVImport={this.onCSVImportClicked}
                        />
                    </div>
                </CCSpinner>
                {
                    (runningTasks && (runningTasks.length > 0)) &&
                    <RunningTasksList
                        className={classes.tasksContainerSize}
                        tasks={runningTasks}
                        rowsPerPage={TASKS_ROWS_PER_PAGE}
                        taskItemsOffset={currentTasksOffset}
                        listName="Running QR Generations"
                        onTaskDataPageOverBoundary={this.onTaskDataPageOverBoundaryReached}
                        onTaskClick={this.onTaskClick}
                        onTaskClear={this.onTaskClear}
                    />
                }
                <SimpleDialog
                    open={isErrorModalOpened}
                    titleText={errorModalHeader}
                    contentText={errorModalText}
                    buttonCancelLabel=""
                    onDialogResult={this.onErrorModalClicked}
                />
                {
                    networkError &&
                        <SimpleModal
                            className={classes.errorPopup}
                            open={networkError}
                            contentClasses= {classes.popupContentContainer}
                            buttonOkLabel=""
                            buttonCancelLabel=""
                            header=""
                            headerClassName={classes.errorPopupHeader}
                        >
                            <ErrorIcon color="error" className={classes.errorPopupIcon} />
                            <div>
                                <Typography variant="h5">
                                    {'Network Error'}
                                </Typography>
                                <Typography variant="subtitle1">
                                    {'Please reload the page.'}
                                </Typography>
                            </div>
                        </SimpleModal>
                }
                {
                    detailModal && clickedTaskData &&
                        <SimpleModal
                            className={classes.resumePopup}
                            open={detailModal}
                            contentClasses={classes.popupContentContainer}
                            buttonOkLabel=""
                            buttonCancelLabel=""
                            header='QR Code generations result'
                            headerClassName={classes.resumePopupHeader}
                        >
                            <List className={classes.resultList}>
                                <ListItem>
                                    <ListItemText
                                        primary="Processed markers"
                                        secondary={clickedTaskData.markers_processed}
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemText
                                        primary="Failed markers"
                                        secondary={clickedTaskData.markers_failed}
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemText
                                        primary="Total markers"
                                        secondary={clickedTaskData.total}
                                    />
                                </ListItem>
                            </List>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={this.downloadJobResults}
                            >
                                Download results
                            </Button>
                        </SimpleModal>
                }
            </div>
        );
    }
};

const MUIComponent = withStyles(styles)(QRCodes);
export { MUIComponent as QRCodes };
