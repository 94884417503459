import * as React from 'react';
import {
    createStyles,
    Theme,
    WithStyles,
    withStyles
} from '@material-ui/core/styles';
import { RootStore } from '../../stores/root.store';
import { Account, Folder } from '../../shared/domain';
import { inject, observer } from 'mobx-react';
import FloorplanProvider, {
    PDF2JPGConvertion
} from '../../providers/floorplan.provider';
import JobProvider, {
    JobRequest,
    JobTypes,
    RunningTasks,
    STATIC_PROGRESS_LIMIT,
    StatusTypes,
    TaskGroup
} from '../../providers/job.provider';
import AccountProvider from '../../providers/account.provider';
import { MainTabs } from '../../stores/admin.store';
import {
    Button,
    Card,
    CardContent,
    Chip,
    CircularProgress,
    Drawer,
    Grid,
    Step,
    StepLabel,
    Stepper,
    Typography
} from '@material-ui/core';
import { CCSpinner } from '../../shared/components/cc-spinner';
import { AccountFolderMarker, RunningGroupTasksList } from '../../components';
import { RenderTree } from '../../shared/components/node-tree';
import { PickedType } from '../marker-export/marker-export';
import _ from 'lodash';
import FolderProvider from '../../providers/folder.provider';
import { PageBoundary } from '../../shared/components/simple-grid-pagination';
import { FloorplanFull } from '../../models';
import { SimpleDialog } from '../../shared/components/simple-dialog';
import { XFDFMarker } from '../marker-operations/marker-operations';
import {
    MarkerOperation,
    ThinClient
} from '../../components/thin-client/thin-client';
import { SimpleListPagination } from '../../shared/components/simple-list-pagination';
import { SimpleListItem } from '../../shared/components/simple-list';
import { Alert } from '@material-ui/lab';
import { UserIdentifiers } from '../../providers/user.provider';
import { CheckCircleOutline } from '@material-ui/icons';
import { v4 as uuidv4 } from 'uuid';

const ACCOUNTS_PAGING_LIMIT = 20;
const ROWS_PER_PAGE = 10;

export interface CanvasMarker {
    id: string;
    room_type: string;
    name: string;
    coordinates: string;
    xfdf_coordinates: string;
    final_coordinates: number[];
    fits: boolean;
    new_xfdf_coordinates?: string;
}

enum WizardSteps {
    FloorplanSelection = 0,
    NewSelection,
    FloorplanEdit,
    Confirmation,
    Result
}

const ENABLED_EVENTS = [
    'ANNOTATION_ADDED',
    'ANNOTATION_DELETED',
    'ANNOTATION_UPDATED'
];

const WIZARD_STEPS = [
    {
        id: WizardSteps.FloorplanSelection,
        label: 'Floorplan selection'
    },
    {
        id: WizardSteps.NewSelection,
        label: 'Replacement selection'
    },
    {
        id: WizardSteps.FloorplanEdit,
        label: 'Marker Corrections'
    },
    {
        id: WizardSteps.Confirmation,
        label: 'Confirm changes'
    },
    {
        id: WizardSteps.Result,
        label: 'Results'
    }
];

const styles = (theme: Theme) =>
    createStyles({
        container: {
            flex: 1,
            display: 'flex',
            flexDirection: 'column'
        },
        spinnerOverlay: {
            zIndex: 2000
        },
        transferButton: {
            padding: '0.5em 0',
            margin: '1em 0'
        },
        stepsContentHolder: {
            padding: '1em',
            position: 'relative'
        },
        heading: {
            fontWeight: 700,
            marginBottom: '0.5em',
            marginTop: '0.5em'
        },
        ellipsis: {
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden'
        },
        simpleList: {
            overflow: 'auto',
            width: '95%',
            height: '100%',
            marginRight: '1em'
        },
        animContainer: {
            transition: 'width 1s',
            marginTop: '0.5em'
        },
        flexRow: {
            display: 'flex',
            flexDirection: 'row',
            marginTop: '1em'
        },
        relativeRow: {
            position: 'relative'
        },
        continueButton: {
            marginLeft: '1%',
            float: 'right',
            minWidth: '10em'
        },
        replacementInfoAlert: {
            width: '75%',
            marginLeft: '5%'
        },
        markerHoverDisplay: {
            position: 'absolute',
            border: '2px solid',
            fontWeight: 'bold',
            padding: '0.5em 1.5em',
            borderRadius: '5px',
            marginLeft: 'auto',
            marginRight: 'auto',
            left: 0,
            right: 0,
            marginTop: '1.5em',
            maxWidth: 'max-content'
        },
        replacementCanvas: {
            border: '2px solid red',
            borderRadius: '1em',
            display: 'block',
            margin: 'auto',
            marginTop: '1.5em'
        },
        thinClientContainer: {
            height: '50em',
            marginBottom: '3em',
            transition: 'width 1s',
            [theme.breakpoints.down('lg')]: {
                maxHeight: '35em'
            }
        },
        backButton: {
            width: '10em'
        },
        nextButton: {
            position: 'absolute',
            bottom: '1em',
            right: '1em'
        },
        confirmationContainer: {
            height: '50em',
            [theme.breakpoints.down('lg')]: {
                maxHeight: '35em'
            }
        },
        successColor: {
            color: '#2dd36f'
        },
        statusCard: {
            maxWidth: 'max-content',
            margin: 'auto'
        },
        statusDisplay: {
            margin: '1em'
        },
        replaceWizardButton: {
            position: 'absolute',
            right: '1em'
        },
        currentTab: {
            display: 'block'
        },
        otherTab: {
            displat: 'none'
        },
        jobMenuButton: {
            position: 'fixed',
            right: '6%',
            top: '3%'
        },
        notFinishedBadge: {
            marginLeft: '1em',
            background: '#ffb300',
            color: 'white',
            fontWeight: 'bolder'
        },
        finishedBadge: {
            marginLeft: '1em',
            background: '#107d00',
            color: 'white',
            fontWeight: 'bolder'
        },
        tasksContainerSize: {
            width: '30em',
            padding: '0 1em'
        },
        paper: {
            zIndex: 'auto'
        },
        redoButton: {
            display: 'block',
            margin: 'auto',
            marginTop: '1.5em'
        }
    });

interface Props extends WithStyles<typeof styles> {
    rootStore: RootStore;
    onAuthError?: () => void;
}

interface States {
    accounts: Account[];
    currentAccountOffset: number;
    isAccountsLoading: boolean;
    totalAccounts: number;
    currentAccount: Account | null;
    currentStage: WizardSteps;
    runningTasks: RunningTasks[];
    runningTasksGroup: TaskGroup[];
    currentGroupOffset: number;
    timeElapser: number | null;
    loading: boolean;
    isErrorModalOpened: boolean;
    errorModalHeader: string;
    errorModalText: string;
    folders: Folder[];
    isFoldersLoading: boolean;
    currentNodeTree: RenderTree;
    treeIsLoading: boolean;
    treePickedType: PickedType;
    treePickedId: string;
    treePickedName: string;
    processedFolders: string[];
    floorPlansIsLoading: boolean;
    replaceFloorplan: FloorplanFull | null;
    isMarkersLoading: boolean;
    originalMarkerList: CanvasMarker[];
    newPDFFile: File | null;
    newPDFImage: string | null;
    hoveringMarker: CanvasMarker | null;
    thinClientMarkerList: XFDFMarker[];
    fileBlob: Blob | null;
    correctionMarkerOperations: MarkerOperation[];
    minimapMarkerUpdates: CanvasMarker[];
    displayImage: HTMLImageElement | null;
    replacementBlob: Blob | null;
    candidateDimensions: PDF2JPGConvertion | null;
    warningText: string | undefined;
    isPDFUploading: boolean;
    isMarkerjobsUploading: boolean;
    isJobMenuOpen: boolean;
    searchAccountName: string;
    storedAccounts: Account[];
    storedAccountOffset: number;
    storedAccountTotalCount: number;
}

@inject('rootStore')
@observer
class FloorplanReplace extends React.Component<Props, States> {
    public static defaultProps: Partial<Props> = {};

    state: States = {
        accounts: [],
        currentAccountOffset: 0,
        isAccountsLoading: false,
        totalAccounts: 0,
        currentAccount: null,
        currentStage: WizardSteps.FloorplanSelection,
        runningTasks: [],
        runningTasksGroup: [],
        currentGroupOffset: 0,
        timeElapser: null,
        loading: false,
        isErrorModalOpened: false,
        errorModalHeader: '',
        errorModalText: '',
        folders: [],
        isFoldersLoading: false,
        currentNodeTree: { id: '', name: '', children: [], path: '' },
        treeIsLoading: false,
        treePickedType: PickedType.None,
        treePickedId: '',
        treePickedName: '',
        processedFolders: [],
        floorPlansIsLoading: false,
        replaceFloorplan: null,
        isMarkersLoading: false,
        originalMarkerList: [],
        newPDFFile: null,
        newPDFImage: null,
        hoveringMarker: null,
        thinClientMarkerList: [],
        fileBlob: null,
        correctionMarkerOperations: [],
        minimapMarkerUpdates: [],
        displayImage: null,
        replacementBlob: null,
        candidateDimensions: null,
        warningText: undefined,
        isPDFUploading: false,
        isMarkerjobsUploading: false,
        isJobMenuOpen: false,
        searchAccountName: '',
        storedAccounts: [],
        storedAccountOffset: 0,
        storedAccountTotalCount: 0
    };

    canvasRef: React.RefObject<any> = React.createRef();

    accountProvider = new AccountProvider();
    jobProvider = new JobProvider();
    floorplanProvider = new FloorplanProvider();
    folderProvider = new FolderProvider();

    componentDidMount() {
        this.fetchRunningTasks();
        this.startTimeInterval();
        this.fetchAccounts(this.state.currentAccountOffset);
    }

    componentWillUnmount() {
        const {
            rootStore: { adminStore }
        } = this.props;
        const { runningTasks, runningTasksGroup } = this.state;
        this.clearIntervals(runningTasks);
        adminStore.setCurrentExportTasks(
            runningTasks,
            MainTabs.Floorplan_Replace
        );
        adminStore.setCurrentExportGroups(
            runningTasksGroup,
            MainTabs.Floorplan_Replace
        );
        this.clearTimeElapserInterval();
    }

    clearIntervals(tasks: RunningTasks[]) {
        tasks.forEach(element => {
            element.set_to_stop = true;
        });
    }

    clearTimeElapserInterval() {
        const { timeElapser } = this.state;
        if (timeElapser) {
            window.clearInterval(timeElapser);
        }
    }

    fetchRunningTasks = () => {
        const {
            rootStore: { adminStore }
        } = this.props;
        const tasks = adminStore.currentExportTasks.find(
            e => e.page === MainTabs.Floorplan_Replace
        );
        this.setState(
            {
                runningTasks: tasks!.tasks,
                runningTasksGroup: tasks!.groups ? tasks!.groups : []
            },
            () => {
                tasks!.tasks.forEach((element, index) => {
                    if (element.status === 102) {
                        // SET TO RESTART POOLING
                        element.set_to_stop = false;
                        this.startTaskWatcher(element, index);
                    }
                });
            }
        );
    };

    startTimeInterval() {
        const { timeElapser } = this.state;
        if (timeElapser) {
            return;
        }
        const timeElapserInterval = window.setInterval(() => {
            const { runningTasks, runningTasksGroup } = this.state;
            const time = Date.now();
            const processing = runningTasks.filter(e => e.status === 102);
            processing.forEach(element => {
                element.elapsedTime = Math.round(
                    (time - element.startedAt) / 1000
                );
            });
            const processingGroups = runningTasksGroup.filter(
                e => e.done.length + e.errored.length !== e.total
            );
            processingGroups.forEach(element => {
                element.elapsedTime = Math.round(
                    (time - element.startedAt) / 1000
                );
            });
            this.setState({ runningTasks, runningTasksGroup });
        }, 1000);
        this.setState({ timeElapser: timeElapserInterval });
    }

    addCountToGroup = (groupId: string, taskId: string, category: string) => {
        if (category !== 'done' && category !== 'errored') {
            return;
        }

        const { runningTasksGroup } = this.state;
        const group = runningTasksGroup.find(e => e.id === groupId);

        if (!group) {
            return;
        }

        if (!group.tasks.find(e => e.task_id === taskId)) {
            return;
        }

        if (group[category].includes(taskId)) {
            return;
        }

        group[category].push(taskId);

        this.setState({ runningTasksGroup });
    };

    startTaskWatcher = (task: RunningTasks, position: number) => {
        const { runningTasks, runningTasksGroup } = this.state;

        if (!task.export_name || !task.task_id || !task.export_id) {
            return;
        }

        const statusCallback = (taskId: string) => {
            const taskIndex = runningTasks.findIndex(e => e.task_id === taskId);
            const taskItem = runningTasks.find(e => e.task_id === taskId);
            const taskGroup = runningTasksGroup.find(
                e => e.id === taskItem!.group_id
            );

            if (!taskItem || taskItem.status !== 102 || !taskGroup) {
                return;
            }

            if (taskItem.set_to_stop) {
                return;
            }

            this.jobProvider
                .checkJobStatus(taskItem.task_id)
                .then(data => {
                    if (data.job_status === StatusTypes.Error) {
                        taskItem.status = 500;
                        runningTasks[taskIndex] = taskItem;
                        this.addCountToGroup(
                            taskGroup.id,
                            taskItem.task_id,
                            'errored'
                        );
                        this.setState({ runningTasks });
                    }
                    if (data.job_status === StatusTypes.Complete) {
                        taskItem.status = 200;
                        taskItem.progress = 100.0;
                        this.addCountToGroup(
                            taskGroup.id,
                            taskItem.task_id,
                            'done'
                        );
                        this.jobProvider
                            .checkJobResult(taskItem.task_id)
                            .then(res => {
                                runningTasks[taskIndex] = taskItem;
                                this.setState({ runningTasks });
                            })
                            .catch(error => {
                                const { status } = error.response;
                                // CHECK IF WE TIMEOUT OR NOT
                                if (status === 408 || status === 504) {
                                    // WE HAVE TIMEOUT INCREASE TIMER AND KEEP TRYING
                                    taskItem.timer += 1000;
                                    setTimeout(
                                        taskItem.internalCallback!,
                                        taskItem.timer,
                                        taskId
                                    );
                                    return;
                                }
                                taskItem.status = 500;
                                this.addCountToGroup(
                                    taskGroup.id,
                                    taskItem.task_id,
                                    'errored'
                                );
                                runningTasks[taskIndex] = taskItem;
                                this.setState({ runningTasks });
                            });
                    } else {
                        // KEEP TRACK OF THE PROGRESS STATUS IF ITS STILL THE SAME
                        if (
                            taskItem.progress === data.estimate_percent_complete
                        ) {
                            taskItem.static_progress_count += 1;
                        }
                        taskItem.progress = data.estimate_percent_complete;
                        // IF WE REACH A POINT WHERE THE STATUS IS THE SAME THE JOB IS SLOW
                        // MAKE FEWER REQUESTS TO NOT OVERWHELM THE STATUS ENDPOINT
                        if (
                            taskItem.static_progress_count ===
                            STATIC_PROGRESS_LIMIT
                        ) {
                            taskItem.timer += 1000;
                            taskItem.static_progress_count = 0;
                        }
                        setTimeout(
                            taskItem.internalCallback!,
                            taskItem.timer,
                            taskId
                        );
                    }
                })
                .catch(error => {
                    const { status } = error.response;
                    // CHECK IF WE TIMEOUT OR NOT
                    if (status === 408 || status === 504) {
                        // WE HAVE TIMEOUT INCREASE TIMER AND KEEP TRYING
                        taskItem.timer += 1000;
                        setTimeout(
                            taskItem.internalCallback!,
                            taskItem.timer,
                            taskId
                        );
                        return;
                    }
                    taskItem.status = 500;
                    this.addCountToGroup(
                        taskGroup.id,
                        taskItem.task_id,
                        'errored'
                    );
                    runningTasks[taskIndex] = taskItem;
                    this.setState({ runningTasks });
                });
        };

        // PREVENT GENERATING TIMERS WITHOUT PROPERTIES BEING INITIALIZED
        const taskIndex = runningTasks.findIndex(
            e => e.task_id === task.task_id
        );
        runningTasks[taskIndex].internalCallback = statusCallback;
        runningTasks[taskIndex].timer = position * 500;
        this.setState({ runningTasks }, () => {
            setTimeout(statusCallback, task.timer, task.task_id);
        });
    };

    fetchAccounts = (offset: number, limit: number = ACCOUNTS_PAGING_LIMIT) => {
        this.setState({
            loading: true
        });
        return this.accountProvider
            .getAllAccounts(offset, limit)
            .then(results => {
                const { accounts, totalCount } = results;
                if (!accounts) {
                    if (offset === 0) {
                        this.setState({
                            isErrorModalOpened: true,
                            errorModalHeader: 'Error fetching accounts',
                            errorModalText: 'No accounts found'
                        });
                    }
                    return;
                }

                this.setState({
                    isAccountsLoading: false,
                    totalAccounts: totalCount,
                    accounts,
                    currentAccountOffset: offset
                });
            })
            .catch(error => {
                console.error(error);
                const { status } = error.response;
                if (status === 401) {
                    const { onAuthError } = this.props;
                    if (!onAuthError) {
                        return;
                    }
                    onAuthError();
                    return;
                }
                this.setState({
                    isErrorModalOpened: true,
                    errorModalHeader: `Error fetching accounts ${error}`,
                    errorModalText: error.stack_trace
                });
            })
            .finally(() => {
                this.setState({ loading: false });
            });
    };

    searchAccounts = (searchName: string, offset?: number) => {
        // Nothing to search load the normal account list
        if (searchName === '') {
            const {
                storedAccounts,
                storedAccountOffset,
                storedAccountTotalCount
            } = this.state;

            // Empty search do nothing
            if (!storedAccounts.length) {
                return;
            }

            // Going back from actual seach
            this.setState({
                accounts: storedAccounts,
                currentAccountOffset: storedAccountOffset,
                totalAccounts: storedAccountTotalCount,
                searchAccountName: '',
                storedAccounts: [],
                storedAccountOffset: 0,
                storedAccountTotalCount: 0
            });
            return;
        }

        // We have a name search
        const {
            currentAccountOffset,
            accounts,
            totalAccounts,
            storedAccounts
        } = this.state;
        this.setState({
            isAccountsLoading: true,
            searchAccountName: searchName
        }, () => {
            const searchOffset = offset ? offset : 0;
            return this.accountProvider
                .searchAccountByNameShortSchema(searchName, searchOffset, ACCOUNTS_PAGING_LIMIT)
                .then(results => {
                    const { accounts: foundAccounts, totalCount } = results;
                    if (!foundAccounts) {
                        if (currentAccountOffset === 0) {
                            this.setState({
                                isErrorModalOpened: true,
                                errorModalHeader: 'Error fetching accounts',
                                errorModalText: 'No accounts found'
                            });
                        }
                        return;
                    }
    
                    // If we haven't stored the original list do it
                    // This prevents multiple searchs overriding each others
                    if (!storedAccounts.length) {
                        this.setState({
                            storedAccounts: accounts,
                            storedAccountTotalCount: totalAccounts,
                            storedAccountOffset: currentAccountOffset
                        })
                    }

                    this.setState({
                        isAccountsLoading: false,
                        totalAccounts: totalCount,
                        accounts: foundAccounts,
                        currentAccountOffset: searchOffset
                    });
                })
                .catch(error => {
                    const { status } = error.response;
                    if (status === 401) {
                        const { onAuthError } = this.props;
                        if (!onAuthError) {
                            return;
                        }
                        onAuthError();
                        return;
                    }
                    this.setState({
                        isErrorModalOpened: true,
                        errorModalHeader: `Error fetching accounts ${error}`,
                        errorModalText: error.stack_trace
                    });
                });
        });
    };

    onAccountDataPageOverBoundaryReached = (
        boundary: PageBoundary,
        nextPage: number
    ): Promise<void> => {
        return new Promise<void>((resolve, reject) => {
            const { currentAccountOffset, totalAccounts } = this.state;
            const isFirstPage = nextPage === 0;
            const isLastPage =
                nextPage === Math.ceil(totalAccounts / ROWS_PER_PAGE) - 1;
            const newOffset = isFirstPage
                ? 0
                : isLastPage
                ? ACCOUNTS_PAGING_LIMIT *
                  Math.floor(totalAccounts / ACCOUNTS_PAGING_LIMIT)
                : boundary === PageBoundary.Upper
                ? currentAccountOffset + ACCOUNTS_PAGING_LIMIT
                : currentAccountOffset - ACCOUNTS_PAGING_LIMIT;
            this.setState({ isAccountsLoading: true });
            this.fetchAccounts(newOffset)
                .then(() => resolve())
                .catch(() => reject());
        });
    };

    onAccountClicked = (account: Account) => {
        const { currentAccount } = this.state;
        if (currentAccount === account) {
            return;
        }

        this.setState({
            currentAccount: account
        });
        this.fetchFoldersFromAccount(account);
    };

    fetchFoldersFromAccount = (account: Account) => {
        this.setState({
            isFoldersLoading: true,
            treePickedType: PickedType.None,
            treePickedId: '',
            processedFolders: []
        });

        return this.accountProvider
            .getRootFoldersByAccountId(account.accountId)
            .then(results => {
                const folders: Folder[] = results;
                if (!folders) {
                    this.setState({
                        floorPlansIsLoading: false,
                        isErrorModalOpened: true,
                        errorModalHeader: `Folder fetch error`,
                        errorModalText: 'No folders found'
                    });
                    return;
                }

                // TRANSFORM THE FOLDERS INTO TREE VIEW
                const accountTree: RenderTree = {
                    id: account.accountId,
                    name: account.name,
                    children: [],
                    path: ''
                };

                const treeNodes: RenderTree[] = folders.map(element => {
                    const newItem = {
                        id: element.id,
                        name: element.name,
                        children: [],
                        path: `${element.id}__`
                    };
                    return newItem as RenderTree;
                });

                accountTree.children = treeNodes;

                this.setState({
                    isFoldersLoading: false,
                    folders,
                    currentNodeTree: accountTree
                });
            })
            .catch(error => {
                // tslint:disable-next-line:no-console
                console.error(error);
                const { status } = error.response;
                if (status === 401) {
                    const { onAuthError } = this.props;
                    if (!onAuthError) {
                        return;
                    }
                    onAuthError();
                    return;
                }
                this.setState({
                    floorPlansIsLoading: false,
                    isErrorModalOpened: true,
                    errorModalHeader: `Floorplan fetch error ${status}`,
                    errorModalText: error.stack_trace
                });
            });
    };

    deepTreeSearch = (treeData: RenderTree, searchId: string) => {
        let foundTree: RenderTree | undefined;
        let searchLevel: RenderTree[] | undefined = treeData.children;
        do {
            foundTree = searchLevel?.find((e: RenderTree) => e.id === searchId);
            let tempLevel: any = [];
            searchLevel?.forEach((element: RenderTree) => {
                tempLevel = tempLevel.concat(element.children);
            });
            searchLevel = tempLevel;
        } while (!foundTree);
        return foundTree;
    };

    updateChildrenOnPath = (
        treeData: RenderTree,
        nodeId: string,
        children: RenderTree[]
    ) => {
        treeData.children!.forEach(element => {
            if (_.isEqual(element.id, nodeId)) {
                element.children = children;
            } else {
                this.updateChildrenOnPath(element, nodeId, children);
            }
        });
    };

    onNodeSelected = (
        treeData: RenderTree,
        nodeId: string,
        nodeLabel: string,
        updateCallback: (nodeIds: string[]) => void
    ) => {
        const { folders, currentAccount, processedFolders } = this.state;
        if (currentAccount!.accountId === nodeId) {
            this.setState({
                treePickedType: PickedType.None,
                treePickedId: '',
                treePickedName: ''
            });
            return;
        }
        this.setState({ treeIsLoading: true });
        // CHECK PICK TYPE
        // FOLDER
        const currentNodePos = this.deepTreeSearch(treeData, nodeId);
        if (folders.find(e => e.id === nodeId)) {
            // DID WE ALREADY CHECK FOR CHILDREN?
            if (processedFolders.find(e => e === nodeId)) {
                // FOLDER CHILDREN
                const firstChild = currentNodePos.children![0];
                if (!firstChild) {
                    // NO CHILDREN CAN DOWNLOAD
                    this.setState({
                        treePickedType: PickedType.Folder,
                        treePickedId: '',
                        treePickedName: '',
                        currentNodeTree: treeData,
                        treeIsLoading: false
                    });
                    return;
                }
                if (folders.find(e => e.id === firstChild.id)) {
                    // DO NOTHING CAN'T DOWNLOAD FROM THIS FOLDER
                    this.setState({
                        treePickedType: PickedType.None,
                        treePickedId: '',
                        treePickedName: '',
                        currentNodeTree: treeData,
                        treeIsLoading: false
                    });
                    return;
                }
                // FLOORPLAN CHILDREN CAN DOWNLOAD
                this.setState({
                    treePickedType: PickedType.Folder,
                    treePickedId: nodeId,
                    treePickedName: currentNodePos.name,
                    currentNodeTree: treeData,
                    treeIsLoading: false
                });
                return;
            }
            // EMPTY
            // CHECK FOR FOLDER CHILDREN FIRST
            return this.accountProvider
                .getChildrenFolderByFolderId(currentAccount!.accountId, nodeId)
                .then((results: Folder[]) => {
                    if (results.length > 0) {
                        const nodes: RenderTree[] = results.map(element => {
                            const newItem = {
                                id: element.id,
                                name: element.name,
                                children: [],
                                path: `${currentNodePos.path}${element.id}__`
                            };
                            return newItem as RenderTree;
                        });
                        this.updateChildrenOnPath(treeData, nodeId, nodes);
                        const currentPath = currentNodePos.path.slice(0, -2);
                        updateCallback([
                            currentAccount!.accountId,
                            ...currentPath.split('__'),
                            ...nodes.map(e => e.id)
                        ]);
                        processedFolders.push(nodeId);
                        this.setState({
                            treePickedType: PickedType.None,
                            treePickedId: '',
                            treePickedName: '',
                            currentNodeTree: treeData,
                            treeIsLoading: false,
                            folders: folders.concat(results),
                            processedFolders
                        });
                        return;
                    } else {
                        return this.folderProvider
                            .fetchFloorplans(nodeId)
                            .then(floorplans => {
                                const nodes: RenderTree[] = floorplans.map(
                                    (element: RenderTree) => {
                                        const newItem = {
                                            ...element
                                        } as RenderTree;
                                        newItem.children = [];
                                        newItem.path = `${currentNodePos.path}${element.id}__`;
                                        return newItem;
                                    }
                                );
                                this.updateChildrenOnPath(
                                    treeData,
                                    nodeId,
                                    nodes
                                );
                                const currentPath = currentNodePos.path.slice(
                                    0,
                                    -2
                                );
                                updateCallback([
                                    currentAccount!.accountId,
                                    ...currentPath.split('__'),
                                    ...nodes.map(e => e.id)
                                ]);
                                processedFolders.push(nodeId);
                                // FOLDERS ARE NOT ALLOWED TO LOAD MARKERS BUT THEY CAN DOWNLOAD FILES
                                this.setState({
                                    treePickedType: PickedType.None,
                                    treePickedId: '',
                                    treePickedName: '',
                                    currentNodeTree: treeData,
                                    treeIsLoading: false,
                                    processedFolders
                                });
                                return;
                            })
                            .catch(error => {
                                // tslint:disable-next-line:no-console
                                console.error(error);
                                const { status } = error.response;
                                if (status === 401) {
                                    const { onAuthError } = this.props;
                                    if (!onAuthError) {
                                        return;
                                    }
                                    onAuthError();
                                    return;
                                }
                                this.setState({
                                    floorPlansIsLoading: false,
                                    isErrorModalOpened: true,
                                    errorModalHeader: 'Floorplan fetch error',
                                    errorModalText: error.stack_trace
                                });
                            });
                    }
                })
                .catch(error => {
                    // tslint:disable-next-line:no-console
                    console.error(error);
                    const { status } = error.response;
                    if (status === 401) {
                        const { onAuthError } = this.props;
                        if (!onAuthError) {
                            return;
                        }
                        onAuthError();
                        return;
                    }
                    this.setState({
                        floorPlansIsLoading: false,
                        isErrorModalOpened: true,
                        errorModalHeader: 'Floorplan fetch error',
                        errorModalText: error.stack_trace
                    });
                });
        }
        this.setState({
            treePickedType: PickedType.FloorPlan,
            treePickedId: nodeId,
            treePickedName: currentNodePos.name,
            treeIsLoading: false
        });

        this.fetchReplaceFloorplan(nodeId);
        return;
    };

    onNodeToggled = (nodeIds: string[]) => {
        return;
    };

    fetchReplaceFloorplan = (floorplanId: string) => {
        if (!floorplanId || floorplanId === '') {
            return;
        }

        this.setState({
            treeIsLoading: true,
            replaceFloorplan: null
        });
        this.floorplanProvider
            .getFloorplanById(floorplanId)
            .then(data => {
                this.setState({
                    treeIsLoading: false,
                    replaceFloorplan: data.floorplan
                });
            })
            .catch(error => {
                const { status } = error.response;
                if (status === 401) {
                    const { onAuthError } = this.props;
                    if (!onAuthError) {
                        return;
                    }
                    onAuthError();
                    return;
                }
                this.setState({
                    treeIsLoading: false,
                    isErrorModalOpened: true,
                    errorModalHeader: `Floorplan fetch error ${status}`,
                    errorModalText: error.stack_trace
                });
            });
    };

    onErrorModalClicked = () => {
        this.setState({ isErrorModalOpened: false });
    };

    onReplacementStart = () => {
        const { replaceFloorplan } = this.state;

        if (!replaceFloorplan) {
            return;
        }

        this.setState({
            isMarkersLoading: true,
            currentStage: WizardSteps.NewSelection
        });
        this.floorplanProvider
            .fetchMarkersInCanvas(replaceFloorplan.floorplan_id)
            .then(data => {
                const originalMarkerList = data.markers.map(
                    (e: CanvasMarker) => {
                        const markerItem = { ...e } as CanvasMarker;
                        markerItem.fits = true;
                        markerItem.final_coordinates = [0, 0];
                        return markerItem;
                    }
                );
                this.setState({
                    originalMarkerList
                });
            })
            .catch(error => {
                console.error(error);
                const { status } = error.response;
                if (status === 401) {
                    const { onAuthError } = this.props;
                    if (!onAuthError) {
                        return;
                    }
                    onAuthError();
                    return;
                }
                this.setState({
                    isErrorModalOpened: true,
                    errorModalHeader: 'Error retrieving floorplan markers',
                    errorModalText: error.message
                });
            })
            .finally(() => {
                this.setState({
                    isMarkersLoading: false
                });
            });
    };

    onFileChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { originalMarkerList, replaceFloorplan } = this.state;
        if (!replaceFloorplan) {
            return;
        }
        const inputFiles = event.target.files;
        if (inputFiles && inputFiles.length > 0) {
            const pdfFile = inputFiles[0];
            this.floorplanProvider
                .convertPDF2JPG(replaceFloorplan.floorplan_id, pdfFile)
                .then(data => {
                    this.setState({
                        newPDFFile: pdfFile,
                        candidateDimensions: data.image,
                        originalMarkerList: originalMarkerList.map(e => {
                            e.fits = true;
                            return e;
                        })
                    });
                    this.paintImageWithMarkers(data.image.image);
                })
                .catch(error => {
                    console.error(error);
                });
        }
    };

    paintImageWithMarkers = (image: string) => {
        const { originalMarkerList } = this.state;
        const canvas: HTMLCanvasElement = this.canvasRef.current;
        const ctx = canvas.getContext('2d');

        if (!ctx) {
            return;
        }

        const paintImage = new Image();
        paintImage.src = `data:image/png;base64,${image}`;

        paintImage.onload = () => {
            canvas.width = paintImage.width;
            canvas.height = paintImage.height;

            ctx.drawImage(paintImage, 0, 0);

            let verticalPadding = 12;
            originalMarkerList.forEach(marker => {
                const coords = marker.coordinates.split(',');
                const mx = parseFloat(coords[0]);
                const my = parseFloat(coords[1]);

                if (mx < paintImage.width && my < paintImage.height) {
                    ctx.fillStyle = 'green';
                    ctx.beginPath();
                    ctx.arc(mx, my, 10, 0, 2 * Math.PI);
                    ctx.fill();
                    marker.final_coordinates = [mx, my];
                } else {
                    ctx.fillStyle = 'red';
                    ctx.beginPath();
                    ctx.arc(
                        paintImage.width - 12,
                        verticalPadding,
                        10,
                        0,
                        2 * Math.PI
                    );
                    ctx.fill();
                    marker.fits = false;
                    marker.final_coordinates = [
                        paintImage.width - 12,
                        verticalPadding
                    ];
                    verticalPadding += 23;
                }
            });

            this.setState({
                originalMarkerList,
                newPDFImage: `data:image/jpeg;base64,${image}`,
                displayImage: paintImage
            });
        };
    };

    handleMouseMove = (event: React.MouseEvent<HTMLCanvasElement>) => {
        const { originalMarkerList } = this.state;
        const canvas = this.canvasRef.current;
        const rect = canvas.getBoundingClientRect();
        const x = event.clientX - rect.left;
        const y = event.clientY - rect.top;

        const hoveringMarker = originalMarkerList.find(marker => {
            const dx = x - marker.final_coordinates[0];
            const dy = y - marker.final_coordinates[1];
            return dx * dx + dy * dy < 45;
        });

        this.setState({
            hoveringMarker: hoveringMarker ? hoveringMarker : null
        });
    };

    handleMouseOut = () => {
        this.setState({ hoveringMarker: null });
    };

    handleVisibleCheck = (stage: WizardSteps) => {
        const { currentStage } = this.state;
        const { classes } = this.props;
        if (stage === currentStage) {
            return classes.currentTab;
        }
        return classes.otherTab;
    };

    fileToBlob = async (file: File) => {
        const arrayBuffet = await file.arrayBuffer();
        return new Blob([arrayBuffet], { type: file.type });
    };

    openThinClient = () => {
        const {
            originalMarkerList,
            newPDFFile,
            candidateDimensions
        } = this.state;

        if (!newPDFFile || !candidateDimensions) {
            return;
        }

        const boundingBox = candidateDimensions.dimensions.cropbox;
        const rotation = candidateDimensions.dimensions.rotation;
        const thinClientMarkerList = originalMarkerList.map(e => {
            e.new_xfdf_coordinates = this.translateCanvas2PDFCoords(
                boundingBox,
                rotation,
                e.final_coordinates,
                e.fits
            );
            const xfdf_marker: XFDFMarker = {
                id: e.id,
                room_type: e.room_type,
                name: e.name,
                coordinates: e.new_xfdf_coordinates,
                isWrong: !e.fits
            };
            return xfdf_marker;
        });
        let warningText: string | undefined;
        const notFittingCount = originalMarkerList.filter(e => !e.fits).length;
        if (notFittingCount > 0) {
            warningText = `There are ${notFittingCount} markers that didn't fit, they have been placed on random locations and have been highlighted in BRIGHT BLUE please adjust accordingly to save changes`;
        }
        this.fileToBlob(newPDFFile).then(blob => {
            this.setState({
                currentStage: WizardSteps.FloorplanEdit,
                thinClientMarkerList,
                fileBlob: blob,
                warningText,
                originalMarkerList
            });
        });
    };

    translateCanvas2PDFCoords = (
        boundingBox: number[],
        rotation: number,
        markerCanvasCoords: number[],
        fits: boolean
    ) => {
        // THIS FUNCTION TRANSLATE CANVAS COORDS BACK TO XFDF
        // 24 IS THE SIZE OF THE ANNOTATION ICON
        let newX = 0,
            newY = 0;
        if (fits) {
            switch (rotation) {
                case 0:
                    newX = markerCanvasCoords[0] + boundingBox[0];
                    newY = boundingBox[3] - markerCanvasCoords[1];
                    break;
                case 90:
                    newX = markerCanvasCoords[1] + boundingBox[0];
                    newY = markerCanvasCoords[0] + boundingBox[1];
                    break;
                case 180:
                    newX = boundingBox[2] - markerCanvasCoords[0];
                    newY = markerCanvasCoords[1] + boundingBox[1];
                    break;
                case 270:
                    newX = boundingBox[2] - markerCanvasCoords[1];
                    newY = boundingBox[3] - markerCanvasCoords[0];
                    break;
            }
        } else {
            // GENERATE RANDOM COORDINATES IF MARKER DOESN'T FIT
            newX =
                Math.random() * (boundingBox[2] - boundingBox[0]) +
                boundingBox[0];
            newY =
                Math.random() * (boundingBox[3] - boundingBox[1]) +
                boundingBox[1];
        }

        return `${newX - 12 < boundingBox[0] ? boundingBox[0] : newX - 12},${
            newY - 12 < boundingBox[1] ? boundingBox[1] : newY - 12
        },${newX + 12 > boundingBox[2] ? boundingBox[2] : newX + 12},${
            newY + 12 > boundingBox[3] ? boundingBox[3] : newY + 12
        }`;
    };

    onFinishMarkerOperations = (
        markerOperations: MarkerOperation[],
        updatedFileBlob: Blob
    ) => {
        // WE NEED TO CHECK IF ALL FAILED MARKERS WHERE CORRECTED
        // IF NOT TAKE THE RANDOMLY GENERATED COORDS AS THE NEW ONES
        const { originalMarkerList, replaceFloorplan } = this.state;
        const failedMarkers = originalMarkerList.filter(e => !e.fits);
        failedMarkers.forEach(e => {
            if (!markerOperations.find(o => o.marker_id === e.id)) {
                markerOperations.push({
                    floorplan_id: replaceFloorplan!.floorplan_id,
                    marker_id: e.id,
                    verb: 'UPDATE',
                    coordinates: e.new_xfdf_coordinates!,
                    name: e.name
                });
            }
        });
        // WE ALSO NEED TO CHECK WHICH MARKERS DIDN'T GET UPDATED TO SEND A JOB
        // TO UPDATE THEIR MARKERS
        const minimapUpdates = originalMarkerList.filter(
            e => !markerOperations.find(m => m.marker_id === e.id)
        );

        this.setState({
            correctionMarkerOperations: markerOperations,
            minimapMarkerUpdates: minimapUpdates,
            currentStage: WizardSteps.Confirmation,
            replacementBlob: updatedFileBlob
        });
    };

    onFinishProgress = () => {
        const { replacementBlob, replaceFloorplan } = this.state;

        if (!replacementBlob || !replaceFloorplan) {
            return;
        }

        this.setState(
            {
                currentStage: WizardSteps.Result,
                isMarkerjobsUploading: true,
                isPDFUploading: true
            },
            () => {
                this.sendFloorplanPayload(
                    replaceFloorplan.floorplan_id,
                    replacementBlob
                );
            }
        );
    };

    sendFloorplanPayload = (floorplanId: string, updatedFileBlob: Blob) => {
        const { replaceFloorplan, runningTasksGroup } = this.state;
        // MAKE A GROUP FOR DISPLAY
        const newGroupId = uuidv4();
        const newGroup = {
            id: newGroupId,
            name: `Updating floorplan: ${replaceFloorplan!.name}`,
            progress: 0,
            startedAt: Date.now(),
            elapsedTime: 0,
            total: 0,
            done: [],
            errored: [],
            tasks: []
        } as TaskGroup;
        runningTasksGroup.push(newGroup);
        this.setState({ runningTasksGroup });

        this.floorplanProvider
            .uploadFloorplanPDF(floorplanId, updatedFileBlob)
            .then(data => {
                const {
                    correctionMarkerOperations,
                    minimapMarkerUpdates
                } = this.state;

                if (
                    correctionMarkerOperations &&
                    correctionMarkerOperations.length > 0
                ) {
                    this.sendMarkerPayloads(
                        correctionMarkerOperations,
                        newGroupId
                    );
                }

                if (minimapMarkerUpdates && minimapMarkerUpdates.length > 0) {
                    this.sendMinimapUpdatePayloads(
                        minimapMarkerUpdates,
                        newGroupId
                    );
                }
                this.setState({
                    isPDFUploading: false,
                    isMarkerjobsUploading: false,
                    replacementBlob: null,
                    newPDFFile: null,
                    newPDFImage: null,
                    fileBlob: null,
                    displayImage: null,
                    candidateDimensions: null
                });
            })
            .catch(error => {
                console.error(error);
                const { status } = error.response;
                if (status === 401) {
                    const { onAuthError } = this.props;
                    if (!onAuthError) {
                        return;
                    }
                    onAuthError();
                    return;
                }
                this.setState({
                    isErrorModalOpened: true,
                    errorModalHeader: 'Error uploading replacement floorplan',
                    errorModalText: error.message
                });
            });
    };

    sendMarkerPayloads = (
        markerPayloads: MarkerOperation[],
        newGroupId: string
    ) => {
        this.setState({ isMarkerjobsUploading: true });
        const { currentAccount, runningTasksGroup } = this.state;
        const {
            rootStore: { adminStore }
        } = this.props;
        const userIdentifiers: UserIdentifiers = adminStore.userIdentifiers!;
        let processed = 0;

        markerPayloads.forEach(m => {
            const { runningTasks } = this.state;
            const payloadItem: JobRequest = {
                user_created_id: userIdentifiers.user_id,
                account_id: currentAccount ? currentAccount.accountId : userIdentifiers.account_id,
                job_type: `${JobTypes.MarkerOperations}`,
                additional_params: { ...m }
            };

            this.jobProvider
                .scheduleJob(payloadItem)
                .then(result => {
                    const newTask: RunningTasks = {
                        account: currentAccount,
                        export_name: `${m.verb}: ${m.name}`,
                        export_id: m.marker_id,
                        type: JobTypes.MarkerOperations,
                        task_id: result.job_id,
                        status: 102,
                        startedAt: Date.now(),
                        elapsedTime: 0,
                        progress: 0,
                        timer: 0,
                        static_progress_count: 0,
                        set_to_stop: false,
                        group_id: newGroupId
                    };

                    runningTasks.push(newTask);
                    const taskGroup = runningTasksGroup.find(
                        e => e.id === newGroupId
                    );
                    taskGroup!.total += 1;
                    const taskPos = taskGroup!.tasks.push(newTask);
                    adminStore.setCurrentExportTasks(
                        runningTasks,
                        MainTabs.Floorplan_Replace
                    );
                    adminStore.setCurrentExportGroups(
                        runningTasksGroup,
                        MainTabs.Floorplan_Replace
                    );
                    this.startTaskWatcher(newTask, taskPos);
                    this.startTimeInterval();
                })
                .catch(error => {
                    console.error(error);
                    const { status } = error.response;
                    if (status === 401) {
                        const { onAuthError } = this.props;
                        if (!onAuthError) {
                            return;
                        }
                        onAuthError();
                        return;
                    }
                    this.setState({
                        isErrorModalOpened: true,
                        errorModalHeader: 'Error creating marker jobs',
                        errorModalText: error.message
                    });
                })
                .finally(() => {
                    processed += 1;
                    if (processed === markerPayloads.length) {
                        this.setState({ isMarkerjobsUploading: false });
                    }
                });
        });
    };

    sendMinimapUpdatePayloads = (
        minimapMarkers: CanvasMarker[],
        newGroupId: string
    ) => {
        this.setState({ isMarkerjobsUploading: true });
        const { currentAccount, runningTasksGroup } = this.state;
        const {
            rootStore: { adminStore }
        } = this.props;
        const userIdentifiers: UserIdentifiers = adminStore.userIdentifiers!;
        let processed = 0;

        minimapMarkers.forEach(m => {
            const { runningTasks } = this.state;
            const payloadItem: JobRequest = {
                user_created_id: userIdentifiers.user_id,
                account_id: currentAccount ? currentAccount.accountId : userIdentifiers.account_id,
                job_type: `${JobTypes.ReGenerateMarkerMinimap}`,
                additional_params: { marker_id: m.id }
            };

            this.jobProvider
                .scheduleJob(payloadItem)
                .then(result => {
                    const newTask: RunningTasks = {
                        account: currentAccount,
                        export_name: `Updating minimap: ${m.name}`,
                        export_id: m.id,
                        type: JobTypes.MarkerOperations,
                        task_id: result.job_id,
                        status: 102,
                        startedAt: Date.now(),
                        elapsedTime: 0,
                        progress: 0,
                        timer: 0,
                        static_progress_count: 0,
                        set_to_stop: false,
                        group_id: newGroupId
                    };

                    runningTasks.push(newTask);
                    const taskGroup = runningTasksGroup.find(
                        e => e.id === newGroupId
                    );
                    taskGroup!.total += 1;
                    const taskPos = taskGroup!.tasks.push(newTask);
                    adminStore.setCurrentExportTasks(
                        runningTasks,
                        MainTabs.Floorplan_Replace
                    );
                    adminStore.setCurrentExportGroups(
                        runningTasksGroup,
                        MainTabs.Floorplan_Replace
                    );
                    this.startTaskWatcher(newTask, taskPos);
                    this.startTimeInterval();
                })
                .catch(error => {
                    console.error(error);
                    const { status } = error.response;
                    if (status === 401) {
                        const { onAuthError } = this.props;
                        if (!onAuthError) {
                            return;
                        }
                        onAuthError();
                        return;
                    }
                    this.setState({
                        isErrorModalOpened: true,
                        errorModalHeader: 'Error creating marker jobs',
                        errorModalText: error.message
                    });
                })
                .finally(() => {
                    processed += 1;
                    if (processed === minimapMarkers.length) {
                        this.setState({ isMarkerjobsUploading: false });
                    }
                });
        });
    };

    onGroupClear = () => {
        const { runningTasks, runningTasksGroup } = this.state;
        const pendingTasks = runningTasks.filter(e => e.status === 102);
        const pendingGroups = runningTasksGroup.filter(
            e => e.done.length + e.errored.length !== e.total
        );
        const {
            rootStore: { adminStore }
        } = this.props;
        adminStore.setCurrentExportTasks(
            pendingTasks,
            MainTabs.Floorplan_Replace
        );
        adminStore.setCurrentExportGroups(
            pendingGroups,
            MainTabs.Floorplan_Replace
        );
        this.setState({
            runningTasks: pendingTasks,
            runningTasksGroup: pendingGroups
        });
    };

    onJobMenuToggle = () => {
        const { isJobMenuOpen } = this.state;
        this.setState({ isJobMenuOpen: !isJobMenuOpen });
    };

    getJobCount = (needBoolean: Boolean) => {
        const { runningTasks } = this.state;

        const finishedCount = runningTasks.filter(e => e.status !== 102).length;
        const totalCount = runningTasks.length;

        if (needBoolean) {
            return finishedCount === totalCount;
        }

        return `${finishedCount}/${totalCount}`;
    };

    onGroupTaskDataPageOverBoundaryReached = (
        boundary: PageBoundary,
        nextPage: number
    ): Promise<void> => {
        return new Promise<void>((resolve, reject) => {
            const { currentGroupOffset, runningTasksGroup } = this.state;
            const totalTasks = runningTasksGroup.length;
            const isFirstPage = nextPage === 0;
            const isLastPage =
                nextPage === Math.ceil(totalTasks / ROWS_PER_PAGE) - 1;
            const newOffset = isFirstPage
                ? 0
                : isLastPage
                ? ROWS_PER_PAGE * Math.floor(totalTasks / ROWS_PER_PAGE)
                : boundary === PageBoundary.Upper
                ? currentGroupOffset + ROWS_PER_PAGE
                : currentGroupOffset - ROWS_PER_PAGE;
            const nextTasks = runningTasksGroup;
            // MANUAL OFFSET
            this.setState({
                runningTasksGroup: nextTasks.splice(newOffset, nextTasks.length)
            });
        });
    };

    onTaskClick = (task: RunningTasks) => {
        if (task.status === 102) {
            return;
        }
        if (task.status === 200) {
            return;
        }
        this.jobProvider
            .checkJobError(task.task_id)
            .then(data => {
                this.setState({
                    isErrorModalOpened: true,
                    errorModalHeader: data.error,
                    errorModalText: data.stack_trace
                });
            })
            .catch(error => {
                // tslint:disable-next-line:no-console
                console.error(error);
                this.setState({
                    isErrorModalOpened: true,
                    errorModalHeader: 'Error retrieveing Job information',
                    errorModalText: error.message
                });
            });
    };

    onRedoButtonClick = () => {
        this.setState({
            replaceFloorplan: null,
            correctionMarkerOperations: [],
            fileBlob: null,
            originalMarkerList: [],
            currentStage: WizardSteps.FloorplanSelection
        });
    };

    onBackButtonClick = () => {
        const { currentStage } = this.state;

        switch (currentStage) {
            case WizardSteps.NewSelection:
                    this.setState({
                        currentStage: WizardSteps.FloorplanSelection,
                        originalMarkerList: []
                    });
                break;
            case WizardSteps.FloorplanEdit:
                this.setState({
                    currentStage: WizardSteps.NewSelection,
                    newPDFImage: null,
                    newPDFFile: null
                });
                break;
            case WizardSteps.Confirmation:
                this.setState({
                    currentStage: WizardSteps.FloorplanEdit
                });
                break;
            case WizardSteps.Confirmation:
                this.setState({
                    currentStage: WizardSteps.Confirmation
                });
                break;
            default:
                break;
        }
    }
    public render() {
        const { classes } = this.props;
        const {
            currentStage,
            loading,
            accounts,
            isAccountsLoading,
            totalAccounts,
            currentAccountOffset,
            currentAccount,
            folders,
            isFoldersLoading,
            currentNodeTree,
            treeIsLoading,
            treePickedType,
            treePickedId,
            replaceFloorplan,
            isErrorModalOpened,
            errorModalHeader,
            errorModalText,
            originalMarkerList,
            newPDFFile,
            newPDFImage,
            hoveringMarker,
            isMarkersLoading,
            thinClientMarkerList,
            fileBlob,
            correctionMarkerOperations,
            displayImage,
            warningText,
            isMarkerjobsUploading,
            isPDFUploading,
            runningTasks,
            runningTasksGroup,
            isJobMenuOpen,
            currentGroupOffset
        } = this.state;

        const markerListItem = originalMarkerList.map(e => {
            return new SimpleListItem(e.id, e.name, e.id);
        });
        const failedCount = originalMarkerList.filter(e => !e.fits).length;
        const failedCheck = failedCount <= 0;
        const failedList = originalMarkerList
            .filter(e => !e.fits)
            .map(e => {
                return new SimpleListItem(e.id, e.name, e.id);
            });
        const correctionList = correctionMarkerOperations.map(e => {
            return new SimpleListItem(
                e.marker_id,
                `${e.verb} ${e.name}`,
                e.marker_id
            );
        });
        const container =
            window !== undefined ? () => window.document.body : undefined;

        return (
            <div className={classes.container} data-testid="mainRender">
                <div className={`${classes.container}`}>
                    <CCSpinner
                        classes={{ darkOverlay: classes.spinnerOverlay }}
                        loading={loading}
                        label="Fetching accounts ..."
                        overlayVisible={true}
                        size={100}
                    />
                    <Stepper activeStep={currentStage} alternativeLabel>
                        {WIZARD_STEPS.map(step => (
                            <Step key={step.id}>
                                <StepLabel>{step.label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                    <div className={classes.stepsContentHolder}>
                        {currentStage === WizardSteps.FloorplanSelection ? (
                            <React.Fragment>
                                {/* STEP #1 FLOORPLAN SELECTION */}
                                <Button
                                    className={classes.replaceWizardButton}
                                    variant="contained"
                                    color="primary"
                                    onClick={this.onReplacementStart}
                                    disabled={!replaceFloorplan}
                                >
                                    Replace Floorplan PDF
                                </Button>
                                <AccountFolderMarker
                                    className={classes.animContainer}
                                    rowsPerPage={ROWS_PER_PAGE}
                                    accounts={accounts}
                                    accountsIsLoading={isAccountsLoading}
                                    accountsTotalItems={totalAccounts}
                                    accountsItemsOffset={currentAccountOffset}
                                    currentAccountName={
                                        currentAccount
                                            ? currentAccount.name
                                            : ''
                                    }
                                    folders={folders}
                                    folderIsLoading={isFoldersLoading}
                                    onAccountClick={this.onAccountClicked}
                                    onAccountDataPageOverBoundary={
                                        this
                                            .onAccountDataPageOverBoundaryReached
                                    }
                                    currentNodeTree={currentNodeTree}
                                    treeIsLoading={treeIsLoading}
                                    onNodeSelected={this.onNodeSelected}
                                    onNodeToggled={this.onNodeToggled}
                                    pickedType={treePickedType}
                                    pickedId={treePickedId}
                                    collapseAccounts={false}
                                    onAccountSearch={this.searchAccounts}
                                />
                            </React.Fragment>
                        ) : currentStage === WizardSteps.NewSelection ? (
                            <React.Fragment>
                                {/* STEP #2 REPLACEMENT SELECTION */}
                                <Grid
                                    container
                                    spacing={1}
                                    alignItems="flex-start"
                                    data-testid="replacement-selection"
                                >
                                    <Grid item xs={4}>
                                        <Typography
                                            variant="h6"
                                            className={`${classes.heading} ${classes.ellipsis}`}
                                        >
                                            Floorplan markers
                                        </Typography>
                                        <SimpleListPagination
                                            className={classes.simpleList}
                                            keepItemSelected={false}
                                            items={markerListItem}
                                            canDeleteListItem={false}
                                            rowsPerPage={10}
                                            totalItems={
                                                originalMarkerList.length
                                            }
                                            offset={0}
                                            isLoading={isMarkersLoading}
                                            noItemsLabel="No markers found"
                                        />
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                className={classes.backButton}
                                                onClick={this.onBackButtonClick}
                                            >
                                                Back
                                            </Button>
                                    </Grid>
                                    <Grid container alignItems="center" xs={8}>
                                        <Grid item xs={12}>
                                            <Typography
                                                variant="h6"
                                                className={`${classes.heading} ${classes.ellipsis}`}
                                            >
                                                Select replacement PDF
                                            </Typography>
                                            <input
                                                type="file"
                                                id="replacement-input"
                                                className="input-file"
                                                accept=".pdf"
                                                data-testid="replacement-input"
                                                onChange={this.onFileChanged}
                                                disabled={isMarkersLoading}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            className={classes.flexRow}
                                            xs={12}
                                        >
                                            {newPDFFile && newPDFImage && (
                                                <React.Fragment>
                                                    <Alert
                                                        severity={`${
                                                            failedCheck
                                                                ? 'success'
                                                                : 'warning'
                                                        }`}
                                                        className={
                                                            classes.replacementInfoAlert
                                                        }
                                                    >
                                                        {failedCheck
                                                            ? 'The replacement floorplan is compatible.'
                                                            : `There are ${failedCount} markers that don't fit in the replacement PDF, Do you wish to continue?`}
                                                    </Alert>
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        disabled={
                                                            !newPDFFile &&
                                                            !newPDFImage
                                                        }
                                                        className={
                                                            classes.continueButton
                                                        }
                                                        onClick={
                                                            this.openThinClient
                                                        }
                                                    >
                                                        Continue
                                                    </Button>
                                                </React.Fragment>
                                            )}
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            className={classes.relativeRow}
                                        >
                                            {hoveringMarker && (
                                                <div
                                                    style={{
                                                        borderColor: `${
                                                            hoveringMarker.fits
                                                                ? 'green'
                                                                : 'red'
                                                        }`,
                                                        backgroundColor: `${
                                                            hoveringMarker.fits
                                                                ? 'lightgreen'
                                                                : 'orange'
                                                        }`
                                                    }}
                                                    className={
                                                        classes.markerHoverDisplay
                                                    }
                                                >
                                                    {hoveringMarker.name}
                                                </div>
                                            )}
                                            <canvas
                                                ref={this.canvasRef}
                                                id="canvas-pdf"
                                                width={600}
                                                height={500}
                                                className={
                                                    classes.replacementCanvas
                                                }
                                                onMouseMove={
                                                    this.handleMouseMove
                                                }
                                                onMouseOut={this.handleMouseOut}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </React.Fragment>
                        ) : currentStage === WizardSteps.FloorplanEdit ? (
                            <React.Fragment>
                                {/* STEP #3 FLOORPLAN THIN CLIENT CORRECTIONS */}
                                <Grid
                                    container
                                    spacing={1}
                                    alignItems="flex-start"
                                    data-testid="thin-client-container"
                                >
                                    <Grid item xs={failedCheck ? 1 : 3}>
                                        {!failedCheck && (
                                            <React.Fragment>
                                                <Typography
                                                    variant="h6"
                                                    className={`${classes.heading} ${classes.ellipsis}`}
                                                >
                                                    Failed markers
                                                </Typography>
                                                <SimpleListPagination
                                                    className={
                                                        classes.simpleList
                                                    }
                                                    keepItemSelected={false}
                                                    items={failedList}
                                                    canDeleteListItem={false}
                                                    rowsPerPage={10}
                                                    totalItems={failedCount}
                                                    offset={0}
                                                    noItemsLabel="No failed markers"
                                                />
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    className={classes.backButton}
                                                    onClick={this.onBackButtonClick}
                                                >
                                                    Back
                                                </Button>
                                            </React.Fragment>
                                        )}
                                    </Grid>
                                    <Grid item xs={failedCheck ? 10 : 8}>
                                        <ThinClient
                                            className={
                                                classes.thinClientContainer
                                            }
                                            floorplanName={`${
                                                replaceFloorplan!.name
                                            } corrections`}
                                            floorplanId={
                                                replaceFloorplan!.floorplan_id
                                            }
                                            fileBlob={fileBlob}
                                            enabledEvents={ENABLED_EVENTS}
                                            existingMarkers={
                                                thinClientMarkerList
                                            }
                                            onFinishMarkerOperations={
                                                this.onFinishMarkerOperations
                                            }
                                            isJSONReady={true}
                                            warningText={warningText}
                                        />
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            className={classes.backButton}
                                            onClick={this.onBackButtonClick}
                                        >
                                            Back
                                        </Button>
                                    </Grid>
                                    <Grid item xs={1}></Grid>
                                </Grid>
                            </React.Fragment>
                        ) : currentStage === WizardSteps.Confirmation ? (
                            <React.Fragment>
                                <Grid
                                    container
                                    spacing={1}
                                    alignItems="flex-start"
                                    className={classes.confirmationContainer}
                                    data-testid="replacement-confirmation"
                                >
                                    <Grid item xs={1}></Grid>
                                    <Grid item xs={4}>
                                        <Typography
                                            variant="h6"
                                            className={`${classes.heading} ${classes.ellipsis}`}
                                        >
                                            Marker correction operations
                                        </Typography>
                                        <SimpleListPagination
                                            className={classes.simpleList}
                                            keepItemSelected={false}
                                            items={correctionList}
                                            canDeleteListItem={false}
                                            rowsPerPage={10}
                                            totalItems={correctionList.length}
                                            offset={0}
                                            noItemsLabel="No markers corrections done"
                                        />
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            className={classes.backButton}
                                            onClick={this.onBackButtonClick}
                                        >
                                            Back
                                        </Button>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={6}
                                        style={{ paddingLeft: '1em' }}
                                    >
                                        <Typography
                                            variant="h6"
                                            className={`${classes.heading} ${classes.ellipsis}`}
                                        >
                                            New Floorplan image
                                        </Typography>
                                        <br />
                                        {displayImage && (
                                            <img src={displayImage.src} />
                                        )}
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            className={classes.nextButton}
                                            onClick={this.onFinishProgress}
                                        >
                                            Submit replacement
                                        </Button>
                                    </Grid>
                                    <Grid item xs={1}></Grid>
                                </Grid>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <Grid
                                    container
                                    spacing={1}
                                    alignItems="center"
                                    data-testid="result-screen"
                                >
                                    <Grid item xs={1}></Grid>
                                    <Grid item xs={10}>
                                        <Card className={classes.statusCard}>
                                            <CardContent>
                                                <Typography
                                                    variant="h5"
                                                    component="h2"
                                                >
                                                    New Floorplan pdf upload
                                                </Typography>
                                                <Typography
                                                    color="textSecondary"
                                                    className={
                                                        classes.statusDisplay
                                                    }
                                                >
                                                    {isPDFUploading ? (
                                                        <React.Fragment>
                                                            <CircularProgress />{' '}
                                                            Uploading . . .
                                                        </React.Fragment>
                                                    ) : (
                                                        <React.Fragment>
                                                            <CheckCircleOutline
                                                                className={
                                                                    classes.successColor
                                                                }
                                                            />{' '}
                                                            Succesfully uploaded
                                                        </React.Fragment>
                                                    )}
                                                </Typography>
                                                <Typography
                                                    variant="h5"
                                                    component="h2"
                                                >
                                                    Marker update jobs
                                                </Typography>
                                                <Typography
                                                    color="textSecondary"
                                                    className={
                                                        classes.statusDisplay
                                                    }
                                                >
                                                    {!correctionMarkerOperations ||
                                                    correctionMarkerOperations.length <=
                                                        0 ? (
                                                        <React.Fragment>
                                                            No marker updates
                                                            were made
                                                        </React.Fragment>
                                                    ) : isMarkerjobsUploading ? (
                                                        <React.Fragment>
                                                            <CircularProgress />{' '}
                                                            Creating jobs . . .
                                                        </React.Fragment>
                                                    ) : (
                                                        <React.Fragment>
                                                            <CheckCircleOutline
                                                                className={
                                                                    classes.successColor
                                                                }
                                                            />{' '}
                                                            Succesfully created
                                                            jobs you can track
                                                            their process on the
                                                            RUNNING JOBS drawer
                                                        </React.Fragment>
                                                    )}
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        data-testid="floorplan-replacement-redo"
                                                        className={
                                                            classes.redoButton
                                                        }
                                                        onClick={
                                                            this
                                                                .onRedoButtonClick
                                                        }
                                                    >
                                                        Run another replacement
                                                    </Button>
                                                </Typography>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                    <Grid item xs={1}></Grid>
                                </Grid>
                            </React.Fragment>
                        )}
                    </div>
                </div>
                <SimpleDialog
                    open={isErrorModalOpened}
                    titleText={errorModalHeader}
                    contentText={errorModalText}
                    buttonCancelLabel=""
                    onDialogResult={this.onErrorModalClicked}
                />
                {runningTasks && runningTasks.length > 0 && (
                    <React.Fragment>
                        <Button
                            className={classes.jobMenuButton}
                            variant="contained"
                            color="primary"
                            data-testid="marker-detail-job-drawer-button"
                            onClick={this.onJobMenuToggle}
                        >
                            Running Jobs
                            <Chip
                                label={this.getJobCount(false)}
                                className={`${
                                    this.getJobCount(true)
                                        ? classes.finishedBadge
                                        : classes.notFinishedBadge
                                }`}
                            />
                        </Button>
                        <Drawer
                            container={container}
                            variant={'temporary'}
                            anchor={'right'}
                            open={isJobMenuOpen}
                            onClose={this.onJobMenuToggle}
                            classes={{
                                paper: classes.paper
                            }}
                        >
                            <RunningGroupTasksList
                                className={classes.tasksContainerSize}
                                tasks={runningTasks}
                                groups={runningTasksGroup}
                                rowsPerPage={ROWS_PER_PAGE}
                                groupItemsOffset={currentGroupOffset}
                                listName="Running tasks"
                                onGroupDataPageOverBoundary={
                                    this.onGroupTaskDataPageOverBoundaryReached
                                }
                                onGroupClear={this.onGroupClear}
                                onJobItemClick={this.onTaskClick}
                            />
                        </Drawer>
                    </React.Fragment>
                )}
            </div>
        );
    }
}

const MUIComponent = withStyles(styles)(FloorplanReplace);
export { MUIComponent as FloorplanReplace };
