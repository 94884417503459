import * as CryptoJS from 'crypto-js';

export class EncryptionHelper {
    static getAESKey(password: string): string {
        const passwordBytes = CryptoJS.enc.Utf16LE.parse(password);
        const sha1Hash = CryptoJS.SHA1(passwordBytes);
    
        const sha1HashToBase64 = sha1Hash.toString(CryptoJS.enc.Base64);
    
        // we are getting only the first 8 chars for actual key generation
        const sha1HashToBase64Short = sha1HashToBase64.substring(0,8);
    
        const AESKey = CryptoJS.enc.Utf16.parse(sha1HashToBase64Short);
        return AESKey;
    }
    
    static encryptText(aesKey: string, plainText: string): string {
        // Note that we are being lazy and the encryption key itself
        // is used as the initialization vector for AES
        const encryptedData = CryptoJS.AES.encrypt(plainText, aesKey, {
            iv: aesKey,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });
    
        // cryptHex will contain the cipher text in base64
        const encryptedText = encryptedData.toString();
        return encryptedText;
    }
    
    static decryptText(aesKey: string, encryptedText: string): string {
        const decryptedData = CryptoJS.AES.decrypt(encryptedText, aesKey, {
            iv: aesKey
        });
    
        const decryptedText = decryptedData.toString(CryptoJS.enc.Utf8);
        return decryptedText;
    }
}
