import * as React from 'react';
import { createStyles, TextField, Theme, withStyles } from "@material-ui/core";
import { WithStyles } from '@material-ui/styles';
import { SimpleList, SimpleListItem } from '../../shared/components/simple-list';
import { Autocomplete } from '@material-ui/lab';

const styles = (theme: Theme) => createStyles({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column'
    },
    list: {
        flex: '1 0',
        overflow: 'auto',
        boxShadow: 'none',
        '& p': {
            whiteSpace: 'break-spaces'
        }
    },
    selectInput: {
        margin: '1em'
    }
});

interface Props extends WithStyles<typeof styles> {
    className?: string;
    formCurrentValue: string[];
    formKey: string;
    formOptions: string[];
    canEdit: boolean;
    onValueChange?: (formKey: string, formValue: string[]) => void;
}

interface States {
    itemList: SimpleListItem[];
    autoCompleteValue: string | null;
    autoCompleteKey: string;
}

class SimpleSelectInput extends React.Component<Props, States> {

    generateRandomKey = () => {
        const randomKey = Math.random().toString(36).substring(7);
        return randomKey;
    };

    state: States = {
        itemList: [],
        autoCompleteValue: null,
        autoCompleteKey: this.generateRandomKey()
    }

    componentDidMount() {
        this.formatItemList();
    }

    formatItemList = () => {
        const { formCurrentValue } = this.props;

        if (!formCurrentValue) {
            return;
        }

        const itemList = formCurrentValue.map(e => {
            return new SimpleListItem(e, e);
        });

        this.setState({itemList});
    }

    onDeleteItemClicked = (listItem: SimpleListItem) => {
        const { onValueChange, formKey } = this.props;

        if (!onValueChange) {
            return;
        }

        const { itemList } = this.state;
        const delIndex = itemList.findIndex(e => e.id === listItem.id);
        itemList.splice(delIndex, 1);

        this.setState({itemList});
        onValueChange(formKey, itemList.map(e => e.label!));
    }

    onAddItem = (item: string) => {
        const { onValueChange, formKey } = this.props;

        if (!onValueChange) {
            return;
        }

        const { itemList } = this.state;

        if (itemList.find(e => e.id === item)) {
            return;
        }

        itemList.push(new SimpleListItem(item, item));

        this.setState({itemList});
        onValueChange(formKey, itemList.map(e => e.label!));
    }

    onItemPick = (e: React.ChangeEvent<{}>, value: string | null) => {
        if (!value) {
            return;
        }

        this.onAddItem(value);
    }

    clearAutocompleteSelector = (evt: React.FocusEvent<HTMLDivElement> | undefined) => {
        this.setState({
            autoCompleteValue: null,
            autoCompleteKey: this.generateRandomKey()
        });
        return;
    }

    public render() {
        const {
            className,
            classes,
            canEdit,
            formKey,
            formOptions
        } = this.props;
        const {
            itemList,
            autoCompleteKey,
            autoCompleteValue
        } = this.state;

        return (
            <div className={`${classes.root} ${className}`} data-testid="simple-select-picker">
                <div className={`${classes.container}`}>
                    <SimpleList
                        className={classes.list}
                        items={itemList}
                        noItemsLabel={"No items found"}
                        canDeleteListItem={canEdit}
                        keepItemSelected={false}
                        onDeleteItemClick={this.onDeleteItemClicked}
                    />
                    {
                        canEdit &&
                        <Autocomplete
                            id={`combo-box-simple-picker-${formKey}`}
                            key={autoCompleteKey}
                            className={classes.selectInput}
                            options={formOptions}
                            getOptionLabel={(option) => option}
                            renderInput={(params) => <TextField {...params} label="Add an item" variant="standard" />}
                            onChange={(e, pickedValue) => this.onItemPick(e, pickedValue)}
                            data-testid="simple-select-form-input"
                            value={autoCompleteValue}
                            blurOnSelect={true}
                            clearOnBlur={true}
                            onBlur={this.clearAutocompleteSelector}
                        />
                    }
                </div>
            </div>
        );
    }
}

const MUIComponent = withStyles(styles)(SimpleSelectInput);
export {MUIComponent as SimpleSelectInput}