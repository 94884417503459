import * as React from 'react';

import {
    Button,
    Divider,
    Link,
    TextField,
    Typography
} from '@material-ui/core';
import {
    createStyles,
    WithStyles,
    withStyles
} from '@material-ui/core/styles';
import { Theme } from '@material-ui/core/styles';
import ReCAPTCHA from 'react-google-recaptcha';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { withPasswordShowHide } from '../../with-password-show-hide';

const TextFieldWithPasswordShowHide = withPasswordShowHide(TextField);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const styles = (theme: Theme) => createStyles({
    root: {},
    divider: {},
    title: {},
    error: {},
    loginButton: {},
    backButton: {
        fontWeight: 'normal',
        marginBottom: '0.75em',
        padding: '0.3em'
    },
    footerTitle: {},
    footerButton: {},
    input: {},
    inputLabel: {},
    link: {
        cursor: 'pointer'
    },
    wizardContainer: {},
    subtitle: {},
    textField: {},
    hidePasswordButton: {
        padding: 0,
        '&:hover': {
            backgroundColor: 'transparent'
        }
    }
});

interface Props extends WithStyles<typeof styles> {
    className?: string;
    email?: string;
    error?: string;
    title: string;
    hideEmail?:boolean;
    subtitle?: string;
    buttonText: string;
    footerTitleText: string;
    footerButtonText: string;
    captchaEnabled: boolean;
    captchaSiteKey: string;
    onLoginClick?: (password: string, captchaToken: string) => void;
    onForgotPasswordClick?: () => void;
    onFooterButtonClick?: () => void;
    onBackButtonClick?: () => void;
}

interface States {
    login: string;
    isFormValid: boolean;
    password: string;
    captchaToken: string;
}

class LoginWizardStepPassword extends React.Component<Props, States> {
    public static defaultProps = {
        captchaEnabled: false,
        captchaSiteKey: '',
        hideEmail: true
    };

    state = {
        login: this.props.email ? this.props.email : '',
        password: '',
        isFormValid: (!!this.props.email && this.props.email.trim().length > 0),
        captchaToken: ''
    };

    emailRef: React.RefObject<TextValidator> = React.createRef();
    captchaRef: React.RefObject<ReCAPTCHA> = React.createRef();

    captchaReset = () => {
        const {
            state: {
                login
            },
            emailRef
        } = this;

        if (login && emailRef && emailRef.current) {
            emailRef.current.validate(login);
        }

        const captcha = this.captchaRef.current;
        if (!captcha) {
            return;
        }

        captcha.reset();
        this.setState({
            captchaToken: ''
        });
    }

    onValidate = (isValid: boolean) => {
        this.setState({isFormValid: isValid});
    }

    onEmailChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {value} = event.target as HTMLInputElement;
        this.setState({
            login: value
        });
    };

    onTextFieldChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            password: event.target.value
        });
    };

    onForgotPasswordClicked = (event: React.MouseEvent<HTMLAnchorElement> & React.MouseEvent<HTMLElement>) => {
        event.preventDefault();

        const { onForgotPasswordClick } = this.props;
        if (onForgotPasswordClick) {
            onForgotPasswordClick();
        }
    };
    
    onSubmitted = (event: React.FormEvent & React.SyntheticEvent<unknown>) => {
        event.preventDefault();

        const { onLoginClick } = this.props;
        const {
            password,
            captchaToken
        } = this.state;
        if (onLoginClick) {
            onLoginClick(password, captchaToken);
            this.captchaReset();
        }
    };
    
    onCaptchaChange = (token: string|null) => {
        if (!token) {
            this.captchaReset();
            return;
        }

        this.setState({
            captchaToken: token
        });
    }

    public render() {
        const {
            classes,
            className,
            error,
            buttonText,
            title,
            subtitle,
            footerTitleText,
            footerButtonText,
            captchaEnabled,
            captchaSiteKey,
            hideEmail,
            onFooterButtonClick,
            onForgotPasswordClick,
            onBackButtonClick
        } = this.props;
        
        const {
            login,
            password,
            isFormValid,
            captchaToken
        } = this.state;

        const rootClassNames = classes.root + (className ? ` ${className}` : '');
        const isCaptchaEnabled: boolean = (captchaEnabled && !!captchaSiteKey);
        const isLoginButtonDisabled: boolean = 
            ((password.length < 1) || (isCaptchaEnabled && !captchaToken) || !isFormValid);

        return (
            <div className={rootClassNames}>
                <Typography
                    variant="h6"
                    className={classes.title}
                >
                    {title}
                </Typography>
                <Typography
                    variant="h6"
                    className={classes.error}
                >
                    {((error!== undefined) && (error.length > 0)) ? error : '\u00a0'} {/* unicode non breaking space */}
                </Typography>
                <ValidatorForm
                    className={classes.wizardContainer}
                    instantValidate={true}
                    onSubmit={this.onSubmitted}
                >
                    <Typography
                        className={classes.subtitle}
                        variant="h6"
                    >
                        {subtitle ? subtitle : '\u00a0'} {/* unicode non breaking space */}
                    </Typography>
                    { !hideEmail ? 
                        <TextValidator
                            id="email"
                            className={classes.textField}
                            ref={this.emailRef}
                            label="Email Address"
                            type="email"
                            name="email"
                            autoComplete="email"
                            placeholder="email"
                            margin="normal"
                            variant="outlined"
                            value={login}
                            onChange={this.onEmailChanged}
                            validatorListener={this.onValidate}
                            InputProps={{ 
                                classes: { 
                                    input: classes.input
                                }
                            }}
                            InputLabelProps={{
                                classes: {
                                    root: classes.inputLabel
                                }
                            }}
                            validators={['required', 'isEmail']}
                            errorMessages={['Email is required', 'Please enter a valid email address']}
                        />
                        : ''
                    }
                    <TextFieldWithPasswordShowHide
                        className={classes.textField}
                        iconClassName={classes.hidePasswordButton}
                        id="password"
                        name="password"
                        label="Password"
                        type="password"
                        placeholder="password"
                        autoComplete="current-password"
                        margin="normal"
                        variant="outlined"
                        autoFocus={true}
                        onChange={this.onTextFieldChanged}
                        InputProps={{ 
                            classes: { 
                                input: classes.input
                            }
                        }}
                        InputLabelProps={{
                            classes: {
                                root: classes.inputLabel
                            }
                        }}
                    />
                    {
                        isCaptchaEnabled ?
                            <ReCAPTCHA
                                ref={this.captchaRef}
                                sitekey={captchaSiteKey}
                                onChange={this.onCaptchaChange}
                                data-testid="captchaHolder"
                            />
                            :''
                    }
                    <Button
                        className={classes.loginButton}
                        color="secondary"
                        variant="contained"
                        type="submit"
                        disabled={isLoginButtonDisabled}
                    >
                        {buttonText}
                    </Button>
                    { onBackButtonClick ?
                        <Button
                            className={classes.backButton}
                            variant="outlined"
                            type="button"
                            onClick={onBackButtonClick}
                        >
                            &#x3C; Back
                        </Button>
                        : ''
                    }
                    { onForgotPasswordClick ? 
                        <Link
                            className={classes.link}
                            color="secondary"
                            variant="subtitle2"
                            onClick={this.onForgotPasswordClicked}
                        >
                            Forgot Password?
                        </Link>
                        : ''
                    }
                    { onFooterButtonClick ?
                        <Divider
                            className={classes.divider}
                            variant="middle"
                        />
                        : ''
                    }
                    { onFooterButtonClick ?
                        <div className={classes.wizardContainer}>
                            <Typography
                                className={classes.footerTitle}
                                variant="h6"
                            >
                                {footerTitleText}
                            </Typography>
                            <Button
                                className={classes.footerButton}
                                variant="outlined"
                                onClick={onFooterButtonClick}
                            >
                                {footerButtonText}
                            </Button>
                        </div>
                        : ''
                    }
                </ValidatorForm>
            </div>
        );
    }
}

const MUIComponent = withStyles(styles)(LoginWizardStepPassword);
export {MUIComponent as LoginWizardStepPassword};
