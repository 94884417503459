import { Team } from './../shared/domain/team';
import { HttpConnectorAdapter } from './../adapters/http-connector-adapter';
import { UserFull } from '../models/user';

export default class TeamProvider {
    private endpointCore: string;
    private endpointAdmin: string;

    constructor() {
        this.endpointCore = `${process.env.REACT_APP_BASE_API_URL}teams/`;
        this.endpointAdmin = `${process.env.REACT_APP_ADMIN_TOOL_API}team/`;
    };

    init = () => {
        return HttpConnectorAdapter;
    };

    getTeamInfoById = (teamId: string) => {
        return this.init().getWithCredentials(`${this.endpointCore}${teamId}`).then(response => {
            const { team } = response.data.results;
            const accountId = team.accounts[0];
            return { accountId };
        });
    }

    fetchTeamFromTeamId = (teamId: string) => {
        return this.init().getWithCredentials(`${this.endpointCore}${teamId}`).then(response => {
            const { results } = response.data;
            const entityTeam: Team = results.team as Team;
            return entityTeam;
        });
    }

    fetchTeamUsers = (teamId: string) => {
        return this.init().getWithCredentials(`${this.endpointAdmin}users/${teamId}`).then(response => {
            const statusCode = response.status;

            return { status: statusCode, users: response.data as UserFull[] };
        });
    }

    updateTeam = (teamId: string, team: Team) => {
        delete team['_rev'];
        delete team['_sync'];
        const headers = { 'Content-Type': 'application/json' };
        return this.init().putWithCredentials(`${this.endpointAdmin}/${teamId}`, JSON.stringify(team), headers).then(response => {
            const statusCode = response.status;

            return { status: statusCode, team: response.data as Team };
        });
    }

    createTeam = (team: Team) => {
        const formattedTeam: any = team;
        delete formattedTeam['team_id'];
        delete formattedTeam['timestamp'];
        const headers = { 'Content-Type': 'application/json' };
        return this.init().postWithCredentials(`${this.endpointAdmin}`, JSON.stringify(formattedTeam), headers).then(response => {
            const statusCode = response.status;

            return { status: statusCode, team: response.data as Team };
        });
    }
}