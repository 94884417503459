import { MuiThemeProvider } from '@material-ui/core';
import { Provider } from 'mobx-react';
import { HistoryAdapter } from 'mobx-state-router';
import * as React from 'react';
import { getMUITheme } from './shared/utils';
import { Shell } from './shell';
import { RootStore } from './stores/root.store';
import { history } from './utils/history';

// Create the rootStore
const rootStore = new RootStore();
rootStore.init();

// Observe history changes
const historyAdapter = new HistoryAdapter(rootStore.routerStore, history);
historyAdapter.observeRouterStateChanges();

class App extends React.Component {
  public render() {
    return (
      <MuiThemeProvider theme={getMUITheme()}>
        <Provider rootStore={rootStore}>
          <Shell />
        </Provider>
      </MuiThemeProvider>
    );
  }
}

export default App;
